import SessionService from './SessionService'
import { sessionStorage } from '../constants/constants'

const storeName = sessionStorage

describe('Session Service', () => {
  let Session = {}
  beforeEach(() => {
    Session = new SessionService()
  })

  describe('Initialization', () => {
    beforeEach(() => {
      SessionService.prototype._init = jest.fn()
      Session = new SessionService()
    })
    test('It initializes on instantiation', () => {
      expect(Session._init).toBeCalled()
    })
  })
  describe('Session', () => {
    test('[SessionService] should create session', () => {
      const cache = Session.getCacheComplete()
      expect(cache).toHaveProperty(`global.${storeName}`)
    })

    test('[SessionService] should get Store Name', () => {
      expect(storeName).toBe(Session.getStoreName())
    })

    test('[SessionService] should set/get from cache', () => {
      const a = 1
      const b = '2'
      const c = {
        3: '4'
      }

      Session.saveToCache('v1', a)
      Session.saveToCache('v2', b)
      Session.saveToCache('v3', c)

      const v1 = Session.getFromCache('v1', null)
      const v2 = Session.getFromCache('v2', null)
      const v3 = Session.getFromCache('v3', null)

      expect(v1).toBe(a)
      expect(v2).toBe(b)
      expect(v3).toEqual(c)
    })

    test('[SessionService] should get default value', () => {
      Session.deleteCacheAll()

      const defValue = Session.getFromCache('nonExistKey', 'defaultValue')
      const defValueOther = Session.getFromCache(
        'nonExistKeyOther',
        'defaultValueOther'
      )

      expect(defValue).toBe('defaultValue')
      expect(defValueOther).toBe('defaultValueOther')
    })

    test('[SessionService] should get cache complete', () => {
      Session.deleteCacheAll()

      const cache = Session.getCacheComplete()

      expect(cache).toEqual({})
    })

    test('[SessionService] should delete from cache', () => {
      Session.deleteCacheAll()

      Session.saveToCache('key1', 'value1')
      Session.saveToCache('key2', 'value2')
      Session.saveToCache('key3', 'value3')

      let key1 = Session.getFromCache('key1', null)
      let key2 = Session.getFromCache('key2', null)
      let key3 = Session.getFromCache('key3', null)

      expect(key1).toBe('value1')
      expect(key2).toBe('value2')
      expect(key3).toBe('value3')

      Session.deleteFromCache('key1')
      key1 = Session.getFromCache('key1', 'def1')

      expect(key1).toBe('def1')
      expect(key2).toBe('value2')
      expect(key3).toBe('value3')
    })

    test('[SessionService] should delete all cache', () => {
      Session.deleteCacheAll()

      const cache = Session.getCacheComplete()

      expect(cache).toEqual({})
    })

    test('[SessionService] should set/get from cache with expiration', () => {
      const a = 1
      const b = '2'
      const c = {
        3: '4'
      }

      Session.deleteCacheAll()

      Session.saveToCache('v1', a, 1000)
      Session.saveToCache('v2', b, 1000)
      Session.saveToCache('v3', c, 1000)

      const cache = Session.getCacheComplete()

      expect(cache).toHaveProperty('cacheExpirationTime')
      expect(cache).toHaveProperty('cacheExpirationTime.global')
      expect(cache).toHaveProperty('cacheExpirationTime.global.v1')
      expect(cache).toHaveProperty('cacheExpirationTime.global.v2')
      expect(cache).toHaveProperty('cacheExpirationTime.global.v3')
    })

    test('[SessionService] should debug', () => {
      let spy = jest.spyOn(Session, 'debug').mockImplementation(() => 'debug!')

      expect(Session.debug()).toBe('debug!')

      spy.mockRestore()
    })
  })
})
