import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { isEmpty } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Page from 'components/Page'
import Typography from 'components/ui/Typography'
import Divider from 'components/ui/Divider'
import { Button } from 'components/ui/Button'
import Avatar from 'components/Avatar'
import styles from './AdvisorProfile.module.sass'
import { actions } from '../fullProfileViews/FullProfileViewsReducer'
import { actions as advisorProfileActions } from './AdvisorProfileReducer'
import * as profileSearchSelectors from '../advisorProfile/AdvisorProfileSelector'
import * as fullProfileViewsSelectors from '../fullProfileViews/FullProfileViewsSelector'
import * as authSelectors from '../auth/AuthSelector'
import LoadingComponent from 'components/LoadingComponent'
import Markup from 'components/ui/Markup'

const { getProfileSearchStatus, getProfile } = profileSearchSelectors
const { getFeaturesTable } = authSelectors
const { getAccessStatus } = fullProfileViewsSelectors

const AdvisorProfile = ({ match }) => {
  const roleSchema = useSelector(getFeaturesTable())['lima-bureau-profile']
  const [advisorProfile] = useSelector(getProfile())
  const profileAccess = useSelector(getAccessStatus())
  const isFetching = useSelector(getProfileSearchStatus())
  const memberId = match.params.memberId
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.fullProfileViewsRequest({ fullRequest: true }))
    dispatch(
      advisorProfileActions.profileRequest({
        memberId: memberId,
        hasList: false,
        isSummary: false
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {isFetching ? (
        <LoadingComponent />
      ) : isEmpty(advisorProfile) ? (
        <NoDataPage />
      ) : profileAccess ? (
        <Page
          contentClassName={styles.contentContainer}
          showHero={false}
          showDivider={false}
          showFooter={false}
          showBackIcon={true}
          backIconLink={'/lima-bureau-advisor/list'}
          isLimaBureau
        >
          <ProfileCard profile={advisorProfile} roleSchema={roleSchema} />
          <Divider className={styles.cardDivider} />
          <ProfileSummary profile={advisorProfile} roleSchema={roleSchema} />
        </Page>
      ) : (
        <ProfileLimitPage memberId={memberId} />
      )}
    </>
  )
}
AdvisorProfile.propTypes = {
  match: PropTypes.object
}

const ProfileCard = ({ profile, roleSchema }) => {
  const {
    sections: { fields }
  } = roleSchema
  const avatarName = `Dr. ${
    isEmpty(profile?.firstName) ? '' : profile.firstName
  } ${isEmpty(profile?.lastName) ? '' : profile.lastName}`
  return (
    <div className={styles.profileCardContainer}>
      <div>
        {fields.includes('callingCardPicture') && (
          <Avatar fullName={avatarName} size={70} />
        )}
      </div>
      <div>
        {fields.includes('callingCardName') && (
          <Typography type={'headline'}>{profile.fullName}</Typography>
        )}
        {fields.includes('callingCardCredential') &&
          !isEmpty(profile?.credentials) && (
            <Typography type={'subheading'}>{profile.credentials}</Typography>
          )}
        {fields.includes('callingCardSpecialty') &&
          !isEmpty(profile?.specialties) && (
            <Typography type={'subheading'}>{profile.specialties}</Typography>
          )}
        {fields.includes('callingCardInstitution') &&
          !isEmpty(profile?.institution) && (
            <Typography type={'subheading'}>{profile.institution}</Typography>
          )}
      </div>
    </div>
  )
}
ProfileCard.propTypes = {
  profile: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    credentials: PropTypes.string,
    specialties: PropTypes.string,
    institution: PropTypes.string
  }),
  roleSchema: PropTypes.object
}

const ListShowMore = ({ list, showLength = 6 }) => {
  const [listCount, setListCount] = useState(showLength)
  const increaseListCount = (e, length) => {
    setListCount(length)
  }
  const resetListCount = () => {
    setListCount(showLength)
  }
  const listLength = isEmpty(list) ? 0 : list.length
  if (listLength <= 0) {
    return <Typography type={'primary'}>No data available</Typography>
  }
  if (listLength > listCount) {
    return (
      <>
        {list.slice(0, listCount).map((tag, i) => (
          <div key={tag}>{tag}</div>
        ))}
        <div className={styles.moreButtonContainer}>
          <Button
            className={styles.moreButton}
            tier={'primary'}
            raised={false}
            fontSize={'1em'}
            iconLeft="svg/custom/carat-d"
            onClick={e => increaseListCount(listLength)}
          >
            show more
          </Button>
        </div>
      </>
    )
  } else if (listLength <= 6) {
    return (
      <>
        {list.slice(0, listCount).map((tag, i) => (
          <div key={tag}>{tag}</div>
        ))}
      </>
    )
  } else {
    return (
      <>
        {list.slice(0, listCount).map((tag, i) => (
          <div key={tag}>{tag}</div>
        ))}
        <div className={styles.moreButtonContainer}>
          <Button
            className={styles.moreButton}
            tier={'primary'}
            raised={false}
            fontSize={'1em'}
            iconLeft="svg/custom/carat-u"
            onClick={e => resetListCount()}
          >
            show less
          </Button>
        </div>
      </>
    )
  }
}
ListShowMore.propTypes = {
  list: PropTypes.array,
  showLength: PropTypes.number
}

const ProfileSummary = ({ profile, roleSchema }) => {
  const {
    sections: { fields }
  } = roleSchema
  return (
    <div className={styles.profileSummary}>
      {/* CONTACT INFO */}
      <div>
        <SectionHeader text={'Contact Info'} />
        <Typography type={'section-heading'}>{profile.fullName}</Typography>
        <div className={styles.profileDataContainer}>
          <div>
            {fields.includes('contactInfoWorkplaceName') &&
              !isEmpty(profile?.workplaceName) && (
                <Typography type={'body1'}>{profile.workplaceName}</Typography>
              )}
            {fields.includes('contactInfoWorkplaceAddress') &&
              !isEmpty(profile?.workplaceAddress) && (
                <Typography type={'body1'}>
                  {profile.workplaceAddress}
                </Typography>
              )}
          </div>
          <div>
            {fields.includes('contactInfoPhoneNumber') &&
              !isEmpty(profile?.phone) && (
                <Typography type={'body1'}>{profile.phone}</Typography>
              )}
            {fields.includes('contactInfoFaxNumber') &&
              !isEmpty(profile?.fax) && (
                <Typography type={'body1'}>{profile.fax}</Typography>
              )}
          </div>
        </div>
      </div>
      {/* FACULTY */}
      {fields.includes('facultyMembership') &&
        !isEmpty(profile?.facultyName) && (
          <div>
            <SectionHeader text={'Faculty Membership'} />
            <div className={styles.profileDataContainer}>
              <div>
                <Typography type={'section-heading'}>
                  {profile.facultyName}
                </Typography>
                {!isEmpty(profile?.facultyAddress) && (
                  <Typography type={'body1'}>
                    {profile.facultyAddress}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        )}
      {/* CERTIFICATIONS */}
      {fields.includes('certificationsLicensure') &&
        !isEmpty(profile?.certifications) && (
          <div>
            <SectionHeader text={'Certifications & Licensure'} />
            <div className={styles.profileDataContainer}>
              <ListShowMore
                list={profile.certifications.map((certificate, i) => (
                  <div key={certificate}>
                    <Typography type={'section-heading'}>
                      {certificate}
                    </Typography>
                  </div>
                ))}
              />
            </div>
          </div>
        )}
      {/* EDUCATION & FELLOWSHIP */}
      {fields.includes('educationFellowships') &&
        !isEmpty(profile?.educationFellowships) && (
          <div>
            <SectionHeader text={'Education & Fellowship'} />
            <div className={styles.profileDataContainer}>
              <ListShowMore
                list={profile.educationFellowships.map(item => (
                  <>
                    {!isEmpty(item.name) && (
                      <Typography type={'section-heading'}>
                        {item.name}
                      </Typography>
                    )}
                    {!isEmpty(item.graduated) && (
                      <Typography type={'body1'}>{item.graduated}</Typography>
                    )}
                    {!isEmpty(item.address) && (
                      <Typography type={'body1'}>{item.address}</Typography>
                    )}
                  </>
                ))}
              />
            </div>
          </div>
        )}
      {/* HOSPITAL AND AFFILIATIONS */}
      {fields.includes('hospitalAffiliations') &&
        !isEmpty(profile?.hospitalAffiliations) && (
          <div>
            <SectionHeader text={'Hospital & Workplace Affiliations'} />
            <div className={styles.profileDataContainer}>
              <ListShowMore
                list={profile.hospitalAffiliations.map((item, i) => (
                  <div className={styles.innerContentDivider} key={item.name}>
                    {fields.includes('hospitalAffiliationsName') && (
                      <Typography type={'section-heading'}>
                        {item.name}
                      </Typography>
                    )}
                    {fields.includes('hospitalAffiliationsAddress') && (
                      <Typography type={'body1'}>{item.address}</Typography>
                    )}
                  </div>
                ))}
              />
            </div>
          </div>
        )}
      {/* PEER REVIEWED PUBLICATIONS */}
      {fields.includes('peerReviewedPublications') &&
        !isEmpty(profile?.publications) && (
          <div>
            <SectionHeader text={'Peer-Reviewed Publications'} />
            <div className={styles.profileDataContainer}>
              <ListShowMore
                list={profile.publications.map((item, i) => (
                  <div
                    className={styles.innerContentDivider}
                    key={item.articleTitle}
                  >
                    <Typography
                      type={'link'}
                      className={styles.customLink}
                      component="a"
                      target="_blank"
                      href={`https://pubmed.ncbi.nlm.nih.gov/${item.articleLink}`}
                    >
                      <Markup html={item.articleTitle} />
                    </Typography>
                  </div>
                ))}
              />
            </div>
          </div>
        )}
      {/* TAGS/INTERESTS */}
      {fields.includes('tags') && !isEmpty(profile?.tags) && (
        <div>
          <SectionHeader text={'Tags'} />
          <div className={styles.profileDataContainer}>
            <ListShowMore
              list={profile.tags.map((item, i) => (
                <Typography key={item} type={'section-heading'}>
                  {item}
                </Typography>
              ))}
            />
          </div>
        </div>
      )}
      {/* ADVISOR TYPE */}
      {fields.includes('advisorType') && !isEmpty(profile?.advisorType) && (
        <div>
          <SectionHeader text={'Advisor Type'} />
          <div className={styles.profileDataContainer}>
            {profile.advisorType.map((item, i) => (
              <Typography
                key={item}
                type={'section-heading'}
                className={styles.allCaps}
              >
                {item}
              </Typography>
            ))}
          </div>
        </div>
      )}
      {/* INTERNAL */}
      {fields.includes('internal') && !isEmpty(profile?.sessionFee) && (
        <div>
          <SectionHeader text={'Internal'} />
          <div className={styles.profileDataContainer}>
            {fields.includes('internalFeePerSession') &&
              !isEmpty(profile?.sessionFee) && (
                <div className={styles.innerContentDivider}>
                  <Typography type={'section-heading'}>
                    Fee Per Session
                  </Typography>
                  <Typography type={'body1'}>${profile.sessionFee}</Typography>
                </div>
              )}
          </div>
        </div>
      )}
      {/* OTHER */}
      {fields.includes('practiceLanguages') && (
        <div>
          <SectionHeader text={'Other'} />
          <Typography type={'section-heading'}>Practice Languages</Typography>
          <Typography type={'body1'}>English</Typography>
          {!isEmpty(profile?.languages) &&
            profile.languages.map(
              (lang, i) =>
                lang.name !== 'English' && (
                  <Typography key={lang.name} type={'body1'}>
                    {lang.name}
                  </Typography>
                )
            )}
        </div>
      )}
      {/* NATIONAL ID */}
      {fields.includes('nationalID') && (
        <div>
          <SectionHeader text={'National ID'} />
          <Typography type={'body1'}>{profile.nationalID}</Typography>
        </div>
      )}
    </div>
  )
}
ProfileSummary.propTypes = {
  profile: PropTypes.shape({
    fullName: PropTypes.string,
    workplaceName: PropTypes.string,
    workplaceAddress: PropTypes.string,
    phone: PropTypes.string,
    fax: PropTypes.string,
    facultyName: PropTypes.string,
    facultyAddress: PropTypes.string,
    certifications: PropTypes.array,
    educationFellowships: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        graduated: PropTypes.string,
        address: PropTypes.string
      })
    ),
    hospitalAffiliations: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        address: PropTypes.string
      })
    ),
    publications: PropTypes.array,
    tags: PropTypes.array,
    advisorType: PropTypes.array,
    sessionFee: PropTypes.string,
    languages: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string
      })
    ),
    nationalID: PropTypes.string
  }),
  roleSchema: PropTypes.object
}

const SectionHeader = ({ text }) => (
  <div className={styles.sectionHeader}>
    <Typography type={'title'}>{text}</Typography>
    <Divider className={styles.titleDivider} />
  </div>
)
SectionHeader.propTypes = {
  text: PropTypes.string
}

const ProfileLimitPage = ({ memberId }) => {
  const profileSummaryLink = `/lima-bureau-advisor/profile/${memberId}`
  return (
    <Page
      contentClassName={styles.contentContainer}
      heroTitle={
        'You have reached the limit of full profile views allowed for today'
      }
      showBackIcon={true}
      backIconLink={profileSummaryLink}
      showHero={true}
      showDivider={true}
      showFooter={false}
      isLimaBureau
    >
      <Typography type={'link'} component={Link} to={profileSummaryLink}>
        Back to Profile Summary
      </Typography>
    </Page>
  )
}
ProfileLimitPage.propTypes = {
  /**
   * Required for advisor profile
   */
  memberId: PropTypes.string
}

const NoDataPage = () => (
  <Page
    heroTitle={'No data available'}
    contentClassName={styles.contentContainer}
    showBackIcon={true}
    backIconLink={'/lima-bureau-advisor'}
    showHero={true}
    showDivider={true}
    showFooter={false}
    isLimaBureau
  >
    <Typography type={'link'} component={Link} to={'/lima-bureau-advisor'}>
      Back to Advisor Search Form
    </Typography>
  </Page>
)

export default AdvisorProfile
