import { createContainer, asValue, Lifetime, InjectionMode } from 'awilix'
import Services from 'services/Services'

const serviceList = {}
Services.forEach(({ name, subfolder = '', registerAs }) => {
  serviceList[name] = registerAs(
    require(`services${subfolder}/${name}`).default,
    registerAs.name === 'asClass'
      ? {
          lifetime: Lifetime.SINGLETON
        }
      : null
  )
})
const serviceContainer = () => {
  const container = createContainer({ injectionMode: InjectionMode.CLASSIC })
  container.register(serviceList)
  // This provides a way to access dispatch and the store inside
  // connected services
  container.registerStore = ({ store }) => {
    container.register({
      dispatch: asValue(action => store.dispatch(action)),
      getState: asValue(() => store.getState())
    })
  }
  if (process.env.REACT_APP_API_DOMAIN !== 'production') {
    window.di = container
  }
  return container
}

export default serviceContainer
