import { all, call, put, takeEvery, select } from 'redux-saga/effects'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as translationActions } from 'modules/dougall/translation/TranslationReducer'
import { getTranslationList } from 'modules/dougall/translation/TranslationSelector'
import {
  isEmpty
  // supportBCP
} from '../../../utils'
import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { localeEndpoint } from '../../../constants'
// import SessionService from 'services/SessionService'
import siteConfig from 'site.config.json'

const { configuration } = siteConfig

/**
 * @param services
 * function that sets page language hierarchy for authenticated users
 * 1. check for supported language set at profile level
 * 2. if it set at the browser and supported
 * 3. if user profile country is set to accepted languageCode
 * 4. set to fallbackLng
 */
function* translationRequest(services) {
  const {
    localization: {
      fallbackLng,
      //countryLang,
      debug = false
    }
  } = configuration
  try {
    const browserLang = navigator.language
    // We do not have the language/country code in the user profile so this is not needed for the moment
    // const CookieService = services('CookieService')
    // const state = yield call([CookieService, 'getFromCache'], 'state')
    // const profileCountryCode = state?.userInfo?.countryCode
    // const profileLanguageCode = supportBCP(state?.userInfo?.languageCode)
    const TranslationService = services('TranslationService')

    // RETRIEVE RESOURCES
    let resources = yield select(getTranslationList())
    if (isEmpty(resources)) {
      try {
        resources = yield call(
          [TranslationService, 'fetchTranslation'],
          localeEndpoint
        )
      } catch (e) {
        console.error(e)
      }
    }

    // If the bowser language is supported use that one, otherwise use the fallbacklanguage
    const languageCode =
      !isEmpty(browserLang) && Object.keys(resources).includes(browserLang)
        ? browserLang
        : fallbackLng

    // const languageCode =
    //   !isEmpty(profileLanguageCode) &&
    //   Object.keys(resources).includes(profileLanguageCode)
    //     ? profileLanguageCode
    //     : !isEmpty(browserLang) && Object.keys(resources).includes(browserLang)
    //     ? browserLang
    //     : !isEmpty(profileCountryCode) &&
    //       Object.keys(countryLang).includes(profileCountryCode.toLowerCase())
    //     ? countryLang[profileCountryCode.toLowerCase()]
    //     : fallbackLng
    if (!i18next?.isInitialized) {
      i18next
        .use(LanguageDetector)
        .use(initReactI18next)
        .use(Backend)
        .init({
          debug,
          resources,
          react: {
            bindI18n: 'loaded languageChanged',
            bindI18nStore: 'added',
            useSuspense: true
          },
          detection: {
            order: ['queryString', 'cookie'],
            cache: ['cookie']
          },
          interpolation: {
            escapeValue: false
          },
          missingKeyHandler: (lng, ns, key, fallbackValue) => {
            console.warn(lng, ns, key, fallbackValue)
          }
        })
    }

    i18next?.changeLanguage(languageCode)

    yield put(
      translationActions.translationRequestSuccess({ languageCode, resources })
    )
  } catch (e) {
    console.error(e)
    yield put(translationActions.translationRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: i18next.t('translationSagaErrorMessage'),
        type: 'error'
      })
    )
  }
}

export default function* watchTranslationRequest(services) {
  yield all([takeEvery('AUTH_REQUEST_SUCCESS', translationRequest, services)])
}
