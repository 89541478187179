import { isEmpty } from 'utils'

/**
 * List Ingestion endpoints Service
 */
const TypeaheadService = SmartsML => ({
  /**
   * Fetch options for typeahead
   *
   * @param endpoint
   * @param data
   * @param token - access token
   * @returns {Promise<*[]|*>}
   */
  async fetch(endpoint, data) {
    const response = await SmartsML.fetch(endpoint, 'POST', data)
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  }
})

export default TypeaheadService
