import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Message from 'components/Dougall/Message/Message'
import { CircularProgress } from 'components/ui/Progress'
import TextDivider from 'components/Dougall/TextDivider/TextDivider'
import styles from './MessagesStream.module.sass'

import siteConfig from 'site.config.json'
import { getDateChunk } from 'utils'
import { useTranslation } from 'react-i18next'

const {
  sections,
  configuration: { appTheme }
} = siteConfig

const MessageStream = ({
  messages = [],
  onEditClick,
  onAction,
  isLoading,
  isTyping,
  showShare,
  showLikes,
  onTypingFinish
}) => {
  const { t } = useTranslation()
  const {
    chat: { user, chatGPT, dougallMD }
  } = sections

  const scrollable = useRef(null)

  useEffect(() => {
    // Autoscroll to your last prompt
    if (isLoading) {
      const children = Array.from(scrollable.current.children).reverse()
      children.every(child => {
        if (child.id.includes('prompt')) {
          child.scrollIntoView({ behavior: 'smooth' })
          return false
        }
        return true
      })
    }
  }, [isLoading])

  useEffect(() => {
    // Scroll to Top on messages reset (for mobile)
    if (messages.length === 2) {
      scrollable.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages])

  const handleTypingFinish = () => {
    onTypingFinish()
    scrollable.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }

  return (
    <div className={styles.container} ref={scrollable}>
      {messages.map((messageInfo, idx) => {
        const {
          promptId = '',
          answerId = '',
          isAnswer,
          isDougall,
          showWarning,
          message,
          answer,
          highlightedAnswer,
          hcpPrompt,
          editable,
          tags,
          urls,
          showSocial,
          isError,
          createdDate: date,
          typewriter,
          done,
          isDivider = false
        } = messageInfo
        const profileIcon = isAnswer
          ? isDougall
            ? dougallMD?.defaultIcon
            : chatGPT?.defaultIcon
          : user?.defaultIcon
        const title = isAnswer
          ? isDougall
            ? t('trustedLinks')
            : t('answerFromGPT')
          : t('you')

        if (isDivider) {
          return <TextDivider key={idx} text={getDateChunk(date)} />
        }

        return (
          <div
            id={isAnswer ? `answer-${idx}` : `prompt-${idx}`}
            key={idx}
            className={cx(
              idx !== messages.length - 1 && styles.messageContainer
            )}
          >
            <Message
              index={`${idx}`}
              isAnswer={isAnswer}
              isDougall={isDougall}
              showWarning={showWarning}
              icon={profileIcon}
              title={title}
              message={message ?? answer}
              highlightedAnswer={highlightedAnswer}
              prompt={hcpPrompt}
              editable={editable}
              onEditClick={onEditClick}
              onAction={onAction}
              promptId={promptId}
              answerId={answerId}
              tags={tags}
              urls={urls}
              showSocial={showSocial}
              isError={isError}
              showAvatar={isAnswer || appTheme !== 'dougallgpt'}
              date={date}
              showShare={showShare}
              showLikes={showLikes}
              typewriter={typewriter}
              done={done}
              onTypingFinish={handleTypingFinish}
            />
          </div>
        )
      })}
      {isLoading && !isTyping && (
        <div>
          <CircularProgress
            className={styles.progress}
            fillColor="transparent"
            strokeColor="#293056"
          />
        </div>
      )}
    </div>
  )
}

MessageStream.propTypes = {
  /**
   * Messages to display
   */
  messages: PropTypes.array,
  /**
   * When is user prompt, on edit icon click
   */
  onEditClick: PropTypes.func,
  /**
   * When it has actionBar, on like or dislike
   */
  onAction: PropTypes.func,
  /**
   * Boolean when answer is fetching
   */
  isLoading: PropTypes.bool,
  /**
   * Boolean when an anwer is being typed
   */
  isTyping: PropTypes.bool,
  /**
   * Boolean to show Share Button or not
   */
  showShare: PropTypes.bool,
  /**
   * Boolean to show Like/Dislike Button
   */
  showLikes: PropTypes.bool,
  /**
   * Function excecuted when current typewriter message animation finishes
   */
  onTypingFinish: PropTypes.func
}

export default MessageStream
