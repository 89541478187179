import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styles from './form.module.sass'
import ProjectMetadata from 'modules/projectMetadata'
import Divider from '../../../components/ui/Divider'
import Typography from '../../../components/ui/Typography'
import Button from 'components/Button'
import * as exploreProjectSelectors from '../ExploreProjectSelector'
import * as exploreProjectActions from '../ExploreProjectReducer'
import useFilters from 'modules/filter/useFilters'
import { SelectedFilters, SelectedListIds } from 'components/Selected'
import useRoleFeatures from 'modules/auth/useRoleFeatures'

const { getFormState, getFetchStatus } = exploreProjectSelectors

function ProjectMetadataForm({ formProps }) {
  const isFetching = useSelector(getFetchStatus())
  const formState = useSelector(getFormState())
  const { values, setFieldValue } = formProps
  const {
    allMembersSelected,
    combinations,
    filters,
    selectedListLabels,
    type
  } = values
  const dispatch = useDispatch()
  const { selectedFiltersCaptions } = useFilters(filters)

  const { features } = useRoleFeatures({
    coin: 'explore-project'
  })

  const handleChangeForm = step => {
    dispatch(
      exploreProjectActions.setFormState({
        ...formState,
        activeStep: step
      })
    )
  }
  const handleTypeSubmit = type => {
    setFieldValue('type', type)
  }
  return (
    <>
      <div className={styles.selectedContainer}>
        <SelectedListIds
          selectedListLabels={selectedListLabels}
          onEdit={() => handleChangeForm('list-selection')}
          isFetching={isFetching}
          alignEditButton="flex-end"
        />
        <SelectedFilters
          combinations={combinations}
          selectedFiltersCaptions={selectedFiltersCaptions}
          onEdit={() => handleChangeForm('filters')}
          isFetching={isFetching}
          allMembersSelected={allMembersSelected}
          alignEditButton="flex-end"
        />
      </div>
      <div className={styles.buttonsRow}>
        {type !== 'graph' && (
          <Button
            className={styles.button}
            hasSpinner={values.type === 'graph'}
            submitting={isFetching}
            disabled={isFetching}
          >
            Graph
          </Button>
        )}
        {type !== 'save-list' &&
          features.sections?.actions.includes('save-list') && (
            <Button
              className={styles.button}
              onClick={() => handleTypeSubmit('save-list')}
              hasSpinner={values.type === 'save-list'}
              submitting={isFetching}
              disabled={isFetching}
            >
              Save List
            </Button>
          )}
        {/* {type !== 'run-analysis' && (
          <Button
            className={styles.button}
            onClick={() => handleTypeSubmit('run-analysis')}
            hasSpinner={values.type === 'run-analysis'}
            submitting={isFetching}
            disabled={isFetching}
          >
            Run Analysis
          </Button>
        )} */}
      </div>
      <Typography
        type="body2"
        className={styles.center}
        style={{ marginBottom: '2vh' }}
      >
        {/* Update with Endpoint response */}
        LiMA Member Count: 0
      </Typography>
      <Typography
        type="title"
        color="text-primary"
        className={styles.title}
        align="center"
      >
        Select Project Metadata
      </Typography>
      <Divider />
      <ProjectMetadata
        formName="explore-project"
        formProps={formProps}
        hideDefaultCountry
        hideTypeForm
        hideAgency
        showReportName
      />
    </>
  )
}

ProjectMetadataForm.propTypes = {
  /**
   * Form props
   */
  formProps: PropTypes.object
}

export default ProjectMetadataForm
