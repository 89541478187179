class DougallMLError extends Error {
  constructor(message, responseData, code) {
    super(message)
    this.responseData = responseData
    this.code = code

    // For IE11 badness
    this.constructor = DougallMLError
  }

  getResponseData() {
    return this.responseData
  }

  getCode() {
    return this.code
  }
}

export default DougallMLError
