import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import SelectMenuInput from 'components/SelectMenuInput'
import MultiSelectInput from 'components/MultiSelectInput'
import * as typeaheadSelectors from './TypeaheadSelector'
import { actions as typeaheadActions } from 'modules/typeahead/TypeaheadReducer'
import Spinner from '../../components/ui/Spinner'
import styles from './Typeahead.module.sass'
import { isEmpty } from 'utils'
import Tooltip from 'components/Tooltip'
import { isBoolean } from 'lodash'
const { getOptions, getTooltips, getPagination, getFetching } =
  typeaheadSelectors

const Typeahead = ({
  formName = '',
  name,
  body,
  fetchOnFocus = true,
  multiselect = false,
  disabled,
  disableOnEmptyResults = false,
  ...props
}) => {
  const inputName = (formName ? `${formName}-` : '') + name
  const [value, setValue] = useState('')
  const dispatch = useDispatch()
  const options = useSelector(getOptions(inputName)) || {}
  const tooltips = useSelector(getTooltips(inputName)) || {}
  const pagination = useSelector(getPagination(inputName)) || {}
  const isFetching = useSelector(getFetching(inputName))

  const handleChange = query => {
    const { data = {}, endpoint, parseFunction } = body
    if (typeof props.onChange === 'function') {
      props.onChange(query)
    }
    setValue(query)
    // Only call if the search term is not in the options list
    if (!Object.values(options).find(o => o === query)) {
      dispatch(
        typeaheadActions.typeaheadRequest({
          name: inputName,
          data: { ...data, search: { term: query, ...data.search } },
          endpoint,
          parseFunction
        })
      )
    }
  }
  const handleOnFocus = () => {
    // Fetch only if options are empty/ first time user focus input
    if (isEmpty(options)) {
      handleChange()
    }
  }
  const renderTooltip = ({ key }) => {
    return (
      tooltips[key] && (
        <div className={styles.tooltipContainer}>
          <Tooltip
            tip={tooltips[key]}
            multiline={true}
            iconProps={{
              icon: 'svg/custom/help-outline',
              iconSize: 20
            }}
          />
        </div>
      )
    )
  }

  const isDisabled = () => {
    const { filtered, total } = pagination
    if (
      disableOnEmptyResults &&
      isEmpty(options) &&
      filtered === 0 &&
      !value &&
      total >= 0
    ) {
      return true
    }
    if (isBoolean(disabled)) {
      return disabled
    }
    return false
  }

  return (
    <>
      {multiselect ? (
        <MultiSelectInput
          {...props}
          name={name}
          options={options || {}}
          onChange={handleChange}
          onFocus={handleOnFocus}
          disabled={disabled}
          rightElements={
            isFetching && (
              <div className={styles.rightElements}>
                <Spinner
                  strokeColor="#02296d"
                  fillColor="transparent"
                  size={20}
                  strokeWidth={4}
                />
              </div>
            )
          }
        />
      ) : (
        <SelectMenuInput
          {...props}
          name={name}
          options={options || {}}
          onChange={handleChange}
          onFocus={handleOnFocus}
          suggestionExtraElement={renderTooltip}
          maxLength={100}
          disabled={isDisabled()}
          rightElements={
            isFetching && (
              <div className={styles.rightElements}>
                <Spinner
                  strokeColor="#02296d"
                  fillColor="transparent"
                  size={20}
                  strokeWidth={4}
                />
              </div>
            )
          }
        />
      )}
    </>
  )
}

Typeahead.propTypes = {
  /**
   * Typeahead formName
   */
  formName: PropTypes.string,
  /**
   * Typeahead name to be used
   */
  name: PropTypes.string.isRequired,
  /**
   * Function tobe called on input change
   */
  onChange: PropTypes.func,
  /**
   * Body to be sent to the saga
   */
  body: PropTypes.shape({
    /**
     * Data/Body to be sent to the endpoint
     * 'searchTerm' will be added automatically
     */
    data: PropTypes.object,
    /**
     * Endpoint to be called by the saga
     */
    endpoint: PropTypes.string.isRequired,
    /**
     * Parse function to be used, default 'optionsParser'
     */
    parseFunction: PropTypes.string
  }).isRequired,
  /**
   * If true the typeahead will make a request on first focus event
   */
  fetchOnFocus: PropTypes.bool,
  /**
   * If true the rendered component will be a multiple select input, default true
   */
  multiselect: PropTypes.bool,
  /**
   * If true input will be disabled
   */
  disabled: PropTypes.bool,
  /**
   * If true input will be disabled if there are no results on fetch
   */
  disableOnEmptyResults: PropTypes.bool
}

export default Typeahead
