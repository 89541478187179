import { createSelector } from '@reduxjs/toolkit'

export const incomingSelector = state => state.incomingReducer

export const getIsFetching = () =>
  createSelector(incomingSelector, incoming => incoming.fetching)
export const getRequestType = () =>
  createSelector(incomingSelector, incoming => incoming.type)
export const getSearchFetching = () =>
  createSelector(incomingSelector, incoming => incoming.searchFetching)
export const getPagination = () =>
  createSelector(incomingSelector, incoming => incoming.pagination)
export const getCounts = () =>
  createSelector(incomingSelector, incoming => incoming.counts)
export const getSearchState = () =>
  createSelector(incomingSelector, incoming => incoming.searchState)
export const getSpecialtyList = () =>
  createSelector(incomingSelector, incoming => incoming.specialties)
export const getProfessionList = () =>
  createSelector(incomingSelector, incoming => incoming.professions)
