import { createReducer, createAction } from '@reduxjs/toolkit'

// Typeahead actions
export const typeaheadRequest = createAction('TYPEAHEAD_REQUEST')
export const typeaheadRequestSuccess = createAction('TYPEAHEAD_REQUEST_SUCCESS')
export const typeaheadRequestFail = createAction('TYPEAHEAD_REQUEST_FAIL')
export const typeaheadRequestReset = createAction('TYPEAHEAD_REQUEST_RESET')

export const actions = {
  typeaheadRequest,
  typeaheadRequestSuccess,
  typeaheadRequestFail,
  typeaheadRequestReset
}

const typeaheadReducer = createReducer(
  {
    typeaheads: {}
  },
  {
    [typeaheadRequest]: (state, { payload }) => {
      const { name } = payload
      state.typeaheads[name] = {
        ...state.typeaheads[name],
        fetching: true
      }
    },
    [typeaheadRequestSuccess]: (state, { payload }) => {
      const { name, ...rest } = payload
      state.typeaheads[name] = {
        ...state.typeaheads[name],
        ...rest,
        fetching: false
      }
    },
    [typeaheadRequestFail]: (state, { payload }) => {
      const { name } = payload
      state.typeaheads[name] = {
        ...state.typeaheads[name],
        options: {},
        fetching: false
      }
    },
    [typeaheadRequestReset]: (state, { payload }) => {
      const { ids } = payload
      ids.forEach(id => {
        state.typeaheads[id] = {
          ...state.typeaheads[id],
          options: {},
          fetching: false
        }
      })
    }
  }
)

export default typeaheadReducer
