import PropTypes from 'prop-types'
import Divider from 'components/ui/Divider/Divider'
import Typography from 'components/ui/Typography/Typography'
import styles from './TextDivider.module.sass'

const TextDivider = ({ text }) => {
  return (
    <div className={styles.container}>
      <Divider />
      <Typography>{text}</Typography>
      <Divider />
    </div>
  )
}

TextDivider.propTypes = {
  text: PropTypes.string
}

export default TextDivider
