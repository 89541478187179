import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as faqAction } from 'modules/faq/FaqPageReducer'

/**
 * Add File Upload form request action and reacts accordingly
 *
 * @param services
 * @param payload
 */
function* loadFaqRequest(services) {
  const SmartsService = services('SmartsService')
  try {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Loading Faq, Please wait a moment',
        duration: 1000
      })
    )
    const response = yield call([SmartsService, 'fetchFaq'], {
      brandCode: 'lima-smarts',
      fileName: 'faq.json'
    })
    yield put(faqAction.faqLoadSuccessRequest(response ? response.data : {}))
  } catch (e) {
    console.error(e)
    yield put(faqAction.faqLoadSuccessRequest({}))
    yield put(
      notificationActions.notificationEnqueue({
        message: e?.message || 'We had an issue processing your request',
        duration: 0
      })
    )
  }
}

export default function* watchSiteConfigTransaction(services) {
  yield all([takeEvery('FAQ_LOAD_REQUEST', loadFaqRequest, services)])
}
