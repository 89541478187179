import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { isEmpty } from 'utils'

import { getFetchingSingle, getSingleVariable } from './VariablesSelector'
import { actions as variablesActions } from './VariablesReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'

import TextInput from 'components/TextInput'
import Button from 'components/Admin/Button'
import Card from 'components/Admin/Card/Card'
import Typography from 'components/ui/Typography'
import PageContent from 'components/Admin/Page/PageContent'
import Icon from 'components/ui/Icon'
import { CircularProgress } from 'components/ui/Progress'
import useRoleFeatures from 'modules/auth/useRoleFeatures'
import ConfirmationModal from 'components/Admin/ConfirmationModal/ConfirmationModal'
import JsonViewer from 'react-json-view'
import * as Yup from 'yup'

import styles from './Variables.module.sass'

const validationSchema = Yup.object().shape({
  id: Yup.string()
    .required('Required')
    .test('is valid ID', 'Invalid ID', value => {
      const regex = new RegExp(/^([a-zA-Z0-9-_.+@]){5,}$/)
      return regex.test(value)
    }),
  note: Yup.string()
})

const SingleVariable = () => {
  const dispatch = useDispatch()
  const routerHistory = useHistory()
  const [clearInput, setClearInput] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const initialForm = {
    id: '',
    data: {},
    note: ''
  }
  const { features } = useRoleFeatures({
    coin: 'charts-configuration'
  })
  const isFetching = useSelector(getFetchingSingle())
  const singleVariable = useSelector(getSingleVariable())
  const roleSchema = features?.sections?.actions || []
  const userCanEdit = roleSchema.includes('save')
  const { location } = routerHistory
  const urlId = location.pathname.split('/').slice(-1)[0]
  const formStatePr = isEmpty(singleVariable) ? initialForm : singleVariable

  const {
    id = '',
    data = {},
    createdAt = 0,
    updatedAt = 0
  } = useSelector(getSingleVariable())

  useEffect(() => {
    if (urlId !== 'create') {
      dispatch(variablesActions.requestSingleVariable(urlId))
    } else {
      dispatch(variablesActions.resetSingleVariable({}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (urlId === 'create' && singleVariable.id !== '') {
      routerHistory.push(`/admin/variables/${singleVariable.id}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleVariable])

  const handleBack = () => {
    dispatch(variablesActions.resetSingleVariable({}))
    routerHistory.push('/admin/variables')
  }

  const handleReset = () => {
    setClearInput(!clearInput)
  }

  const onChangeData = (data, values, setFieldValue) => {
    setFieldValue('id', values.id)
    setFieldValue('note', values.note)
    setFieldValue('data', data.updated_src)
  }

  const handleSubmit = values => {
    if (values !== singleVariable) {
      dispatch(variablesActions.completeVariablesSingle(values))
    } else {
      dispatch(
        notificationActions.notificationEnqueue({
          message: 'Variable is not changed.',
          duration: 0
        })
      )
    }
  }

  const handleDelete = () => {
    dispatch(variablesActions.requestVariablesDelete({ id }))
    setModalOpen(false)
    handleBack()
  }

  return (
    <PageContent>
      {isFetching && urlId !== 'create' ? (
        <CircularProgress fillColor="transparent" size={64} strokeWidth={4} />
      ) : (
        <Formik
          key={clearInput}
          enableReinitialize
          initialValues={formStatePr}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form autoComplete="off">
              <Typography
                type="title"
                color="text-primary"
                className={styles.variableId}
              >
                {urlId === 'create' ? 'New Variable' : `Variable: ${id}`}
              </Typography>
              <Card className={styles.card}>
                <div className={styles.headingContainer}>
                  <Icon
                    icon="svg/custom/arrow-back"
                    className={styles.backIcon}
                    onClick={() => handleBack()}
                  />
                  <div className={styles.headerDisplayInfo}>
                    <Typography
                      type="title"
                      color="text-primary"
                      className={styles.cardTitle}
                    >
                      Back to Variables
                    </Typography>
                    {urlId !== 'create' && (
                      <div className={styles.headerSubtitle}>
                        <Typography
                          type="title"
                          color="text-primary"
                          className={styles.cardSubtitle}
                        >
                          Created: {createdAt}
                        </Typography>
                        <Typography
                          type="title"
                          color="text-primary"
                          className={styles.cardSubtitle}
                        >
                          Modified: {updatedAt}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.singleVariableInputs}>
                  <TextInput
                    label="ID"
                    name="id"
                    type="text"
                    traditional={false}
                    disabled={urlId !== 'create'}
                    className={styles.disabled}
                    required
                  />
                  {urlId === 'create' && (
                    <Typography className={styles.idWarning}>
                      <i>Name must be Unique</i>
                      <br />
                      <i>Use only alphanumeric characteres</i>
                      <br />
                      <i>
                        Symbols allowed: <code>-</code>, <code>_</code>,{' '}
                        <code>.</code>, <code>+</code> and <code>@</code>
                      </i>
                      <br />
                      <i>Must contain at least 5 characters</i>
                    </Typography>
                  )}
                  <TextInput
                    label="Note"
                    name="note"
                    type="text"
                    traditional={false}
                  />
                </div>
              </Card>
              <Card className={styles.card}>
                <Typography
                  type="title"
                  color="text-primary"
                  className={styles.cardTitle}
                >
                  Data
                </Typography>
                {urlId !== 'create' && (
                  <Typography className={styles.dataWarning}>
                    Incorrect structure & handlers might harm the process.
                    Please consult Project Manager before proceed with any
                    changes
                  </Typography>
                )}
                <div className={styles.jsonviewer}>
                  <JsonViewer
                    theme="ocean"
                    src={data}
                    name="data"
                    iconStyle="triangle"
                    displayDataTypes={false}
                    displayObjectSize={false}
                    collapsed={1}
                    onEdit={e => onChangeData(e, values, setFieldValue)}
                    onDelete={e => onChangeData(e, values, setFieldValue)}
                    onAdd={e => onChangeData(e, values, setFieldValue)}
                  />
                </div>
              </Card>
              <Card className={styles.card}>
                <div className={styles.singleVariableButtons}>
                  <Button
                    className={styles.resetButton}
                    text="Reset"
                    textCase="none"
                    type="reset"
                    backgroundColor="#d3d3d3"
                    onClick={() => handleReset()}
                  />
                  {userCanEdit && (
                    <div className={styles.actionButtons}>
                      {urlId !== 'create' && (
                        <Button
                          text="Delete"
                          backgroundColor="#cb4e4e"
                          onClick={() => setModalOpen(true)}
                          disabled={isFetching}
                          className={styles.deleteButton}
                        ></Button>
                      )}

                      <Button
                        text={urlId === 'create' ? 'Create' : 'Update'}
                        textCase="none"
                        type="submit"
                      />
                    </div>
                  )}
                </div>
              </Card>
            </Form>
          )}
        </Formik>
      )}
      <ConfirmationModal
        message={
          'Are you sure you want to delete element with ID: ' +
          singleVariable.id
        }
        open={modalOpen}
        onConfirm={handleDelete}
        onCancel={() => setModalOpen(false)}
        actionType="delete"
        actionCaption="Delete"
      />
    </PageContent>
  )
}

export default SingleVariable
