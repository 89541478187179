/**
 * Global settings
 */
const pptxLayout = { name: 'CUSTOM_PDF', width: 15.1, height: 8.5 }
const fontDefault = 'lato'
const fontSizeNormal = 14
const fontSizeSmall = 12
const fontColor = '656565'
const pptxBgFill = 'FFFFFF'
// const backgroundImagePath = ConfigManager.get('pptx_template_images', './src/utils/pptx/templates/images/background')

const defaultTextStyle = {
  fontSize: fontSizeNormal,
  fontFace: fontDefault
}
const defaultTitleTextStyle = {
  fontSize: 16,
  fontFace: fontDefault
}

/**
 * Set the PPTX Constants Settings
 */

export const pptxc = {
  pptxLayout,
  fontDefault,
  fontSizeNormal,
  fontSizeSmall,
  fontColor,
  defaultTextStyle,
  defaultTitleTextStyle,
  pptxBgFill
}
