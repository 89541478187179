import classNames from 'classnames/bind'
import styles from './Page.module.sass'
import PropTypes from 'prop-types'
import PageHeader from './PageHeader'

const cx = classNames.bind(styles)

const Page = ({ children }) => {
  return (
    <div className={cx(styles.pageContainer)} data-testid="page-header">
      <PageHeader>{children}</PageHeader>
    </div>
  )
}
Page.propTypes = {
  children: PropTypes.node.isRequired
}

export default Page
