import { createReducer, createAction } from '@reduxjs/toolkit'

export const setFormState = createAction('CHARTS_SEARCH_SET_FORM_STATE')
export const setChartState = createAction('CHARTS_SEARCH_SET_CHART_STATE')

export const requestChartsSearch = createAction('CHARTS_SEARCH_REQUEST')
export const requestChartsSearchSuccess = createAction(
  'CHARTS_SEARCH_REQUEST_SUCCESS'
)
export const requestChartsSearchFail = createAction(
  'CHARTS_SEARCH_REQUEST_FAIL'
)

export const requestIndividualConfig = createAction(
  'CHARTS_SEARCH_INDIVIDUAL_REQUEST'
)
export const requestIndividualConfigSuccess = createAction(
  'CHARTS_SEARCH_INDIVIDUAL_REQUEST_SUCCESS'
)
export const requestIndividualConfigFail = createAction(
  'CHARTS_SEARCH_INDIVIDUAL_REQUEST_FAIL'
)

export const requestSingleChart = createAction('CHARTS_SEARCH_SINGLE_REQUEST')
export const requestSingleChartSuccess = createAction(
  'CHARTS_SEARCH_SINGLE_REQUEST_SUCCESS'
)
export const requestSingleChartFail = createAction(
  'CHARTS_SEARCH_SINGLE_REQUEST_FAIL'
)

export const deleteChart = createAction('CHARTS_SEARCH_DELETE_CHART')
export const deleteChartSuccess = createAction(
  'CHARTS_SEARCH_DELETE_CHART_SUCCESS'
)
export const deleteChartFail = createAction('CHARTS_SEARCH_DELETE_CHART_FAIL')

export const requestVariations = createAction(
  'CHARTS_SEARCH_REQUEST_VARIATIONS'
)
export const requestVariationsSuccess = createAction(
  'CHARTS_SEARCH_REQUEST_VARIATIONS_SUCCESS'
)
export const requestVariationsFail = createAction(
  'CHARTS_SEARCH_REQUEST_VARIATIONS_FAIL'
)

export const updateConfigRequest = createAction(
  'CHARTS_SEARCH_UPDATE_CONFIG_REQUEST'
)
export const updateConfigRequestSuccess = createAction(
  'CHARTS_SEARCH_UPDATE_CONFIG_REQUEST_SUCCESS'
)
export const updateConfigRequestFail = createAction(
  'CHARTS_SEARCH_UPDATE_CONFIG_REQUEST_FAIL'
)
export const setChartCreated = createAction('CHARTS_SEARCH_SET_CHART_CREATED')

export const actions = {
  setFormState,
  setChartState,
  requestChartsSearch,
  requestChartsSearchSuccess,
  requestChartsSearchFail,
  requestIndividualConfig,
  requestIndividualConfigSuccess,
  requestIndividualConfigFail,
  requestSingleChart,
  requestSingleChartSuccess,
  requestSingleChartFail,
  deleteChart,
  deleteChartSuccess,
  deleteChartFail,
  requestVariations,
  requestVariationsSuccess,
  requestVariationsFail,
  updateConfigRequest,
  updateConfigRequestSuccess,
  updateConfigRequestFail,
  setChartCreated
}

const chartsSearchReducer = createReducer(
  {
    formState: {
      chartId: '',
      title: '',
      tooltip: '',
      enable: null,
      orderBy: 'chartId',
      order: 'asc',
      page: 1,
      perPage: 20
    },
    isFetchig: true,
    data: [],
    pagination: {
      total: 0,
      filtered: 0,
      maxPage: 0,
      results: 0,
      page: 1,
      perPage: 20
    },
    configFetching: false,
    individualConfig: {},
    chartFetching: false,
    chartState: {
      chartId: '',
      title: '',
      tooltip: '',
      enable: null,
      countries: [],
      workflows: [],
      professions: []
    },
    chartDeleting: false,
    variationsFetching: false,
    variationWorkflows: [],
    variationProfessions: [],
    variationCountries: [],
    isUpdating: false,
    chartCreated: false
  },
  {
    [setFormState]: (state, { payload }) => {
      state.formState = { ...state.formState, ...payload }
    },
    [setChartState]: (state, { payload }) => {
      state.chartState = { ...state.chartState, ...payload }
    },
    [requestChartsSearch]: state => {
      state.isFetchig = true
    },
    [requestChartsSearchSuccess]: (state, { payload }) => {
      state.isFetchig = false
      state.data = payload.data
      state.pagination = payload.pagination
    },
    [requestChartsSearchFail]: state => {
      state.isFetchig = false
    },
    [requestIndividualConfig]: state => {
      state.configFetching = true
    },
    [requestIndividualConfigSuccess]: (state, { payload }) => {
      state.individualConfig = payload
      state.configFetching = false
    },
    [requestIndividualConfigFail]: state => {
      state.configFetching = false
    },
    [requestSingleChart]: state => {
      state.chartFetching = true
    },
    [requestSingleChartSuccess]: (state, { payload }) => {
      state.chartState = payload.chartState
      state.chartFetching = false
    },
    [requestSingleChartFail]: state => {
      state.chartFetching = false
    },
    [deleteChart]: state => {
      state.chartDeleting = true
    },
    [deleteChartSuccess]: state => {
      state.chartDeleting = false
    },
    [deleteChartFail]: state => {
      state.chartDeleting = false
    },
    [requestVariations]: state => {
      state.variationsFetching = true
    },
    [requestVariationsSuccess]: (state, { payload }) => {
      state.variationsFetching = false
      state.variationWorkflows = payload.workflows
      state.variationProfessions = payload.professions
      state.variationCountries = payload.countries
    },
    [requestVariationsFail]: state => {
      state.variationsFetching = false
    },
    [updateConfigRequest]: state => {
      state.isUpdating = true
    },
    [updateConfigRequestSuccess]: state => {
      state.isUpdating = false
      state.chartCreated = true
    },
    [updateConfigRequestFail]: state => {
      state.isUpdating = false
    },
    [setChartCreated]: (state, { payload }) => {
      state.chartCreated = payload
    }
  }
)

export default chartsSearchReducer
