import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as exploreMarketActions } from './ExploreMarketReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as trackActions } from 'modules/tracking/TrackingReducer'
import history from '../../history'
import { initialValues } from './config'
import { LOCATION_CHANGE } from 'connected-react-router'

/**
 * Request submit form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitFormRequest(services, { payload }) {
  const SmartsService = services('SmartsService')

  try {
    const {
      combinationsCountry,
      combinationsProfession,
      combinationsSpecialty,
      combinations,
      filters,
      type
    } = payload

    yield put(trackActions.trackSubmitAction({}))

    // Build Combinations data
    let combinationsData = []
    if (
      combinationsCountry === '' &&
      combinationsProfession === '' &&
      combinationsSpecialty === ''
    ) {
      combinationsData = [...combinations]
    } else {
      combinationsData = [
        ...combinations,
        {
          id: combinations.length + 1,
          country: combinationsCountry,
          profession: combinationsProfession,
          specialty: combinationsSpecialty || undefined
        }
      ]
    }

    // Call endpoint
    const { id: uuid } = yield call([SmartsService, 'listsCreate'], {
      workflow: 'ExploreMarket',
      parameters: null,
      combinations: combinationsData,
      filters
    })

    const service =
      combinationsData.length > 1
        ? 'listsBreakdownChartsGeneration'
        : 'listsChartsGeneration'

    // Call Charts endpoint to get universe/list size
    const {
      universe,
      listSize,
      charts,
      tableCombinations,
      _totalMembersCount,
      _totalUniversesCount
    } = yield call([SmartsService, service], uuid)

    yield put(
      exploreMarketActions.setFormState({
        ...payload,
        listId: uuid,
        activeStep: type === 'graph' ? 'charts' : 'project-metadata',
        combinationsCountry: '',
        combinationsProfession: '',
        combinationsSpecialty: '',
        combinations: combinationsData,
        tableCombinations,
        chartsData: charts,
        universe,
        listSize,
        _totalMembersCount,
        _totalUniversesCount
      })
    )

    yield put(exploreMarketActions.submitFormRequestSuccess())
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(exploreMarketActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request project metadata form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* projectMetadataRequest(services, payload) {
  const SmartsService = services('SmartsService')
  const {
    listId: uuid,
    domain,
    clientProduct,
    brand,
    client,
    masterProject,
    project,
    notes,
    defaultCountry,
    reportName,
    type: action
  } = payload

  let projectMetadataPayload = {
    uuid,
    domain,
    clientProduct,
    brand: brand === '' ? [] : [brand],
    client,
    masterProject,
    project,
    notes,
    defaultCountry,
    tags: '',
    action,
    reportName
  }

  // Set specific payload values when domain is 'FW'
  if (domain === 'FW') {
    projectMetadataPayload = {
      ...projectMetadataPayload,
      client: null,
      project: null,
      masterProject: -1,
      clientProduct: null
    }
  }

  // Call endpoint
  yield call([SmartsService, 'setProjectMetadata'], projectMetadataPayload)
}

/**
 * Request list validation & submit
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitExploreMarketRequest(services, payload) {
  const SmartsService = services('SmartsService')
  const { listId: uuid, type } = payload

  // Validate List
  yield call([SmartsService, 'validateList'], { uuid })
  // Submit List
  const { message } = yield call([SmartsService, 'submitList'], { uuid, type })
  yield put(
    notificationActions.notificationEnqueue({
      message,
      duration: 0
    })
  )
}

/**
 * Request run analysis
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* completeExploreMarketRequest(services, { payload }) {
  try {
    yield put(trackActions.trackSubmitAction({}))
    // Submit Project Metadata
    yield call(projectMetadataRequest, services, payload)

    // Submit explore market
    yield call(submitExploreMarketRequest, services, payload)

    yield put(
      notificationActions.notificationEnqueue({
        message: 'Your request has been submitted successfully',
        duration: 0
      })
    )
    yield put(exploreMarketActions.submitFormRequestSuccess())
    yield call([history, history.replace], { pathname: '/' })
    yield put(
      exploreMarketActions.setFormState({
        ...initialValues
      })
    )
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(exploreMarketActions.submitFormRequestFail())
    console.error(e)
  }
}

function* resetForm(services, { payload }) {
  const { action } = payload

  if (action === 'REPLACE') {
    yield put(exploreMarketActions.setFormState({ ...initialValues }))
  }
}

export default function* watchExploreMarketRequest(services) {
  yield all([
    takeEvery(
      'EXPLORE_MARKET_SUBMIT_FORM_REQUEST',
      submitFormRequest,
      services
    ),
    takeEvery(
      'EXPLORE_MARKET_COMPLETE_REQUEST',
      completeExploreMarketRequest,
      services
    ),
    takeEvery(LOCATION_CHANGE, resetForm, services)
  ])
}
