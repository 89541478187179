import PropTypes from 'prop-types'
import { FormLabel, FormGroup } from '@material-ui/core'
import { Checkbox } from 'components/Checkbox'
import Tooltip from 'components/Tooltip'
import styles from './Roles.module.sass'
import siteConfig from 'site.config.json'

const {
  admin: { rolesDisplay },
  menuItems: { links }
} = siteConfig

const Roles = ({
  handleRoleChange,
  className,
  formProps,
  rolesAttributes,
  disabled = false
}) => {
  const tooltipScope = roleName => {
    const routes = rolesAttributes[roleName].permissions.routes
    let scopes = '<h3>Scopes:</h3><ul>'
    routes.forEach(route => {
      const titleObject = links.find(element => element.link === route)
      if (titleObject !== undefined) {
        scopes += `<li>${titleObject.title}</li>`
      }
    })
    scopes += '</ul>'
    return scopes
  }

  const possibleRoles = Object.keys(rolesAttributes).sort((a, b) =>
    a.localeCompare(b)
  )
  // Build Role groups
  let roles = [...possibleRoles]
  // removes default role if found
  if (roles.includes('default')) {
    roles = roles.filter(item => item !== 'default')
  }

  return (
    <div className={className ?? styles.rolesContainer}>
      {rolesDisplay.map(groupObject => (
        <div key={groupObject.group}>
          <FormLabel component="legend">{groupObject.group}</FormLabel>
          <FormGroup>
            {groupObject.roles.map(
              roleObject =>
                roles.includes(roleObject.key) && (
                  <div key={roleObject.key} className={styles.roleCheckbox}>
                    <Checkbox
                      isRadio
                      name={roleObject.key}
                      label={roleObject.caption}
                      defaultChecked={true}
                      value={formProps.values.roles.includes(roleObject.key)}
                      onChange={e => {
                        handleRoleChange(e, roleObject.key, formProps)
                      }}
                      disabled={disabled}
                    />
                    <Tooltip
                      tip={tooltipScope(roleObject.key)}
                      className={styles.tooltip}
                    />
                  </div>
                )
            )}
          </FormGroup>
        </div>
      ))}
    </div>
  )
}

Roles.propTypes = {
  /**
   * Required: Object of roles to show (they have to be in the site.config file too)
   */
  rolesAttributes: PropTypes.object,
  /**
   * Required: To toggle the checkboxes
   */
  handleRoleChange: PropTypes.func.isRequired,
  /**
   * Optional: To add style
   */
  className: PropTypes.string,
  /**
   * Required: for the checkbox value (true, false)
   */
  formProps: PropTypes.object,
  /**
   * Disable checkbox inputs, default to false
   */
  disabled: PropTypes.bool
}

export default Roles
