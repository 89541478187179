import SvgIcon from '@mui/material/SvgIcon'

const BroomIcon = props => (
  <SvgIcon viewBox="0 0 14 18" {...props}>
    <g
      clipPath="url(#vcmdo339qa)"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#B3B8DB"
    >
      <path d="M13.02.8a.57.57 0 0 0-.761.256L8.263 9.092l-.076-.037a2.545 2.545 0 0 0-3.394 1.143l5.718 2.788a2.5 2.5 0 0 0-1.154-3.36l-.075-.037 3.996-8.036A.56.56 0 0 0 13.019.8zM.807 13.466l4.767 2.383c.76-.276 1.388-.723 1.844-1.397.127.83-.127 1.477-.753 1.943l1.728.863 1.81-3.731-5.636-2.818c-.916 1.765-2.17 2.685-3.76 2.757z" />
      <path d="M7.331 6.416c.04.081.07.133.088.157a.47.47 0 0 0 .086.085c.036.028.077.049.16.09l.246.123-.244.122-.006.003c-.08.04-.133.07-.156.088a.47.47 0 0 0-.086.086.992.992 0 0 0-.09.16l-.123.246-.122-.244-.001-.003a1.198 1.198 0 0 0-.09-.16.47.47 0 0 0-.085-.085c-.036-.027-.078-.048-.16-.09L6.5 6.871l.244-.122.003-.001c.083-.041.136-.071.16-.09a.47.47 0 0 0 .085-.085.992.992 0 0 0 .09-.16l.123-.247.122.244.003.006zM4.737 7.204c.043.114.076.186.1.218a.47.47 0 0 0 .11.11c.048.035.105.057.22.101l.48.185-.478.184-.003.001a1.219 1.219 0 0 0-.22.1.47.47 0 0 0-.11.11 1.01 1.01 0 0 0-.1.221l-.185.48-.184-.478a.47.47 0 0 0-.212-.333 1.01 1.01 0 0 0-.22-.1l-.48-.185.478-.184.004-.002c.114-.043.186-.076.218-.1a.47.47 0 0 0 .11-.11 1.01 1.01 0 0 0 .101-.22l.185-.48.184.478c0 .001 0 .003.002.004z" />
    </g>
    <defs>
      <clipPath id="vcmdo339qa">
        <path
          fill="#fff"
          transform="translate(.807 .742)"
          d="M0 0h12.903v16.516H0z"
        />
      </clipPath>
    </defs>
  </SvgIcon>
)
export default BroomIcon
