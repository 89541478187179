import * as Yup from 'yup'
import { isEmpty } from 'utils'

const validationSchema = {
  // Project Form
  project: Yup.object().shape({
    projects: Yup.array().when('masterProjects', {
      is: val => isEmpty(val),
      then: schema =>
        schema.min(1, 'Project ID or Master Project ID is required')
    }),
    // This causes cycling dependency error
    // masterProjects: Yup.array().when('projects', {
    //   is: val => isEmpty(val),
    //   then: schema => schema.min(1, 'Master Project ID is required')
    // }),
    startDate: Yup.date()
      .typeError('Start Date is required')
      .required('Start Date is required'),
    endDate: Yup.date()
      .typeError('End Date is required')
      .required('End Date is required')
      .when('startDate', {
        is: val => val,
        then: Yup.date()
          .min(Yup.ref('startDate'), "End Date can't be before Start Date")
          .required('End Date is required')
      }),
    engagementType: Yup.string().required('Engagement Type is required')
  }),
  'list-selection': Yup.object().shape({
    ids: Yup.array().of(Yup.string().required('Required')).min(1)
  })
}

export default validationSchema
