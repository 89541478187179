import { all, call, put, takeEvery, select } from 'redux-saga/effects'
import { actions as usersSearchActions } from './UsersSearchReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { getFormState } from './UsersSearchSelector'

function* possibleRolesRequest(services) {
  const AdminService = services('AdminService')

  try {
    const { attributes = {} } = yield call([AdminService, 'getPossibleRoles'])

    yield put(usersSearchActions.setPossibleRoles({ attributes }))
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to fetch possible roles for Users',
        duration: 0
      })
    )
  }
}

/**
 * Request incoming counts search
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* searchRequest(services, { payload }) {
  const AdminService = services('AdminService')
  try {
    const {
      name = '',
      email = '',
      uuid = '',
      roles = [],
      status = null,
      perPage = 20,
      page,
      pageLastEmails,
      newSearch
    } = payload.search

    let statusToSend
    if (status === 'any' || status === null) {
      statusToSend = null
    } else {
      statusToSend = status === 'active'
    }

    const pageLastEmailsToSend = newSearch ? '' : pageLastEmails[page - 1] // Send empty string if new search

    const { data, pagination } = yield call([AdminService, 'searchUsers'], {
      name,
      email,
      uuid,
      status: statusToSend,
      roles,
      pageLastEmail: pageLastEmailsToSend, // Custom page support
      perPage
    })
    yield put(usersSearchActions.searchRequestSuccess({ data, pagination }))
  } catch (e) {
    yield put(usersSearchActions.searchRequestFail())
    console.error(e)
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
  }
}

/**
 * Request user invalidation
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* invalidateUser(services, { payload }) {
  const AdminService = services('AdminService')
  const searchState = yield select(getFormState())
  try {
    const { email } = payload
    const { message } = yield call([AdminService, 'userInvalidate'], email)
    yield put(usersSearchActions.invalidateUserRequestSuccess())
    yield put(usersSearchActions.searchRequest({ search: searchState }))
    yield put(
      notificationActions.notificationEnqueue({
        message,
        duration: 0
      })
    )
  } catch (e) {
    yield put(usersSearchActions.invalidateUserRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: e?.message || 'We had an issue processing your request',
        duration: 0
      })
    )
  }
}

function* fetchUser(services, { payload }) {
  const UsersCreateService = services('UsersCreateService')

  try {
    // Clean the previous user
    yield put(
      usersSearchActions.setUser({
        city: '',
        country: '',
        province: '',
        company: '',
        postalCode: '',
        jobTitle: '',
        jobFunction: '',
        telephone: '',
        firstName: '',
        lastName: '',
        created: 0,
        email: '',
        roles: [],
        status: '',
        updated: 0,
        displayNames: []
      })
    )
    const { id } = payload
    const { info, ...rest } = yield call([UsersCreateService, 'fetchUser'], id)
    yield put(usersSearchActions.setUser({ ...info, ...rest }))
    yield put(usersSearchActions.fetchUserRequestSuccess())
  } catch (e) {
    yield put(usersSearchActions.fetchUserRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to fetch user.',
        duration: 0
      })
    )
  }
}

export default function* watchUsersSearch(services) {
  yield all([
    takeEvery('USERS_SEARCH_POSSIBLE_ROLES', possibleRolesRequest, services),
    takeEvery('USERS_SEARCH_REQUEST', searchRequest, services),
    takeEvery('USERS_SEARCH_INVALIDATE_USER_REQUEST', invalidateUser, services),
    takeEvery('USERS_SEARCH_FETCH_REQUEST', fetchUser, services)
  ])
}
