import * as Yup from 'yup'

const validationSchema = {
  // Export List Form
  'export-list': Yup.object().shape({
    ids: Yup.array().of(Yup.string().required('List ID is required')),
    brand: Yup.string().required('Brand is required'),
    maconomyNumber: Yup.number().when('exportToHopper', {
      is: val => val === 'Yes',
      then: schema => schema.required('Maconomy Number is required')
    }),
    lengthOfInterview: Yup.number().when('exportToHopper', {
      is: val => val === 'Yes',
      then: schema => schema.required('Loi is required')
    })
  }),
  // Create List Form
  'create-list': Yup.object().shape({
    file: Yup.mixed().required('File is required'),
    brand: Yup.string().required('Brand is required'),
    maconomyNumber: Yup.number().required('Maconomy Number is required'),
    lengthOfInterview: Yup.number().required('Length of Interview is required')
  })
}

export default validationSchema
