import { createReducer, createAction } from '@reduxjs/toolkit'

export const profileRequest = createAction('PROFILE_REQUEST')
export const profileInitRequest = createAction('PROFILE_INIT_REQUEST')
export const profileSuccess = createAction('PROFILE_REQUEST_SUCCESS')
export const profileFail = createAction('PROFILE_REQUEST_FAIL')
export const profileReset = createAction('PROFILE_REQUEST_RESET')

export const actions = {
  profileRequest,
  profileInitRequest,
  profileSuccess,
  profileFail,
  profileReset
}

const initialState = { profile: [], fetching: false, listStatus: false }

const advisorProfileReducer = createReducer(initialState, {
  [profileRequest]: (state, { payload: { memberId } }) => {
    state.memberId = memberId
    state.fetching = true
    state.listStatus = false
    state.profile = []
  },
  [profileInitRequest]: state => {
    state.fetching = true
    state.listStatus = false
    state.profile = []
  },
  [profileSuccess]: (state, { payload: { profile } }) => {
    state.fetching = false
    state.listStatus = true
    state.profile = profile
  },
  [profileFail]: state => {
    state.fetching = false
    state.listStatus = false
    state.profile = []
  },
  [profileReset]: state => {
    state.fetching = false
    state.listStatus = false
    state.profile = []
  }
})

export default advisorProfileReducer
