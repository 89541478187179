import { createReducer, createAction } from '@reduxjs/toolkit'

export const loginWithEmailRequest = createAction('LOGIN_WITH_EMAIL_REQUEST')
export const loginWithEmailRequestSuccess = createAction(
  'LOGIN_WITH_EMAIL_REQUEST_SUCCESS'
)
export const loginWithEmailRequestFail = createAction(
  'LOGIN_WITH_EMAIL_REQUEST_FAIL'
)

export const actions = {
  loginWithEmailRequest,
  loginWithEmailRequestSuccess,
  loginWithEmailRequestFail
}
const loginWithEmailReducer = createReducer(
  { fetching: false },
  {
    [loginWithEmailRequest]: state => {
      state.fetching = true
    },
    [loginWithEmailRequestSuccess]: state => {
      state.fetching = false
    },
    [loginWithEmailRequestFail]: state => {
      state.fetching = false
    }
  }
)

export default loginWithEmailReducer
