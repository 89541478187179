import { Component, forwardRef, createRef } from 'react'
import PropTypes from 'prop-types'
import Typography, { typographyTypes } from './Typography'
import Divider from '../Divider'

export const TypographyChild = forwardRef(({ type, ...props }, ref) => (
  <div ref={ref}>
    <Typography type={type} {...props}>
      {type}
    </Typography>
  </div>
))
TypographyChild.propTypes = {
  type: PropTypes.string
}
TypographyChild.displayName = 'TypographyChild'

export class TypographyStyles extends Component {
  static propTypes = {
    type: PropTypes.string
  }
  typeRef = createRef()
  state = {
    color: '',
    weight: '',
    size: '',
    transform: '',
    tag: '',
    type: '',
    styles: {},
    family: ''
  }
  componentDidMount() {
    // Run setStyles if load has already fired when viewing another story
    this.setStyles()
    // If Typography story is active when load event fires, we run setStyles
    window.addEventListener('load', this.setStyles)
  }
  componentWillUnmount() {
    window.removeEventListener('load', this.setStyles)
  }
  setStyles = () => {
    const { styles } = this.state
    const { type } = this.props
    const domNode = this.typeRef.current.children[0]
    const computedStyles = window.getComputedStyle(domNode)
    const tag = domNode.tagName.toLowerCase()
    const color = computedStyles.color
    const weight = computedStyles.fontWeight
    const size = computedStyles.fontSize
    const transform = computedStyles.textTransform
    const family = computedStyles.fontFamily
    const style = computedStyles.fontStyle
    const lineHeight = computedStyles.lineHeight
    this.setState({
      styles: {
        ...styles,
        [type]: {
          tag,
          size,
          weight,
          color,
          transform,
          family,
          style,
          lineHeight
        }
      }
    })
  }
  render() {
    const { type, ...props } = this.props
    const { styles } = this.state
    return (
      <>
        <TypographyChild
          style={{ marginTop: '30px' }}
          ref={this.typeRef}
          type={type}
          {...props}
        />
        {styles[type] && (
          <pre style={{ marginBottom: '45px' }}>
            Color: <strong>{styles[type].color}</strong>
            <br />
            Font family: <strong>{styles[type].family}</strong>
            <br />
            Font size: <strong>{styles[type].size}</strong>
            <br />
            Font style: <strong>{styles[type].style}</strong>
            <br />
            Weight: <strong>{styles[type].weight}</strong>
            <br />
            Line height: <strong>{styles[type].lineHeight}</strong>
            <br />
            Text transform: <strong>{styles[type].transform}</strong>
            <br />
            Element: <strong>&lt;{styles[type].tag}&gt;</strong>
          </pre>
        )}
        <Divider />
      </>
    )
  }
}

const TypographyDemo = ({ types = typographyTypes }) => (
  <div style={{ margin: 10 }}>
    {types.map(type => (
      <TypographyStyles key={type} type={type} />
    ))}
  </div>
)
TypographyDemo.displayName = 'TypographyDemo'
TypographyDemo.propTypes = {
  types: PropTypes.array
}

export default TypographyDemo
