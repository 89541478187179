import { createReducer, createAction } from '@reduxjs/toolkit'

export const setFormState = createAction('USERS_SEARCH_SET_FORM_STATE')
export const requestPossibleRoles = createAction('USERS_SEARCH_POSSIBLE_ROLES')
export const setPossibleRoles = createAction('USERS_SEARCH_SET_POSSIBLE_ROLES')
export const searchRequest = createAction('USERS_SEARCH_REQUEST')
export const searchRequestSuccess = createAction('USERS_SEARCH_REQUEST_SUCCESS')
export const searchRequestFail = createAction('USERS_SEARCH_REQUEST_FAIL')
export const completeSubmit = createAction('USERS_SEARCH_SUBMIT_COMPLETED')
export const setSelected = createAction('USERS_SEARCH_SET_SELECTED')
export const setModalOpen = createAction('USERS_SEARCH_SET_MODAL')
export const invalidateUserRequest = createAction(
  'USERS_SEARCH_INVALIDATE_USER_REQUEST'
)
export const invalidateUserRequestSuccess = createAction(
  'USERS_SEARCH_INVALIDATE_USER_REQUEST_SUCCESS'
)
export const invalidateUserRequestFail = createAction(
  'USERS_SEARCH_INVALIDATE_USER_REQUEST_FAIL'
)
export const fetchUserRequest = createAction('USERS_SEARCH_FETCH_REQUEST')
export const fetchUserRequestSuccess = createAction(
  'USERS_SEARCH_FETCH_REQUEST_SUCCESS'
)
export const fetchUserRequestFail = createAction(
  'USERS_SEARCH_FETCH_REQUEST_FAIL'
)
export const setUser = createAction('USERS_SEARCH_SET_USER')

export const actions = {
  setFormState,
  requestPossibleRoles,
  setPossibleRoles,
  searchRequest,
  searchRequestSuccess,
  searchRequestFail,
  completeSubmit,
  setSelected,
  setModalOpen,
  invalidateUserRequest,
  invalidateUserRequestSuccess,
  invalidateUserRequestFail,
  fetchUserRequest,
  fetchUserRequestSuccess,
  fetchUserRequestFail,
  setUser
}

const usersSearchReducer = createReducer(
  {
    formState: {
      name: '',
      email: '',
      uuid: '',
      status: null,
      roles: [],
      page: 1,
      perPage: 20,
      pageLastEmails: [''],
      newSearch: false
    },
    rolesFetching: true,
    dataFetching: false,
    rolesAttributes: {},
    submitting: false,
    data: {},
    pagination: {
      filtered: 0,
      maxPage: 0,
      page: 1,
      perPage: 20,
      results: 0,
      total: 0
    },
    selected: {
      id: null,
      email: ''
    },
    modalOpen: false,
    invalidateFetching: false,
    userFetching: false,
    user: {
      city: '',
      country: '',
      firstName: '',
      lastName: '',
      created: 0,
      email: '',
      roles: [],
      status: '',
      updated: 0,
      displayNames: ['']
    }
  },
  {
    [setFormState]: (state, { payload }) => {
      state.formState = { ...state.formState, ...payload }
    },
    [requestPossibleRoles]: state => {
      state.rolesFetching = true
    },
    [setPossibleRoles]: (state, { payload: { attributes = {} } }) => {
      state.rolesFetching = false
      state.rolesAttributes = attributes
    },
    [searchRequest]: (state, { payload }) => {
      state.formState = payload.search || state.searchState
      state.dataFetching = true
    },
    [searchRequestSuccess]: (state, { payload }) => {
      state.dataFetching = false
      state.data = payload.data
      state.pagination = payload.pagination
    },
    [searchRequestFail]: state => {
      state.dataFetching = false
      state.data = { ...state.data }
      state.pagination = { ...state.pagination }
    },
    [completeSubmit]: state => {
      state.dataFetching = false
    },
    [setSelected]: (state, { payload }) => {
      state.selected.id = payload.id
      state.selected.email = payload.email
    },
    [setModalOpen]: (state, { payload }) => {
      state.modalOpen = payload.modalOpen
    },
    [invalidateUserRequest]: state => {
      state.invalidateFetching = true
      state.modalOpen = true
    },
    [invalidateUserRequestSuccess]: state => {
      state.invalidateFetching = false
      state.modalOpen = false
    },
    [invalidateUserRequestFail]: state => {
      state.invalidateFetching = false
    },
    [fetchUserRequest]: state => {
      state.userFetching = true
    },
    [fetchUserRequestSuccess]: state => {
      state.userFetching = false
    },
    [fetchUserRequestFail]: state => {
      state.userFetching = false
    },
    [setUser]: (state, { payload }) => {
      state.user = { ...state.user, ...payload }
    }
  }
)

export default usersSearchReducer
