import { useState } from 'react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Card from 'components/Admin/Card/Card'
import Typography from 'components/ui/Typography'
import FileInput from 'components/FileInput/FileInput'
import styles from './form.module.sass'
import Button from 'components/Admin/Button'
import ConfirmationModal from 'components/Admin/ConfirmationModal'
import { uploadRequest } from '../UsersBulkReducer'
import * as usersBulkSelector from '../UsersBulkSelector'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)
const { getIsFetching, getRequestType } = usersBulkSelector

const FileForm = () => {
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const isFormFetching = useSelector(getIsFetching())
  const requestType = useSelector(getRequestType())

  const handleModalOpen = () => {
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleSubmit = values => {
    dispatch(uploadRequest(values))
    setModalOpen(false)
  }
  return (
    <div
      data-testid="adminBulkCreateUsers-container"
      className={styles.container}
    >
      <Card className={cx(styles.card, styles.fileCard)}>
        <Typography
          type="title"
          className={styles.cardTitle}
          color="text-primary"
        >
          Upload File
        </Typography>
        <span className={styles.cardDescription}>
          Please ensure file only contains below 250 User records per upload.
        </span>
        <Formik initialValues={{ file: '' }} onSubmit={handleSubmit}>
          {formProps => (
            <Form>
              <FileInput
                id="usersFile"
                name="file"
                label="Select File"
                traditional={false}
                multiple={false}
                accept=".csv"
              />
              <div className={styles.actionContainer}>
                <Button
                  data-testid="adminCreateBulkUsers-upload"
                  className={styles.uploadButton}
                  text="Upload"
                  textCase="none"
                  disabled={!formProps.values.file || isFormFetching}
                  submitting={isFormFetching && requestType === 'upload'}
                  type="button"
                  hasSpinner
                  onClick={handleModalOpen}
                />
              </div>
              <ConfirmationModal
                message="Are you sure you want to create users using the selected csv file?"
                open={modalOpen}
                onConfirm={() => handleSubmit(formProps.values)}
                onCancel={handleModalClose}
                actionType="submit"
                actionCaption="Confirm"
              />
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  )
}

export default FileForm
