import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import SessionService from 'services/SessionService'
import siteConfig from 'site.config.json'

const { configuration, sections } = siteConfig

const metaByPath = (path, metaTitle) => {
  if (path.includes(`/article`)) {
    const title = new SessionService().getFromCache('article_title', '')
    return {
      title: metaTitle + (title !== '' ? ` - ${title}` : ''),
      desc: metaTitle
    }
  } else {
    return {
      title: metaTitle,
      desc: metaTitle
    }
  }
}

export const Title = ({ children }) => {
  const { meta } = sections
  const history = useHistory()
  const [titleLocation, setTitleLocation] = useState(
    metaByPath(history.location.pathname, meta.title)
  )
  useEffect(() => {
    const unlisten = history.listen(location =>
      setTitleLocation(metaByPath(location.pathname, meta.title))
    )
    return () => {
      if (unlisten != null) {
        unlisten()
      }
    }
  }, [meta.title, history])

  return (
    <>
      <Helmet titleTemplate={titleLocation.title} defaultTitle={meta.title}>
        <title>{titleLocation.title}</title>
        <meta name="og:title" content={meta.title} />
        <meta name="og:description" content={meta.title} />
        <link rel="icon" href={configuration.assets.favicon} />
        <link
          rel="apple-touch-icon"
          href={configuration.assets.limaSmartsBetaAppleTouchIcon29x29}
          type="image/png"
        />
        <link
          rel="apple-touch-icon"
          href={configuration.assets.limaSmartsBetaAppleTouchIcon50x50}
          type="image/png"
        />
        <link
          rel="apple-touch-icon"
          href={configuration.assets.limaSmartsBetaAppleTouchIcon57x57}
          type="image/png"
        />
        <link
          rel="apple-touch-icon"
          href={configuration.assets.limaSmartsBetaAppleTouchIcon60x60}
          type="image/png"
        />
        <link
          rel="apple-touch-icon"
          href={configuration.assets.limaSmartsBetaAppleTouchIcon60x60x2}
          type="image/png"
        />
        <link
          rel="apple-touch-icon"
          href={configuration.assets.limaSmartsBetaAppleTouchIcon60x60x3}
          type="image/png"
        />
        <link
          rel="apple-touch-icon"
          href={configuration.assets.limaSmartsBetaAppleTouchIcon72x72}
          type="image/png"
        />
        <link
          rel="apple-touch-icon"
          href={configuration.assets.limaSmartsBetaAppleTouchIcon72x72}
          type="image/png"
        />
        <link
          rel="apple-touch-icon"
          href={configuration.assets.limaSmartsBetaAppleTouchIcon72x72x2}
          type="image/png"
        />
        <link
          rel="apple-touch-icon"
          href={configuration.assets.limaSmartsBetaAppleTouchIcon76x76}
          type="image/png"
        />
        <link
          rel="apple-touch-icon"
          href={configuration.assets.limaSmartsBetaAppleTouchIcon76x76x2}
          type="image/png"
        />
        <meta />
      </Helmet>
      {children}
    </>
  )
}
Title.propTypes = {
  children: PropTypes.node
}

export default Title
