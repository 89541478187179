import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { isEmpty } from 'utils'
import { Button } from 'components/ui/Button'
import Typography from 'components/ui/Typography'
import Avatar from 'components/Avatar'
import Page from 'components/Page'
import styles from './AdvisorList.module.sass'
import * as advisorSearchSelectors from '../formAdvisorSearch/AdvisorSearchSelector'
import useRoleFeatures from 'modules/auth/useRoleFeatures'
import { actions as trackActions } from 'modules/tracking/TrackingReducer'
import classNames from 'classnames/bind'
import siteConfig from 'site.config.json'

const cx = classNames.bind(styles)

const { getAdvisorList } = advisorSearchSelectors

const { advisorList: advisorListConfig } = siteConfig

const AdvisorList = () => {
  const paginationDisplay = advisorListConfig.paginationDisplay
  const advisorList = useSelector(getAdvisorList())
  const { features } = useRoleFeatures({
    coin: 'lima-bureau-advisor'
  })
  const roleSchema = features?.sections?.fields || []
  const listLength = !isEmpty(advisorList) ? advisorList.length : 0
  const [initialListCount, setInitialListCount] = useState(paginationDisplay)
  const increaseInitialCount = () => {
    setInitialListCount(initialListCount + paginationDisplay)
  }
  const resetInitialCount = () => {
    setInitialListCount(paginationDisplay)
  }
  const RenderProfileList = () => {
    // fail safe
    if (listLength <= 0) {
      return (
        <Typography type={'primary'}>
          No advisors found, please update your search criteria
        </Typography>
      )
    }
    if (listLength > initialListCount) {
      return (
        <>
          {advisorList.slice(0, initialListCount).map((profile, i) => (
            <ProfileCard key={i} profile={profile} roleSchema={roleSchema} />
          ))}
          <div className={styles.showMoreButtonContainer}>
            <div>
              <Button className={styles.button} onClick={increaseInitialCount}>
                show{' '}
                {listLength - initialListCount > paginationDisplay
                  ? paginationDisplay
                  : Math.abs(listLength - initialListCount)}{' '}
                more
              </Button>
            </div>
          </div>
        </>
      )
    } else if (listLength < paginationDisplay) {
      return (
        <>
          {advisorList.map((profile, i) => (
            <ProfileCard key={i} profile={profile} roleSchema={roleSchema} />
          ))}
        </>
      )
    } else {
      return (
        <>
          {advisorList.slice(0, initialListCount).map((profile, i) => (
            <ProfileCard key={i} profile={profile} roleSchema={roleSchema} />
          ))}
          <div className={styles.showMoreButtonContainer}>
            <div>
              <Button className={styles.button} onClick={resetInitialCount}>
                collapse list
              </Button>
            </div>
          </div>
        </>
      )
    }
  }
  return (
    <>
      {isEmpty(advisorList) ? (
        <Page
          heroTitle={'No advisors found, please update your search criteria'}
          showBackIcon={true}
          backIconLink={'/lima-bureau-advisor'}
          showHero={true}
          showDivider={true}
          showFooter={false}
          isLimaBureau
        >
          <Typography
            type={'link'}
            component={Link}
            to={'/lima-bureau-advisor'}
          >
            Back to Search Form
          </Typography>
        </Page>
      ) : (
        <Page
          heroTitle={`${listLength} Advisors Found - Results are sorted by most relevant first`}
          showHero={true}
          showDivider={true}
          showBackIcon={true}
          backIconLink={'/lima-bureau-advisor'}
          showFooter={false}
          isLimaBureau
        >
          <div className={styles.sectionContainer}>
            <RenderProfileList />
          </div>
        </Page>
      )}
    </>
  )
}

const ProfileCard = ({ profile, roleSchema }) => {
  const dispatch = useDispatch()
  const scheduleLink = `/lima-bureau-advisor/schedule/${profile.hashedPartyId}`
  const profileLink = `/lima-bureau-advisor/profile/${profile.hashedPartyId}`
  const avatarName = `Dr. ${
    isEmpty(profile.firstName) ? '' : profile.firstName
  } ${isEmpty(profile.lastName) ? '' : profile.lastName}`
  return (
    <div className={styles.item}>
      <div className={styles.itemContent}>
        <div className={styles.itemTitle}>
          {roleSchema.includes('name') && !isEmpty(profile.fullName) && (
            <Typography
              className={styles.truncateOneLine}
              type={'subheading'}
              fontFamily="font-secondary"
            >
              {profile.fullName}
            </Typography>
          )}
          {roleSchema.includes('credential') &&
            !isEmpty(profile.credentials) && (
              <Typography
                className={styles.truncateOneLine}
                type={'subheading'}
              >
                {profile.credentials}
              </Typography>
            )}
        </div>
        {roleSchema.includes('specialty') && !isEmpty(profile.specialties) && (
          <Typography
            className={cx(styles.truncateTwoLines, styles.itemText)}
            type={'body1'}
          >
            {profile.specialties}
          </Typography>
        )}
        {roleSchema.includes('workplaceName') &&
          !isEmpty(profile.workplaceName) && (
            <Typography
              className={cx(styles.truncateOneLine, styles.itemText)}
              type={'body1'}
            >
              {profile.workplaceName}
            </Typography>
          )}
        {roleSchema.includes('workplaceAddress') &&
          !isEmpty(profile.workplaceAddress) && (
            <Typography
              className={cx(styles.truncateTwoLines, styles.itemText)}
              type={'body1'}
            >
              {profile.workplaceAddress}
            </Typography>
          )}
      </div>
      {roleSchema.includes('picture') && (
        <Avatar headShot={profile.headShot} fullName={avatarName} size={70} />
      )}
      <div className={styles.cardButtonContainer}>
        <div className={styles.buttons}>
          <Typography
            component={Link}
            type="link"
            to={profileLink}
            onClick={() => dispatch(trackActions.trackSubmitAction({}))}
          >
            View Profile
          </Typography>
          <div className={styles.linkDivider}>
            <span className={styles.linkDivider} />
          </div>
          <Typography
            component={Link}
            type="link"
            to={scheduleLink}
            onClick={() => dispatch(trackActions.trackSubmitAction({}))}
          >
            Request Session
          </Typography>
        </div>
      </div>
    </div>
  )
}
ProfileCard.propTypes = {
  roleSchema: PropTypes.array,
  profile: PropTypes.object
}
export default AdvisorList
