import { createReducer, createAction } from '@reduxjs/toolkit'

export const deviceIdInit = createAction('DEVICEID_INIT')
export const deviceIdGet = createAction('DEVICEID_GET')
export const deviceIdReady = createAction('DEVICEID_READY')

export const actions = {
  deviceIdInit,
  deviceIdGet,
  deviceIdReady
}

const authReducer = createReducer(
  {
    deviceIdReady: false
  },
  {
    [deviceIdReady]: state => {
      state.deviceIdReady = true
    }
  }
)

export default authReducer
