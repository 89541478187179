const languages = {
  Acoli: 'Acoli',
  Afrikaans: 'Afrikaans',
  Akan: 'Akan',
  Amharic: 'Amharic',
  Arabic: 'Arabic',
  Azerbaijani: 'Azerbaijani',
  Baluchi: 'Baluchi',
  Bulgarian: 'Bulgarian',
  Bislama: 'Bislama',
  Bashkir: 'Bashkir',
  Bengali: 'Bengali',
  'Bantu languages': 'Bantu languages',
  Tibetan: 'Tibetan',
  Bosnian: 'Bosnian',
  'Catalan; Valencian': 'Catalan; Valencian',
  Cebuano: 'Cebuano',
  'Creoles and pidgins, English based': 'Creoles and pidgins, English based',
  Cree: 'Cree',
  Czech: 'Czech',
  Welsh: 'Welsh',
  Danish: 'Danish',
  German: 'German',
  'Dravidian languages': 'Dravidian languages',
  Greek: 'Greek',
  English: 'English',
  'British English': 'British English',
  Esperanto: 'Esperanto',
  Spanish: 'Spanish',
  Estonian: 'Estonian',
  Basque: 'Basque',
  Persian: 'Persian',
  Finnish: 'Finnish',
  'Filipino; Pilipino': 'Filipino; Pilipino',
  Fijian: 'Fijian',
  French: 'French',
  Irish: 'Irish',
  'Gaelic; Scottish Gaelic': 'Gaelic; Scottish Gaelic',
  Gujarati: 'Gujarati',
  Hausa: 'Hausa',
  Hebrew: 'Hebrew',
  Hindi: 'Hindi',
  Croatian: 'Croatian',
  'Haitian; Haitian Creole': 'Haitian; Haitian Creole',
  Hungarian: 'Hungarian',
  Armenian: 'Armenian',
  Igbo: 'Igbo',
  Indonesian: 'Indonesian',
  Iloko: 'Iloko',
  'Indic languages': 'Indic languages',
  Icelandic: 'Icelandic',
  Italian: 'Italian',
  Japanese: 'Japanese',
  Kabyle: 'Kabyle',
  'Kachin; Jingpho': 'Kachin; Jingpho',
  Kamba: 'Kamba',
  Khmer: 'Khmer',
  Kannada: 'Kannada',
  Korean: 'Korean',
  Konkani: 'Konkani',
  Kashmiri: 'Kashmiri',
  Kurdish: 'Kurdish',
  Ganda: 'Ganda',
  Lingala: 'Lingala',
  Lao: 'Lao',
  Lithuanian: 'Lithuanian',
  Latvian: 'Latvian',
  'Austronesian languages': 'Austronesian languages',
  Memoni: 'Memoni',
  Macedonian: 'Macedonian',
  Malayalam: 'Malayalam',
  Marathi: 'Marathi',
  Malay: 'Malay',
  Maltese: 'Maltese',
  Burmese: 'Burmese',
  Nepali: 'Nepali',
  'Niger-Kordofanian languages': 'Niger-Kordofanian languages',
  'Dutch; Flemish': 'Dutch; Flemish',
  Norwegian: 'Norwegian',
  'Chichewa; Chewa; Nyanja': 'Chichewa; Chewa; Nyanja',
  Nzima: 'Nzima',
  Ojibwa: 'Ojibwa',
  Oriya: 'Oriya',
  'Panjabi; Punjabi': 'Panjabi; Punjabi',
  Polish: 'Polish',
  'Pushto; Pashto': 'Pushto; Pashto',
  Portuguese: 'Portuguese',
  Romanian: 'Romanian',
  Russian: 'Russian',
  Sindhi: 'Sindhi',
  'Sign Language': 'Sign Language',
  'Sinhala; Sinhalese': 'Sinhala; Sinhalese',
  Slovak: 'Slovak',
  Slovenian: 'Slovenian',
  Samoan: 'Samoan',
  Shona: 'Shona',
  Somali: 'Somali',
  Albanian: 'Albanian',
  Serbian: 'Serbian',
  Swati: 'Swati',
  Sotho: 'Sotho',
  Swedish: 'Swedish',
  Swahili: 'Swahili',
  'Classical Syriac': 'Classical Syriac',
  Tamil: 'Tamil',
  'Tai languages': 'Tai languages',
  Telugu: 'Telugu',
  Thai: 'Thai',
  Tigrinya: 'Tigrinya',
  Tiv: 'Tiv',
  Tagalog: 'Tagalog',
  Tswana: 'Tswana',
  'Tok Pisin': 'Tok Pisin',
  Turkish: 'Turkish',
  Urdu: 'Urdu',
  Ukrainian: 'Ukrainian',
  Vietnamese: 'Vietnamese',
  Xhosa: 'Xhosa',
  Yiddish: 'Yiddish',
  Yoruba: 'Yoruba',
  Chinese: 'Chinese',
  Zhongwen: 'Zhongwen',
  Zulu: 'Zulu'
}

export default languages
