import { createReducer, createAction } from '@reduxjs/toolkit'

export const scheduleRequest = createAction('SCHEDULE_REQUEST')
export const scheduleSuccess = createAction('SCHEDULE_REQUEST_SUCCESS')
export const scheduleReset = createAction('SCHEDULE_REQUEST_RESET')
export const scheduleFail = createAction('SCHEDULE_REQUEST_FAIL')

export const actions = {
  scheduleRequest,
  scheduleSuccess,
  scheduleFail,
  scheduleReset
}

const initialState = { scheduleMsg: {}, fetching: false, status: false }

const advisorScheduleReducer = createReducer(initialState, {
  [scheduleRequest]: (state, { payload: { scheduleMsg } }) => {
    state.scheduleMsg = scheduleMsg
    state.fetching = true
  },
  [scheduleSuccess]: state => {
    state.status = true
    state.fetching = false
  },
  [scheduleFail]: state => {
    state.scheduleMsg = {}
    state.status = false
    state.fetching = false
  },
  [scheduleReset]: state => {
    state.scheduleMsg = {}
    state.status = false
    state.fetching = false
  }
})

export default advisorScheduleReducer
