import { createReducer, createAction } from '@reduxjs/toolkit'
import { initialValues } from './config'

export const setFormState = createAction('COMPARE_LISTS_SET_FORM_STATE')

export const submitFormRequest = createAction(
  'COMPARE_LISTS_SUBMIT_FORM_REQUEST'
)
export const submitFormRequestSuccess = createAction(
  'COMPARE_LISTS_SUBMIT_FORM_REQUEST_SUCCESS'
)
export const submitFormRequestFail = createAction(
  'COMPARE_LISTS_SUBMIT_FORM_REQUEST_FAIL'
)
export const submitCompleteRequest = createAction(
  'COMPARE_LISTS_COMPLETE_REQUEST'
)
export const submitDownloadRequest = createAction(
  'COMPARE_LISTS_DOWNLOAD_REQUEST'
)

export const actions = {
  setFormState,
  submitFormRequest,
  submitFormRequestSuccess,
  submitFormRequestFail,
  submitCompleteRequest,
  submitDownloadRequest
}

const compareListsReducer = createReducer(
  {
    formState: {
      ...initialValues
    },
    fetching: false
  },
  {
    [setFormState]: (state, { payload }) => {
      state.formState = { ...state.formState, ...payload }
    },
    [submitFormRequest]: state => {
      state.fetching = true
    },
    [submitCompleteRequest]: state => {
      state.fetching = true
    },
    [submitFormRequestSuccess]: state => {
      state.fetching = false
    },
    [submitFormRequestFail]: state => {
      state.fetching = false
    },
    [submitDownloadRequest]: (state, { payload }) => {
      state.formState = { ...state.formState, ...payload }
    }
  }
)

export default compareListsReducer
