import { useDispatch } from 'react-redux'
import { actions } from 'modules/auth/AuthReducer'
import ListingLoader from 'components/ListingLoader'
import { useEffect } from 'react'

const Logout = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.logoutRequest())
  }, [dispatch])
  return <ListingLoader />
}
export default Logout
