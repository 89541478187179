/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { actions } from './AdvisorSearchReducer'
import { isEmpty, sortConstants } from 'utils'
import * as advisorSearchSelectors from 'modules/formAdvisorSearch/AdvisorSearchSelector'
import * as authSelectors from 'modules/auth/AuthSelector'
import ReactTooltip from 'react-tooltip'
import { Button } from 'components/ui/Button'
import Typography from '../../components/ui/Typography/Typography'
import ButtonStyles from '../../components/ui/Button/Button.module.sass'
import Icon from '../../components/ui/Icon/Icon'
import Page from 'components/Page'
import DropdownInput from 'components/DropdownInput'
import TextInput from 'components/TextInput'
import classNames from 'classnames/bind'
import languages from './languages'
import countries from './countries'
import styles from './AdvisorSearch.module.sass'
import CheckboxInput from 'components/LimaBureau/CheckboxInput'
import { initialValues as initialForm, validationSchema } from './config'

const cx = classNames.bind(styles)

const { getSearchStatus, getFormState, getSpecialtyList, getProfessionList } =
  advisorSearchSelectors
const { getFeaturesTable } = authSelectors

const AdvisorSearch = () => {
  ButtonStyles.title = styles.title
  const roleSchema = useSelector(getFeaturesTable())['lima-bureau-advisor']
  const fields = roleSchema.sections.fields
  const isFetching = useSelector(getSearchStatus())
  const formState = useSelector(getFormState())
  const specialtyList = useSelector(getSpecialtyList())
  const professionList = useSelector(getProfessionList())
  const dispatch = useDispatch()
  let formStatePr = isEmpty(formState) ? initialForm : formState
  const [initialValues, setInitialValues] = useState(formStatePr)
  const [clearInput, setClearInput] = useState(false)

  const handleSumbit = async values => {
    // If advisorType hidden send all types
    if (!fields.includes('advisorType')) {
      dispatch(actions.searchRequest(values))
    } else {
      const { internal, external, me, brand } = values
      const advisorTypes = Object.entries({ internal, external, me, brand })
        .filter(type => type[1]) // filter only the advisor types that are true
        .map(advisor => advisor[0]) // return their type (i. e. 'brand')

      dispatch(actions.searchRequest({ ...values, advisorTypes }))
    }
  }

  const handleReset = () => {
    // FORCES NESTED COMPONENTS TO UPDATE
    setClearInput(!clearInput)
    setInitialValues(initialForm)
    dispatch(actions.searchRequestFormStateClear())
  }
  const formValidationSchema =
    fields.includes('advisorType') && validationSchema

  useEffect(() => {
    dispatch(actions.searchSpecialtiesRequest())
    dispatch(actions.searchProfessionsRequest())
  }, [dispatch])

  useEffect(() => {
    document.body.classList.add('lima-smarts', 'page-advisor-search')
  }, [])

  // clean up
  useEffect(() => {
    return () => {
      document.body.classList.remove('lima-smarts', 'page-advisor-search')
    }
  }, [])

  return (
    <Page
      heroTitle={'Search For a LiMA Advisor'}
      showHero={true}
      showDivider={true}
      showBackIcon={false}
      contentClassName={styles.contentContainer}
      showFooter={false}
      isLimaBureau
    >
      <Formik
        key={clearInput}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSumbit}
        validationSchema={formValidationSchema}
      >
        {props => (
          <Form>
            <div className={styles.container}>
              {fields.includes('name') && (
                <div className={styles.internalContainer}>
                  <TextInput
                    label="First Name"
                    name="firstName"
                    type="text"
                    required={false}
                    traditional={false}
                  />
                  <TextInput
                    label="Last Name"
                    name="lastName"
                    type="text"
                    required={false}
                    traditional={false}
                  />
                </div>
              )}
              {fields.includes('profession') && (
                <DropdownInput
                  label="Profession"
                  name="profession"
                  options={professionList}
                  traditional={false}
                  customIcon="svg/custom/arrow-drop-down"
                />
              )}
              {fields.includes('workplaceName') && (
                <TextInput
                  label="Workplace Name"
                  name="workplaceName"
                  type="text"
                  required={false}
                  traditional={false}
                />
              )}
              {fields.includes('address') && (
                <TextInput
                  label="Workplace Address"
                  name="workplaceAddress"
                  type="text"
                  required={false}
                  traditional={false}
                />
              )}
              {fields.includes('city') && (
                <TextInput
                  label="City"
                  name="city"
                  type="text"
                  required={false}
                  traditional={false}
                />
              )}
              {fields.includes('zip') && (
                <TextInput
                  label="Postal Code"
                  name="postalCode"
                  type="text"
                  required={false}
                  traditional={false}
                />
              )}
              {fields.includes('state') && (
                <TextInput
                  label="State/Province"
                  name="state"
                  type="text"
                  required={false}
                  traditional={false}
                />
              )}
              {fields.includes('country') && (
                <DropdownInput
                  label="Country/Region"
                  name="country"
                  options={sortConstants(countries)}
                  traditional={false}
                  customIcon="svg/custom/arrow-drop-down"
                />
              )}
              {fields.includes('specialty') && (
                <DropdownInput
                  label="Specialty"
                  name="specialties"
                  options={sortConstants(specialtyList)}
                  traditional={false}
                  customIcon="svg/custom/arrow-drop-down"
                />
              )}
              {fields.includes('tags') && (
                <div>
                  <TextInput
                    labelTooltip={
                      <Icon
                        data-tip
                        data-for="tagsTooltip"
                        iconSize={15}
                        icon={'svg/custom/help'}
                        className={styles.tooltipIcon}
                        iconColor="#00000099"
                      />
                    }
                    data-tip
                    data-for="tagsTooltip"
                    label="Tags"
                    name="tags"
                    type="text"
                    traditional={false}
                    required={false}
                  />
                  <ReactTooltip
                    id="tagsTooltip"
                    place="top"
                    type="dark"
                    effect="solid"
                  >
                    <Typography className={styles.tooltipText} type={'body1'}>
                      Write each tag separated by a comma
                    </Typography>
                  </ReactTooltip>
                </div>
              )}
              {fields.includes('language') && (
                <DropdownInput
                  label="Language"
                  name="language"
                  options={languages}
                  traditional={false}
                  customIcon="svg/custom/arrow-drop-down"
                />
              )}
              {fields.includes('feePerSession') && (
                <div className={styles.item}>
                  <div>
                    <Typography type={'caption'}> Fee Per Session </Typography>
                    <div>
                      <Typography type={'caption'}> $ </Typography>
                      <TextInput
                        label=""
                        name="sessionFeeFrom"
                        type="number"
                        required={false}
                        traditional={false}
                      />
                      <Typography type={'caption'}> to </Typography>
                      <Typography type={'caption'}> $ </Typography>
                      <TextInput
                        label=""
                        name="sessionFeeTo"
                        type="number"
                        required={false}
                        traditional={false}
                      />
                    </div>
                  </div>
                </div>
              )}
              {fields.includes('advisorType') && (
                <div className={styles.checkboxItem}>
                  <Typography type={'body2'}>Advisor</Typography>
                  <div className={styles.advisorTypeContainer}>
                    <CheckboxInput
                      label="Brand"
                      name="brand"
                      showError={false}
                    />
                    <CheckboxInput label="ME" name="me" showError={false} />
                    <CheckboxInput
                      label="External"
                      name="external"
                      showError={false}
                    />
                    <CheckboxInput
                      label="Internal"
                      name="internal"
                      showError={false}
                    />
                  </div>
                  {Object.keys(props.errors).includes(
                    'brand',
                    'me',
                    'external',
                    'internal'
                  ) &&
                    Object.keys(props.touched).includes(
                      'brand',
                      'me',
                      'external',
                      'internal'
                    ) && (
                      <Typography type={'error'}>
                        Select at least one Advisor type.
                      </Typography>
                    )}
                </div>
              )}
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.button}
                  type="submit"
                  submitting={isFetching}
                  disabled={isFetching}
                  hasSpinner
                  spinnerStrokeColor="#1e3661"
                >
                  Search
                </Button>
                <Button
                  className={cx(styles.button, styles.rightButton)}
                  type="reset"
                  onClick={handleReset}
                  hasSpinner
                >
                  Reset
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  )
}
export default AdvisorSearch
