import { createSelector } from '@reduxjs/toolkit'

export const jobStatusListSelector = state => state.jobStatusListReducer
export const getJobStatusList = () =>
  createSelector(jobStatusListSelector, jobStatusList => jobStatusList.list)
export const getJobStatusListTotal = () =>
  createSelector(jobStatusListSelector, jobStatusList => jobStatusList.total)
export const getFetchingStatus = () =>
  createSelector(jobStatusListSelector, jobStatusList => jobStatusList.fetching)
export const getErrorStatus = () =>
  createSelector(jobStatusListSelector, jobStatusList => jobStatusList.error)
export const getPaginationState = () =>
  createSelector(
    jobStatusListSelector,
    jobStatusList => jobStatusList.paginationState
  )
export const getSearchState = () =>
  createSelector(
    jobStatusListSelector,
    jobStatusList => jobStatusList.searchState
  )

export const getListStatus = () =>
  createSelector(
    jobStatusListSelector,
    jobStatusList => jobStatusList.listStatus
  )

export const getListDomains = () =>
  createSelector(
    jobStatusListSelector,
    jobStatusList => jobStatusList.listDomains
  )

export const getListIds = () =>
  createSelector(jobStatusListSelector, jobStatusList => jobStatusList.listIds)

export const getAssignForm = () =>
  createSelector(
    jobStatusListSelector,
    jobStatusList => jobStatusList.assignForm
  )
