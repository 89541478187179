import PropTypes from 'prop-types'
import { useField, useFormikContext } from 'formik'
import { useDispatch } from 'react-redux'
import Dropdown from 'components/ui/DropDown'
import styles from './DropdownInput.module.sass'
import classNames from 'classnames/bind'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'

const cx = classNames.bind(styles)

/**
 * PSL UI select input integration with formik
 * @param options
 * @param suggestionsLimit
 * @param traditional
 * @param props
 * @returns {*}
 * @constructor
 */
const DropdownInput = ({
  traditional = true,
  options,
  className,
  uniqueFields,
  customIcon,
  ...props
}) => {
  const [field, meta] = useField({ ...props, type: 'select' })
  const { setFieldValue } = useFormikContext()
  const dispatch = useDispatch()
  const fieldName = props.name
  const onChange = value => {
    if (uniqueFields) {
      // If uniqueFields exist, check there are no duplicates
      const fields = Object.keys(uniqueFields).filter(
        f => f !== fieldName && uniqueFields[f] && uniqueFields[f] !== '0'
      )
      let isDuplicated = false
      fields.forEach(f => {
        if (uniqueFields[f] === value) {
          isDuplicated = true
        }
      })
      if (!isDuplicated) {
        setFieldValue(fieldName, value)
        if (typeof props.onChange === 'function') {
          props.onChange(value)
        }
      } else {
        dispatch(
          notificationActions.notificationEnqueue({
            message: 'Column already selected',
            duration: 0
          })
        )
        setFieldValue(fieldName, '')
        if (typeof props.onChange === 'function') {
          props.onChange('')
        }
      }
    } else {
      setFieldValue(fieldName, value)
      if (typeof props.onChange === 'function') {
        props.onChange(value)
      }
    }
  }
  return (
    <Dropdown
      {...props}
      id={props.name}
      data-testid="dropdown-input"
      name={props.name}
      className={cx(traditional && styles.traditional, className)}
      value={props.value ? props.value : field.value}
      onChange={onChange}
      options={options}
      traditional={traditional}
      showIcon={true}
      customIcon={customIcon || 'svg/custom/unfold-more'}
      autoComplete="off" // Using unsupported autocomplete value to disable Chrome autocomplete
      error={meta.touched && meta.error ? meta.error : null}
    />
  )
}

DropdownInput.propTypes = {
  /**
   * Input name
   */
  name: PropTypes.string.isRequired,
  /**
   * Input label
   */
  label: PropTypes.string.isRequired,
  /**
   * Additional data for default select input
   */
  props: PropTypes.object,
  /**
   * object for select input options
   */
  options: PropTypes.object,
  /**
   * flag for traditional render
   */
  traditional: PropTypes.bool,
  /**
   * Input value
   */
  value: PropTypes.string,
  /**
   * extra className passed to the input
   */
  className: PropTypes.string,
  /**
   * onChange callback
   */
  onChange: PropTypes.func,
  /**
   * if set the component will validate that there are no duplicate between the fields
   */
  uniqueFields: PropTypes.object,
  /**
   * Custom Icon
   */
  customIcon: PropTypes.string
}
export default DropdownInput
