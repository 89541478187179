import React from 'react'
import Page from 'components/Page'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button, { RaisedButton } from 'components/Button'
import Charts from 'modules/Charts'
import { actions as exploreListActions } from './ExploreListReducer'
import { actions as chartsActions } from '../Charts/ChartsReducer'
import * as exploreListSelectors from './ExploreListSelector'
import { getActiveStep } from '../Charts/ChartsSelector'
import styles from './ExploreList.module.sass'
import { initialValues, steps } from './config'
import { isEmpty } from 'utils'
import classNames from 'classnames/bind'
import validationSchema from './validationSchema'
import useRoleFeatures from 'modules/auth/useRoleFeatures'

const cx = classNames.bind(styles)

const { getFormState, getFetchStatus } = exploreListSelectors

const ExploreList = () => {
  let initialForm = {
    ...initialValues
  }
  const dispatch = useDispatch()
  const history = useHistory()
  const formState = useSelector(getFormState())
  const isFetching = useSelector(getFetchStatus())
  const chartsStep = useSelector(getActiveStep())
  const { activeStep } = formState
  const formStatePr = isEmpty(formState) ? initialForm : formState
  const isLastStep = activeStep === 'project-metadata'
  const chartsData = isEmpty(formState) ? {} : formState.chartsData
  const currentValidationSchema = validationSchema[activeStep]
  const { features } = useRoleFeatures({
    coin: 'explore-list'
  })

  const handleBack = () => {
    let newStep = ''
    switch (activeStep) {
      case 'list-selection':
        history.push('/')
        break
      case 'filters':
        newStep = 'list-selection'
        break
      case 'project-metadata':
        newStep = 'filters'
        break
      case 'charts':
        newStep = 'filters'
        break
      case 'share-results':
        newStep = 'charts'
        break
      default:
        break
    }
    if (newStep) {
      dispatch(
        exploreListActions.setFormState({
          ...formState,
          activeStep: newStep
        })
      )
    }
  }

  const handleShareResults = () => {
    dispatch(
      exploreListActions.setFormState({
        ...formState,
        activeStep: 'share-results'
      })
    )
  }

  const handleSubmit = (values, actions) => {
    if (!isLastStep) {
      dispatch(
        exploreListActions.submitFormRequest({
          ...values
        })
      )
    } else {
      dispatch(exploreListActions.submitCompleteRequest({ ...values }))
    }
  }

  const handleReset = ({ resetForm }) => {
    if (typeof resetForm === 'function') {
      resetForm()
    }
    dispatch(
      exploreListActions.setFormState({
        ...initialValues
      })
    )
    if (chartsStep === 'share-results') {
      dispatch(chartsActions.changeStep())
    }
  }

  const ActiveStep = formProps =>
    React.cloneElement(steps[activeStep].component, {
      ...steps[activeStep].component.props,
      formProps,
      handleReset
    })

  return (
    <Page
      heroTitle={
        activeStep === 'share-results'
          ? 'Share your results'
          : 'Explore Project List'
      }
      showHero
      showGoBack
      onBackClick={handleBack}
      showDivider
      showFooter={false}
      contentClassName={styles.contentContainer}
    >
      <Formik
        initialValues={formStatePr}
        onSubmit={handleSubmit}
        validationSchema={currentValidationSchema}
        enableReinitialize
      >
        {formProps => (
          <>
            <Form>
              <ActiveStep {...formProps} />
              <div className={styles.buttonContainer}>
                {isLastStep && (
                  <Button
                    className={styles.submitButton}
                    hasSpinner
                    type="submit"
                    submitting={isFetching}
                    isLimaSubmit
                  />
                )}
                {activeStep !== 'filters' &&
                  !isLastStep &&
                  activeStep !== 'charts' &&
                  activeStep !== 'share-results' && (
                    <Button
                      className={styles.button}
                      type="submit"
                      submitting={isFetching}
                      disabled={isFetching}
                      hasSpinner
                      isLimaSubmit
                    />
                  )}
              </div>
              <div className={cx(styles.resetContainer)}>
                <RaisedButton
                  type="button"
                  className={cx(styles.resetButton)}
                  onClick={() => handleReset(formProps)}
                  disabled={isFetching}
                >
                  Reset Form
                </RaisedButton>
              </div>
            </Form>
            {activeStep === 'charts' && (
              <Charts
                chartsData={chartsData}
                formProps={formProps}
                onShareResults={handleShareResults}
                showDownloadZip={features.sections?.actions.includes(
                  'download-charts'
                )}
              />
            )}
          </>
        )}
      </Formik>
    </Page>
  )
}

export default ExploreList
