import PropTypes from 'prop-types'
import styles from './CircularProgress.module.sass'
import { between } from '../utils/propValidators'
import classNames from 'classnames/bind'

const CircularProgress = ({
  strokeBackgroundColor,
  progress,
  size,
  strokeWidth,
  fillColor,
  strokeColor,
  altTheme,
  className,
  style,
  colorTier
}) => {
  const spinnerSize = 50
  const radius = 20
  const themeStyles = { ...styles, ...altTheme }
  const cx = classNames.bind(themeStyles)
  const dashArray =
    progress !== null && progress >= 0 ? radius * Math.PI * 2 : null
  const dashOffset =
    progress !== null && progress >= 0
      ? Math.max(dashArray - (dashArray * progress) / 100, 0)
      : null
  return (
    <div
      className={cx(themeStyles.loader, className)}
      style={{ ...{ minHeight: size + 15 }, ...style }}
      data-testid="d1"
    >
      <svg
        className={cx(themeStyles.circular, {
          indeterminate: !progress && progress !== 0
        })}
        style={{ width: size, height: size }}
        viewBox={`${spinnerSize / 2} ${
          spinnerSize / 2
        } ${spinnerSize} ${spinnerSize}`}
        data-testid="s1"
      >
        <circle
          fill={fillColor}
          cx={spinnerSize}
          cy={spinnerSize}
          r={radius + strokeWidth / 2}
          style={{
            fill: fillColor
          }}
          data-testid="c"
        />
        <circle
          fill="none"
          cx={spinnerSize}
          cy={spinnerSize}
          r={radius}
          strokeWidth={strokeWidth}
          style={{
            stroke: strokeBackgroundColor
          }}
          data-testid="c"
        />
        <circle
          className={cx(themeStyles.path, {
            [colorTier]: colorTier
          })}
          cx={spinnerSize}
          cy={spinnerSize}
          r={radius}
          fill="none"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          stroke={strokeColor}
          style={{
            stroke: strokeColor,
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset
          }}
          data-testid="c"
        />
      </svg>
    </div>
  )
}
CircularProgress.displayName = 'CircularProgress'
CircularProgress.defaultProps = {
  size: 64,
  strokeWidth: 4,
  strokeBackgroundColor: 'none',
  colorTier: 'primary'
}
CircularProgress.propTypes = {
  /**
   * The color of the background stroke, if any
   */
  strokeBackgroundColor: PropTypes.string,
  /**
   * The completion percentage, if undefined or null it will behave as indeterminate
   */
  progress: between({ gte: 0, lte: 100 }),
  /**
   * The circular progress size (in pixels)
   */
  size: PropTypes.number,
  /**
   * The circular progress stroke width in 64ths of the total size (max is 10)
   */
  strokeWidth: between({ gte: 1, lte: 10 }),
  /**
   * The circle background fill color (can be a color or 'transparent')
   */
  fillColor: PropTypes.string,
  /**
   * The circular progress stroke color (can be a color or 'transparent')
   * (overrides `colorTier` prop)
   */
  strokeColor: PropTypes.string,
  /**
   * A CSS modules style object to override default theme
   */
  altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  /**
   * Inline style overrides
   */
  style: PropTypes.object,
  /**
   * An additional custom className for the root element
   */
  className: PropTypes.string,
  /**
   * The progress color where the tier colors are determined by the theme
   */
  colorTier: PropTypes.oneOf([
    '',
    'action',
    'primary',
    'secondary',
    'tertiary',
    'default',
    'knockout',
    'text-primary',
    'text-secondary'
  ])
}
export default CircularProgress
