import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FieldArray, useFormikContext, useField } from 'formik'
import Typeahead from 'modules/typeahead'
import Icon from 'components/ui/Icon'
import Spinner from 'components/ui/Spinner'
import Tooltip from 'components/Tooltip'
import * as typeaheadSelectors from './ListIdsSelector'
import { actions as listIdsActions } from './ListIdsReducer'
import { smartsEndpoints } from 'constants/apiConstants'
import styles from './ListIds.module.sass'

const { getTips } = typeaheadSelectors

function ListIds({
  name,
  disabled,
  onSuggestionSelected,
  onListIdRemove,
  label,
  requestBody = {},
  formName = '',
  noDuplicate = false
}) {
  const dispatch = useDispatch()
  const [clearInput, setClearInput] = useState(false)
  const { setFieldValue } = useFormikContext()
  const [field] = useField({ name })
  const tips = useSelector(getTips())

  const requestData = requestBody.data ? { ...requestBody.data } : {}

  // Remove current list from selectedLists filters
  // this only applies if noDuplicate prop is true
  const selectedListIds = (index, values) => {
    const inputValues = values[name]
    return inputValues.filter((_, idx) => idx !== index)
  }

  const handleSuggestionSelected = (id, suggestion, name) => {
    dispatch(
      listIdsActions.tooltipRequest({
        listId: suggestion.key,
        name,
        requestBody, // Used to check if we need to update the form based on the workflow
        setFieldValue // we pass this callback in order to be able to update formik state
      })
    )
    if (typeof onSuggestionSelected === 'function') {
      onSuggestionSelected(id, suggestion)
    }
  }
  const handleRemove = name => {
    dispatch(listIdsActions.tooltipRequestRemove({ name }))
  }
  // Force FieldArray re-render on redux state update
  useEffect(() => {
    setClearInput(!clearInput)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tips])

  return (
    <FieldArray key={clearInput} name={name}>
      {fieldArrayProps => {
        const { remove, form } = fieldArrayProps
        const { values } = form
        const { ids } = values
        return (
          <div className={styles.container}>
            {ids.map((id, index) => (
              <div className={styles.rowContainer} key={id}>
                <Typeahead
                  formName={formName}
                  label={label ?? `List ID ${index + 1}`}
                  name={`${name}[${index}]`}
                  placeholder="Enter List ID"
                  type="text"
                  className={styles.input}
                  required
                  traditional
                  body={{
                    endpoint:
                      smartsEndpoints.listsCombinationsFetchListsEndpoint,
                    parseFunction: 'optionsParser',
                    ...requestBody,
                    data: {
                      ...requestData,
                      filters: requestData.filters
                        ? {
                            ...requestData.filters,
                            selectedLists: noDuplicate
                              ? selectedListIds(index, values)
                              : requestData.filters.selectedLists || undefined
                          }
                        : noDuplicate
                        ? { selectedLists: selectedListIds(index, values) }
                        : undefined
                    }
                  }}
                  disabled={disabled}
                  onSuggestionSelected={suggestion =>
                    handleSuggestionSelected(
                      index,
                      suggestion,
                      `${name}[${index}]`
                    )
                  }
                />
                <div className={styles.rightElements}>
                  <div>
                    {tips[`${name}[${index}]`]?.fetching && (
                      <div className={styles.tooltipLoader}>
                        <Spinner
                          strokeColor="#02296d"
                          fillColor="transparent"
                          size={20}
                          strokeWidth={4}
                        />
                      </div>
                    )}
                    {field.value[index] && tips[`${name}[${index}]`]?.tip && (
                      <div className={styles.tooltipContainer}>
                        <Tooltip
                          tip={tips[`ids[0]`].tip}
                          multiline={false}
                          iconProps={{
                            icon: 'svg/custom/help-outline',
                            iconSize: 20
                          }}
                        />
                      </div>
                    )}
                    {index > 0 && !disabled && (
                      <Icon
                        button
                        className={styles.inputItem}
                        icon="svg/custom/close"
                        colorTier="tertiary"
                        onClick={() => {
                          remove(index)
                          onListIdRemove(index)
                          handleRemove(`${name}[${index}]`)
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )
      }}
    </FieldArray>
  )
}

ListIds.propTypes = {
  /**
   * Typeahead formName
   */
  formName: PropTypes.string,
  /**
   * Form name
   */
  name: PropTypes.string,
  /**
   * Body passed to the typeahead request
   */
  requestBody: PropTypes.object,
  /**
   * Disabled
   */
  disabled: PropTypes.bool,
  /**
   * onSuggestionSelected(id, { suggestion: { key, value } })
   *
   * Called when a suggestion is selected (returns the event and the suggestion object with label and key values)
   */
  onSuggestionSelected: PropTypes.func,
  /**
   * onSuggestionSelected(index)
   *
   * Called when a list is removed
   */
  onListIdRemove: PropTypes.func,
  /**
   * Label
   */
  label: PropTypes.string,
  /**
   * if true, it will create a new selectedLists property to be sent to the typeahead query
   */
  noDuplicate: PropTypes.string
}

export default ListIds
