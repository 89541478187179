/* eslint-disable react/display-name */
import { useState, useEffect } from 'react'
// import ReactTooltip from 'react-tooltip'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from 'react-data-table-component'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'utils'
import classNames from 'classnames/bind'

import Icon from 'components/ui/Icon'
import Typography from '../../components/ui/Typography'
import { CircularProgress } from 'components/ui/Progress'
import Chip from 'components/ui/Chip'

import Page from 'components/Page'
import * as jobStatusListSelectors from './JobStatusListSelector'
import * as actions from './JobStatusListReducer'
import JobStatusSearch from './JobStatusSearch'
import JobStatusAssign from './JobStatusAssign'
import styles from './JobStatusList.module.sass'

const cx = classNames.bind(styles)

const LIST_TOTAL_ROWS = 20

const {
  getJobStatusList,
  getJobStatusListTotal,
  getFetchingStatus,
  //getErrorStatus,
  getSearchState,
  getPaginationState
} = jobStatusListSelectors

const dataTableStyles = {
  table: {
    style: {
      backgroundColor: '#fafafa'
    }
  },
  headCells: {
    style: {
      color: '#02296D',
      fontSize: '1.0625em',
      fontWeight: 400
    }
  },
  headRow: {
    style: {
      backgroundColor: '#fafafa',
      fontSize: '15px'
    }
  },
  header: {
    style: {
      backgroundColor: '#fafafa'
    }
  },
  rows: {
    style: {
      backgroundColor: '#fafafa',
      fontSize: '15px'
    }
  },
  pagination: {
    style: {
      color: '#02296D',
      fontSize: '0.85em',
      fontWeight: 400,
      backgroundColor: '#fafafa'
    }
  },
  noData: {
    style: {
      fontSize: '1.25em',
      fontWeight: 500
    }
  },
  progress: {
    style: {
      marginTop: '50px'
    }
  }
}

const JobStatusList = () => {
  const dispatch = useDispatch()
  const [firstVisit, setFirstVisit] = useState(true)
  const [rowsPerPage, setRowsPerPage] = useState(LIST_TOTAL_ROWS)
  const [sortColumn, setSortColumn] = useState('')
  const [sortOrder, setSortOrder] = useState('')
  const history = useHistory()

  const isFetching = useSelector(getFetchingStatus())
  const data = useSelector(getJobStatusList())
  const totalRows = useSelector(getJobStatusListTotal())
  const searchState = useSelector(getSearchState())
  const paginationState = useSelector(getPaginationState())

  // mount
  useEffect(() => {
    document.title = 'LiMA Smarts | Job Status'
    document.body.classList.add('lima-smarts', 'page-job-status-listing')

    if (isEmpty(data) && firstVisit && !isFetching) {
      dispatch(actions.searchRequest({ perPage: rowsPerPage }))
    }

    setFirstVisit(false)
  }, [data, dispatch, firstVisit, isFetching, rowsPerPage])
  // clean up
  useEffect(() => {
    return () => {
      document.title = 'LiMA'
      document.body.classList.remove('lima-smarts', 'page-job-status-listing')
    }
  }, [])

  const handlePageChange = page => {
    dispatch(
      actions.searchRequest({
        ...searchState,
        page: page,
        perPage: rowsPerPage,
        sortBy: sortColumn,
        sortOrder: sortOrder
      })
    )
  }
  const handlePerRowsChange = async (newPerPage, page) => {
    setRowsPerPage(newPerPage)
    dispatch(
      actions.searchRequest({
        ...searchState,
        page,
        perPage: newPerPage,
        sortBy: sortColumn,
        sortOrder: sortOrder
      })
    )
  }
  const handleSort = ({ sortField: selector }, sortDirection) => {
    const sortBy = selector === 'business_workflow' ? 'business_flow' : selector

    setSortColumn(sortBy)
    setSortOrder(sortDirection)
    dispatch(
      actions.searchRequest({
        ...searchState,
        page: 1,
        perPage: rowsPerPage,
        sortBy,
        sortOrder: sortDirection
      })
    )
  }
  const handleSearchSubmit = values => {
    const newValues = {
      ...values,
      listId: values.list_id_key || values.listId
    }
    dispatch(
      actions.searchRequest({
        ...paginationState,
        ...newValues,
        page: 1
      })
    )
  }
  const handleSearchReset = () => {
    dispatch(
      actions.searchRequest({
        perPage: rowsPerPage
      })
    )
  }
  const handleOnAssignClick = listId => {
    dispatch(
      actions.assignListFormOpen({
        listId
      })
    )
  }

  const IconLink = row => {
    return (
      <Icon
        icon="svg/material-design-icons/action/visibility"
        title="View List Details"
        colorTier="tertiary"
        button
        disabled={!row.uuid}
        onClick={event => {
          if (row.uuid) {
            if (event.ctrlKey || event.metaKey) {
              window.open(`/job-status/${row.uuid}`, '_blank')
            } else {
              history.push(`/job-status/${row.uuid}`)
            }
          }
        }}
      />
    )
  }
  const columns = [
    {
      name: 'Submitted',
      selector: row => row.updatedAt,
      sortField: 'updatedAt',
      sortable: false,
      cell: row => {
        const dateArray = row.updatedAt.split(' ')
        let date = dateArray[0]
        let time = dateArray[1]
        return (
          <Typography className="date-time-column">
            {date}
            <br />
            <small>{time}</small>
          </Typography>
        )
      }
    },
    {
      name: 'List ID',
      selector: row => row.idhListId,
      sortField: 'idhListId',
      sortable: false,
      cell: row =>
        row.idhListId && <Typography>{row.idhListId || ''}</Typography>
    },
    {
      name: 'Workflow',
      sortField: 'workflow',
      selector: row => row.workflow,
      sortable: false,
      cell: row => <Typography>{row.workflow}</Typography>
    },
    {
      name: 'Master Project',
      sortField: 'masterProject',
      selector: row => row.masterProject,
      sortable: false,
      cell: row => <Typography>{row.masterProject || ''}</Typography>
    },
    {
      name: 'Status',
      sortField: 'status',
      selector: row => row.status,
      sortable: false,
      cell: row => {
        let status = ''
        if (row.status.toLowerCase().includes('completed')) {
          status = 'completed'
        } else if (row.status.toLowerCase().includes('error')) {
          status = 'failed'
        } else if (row.status.toLowerCase().includes('processing')) {
          status = 'in-progress'
        }
        return (
          <Typography className={cx({ [`status-${status}`]: true })}>
            {row.status}
          </Typography>
        )
      }
    },
    // {
    //   name: 'List Owner',
    //   selector: row => row.owner,
    //   sortField: 'owner',
    //   sortable: false,
    //   cell: row => {
    //     let email = row.owner.split('@')
    //     return (
    //       <Typography className="owner-email-column">
    //         {email[0]} <small>@{email[1]}</small>
    //       </Typography>
    //     )
    //   }
    // },
    {
      name: 'Email Sharing',
      sortable: false,
      center: true,
      cell: row => (
        <Chip
          className="avatars-list-wrapper"
          label={`${row.emailsSharingCount}`}
          onClick={() => handleOnAssignClick(row.uuid)}
        />
      )
    },
    // {
    //   name: 'Assigned Groups',
    //   sortable: false,
    //   cell: row => (
    //     <div className="sharing-groups-count">
    //       <div
    //         data-tip={[].map(group => group.name).join('<br/>')}
    //         data-for={`group-${row.key}`}
    //       >
    //         <Typography>{row.groupsSharingCount}</Typography>
    //       </div>
    //       <ReactTooltip id={`group-${row.key}`} multiline />
    //     </div>
    //   )
    // },
    {
      cell: IconLink,
      button: true
    }
  ]
  return (
    <Page
      heroTitle={'Job Status'}
      showHero={true}
      showDivider={true}
      showBackIcon={true}
      pageClassName={styles.pageContainer}
      contentClassName={styles.contentContainer}
      showFooter={false}
    >
      <JobStatusSearch
        onSubmit={handleSearchSubmit}
        onReset={handleSearchReset}
      />
      <DataTable
        title={'Your Lists'}
        columns={columns}
        data={data}
        keyField="key"
        progressPending={isFetching}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        sortServer
        persistTableHead
        paginationPerPage={LIST_TOTAL_ROWS}
        paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 80, 150]}
        customStyles={dataTableStyles}
        progressComponent={
          <CircularProgress fillColor="transparent" size={64} strokeWidth={4} />
        }
      />
      <JobStatusAssign />
    </Page>
  )
}

export default JobStatusList
