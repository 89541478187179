import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as popularPromptsActions } from './PopularPromptsReducer'
import siteConfig from 'site.config.json'

const { configuration } = siteConfig

function* popularPromptsRequest(services, { payload: language }) {
  const DougallService = services('DougallService')
  const DougallParser = services('DougallParser')

  try {
    // const [languageCode, countryCode] = navigator.language.split('-')

    // const { popularPrompts } = yield call(
    //   [DougallService, 'popularPromptsRequest'],
    //   { languageCode, countryCode }
    // )

    const popularPrompts = yield call(
      [DougallService, 'popularPromptsRequest'],
      {
        brandCode: 'dougallgpt',
        fileName: 'popular_prompts.json'
      }
    )

    if (popularPrompts) {
      const prompts = popularPrompts[language]
        ? popularPrompts[language]
        : popularPrompts[configuration.localization.fallbackLng]

      const parsedPrompts = yield call(
        [DougallParser, 'popularPromptsParser'],
        prompts
      )

      yield put(
        popularPromptsActions.requestSuccess({ prompts: parsedPrompts || [] })
      )
    } else {
      yield put(popularPromptsActions.requestFail())
    }
  } catch (e) {
    yield put(popularPromptsActions.requestFail())
  }
}

export default function* watchPopularPrompts(services) {
  yield all([
    takeEvery('POPULAR_PROMPTS_REQUEST', popularPromptsRequest, services)
  ])
}
