import { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Header from 'components/ui/Header'
import { useHistory } from 'react-router-dom'
import styles from './PageHeader.module.sass'
import classNames from 'classnames/bind'
import { actions as trackActions } from 'modules/tracking/TrackingReducer'
import * as authSelectors from 'modules/auth/AuthSelector'
import siteConfig from 'site.config.json'
import Drawer from '@material-ui/core/Drawer'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import useMediaSize from 'hooks/useMediaSize'
import Icon from 'components/ui/Icon'
import useRoleFeatures from 'modules/auth/useRoleFeatures'

const { logo, adminMenu } = siteConfig

const cx = classNames.bind(styles)

const { getIsAuth } = authSelectors

const PageHeader = ({ menuLinks = [], children, ...rest }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [expanded, setExpanded] = useState({})
  const history = useHistory()
  const { roles = [] } = useRoleFeatures({})
  const dispatch = useDispatch()
  const isAuth = useSelector(getIsAuth())
  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 550px)',
      medium: '(max-width: 960px)'
    }
  })
  const { location } = history
  const logoLink = '/'

  const handleLogoClick = () => {
    dispatch(
      trackActions.trackCustomAction({
        title: `${isAuth ? 'Authenticated' : 'Anonymous'} - Header logo link`
      })
    )
    // Reverting back to push since the replace already happens on the AuthSaga
    // and we need to track the push action
    history.push(logoLink)
  }

  const handleMenuAction = event => {
    event.preventDefault()
    setMenuOpen(true)
  }

  const handleExpandGroup = group => {
    setExpanded(prev => ({
      ...prev,
      [group]: !expanded[group]
    }))
  }

  const handleItemClick = link => {
    history.push(link)
  }

  const hasPermission = link => {
    // User has wildcard permission, allow all access
    if (roles?.includes('/admin')) return true
    // Validate user role
    return roles?.includes(link)
  }

  const toggleIcon = expand =>
    expand ? (
      <Icon icon="svg/custom/carat-d" />
    ) : (
      <Icon icon="svg/custom/carat-r" />
    )

  const renderLinks = () => {
    return Object.keys(adminMenu).map(section => {
      if (Array.isArray(adminMenu[section])) {
        return (
          <Fragment key={section}>
            <ListSubheader className={styles.menuHeader} component="div">
              {section}
            </ListSubheader>
            {adminMenu[section].map((menuItem, index) => {
              if (menuItem.group) {
                const isGroupActive = menuItem.links.find(
                  l => location.pathname === l.link
                )
                return (
                  <Fragment key={menuItem.group}>
                    <ListItem
                      button
                      onClick={() => handleExpandGroup(menuItem.group)}
                      className={cx(styles.menuExpandable, {
                        active: isGroupActive
                      })}
                    >
                      <ListItemText primary={menuItem.group} />
                      {toggleIcon(expanded[menuItem.group])}
                    </ListItem>
                    <Collapse
                      in={expanded[menuItem.group]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {menuItem.links.map(item => (
                          <ListItem
                            key={item.link}
                            className={cx(styles.menuItem, {
                              active: location.pathname === item.link
                            })}
                            disabled={
                              item.disabled || !hasPermission(item.link)
                            }
                            onClick={() => handleItemClick(item.link)}
                            button
                          >
                            <ListItemText primary={item.title} />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </Fragment>
                )
              } else {
                return (
                  <ListItem
                    key={menuItem.link}
                    className={cx(styles.menuExpandable, {
                      active: location.pathname === menuItem.link
                    })}
                    disabled={
                      menuItem.disabled || !hasPermission(menuItem.link)
                    }
                    onClick={() => handleItemClick(menuItem.link)}
                    button
                  >
                    <ListItemText primary={menuItem.title} />
                  </ListItem>
                )
              }
            })}
          </Fragment>
        )
      } else {
        const {
          title,
          link,
          group,
          links = [],
          disabled = false
        } = adminMenu[section]
        if (group) {
          const isGroupActive = links.find(l => location.pathname === l.link)
          return (
            <Fragment key={group}>
              <ListItem
                button
                onClick={() => handleExpandGroup(group)}
                className={cx(styles.menuExpandable, {
                  active: isGroupActive
                })}
              >
                <ListItemText primary={group} />
                {toggleIcon(expanded[group])}
              </ListItem>
              <Collapse in={expanded[group]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {links.map(item => (
                    <ListItem
                      key={item.link}
                      className={cx(styles.menuItem, {
                        active: location.pathname === item.link
                      })}
                      onClick={() => handleItemClick(item.link)}
                      disabled={item.disabled || !hasPermission(item.link)}
                      button
                    >
                      <ListItemText primary={item.title} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </Fragment>
          )
        } else {
          return (
            <ListItem
              key={title}
              className={cx(styles.menuExpandable, {
                active: location.pathname === link
              })}
              onClick={() => handleItemClick(link)}
              disabled={disabled || !hasPermission(link)}
              button
            >
              <ListItemText primary={title} />
            </ListItem>
          )
        }
      }
    })
  }

  return (
    <>
      <Header
        className={cx(styles.header)}
        iconLeft={
          mediaSize === 'small'
            ? 'svg/material-design-icons/navigation/menu'
            : ''
        }
        iconRight="svg/material-design-icons/action/exit_to_app"
        iconColor={'primary'}
        actionLeft={handleMenuAction}
        actionRight={handleLogoClick}
        logoSrc={logo.source}
        borderBottom="1px solid #e0e0e0"
        logoOnClick={handleLogoClick}
      />
      <div className={cx(styles.content)}>
        <Drawer
          variant={mediaSize === 'small' ? 'temporary' : 'persistent'}
          anchor="left"
          open={mediaSize === 'small' ? menuOpen : true}
          onClose={() => setMenuOpen(false)}
          className={cx(styles.fullDrawer)}
        >
          <List component="nav" aria-labelledby="nested-list-subheader">
            {renderLinks()}
          </List>
        </Drawer>
        <div className={styles.contentContainer}>{children}</div>
      </div>
    </>
  )
}
PageHeader.propTypes = {
  /**
   * List of links to show in side menu
   */
  menuLinks: PropTypes.array,
  /**
   * The page content
   */
  children: PropTypes.node.isRequired
}

export default PageHeader
