import { useSelector } from 'react-redux'
import { getRoleTable, getFeaturesTable } from './AuthSelector'
const useRoleFeatures = ({ coin = '' }) => {
  const roles = useSelector(getRoleTable()) || []
  const features = useSelector(getFeaturesTable()) || {}

  return {
    roles,
    features: features[coin] || {}
  }
}

export default useRoleFeatures
