import { createSelector } from '@reduxjs/toolkit'

export const filterSelector = state => state.combinationsTableReducer

export const getCombinations = () =>
  createSelector(filterSelector, combinations => combinations.combinationsTable)
export const getTooltips = () =>
  createSelector(filterSelector, combinations => combinations.tooltips)
export const getFetchStatus = () =>
  createSelector(filterSelector, combinations => combinations.fetching)
export const getLoadedCountriesStatus = () =>
  createSelector(filterSelector, combinations => combinations.loadedCountries)
