import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as chartsGenericActions } from './ChartsGenericReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'

function* requestGenericConfiguration(services) {
  const AdminService = services('AdminService')

  const { status, message, responseData } = yield call([
    AdminService,
    'getChartsGenericConfiguration'
  ])
  if (status === 'OK') {
    const { tokens, tooltips } = responseData
    yield put(
      chartsGenericActions.requestGenericConfigSuccess({
        tokens,
        tooltips,
        responseData
      })
    )
  } else {
    yield put(chartsGenericActions.requestGenericConfigFail())
    yield put(
      notificationActions.notificationEnqueue({
        message,
        duration: 0
      })
    )
  }
}

function* saveGenericConfiguration(services, { payload }) {
  const AdminService = services('AdminService')

  try {
    const { message, status, responseData } = yield call(
      [AdminService, 'updateChartsGenericConfiguration'],
      payload
    )

    if (status === 'OK') {
      yield put(chartsGenericActions.saveGenericConfigSuccess(payload))
      yield put(
        notificationActions.notificationEnqueue({
          message,
          duration: 0
        })
      )
    } else if (status === 'Validation failed') {
      yield put(
        notificationActions.notificationEnqueue({
          message: message + ': ' + responseData[0].message,
          duration: 0
        })
      )
    } else {
      yield put(
        notificationActions.notificationEnqueue({
          message,
          duration: 0
        })
      )
    }
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
  }
}

export default function* watchChartsGeneric(services) {
  yield all([
    takeEvery('CHARTS_GENERIC_REQUEST', requestGenericConfiguration, services)
  ])
  yield all([
    takeEvery('CHARTS_GENERIC_SAVE', saveGenericConfiguration, services)
  ])
}
