import ContentLoader from 'react-content-loader'
import styles from './LoadingComponent.module.sass'
import Page from 'components/Page'

const LoadingComponent = () => (
  <Page
    showHero={false}
    showBackIcon={false}
    showFooter={false}
    showDivider={false}
  >
    <div className={styles.containerFull}>
      <ContentLoader
        width={'100%'}
        height={500}
        primarycolor="#e4e4e4"
        secondarycolor="#d3d3d3"
      >
        <rect x="0" y="20" rx="0" ry="0" width="40%" height="50" />
        <rect x="0" y="80" rx="0" ry="0" width="100%" height="4" />
        <rect x="0" y="150" rx="0" ry="0" width="45%" height="10" />
        <rect x="55%" y="150" rx="0" ry="0" width="45%" height="10" />
        <rect x="0" y="210" rx="0" ry="0" width="45%" height="10" />
        <rect x="55%" y="210" rx="0" ry="0" width="45%" height="10" />
        <rect x="0" y="270" rx="0" ry="0" width="45%" height="10" />
        <rect x="55%" y="270" rx="0" ry="0" width="45%" height="10" />
        <rect x="0" y="330" rx="0" ry="0" width="45%" height="10" />
        <rect x="55%" y="330" rx="0" ry="0" width="45%" height="10" />
        <rect x="0" y="390" rx="0" ry="0" width="45%" height="10" />
        <rect x="55%" y="390" rx="0" ry="0" width="45%" height="10" />
      </ContentLoader>
    </div>
    <div className={styles.containerSmall}>
      <ContentLoader
        width={'100%'}
        height={500}
        primarycolor="#e4e4e4"
        secondarycolor="#d3d3d3"
      >
        <rect x="0" y="20" rx="0" ry="0" width="40%" height="50" />
        <rect x="0" y="80" rx="0" ry="0" width="100%" height="4" />
        <rect x="0" y="150" rx="0" ry="0" width="100%" height="10" />
        <rect x="0" y="210" rx="0" ry="0" width="100%" height="10" />
        <rect x="0" y="270" rx="0" ry="0" width="100%" height="10" />
        <rect x="0" y="330" rx="0" ry="0" width="100%" height="10" />
        <rect x="0" y="390" rx="0" ry="0" width="100%" height="10" />
      </ContentLoader>
    </div>
  </Page>
)
export default LoadingComponent
