import { useState } from 'react'
import PropTypes from 'prop-types'
import { useField, useFormikContext } from 'formik'
import SelectMenu from '../ui/SelectMenu'
import Chip from 'components/ui/Chip'
import Typography from '../ui/Typography'
import styles from './MultiSelectInput.module.sass'
import classNames from 'classnames/bind'
import { isEmpty } from 'utils'
import Icon from 'components/ui/Icon'

const cx = classNames.bind(styles)

/**
 * PSL UI select input integration with formik
 * @param options
 * @param suggestionsLimit
 * @param traditional
 * @param props
 * @returns {*}
 * @constructor
 */
const MultiSelectInput = ({
  traditional = true,
  options,
  containerClassName,
  label,
  ...props
}) => {
  const [field, meta] = useField({ ...props, type: 'select' })
  const [inputValue, setInputValue] = useState('')
  const { setFieldValue } = useFormikContext()
  const fieldName = props.name
  const onSuggestionSelected = (e, { suggestion }) => {
    if (!field.value.find(v => v.key === suggestion.key)) {
      setFieldValue(fieldName, [...field.value, suggestion])
      setInputValue('')
    }
  }
  const onChange = (e, { newValue }) => {
    if (typeof props.onChange === 'function') {
      props.onChange(newValue)
    }
    setInputValue(newValue)
  }
  const onDelete = key => {
    setFieldValue(
      fieldName,
      field.value.filter(v => v.key !== key)
    )
  }
  const renderSelectedValues = () => {
    return (
      !isEmpty(field.value) && (
        <div className={styles.selectedValues}>
          {field.value.map(({ key, label }) => (
            <Chip
              key={key}
              className={styles.chip}
              label={label}
              deleteIcon={
                <Icon
                  icon={'svg/material-design-icons/navigation/close'}
                  iconColor="rgba(0,0,0,0.84)"
                  iconSize={15}
                />
              }
              onDelete={() => onDelete(key)}
            />
          ))}
        </div>
      )
    )
  }
  return (
    <div className={cx(styles.container, containerClassName)}>
      {traditional && (
        <label className={styles.label} htmlFor={fieldName}>
          {label}
        </label>
      )}
      <div
        className={cx(
          traditional && styles.inputContainer,
          props.disabled && styles.disabled
        )}
      >
        {traditional && renderSelectedValues()}
        <SelectMenu
          {...props}
          label={!traditional && label}
          id={fieldName}
          name={fieldName}
          className={cx(styles.input, { traditional })}
          options={options}
          traditional={traditional}
          showIcon={true}
          autoComplete="off" // Using unsupported autocomplete value to disable Chrome autocomplete
          onSuggestionSelected={onSuggestionSelected}
          value={inputValue}
          onChange={onChange}
          freeEntry
        />
        {!traditional && renderSelectedValues()}
      </div>
      {meta.touched && meta.error && (
        <Typography type="caption" color="error" style={{ fontSize: '12px' }}>
          {meta.error}
        </Typography>
      )}
    </div>
  )
}

MultiSelectInput.propTypes = {
  /**
   * Input name
   */
  name: PropTypes.string.isRequired,
  /**
   * Input label
   */
  label: PropTypes.string.isRequired,
  /**
   * Additional data for default select input
   */
  props: PropTypes.object,
  /**
   * object for select input options
   */
  options: PropTypes.object,
  /**
   * flag for traditional render
   */
  traditional: PropTypes.bool,
  /**
   * Input value
   */
  value: PropTypes.string,
  /**
   * Additonal className passed to the root element
   */
  containerClassName: PropTypes.string,
  /**
   * onChange callback
   */
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}
export default MultiSelectInput
