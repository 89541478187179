import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as dataCachesActions } from './DataCachesReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'

function* requestDataCaches(services, { payload }) {
  const AdminService = services('AdminService')

  const { id = '', functional } = payload

  const functionalToSend =
    functional === 'true' ? true : functional === 'false' ? false : null

  const objectToSearch = {
    ...payload,
    id: { like: id },
    functional: functionalToSend
  }

  try {
    const { data = [], pagination = {} } = yield call(
      [AdminService, 'searchDataCaches'],
      objectToSearch
    )

    yield put(dataCachesActions.requestDataCachesSuccess({ data, pagination }))
  } catch (e) {
    yield put(dataCachesActions.requestDataCachesFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to fetch DataCaches',
        duration: 0
      })
    )
  }
}

function* clearFunctionalDataCaches(services, { payload }) {
  const AdminService = services('AdminService')

  try {
    const response = yield call([AdminService, 'clearFunctionalDataCaches'])

    yield put(dataCachesActions.requestDataCaches(payload))
    // yield put(dataCachesActions.clearFunctionalDataCachesRequestSuccess())
    yield put(
      notificationActions.notificationEnqueue({
        message: response.message,
        duration: 0
      })
    )
  } catch (e) {
    yield put(dataCachesActions.clearFunctionalDataCachesRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to clear functional data caches.',
        duration: 0
      })
    )
  }
}

function* clearIndividualDataCaches(services, { payload }) {
  const AdminService = services('AdminService')

  try {
    const response = yield call([AdminService, 'clearIndividualDataCaches'])

    yield put(dataCachesActions.requestDataCaches(payload))
    // yield put(dataCachesActions.clearFunctionalDataCachesRequestSuccess())
    yield put(
      notificationActions.notificationEnqueue({
        message: response.message,
        duration: 0
      })
    )
  } catch (e) {
    yield put(dataCachesActions.clearFunctionalDataCachesRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to clear individual data caches.',
        duration: 0
      })
    )
  }
}

function* rebuildDataCaches(services, { payload }) {
  const AdminService = services('AdminService')

  try {
    const response = yield call([AdminService, 'rebuildAllDataCaches'])

    yield put(dataCachesActions.requestDataCaches(payload))
    yield put(
      notificationActions.notificationEnqueue({
        message: response.message,
        duration: 0
      })
    )
  } catch (e) {
    yield put(dataCachesActions.rebuildDataCachesRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to rebuild data caches.',
        duration: 0
      })
    )
  }
}

function* deleteDataCaches(services, { payload }) {
  const AdminService = services('AdminService')
  const { selectedId } = payload
  delete payload.selectedId

  try {
    const response = yield call([AdminService, 'deleteDataCache'], selectedId)
    yield put(dataCachesActions.requestDataCaches(payload))
    yield put(
      notificationActions.notificationEnqueue({
        message: response.message,
        duration: 0
      })
    )
  } catch (e) {
    yield put(dataCachesActions.deleteDataCachesRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to delete DC record.',
        duration: 0
      })
    )
  }
}

function* downloadDataCachesRecord(services, { payload: { id, type } }) {
  const AdminService = services('AdminService')

  try {
    const response = yield call([AdminService, 'getDataCacheRecord'], id, type)
    const name = type === 'record' ? `${id}_record.json` : `${id}_options.json`
    const payload = { response, name }
    yield put(dataCachesActions.downloadDataCachesRequestSuccess(payload))
  } catch (e) {
    yield put(dataCachesActions.downloadDataCachesRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to download DC record.',
        duration: 0
      })
    )
  }
}

function* clearDataCachesRecordOptions(
  services,
  { payload: { formStatePr, id } }
) {
  const AdminService = services('AdminService')

  const response = yield call([AdminService, 'clearDataCacheOptions'], id)
  let message = response.message
  if (response.status === 'OK') {
    message = `${response.message} for: ${response.responseData.dcId}`
  }
  yield put(dataCachesActions.requestDataCaches(formStatePr))
  yield put(dataCachesActions.clearDataCachesRecordFinished())
  yield put(
    notificationActions.notificationEnqueue({
      message,
      duration: 0
    })
  )
}

function* rebuildDataCachesRecord(services, { payload: { formStatePr, id } }) {
  const AdminService = services('AdminService')

  const { message, responseData, status } = yield call(
    [AdminService, 'rebuildDataCachesById'],
    id
  )
  let notificationMessage = message
  if (status === 'OK') {
    notificationMessage = `${message} for: ${responseData.id}`
  }
  yield put(
    notificationActions.notificationEnqueue({
      message: notificationMessage,
      duration: 0
    })
  )
  yield put(dataCachesActions.requestDataCaches(formStatePr))
  yield put(dataCachesActions.rebuildDataCachesRecordFinished())
}

export default function* watchDataCachesRequest(services) {
  yield all([takeEvery('DATA_CACHES_REQUEST', requestDataCaches, services)])
  yield all([
    takeEvery(
      'DATA_CACHES_CLEAR_FUNCTIONAL_REQUEST',
      clearFunctionalDataCaches,
      services
    )
  ])
  yield all([
    takeEvery(
      'DATA_CACHES_CLEAR_INDIVIDUAL_REQUEST',
      clearIndividualDataCaches,
      services
    )
  ])
  yield all([
    takeEvery('DATA_CACHES_REBUILD_REQUEST', rebuildDataCaches, services)
  ])
  yield all([
    takeEvery('DATA_CACHES_DELETE_REQUEST', deleteDataCaches, services)
  ])
  yield all([
    takeEvery(
      'DATA_CACHES_DOWNLOAD_REQUEST',
      downloadDataCachesRecord,
      services
    )
  ])
  yield all([
    takeEvery(
      'DATA_CACHES_CLEAR_RECORD_REQUEST',
      clearDataCachesRecordOptions,
      services
    )
  ])
  yield all([
    takeEvery(
      'DATA_CACHES_REBUILD_RECORD_REQUEST',
      rebuildDataCachesRecord,
      services
    )
  ])
}
