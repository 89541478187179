import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'modules/bootstrap/BootstrapReducer'
import PropTypes from 'prop-types'

const Bootstrap = ({ children }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.requestBootstrap())
  }, [dispatch])
  return children
}
Bootstrap.propTypes = {
  children: PropTypes.node
}

export default Bootstrap
