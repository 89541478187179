import { all, call, put, takeEvery, select, throttle } from 'redux-saga/effects'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as trackActions } from 'modules/tracking/TrackingReducer'
import { actions as jobStatusSearchActions } from 'modules/jobStatusList/JobStatusListReducer'
import { smartsEndpoints } from '../../constants'
import { getAccessToken } from 'modules/auth/AuthSelector'
import {
  getSearchState,
  getPaginationState,
  getAssignForm
} from 'modules/jobStatusList/JobStatusListSelector'
import { LOCATION_CHANGE } from 'connected-react-router'
import i18next from 'i18next'
//import { isEmpty } from 'utils'

/**
 * Receives search request action and reacts accordingly
 *
 * @param services
 */
function* searchRequest(services) {
  const JobStatusService = services('JobStatusService')
  const SmartsParser = services('SmartsParser')
  try {
    yield put(trackActions.trackSubmitAction({}))
    // GET ML TOKEN
    const token = yield select(getAccessToken())

    // GET PAGINATION & SEARCH STATE
    const searchState = yield select(getSearchState())
    const paginationState = yield select(getPaginationState())
    const data = yield call([SmartsParser, 'listsSearchParser'], {
      ...searchState,
      ...paginationState
    })
    const {
      responseData: {
        data: results,
        pagination: { total: records }
      }
    } = yield call(
      [JobStatusService, 'request'],
      'post',
      smartsEndpoints.listsSearch,
      data,
      token
    )
    yield put(
      jobStatusSearchActions.searchRequestSuccess({
        list: results,
        total: records
      })
    )
  } catch ({ response }) {
    const {
      data: { message }
    } = response
    yield put(jobStatusSearchActions.searchRequestFail({ error: message }))
    yield put(
      notificationActions.notificationEnqueue({
        message,
        duration: 0
      })
    )
  }
}

function* listIdsRequest(services, { payload = {} }) {
  const JobStatusService = services('JobStatusService')
  try {
    // GET ML TOKEN
    const token = yield select(getAccessToken())

    const {
      responseData: { data: options }
    } = yield call(
      [JobStatusService, 'request'],
      'post',
      smartsEndpoints.listsCombinationsFetchListsEndpoint,
      {
        ...payload,
        filters: { workflow: 'ListIngestion', ...payload.filters }
      },
      token
    )
    const parsedOptions = options.reduce((acc, option) => {
      acc[option.key] = option.caption
      return acc
    }, {})
    yield put(
      jobStatusSearchActions.requestListIdsSuccess({
        options: parsedOptions
      })
    )
  } catch ({ response }) {
    const {
      data: { message }
    } = response
    yield put(jobStatusSearchActions.requestListIdsFail({ error: message }))
    yield put(
      notificationActions.notificationEnqueue({
        message,
        duration: 0
      })
    )
  }
}

function* listStatusRequest(services) {
  const JobStatusService = services('JobStatusService')
  try {
    // GET ML TOKEN
    const token = yield select(getAccessToken())

    const {
      responseData: { data: options }
    } = yield call(
      [JobStatusService, 'request'],
      'POST',
      smartsEndpoints.listsLookupStatuses,
      {},
      token
    )
    const parsedOptions = options.reduce((acc, option) => {
      acc[option.key] = option.caption
      return acc
    }, {})
    yield put(
      jobStatusSearchActions.requestListStatusSuccess({
        options: parsedOptions
      })
    )
  } catch ({ response }) {
    const {
      data: { message }
    } = response
    yield put(jobStatusSearchActions.requestListStatusFail({ error: message }))
    yield put(
      notificationActions.notificationEnqueue({
        message,
        duration: 0
      })
    )
  }
}

function* listMetadataDomainsRequest(services) {
  const JobStatusService = services('JobStatusService')
  try {
    // GET ML TOKEN
    const token = yield select(getAccessToken())

    const {
      responseData: { data: options }
    } = yield call(
      [JobStatusService, 'request'],
      'POST',
      smartsEndpoints.listsLookupDomains,
      {},
      token
    )
    const parsedOptions = options.reduce((acc, option) => {
      acc[option.key] = option.caption
      return acc
    }, {})
    yield put(
      jobStatusSearchActions.requestListMetadataDomainsSuccess({
        options: parsedOptions
      })
    )
  } catch ({ message }) {
    if (message) {
      yield put(
        jobStatusSearchActions.requestListMetadataDomainsFail({
          error: message
        })
      )
      yield put(
        notificationActions.notificationEnqueue({
          message,
          duration: 5000
        })
      )
    } else {
      yield put(
        jobStatusSearchActions.requestListMetadataDomainsFail({
          error: i18next.t('registrationFail')
        })
      )
      yield put(
        notificationActions.notificationEnqueue({
          message: i18next.t('registrationFail'),
          duration: 5000
        })
      )
    }
  }
}

function* assignListRequest(services) {
  const JobStatusService = services('JobStatusService')
  try {
    yield put(trackActions.trackSubmitAction({}))
    // GET ML TOKEN
    const token = yield select(getAccessToken())

    // GET ASSIGN FORM STATE
    const { listId, emailsList } = yield select(getAssignForm())

    yield call(
      [JobStatusService, 'request'],
      'put',
      smartsEndpoints.listsShareEmails,
      { listId, emails: emailsList },
      token
    )
    yield put(jobStatusSearchActions.assignListSuccess())

    // CALL SEARCH SAGA TO UPDATE LIST
    yield call(searchRequest, services)
    yield put(
      notificationActions.notificationEnqueue({
        message: `List ID: ${listId} updated successfully`,
        duration: 0
      })
    )
  } catch ({ message }) {
    if (message) {
      yield put(jobStatusSearchActions.assignListFail())
      yield put(
        notificationActions.notificationEnqueue({
          message: message || i18next.t('registrationFail'),
          duration: 5000
        })
      )
    } else {
      yield put(jobStatusSearchActions.assignListFail())
      yield put(
        notificationActions.notificationEnqueue({
          message: i18next.t('registrationFail'),
          duration: 5000
        })
      )
    }
  }
}

function* openAssignListRequest(services) {
  const JobStatusService = services('JobStatusService')
  try {
    // GET ML TOKEN
    const token = yield select(getAccessToken())

    // GET ASSIGN FORM STATE
    const { listId } = yield select(getAssignForm())

    // REQUEST list of shared emails
    const {
      responseData: { emails }
    } = yield call(
      [JobStatusService, 'request'],
      'post',
      smartsEndpoints.listsShareEmails,
      { listId },
      token
    )
    yield put(
      jobStatusSearchActions.assignListUpdateEmails({ emailsList: emails })
    )
  } catch ({ message }) {
    if (message) {
      yield put(jobStatusSearchActions.assignListFail())
      yield put(
        notificationActions.notificationEnqueue({
          message: message || i18next.t('registrationFail'),
          duration: 5000
        })
      )
    } else {
      yield put(jobStatusSearchActions.assignListFail())
      yield put(
        notificationActions.notificationEnqueue({
          message: i18next.t('registrationFail'),
          duration: 5000
        })
      )
    }
  }
}

function* resetForm(services, { payload }) {
  const { action } = payload

  if (action === 'REPLACE') {
    yield put(
      jobStatusSearchActions.setFormState({
        startDate: null,
        endDate: null,
        listId: '',
        // listId_key: null,
        status: null,
        domain: null,
        masterProject: ''
      })
    )
  }
}

export default function* watchSearch(services) {
  yield all([takeEvery('JOB_STATUS_SEARCH_REQUEST', searchRequest, services)])
  yield all([
    takeEvery('JOB_STATUS_LIST_STATUS_REQUEST', listStatusRequest, services)
  ])
  yield all([
    takeEvery(
      'JOB_STATUS_LIST_METADATA_DOMAINS_REQUEST',
      listMetadataDomainsRequest,
      services
    )
  ])
  yield throttle(500, 'JOB_STATUS_LIST_IDS_REQUEST', listIdsRequest, services)
  yield all([
    takeEvery('JOB_STATUS_ASSIGN_LIST_REQUEST', assignListRequest, services)
  ])
  yield all([
    takeEvery('JOB_STATUS_ASSIGN_LIST_OPEN', openAssignListRequest, services)
  ])
  yield all([takeEvery(LOCATION_CHANGE, resetForm, services)])
}
