import { createReducer, createAction } from '@reduxjs/toolkit'

export const faqLoadRequest = createAction('FAQ_LOAD_REQUEST')
export const faqLoadSuccessRequest = createAction('FAQ_LOAD_SUCCESS_REQUEST')
export const actions = {
  faqLoadRequest,
  faqLoadSuccessRequest
}

const initialValue = {
  faqList: {},
  isFetching: true
}

const faqPageReducer = createReducer(initialValue, {
  [faqLoadSuccessRequest]: (state, { payload }) => {
    state.isFetching = false
    state.faqList = payload
  }
})

export default faqPageReducer
