import { createSelector } from '@reduxjs/toolkit'

export const universeSelector = state => state.universeReducer

export const getFile = () =>
  createSelector(universeSelector, universe => universe.file)
export const getIsFetching = () =>
  createSelector(universeSelector, universe => universe.fetching)
export const getRequestType = () =>
  createSelector(universeSelector, universe => universe.type)
export const getSearchFetching = () =>
  createSelector(universeSelector, universe => universe.searchFetching)
export const getPagination = () =>
  createSelector(universeSelector, universe => universe.pagination)
export const getUniverse = () =>
  createSelector(universeSelector, universe => universe.universe)
export const getSearchState = () =>
  createSelector(universeSelector, universe => universe.searchState)
export const getSpecialtyList = () =>
  createSelector(universeSelector, universe => universe.specialties)
export const getProfessionList = () =>
  createSelector(universeSelector, universe => universe.professions)
