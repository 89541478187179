import PropTypes from 'prop-types'
import Avatar from '../Avatar'
import styles from './ListItemAvatar.module.sass'
import cx from 'classnames'

const ListItemAvatar = ({ right, className, size, ...other }, { dense }) => (
  <Avatar
    {...other}
    className={cx(
      styles.avatar,
      right && styles.right,
      dense && styles.dense,
      className
    )}
    size={size || (dense ? 32 : 40)}
  />
)

ListItemAvatar.displayName = 'ListItemAvatar'
ListItemAvatar.propTypes = {
  /** The size of the avatar. This determines the size even when "dense" is `true` */
  size: PropTypes.number,
  /**
   * If `true`, this will be given styling allowing right alignment
   */
  right: PropTypes.bool,
  /** An additional custom className for the root element */
  className: PropTypes.string
}
ListItemAvatar.contextTypes = {
  /**
   * If `true`, compact vertical padding designed for keyboard and mouse input will be used.
   */
  dense: PropTypes.bool
}
export default ListItemAvatar
