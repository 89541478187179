import ExploreListForm from './forms/ExploreListForm'
import ProjectMetadata from './forms/ProjectMetadataForm'
import ChartsForm from './forms/ChartsForm'
import { ShareResultsPage } from 'modules/Charts/Charts'

export const initialValues = {
  activeStep: 'list-selection',
  selectedList: [],
  selectedListLabels: [],
  ids: [''],
  combinationsCountry: '',
  combinationsProfession: '',
  combinationsSpecialty: '',
  combinations: [],
  // targetSpeciality: [],
  // targetSpecialtyBoolean: false,
  // filters
  filters: {
    practice: {
      '': true,
      countOfGroupPractice: false,
      countOfSoloPractice: false,
      countOfHospital: false
    },
    groupPractice: {
      '': true,
      '0_9': false,
      '10_49': false,
      '50_99': false,
      '100_plus': false
    },
    hospitalSize: {
      '': true,
      '0_99': false,
      '100_249': false,
      '250_499': false,
      '500_plus': false
    },
    yearsInPractice: {
      '': true,
      '1_10': false,
      '11_20': false,
      '21_30': false,
      '31_40': false,
      '41_plus': false
    },
    gender: {
      '': true,
      female: false,
      male: false
    },
    lift: {
      from: '',
      to: ''
    },
    generational: {
      '': true,
      boomers: false,
      genX: false,
      millenials: false
    },
    leadershipStature: {
      '': true,
      internationalLeader: false,
      nationalLeader: false,
      wla: false,
      recentAuthor: false
    },
    industryAffinity: {
      '': true,
      isPharmaChampion: false,
      isPharmaCordial: false,
      isPharmaShy: false
    },
    hospitalType: {
      '': true,
      no: false,
      countOfPlaceTypeHospitalCommunity: false,
      countOfPlaceTypeHospitalTeaching: false,
      countOfPlaceTypeHospitalUniversity: false
    },
    idn: {
      '': true,
      no: false,
      yes: false
    },
    propensity: {
      '': true,
      no: false,
      yes: false
    }
  },

  allMembersSelected: true,
  type: '',

  // Project Metadata
  domain: '',
  clientProduct: '',
  brand: '',
  client: '',
  masterProject: '',
  project: '',
  reportName: '',
  notes: '',

  // Charts
  universe: 0,
  listSize: 0,
  chartsData: {}
}

export const steps = {
  'list-selection': {
    component: <ExploreListForm />
  },
  filters: {
    component: <ExploreListForm />
  },
  'project-metadata': {
    component: <ProjectMetadata />
  },
  charts: {
    component: <ChartsForm />
  },
  'share-results': {
    component: <ShareResultsPage />
  }
}
