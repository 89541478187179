import PropTypes from 'prop-types'
import Icon from 'components/ui/Icon'
import { RaisedButton } from 'components/Button'
import TextInput from 'components/TextInput'
import styles from './CombinationTable.module.sass'
import { isEmpty } from 'utils'
import Typography from 'components/ui/Typography'
import { Fragment } from 'react'

function SpecialtyCountryTable({ name, formProps, disabled = false }) {
  const { setFieldValue, values } = formProps
  const { allMembersSelected } = values

  const addElement = (setFieldValue, values) => {
    if (
      !isEmpty(values[name].country) ||
      !isEmpty(values[name].specialty) ||
      !isEmpty(values[name].listSize) ||
      !isEmpty(values[name].targetSampleSize) ||
      !isEmpty(values[name].surveyParticipants) ||
      !isEmpty(values[name].screenedOut)
    ) {
      setFieldValue(`${name}Array`, [
        ...values[`${name}Array`],
        {
          id: values[`${name}Array`].length + 1,
          ...values[name]
        }
      ])
    }
  }

  const deleteElement = (id, values, setFieldValue) => {
    const newSpecialtyCountry = values[`${name}Array`].filter(
      combination => combination.id !== id
    )
    setFieldValue(`${name}Array`, newSpecialtyCountry)
  }

  return (
    <>
      <div className={styles.gridForm}>
        <TextInput
          label="Country"
          className={styles.countryArray}
          name={`${name}.country`}
          type="text"
          traditional
        />
        <TextInput
          label="Area of medicine"
          name={`${name}.specialty`}
          type="text"
          traditional
        />
        <TextInput
          label="List Size"
          className={styles.listSizeArray}
          name={`${name}.listSize`}
          type="number"
          traditional
        />
        <TextInput
          label="Target Sample Size"
          name={`${name}.targetSampleSize`}
          type="number"
          traditional
        />
        <TextInput
          label="Survey Participants"
          name={`${name}.surveyParticipants`}
          type="number"
          traditional
        />
        <TextInput
          label="Screened Out"
          name={`${name}.screenedOut`}
          type="number"
          traditional
        />
      </div>
      <div className={styles.actionsContainer}>
        <RaisedButton
          type="button"
          onClick={() => addElement(setFieldValue, values)}
          fontSize="14px"
        >
          <Icon icon="svg/custom/add-circle" colorTier="tertiary" /> Add
          Country/Specialty
        </RaisedButton>
      </div>
      {!allMembersSelected && !disabled && !isEmpty(values[`${name}Array`]) && (
        <div className={styles.gridTable}>
          <div className={styles.rowHeader}>
            <Typography type="display1">Country</Typography>
          </div>
          <div className={styles.rowHeader}>
            <Typography type="display1">Area of Medicine</Typography>
          </div>
          <div className={styles.rowHeader}>
            <Typography type="display1">List Size</Typography>
          </div>
          <div className={styles.rowHeader}>
            <Typography type="display1">Target Sample Size</Typography>
          </div>
          <div className={styles.rowHeader}>
            <Typography type="display1">Survey Participants</Typography>
          </div>
          <div className={styles.rowHeader}>
            <Typography type="display1">Screened Out</Typography>
          </div>
          <div />
          {values[`${name}Array`].map(item => (
            <Fragment key={item.id}>
              <Typography>{item.country || '\u00A0'}</Typography>
              <Typography>{item.specialty || '\u00A0'}</Typography>
              <Typography>{item.listSize}</Typography>
              <Typography>{item.targetSampleSize}</Typography>
              <Typography>{item.surveyParticipants}</Typography>
              <Typography>{item.screenedOut}</Typography>
              <Icon
                icon="svg/custom/delete"
                colorTier="action"
                button
                onClick={() => deleteElement(item.id, values, setFieldValue)}
              />
            </Fragment>
          ))}
        </div>
      )}
    </>
  )
}

SpecialtyCountryTable.propTypes = {
  /**
   * Form name
   */
  name: PropTypes.string,
  /**
   * Form props
   */
  formProps: PropTypes.object,
  /**
   * if true it will disable all inputs
   */
  disabled: PropTypes.object
}

export default SpecialtyCountryTable
