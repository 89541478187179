import PropTypes from 'prop-types'
import Typeahead from 'modules/typeahead'
import DateInput from 'components/DateInput'
import { smartsEndpoints } from 'constants/apiConstants'
import styles from './form.module.sass'
import { isEmpty } from 'utils'

function ProjectForm({ formProps }) {
  const {
    projects,
    masterProjects
    // startDate,
    // endDate,
    // engagementType
  } = formProps.values

  return (
    <div className={styles.form}>
      <Typeahead
        formName="explore-project"
        label="Project ID *"
        name="projects"
        traditional
        disabled={!isEmpty(masterProjects)}
        multiselect
        body={{
          data: {
            filters: { workflow: 'ExploreProject' },
            search: {
              perPage: 100
            }
          },
          endpoint: smartsEndpoints.listsCombinationsFetchProjectsEndpoint,
          parseFunction: 'optionsParser'
        }}
      />
      <Typeahead
        formName="explore-project"
        label="Master Project ID *"
        name="masterProjects"
        traditional
        disabled={!isEmpty(projects)}
        multiselect
        body={{
          data: {
            filters: { workflow: 'ExploreProject' },
            search: {
              perPage: 100
            }
          },
          endpoint:
            smartsEndpoints.listsCombinationsFetchMasterProjectsEndpoint,
          parseFunction: 'optionsParser'
        }}
      />
      <DateInput name="startDate" label="Activity Start Date" required />
      <DateInput name="endDate" label="Activity End Date" required />
      <Typeahead
        formName="explore-project"
        label="Engagement Type"
        name="engagementType"
        required
        traditional
        body={{
          data: {
            filters: { workflow: 'ExploreProject' },
            search: {
              perPage: 100
            }
          },
          endpoint:
            smartsEndpoints.listsCombinationsFetchEngagementTypesEndpoint,
          parseFunction: 'optionsParser'
        }}
      />
    </div>
  )
}

ProjectForm.propTypes = {
  /**
   * Form props
   */
  formProps: PropTypes.object
}

export default ProjectForm
