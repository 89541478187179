import { Button as ButtonUI } from 'components/ui/Button'
import PropTypes from 'prop-types'
import styles from './RaisedButton.module.sass'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const RaisedButton = ({ className, children, ...props }) => {
  return (
    <ButtonUI
      className={cx(styles.container, className)}
      raised={false}
      color="tertiary"
      textCase="uppercase"
      fontSize="14px"
      {...props}
    >
      {children}
    </ButtonUI>
  )
}
RaisedButton.propTypes = {
  /**
   * Extra class name passed to Button container
   */
  className: PropTypes.string,
  /**
   * Component children
   */
  children: PropTypes.node
}
export default RaisedButton
