import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as contactUsActions } from './ContactUsReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as trackActions } from 'modules/tracking/TrackingReducer'

/**
 * Request submit form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitFormRequest(services, { payload }) {
  const SmartsService = services('SmartsService')
  try {
    const { userAgent, lastAction, name, message, history } = payload.payload
    const dataToSend = {
      userAgent,
      lastAction,
      fields: {
        name
      },
      message
    }
    yield put(trackActions.trackSubmitAction({}))
    const response = yield call([SmartsService, 'sendFeedback'], dataToSend)

    yield put(
      notificationActions.notificationEnqueue({
        message: response,
        duration: 0
      })
    )

    yield put(contactUsActions.submitFormRequestSuccess())
    yield call([history, history.replace], { pathname: '/' })
  } catch (e) {
    yield put(contactUsActions.submitFormRequestFail())
    console.error(e)
  }
}

export default function* watchContactUsRequest(services) {
  yield all([
    takeEvery('CONTACT_US_SUBMIT_FORM_REQUEST', submitFormRequest, services)
  ])
}
