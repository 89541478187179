import doctorsorders from './doctorsorders'
import dgconnect from './dgconnect'
import medelists from './medelists'
import numedis from './numedis'
import peeraudience from './peeraudience'
import peerdirect from './peerdirect'
import pvi from './pvi'
import scriptlift from './scriptlift'

export const images = {
  medelists,
  numedis,
  scriptlift,
  doctorsorders,
  dgconnect,
  peeraudience,
  peerdirect,
  pvi
}

export default images
