import { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Divider, Typography } from '@mui/material'

import { styled } from '@mui/material/styles'

/**
 * Footer Container (section)
 * Main Container of the Footer Component
 */
const FooterContainer = styled('section')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  '& a': {
    textDecoration: 'underline'
  },
  ...theme.components.Footer.styleOverrides.container
}))

/**
 * Footer Divider (Divider)
 * Divides the Component itself from other possible sibling components
 */
const FooterDivider = styled(Divider)(() => ({
  width: '100%',
  margin: '17px auto'
}))

/**
 * Footer Row (div)
 * Main text row of the Footer
 */
const FooterRow = styled('div')(({ theme }) => ({
  justifyContent: 'center',
  flexWrap: 'wrap',
  ...theme.components.Footer.styleOverrides.row
}))

/**
 * Footer Text (Typography)
 * Text Styles for all text in Footer
 */
const FooterText = styled(Typography)(() => ({
  color: '#667085',
  fontSize: '12px',
  lineHeight: 1.8,
  flexShrink: 0,
  flexWrap: 'nowrap'
}))

/**
 * Link Container (div)
 */
const LinkContainer = styled('div')(() => ({
  justifyContent: 'center',
  flexWrap: 'wrap'
}))

/**
 * Footer Link (FooterText)
 */
const FooterLink = styled(FooterText)(({ theme }) => ({
  padding: '0px 14px',
  textUnderlineOffset: '3px',
  lineHeight: 1,
  ...theme.components.Footer.styleOverrides.link
}))

const Footer = ({
  text,
  copyright,
  linkComponent,
  links,
  hasDivider,
  activeTheme,
  handleFooterClick = () => {}
}) => (
  <FooterContainer data-testid="section">
    {hasDivider && <FooterDivider />}
    <FooterRow>
      {text && (
        <>
          <FooterText data-testid="text" type="footnote" align={'center'}>
            {text[0]}
            {activeTheme === 'dougallgpt' && <span>&nbsp;|&nbsp;</span>}
          </FooterText>
          <FooterText data-testid="text" type="footnote" align={'center'}>
            {text.slice(-2).join(', ')}
          </FooterText>
        </>
      )}
      {copyright && (
        <FooterText
          data-testid="copyright"
          type="footnote"
          align={'center'}
          marginLeft={activeTheme === 'dougallgpt' ? '16px' : '0'}
          sx={{ lineHeight: 1 }}
        >
          {copyright}
        </FooterText>
      )}
    </FooterRow>
    <LinkContainer
      data-testid="linkContainer"
      sx={{
        display: activeTheme === 'dougallgpt' ? 'flex' : 'inline-flex'
      }}
    >
      {links?.map(({ link, title: linkTitle, trackAction = '' }, linkIndex) => {
        const isExternalLink = link.startsWith('http') || link.startsWith('//')

        return (
          <Fragment key={linkTitle}>
            {linkIndex !== 0 && activeTheme === 'dougallmd' && (
              <span style={{ color: '#cccccc' }}>&nbsp;|&nbsp;</span>
            )}
            <FooterLink
              data-testid="link"
              component={isExternalLink ? 'a' : linkComponent}
              type="footnote"
              target={isExternalLink ? '_blank' : null}
              to={isExternalLink ? undefined : link}
              href={isExternalLink ? link : undefined}
              onClick={() => handleFooterClick(trackAction)}
            >
              {linkTitle}
            </FooterLink>
          </Fragment>
        )
      })}
    </LinkContainer>
  </FooterContainer>
)
Footer.displayName = 'Footer'
Footer.propTypes = {
  /**
   * An array with the footer menu links
   */
  links: PropTypes.array,
  /**
   * Text on the left of footer menu
   */
  text: PropTypes.array,
  /**
   * Copyright statement for the footer
   */
  copyright: PropTypes.string,
  /**
   * Component for Link
   */
  linkComponent: PropTypes.any,
  /**
   * If the footer need a Divider
   */
  hasDivider: PropTypes.bool,
  /**
   * Active App Theme
   */
  activeTheme: PropTypes.string,
  /**
   * An onClick handler function
   */
  handleFooterClick: PropTypes.func
}
export default Footer
