import { Formik } from 'formik'
import { Fragment } from 'react'
import TextInput from 'components/TextInput'
import BarChart from 'components/BarChart'
import Checkbox from 'components/Checkbox'
import { useSelector, useDispatch } from 'react-redux'
import {
  getCurrentChart,
  getUploadedFiles
} from 'modules/Charts/ChartsSelector'
import { actions as chartsAction } from 'modules/Charts/ChartsReducer'
import TextAreaInput from 'components/TextAreaInput'
import Button from 'components/Button'
import styles from './form.module.sass'
import PropTypes from 'prop-types'
import Typography from 'components/ui/Typography'
import * as Yup from 'yup'

const shareResultsSchema = Yup.object().shape({
  toEmail: Yup.string()
    .email(({ value }) => `${value} is not a valid email`)
    .required('This field is required')
})

const ShareResults = ({ formProps }) => {
  const chart = useSelector(getCurrentChart() || [])
  const files = useSelector(getUploadedFiles() || [])
  const dispatch = useDispatch()
  const { values } = formProps
  const { combinations = [], listSize, universe, tableCombinations } = values
  const { _key = null } = chart
  let [country = '', profession = '', specialty = ''] = _key
    ? _key.split('|')
    : [null, null, null]
  let emailCombinations = []

  if (Array.isArray(combinations) && combinations.length) {
    if (!_key) {
      country = combinations[0].country
      profession = combinations[0].profession
      specialty = combinations[0].specialty

      if (combinations.length === 1) {
        emailCombinations.push({
          country,
          profession,
          specialty: specialty || null
        })
      } else {
        combinations.forEach(combination => {
          emailCombinations.push({
            country: combination.country,
            profession: combination.profession,
            specialty: combination.specialty || null
          })
        })
      }
    } else {
      emailCombinations.push({
        country,
        profession,
        specialty: specialty || null
      })
    }
  }

  const handleSubmit = values => {
    const title = chart.title
    const image = files[0]
    dispatch(
      chartsAction.chartShareRequest({
        ...values,
        image,
        title,
        combinations: emailCombinations,
        universe: universeTotal.toLocaleString(),
        listSize: listTotal.toLocaleString()
      })
    )
  }

  // Get combination universe & list size
  const singleCombination =
    _key &&
    tableCombinations.find(
      c =>
        c.country === country &&
        c.profession === profession &&
        c.specialty === specialty
    )

  const universeTotal = _key ? singleCombination._universe : universe
  const listTotal = _key ? singleCombination._total : listSize

  return (
    <Formik
      initialValues={{ toEmail: '', message: '', copyMe: false }}
      onSubmit={handleSubmit}
      validationSchema={shareResultsSchema}
    >
      {() => (
        <div>
          <Typography className={styles.chartTitle}>{chart.title}</Typography>
          {_key || (combinations && combinations.length === 1) ? (
            <div className={styles.shareHeader}>
              <Typography>
                Country: <span className={styles.bold}> {country}</span>
              </Typography>
              {specialty === '' ? (
                <Typography>
                  Profession: <span className={styles.bold}> {profession}</span>
                </Typography>
              ) : (
                <Typography>
                  Specialty: <span className={styles.bold}> {specialty}</span>
                </Typography>
              )}
            </div>
          ) : (
            combinations &&
            combinations.length > 1 && (
              <div className={styles.gridTable}>
                <div className={styles.rowHeader}>
                  <Fragment key="header">
                    <Typography type="display1" color="text-primary">
                      Country
                    </Typography>
                    <Typography type="display1" color="text-primary">
                      Profession / Specialty
                    </Typography>
                  </Fragment>
                </div>
                {combinations?.map((combination, index) => (
                  <Fragment key={index}>
                    <Typography>{combination.country || ''}</Typography>
                    <Typography>
                      {combination.specialty ?? combination.profession}
                    </Typography>
                  </Fragment>
                ))}
              </div>
            )
          )}
          <div className={styles.shareHeader} style={{ marginBottom: '1em' }}>
            <Typography>
              Estimated size of universe:{' '}
              <span className={styles.bold}>
                {' '}
                {universeTotal.toLocaleString()}
              </span>
            </Typography>
            <Typography>
              LiMA Member Count:{' '}
              <span className={styles.bold}> {listTotal.toLocaleString()}</span>
            </Typography>
          </div>
          <BarChart chart={chart} chartOnly />
          <div className={styles.container}>
            <TextInput label="To email" name="toEmail" type="text" required />
            <TextAreaInput
              label="Message"
              name="message"
              minHeight={120}
              className={styles.textArea}
            />
            <Checkbox
              label="Send copy to Myself"
              name="copyMe"
              className={styles.checkbox}
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button type="submit" isLimaSubmit className={styles.button} />
          </div>
        </div>
      )}
    </Formik>
  )
}

ShareResults.propTypes = {
  /**
   * FormProps
   */
  formProps: PropTypes.object
}

export default ShareResults
