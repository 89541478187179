import axios from 'axios'
import { isEmpty } from 'utils'

const MiscService = () => ({
  /**
   * Fetch Terms of Use JSON from CDN
   *
   * @param endpoint
   * @param token - access token
   * @param payload
   * @returns {Promise<*[]|*>}
   */
  async fetchMisc(endpoint, payload, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'POST',
      data: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const response = await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },
  /**
   * Fetch Terms of Use JSON from CDN
   *
   * @param endpoint
   * @param token - access token
   * @param payload
   * @returns {Promise<*[]|*>}
   */
  async updateMisc(endpoint, payload, token) {
    const axiosOptions = {
      url: endpoint,
      method: 'PUT',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const response = await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
    return response
  },
  /**
   * Delete Misc service
   *
   * @param endpoint
   * @param token - access token
   * @param payload
   * @returns {Promise<*[]|*>}
   */
  async deleteMisc(endpoint, payload, token) {
    const axiosOptions = {
      url: endpoint,
      method: 'DELETE',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const response = await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
    return response
  },
  /**
   * Fetch Terms of Use JSON from CDN
   *
   * @param endpoint
   * @param token - access token
   * @param brandCode
   * @returns {Promise<*[]|*>}
   */
  async getMiscFiles(endpoint, brandCode, token) {
    const axiosOptions = {
      url: `${endpoint}?brandCode=${brandCode}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const response = await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
    return response
  }
})

export default MiscService
