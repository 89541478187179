import React from 'react'
import PropTypes from 'prop-types'

const toCamelCase = variable =>
  variable.replace(/-([a-z])/g, (str, letter) => letter.toUpperCase())

const getInlineStyle = element => {
  return Array.from(element.style).reduce((style, name) => {
    style[toCamelCase(name)] = element.style[name]
    return style
  }, {})
}
export const createAcceptedMarkup = (node, key, acceptedElements) => {
  const tagName = node.nodeName.toLowerCase()
  if (acceptedElements.includes(tagName)) {
    const style = node.style?.length ? getInlineStyle(node) : {}
    const childNodes = node.childNodes
    const children = childNodes?.length ? [] : null
    if (children) {
      for (let childKey of Object.keys(childNodes)) {
        children.push(
          createAcceptedMarkup(childNodes[childKey], childKey, acceptedElements)
        )
      }
    }
    return React.createElement(
      tagName,
      {
        key,
        style
      },
      children
    )
  } else if (tagName === '#text') {
    // Text elements
    return node.textContent // node.data
  } else {
    // Not Allowed elements
    return node.outerHTML
  }
}

const Markup = ({ html, acceptedElements, isPlainText }) => {
  const parseRange = document.createRange()
  const parse = Range.prototype.createContextualFragment.bind(parseRange)
  const parsedHTML = parse(html)
  const { textContent: plainText, childNodes } = parsedHTML
  if (isPlainText) {
    return plainText
  }
  const result = []
  for (let key of Object.keys(childNodes)) {
    result.push(createAcceptedMarkup(childNodes[key], key, acceptedElements))
  }
  return result
}
Markup.displayName = 'Markup'
Markup.defaultProps = {
  acceptedElements: [
    'strong',
    'b',
    'p',
    'br',
    'span',
    'i',
    'em',
    'span',
    'sup',
    'sub',
    'a',
    'u'
  ],
  isPlainText: false
}
const propTypes = {
  /**
   * Array of accepted elements ['strong', 'b', 'p', 'br', 'span', 'i', 'em', 'span', 'sup', 'sub', 'a', 'u']
   */
  acceptedElements: PropTypes.array,
  /**
   * The html content
   */
  html: PropTypes.string,
  /**
   * If `true` will return plainText content
   */
  isPlainText: PropTypes.bool
}
Markup.propTypes = propTypes

export const MarkupElement = ({ className, ...props }) => (
  <span className={className}>
    <Markup {...props} />
  </span>
)
MarkupElement.propTypes = {
  /**
   * An additional custom className for the root element (only applied when using the named MarkupElement export)
   */
  className: PropTypes.string,
  ...propTypes
}

export default Markup
