import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as incomingActions } from './IncomingCountsReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'

/**
 * Request Incoming Counts rebuild
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* rebuildRequest(services, { payload }) {
  const AdminService = services('AdminService')
  try {
    yield call([AdminService, 'rebuildIncomingCounts'])
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Incoming Count records refreshed',
        duration: 0
      })
    )
    yield put(incomingActions.rebuildRequestSuccess())
    yield put(
      incomingActions.searchRequest({
        search: {
          country: '',
          profession: '',
          specialty: '',
          orderBy: '',
          order: '',
          page: 1,
          perPage: 25
        }
      })
    )
  } catch (e) {
    yield put(incomingActions.rebuildRequestFail())
    console.error(e)
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
  }
}

/**
 * Request Incoming Count Delete
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* deleteRequest(services) {
  const AdminService = services('AdminService')
  try {
    yield call([AdminService, 'deleteIncomingCounts'])
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Incoming Count records flushed',
        duration: 0
      })
    )
    yield put(incomingActions.deleteRequestSuccess())
    yield put(
      incomingActions.searchRequest({
        search: {
          country: '',
          profession: '',
          specialty: '',
          orderBy: '',
          order: '',
          page: 1,
          perPage: 25
        }
      })
    )
  } catch (e) {
    yield put(incomingActions.deleteRequestFail())
    console.error(e)
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
  }
}

/**
 * Request incoming counts search
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* searchRequest(services, { payload }) {
  const AdminService = services('AdminService')
  try {
    const {
      search: {
        country = '',
        profession = '',
        specialty = '',
        orderBy = '',
        order = '',
        page = 1,
        perPage = 25
      }
    } = payload
    const { data, pagination } = yield call([AdminService, 'searchIncoming'], {
      country,
      profession,
      specialty,
      orderBy,
      order,
      page,
      perPage
    })
    yield put(
      incomingActions.searchRequestSuccess({ counts: data, pagination })
    )
  } catch (e) {
    yield put(incomingActions.searchRequestFail())
    console.error(e)
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
  }
}

function* specialtyListRequest(services) {
  const AdvisorSearch = services('AdvisorSearchService')
  try {
    const specialties = yield call([AdvisorSearch, 'getSpecialtyList'])
    yield put(incomingActions.searchSpecialtiesSuccess(specialties))
  } catch (e) {
    console.error({ specialtiesListError: e })
    yield put(incomingActions.searchSpecialtiesFail())
  }
}

function* professionListRequest(services) {
  const AdvisorSearch = services('AdvisorSearchService')
  try {
    const professions = yield call([AdvisorSearch, 'getProfessionList'])
    yield put(incomingActions.searchProfessionsSuccess(professions))
  } catch (e) {
    console.error({ professionsListError: e })
    yield put(incomingActions.searchProfessionsFail())
  }
}

export default function* watchIncomingRequest(services) {
  yield all([takeEvery('INCOMING_REBUILD_REQUEST', rebuildRequest, services)])
  yield all([takeEvery('INCOMING_DELETE_REQUEST', deleteRequest, services)])
  yield all([takeEvery('INCOMING_SEARCH_REQUEST', searchRequest, services)])
  yield all([
    takeEvery('INCOMING_SPECIALTIES_REQUEST', specialtyListRequest, services),
    takeEvery('INCOMING_PROFESSIONS_REQUEST', professionListRequest, services)
  ])
}
