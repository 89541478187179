import { useState, useEffect } from 'react'
import Snackbar from 'components/ui/Snackbar'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'modules/notification/NotificationReducer'
import { getCurrentNotification } from 'modules/notification/NotificationSelector'
import styles from './Notification.module.sass'

const Notification = () => {
  const currentNotification = useSelector(getCurrentNotification)
  const dispatch = useDispatch()
  const [dismissing, setDismissing] = useState(false)
  useEffect(() => {
    let dismissalDelay
    if (dismissing) {
      dismissalDelay = setTimeout(() => {
        dispatch(actions.notificationDequeue(currentNotification.uid))
        setDismissing(false)
      }, 250)
    }
    return () => clearTimeout(dismissalDelay)
  }, [dismissing, currentNotification.uid, dispatch])
  const handleDismiss = () => {
    setDismissing(true)
    if (typeof currentNotification.onDismiss !== 'undefined') {
      currentNotification.onDismiss()
    }
    // setDismissing(false)
    dispatch(actions.notificationClear())
  }
  return currentNotification.uid ? (
    <Snackbar
      position="bottom"
      key={currentNotification.uid}
      dismissal={currentNotification.duration}
      onDismiss={handleDismiss}
      className={styles.notification}
      closeIcon="svg/peervoice/close"
    >
      {currentNotification.message}
    </Snackbar>
  ) : null
}

export default Notification
