import { Button as ButtonUI } from '../ui/Button'
import Icon from 'components/ui/Icon'
import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import styles from './Button.module.sass'
import classNames from 'classnames/bind'
import { CircularProgress } from 'components/ui/Progress'

const cx = classNames.bind(styles)

const Button = ({ className, children, isLimaSubmit, ...rest }) => {
  const {
    disabled,
    submitting,
    onClick = () => {},
    type = 'submit',
    icon
  } = rest
  const { submitForm } = useFormikContext()
  if (isLimaSubmit) {
    return (
      <div
        className={styles.limaSubmitContainer}
        data-testid="limaSmartsButton"
      >
        {submitting && (
          <CircularProgress
            className={styles.spinner}
            strokeBackgroundColor="#776cff"
            progress={null}
            strokeColor="#3f3987"
            fillColor="transparent"
            strokeWidth={3}
          />
        )}
        <Icon
          data-testid="limaSmartsButton-submit"
          className={cx(
            styles.limaSubmit,
            disabled || submitting ? styles.disabled : null,
            className
          )}
          colorTier="knockout"
          button
          disabled
          onClick={type === 'submit' ? submitForm : onClick}
          icon={icon}
        />
      </div>
    )
  }
  return (
    <ButtonUI
      className={cx(styles.container, disabled && styles.disabled, className)}
      {...rest}
    >
      {children}
    </ButtonUI>
  )
}
Button.propTypes = {
  /**
   * Extra class name passed to Button container
   */
  className: PropTypes.string,
  /**
   * Extra class name passed to Button container
   */
  isLimaSubmit: PropTypes.bool,
  /**
   * Component children
   */
  children: PropTypes.node
}
export default Button
