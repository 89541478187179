import PropTypes from 'prop-types'
import Typography from '../ui/Typography'
import styles from './ListSize.module.sass'
import classNames from 'classnames/bind'
import Tooltip from 'components/Tooltip'

const cx = classNames.bind(styles)
/**
 * List Size display component
 * @param traditional
 * @param type
 * @param props
 * @returns {*}
 * @constructor
 */
const ListSize = ({ hidden = false, universeSize, listSize, className }) => {
  return (
    !hidden && (
      <div className={cx(className, styles.container)}>
        {universeSize > 0 && (
          <div className={styles.textContainer}>
            <Typography type="body1">
              Estimated size of universe:{' '}
              <strong>{universeSize.toLocaleString()} </strong>
            </Typography>
            <Tooltip
              tip="'Estimated size of universe' is the number of physicians the LiMA Team estimate are practicing in the LiMA Market selected."
              multiline={false}
              iconProps={{
                icon: 'svg/custom/help-outline',
                iconSize: 20
              }}
            />
          </div>
        )}
        {listSize > 0 && (
          <div className={styles.textContainer}>
            <Typography type="body1">
              LiMA Member Count: <strong>{listSize.toLocaleString()} </strong>
            </Typography>
            <Tooltip
              tip="'LiMA member count' is the number of physicians with LiMA Directory Profiles practicing in the list or LiMA Market being analysed."
              multiline={false}
              iconProps={{
                icon: 'svg/custom/help-outline',
                iconSize: 20
              }}
            />
          </div>
        )}
      </div>
    )
  )
}
ListSize.propTypes = {
  hidden: PropTypes.bool,
  universeSize: PropTypes.number,
  listSize: PropTypes.number,
  className: PropTypes.string
}
export default ListSize
