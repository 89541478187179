import { all, call, put, takeEvery, select } from 'redux-saga/effects'
import { actions as compareListsActions } from './CompareListsReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as trackActions } from 'modules/tracking/TrackingReducer'
import { generatePptx } from '../pptxGeneration/generatePptx'
import { medelists } from 'modules/pptxGeneration/templates/medelists'
import { initialValues } from './config'
import { LOCATION_CHANGE } from 'connected-react-router'
import { getChartsObject } from 'modules/Charts/ChartsSelector'

/**
 * Request submit form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitFormRequest(services, { payload }) {
  const { activeStep } = payload
  try {
    yield put(trackActions.trackSubmitAction({}))
    switch (activeStep) {
      case 'lists-selection':
        yield call(submitListSelectionsRequest, services, payload)
        break
      case 'download-form':
        yield call(submitDownloadRequest, services, payload)
        break
      default:
        break
    }
  } catch (e) {
    yield put(compareListsActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request submit lists selection form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitListSelectionsRequest(services, payload) {
  try {
    const SmartsService = services('SmartsService')
    const { surveyParticipantList, targetList } = payload

    // Create List
    const { id: uuid } = yield call([SmartsService, 'listsCreate'], {
      workflow: 'CompareLists',
      parameters: {
        CompareLists: {
          lists: [targetList, surveyParticipantList]
        }
      },
      combinations: null,
      filters: null
    })
    const response = yield call([SmartsService, 'listsChartsGeneration'], uuid)
    // Get list info
    const { raw } = yield call([SmartsService, 'listsGet'], targetList)
    let defaultSpecialtyCountry = null
    if (raw?.filters?.combinations?.length === 1) {
      const specialtyCountryRow = raw.filters.combinations[0]
      defaultSpecialtyCountry = {
        id: 0,
        country: specialtyCountryRow.country,
        specialty:
          specialtyCountryRow.profession === 'Physician'
            ? specialtyCountryRow.specialty
            : specialtyCountryRow.profession,
        listSize: raw?.elements?.totalRecords
          ? `${raw.elements.totalRecords}`
          : '0',
        targetSampleSize: '0',
        surveyParticipants: '',
        screenedOut: ''
      }
    }

    // Save Form state & redirect user to next step
    yield put(
      compareListsActions.setFormState({
        ...payload,
        chartsData: response.charts,
        showCharts: true,
        specialtyCountryArray: defaultSpecialtyCountry
          ? [defaultSpecialtyCountry]
          : [],
        activeStep: 'download-form'
      })
    )
    yield put(compareListsActions.submitFormRequestSuccess())
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(compareListsActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request download form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitDownloadRequest(services, payload) {
  try {
    // Save Form state
    yield put(
      compareListsActions.setFormState({
        ...payload
      })
    )
    yield put(compareListsActions.submitFormRequestSuccess())
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(compareListsActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request project metadata form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* countrySpecialtyRequest(services, payload) {
  // const SmartsService = services('SmartsService')
  // const {
  //   listId: uuid,
  //   domain,
  //   clientProduct,
  //   brand,
  //   client,
  //   masterProject,
  //   project,
  //   notes,
  //   defaultCountry,
  //   reportName,
  //   type: action
  // } = payload
  // Call endpoint
  // yield call(
  //   [SmartsService, 'setProjectMetadata'],
  //   {
  //     uuid,
  //     domain,
  //     clientProduct,
  //     brand: [brand],
  //     client,
  //     masterProject,
  //     project,
  //     notes,
  //     defaultCountry,
  //     tags: '',
  //     action,
  //     reportName
  //   }
  // )
}

/**
 * Request run analysis
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* completeCompareListsRequest(services, { payload }) {
  try {
    // Submit Country Specialty
    yield call(countrySpecialtyRequest, services, payload)

    yield put(compareListsActions.submitFormRequestSuccess())
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(compareListsActions.submitFormRequestFail())
    console.error(e)
  }
}

function* submitCompleteRequest(services, { payload }) {
  try {
    yield put(trackActions.trackSubmitAction({}))
    const chartsObject = yield select(getChartsObject())

    generatePptx(
      { ...payload, chartsObject },
      medelists('SAR', payload.clientList ? 'CLIENT_LIST' : 'LIMA_LIST')
    )
    yield put(compareListsActions.submitFormRequestSuccess())
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(compareListsActions.submitFormRequestFail())
    console.error(e)
  }
}

function* resetForm(services, { payload }) {
  const { action } = payload

  if (action === 'REPLACE') {
    yield put(compareListsActions.setFormState({ ...initialValues }))
  }
}

export default function* watchCompareListsRequest(services) {
  yield all([
    takeEvery('COMPARE_LISTS_SUBMIT_FORM_REQUEST', submitFormRequest, services),
    takeEvery(
      'COMPARE_LISTS_COMPLETE_REQUEST',
      submitCompleteRequest,
      services
    ),
    takeEvery(
      'COMPARE_LISTS_COMPLETE_REQUEST',
      completeCompareListsRequest,
      services
    ),
    takeEvery(LOCATION_CHANGE, resetForm, services)
  ])
}
