import { createSelector } from '@reduxjs/toolkit'

export const chartsSelector = state => state.chartsReducer
export const getChartsData = () =>
  createSelector(chartsSelector, charts => charts.chartsData)
export const getIsFetching = () =>
  createSelector(chartsSelector, charts => charts.isFetching)
export const getActiveStep = () =>
  createSelector(chartsSelector, charts => charts.activeStep)
export const getCurrentChart = () =>
  createSelector(chartsSelector, charts => charts.currentChart)
export const getUploadedFiles = () =>
  createSelector(chartsSelector, charts => charts.chartsLocation)
export const getChartsObject = () =>
  createSelector(chartsSelector, charts => charts.chartsObject)
