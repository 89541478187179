import React from 'react'
import PropTypes from 'prop-types'
import ModalContainer from 'react-overlays/Modal'
import Icon from 'components/ui/Icon'
import Typography from '../Typography'
import styles from './Modal.module.sass'

const modalStyle = {
  position: 'fixed',
  zIndex: 1040,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(5px)',
  outline: 0
}

class Modal extends React.Component {
  state = {
    showModal: this.props.modalOpen,
    internalUpdate: false
  }
  static displayName = 'Modal'

  static defaultProps = {
    overlayColor: 'rgba(0, 0, 0, 0.1)'
  }

  static propTypes = {
    /** The color of the background overlay shown underneath Modal when opened */
    overlayColor: PropTypes.string,
    /** The max-width of the item */
    maxWidth: PropTypes.number,
    /** The max-height of the item */
    maxHeight: PropTypes.number,
    /** The height of the item */
    height: PropTypes.string,
    /** The width of the item */
    width: PropTypes.string,
    /** Is the modal open? */
    modalOpen: PropTypes.bool,
    /** The type of modal that's going to be used */
    modalType: PropTypes.number,
    /** The component children */
    children: PropTypes.node.isRequired,
    /** Callback when the user closes the modal */
    onDismiss: PropTypes.func,
    /** A CSS modules style object to override default theme */
    altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    /** Close Label for Interstitial */
    closeLabel: PropTypes.string,
    /** Bottom Text for Interstitial */
    bottomText: PropTypes.string,
    /** Does it have backdrop blur? */
    hasBackdropBlur: PropTypes.bool
  }

  static getDerivedStateFromProps({ modalOpen: showModal }, state) {
    if (state.internalUpdate) {
      return { ...state, internalUpdate: false }
    } else if (showModal !== state.showModal) {
      return {
        showModal: showModal
      }
    }
    return null
  }

  decideModal = (
    modalStyle,
    showModal,
    type,
    themeStyles,
    maxWidth,
    width,
    height,
    other,
    children,
    closeLabel,
    bottomText
  ) => {
    // Interstitial
    if (type === 1) {
      return (
        <ModalContainer
          aria-labelledby="modal-label"
          style={modalStyle}
          show={showModal}
          className={themeStyles.interstitialContainer}
          containerClassName={styles.modalBody}
          role="textbox"
        >
          <div
            className={themeStyles.interstitial}
            style={{
              ...(width && { width: width }),
              ...(height && { height: height })
            }}
            {...other}
          >
            <Typography
              type="thumb-label"
              className={themeStyles.closeLabel}
              onClick={this.close}
            >
              {closeLabel}
            </Typography>
            <div className={themeStyles.interstitialContent}>{children}</div>
            <Typography type="greeting" className={themeStyles.bottomText}>
              {bottomText}
            </Typography>
          </div>
        </ModalContainer>
      )
    } else {
      return (
        <ModalContainer
          aria-labelledby="modal-label"
          style={modalStyle}
          show={showModal}
          onHide={this.close}
          className={themeStyles.container}
          containerClassName={styles.modalBody}
          role="textbox"
        >
          <div
            className={themeStyles.modal}
            style={{ ...(maxWidth && { maxWidth: `${maxWidth}px` }) }}
            {...other}
          >
            <Icon
              className={themeStyles.button}
              onClick={this.close}
              icon="svg/material-design-icons/navigation/close"
              button
            />
            <div className={themeStyles.content}>{children}</div>
          </div>
        </ModalContainer>
      )
    }
  }

  render = () => {
    const {
      children,
      maxWidth,
      width,
      height,
      overlayColor,
      altTheme,
      modalType,
      closeLabel,
      bottomText,
      modalOpen,
      onDismiss,
      hasBackdropBlur,
      ...other
    } = this.props
    const { showModal } = this.state
    const themeStyles = { ...styles, ...altTheme }
    return (
      <>
        {this.decideModal(
          modalStyle,
          showModal,
          modalType,
          themeStyles,
          maxWidth,
          width,
          height,
          other,
          children,
          closeLabel,
          bottomText,
          hasBackdropBlur
        )}
      </>
    )
  }

  close = () => {
    this.setState({ showModal: false, internalUpdate: true })
    typeof this.props.onDismiss === 'function' && this.props.onDismiss()
  }

  open = () => this.setState({ showModal: true, internalUpdate: true })
}

export default Modal
