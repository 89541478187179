import history from '../../history'
import { idhBrandId, authEndpoints, phnxSiteID } from '../../constants'
import { isEmpty } from 'utils'

const { contactUsEndpoint } = authEndpoints

const UserTransactionParser = (SessionService, getState) => ({
  /**
   * Process last action for feedback form
   * @returns {string}
   */
  lastAction() {
    const {
      location: { pathname }
    } = history
    let action = SessionService.getFromCache('lastAction', pathname)
    // const isSearch = getState().searchReducer.isSearch
    // const tagCategory = getState().searchReducer.tagCategory

    action = action === '/' ? '/home' : action
    if (isEmpty(action)) return ''

    // if (isSearch && !tagCategory) {
    //   return 'Site : /search'
    // }

    // if (isSearch && tagCategory) {
    //   return `Site : /${tagCategory}`
    // }

    return `Site : ${action}`
  },
  /**
   * Parse payload for UserTransactionService.sendFeedback
   * @param payload
   * @param endpoint
   * @returns {string}
   */
  sendFeedbackParser(payload, endpoint) {
    const isAuth = getState().authReducer.isAuth
    const isLimited = getState().authReducer.isLimited
    const { email, firstName, lastName, grade, message, reCaptcha } = payload

    const countryCode = isLimited
      ? SessionService.getFromCache('country', '')
      : undefined
    const professionId = isLimited
      ? SessionService.getFromCache('profession_id', '')
      : undefined
    const specialtyId = isLimited
      ? SessionService.getFromCache('SpecialtyID', '')
      : undefined

    // Parse Feedback endpoint payload
    const feedbackPayload = {
      idhBrandId: isAuth ? parseInt(idhBrandId) : undefined,
      feedback: {
        email,
        firstName,
        lastName,
        grade,
        message
      }
    }

    // Parse Contact us endpoint payload
    const contactUsPayload = {
      reCaptcha,
      feedback: {
        email,
        firstName,
        grade,
        lastName,
        message,
        countryCode,
        professionId,
        specialtyId
      }
    }
    return endpoint === contactUsEndpoint ? contactUsPayload : feedbackPayload
  },
  /**
   * Parse payload for DougallService.inviteSubmit
   * @param payload
   * @returns {string}
   */
  inviteSubmitParser(payload) {
    return {
      brandType: 'inviteYourColleague',
      gptInvite: {
        email: payload.email,
        emailTo: [payload.toEmail],
        firstName: payload.name,
        message: payload.message.replaceAll('\n', '<br>')
      }
    }
  },
  /**
   * Parse payload for DougallService.inviteSubmit
   * @param payload
   * @returns {string}
   */
  sendEmailParser(payload) {
    const { name, email, emailto, message, articleUrl, articleTitle } = payload
    return {
      brandType: 'share',
      share: {
        articleTitle,
        articleUrl,
        email,
        emailTo: emailto,
        firstName: name,
        lastName: ' ',
        message
      }
    }
  },

  /**
   * Parse payload for DougallService.sendRegistration
   * @param payload
   * @returns {string}
   */
  registrationSubmitParser(payload) {
    const {
      email,
      firstName,
      lastName,
      state,
      country,
      profession,
      specialty,
      reCaptcha
    } = payload
    return {
      reCaptcha,
      registration: {
        country,
        email,
        firstName,
        lastName,
        option: ' ',
        profession,
        specialty,
        state,
        userProfession: profession
      },
      siteId: parseInt(phnxSiteID)
    }
  }
})

export default UserTransactionParser
