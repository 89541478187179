import { createReducer, createAction } from '@reduxjs/toolkit'

export const listCoinsRequest = createAction('LIST_COINS_REQUEST')
export const listCoinsRequestSuccess = createAction(
  'LIST_COINS_REQUEST_SUCCESS'
)
export const listCoinsRequestFail = createAction('LIST_COINS_REQUEST_FAIL')
export const actions = {
  listCoinsRequest,
  listCoinsRequestSuccess,
  listCoinsRequestFail
}

const initialValue = {
  coinsList: [],
  isFetching: true
}

const homeReducer = createReducer(initialValue, {
  [listCoinsRequest]: state => {
    state.isFetching = true
  },
  [listCoinsRequestSuccess]: (state, { payload }) => {
    state.isFetching = false
    state.coinsList = payload
  },
  [listCoinsRequestFail]: state => {
    state.isFetching = false
    state.coinsList = []
  }
})

export default homeReducer
