import Bootstrap from 'modules/bootstrap'
import { ConnectedRouter } from 'connected-react-router'
import Notification from 'modules/notification/Notification'
import Translation from 'modules/dougall/translation/Translation'
import { ThemeProvider } from 'modules/dougall/themeProvider'
import Title from 'modules/title'
import styles from 'App.module.sass'
import history from './history'
import { createServiceProvider } from 'services/ServiceContext'
import { Provider } from 'react-redux'
import createStore from './store'
import createContainer from 'services'
import Routes from 'Routes'
import ScrollToTop from 'components/ScrollToTop'
import { PersistGate } from 'redux-persist/integration/react'

const container = createContainer()
const store = createStore(history, container)

container.registerStore(store)
const ServiceProvider = createServiceProvider(container)

const App = () => (
  <Provider store={store.store}>
    <PersistGate loading={null} persistor={store.persistor}>
      <ServiceProvider>
        <Bootstrap>
          <ConnectedRouter className={styles.container} history={history}>
            <Translation>
              <Title>
                <ScrollToTop>
                  <ThemeProvider>
                    <Routes />
                  </ThemeProvider>
                </ScrollToTop>
              </Title>
            </Translation>
          </ConnectedRouter>
          <Notification />
        </Bootstrap>
      </ServiceProvider>
    </PersistGate>
  </Provider>
)

export default App
