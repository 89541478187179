import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Page from 'components/Page'
import Icon from 'components/ui/Icon'
import Typography from '../../components/ui/Typography'
import Divider from '../../components/ui/Divider'
import Spinner from '../../components/ui/Spinner'
import moment from 'moment'

import styles from './JobStatusSingle.module.sass'
import {
  getFetchingStatus,
  getJobStatusSingle,
  getEmailsFetchingStatus,
  getGroupsFetchingStatus,
  getFilesFetchingStatus
} from 'modules/jobStatusSingle/JobStatusSingleSelector'
import LoadingComponent from 'components/LoadingComponent'
import { actions } from './JobStatusSingleReducer'

import { isEmpty } from 'utils'

const JobStatusSingle = ({ match }) => {
  const {
    params: { jobid }
  } = match
  const dispatch = useDispatch()
  const [firstVisit, setFirstVisit] = useState(true)

  const isFetching = useSelector(getFetchingStatus())
  const list = useSelector(getJobStatusSingle())

  // mount
  useEffect(() => {
    if (firstVisit && !isFetching) {
      dispatch(actions.jobStatusRequest({ listId: jobid }))
    }
    setFirstVisit(false)
  }, [dispatch, firstVisit, isFetching, jobid])

  if (isEmpty(list) && isFetching) {
    return <LoadingComponent />
  }

  return (
    <div>
      <Page
        showHero={false}
        showDivider={false}
        showFooter={false}
        showBackIcon={true}
        backIconLink={'/job-status'}
        pageClassName={styles.pageContainer}
        contentClassName={styles.contentContainer}
      >
        {!isEmpty(list) && !isFetching && (
          <>
            <ListMetadata list={list} />
            <ListIdhStatus list={list} />
            <JobStatusSummary list={list} />
            <ListLogs list={list} />
          </>
        )}
      </Page>
    </div>
  )
}
JobStatusSingle.propTypes = {
  match: PropTypes.object
}

const ListMetadata = ({ list }) => {
  const {
    raw: { createdAt },
    mapped: { idhListId, domain, workflow, status, owner }
  } = list
  const submittedAt = moment(createdAt).format('YYYY-MM-DD')
  const [listStatus] = status.split(':')
  return (
    <div className={styles.listMetadataContainer}>
      <Typography type="subheading">List Metadata</Typography>
      <Divider className={styles.listDivider} />
      <div>
        <div>
          <Typography type={'subheading'}>Domain</Typography>
          <Typography className={styles.listMetadataSubHeaderContent}>
            {domain || ''}
          </Typography>
        </div>
        <div>
          <Typography type={'subheading'}>Submit Date</Typography>
          <Typography className={styles.listMetadataSubHeaderContent}>
            {submittedAt || ''}
          </Typography>
        </div>
        <div>
          <Typography type={'subheading'}>List ID</Typography>
          <Typography className={styles.listMetadataSubHeaderContent}>
            {idhListId || ''}
          </Typography>
        </div>
        <div>
          <Typography type={'subheading'}>Process</Typography>
          <Typography className={styles.listMetadataSubHeaderContent}>
            {workflow || ''}
          </Typography>
        </div>
        <div>
          <Typography type={'subheading'}>Status</Typography>
          <Typography className={styles.listMetadataSubHeaderContent}>
            {listStatus || ''}
          </Typography>
        </div>
        <div>
          <Typography type={'subheading'}>Owner</Typography>
          <Typography className={styles.listMetadataSubHeaderContent}>
            {owner || ''}
          </Typography>
        </div>
      </div>
    </div>
  )
}
ListMetadata.propTypes = {
  list: PropTypes.shape({
    raw: PropTypes.PropTypes.shape({
      createdAt: PropTypes.string
    }),
    mapped: PropTypes.PropTypes.shape({
      idhListId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      domain: PropTypes.string,
      workflow: PropTypes.string,
      status: PropTypes.string,
      owner: PropTypes.string
    })
  })
}

const ListIdhStatus = ({ list }) => {
  const status = (list.raw.status || '').toLowerCase()

  let message = ''
  if (list.raw.idh) {
    const jce = list.raw.idh.find(i => i.processReference === 'JCE') || {}
    message = jce.jobMessage || ''
  }

  return (
    <div className={styles.listIdhStatusContainer} data-type={status}>
      <h4>{list.mapped.status}</h4>
      <p>{message}</p>
    </div>
  )
}
ListIdhStatus.propTypes = {
  list: PropTypes.shape({
    raw: PropTypes.object,
    mapped: PropTypes.object
  })
}

const JobStatusSummary = ({ list }) => {
  const dispatch = useDispatch()

  const emailsFetching = useSelector(getEmailsFetchingStatus())
  const groupsFetching = useSelector(getGroupsFetchingStatus())
  const filesFetching = useSelector(getFilesFetchingStatus())

  const handleExportFileDownload = (exportFileIndex, name) => {
    dispatch(
      actions.jobStatusDownloadRequest({
        listId: list.raw.id,
        exportFileIndex,
        name
      })
    )
  }
  const { elements = {} } = list.raw
  const {
    mapped: { domain, client, masterProject, agency },
    raw: {
      metadata: {
        brand,
        clientProduct,
        defaultCountry,
        listMatching,
        deduplicate,
        targetListCreate,
        postalList,
        targetListCreateListIds,
        tags,
        reportName,
        notes
      },
      createdAt,
      updatedAt
    }
  } = list
  return (
    <div>
      <Typography type="subheading" className={styles.sectionHeader}>
        Project Metadata
      </Typography>
      <Divider className={styles.listDivider} />
      <div className={styles.listSummary}>
        {domain && <ContentField title="Domain" body={domain} />}
        {brand && <ContentField title="Brand" body={brand.join(', ')} />}
        {client && <ContentField title="Client" body={client} />}
        {clientProduct && (
          <ContentField title="Client Product" body={clientProduct} />
        )}
        {masterProject && (
          <ContentField title="Master Project" body={masterProject} />
        )}
        {agency && <ContentField title="Via Agency" body={agency} />}
        {defaultCountry && (
          <ContentField title="Default Country" body={defaultCountry} />
        )}
        {typeof listMatching === 'boolean' && (
          <ContentField
            title="List Matching"
            body={listMatching ? 'True' : 'False'}
          />
        )}
        {typeof deduplicate === 'boolean' && (
          <ContentField
            title="Deduplicate"
            body={deduplicate ? 'True' : 'False'}
          />
        )}
        {typeof targetListCreate === 'boolean' && (
          <ContentField
            title="Target List Create"
            body={targetListCreate ? 'True' : 'False'}
          />
        )}
        {typeof postalList === 'boolean' && (
          <ContentField
            title="Postal List"
            body={postalList ? 'True' : 'False'}
          />
        )}
        {targetListCreateListIds?.length && (
          <ContentField
            title="Target List Create Lists"
            links={targetListCreateListIds}
          />
        )}
        {tags && <ContentField title="Tags" body={tags} />}
        {reportName && <ContentField title="Report Name" body={reportName} />}
        {notes && <ContentField title="Notes" body={notes} />}
        {createdAt && <ContentField title="Created at" body={createdAt} />}
        {updatedAt && <ContentField title="Updated at" body={updatedAt} />}
      </div>

      <Typography type="subheading" className={styles.sectionHeader}>
        List Counts
      </Typography>
      <Divider className={styles.listDivider} />
      <div className={styles.listSummaryTri}>
        <ContentField
          title="Total Records"
          body={elements?.totalRecords?.toLocaleString()}
        />
        <ContentField
          title="Total Matched Party ID"
          body={elements?.matchedPartyIds?.toLocaleString()}
        />
        <ContentField
          title="Total Matched Unique Party ID"
          body={elements?.uniquePartyIds?.toLocaleString()}
        />
        <ContentField
          title="Total Unmatched Records"
          body={elements?.unmatchedPartyIds?.toLocaleString()}
        />
        <ContentField
          title="Total Matched National ID"
          body={elements?.matchedNationalIds?.toLocaleString()}
        />
        {elements?.matchingNationalIds?.map((mni, idx) => (
          <ContentField
            key={idx}
            title={`Total Party ID with Unique ${mni.type}`}
            body={mni.count.toLocaleString()}
          />
        ))}
      </div>

      <Typography type="subheading" className={styles.sectionHeader}>
        List Sharing
      </Typography>
      <Divider className={styles.listDivider} />
      <div className={styles.listSummary}>
        <div className={styles.sharingEmailsWrapper}>
          <Typography type={'subheading'}>Emails Sharing</Typography>
          {!emailsFetching ? (
            <>
              {list.emails && list.emails.length === 0 && (
                <Typography className="text-muted">None</Typography>
              )}
              {list.emails && list.emails.length > 0 && (
                <div className={styles.sharingEmailsListing}>
                  {list.emails.map(email => {
                    return (
                      <div
                        key={`email-${email}`}
                        className={styles.sharingEmailsListingItem}
                      >
                        <span>{email}</span>
                      </div>
                    )
                  })}
                </div>
              )}
            </>
          ) : (
            <Spinner
              className={styles.loadingSpinner}
              strokeColor="#25b89b"
              fillColor="transparent"
              size={50}
              strokeWidth={4}
            />
          )}
        </div>
        <div className={styles.sharingGroupsWrapper}>
          <Typography type={'subheading'}>Groups Sharing</Typography>
          {!groupsFetching ? (
            <>
              {list.groups.length === 0 && (
                <Typography className="text-muted">None</Typography>
              )}
              {list.groups.length > 0 && (
                <div className={styles.sharingGroupsListing}>
                  {list.groups.map(group => {
                    return (
                      <div
                        key={`group-${group.id}`}
                        className={styles.sharingGroupsListingItem}
                      >
                        <span>{group.name}</span>
                        <div className={styles.sharingGroupsListingItemSubItem}>
                          <small
                            className={
                              styles.sharingGroupsListingItemSubItemSmall
                            }
                          >
                            {group.active ? 'Active' : 'Inactive'}
                          </small>
                          <small
                            className={
                              styles.sharingGroupsListingItemSubItemSmall
                            }
                          >
                            {`${
                              group.emails ? group.emails.length : '0'
                            } Emails, ${
                              group.autoAssignEmail ? 'with' : 'without'
                            } auto assignments`}
                          </small>
                          <small
                            className={
                              styles.sharingGroupsListingItemSubItemSmall
                            }
                          >
                            {`${
                              group.domains ? group.domains.length : '0'
                            } Domains, ${
                              group.autoAssignDomain ? 'with' : 'without'
                            } auto assignments`}
                          </small>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
            </>
          ) : (
            <Spinner
              className={styles.loadingSpinner}
              strokeColor="#25b89b"
              fillColor="transparent"
              size={50}
              strokeWidth={4}
            />
          )}
        </div>
      </div>

      <Typography type="subheading" className={styles.sectionHeader}>
        Export Files
      </Typography>
      <Divider className={styles.listDivider} />
      <div className={styles.listSummary}>
        <div className={styles.sharingEmailsWrapper}>
          {!filesFetching ? (
            <>
              {list.exportFiles && list.exportFiles.length === 0 && (
                <Typography className="text-muted">None</Typography>
              )}
              {list.exportFiles && list.exportFiles.length > 0 && (
                <div className={styles.exportFilesListing}>
                  {list.exportFiles.map(file => {
                    return (
                      <div
                        key={`file-${file.id}`}
                        className={styles.exportFilesListingItem}
                      >
                        <div className={styles.fileInfo}>
                          <Typography>{file.name}</Typography>
                          {file.original && (
                            <Typography className={styles.originalFilename}>
                              Original Filename: {file.original}
                            </Typography>
                          )}
                          <small>{file.description}</small>
                          <small>{file.updatedAt}</small>
                        </div>
                        {file.available && (
                          <Icon
                            colorTier="action"
                            icon="svg/material-design-icons/file/file_download"
                            button
                            onClick={() =>
                              handleExportFileDownload(file.id, file.name)
                            }
                          />
                        )}
                      </div>
                    )
                  })}
                </div>
              )}
            </>
          ) : (
            <Spinner
              className={styles.loadingSpinner}
              strokeColor="#25b89b"
              fillColor="transparent"
              size={50}
              strokeWidth={4}
            />
          )}
        </div>
      </div>
    </div>
  )
}
JobStatusSummary.propTypes = {
  list: PropTypes.shape({
    raw: PropTypes.object,
    mapped: PropTypes.object,
    emails: PropTypes.array,
    groups: PropTypes.array,
    exportFiles: PropTypes.array
  })
}

const formatValues = other => {
  let values
  if (typeof other.values == 'string') {
    values = [other.values]
  } else {
    values = other.values
  }
  return values
}

const ListLogs = ({ list }) => {
  const { idh = [] } = list.raw
  return (
    <div className={styles.listIdhLogsContainer}>
      <div>
        <Typography type="subheading">Logs</Typography>
        <div className={styles.logsListingWrapper}>
          {idh.map(function (log, i) {
            return (
              <LogRow
                key={`logged-${i}`}
                type="INFO"
                rawDatetime={log.receivedAt}
                readableDatetime={log.receivedAt}
                rawMsg={log.jobMessage}
                processReference={log.processReference}
              />
            )
          })}
        </div>
      </div>
      {/* {list.file == null ? null : (
        <div className={styles.ingestedFileTable}>
          <Typography type="subheading">Ingested File</Typography>
          <table>
            <tbody>
              <tr>
                <th>File Name</th>
                <td>{list.file.file_name || 'n/a'}</td>
              </tr>
              <tr>
                <th>Type</th>
                <td>{list.file.is_xls || false ? 'Excel' : 'CSV'}</td>
              </tr>
              <tr>
                <th>Stored Locally</th>
                <td>{list.file.deleted || true ? 'Removed' : 'Yes'}</td>
              </tr>
              {list.file.is_xls ? (
                <tr>
                  <th>Sheet</th>
                  <td>
                    {`${list.file.selected.sheet_caption || 'n/a'} [#${list.file
                      .selected.sheet_index || 0}]`}
                  </td>
                </tr>
              ) : null}
              <tr>
                <th>Headers Row</th>
                <td>{list.file.selected.heading_row || 1}</td>
              </tr>
              <tr>
                <th>Headers Count</th>
                <td>{Object.keys(list.file.selected.columns || {}).length}</td>
              </tr>
              <tr>
                <th>Data Rows</th>
                <td>
                  {`${list.file.selected.row_first || 2} to ${list.file.selected
                    .row_last || 2}  ( ${list.file.selected.row_size || 1} )`}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )} */}
      {list.filters == null ? null : (
        <div className={styles.listFiltersTable}>
          <Typography type="subheading">List Filters</Typography>
          <table>
            <tbody>
              {list.filters.others.length > 0 ? (
                <tr>
                  <td>
                    <h5>Main</h5>
                    {list.filters.others.map(function (other, i) {
                      if (other.id === null) {
                        return null
                      }
                      let values = formatValues(other)
                      let spans = values.map(function (value, i) {
                        if ('Lists Filter' === other.caption) {
                          let listExtracts = value.split(';')
                          if (listExtracts.length === 3) {
                            return (
                              <ul
                                className={styles.subList}
                                key={`list-filter-details-${listExtracts[2]}`}
                              >
                                <li className={styles.subListHead}>
                                  <span>List ID:</span>
                                  <span>{other.id[i] || 'n/a'}</span>
                                </li>
                                <li>
                                  <span>Project Name:</span>
                                  <span>{listExtracts[0]}</span>
                                </li>
                                <li>
                                  <span>IDH Job Name:</span>
                                  <span>{listExtracts[1]}</span>
                                </li>
                                <li>
                                  <span>IDH List ID: </span>
                                  <span>{listExtracts[2]}</span>
                                </li>
                              </ul>
                            )
                          }
                        }
                        return <span key={`other-value=${value}`}>{value}</span>
                      })
                      return (
                        <div key={`type-others-${other.caption}`}>
                          {other.caption}: {spans}
                        </div>
                      )
                    })}
                  </td>
                </tr>
              ) : null}
              {list.filters.combinations.length > 0 ? (
                <tr>
                  <td>
                    <h5>Combinations</h5>
                    <div>
                      {list.filters.combinations.map(function (combine, i) {
                        return (
                          <span key={`type-combine-${combine}`}>{combine}</span>
                        )
                      })}
                    </div>
                  </td>
                </tr>
              ) : null}
              {list.filters.filters.length > 0 ? (
                <tr>
                  <td>
                    <h5>Filters</h5>
                    {list.filters.filters.map(function (filter, i) {
                      if (filter.values.length === 0) {
                        return null
                      }
                      return (
                        <div key={`type-filters-${filter.caption}`}>
                          {filter.caption}:
                          <ul>
                            {filter.values.map(function (value, i) {
                              return (
                                <li key={`type-filter-${value}`}>{value}</li>
                              )
                            })}
                          </ul>
                        </div>
                      )
                    })}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
ListLogs.propTypes = {
  list: PropTypes.shape({
    file: PropTypes.object,
    raw: PropTypes.object,
    filters: PropTypes.object
  })
}

const LogRow = ({
  type,
  rawDatetime,
  readableDatetime,
  rawMsg,
  processReference
}) => (
  <div className={styles.logItem} data-type={type.toLowerCase()}>
    <h5 title={rawDatetime}>{readableDatetime}</h5>
    <div className={styles.logItemBody}>
      <h4>{type}</h4>
      <p title={rawMsg}>
        {processReference && `${processReference}: `}
        {rawMsg || 'n/a'}
      </p>
    </div>
  </div>
)
LogRow.propTypes = {
  type: PropTypes.string,
  rawDatetime: PropTypes.string,
  readableDatetime: PropTypes.string,
  rawMsg: PropTypes.string,
  processReference: PropTypes.string
}

const ContentField = ({ title, body, align = '', links }) => (
  <div className={styles.subSectionHeader}>
    <Typography type={'subheading'} align={align}>
      {title}
    </Typography>
    {!links && <Typography align={align}>{body || 'N/A'}</Typography>}
    {links && (
      <div className={styles.contentLinks}>
        {links.map(l => (
          <Link key={l} to={`/job-status/${l}`} target="_blank">
            {l}
          </Link>
        ))}
      </div>
    )}
  </div>
)
ContentField.propTypes = {
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  links: PropTypes.array,
  align: PropTypes.string
}

// const NoDataPage = () => (
//   <Page
//     heroTitle={'No data available'}
//     showBackIcon={true}
//     backIconLink={'/lima_smarts/job_status'}
//     showHero={true}
//     showDivider={true}
//     showFooter={true}
//   >
//     <Typography type={'link'} component={Link} to={'/lima_smarts/job_status'}>
//       Back to Job Status List
//     </Typography>
//   </Page>
// )

export default JobStatusSingle
