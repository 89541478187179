import SvgIcon from '@mui/material/SvgIcon'

const EditIcon = props => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <title>Fill 1</title>
    <g
      id="R6-02272023"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Screen-2-FULL-VIEW:-Answer-to-Prompt---1-Prompt---D-02272023"
        transform="translate(-1034.000000, -174.000000)"
        fill="inherit"
      >
        <path
          d="M1045.45975,178.540246 L1044.97954,178.060027 L1045.93997,179.020464 L1045.45975,178.540246 Z M1047.79536,178.998636 L1036.794,190 L1034,190 L1034,187.206003 L1045.00136,176.204638 L1047.79536,178.998636 Z M1048.71214,178.081855 L1045.91814,175.287858 L1046.83492,174.371078 C1047.08246,174.123547 1047.38805,174 1047.75171,174 C1048.11536,174 1048.42095,174.123547 1048.66849,174.371078 L1049.62892,175.331514 C1049.87645,175.579045 1050,175.884638 1050,176.248295 C1050,176.611951 1049.87645,176.917544 1049.62892,177.165075 L1048.71214,178.081855 Z M1035.30969,188.690314 L1036.27012,188.690314 L1045.93997,179.020464 L1044.97954,178.060027 L1035.30969,187.729877 L1035.30969,188.690314 Z"
          id="Fill-1"
        />
      </g>
    </g>
  </SvgIcon>
)

export default EditIcon
