import axios from 'axios'

const SiteConfigService = () => ({
  /**
   * @param endpoint
   */
  async getRequest(endpoint) {
    let axiosOptions = {
      url: endpoint,
      method: 'GET'
    }
    const response = await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
    return response.data
  },
  /**
   * @param endpoint
   * @param payload
   * {
   *    "brandCode": "ntk_today",
   *    "brandName": "NTK Today",
   *    "type": "nl",
   *    "templateSettings": {
   *        "theme": "ntk",
   *        "version": "1.9.3",
   *        "template": "NTKToday"
   *    },
   *    "envVars": {
   *        "PUBLIC_URL": "https://staging.ntk-institute.org",
   *        "REACT_APP_PHNX_API": "https://api.phnxml.io",
   *        "REACT_APP_PHNX_CDN": "https://staging.cdn.phnxml.io",
   *        "REACT_APP_PIWIK_URL": "",
   *        "REACT_APP_PIWIK_SITEID": "",
   *        "REACT_APP_JSON_LISTS_URL": "https://c.peervoice.com/meet",
   *        "REACT_APP_SESSION_STORAGE": "ntk-institute"
   *    },
   *    "emailConfig": {},
   *    "tracking": {
   *        "vars": {
   *            "projectNumber": "{{%campaignId%}}"
   *        },
   *        "_cvars": {
   *            "1": [
   *                "MemberID",
   *                "{{%partyId%}}"
   *            ],
   *            "2": [
   *                "EmailID",
   *                "{{%emailId%}}"
   *            ],
   *            "3": [
   *                "SpecialtyID",
   *                "{{%specialty%}}"
   *            ],
   *            "4": [
   *                "country",
   *                "{{%country%}}"
   *            ],
   *            "5": [
   *                "CampaignID",
   *                "\"1\""
   *            ],
   *            "12": [
   *                "EventID",
   *                "\"3\""
   *            ]
   *        },
   *        "schema": {
   *            "logo": {
   *                "type": "click",
   *                "action": "Newsletter/Click",
   *                "params": {
   *                    "fb_id": "",
   *                    "Source": "",
   *                    "DestUrl": "",
   *                    "ClickType": "Home",
   *                    "entity_id": "",
   *                    "ProjectNumber": "{{%projectNumber%}}"
   *                }
   *            },
   *            "open": {
   *                "url": "{{%openTrackingUrl%}}",
   *                "type": "open",
   *                "action": "Newsletter/Open"
   *            }
   *        }
   *    },
   *    "siteConfig": {
   *        "logo": {
   *            "title": "NTK Today",
   *            "source": "https://ntk-institute.org/sites/all/themes/ntknow/images/logo.png"
   *        },
   *        "title": "Need-To-Know Today",
   *        "sections": {
   *            "hero": {
   *                "date": "{{pubDate}}",
   *                "headline": "General Medicine"
   *            },
   *            "login": {
   *                "submitLink": "{{env.public_url}}/user/login"
   *            },
   *            "footer": {
   *                "text": [
   *                    "NTK Institute",
   *                    "119 W 72nd Street",
   *                    "New York, NY 10023"
   *                ],
   *                "links": [
   *                    {
   *                        "link": "{{%env.public_url%}}/user{{%footerLinkParams%}}",
   *                        "title": "Edit Profile"
   *                    },
   *                    {
   *                        "link": "{{%env.public_url%}}/terms-use{{%footerLinkParams%}}",
   *                        "title": "Terms of Use"
   *                    },
   *                    {
   *                        "link": "{{%env.public_url%}}/privacy-policy{{%footerLinkParams%}}",
   *                        "title": "Privacy Policy"
   *                    },
   *                    {
   *                        "link": "{{%env.public_url%}}/unsubscribe{{%footerLinkParams%}}",
   *                        "title": "Unsubscribe"
   *                    }
   *                ],
   *                "copyright": "© 2021 All rights reserved"
   *            },
   *            "header": {
   *                "date": "{{%pubDate%}}",
   *                "subtitle": "Prepared for Dr. {{%physicianName%}}"
   *            },
   *            "profile": {
   *                "submitLink": "{{env.public_url}}/user/profile"
   *            },
   *            "feedback": {
   *                "text": "Tell us what you think of NTK Today",
   *                "rating": {
   *                    "neutral": {
   *                        "icon": "https://ntk-institute.org/sites/all/themes/dgxyz/images/nl_neutral.png",
   *                        "link": "{{%env.public_url%}}/nl-rate/{{%partyId%}}/{{%eventId%}}/neutral/nl-today-feedback{{%rateParams%}}"
   *                    },
   *                    "negative": {
   *                        "icon": "https://ntk-institute.org/sites/all/themes/dgxyz/images/nl_sad.png",
   *                        "link": "{{%env.public_url%}}/nl-rate/{{%partyId%}}/{{%eventId%}}/dislike/nl-today-feedback{{%rateParams%}}"
   *                    },
   *                    "positive": {
   *                        "icon": "https://ntk-institute.org/sites/all/themes/dgxyz/images/nl_smile.png",
   *                        "link": "{{%env.public_url%}}/nl-rate/{{%partyId%}}/{{%eventId%}}/like/nl-today-feedback{{%rateParams%}}"
   *                    }
   *                }
   *            },
   *            "footnote": "Footnote text here",
   *            "siteRiver": {
   *                "ads": {
   *                    "link": {
   *                        "image": "https://dgan.docguide.com/iserver?mid={{%partyId%}}&emailid={{%emailId%}}&adid={{%adId%}}&hint=x&channel=newsletter&property={{%property%}}&geography={{%country%}}&personid={{%partyId%}}&size=440x247&spec={{%specialtyId%}}&prof={{%professionId%}}&viewid={{%viewId%}}&pid={{%pid%}}",
   *                        "click": "https://dgan.docguide.com/adclick?mid={{%partyId%}}&emailid={{%emailId%}}&adid={{%adId%}}&hint=x&channel=newsletter&property={{%property%}}&geography={{%country%}}&personid={{%partyId%}}&size=440x247&spec={{%specialtyId%}}&prof={{%professionId%}}&viewid={{%viewId%}}&pid={{%pid%}}"
   *                    },
   *                    "type": "aimatch",
   *                    "display": {
   *                        "every": 2,
   *                        "minimum": 0,
   *                        "position": "after"
   *                    }
   *                },
   *                "break": {
   *                    "text": "Also of interest?",
   *                    "displayAfter": "1"
   *                },
   *                "query": {
   *                    "orderBy": {
   *                        "sort": "desc",
   *                        "field": "created"
   *                    },
   *                    "timeRange": {
   *                        "field": "created",
   *                        "duration": "1",
   *                        "durationUnit": "Year"
   *                    },
   *                    "conditions": [],
   *                    "maxResults": 10,
   *                    "type": [
   *                        "DGNews",
   *                        "DGCasesText",
   *                        "DGAbstract",
   *                        "DGDispatch"
   *                    ],
   *                    "score": {
   *                        "from": 1,
   *                        "to": 100,
   *                        "sort": "desc"
   *                    },
   *                    "specialties": [
   *                        "AIDS/HIV",
   *                        "Allergy & Immunology",
   *                        "Anesthesiology",
   *                        "Cardiology",
   *                        "Dermatology",
   *                        "Emergency Medicine",
   *                        "Endocrinology & Diabetes",
   *                        "Family Medicine",
   *                        "Gastroenterology",
   *                        "General Practice",
   *                        "General Medicine",
   *                        "Genetics",
   *                        "Geriatrics",
   *                        "Gynaecology/Obstetrics",
   *                        "Haematology",
   *                        "Infectious Diseases",
   *                        "Intensive Care",
   *                        "Internal Medicine",
   *                        "Nephrology",
   *                        "Neurology",
   *                        "Oncology",
   *                        "Ophthalmology",
   *                        "Orthopaedics",
   *                        "Otolaryngology",
   *                        "Paediatrics",
   *                        "Pathology",
   *                        "Psychiatry",
   *                        "Pulmonary/Respiratory Medicine",
   *                        "Radiology",
   *                        "Rheumatology",
   *                        "Surgery",
   *                        "Urology"
   *                    ]
   *                },
   *                "endpoint": "/fb/nl"
   *            },
   *            "feedbackForm": {
   *                "submitLink": "{{env.public_url}}/user/feedback"
   *            }
   *        },
   *        "configuration": {
   *            "assets": {
   *                "publishers": "/assets/newsletter/dg/journal_logos"
   *            },
   *            "envVars": {
   *                "PUBLIC_URL": "https://staging.ntk-institute.org",
   *                "REACT_APP_PHNX_API": "https://api.phnxml.io",
   *                "REACT_APP_PHNX_CDN": "https://staging.cdn.phnxml.io",
   *                "REACT_APP_PIWIK_URL": "",
   *                "REACT_APP_PIWIK_SITEID": "",
   *                "REACT_APP_JSON_LISTS_URL": "https://c.peervoice.com/meet",
   *                "REACT_APP_SESSION_STORAGE": "ntk-institute"
   *            },
   *            "tracking": {
   *                "vars": {
   *                    "projectNumber": "{{%campaignId%}}"
   *                },
   *                "schema": {
   *                    "logo": {
   *                        "type": "click",
   *                        "action": "Newsletter/Click",
   *                        "params": {
   *                            "fb_id": "",
   *                            "Source": "",
   *                            "DestUrl": "",
   *                            "ClickType": "Home",
   *                            "entity_id": "",
   *                            "ProjectNumber": "{{%projectNumber%}}"
   *                        }
   *                    },
   *                    "open": {
   *                        "url": "{{%openTrackingUrl%}}",
   *                        "type": "open",
   *                        "action": "Newsletter/Open"
   *                    },
   *                    "rate": {
   *                        "type": "click",
   *                        "action": "Newsletter/Click",
   *                        "params": {
   *                            "fb_id": "",
   *                            "Source": "",
   *                            "DestUrl": "{{%ratingLink%}}",
   *                            "ClickType": "Survey {{%ratingType%}}",
   *                            "entity_id": "",
   *                            "ProjectNumber": "{{%projectNumber%}}"
   *                        }
   *                    },
   *                    "hidden": {
   *                        "type": "hidden",
   *                        "action": "Hidden"
   *                    },
   *                    "articleRiver": {
   *                        "type": "click",
   *                        "action": "Newsletter/Click",
   *                        "params": {
   *                            "fb_id": "{{%article.id%}}",
   *                            "Source": "{{%article.title%}}",
   *                            "DestUrl": "{{%article.link%}}",
   *                            "ClickType": "Article",
   *                            "entity_id": "{{%article.humanUrl%}}",
   *                            "ProjectNumber": "{{%projectNumber%}}"
   *                        }
   *                    }
   *                }
   *            }
   *        }
   *    }
   *}
   * @param token - access token
   * @returns {Promise<boolean>}
   */
  async createSiteConfig(endpoint, payload, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'POST',
      data: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    return await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
  },
  /**
   * @param endpoint
   * @param payload
   * {
   *      "brandCode": "lima_md",
   *      "brandName": "LiMA MD",
   *      "type": "nl",
   *      "templateSettings": {
   *          "theme": "dgjournal",
   *          "version": "1.11.0",
   *          "template": "DGJournal",
   *          "templateType": "condition"
   *      },
   *      "envVars": {
   *          "PUBLIC_URL": "https://staging.ntk-institute.org",
   *          "APPEND_LINK": "dp={{%authToken%}}&nl_site={{%brandCode%}}&nl_ref=newsletter&pk_campaign=newsletter&nl_eventid={{%eventId%}}&nl_campaignid={{%idhCampaignId%}}&profileId={{%idhProfileId%}}",
   *          "REACT_APP_PHNX_API": "https://staging.api.phnxml.io",
   *          "REACT_APP_PHNX_CDN": "https://staging.cdn.phnxml.io",
   *          "REACT_APP_PIWIK_URL": "https://extstaging-piwik.docguide.com",
   *          "REACT_APP_PIWIK_SITEID": "173",
   *          "REACT_APP_SESSION_STORAGE": "ntk-institute"
   *      },
   *      "emailConfig": {},
   *      "tracking": {
   *          "vars": {
   *              "projectNumber": "{{%campaignId%}}"
   *          },
   *          "_cvars": {
   *              "1": [
   *                  "MemberID",
   *                  "{{%partyId%}}"
   *              ],
   *              "2": [
   *                  "EmailID",
   *                  "{{%emailId%}}"
   *              ],
   *              "3": [
   *                  "SpecialtyID",
   *                  "{{%specialty%}}"
   *              ],
   *              "4": [
   *                  "country",
   *                  "{{%country%}}"
   *              ],
   *              "5": [
   *                  "CampaignID",
   *                  "{{%idhCampaignId%}}"
   *              ],
   *              "12": [
   *                  "EventID",
   *                  "{{%eventId%}}"
   *              ]
   *          },
   *          "schema": {
   *              "logo": {
   *                  "type": "click",
   *                  "action": "Newsletter/Click",
   *                  "params": {
   *                      "fb_id": "",
   *                      "Source": "",
   *                      "DestUrl": "",
   *                      "ClickType": "Home",
   *                      "entity_id": "",
   *                      "ProjectNumber": "{{%projectNumber%}}"
   *                  }
   *              },
   *              "open": {
   *                  "url": "{{%env.react_app_piwik_url%}}/piwik.php?idsite={{%env.react_app_piwik_siteid%}}&action_type=newsletter&action_name=Newsletter+Open&rec=1&apiv=1&_cvar=%7B%221%22%3A%5B%22MemberID%22%2C%22{{%partyId%}}%22%5D%2C%222%22%3A%5B%22EmailID%22%2C%22{{%emailId%}}%22%5D%2C%223%22%3A%5B%22SpecialtyID%22%2C%22{{%specialty%}}%22%5D%2C%224%22%3A%5B%22country%22%2C%22{{%country%}}%22%5D%2C%225%22%3A%5B%22CampaignID%22%2C%22{{%idhCampaignId%}}%22%5D%2C%2212%22%3A%5B%22event_id%22%2C%22{{%eventId%}}%22%5D%7D&url={{%env.public_url%}}%3Fnl_ref=newsletter%26pk_campaign=newsletter%26nl_eventid={{%eventId%}}%26nl_campaignid={{%idhCampaignId%}}%26pw_siteID={{%env.react_app_piwik_siteid%}}%26profileId={{%partyId%}}&bots=1&new_visit=1",
   *                  "type": "open",
   *                  "action": "Newsletter/Open"
   *              }
   *          }
   *      },
   *      "siteConfig": {
   *          "logo": {
   *              "link": "{{%env.public_url%}}?{{%env.append_link%}}",
   *              "title": "NTK Today",
   *              "source": "{{%env.react_app_phnx_cdn%}}/assets/newsletter/dg/logos/logo-ntk.png"
   *          },
   *          "title": "Need-To-Know Today",
   *          "sections": {
   *              "login": {
   *                  "submitLink": "{{env.public_url}}/user/login"
   *              },
   *              "footer": {
   *                  "text": [
   *                      "NTK Institute",
   *                      "119 W 72nd Street",
   *                      "New York, NY 10023"
   *                  ],
   *                  "links": [
   *                      {
   *                          "link": "{{%env.public_url%}}/user?{{%env.append_link%}}",
   *                          "title": "Edit Profile"
   *                      },
   *                      {
   *                          "link": "{{%env.public_url%}}/terms-use?{{%env.append_link%}}",
   *                          "title": "Terms of Use"
   *                      },
   *                      {
   *                          "link": "{{%env.public_url%}}/privacy-policy?{{%env.append_link%}}",
   *                          "title": "Privacy Policy"
   *                      },
   *                      {
   *                          "link": "{{%env.public_url%}}/nl-unsubscribe?{{%env.append_link%}}",
   *                          "title": "Unsubscribe"
   *                      }
   *                  ],
   *                  "copyright": "© 2021 All rights reserved"
   *              },
   *              "header": {
   *                  "date": "{{%pubDate%}}",
   *                  "subtitle": "Prepared for Dr. {{%physicianName%}}"
   *              },
   *              "profile": {
   *                  "submitLink": "{{env.public_url}}/user/profile"
   *              },
   *              "feedback": {
   *                  "text": "Tell us what you think of NTK Today",
   *                  "rating": {
   *                      "neutral": {
   *                          "icon": "{{%env.react_app_phnx_cdn%}}/assets/newsletter/dg/logos/nl_neutral.png",
   *                          "link": "{{%env.public_url%}}/nl-rate?action=neutral&{{%env.append_link%}}"
   *                      },
   *                      "negative": {
   *                          "icon": "{{%env.react_app_phnx_cdn%}}/assets/newsletter/dg/logos/nl_sad.png",
   *                          "link": "{{%env.public_url%}}/nl-rate?action=dislike&{{%env.append_link%}}"
   *                      },
   *                      "positive": {
   *                          "icon": "{{%env.react_app_phnx_cdn%}}/assets/newsletter/dg/logos/nl_smile.png",
   *                          "link": "{{%env.public_url%}}/nl-rate?action=like&{{%env.append_link%}}"
   *                      }
   *                  }
   *              },
   *              "footnote": "Footnote text here",
   *              "siteRiver": {
   *                  "ads": {
   *                      "link": {
   *                          "click": "https://dgan.docguide.com/adclick?hint=x&amp;Channel=newsletter&property=ntktoday&geography={{%countryCode%}}&personid={{%partyId%}}&mid={{%partyId%}}&size=440x247,440x490&spec={{%specialtyId%}}&prof={{%professionId%}}&viewid=11111&pid={{%random%}}",
   *                          "image": "https://dgan.docguide.com/iserver?hint=x&Channel=newsletter&property=ntktoday&geography={{%countryCode%}}&personid={{%partyId%}}&mid={{%partyId%}}&size=440x247,440x490&spec={{%specialtyId%}}&prof={{%professionId%}}&viewid=11111&pid={{%random%}}"
   *                      },
   *                      "type": "aimatch",
   *                      "display": {
   *                          "every": 2,
   *                          "maximum": 1,
   *                          "minimum": 0,
   *                          "position": "after"
   *                      }
   *                  },
   *                  "break": {
   *                      "text": "Also of interest?",
   *                      "displayAfter": "1"
   *                  },
   *                  "query": {
   *                      "type": [
   *                          "DGNews",
   *                          "DGAbstract",
   *                          "DGDispatch"
   *                      ],
   *                      "score": {
   *                          "to": 100,
   *                          "from": 1,
   *                          "sort": "desc"
   *                      },
   *                      "include": [
   *                          "tags"
   *                      ],
   *                      "orderBy": {
   *                          "sort": "desc",
   *                          "field": "created"
   *                      },
   *                      "timeRange": {
   *                          "field": "created",
   *                          "duration": "128",
   *                          "durationUnit": "days"
   *                      },
   *                      "conditions": [],
   *                      "maxResults": 4,
   *                      "specialties": [
   *                          "Cardiology",
   *                          "General Medicine"
   *                      ]
   *                  },
   *                  "endpoint": "/fb/nl",
   *                  "conditionId": 12,
   *                  "specialtyId": 22
   *              },
   *              "feedbackForm": {
   *                  "submitLink": "{{env.public_url}}/user/feedback"
   *              }
   *          },
   *          "configuration": {
   *              "assets": {
   *                  "publishers": "/assets/newsletter/dg/journal_logos"
   *              }
   *          }
   *      }
   *  }
   * @param token - access token
   * @returns {Promise<boolean>}
   */
  async updateCampaign(endpoint, payload, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'PATCH',
      data: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    return await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
  }
})

export default SiteConfigService
