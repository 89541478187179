import { takeEvery, all, call, put, take, select } from 'redux-saga/effects'
import { actions as commentsActions } from 'modules/dougall/comments/CommentsReducer'
import { actions as loadingActions } from './LoadingReducer'
import { actions as trackingActions } from 'modules/dougall/tracking/TrackingReducer'
import { getPathToRedirect } from './LoadingSelector'
import history from '../../../history'

import queryString from 'query-string'
import { LOCATION_CHANGE } from 'connected-react-router'

import { isEmpty } from 'utils'

/**
 * Function for processing external articles
 *
 * @param {any} services
 */
function* loadingRequest(services) {
  try {
    const SessionService = services('SessionService')

    const interactionId = yield call(
      [SessionService, 'getFromCache'],
      'interactionId',
      ''
    )

    const path = yield select(getPathToRedirect())
    const parsedParams = queryString.parse(path)

    //GET PARSED PARAMS FROM PATH
    const likes = parsedParams.likes || parsedParams.like
    const external = parsedParams.external
    const destUrl = parsedParams.destUrl

    yield put(loadingActions.pathReset())
    const articleId = yield call(
      [SessionService, 'getFromCache'],
      'article_id',
      ''
    )
    if (external) {
      if (likes) {
        yield all([
          put(
            commentsActions.likeArticleRequest({
              scoreType: 'vote_up',
              pathname: '',
              articleId
            })
          )
        ])
        yield take(trackingActions.trackClickAction.type)
      } else {
        yield take(trackingActions.trackClickActionSuccess.type)
      }
      if (!isEmpty(destUrl)) {
        let url = decodeURIComponent(destUrl)
        if (
          ['ad-som-river', 'som-river', 'som-single', 'ad-som-single'].includes(
            external
          )
        ) {
          url = queryString.stringifyUrl({
            url,
            query: { interactionId }
          })
        }
        !isEmpty(url) && window.location.replace(url)
      } else {
        yield call([history, history.push], {
          pathname: '/'
        })
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* setPath(services, { payload }) {
  const { location, action } = payload
  if (['POP', 'PUSH'].includes(action)) {
    yield put(loadingActions.setPathURL(location))
  }
}

export default function* watchListings(services) {
  yield all([
    takeEvery('PROCESS_EXTERNAL', loadingRequest, services),
    takeEvery(LOCATION_CHANGE, setPath, services)
  ])
}
