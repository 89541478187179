import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Page from 'components/Page'
import { actions } from './FaqPageReducer'
import Spinner from '../../components/ui/Spinner'
import { getFaqList, getIsFetching } from './FaqPageSelector'
import Faq from 'components/FAQ'
import { useHistory } from 'react-router-dom'
import styles from './FaqPage.module.sass'

const FaqPage = () => {
  const dispatch = useDispatch()
  let data = useSelector(getFaqList() || {})
  const isFetching = useSelector(getIsFetching() || false)
  const history = useHistory()

  useEffect(() => {
    getJson()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getJson = async () => {
    await dispatch(actions.faqLoadRequest({}))
  }

  const handleBack = () => {
    history.goBack()
  }

  return (
    <Page
      contentClassName={styles.page}
      heroTitle={'Frequently Asked Questions'}
      showHero
      showGoBack
      onBackClick={handleBack}
      showDivider
      showFooter={false}
    >
      {isFetching && (
        <Spinner
          strokeColor={null}
          fillColor="transparent"
          size={64}
          strokeWidth={4}
        />
      )}
      {Object.keys(data).length > 0 && <Faq data={data} />}
    </Page>
  )
}

export default FaqPage
