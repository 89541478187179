import * as Yup from 'yup'

const validationSchema = {
  // File Form
  'file-upload': Yup.object().shape({
    file: Yup.mixed().required('File is required')
  }),
  //Xlsx form
  sheet: Yup.object().shape({
    xlsxSheet: Yup.string().required('Required'),
    headingRow: Yup.number().required('Required')
  }),
  'data-location': Yup.object().shape({
    columnFirst: Yup.string().required('Required'),
    columnLast: Yup.string().required('Required'),
    rowFirst: Yup.string().required('Required'),
    rowLast: Yup.string().required('Required')
  }),
  'project-metadata': Yup.object().shape({
    domain: Yup.string().required('Required'),
    clientProduct: Yup.string().when('domain', {
      is: domain => domain === 'FW',
      then: Yup.string(),
      otherwise: Yup.string().required('Required')
    }),
    brand: Yup.string().when('domain', {
      is: domain => domain === 'FW',
      then: Yup.string(),
      otherwise: Yup.string().required('Required')
    }),
    client: Yup.string().when('domain', {
      is: domain => domain === 'FW',
      then: Yup.string(),
      otherwise: Yup.string().required('Required')
    }),
    agency: Yup.string(),
    masterProject: Yup.string().when('domain', {
      is: domain => domain === 'FW',
      then: Yup.string(),
      otherwise: Yup.string().required('Required')
    }),
    project: Yup.string(),
    notes: Yup.string(),
    defaultCountry: Yup.string(),
    listMatching: Yup.boolean(),
    targetListCreate: Yup.boolean(),
    dedupe: Yup.boolean(),
    postalList: Yup.boolean(),
    noAssociatedListID: Yup.boolean(),
    targetListCreateListIds: Yup.array().when(
      ['targetListCreate', 'noAssociatedListID'],
      {
        is: (targetListCreate, noAssociatedListID) =>
          targetListCreate && !noAssociatedListID,
        then: schema => schema.min(1, 'List ID(s) is required')
      }
    )
  })
}

export default validationSchema
