import { createReducer, createAction } from '@reduxjs/toolkit'
import { initialValues } from './config'

export const setFormState = createAction('LIST_INGESTION_SET_FORM_STATE')

// File upload actions
export const submitFormRequest = createAction(
  'LIST_INGESTION_SUBMIT_FORM_REQUEST'
)
export const submitFormRequestSuccess = createAction(
  'LIST_INGESTION_SUBMIT_FORM_REQUEST_SUCCESS'
)
export const submitFormRequestFail = createAction(
  'LIST_INGESTION_SUBMIT_FORM_REQUEST_FAIL'
)
export const completeIngestionRequest = createAction(
  'LIST_INGESTION_COMPLETE_REQUEST'
)
export const finishIngestionRequest = createAction(
  'LIST_INGESTION_FINISH_REQUEST'
)

// Options actions
export const setOptions = createAction('LIST_INGESTION_OPTIONS_SET')

export const actions = {
  setFormState,

  submitFormRequest,
  submitFormRequestSuccess,
  submitFormRequestFail,
  completeIngestionRequest,

  setOptions,

  finishIngestionRequest
}

const listIngestionReducer = createReducer(
  {
    formState: {
      ...initialValues
    },
    options: {
      sheets: {},
      'data-location': {}
    },
    fetching: false,
    finished: false
  },
  {
    [setFormState]: (state, { payload }) => {
      state.formState = { ...state.formState, ...payload }
    },
    [submitFormRequest]: state => {
      state.fetching = true
    },
    [completeIngestionRequest]: state => {
      state.fetching = true
    },
    [submitFormRequestSuccess]: state => {
      state.fetching = false
    },
    [submitFormRequestFail]: state => {
      state.fetching = false
    },
    [setOptions]: (state, { payload }) => {
      state.options = {
        ...state.options,
        ...payload
      }
    },
    [finishIngestionRequest]: (state, { payload }) => {
      state.finished = payload.finished
    }
  }
)

export default listIngestionReducer
