import { createSelector } from '@reduxjs/toolkit'

export const exploreMarketSelector = state => state.exploreMarketReducer

export const getFormState = () =>
  createSelector(
    exploreMarketSelector,
    exploreMarket => exploreMarket.formState
  )
export const getFetchStatus = () =>
  createSelector(exploreMarketSelector, exploreMarket => exploreMarket.fetching)
