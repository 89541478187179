import { createReducer, createAction } from '@reduxjs/toolkit'

export const requestBootstrap = createAction('BOOTSTRAP__REQUEST')
export const successBootstrap = createAction('BOOTSTRAP__SUCCESS')
export const errorBootstrap = createAction('BOOTSTRAP__ERROR')

export const requestConfig = createAction('CONFIG__REQUEST')
export const successConfig = createAction('CONFIG__SUCCESS')
export const errorConfig = createAction('CONFIG__ERROR')

export const requestNotifications = createAction('NOTIFICATIONS__REQUEST')
export const successNotifications = createAction('NOTIFICATIONS__SUCCESS')
export const errorNotifications = createAction('NOTIFICATIONS__ERROR')

export const actions = {
  requestBootstrap,
  successBootstrap,
  errorBootstrap,

  requestConfig,
  successConfig,
  errorConfig,

  requestNotifications,
  successNotifications,
  errorNotifications
}
export const stages = {
  UNINITIALIZATED: 'UNINITIALIZATED',
  BOOTSTRAPPING: 'BOOTSTRAPPING',
  INITIALIZED: 'INITIALIZED',
  FAILED: 'FAILED'
}

const bootstrapReducer = createReducer(
  {
    stage: stages.UNINITIALIZATED,
    configFetching: false,
    config: {}
  },
  {
    [requestConfig]: state => {
      state.configFetching = true
    },
    [successConfig]: (state, { payload }) => {
      state.config = payload
      state.configFetching = false
    },
    [errorConfig]: state => {
      state.configFetching = false
    },
    [requestBootstrap]: state => {
      state.stage = stages.BOOTSTRAPPING
    },
    [successBootstrap]: state => {
      state.stage = stages.INITIALIZED
    },
    [errorBootstrap]: state => {
      state.stage = stages.FAILED
    }
  }
)

export default bootstrapReducer
