import { isEmpty } from '../utils'
import siteConfig from 'site.config'

/**
 * Search request helper
 */
const AdvisorSearchParser = () => ({
  /**
   * @param payload
   * @param isAdmin
   * @returns {{advisorTypes: []|*}}
   */
  adaptQuery({ payload, isAdmin }) {
    const { advisorList } = siteConfig

    // IF NOT ADMIN SEND ALL ADVISOR TYPES
    let queryValues = {
      // LIMIT QUERY RES
      size: isAdmin ? advisorList.adminDisplay : advisorList.maxResultsDisplay
    }

    // CLEAN PAYLOAD
    Object.keys(payload).forEach(key => {
      if (
        !isEmpty(payload[key]) &&
        !['brand', 'external', 'internal', 'me'].includes(key)
      ) {
        queryValues[key] = payload[key]
      }
    })

    // PARSE TAGS
    if (!isEmpty(payload.tags)) {
      try {
        queryValues['tags'] = payload.tags
          .split(',')
          .map(string => string.trim())
      } catch (e) {
        queryValues['tags'] = []
      }
    }

    return queryValues
  },

  parseSpecialties(data) {
    try {
      const {
        responseData: {
          referenceTable: { data: res }
        }
      } = data
      const specialties = {}

      res
        .filter(d => d.value2 === 'LIMA')
        .forEach(item => {
          if (item['value'] !== 'Unknown') {
            specialties[item['value']] = item['value']
          }
        })
      return { specialties }
    } catch (e) {
      console.error(e)
    }
  },

  parseProfessions(data) {
    try {
      const {
        responseData: {
          referenceTable: { data: res }
        }
      } = data
      const professions = {}

      res
        .filter(d => d.value3 === 'LIMA')
        .forEach(item => {
          if (item['value'] !== 'Unknown') {
            professions[item['value']] = item['value']
          }
        })
      return { professions }
    } catch (e) {
      console.error(e)
    }
  }
})

export default AdvisorSearchParser
