import { createReducer, createAction } from '@reduxjs/toolkit'

export const feedbackRequest = createAction('FEEDBACK_REQUEST')
export const feedbackRequestSuccess = createAction('FEEDBACK_REQUEST_SUCCESS')
export const feedbackRequestFail = createAction('FEEDBACK_REQUEST_FAIL')
export const feedbackRate = createAction('FEEDBACK_RATE')

export const actions = {
  feedbackRequest,
  feedbackRequestSuccess,
  feedbackRequestFail,
  feedbackRate
}

const initialState = {
  fetching: false,
  status: false
}

const feedbackReducer = createReducer(initialState, {
  [feedbackRequest]: state => {
    state.fetching = true
  },
  [feedbackRequestSuccess]: state => {
    state.feedbackForm = {}
    state.status = true
    state.fetching = false
  },
  [feedbackRequestFail]: state => {
    state.status = false
    state.fetching = false
  }
})

export default feedbackReducer
