import axios from 'axios'
import { limitedRoles, authEndpoints } from '../constants'
import { addSiteIdToPayload, isEmpty, parseJwt } from 'utils'

const AuthService = CookieService => ({
  /**
   * Executes user verify and token refresh
   * @param accessToken
   */
  async userVerify(accessToken) {
    if (isEmpty(accessToken)) {
      throw new Error('no token')
    }

    return await axios({
      url: authEndpoints.userVerifyEndpoint,
      method: 'POST',
      headers: { Authorization: `Bearer ${accessToken}` }
    })
      .then(r => {
        return this.parseAuthVerifyResponse(r, 'verify')
      })
      .catch(async e => {
        // SOMETHING'S WRONG WITH AUTH - ABORT
        console.warn(e)
        throw new Error('VERIFY TOKEN ERROR')
      })
  },
  /**
   * Executes token verify and token refresh
   * @param accessToken
   */
  async tokenVerify(accessToken) {
    if (isEmpty(accessToken)) {
      throw new Error('no token')
    }
    return await axios({
      url: authEndpoints.tokenVerifyEndpoint,
      method: 'POST',
      data: JSON.stringify({ token: accessToken }),
      headers: { Authorization: `Bearer ${accessToken}` }
    })
      .then(r => {
        return this.parseAuthVerifyResponse(r, 'verify')
      })
      .catch(async e => {
        // SOMETHING'S WRONG WITH AUTH - ABORT
        console.warn(e)
        throw new Error('VERIFY TOKEN ERROR')
      })
  },
  /**
   * Executes token refresh
   * @param refreshEndpoint
   * @param refreshToken
   * @return {verifyResponse} New verified token
   */
  async refreshToken(refreshToken) {
    // Refresh tokens and verify
    const refreshCall = await axios({
      url: authEndpoints.tokenRefreshEndpoint,
      method: 'POST',
      data: addSiteIdToPayload({ token: refreshToken }),
      headers: { Authorization: `Bearer ${refreshToken}` }
    }).catch(e => {
      console.warn(e)
      throw new Error('REFRESH TOKEN ERROR')
    })
    const { access: accessRefresh } = this.parseAuthResponse(
      refreshCall,
      'refresh'
    )
    const verifyResponse = await this.tokenVerify(accessRefresh)
    const { exp: expirationDate } = parseJwt(verifyResponse.access)

    // Update Cookies
    CookieService.saveToCache(
      {
        state: this.extractCacheStateValues({ ...verifyResponse }),
        ...verifyResponse.userInfo
      },
      false,
      expirationDate
    )
    CookieService.saveToCache(
      {
        access: verifyResponse.access,
        refresh: verifyResponse.refresh,
        isAnon: !verifyResponse.isAuth
      },
      false
    )
    return verifyResponse
  },
  /**
   * Executes OTP AUTH  and verify
   * @returns {Promise<{valid: boolean, access: *, valType: *, refresh: *}|{valid: boolean, access: *, valType: *, refresh}>}
   * @param endpoint
   * @param payload
   */
  async otpRequest(payload) {
    // TRY TO VERIFY
    return await axios({
      url: authEndpoints.tokenVerifyEndpoint,
      method: 'POST',
      data: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payload.token}`
      }
    }).then(r => {
      return this.parseAuthVerifyResponse(r, 'verify')
    })
  },
  /**
   * Validate verify response and return processed auth obj
   * @param response
   * @param type - 'refresh', 'verify'
   * @returns {{valid: boolean, access: *, valType: *, refresh: *}|{valid: boolean, userInfo: ({}), isAuth: boolean, access: *, role: {alias: string, description: string}, valType: *, refresh: *}}
   */
  parseAuthVerifyResponse(response, type) {
    const roles = response?.data?.responseData?.info?.role?.roles
    const responseData = response?.data?.responseData
    if (type === 'verify') {
      if (isEmpty(responseData)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (isEmpty(responseData?.info)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (isEmpty(responseData?.tokens)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (isEmpty(responseData?.tokens?.access)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (roles?.includes('limited'))
        if (isEmpty(responseData?.tokens?.refresh)) {
          throw new Error('TOKEN INTEGRITY ERROR')
        }
      if (isEmpty(responseData?.info?.role)) {
        throw new Error('ROLE ERROR')
      }
      const idhProfileId = isEmpty(responseData?.info?.user?.idhProfileId)
        ? ''
        : responseData.info.user.idhProfileId
      const userInfo = isEmpty(responseData?.info?.user)
        ? {}
        : {
            ...responseData.info.user
          }
      const isAuth = Array.isArray(roles) && !isEmpty(roles)
      const isLimited =
        responseData?.info?.role?.roles &&
        limitedRoles.some(limitedRole =>
          responseData.info.role.roles.includes(limitedRole)
        )

      return {
        valid: true,
        isAuth,
        isLimited,
        valType: type,
        access: responseData.tokens.access,
        refresh: responseData.tokens.refresh,
        name: responseData.info.role.roles || [responseData.info.role.name],
        role: responseData.info.role.permissions?.routes || [],
        features: responseData.info.role.permissions?.features || {},
        userInfo,
        idhProfileId
      }
    } else if (type === 'refresh') {
      if (isEmpty(responseData)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (isEmpty(responseData?.access)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (isEmpty(responseData?.refresh)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      return {
        valid: true,
        valType: type,
        access: responseData.access,
        refresh: responseData.refresh
      }
    }
  },
  /**
   * Validate refresh response and return processed auth obj
   * @param response
   * @param type - 'refresh', 'verify'
   * @returns {{valid: boolean, access: *, valType: *, refresh: *}|{valid: boolean, userInfo: ({}), isAuth: boolean, access: *, role: {alias: string, description: string}, valType: *, refresh: *}}
   */
  parseAuthResponse(response, type) {
    const roles = response?.data?.info?.role?.roles

    if (type === 'verify') {
      if (isEmpty(response?.data)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (isEmpty(response?.data?.info)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (isEmpty(response?.data?.tokens)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (isEmpty(response?.data?.tokens?.access)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (roles?.includes('limited'))
        if (isEmpty(response?.data?.tokens?.refresh)) {
          throw new Error('TOKEN INTEGRITY ERROR')
        }
      if (isEmpty(response?.data?.info?.role)) {
        throw new Error('ROLE ERROR')
      }
      const idhProfileId = isEmpty(response?.data?.info?.user?.idhProfileId)
        ? ''
        : response.data.info.user.idhProfileId
      const userInfo = isEmpty(response?.data?.info?.user)
        ? {}
        : {
            ...response.data.info.user
          }
      const isAuth = Array.isArray(roles) && !isEmpty(roles)
      const isLimited =
        response?.data?.info?.role?.roles &&
        limitedRoles.some(limitedRole =>
          response.data.info.role.roles.includes(limitedRole)
        )

      return {
        valid: true,
        isAuth,
        isLimited,
        valType: type,
        access: response.data.tokens.access,
        refresh: response.data.tokens.refresh,
        name: response.data.info.role.roles || [response.data.info.role.name],
        role: response.data.info.role.permissions?.routes || [],
        features: response.data.info.role.permissions?.features || {},
        userInfo,
        idhProfileId
      }
    } else if (type === 'refresh') {
      if (isEmpty(response?.data)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (isEmpty(response?.data?.access)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (isEmpty(response?.data?.refresh)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      return {
        valid: true,
        valType: type,
        access: response.data.access,
        refresh: response.data.refresh
      }
    }
  },
  /**
   * Validate that session token has all required fields, and does full validation
   * @param verifyEndpoint
   * @param refreshEndpoint
   * @param cache
   * @returns {Promise<*>}
   */
  validateCacheToken(cache) {
    if (isEmpty(cache?.access)) throw new Error('INVALID SESSION TOKEN')
    return this.tokenVerify(cache.access).then(authResponse => authResponse)
  },
  /**
   * Validate that session token has valid user
   * @param verifyEndpoint
   * @param refreshEndpoint
   * @param cache
   * @returns {Promise<*>}
   */
  validateCacheUser(cache) {
    if (!isEmpty(cache?.access)) {
      return this.userVerify(cache.access).then(authResponse => authResponse)
    } else {
      throw new Error('INVALID SESSION TOKEN')
    }
  },
  /**
   * Validate that session token has all required fields
   * @param cacheToken
   * @returns {boolean}
   */
  validateCacheIntegrity(cacheToken) {
    if (isEmpty(cacheToken?.access) && isEmpty(cacheToken?.refresh))
      throw new Error('cache integrity error')
  },
  /**
   * @param endpoint
   * @param payload
   * @returns {Promise<{valid: boolean, userInfo: {}, isAuth: boolean, access: *, role: [], refresh: *}>}
   */
  async getAnonToken(payload = {}) {
    const axiosOptions = {
      url: authEndpoints.tokenGetEndpoint,
      method: 'POST',
      data: addSiteIdToPayload(payload)
    }
    return await axios(axiosOptions)
      .then(response => {
        if (isEmpty(response?.data)) {
          throw new Error('TOKEN INTEGRITY ERROR')
        }
        if (isEmpty(response?.data?.access)) {
          throw new Error('access error')
        }
        return {
          isAuth: false,
          isLimited: false,
          valid: true,
          access: response.data.access,
          refresh: response.data.refresh,
          role: [],
          userInfo: {}
        }
      })
      .catch(e => {
        throw new Error('Error fetching anon token' + e)
      })
  },
  /**
   * Verify if requested path is safe to access
   * @param reqAuth - if the path requires auth, will trigger lookup
   * @param reqPathName - path identifier, will do lookup in role table
   * @param role - AUTH role table containing path names
   * @returns {boolean}
   */
  pathCheck({ reqAuth, reqPathName, role }) {
    let allowed = false
    const roleList = [...role]
    if (reqAuth === true) {
      for (let i = 0; i < roleList.length; i++) {
        if (reqPathName === '/') {
          if (roleList[i] === '/') {
            allowed = true
            break
          }
        } else if (
          new RegExp('(?:^|\\s)' + reqPathName, 'i').test(roleList[i]) ||
          reqPathName.includes(`${roleList[i]}/`) //Check wildcard persmissions
        ) {
          allowed = true
          break
        }
      }
    } else {
      allowed = true
    }
    return allowed
  },
  extractCacheStateValues({
    name = '',
    // role = '',
    // features = '',
    version = '',
    isAuth = false,
    isLimited = false,
    userInfo,
    expirationDate
  }) {
    return {
      name,
      // role,
      // features,
      version,
      isAuth,
      isLimited,
      userInfo,
      expirationDate
    }
  }
})

export default AuthService
