import pptxgen from 'pptxgenjs'
import { isEmptyObject } from 'utils'

const replaceVars = (text, values) => {
  const tokens = {
    '%%clientcompany%%': values.clientCompanyName,
    '%%projectName%%': values.projectName,
    '%%projectNumber%%': values.projectNumber,
    '%%clientName%%': values.clientName,
    '%%consultantName%%': values.consultantName,
    '%%telephone%%': values.telephone,
    '%%email%%': values.email
  }

  Object.keys(tokens).forEach(function (token) {
    text.texts.forEach(function (t, i) {
      text.texts[i].text = t.text.replace(token, tokens[token])
    })
  })
}

const addNewSlideClosing = (slide, slideMasterName = false) => {
  if (!slideMasterName) {
    // no changes
    return
  }

  // add page number

  let pageNumber = slide.pageNumber
  if (pageNumber) {
    slide.addText([
      {
        options: slideMasterName.slideNumber,
        text: pageNumber.toString()
      }
    ])
  }
}

const getStatisticInputValue = (page, rowPerTable, tableData) => {
  page = page - 1
  let start = page * rowPerTable
  let end = start + rowPerTable

  const arrStat = tableData.slice(start, end)
  const orderedArrStat = {}

  arrStat.forEach(object => {
    const { id, country, specialty, ...rest } = object
    orderedArrStat[country] = {
      ...orderedArrStat[country],
      [specialty]: { ...rest }
    }
  })

  return orderedArrStat
}

/**
 * getTablePositionX      return the table x position for pptx slide.
 * @param  colCount       total columns of the table.
 * @param  colWidth       an array consists of width of each column.
 * @param  templateLayout tempalte layout object with height, width and name.
 */
const getTablePositionX = (colCount, colWidth, templateLayout) => {
  let tableWidth = 0
  for (let i = 0; i < colCount; i++) {
    tableWidth += colWidth[i]
  }
  let availableSpace = templateLayout.width - tableWidth
  return availableSpace / 2
}

/**
 * addStatisticSlide    Add content table into slides (4 & 5) into pptx.
 * @param name          slide name.
 * @param slide         slide object.
 * @param template      response from ML (statistic combination)
 * @param page          page number of statistic page (1 page has 8 rows).
 * @param clientList    whether list is a Client List or not.
 */
const addStatisticSlide = (
  name,
  slide,
  template,
  page,
  clientList,
  tableData
) => {
  if (name === 'filteredClientStatistic') {
    let headerOption = template.tableOptions.headerOption
    let tabOpts = template.tableOptions.tableOption
    let rowPerTable = template.tableOptions.rowPerTable
    let formType = 'SAR'
    tabOpts['colW'] = [1.8, 3, 1.2, 1.2, 1.2]
    tabOpts['fontSize'] = 13
    let listType = clientList ? 'client-list' : 'lima-list'
    let dataRow = []

    // Default header row.
    let headerRow = [
      { text: 'Country', options: headerOption },
      { text: 'Specialty', options: headerOption },
      { text: 'List size', options: headerOption }
    ]
    headerRow = headerRow.concat([
      { text: 'Target Sample Size', options: headerOption },
      { text: 'Survey Participants', options: headerOption },
      { text: 'Screened Out', options: headerOption }
    ])

    dataRow.push(headerRow)

    let statistic = getStatisticInputValue(page, rowPerTable, tableData)

    let totals = {
      listSize: 0,
      matchedLima: 0,
      participants: 0,
      targetSample: 0,
      screenOut: 0
    }

    Object.keys(statistic).forEach(function (country, i) {
      let specialtyKeys = Object.keys(statistic[country])
      specialtyKeys.forEach(function (specialty, j) {
        let listSize = statistic[country][specialty]['listSize']
        let matchedLima = statistic[country][specialty]['ppt-matched-lima']
        let participants = statistic[country][specialty]['surveyParticipants']
        let targetSample = statistic[country][specialty]['targetSampleSize']
        let screenOut = statistic[country][specialty]['screenedOut']

        // Add to totals
        totals.listSize += parseInt(listSize, 10) || 0
        totals.matchedLima += parseInt(matchedLima, 10) || 0
        totals.participants += parseInt(participants, 10) || 0
        totals.targetSample += parseInt(targetSample, 10) || 0
        totals.screenOut += parseInt(screenOut, 10) || 0

        // Default row
        let row = [
          { text: country, options: { align: 'l' } },
          { text: specialty, options: { align: 'l' } },
          { text: listSize, options: { align: 'r' } }
        ]
        if (formType === 'LAR' && listType === 'client-list') {
          row.push({ text: matchedLima, options: { align: 'r' } })
        } else if (formType === 'SAR') {
          row = row.concat([
            { text: targetSample, options: { align: 'r' } },
            { text: participants, options: { align: 'r' } },
            { text: screenOut, options: { align: 'r' } }
          ])
        }
        dataRow.push(row)
      })
    })

    if (Object.keys(statistic).length === 0) {
      dataRow.push([
        {
          text: 'No country / specialty found',
          options: { align: 'c', colspan: Object.keys(headerRow).length }
        }
      ])
    }

    let totalRow = [
      { text: 'TOTAL', options: { align: 'l', bold: true } },
      { text: '', options: { align: 'l' } },
      { text: totals.listSize, options: { align: 'r', bold: true } }
    ]
    if (formType === 'LAR' && listType === 'client-list') {
      totalRow.push({
        text: totals.matchedLima,
        options: { align: 'r', bold: true }
      })
    } else if (formType === 'SAR') {
      totalRow = totalRow.concat([
        { text: totals.targetSample, options: { align: 'r', bold: true } },
        { text: totals.participants, options: { align: 'r', bold: true } },
        { text: totals.screenOut, options: { align: 'r', bold: true } }
      ])
    }
    dataRow.push(totalRow)
    // Align table to center - x position.
    tabOpts['x'] = getTablePositionX(
      headerRow.length,
      tabOpts['colW'],
      template.layout
    )
    slide.addTable(dataRow, tabOpts)
  }
}

const getChartDimensions = (width, height, isTableSize) => {
  let dimensionsHeight = 6.0
  let dimensionsWidth = 10.0

  if (isTableSize) {
    dimensionsHeight = 5.0
    dimensionsWidth = 7.0
  }

  // Default dimensions
  let dimensions = {
    w: dimensionsWidth,
    h: dimensionsHeight
  }
  // find height convert ratio
  let ratio = width / dimensionsWidth
  dimensions.h = height / ratio
  if (dimensions.h > dimensionsHeight) {
    dimensions.h = dimensionsHeight
    // find new width convert ratio
    ratio = height / dimensionsHeight
    dimensions.w = width / ratio
  }

  return dimensions
}

/**
 * addGraphToSlide    Foreach chart add slide with the graph
 * @param  pptx       pptx object
 */
const addGraphToSlide = (pptx, template, chartData) => {
  // Layout height.
  // let slideHeight = template.layout.height // 8.5
  let slideWidth = template.layout.width // 11

  let slide = pptx.addSlide('MASTER_SLIDE')
  // Get chart dimensions
  let { w, h } = getChartDimensions(chartData.width, chartData.height)
  let xPos = (slideWidth - w) / 2
  let yPos = 1.25

  slide.addText(chartData.title.toUpperCase(), {
    x: 0.4,
    y: 0.74,
    fontFace: 'Arial',
    color: '656565',
    h: 0.1
  })

  // Add graph
  slide.addImage({
    x: xPos,
    y: yPos,
    w,
    h,
    data: chartData.base64Img,
    sizing: { type: 'cover' }
  })

  addNewSlideClosing(slide, 'MASTER_SLIDE')
}

export const generatePptx = async (data, template) => {
  const pptx = new pptxgen()
  pptx.defineLayout(template.layout)
  pptx.layout = template.layout.name

  // Define master slide
  if (typeof template.masterSlides !== 'undefined') {
    template.masterSlides.forEach(function (slide) {
      pptx.defineSlideMaster(slide)
    })
  }

  // Add default slides
  if (typeof template.defaultSlides !== 'undefined') {
    template.defaultSlides.forEach(templateSlide => {
      // Add Slide
      let slide = pptx.addSlide(templateSlide.masterSlide)
      // Add Image
      templateSlide.images.forEach(img => {
        slide.addImage(img)
      })
      //Add Shape
      templateSlide.shapes.forEach(shape => {
        slide.addShape(pptx.shapes.LINE, shape.options)
      })
      //Add Text
      templateSlide.texts.forEach(text => {
        replaceVars(text, data)
        slide.addText(text.texts, text.options)
      })
      // final changes to slide
      addNewSlideClosing(slide, templateSlide.masterSlide)
    })
  }

  // Add statistic slide
  if (typeof template.statisticSlides !== 'undefined') {
    template.statisticSlides.forEach(templateSlide => {
      let rowPerTable = template.tableOptions.rowPerTable || 8
      // calculate total combo of country specialty.

      // let specialties = []

      let selectedRowsToExport = data.specialtyCountryArray.length || 1
      let totalRow = selectedRowsToExport
      let totalSlide = Math.ceil(totalRow / rowPerTable) // 8 row for 1 slide.

      for (let i = 1; i <= totalSlide; i++) {
        // Add Slide
        let slide = pptx.addSlide(templateSlide.masterSlide)
        // Add Text (title & subtitle)
        templateSlide.texts.forEach(function (text) {
          slide.addText(text.texts, text.options)
        })
        // Add Statistic
        addStatisticSlide(
          templateSlide.name,
          slide,
          template,
          i,
          data.clientList,
          data.specialtyCountryArray
        )
        // final changes to slide
        addNewSlideClosing(slide, templateSlide.masterSlide)
      }
    })
  }
  // Add chart slides
  const charts = data.chartsObject
  if (!isEmptyObject(charts)) {
    Object.keys(charts).forEach(chart =>
      addGraphToSlide(pptx, template, charts[chart])
    )
  }

  if (typeof template.endingSlides !== 'undefined') {
    template.endingSlides.forEach(templateSlide => {
      // Add Slide
      const slide = pptx.addSlide(templateSlide.masterSlide)
      // Add Image
      templateSlide.images.forEach(img => {
        slide.addImage(img)
      })
      //Add Shape
      templateSlide.shapes.forEach(shape => {
        slide.addShape(pptx.shapes.LINE, shape.options)
      })
      //Add Text
      templateSlide.texts.forEach(text => {
        replaceVars(text, data)
        slide.addText(text.texts, text.options)
      })
      addNewSlideClosing(slide, templateSlide.masterSlide)
    })
  }

  pptx.writeFile({
    fileName: 'list.pptx',
    compression: true
  })
}
