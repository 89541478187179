import PropTypes from 'prop-types'
import Input from '../Input'
import { propError, propWarning } from '../utils'
import { useField } from 'formik'

const TextArea = ({ ...props }) => {
  const [field, meta] = useField(props)
  return (
    <Input
      {...field}
      {...props}
      id={props.name}
      error={meta.touched && meta.error ? meta.error : null}
      textarea
    />
  )
}
TextArea.displayName = 'TextArea'
TextArea.propTypes = {
  /**
   * Input label
   */
  label: PropTypes.string.isRequired,
  /**
   * Input name
   */
  name: PropTypes.string.isRequired,
  /**
   * If `true` the textarea will resize to fit entered text
   */
  autosize: PropTypes.bool,
  /**
   * If and how the textarea is resizable by the user
   */
  resize: PropTypes.oneOf([
    '',
    'both',
    'horizontal',
    'vertical',
    'block',
    'inline'
  ]),
  /**
   * If `true`, the label is floating, like material design text fields (not used with "placeholder")
   */
  labelFloat: propWarning(
    'Deprecated prop: This prop will be removed in version 3. Use `rightElements` prop instead.'
  ),
  /**
   * Not supported
   */
  button: propError('This prop is not supported'),
  /**
   * Not supported
   */
  rightElements: propError('This prop is not supported'),
  /**
   * A minimum height for the field
   */
  minHeight: PropTypes.number,
  /**
   * A maximum height for the field
   */
  maxHeight: PropTypes.number
}

export default TextArea
