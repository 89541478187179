import { all, call, put, take, takeEvery, select } from 'redux-saga/effects'
import i18next from 'i18next'
import { actions as chatActions } from './ChatReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import {
  getIsAuth,
  getFeaturesTable,
  getIsLimited
  // getDspId
} from 'modules/auth/AuthSelector'
import { actions as trackingActions } from 'modules/tracking/TrackingReducer'
import siteConfig from 'site.config.json'
import history from '../history'
import DougallMLError from 'services/dougall/DougallMLError'
import moment from 'moment'
import {
  getMessages,
  // getLinkedPromptId,
  getThreadId
} from './ChatSelector'
import { actions as deviceIdActions } from 'modules/deviceId/DeviceIdReducer'
// import { parseJwt } from 'utils'
import SessionService from 'services/SessionService'
import { phnxSiteID } from 'constants'

const Session = new SessionService()

const { routes } = siteConfig

function* validatePromptAccess(services) {
  const SessionService = services('SessionService')

  const { promptsAllowed = null } = yield select(getFeaturesTable())

  const promptsRequested = yield call(
    [SessionService, 'getFromCache'],
    'promptsRequested',
    0
  )

  if (
    typeof promptsAllowed === 'number' &&
    promptsRequested >= promptsAllowed
  ) {
    yield call([history, history.replace], routes.login.path)
  }
}

function* promptRequest(services, { payload }) {
  const DougallService = services('DougallService')
  const SessionService = services('SessionService')
  const CookieService = services('CookieService')
  const { promptsAllowed = null } = yield select(getFeaturesTable())
  const messages = yield select(getMessages())

  // const access = yield call([CookieService, 'getFromCache'], 'access', '')

  // const { partyId } = parseJwt(access)

  const date = moment().format('DD/MM/YYYY')

  try {
    const isAuth = yield select(getIsAuth())
    // const isLimited = yield select(getIsLimited())
    const deviceId = yield call(
      [CookieService, 'getFromCache'],
      'psl_device_id',
      !isAuth ? '' : 'DeviceID not available'
    )

    const { promptFile, hcpPrompt } = payload

    // Add date chunk divider
    if (date && !messages.find(m => m.date === date && m.isDivider)) {
      yield put(
        chatActions.addMessage({
          date,
          isDivider: true
        })
      )
    }

    // Update Chat reducer
    yield put(
      chatActions.addMessage({
        message: hcpPrompt,
        isAnswer: false,
        date: moment().format()
      })
    )
    yield put(
      chatActions.setFormState({
        prompt: ''
      })
    )

    if (!deviceId) {
      yield take(deviceIdActions.deviceIdReady.type)
    }

    // Exit if user is anon & deviceId is not ready
    if (!isAuth && !deviceId) {
      return
    }

    yield put(chatActions.promptRequestStart())

    const promptsRequested = yield call(
      [SessionService, 'getFromCache'],
      'promptsRequested',
      0
    )

    if (promptsAllowed && promptsRequested >= promptsAllowed) {
      // User no longer has prompts available, redirect to login page
      yield call([history, history.replace], routes.login.path)
    } else {
      // const linkedPromptId = yield select(getLinkedPromptId())
      const threadId = yield select(getThreadId())
      // const dspId = yield select(getDspId())
      // const [languageCode, countryCode] = navigator.language.split('-')

      // If file, first upload
      let fileIds
      if (promptFile) {
        try {
          const { name: fileName, type: contentType } = promptFile

          // Get Signed URL
          const signedUrl = yield call(
            [DougallService, 'fileSignedUrlRequest'],
            {
              fileName,
              contentType
            }
          )

          // Upload File
          yield call(
            [DougallService, 'fileSignedUrlUpload'],
            signedUrl?.url,
            signedUrl?.fields,
            promptFile
          )

          // Upload File to Open AI
          const uploadResponse = yield call(
            [DougallService, 'fileOpenAIUpload'],
            {
              siteId: parseInt(phnxSiteID),
              files: [signedUrl?.fields?.key]
            }
          )

          const { files } = uploadResponse
          if (files && files.length) {
            fileIds = [files[0].id]
          }
        } catch (e) {
          console.error('File Upload Process Error', e)
        }
      }

      const response = yield call([DougallService, 'promptStreamRequest'], {
        // ...restPayload,
        prompt: hcpPrompt,
        fileIds,
        // deviceId: isAuth && !isLimited ? deviceId : `${deviceId}+${Date.now()}`,
        // countryCode,
        // languageCode,
        // dspId,
        threadId
        // applicationLinkedPromptId: linkedPromptId
        //   ? linkedPromptId.toString()
        //   : undefined
      })

      if (response) {
        if (promptsAllowed) {
          // Increase cache count if role contains promptsAllowed key
          yield call(
            [SessionService, 'saveToCache'],
            'promptsRequested',
            promptsRequested + 1
          )
        }
      }
    }
  } catch (e) {
    yield put(
      trackingActions.trackClickAction({
        title: 'Site/View/Answer/prompt/failure'
      })
    )
    // Handle Timeout error & add error answer
    if (e instanceof DougallMLError && [408, 504].includes(e.getCode())) {
      yield put(
        chatActions.addMessage({
          answer: i18next.t('dougallGptTimeoutAnswer'),
          isAnswer: true,
          showSocial: false,
          isError: true,
          showWarning: false,
          date: moment().format()
        })
      )
      yield put(chatActions.promptFail())
    } else {
      yield put(
        notificationActions.notificationEnqueue({
          message: 'There was a problem with the request.',
          type: 'error'
        })
      )
      yield put(chatActions.promptFail())
    }
  } finally {
    // Set URL flag request to true
    yield put(chatActions.setUrlRequested(true))
  }
}

function* actionSubmit(services, { payload }) {
  const DougallService = services('DougallService')
  const CookieService = services('CookieService')

  try {
    const { action, promptId, answerId, feedback, undo, parentPromptId } =
      payload

    const isAuth = yield select(getIsAuth())
    const isLimited = yield select(getIsLimited())
    const deviceId = yield call(
      [CookieService, 'getFromCache'],
      'psl_device_id',
      !isAuth ? '' : 'DeviceID not available'
    )
    // Exit if user is anon & deviceId is not ready
    if (!isAuth && !deviceId) {
      return
    }

    const feedbackMessage = feedback
      ? feedback
      : action === 'like'
      ? 'Yes'
      : 'No'
    const { actionId } = yield call([DougallService, 'answerActionRequest'], {
      action,
      feedback: feedbackMessage,
      applicationPromptId: promptId,
      answerId,
      applicationLinkedPromptId: parentPromptId,
      deviceId: isAuth && !isLimited ? deviceId : `${deviceId}+${Date.now()}`,
      undo
    })

    if (actionId) {
      // if (feedback) {
      // const dspId = yield select(getDspId())
      // const campaignId = yield call(
      //   [SessionService, 'getFromCache'],
      //   'CampaignID',
      //   ''
      // )
      // const dspUserData = dspId
      //   ? { dspId, campaignId: parseInt(campaignId) }
      //   : {}
      const response = yield call([DougallService, 'answerFeedbackSubmit'], {
        feedback: feedbackMessage,
        actionId
        // ...dspUserData
      })
      if (response) {
        yield put(
          notificationActions.notificationEnqueue({
            message: i18next.t('feedbackThankYou'),
            type: 'success'
          })
        )
        // yield put(
        //   trackingActions.trackClickAction({
        //     title: 'Site/View/Home/Feedback-Overlay/success',
        //     userData: {
        //       answerId,
        //       promptId
        //     }
        //   })
        // )
      } else {
        yield put(
          notificationActions.notificationEnqueue({
            message: response,
            type: 'error'
          })
        )
      }
      // }
    } else {
      yield put(
        notificationActions.notificationEnqueue({
          message: 'There was a problem submitting your action',
          type: 'error'
        })
      )
    }
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'There was a problem submitting your action',
        type: 'error'
      })
    )
  }
}

function* shareEmail(services, { payload }) {
  const DougallService = services('DougallService')
  const UserTransactionService = services('UserTransactionService')

  try {
    const {
      pId = undefined, // If pId then is Email Share
      // Tracking
      answerId,
      promptId,
      source
    } = payload

    const service = pId
      ? [DougallService, 'promptShareRequest'] // Share prompt
      : [UserTransactionService, 'inviteSubmit'] // Invite your colleague
    const response = yield call(service, payload)

    if (response) {
      yield put(
        trackingActions.trackSubmitAction({
          title: 'Site/View/Answer/share/success',
          userData: {
            answerId,
            promptId,
            source
          }
        })
      )
      yield put(
        notificationActions.notificationEnqueue({
          message: i18next.t('emailShareFormSnackbarMessage'),
          type: 'success'
        })
      )
    }
  } catch (e) {
    console.error(e)
    yield put(
      notificationActions.notificationEnqueue({
        message: i18next.t('commentsSagaErrorMessage'),
        type: 'error'
      })
    )
  }
}

function* getGptToken(services) {
  const SmartsService = services('SmartsService')

  try {
    const response = yield call([SmartsService, 'gptTokenRequest'])
    if (response) {
      const gpt = response.responseData
      Session.saveToCache('gpt', gpt)
    } else {
      Session.deleteFromCache('gpt')
    }
  } catch (e) {
    console.error(e)
    yield put(
      notificationActions.notificationEnqueue({
        message: i18next.t('gptTokenError'),
        type: 'error'
      })
    )
    if (window.location.pathname === '/gpt')
      setTimeout(() => window.location.replace('/'), 3000)
  }
}

export default function* watchChat(services) {
  yield all([
    takeEvery('GPT_TOKEN_REQUEST', getGptToken, services),
    takeEvery('CHAT_PROMPT_REQUEST', promptRequest, services),
    takeEvery('CHAT_VALIDATE_PROMPT_ACCESS', validatePromptAccess, services),
    // If prompt validation should occur on events add the events here like:
    // or dispatch the action validatePromptAccess from the component
    //takeEvery('CHAT_SET_FORM_STATE', validatePromptAccess, services),
    //takeEvery('OTHER_ACTION', validatePromptAccess, services),
    takeEvery('CHAT_ACTION_SUBMIT', actionSubmit, services),
    takeEvery('CHAT_SHARE_EMAIL', shareEmail, services)
  ])
}
