import axios from 'axios'

const SmartsML = (SmartsMLInterceptors, CookieService) => ({
  createClient() {
    const instance = axios.create({})
    instance.interceptors.request.use(
      SmartsMLInterceptors.validateCacheTokens,
      null
    )
    instance.interceptors.response.use(
      null,
      SmartsMLInterceptors.catchNetworkErrors
    )
    instance.interceptors.response.use(
      null,
      SmartsMLInterceptors.detectMLfailuresOnFail
    )
    return instance
  },
  async fetch(endpoint, method = 'GET', data = {}, extra = {}) {
    const { headers = {}, ...restExtra } = extra
    const access = CookieService.getFromCache('access', '')
    const conf = {
      url: endpoint,
      method,
      data,
      ...restExtra,
      headers: {
        Authorization: `Bearer ${access}`,
        'Content-Type': 'application/json',
        ...headers
      }
    }
    const client = this.createClient()
    return client.request(conf)
  },
  async fetchDougall(endpoint, method = 'GET', data = {}, token) {
    const conf = {
      url: endpoint,
      method,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
    const client = this.createClient()
    return client.request(conf)
  }
})

export default SmartsML
