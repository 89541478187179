import { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import styles from './ListItemIcon.module.sass'
import cx from 'classnames'

class ListItemIcon extends Component {
  static displayName = 'ListItemIcon'
  static defaultProps = {
    colorTier: 'default',
    icon: 'svg/material-design-icons/hardware/keyboard_arrow_right'
  }
  static propTypes = {
    /** Is this a button? */
    button: PropTypes.bool,
    /**
     * If `true`, this will be given styling allowing right alignment
     */
    right: PropTypes.bool,
    /**
     * An additional custom className for the root element
     */
    className: PropTypes.string,
    /**
     * @ignore
     */
    onMouseDown: PropTypes.func,
    /**
     * @ignore
     */
    onClick: PropTypes.func,
    /**
     * @ignore
     */
    onTouchStart: PropTypes.func
  }
  static contextTypes = {
    /**
     * If `true`, compact vertical padding designed for keyboard and mouse input will be used.
     */
    dense: PropTypes.bool
  }

  /* istanbul ignore next */
  restrictHandler = event => {
    const { onClick, onMouseDown, onTouchStart } = this.props
    /* istanbul ignore next */
    typeof onClick === 'function' && event.type === 'click' && onClick(event)
    /* istanbul ignore next */

    typeof onMouseDown === 'function' &&
      event.type === 'mousedown' &&
      onMouseDown(event)
    /* istanbul ignore next */
    typeof onTouchStart === 'function' &&
      event.type === 'touchstart' &&
      onTouchStart(event)
    /* istanbul ignore next */
    event.stopPropagation()
  }
  render = () => {
    const { right, button, className, onClick, onMouseDown, ...other } =
      this.props
    const { dense } = this.context
    return (
      <div
        data-testid="listItemIcon"
        className={cx(
          !button && styles.icon,
          right && styles.right,
          dense && styles.dense,
          className
        )}
      >
        <Icon
          onClick={this.restrictHandler}
          onMouseDown={this.restrictHandler}
          onTouchStart={this.restrictHandler}
          className={styles.iconButton}
          button={button}
          {...other}
        />
      </div>
    )
  }
}

export default ListItemIcon
