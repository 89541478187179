import { all, call, put, takeEvery, select } from 'redux-saga/effects'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as loginWithEmailActions } from './LoginWithEmailReducer'
import { actions as trackingActions } from 'modules/tracking/TrackingReducer'
import { deviceIdFull } from 'modules/deviceId/DeviceIdSaga'
import { getAccessToken } from 'modules/auth/AuthSelector'
import { authEndpoints } from '../../constants'
import history from '../../history'
// import { addSiteIdToPayload } from 'utils'
import siteConfig from 'site.config.json'

const { configuration } = siteConfig

const { deviceID } = configuration

/**
 * Request OTP Login email
 * @param services
 * @param payload
 */
function* loginWithEmailRequest(services, { payload: { email } }) {
  try {
    const CookieService = services('CookieService')
    const userTransaction = services('UserTransactionService')
    const token = yield select(getAccessToken())

    // GET DEVICE ID
    if (deviceID.sendOnOtp) {
      // if sendOnOtp required, request DeviceId
      yield call(deviceIdFull, services, deviceID.useCacheOnRefresh)
    }

    const deviceId = yield call(
      [CookieService, 'getFromCache'],
      'psl_device_id',
      ''
    )
    // FORM DATA
    const requestPayload = {
      email,
      deviceId: deviceID.sendOnOtp ? deviceId : undefined
    }

    yield call(loginCall, {
      userTransaction,
      requestPayload,
      token
    })
    // TRACK LOGIN REQUEST
    yield put(loginWithEmailActions.loginWithEmailRequestSuccess())
    yield put(
      trackingActions.trackSubmitAction({
        title: 'login-otp'
      })
    )
    yield put(trackingActions.trackSubmitActionReset())
    yield call([history, history.push], '/user/email-login')
  } catch (e) {
    console.error(e)
    yield put(loginWithEmailActions.loginWithEmailRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
  }
}

/**
 * Parses payload and performs login call
 * @param userTransaction
 * @param requestPayload
 * @param token
 */
function* loginCall({ userTransaction, requestPayload, token }) {
  const { loginWithEmailEndpoint } = authEndpoints
  // Site ID is not needed
  // const payload = addSiteIdToPayload(requestPayload)
  yield call(
    [userTransaction, 'loginWithEmail'],
    loginWithEmailEndpoint,
    requestPayload,
    token
  )
}

export default function* watchLoginRequest(services) {
  yield all([
    takeEvery('LOGIN_WITH_EMAIL_REQUEST', loginWithEmailRequest, services)
  ])
}
