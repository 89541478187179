import { authEndpoints, phnxSiteID, idhBrandId } from '../../constants'
import UserTransactionParser from './UserTransactionParser'

describe('UserTransaction Parser', () => {
  let parser = null
  const SessionService = {
    getFromCache: jest.fn().mockReturnValue()
  }
  const getState = jest.fn().mockReturnValue()

  beforeEach(() => {
    SessionService.getFromCache.mockReset()
    parser = UserTransactionParser(SessionService, getState)
  })

  test('It should return the parsed lastAction', () => {
    SessionService.getFromCache.mockReturnValue('/user/profile')
    getState.mockReturnValue({
      searchReducer: {
        isSearch: false,
        tagCategory: null
      }
    })

    const output = parser.lastAction()
    expect(output).toBe('Site : /user/profile')
  })
  test('It should return the parsed lastAction for home path', () => {
    SessionService.getFromCache.mockReturnValue('/')
    getState.mockReturnValue({
      searchReducer: {
        isSearch: false,
        tagCategory: null
      }
    })

    const output = parser.lastAction()
    expect(output).toBe('Site : /home')
  })
  test('It should return the parsed lastAction for empty', () => {
    SessionService.getFromCache.mockReturnValue('')
    getState.mockReturnValue({
      searchReducer: {
        isSearch: false,
        tagCategory: null
      }
    })

    const output = parser.lastAction()
    expect(output).toBe('')
  })
  test.skip('It should return the parsed lastAction when isSearch', () => {
    SessionService.getFromCache.mockReturnValue('/')
    getState.mockReturnValue({
      searchReducer: {
        isSearch: true,
        tagCategory: null
      }
    })
    const output = parser.lastAction()
    expect(output).toBe('Site : /search')
  })
  test.skip('It should return the parsed lastAction when isSearch & tagCategory', () => {
    SessionService.getFromCache.mockReturnValue('/')
    getState.mockReturnValue({
      searchReducer: {
        isSearch: true,
        tagCategory: 'medications'
      }
    })
    const output = parser.lastAction()
    expect(output).toBe('Site : /medications')
  })
  // sendFeebackParser
  test('It should return the correct body payload for a send feedback request (auth)', () => {
    getState.mockReturnValue({
      authReducer: {
        isAuth: true,
        isLimited: false
      }
    })
    SessionService.getFromCache
      .mockReturnValueOnce('US') // countryCode
      .mockReturnValueOnce(123) // professionId
      .mockReturnValueOnce(124) // specialtyId

    const payload = {
      email: 'test@test.com',
      firstName: 'John',
      lastName: 'Doe',
      grade: 9,
      message: 'This is a test'
    }
    const endpoint = authEndpoints.contactUsEndpoint

    const output = parser.sendFeedbackParser(payload, endpoint)
    expect(output).toStrictEqual({
      reCaptcha: undefined,
      feedback: {
        email: payload.email,
        firstName: payload.firstName,
        grade: payload.grade,
        lastName: payload.lastName,
        message: payload.message,
        countryCode: undefined,
        professionId: undefined,
        specialtyId: undefined
      }
    })
  })
  test('It should return the correct body payload for a send feedback request (limited)', () => {
    getState.mockReturnValue({
      authReducer: {
        isAuth: true,
        isLimited: true
      }
    })
    SessionService.getFromCache
      .mockReturnValueOnce('US') // countryCode
      .mockReturnValueOnce(123) // professionId
      .mockReturnValueOnce(124) // specialtyId

    const payload = {
      email: 'test@test.com',
      firstName: 'John',
      lastName: 'Doe',
      grade: 9,
      message: 'This is a test'
    }
    const endpoint = authEndpoints.contactUsEndpoint

    const output = parser.sendFeedbackParser(payload, endpoint)
    expect(output).toStrictEqual({
      reCaptcha: undefined,
      feedback: {
        email: payload.email,
        firstName: payload.firstName,
        grade: payload.grade,
        lastName: payload.lastName,
        message: payload.message,
        countryCode: 'US',
        professionId: 123,
        specialtyId: 124
      }
    })
  })
  test('It should return the correct body payload for a send feedback request (anon)', () => {
    getState.mockReturnValue({
      authReducer: {
        isAuth: false,
        isLimited: false
      }
    })
    SessionService.getFromCache
      .mockReturnValueOnce('US') // countryCode
      .mockReturnValueOnce(123) // professionId
      .mockReturnValueOnce(124) // specialtyId

    const payload = {
      email: 'test@test.com',
      firstName: 'John',
      lastName: 'Doe',
      grade: 9,
      message: 'This is a test',
      reCaptcha: 'captcha123'
    }
    const endpoint = authEndpoints.contactUsEndpoint

    const output = parser.sendFeedbackParser(payload, endpoint)
    expect(output).toStrictEqual({
      reCaptcha: 'captcha123',
      feedback: {
        email: payload.email,
        firstName: payload.firstName,
        grade: payload.grade,
        lastName: payload.lastName,
        message: payload.message,
        countryCode: undefined,
        professionId: undefined,
        specialtyId: undefined
      }
    })
  })
  test('It should return the correct body payload for a send feedback request (feedbackEndpoint)', () => {
    getState.mockReturnValue({
      authReducer: {
        isAuth: true,
        isLimited: false
      }
    })
    SessionService.getFromCache
      .mockReturnValueOnce('US') // countryCode
      .mockReturnValueOnce(123) // professionId
      .mockReturnValueOnce(124) // specialtyId

    const payload = {
      email: 'test@test.com',
      firstName: 'John',
      lastName: 'Doe',
      grade: 9,
      message: 'This is a test',
      reCaptcha: 'captcha123'
    }
    const endpoint = authEndpoints.feedbackEndpoint

    const output = parser.sendFeedbackParser(payload, endpoint)
    expect(output).toStrictEqual({
      idhBrandId: parseInt(idhBrandId),
      feedback: {
        email: payload.email,
        firstName: payload.firstName,
        grade: payload.grade,
        lastName: payload.lastName,
        message: payload.message
      }
    })
  })

  // inviteSubmitParser
  test('It should return the correct body payload for a invite request', () => {
    const payload = {
      email: 'test@test.com',
      name: 'John',
      toEmail: 'another@test.com',
      message: 'This is a test\nwith a break line'
    }

    const output = parser.inviteSubmitParser(payload)
    expect(output).toStrictEqual({
      brandType: 'inviteYourColleague',
      gptInvite: {
        email: payload.email,
        emailTo: [payload.toEmail],
        firstName: payload.name,
        message: 'This is a test<br>with a break line'
      }
    })
  })

  // sendEmailParser
  test('It should return the correct body payload for a sendEmail request', () => {
    const payload = {
      email: 'test@test.com',
      name: 'John',
      emailto: 'another@test.com',
      message: 'This is a test',
      articleUrl: '/article/im-an-article',
      articleTitle: 'Im an article'
    }

    const output = parser.sendEmailParser(payload)
    expect(output).toStrictEqual({
      brandType: 'share',
      share: {
        articleTitle: payload.articleTitle,
        articleUrl: payload.articleUrl,
        email: payload.email,
        emailTo: payload.emailto,
        firstName: payload.name,
        lastName: ' ',
        message: payload.message
      }
    })
  })

  // registrationSubmitParser
  test('It should return the correct body payload for a registration request', () => {
    const payload = {
      email: 'test@test.com',
      firstName: 'John',
      lastName: 'Doe',
      state: 'MT',
      country: 'Canada',
      profession: 'Physician',
      specialty: 'Oncology',
      reCaptcha: 'captcha123'
    }

    const output = parser.registrationSubmitParser(payload)
    expect(output).toStrictEqual({
      reCaptcha: payload.reCaptcha,
      registration: {
        country: payload.country,
        email: payload.email,
        firstName: payload.firstName,
        lastName: payload.lastName,
        option: ' ',
        profession: payload.profession,
        specialty: payload.specialty,
        state: payload.state,
        userProfession: payload.profession
      },
      siteId: parseInt(phnxSiteID)
    })
  })
})
