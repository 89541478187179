import PropTypes from 'prop-types'
import { useField, useFormikContext } from 'formik'
import SelectMenu from './ui/SelectMenu'

const SelectInput = ({
  options,
  clearFields = [],
  suggestionsLimit = 6,
  sort = true,
  showAllSuggestionsOnClick = true,
  freeEntry = true,
  ...props
}) => {
  const [field, meta] = useField({ ...props, type: 'select' })
  const { handleChange, setFieldValue } = useFormikContext()
  const fieldName = props.name
  const handleSuggestionSelected = (event, { suggestion: { key } }) => {
    setFieldValue(fieldName, key)
  }
  const handleOnfocus = () => {
    setFieldValue(fieldName, '')
    if (clearFields) {
      clearFields.forEach(item => setFieldValue(item, null))
    }
  }
  return (
    <SelectMenu
      {...props}
      {...field}
      id={fieldName}
      suggestionsLimit={suggestionsLimit}
      passthrough
      sort={sort}
      name={fieldName}
      value={field.value}
      onFocus={handleOnfocus}
      onSuggestionSelected={handleSuggestionSelected}
      onChange={handleChange(fieldName)}
      options={options}
      freeEntry
      showAllSuggestionsOnClick={showAllSuggestionsOnClick}
      error={meta.touched && meta.error ? meta.error : null}
      data-testid="SelectMenu"
    />
  )
}
SelectInput.propTypes = {
  /**
   * Input name
   */
  name: PropTypes.string.isRequired,
  /**
   * Input label
   */
  label: PropTypes.string.isRequired,
  /**
   * Additional data for default select input
   */
  props: PropTypes.object,
  /**
   * object for select input options
   */
  options: PropTypes.object,
  /**
   * Number of suggestions to show in list
   */
  suggestionsLimit: PropTypes.number,
  /**
   * names of inputs to clear when select is clicked
   */
  clearFields: PropTypes.array,
  sort: PropTypes.bool,
  showAllSuggestionsOnClick: PropTypes.bool,
  freeEntry: PropTypes.bool
}
export default SelectInput
