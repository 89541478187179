import { createReducer, createAction } from '@reduxjs/toolkit'

export const searchRequest = createAction('SEARCH_REQUEST')
export const searchRequestSuccess = createAction('SEARCH_REQUEST_SUCCESS')
export const searchRequestFail = createAction('SEARCH_REQUEST_FAIL')
export const searchRequestFormState = createAction('SEARCH_REQUEST_FORM_STATE')
export const searchRequestFormStateClear = createAction(
  'SEARCH_REQUEST_FORM_STATE_CLEAR'
)

export const searchSpecialtiesRequest = createAction(
  'SEARCH_SPECIALTIES_REQUEST'
)
export const searchSpecialtiesSuccess = createAction(
  'SEARCH_SPECIALTIES_SUCCESS'
)
export const searchSpecialtiesFail = createAction('SEARCH_SPECIALTIES_FAIL')

export const searchProfessionsRequest = createAction(
  'SEARCH_PROFESSIONS_REQUEST'
)
export const searchProfessionsSuccess = createAction(
  'SEARCH_PROFESSIONS_SUCCESS'
)
export const searchProfessionsFail = createAction('SEARCH_PROFESSIONS_FAIL')

export const actions = {
  searchRequest,
  searchRequestSuccess,
  searchRequestFail,
  searchRequestFormState,
  searchRequestFormStateClear,
  searchSpecialtiesRequest,
  searchSpecialtiesSuccess,
  searchSpecialtiesFail,
  searchProfessionsRequest,
  searchProfessionsSuccess,
  searchProfessionsFail
}

const initialState = {
  advisorList: [],
  fetching: false,
  listStatus: false,
  formState: {},
  fetchingSpecialties: false,
  specialties: {},
  fetchingProfessions: false,
  professions: {}
}

const advisorSearchReducer = createReducer(initialState, {
  [searchRequest]: (state, { payload: { advisorList } }) => {
    state.advisorList = advisorList
    state.fetching = true
    state.listStatus = false
  },
  [searchRequestSuccess]: (state, { payload: { advisorList } }) => {
    state.advisorList = advisorList
    state.fetching = false
    state.listStatus = true
  },
  [searchRequestFail]: state => {
    state.advisorList = []
    state.fetching = false
    state.listStatus = false
  },
  [searchRequestFormState]: (state, { payload: { formState } }) => {
    state.formState = formState
  },
  [searchRequestFormStateClear]: state => {
    state.formState = {}
  },
  [searchSpecialtiesRequest]: state => {
    state.fetchingSpecialties = true
  },
  [searchSpecialtiesSuccess]: (state, { payload }) => {
    state.fetchingSpecialties = false
    state.specialties = { ...payload }
  },
  [searchSpecialtiesFail]: state => {
    state.fetchingSpecialties = false
    state.specialties = {}
  },
  [searchProfessionsRequest]: state => {
    state.fetchingProfessions = true
  },
  [searchProfessionsSuccess]: (state, { payload }) => {
    state.fetchingProfessions = false
    state.professions = { ...payload }
  },
  [searchProfessionsFail]: state => {
    state.fetchingProfessions = false
    state.professions = {}
  }
})

export default advisorSearchReducer
