import { createReducer, createAction } from '@reduxjs/toolkit'
import { initialValues } from './config'

export const setFormState = createAction('EXPLORE_LIST_SET_FORM_STATE')

export const submitFormRequest = createAction(
  'EXPLORE_LIST_SUBMIT_FORM_REQUEST'
)
export const submitFormRequestSuccess = createAction(
  'EXPLORE_LIST_SUBMIT_FORM_REQUEST_SUCCESS'
)
export const submitFormRequestFail = createAction(
  'EXPLORE_LIST_SUBMIT_FORM_REQUEST_FAIL'
)
export const submitCompleteRequest = createAction(
  'EXPLORE_LIST_COMPLETE_REQUEST'
)

export const actions = {
  setFormState,
  submitFormRequest,
  submitFormRequestSuccess,
  submitFormRequestFail,
  submitCompleteRequest
}

const exploreListReducer = createReducer(
  {
    formState: {
      ...initialValues
    },
    fetching: false
  },
  {
    [setFormState]: (state, { payload }) => {
      state.formState = { ...state.formState, ...payload }
    },
    [submitFormRequest]: state => {
      state.fetching = true
    },
    [submitCompleteRequest]: state => {
      state.fetching = true
    },
    [submitFormRequestSuccess]: state => {
      state.fetching = false
    },
    [submitFormRequestFail]: state => {
      state.fetching = false
    }
  }
)

export default exploreListReducer
