import { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import styles from './Ripple.module.sass'
import cx from 'classnames'

class Ripple extends Component {
  constructor(props) {
    super(props)
    this.state = {
      top: 0,
      left: 0,
      time: 0,
      animate: null,
      rippleTimeout: null
    }
    this.focus = this.focus.bind(this)
    this.blur = this.blur.bind(this)
    this.rippleStart = this.rippleStart.bind(this)
    this.rippleEnd = this.rippleEnd.bind(this)
  }
  static displayName = 'Ripple'
  static propTypes = {
    /** The component children (Contains the element that should display a ripple) */
    children: PropTypes.node,
    /** The element's tagname */
    element: PropTypes.any,
    /** An additional custom clasname for the ripple parent element */
    className: PropTypes.string,
    /** The ripple has color determined by the theme */
    hasColor: PropTypes.bool,
    /** A CSS modules style object to override default theme */
    altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    /**
     * @ignore
     */
    onMouseDown: PropTypes.func,
    /**
     * @ignore
     */
    onTouchStart: PropTypes.func
  }
  rippleRef = createRef()
  focus(event) {
    const { animate } = this.state
    if (animate !== 'start') {
      const focusX = event.target.clientWidth / 2
      const focusY = event.target.clientHeight / 2
      this.setState({
        left: focusX,
        top: focusY,
        time: Date.now(),
        animate: 'focus'
      })
    }
  }

  blur() {
    this.setState({ animate: 'end' })
  }

  rippleStart(event) {
    const { onMouseDown, onTouchStart } = this.props
    typeof onTouchStart === 'function' &&
      event.type === 'touchstart' &&
      onTouchStart(event)
    typeof onMouseDown === 'function' &&
      event.type === 'mousedown' &&
      onMouseDown(event)
    const touchX = event.touches ? event.touches[0].clientX : event.clientX
    const touchY = event.touches ? event.touches[0].clientY : event.clientY
    const buttonPos = this.rippleRef.current.getBoundingClientRect()
    this.setState({
      left: touchX - buttonPos.left,
      top: touchY - buttonPos.top,
      time: Date.now(),
      animate: 'start'
    })
  }
  rippleEnd() {
    const { animate } = this.state
    if (animate !== 'focus') {
      const minDuration = 175
      const currentDuration = Date.now() - this.state.time
      clearTimeout(this.state.rippleTimeout)
      let rippleTimeout
      if (currentDuration < minDuration) {
        rippleTimeout = setTimeout(() => {
          this.setState({
            animate: 'end'
          })
        }, minDuration - currentDuration)
        this.setState({
          rippleTimeout: rippleTimeout
        })
      } else {
        this.setState({
          animate: 'end'
        })
      }
    }
  }
  componentWillUnmount() {
    clearTimeout(this.state.rippleTimeout)
  }
  render() {
    const {
      element,
      className,
      hasColor,
      altTheme,
      onMouseDown,
      onTouchStart,
      ...other
    } = this.props
    const ElementTag = element || 'div'
    const themeStyles = { ...styles, ...altTheme }
    let rippleClass
    switch (this.state.animate) {
      case 'end':
        rippleClass = themeStyles.rippleEnd
        break
      case 'start':
        rippleClass = themeStyles.rippleStart
        break
      case 'focus':
        rippleClass = themeStyles.rippleFocus
        break
      default:
        rippleClass = themeStyles.ripple
    }

    const parentClass = hasColor ? 'rippleParentColor' : 'rippleParent'
    return (
      <ElementTag
        data-testid="Ripple"
        ref={this.rippleRef}
        className={cx(themeStyles[parentClass], className)}
        onMouseDown={this.rippleStart}
        onTouchStart={this.rippleStart}
        onMouseUp={this.rippleEnd}
        onTouchEnd={this.rippleEnd}
        onMouseOut={this.rippleEnd}
        {...other}
      >
        <div style={{ position: 'relative' }}>
          <div
            data-testid="RippleDiv"
            className={rippleClass}
            style={{ top: this.state.top, left: this.state.left }}
          />
        </div>
        {this.props.children}
      </ElementTag>
    )
  }
}

export default Ripple
