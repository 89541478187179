import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import DataTable from 'react-data-table-component'
import Card from 'components/Admin/Card/Card'
import { CircularProgress } from 'components/ui/Progress'
import DropdownInput from 'components/DropdownInput'
import Typography from 'components/ui/Typography'
import styles from './form.module.sass'
import Button from 'components/Admin/Button'
import Typeahead from 'modules/typeahead'
import * as incomingSelectors from '../IncomingCountsSelector'
import * as incomingActions from '../IncomingCountsReducer'
import { isEmpty, sortConstants } from 'utils'
import { smartsEndpoints } from 'constants/apiConstants'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const {
  getSearchFetching,
  getCounts,
  getPagination,
  getSearchState,
  getSpecialtyList,
  getProfessionList
} = incomingSelectors

const SearchForm = () => {
  let initialForm = {
    country: '',
    profession: '',
    specialty: '',
    orderBy: '',
    order: '',
    page: 1,
    perPage: 25
  }
  const dispatch = useDispatch()
  const [firstVisit, setFirstVisit] = useState(true)
  const [clearInput, setClearInput] = useState(false)
  const formState = useSelector(getSearchState())
  let formStatePr = isEmpty(formState) ? initialForm : formState
  const [initialValues, setInitialValues] = useState(formStatePr)
  const isFetching = useSelector(getSearchFetching())
  const data = useSelector(getCounts())
  const pagination = useSelector(getPagination())
  const specialtyList = useSelector(getSpecialtyList())
  const professionList = useSelector(getProfessionList())

  useEffect(() => {
    if (firstVisit && !isFetching) {
      dispatch(
        incomingActions.searchRequest({
          search: {
            country: '',
            profession: '',
            specialty: '',
            orderBy: '',
            order: '',
            page: 1,
            perPage: 25
          }
        })
      )
      dispatch(incomingActions.searchSpecialtiesRequest())
      dispatch(incomingActions.searchProfessionsRequest())
    }
    setFirstVisit(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      name: 'id',
      selector: row => row.id,
      sortField: 'id',
      sortable: true,
      cell: row => {
        return <Typography>{row.id}</Typography>
      }
    },
    {
      name: 'Country',
      selector: row => row.country,
      sortField: 'country',
      sortable: true,
      cell: row => {
        return <Typography>{row.country}</Typography>
      }
    },
    {
      name: 'Profession',
      selector: row => row.profession,
      sortField: 'profession',
      sortable: true,
      cell: row => {
        return <Typography>{row.profession}</Typography>
      }
    },
    {
      name: 'Specialty',
      selector: row => row.specialty,
      sortField: 'specialty',
      sortable: true,
      cell: row => {
        return <Typography>{row.specialty}</Typography>
      }
    },
    {
      name: 'Value',
      selector: row => row.value,
      sortField: 'value',
      sortable: false,
      cell: row => {
        return <Typography>{row.value}</Typography>
      }
    }
  ]
  const handlePageChange = page => {
    dispatch(
      incomingActions.searchRequest({
        search: {
          ...formStatePr,
          page: page
        }
      })
    )
  }
  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(
      incomingActions.searchRequest({
        search: {
          ...formStatePr,
          page,
          perPage: newPerPage
        }
      })
    )
  }
  const handleSort = ({ sortField: selector }, sortDirection) => {
    dispatch(
      incomingActions.searchRequest({
        search: {
          ...formStatePr,
          orderBy: selector,
          order: sortDirection
        }
      })
    )
  }
  const handleSubmit = values => {
    dispatch(
      incomingActions.searchRequest({
        search: {
          ...values,
          orderBy: '',
          order: '',
          page: 1
        }
      })
    )
  }
  const handleReset = formProps => {
    dispatch(
      incomingActions.searchRequest({
        search: {
          country: '',
          profession: '',
          specialty: '',
          orderBy: '',
          order: '',
          page: 1,
          perPage: 25
        }
      })
    )
    setClearInput(!clearInput)
    setInitialValues(initialForm)
  }
  return (
    <Formik
      key={clearInput}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {formProps => (
        <Form>
          <div className={styles.searchContainer}>
            <Card className={cx(styles.card, styles.searchCard)}>
              <Typography
                type="title"
                className={styles.cardTitle}
                color="text-primary"
              >
                Search Criteria
              </Typography>
              <div className={styles.searchForm}>
                <Typeahead
                  formName="admin-create-user"
                  label="Country"
                  name="country"
                  type="text"
                  traditional={false}
                  body={{
                    data: {
                      search: {
                        perPage: 100
                      }
                    },
                    endpoint: smartsEndpoints.variationCountries,
                    parseFunction: 'optionsParser'
                  }}
                />
                <DropdownInput
                  label="Profession"
                  name="profession"
                  options={professionList}
                  traditional={false}
                  customIcon="svg/custom/arrow-drop-down"
                />
                <DropdownInput
                  label="Specialty"
                  name="specialty"
                  options={sortConstants(specialtyList)}
                  traditional={false}
                  customIcon="svg/custom/arrow-drop-down"
                />
              </div>
              <div className={styles.actionContainer}>
                <Button
                  className={styles.resetButton}
                  text="Reset"
                  textCase="none"
                  disabled={false}
                  type="button"
                  backgroundColor="#d3d3d3"
                  onClick={() => handleReset(formProps)}
                />
                <Button
                  text="Search"
                  textCase="none"
                  disabled={isFetching}
                  type="submit"
                />
              </div>
            </Card>
            <Card className={styles.card}>
              <Typography
                type="title"
                className={styles.cardTitle}
                color="text-primary"
              >
                Incoming Counts Results
              </Typography>
              <DataTable
                columns={columns}
                data={data}
                keyField="key"
                progressPending={isFetching}
                pagination
                paginationServer
                paginationTotalRows={pagination.total}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                sortServer
                persistTableHead
                paginationPerPage={pagination.perPage}
                paginationRowsPerPageOptions={[15, 25, 35, 45, 55, 85, 150]}
                progressComponent={
                  <div className={styles.loadingComponent}>
                    <CircularProgress
                      fillColor="transparent"
                      size={64}
                      strokeWidth={4}
                    />
                  </div>
                }
              />
            </Card>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default SearchForm
