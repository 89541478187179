import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as feedbackActions } from './FeedbackReducer'
import { getIsAuth } from 'modules/auth/AuthSelector'
import i18next from 'i18next'
import { isEmpty, isInternalServerError } from 'utils'
import history from '../../../history'
import siteConfig from 'site.config.json'
import { piwikSiteId } from 'constants'
const { sections, routes } = siteConfig

/**
 * Submit Feedback process
 * @param services
 * @param payload
 */
function* feedbackRequest(services, { payload }) {
  try {
    // Send actual request to API
    yield call(feedbackCall, services, payload)
    yield all([
      // Track Request
      // put(trackingActions.trackSubmitActionReset()),

      put(feedbackActions.feedbackRequestSuccess()),
      put(
        // Acknowledge Feedback actions
        notificationActions.notificationEnqueue({
          message: i18next.t('feedbackConfirmation'),
          type: 'success'
        })
      ), // PUSH HISTORY TO RESET
      call([history, history.push], {
        pathname: '/gpt',
        state: { from: '/' }
      })
    ])
  } catch (e) {
    console.error(e)
    const errorMessage = isInternalServerError(e)
      ? i18next.t('feedbackFailSubmission')
      : i18next.t('feedbackFail')
    yield put(feedbackActions.feedbackRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: errorMessage,
        type: 'error'
      })
    )
  }
}

/**
 * Sends actual payload to user feedback API
 * @param {any} services
 * @param {Object} payload - {name, email, message, brandType, fields: { name, emailFrom, emailTo} }
 */
export function* feedbackCall(services, payload) {
  const { firstName, lastName, email, userAgent, message, grade } = payload
  const SmartsService = services('SmartsService')
  const CookieService = services('CookieService')
  const SessionService = services('SessionService')

  const gptToken = yield call([SessionService, 'getFromCache'], 'gpt', '')
  const brandCode = piwikSiteId === '141' ? 'limasmarts' : 'limasmartsprod'

  const deviceId = yield call(
    [CookieService, 'getFromCache'],
    'psl_device_id',
    ''
  )

  const dataToSend = {
    brandCode,
    configType: 'standard',
    feedback: {
      email,
      firstName,
      lastName,
      grade,
      message
    },
    idhBrandId: 0,
    notifyCS: true,
    source: {
      device: userAgent,
      lastAction: 'Dougall PSL',
      piwikSiteId: parseInt(piwikSiteId),
      deviceId
    }
  }

  yield call(
    [SmartsService, 'sendFeedbackDougall'],
    dataToSend,
    gptToken.access
  )
}

/**
 * Handle nl/site rate redirection
 * @param services
 * @param payload
 */
function* feedbackRate(services) {
  try {
    const SessionService = services('SessionService')
    const isAuth = yield select(getIsAuth())
    const {
      location: { state, pathname }
    } = history

    const nlSite = yield call([SessionService, 'getFromCache'], 'nl_site')

    // AUTH REDIRECT TO FEEDBACK PAGE
    if (isAuth === true) {
      // INIT GENERIC NL FEEDBACK FORM
      let to = routes.nlFeedback.path

      // SITE RATE BUTTONS
      if (pathname.includes(routes.siteRate.path)) {
        yield call([history, history.replace], to, {
          from: isEmpty(state?.from) ? to : state.from
        })
      }
      // IF TODAY
      if (
        nlSite === sections?.feedback?.form?.ntkNLToday?.brandType &&
        routes?.nlTodayFeedback?.path
      ) {
        to = routes.nlTodayFeedback.path
      }
      // IF DISPATCH
      if (
        nlSite === sections?.feedback?.form?.ntkNLDispatch?.brandType &&
        routes?.nlDispatchFeedback?.path
      ) {
        to = routes.nlDispatchFeedback.path
      }
      // REDIRECT
      yield call([history, history.replace], to, {
        from: isEmpty(state?.from) ? to : state.from
      })
    } else {
      // CHECK IF THERE'S A LOGIN INTENTION
      let from = isEmpty(state?.from) ? '/' : state.from
      let loginRedirect = routes.nlFeedback.path

      // DECIDE FEEDBACK PAGE
      if (pathname.includes(routes.siteRate.path)) {
        from = isEmpty(state?.from) ? loginRedirect : state.from
      }
      // IF TODAY
      if (
        nlSite === sections?.feedback?.form?.ntkNLToday?.brandType &&
        routes?.nlTodayFeedback?.path
      ) {
        from = isEmpty(state?.from) ? routes?.nlTodayFeedback.path : state.from
        loginRedirect = routes?.nlTodayFeedback.path
      }
      // IF DISPATCH
      if (
        nlSite === sections?.feedback?.form?.ntkNLDispatch?.brandType &&
        routes?.nlDispatchFeedback?.path
      ) {
        from = isEmpty(state?.from)
          ? routes.nlDispatchFeedback.path
          : state.from
        loginRedirect = routes?.nlDispatchFeedback.path
      }
      // REDIRECT TO LOGIN WITH HISTORY
      yield call([history, history.replace], '/user/login', {
        from: from,
        loginRedirect: loginRedirect
      })
    }
  } catch (e) {
    console.error(e)
    yield put(feedbackActions.feedbackRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: i18next.t('feedbackFail'),
        type: 'error'
      })
    )
  }
}

export default function* watchFeedbackRequest(services) {
  yield all([
    takeEvery('FEEDBACK_REQUEST', feedbackRequest, services),
    takeEvery('FEEDBACK_RATE', feedbackRate, services)
  ])
}
