import { createReducer, createAction } from '@reduxjs/toolkit'

export const loginRequest = createAction('LOGIN_REQUEST')
export const loginRequestSuccess = createAction('LOGIN_REQUEST_SUCCESS')
export const loginRequestFail = createAction('LOGIN_REQUEST_FAIL')

export const actions = {
  loginRequest,
  loginRequestSuccess,
  loginRequestFail
}

const loginReducer = createReducer(
  { fetching: false },
  {
    [loginRequest]: state => {
      state.fetching = true
    },
    [loginRequestSuccess]: state => {
      state.fetching = false
    },
    [loginRequestFail]: state => {
      state.fetching = false
    }
  }
)

export default loginReducer
