import 'moment/min/locales'
import moment from 'moment'
import { unserialize } from 'utils'
import { Link } from 'react-router-dom'
import { phnxSiteID } from '../constants'
import siteConfig from 'site.config.json'

const { configuration } = siteConfig

/**
 * Function that formats the authors of a certain article.
 *
 * @param {obj[]} data – array of objects in the form of { LastName: "x", ForeName: "y", Initials: "z"}
 * @returns the string ready to be displayed for the article listings.
 */
const authorsFormat = data => {
  const authorList = data.map(
    author =>
      (isEmpty(author?.ForeName) ? '' : ` ${author?.ForeName}`) +
      (isEmpty(author?.LastName) ? '' : ` ${author?.LastName}`)
  )
  return authorList.toString().trim()
}

/**
 * Function that formats the authors of a certain article.
 *
 * @param {obj[]} data – array of objects in the form of { LastName: "x", ForeName: "y", Initials: "z"}
 * @param size
 * @returns the string ready to be displayed for the article listings.
 */
const authorsFormatSize = (data, size) => {
  try {
    const authorList = data
      ?.slice(0, size)
      ?.map(
        author =>
          (isEmpty(author?.ForeName) ? '' : ` ${author?.ForeName}`) +
          (isEmpty(author?.LastName) ? '' : ` ${author?.LastName}`)
      )
    return authorList.toString().trim()
  } catch (e) {
    return ''
  }
}

/**
 * Function that will convert the article body's new lines to paragraph
 * @param {string} text
 * @returns an object with the body as array, the reference and the source stripped from the text
 */
const bodyFormat = text => {
  const formattedText = {
    articleText: '',
    reference: '',
    source: ''
  }
  if (isEmpty(text)) return formattedText
  const refRegex = /Reference(s?)\s?:\s?(.*)/gi
  const sourceRegex = /Source:(.*\s*\S*)/gi
  const sourceArray = text.split(sourceRegex)
  let textItem = sourceArray[0]
  formattedText.source = stripTags(sourceArray[1]) // Some sources have line breaks within their html tag
  const refArray = sourceArray[0].split(refRegex)
  if (refArray?.length) {
    formattedText.reference = refArray[2]
    textItem = refArray[0]
  }
  const textRegex = /[\n]+/gm
  formattedText.articleText = textItem.trim().split(textRegex)
  return formattedText
}

/**
 * Gets a particular value,
 * if undefined, null or error it returns a default value.
 * Use this function to get Object values safely.
 *
 * ie: const myVar = getValue(() => someObject.someProp.SomeChildProp, defaultsTo);
 *
 * @param value (any) - The value to get
 * @param defaultsTo (any) - The value to defaults to
 * @return any
 */
const getValue = (value, defaultsTo) => {
  let safeValue

  try {
    safeValue = value()
  } catch (e) {
    return defaultsTo
  }

  return isDefined(safeValue) && safeValue !== null ? safeValue : defaultsTo
}

/**
 * A function that will convert a text into a hyphenated string.
 * E.g. Resources & Practice Aids > resources-&-practice-aids
 * @param {*} text
 */
const hyphenFormat = text => {
  return stripTags(text).trim().toLowerCase().replace(/(\s)+/g, '-')
}

/**
 * Checks if a value is defined
 *
 * @param value (any) - The value to check for
 * @return boolean
 */
const isDefined = value => {
  return typeof value !== 'undefined'
}

/**
 * Checks if a value is empty
 *
 * @param value (any) - The value to test
 * @return boolean
 */
const isEmpty = value => {
  return (
    !isDefined(value) ||
    value === '' ||
    value === false ||
    value === 0 ||
    isEmptyObject(value) ||
    value === null
  )
}

/**
 * Checks if obj is an empty Object
 *
 * @param obj (object) - The object to test
 * @return boolean
 */
const isEmptyObject = obj => {
  if (typeof obj != 'object') {
    return false
  }

  return getValue(() => Object.keys(obj).length, 0) <= 0
}

/**
 * Checks if value is an integer
 *
 * @param value (any) - The value to test
 * @return boolean
 */
const isInteger = value => {
  return Number.isInteger(value)
}

/**
 * A function to replace all html tags and malformed html with just the text.
 *
 * @param {*} html
 * @returns a clean string
 */
const stripTags = html => {
  if (typeof html !== 'string') return ''

  const doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.body.textContent || ''
}

/**
 * A function to truncate a long text.
 *
 * @param {string} text
 * @param {int} max : number of words to truncate
 * @param {string} altEllipsis : alternative elipsis
 *
 * @returns the new string with ellipsis at the end.
 */
const truncateText = (text, max = 50, altEllipsis = '...') => {
  const textArr = text.trim().split(/\s+/)
  const ellipsis = textArr.length > max ? altEllipsis : ''
  return textArr.slice(0, max).join(' ') + ellipsis
}

/**
 * A function that converts an unserialized PHP string.
 *
 * @param {string} text
 *
 * @returns the object with the corresponding properties.
 */
const unserializeText = text => {
  return unserialize(text)
}

/**
 * A function that prepares component for listing sections
 *
 * @param {string} listing
 *
 * @returns the object with the corresponding properties.
 */
const prepareListingLinks = listing => {
  const { link: to, ...rest } = listing
  const component = to ? Link : 'div'
  const replace = component === Link && to.startsWith('?') ? true : undefined
  return { ...rest, to, component, replace }
}

/**
 * A function that provides smooth scrolling to an element
 *
 * @param element
 * @param offset
 */
const scrollToTargetWithOffset = (element, offset) => {
  const elementPosition = element.getBoundingClientRect().top
  const offsetPosition = elementPosition - offset

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  })
}
/**
 * A function that helps completing tracking URLs
 *
 * @param {string} url
 * @param {string} secure
 * @returns {string} the url modificated if needed
 */
const transformTrackingUrl = (url, secure = false) => {
  if (url.indexOf('//') === -1) {
    const protocol = secure ? 'https' : 'http'
    url = `${protocol}://${url}/`
  }
  if (url.indexOf('.php') === -1) {
    url = url + 'piwik.php'
  }
  return url
}
/**
 * Function that recieves a node, find the last element and applies a style to it
 * @param {node} node
 * @param {string} style
 * @param tagName
 * @returns
 */
const addStyleToUIElement = (node, style, tagName = 'u') => {
  if (!node) return
  const elements = node.getElementsByTagName(tagName)
  if (!isEmpty(elements)) {
    const element = elements[elements.length - 1]
    element.className = style
  }
}

/**
 * Convert JWT to JSON
 * @param jwt
 */
function parseJwt(jwt) {
  try {
    let base64Url = jwt.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    return JSON.parse(jsonPayload)
  } catch (e) {
    return undefined
  }
}

/**
 * Stringifies a payload and ads phnxSiteID to it
 * @param {object} payload
 * @returns a string with the new object parsed
 */
const addSiteIdToPayload = (payload = {}) => {
  return JSON.stringify({ ...payload, siteId: parseInt(phnxSiteID) })
}

/**
 * Function that processes a text and add
 * @param {string} text
 * @param {boolean} truncate
 * @param {number} limit
 * @param {string} link
 * @param {boolean} useLink
 * @returns {string}
 */
function transformExcerpt(text, truncate, limit, link, useLink = true) {
  const stripHTML = stripTags(text)
  const newLink = useLink ? `...<u>${link}</u>` : ''
  const trunctated = truncateText(stripHTML, limit, '')
  const newText = trunctated.endsWith('.')
    ? trunctated.substring(0, trunctated.length - 1)
    : trunctated
  return newText.endsWith('.&nbsp;')
    ? `${newText.substring(0, newText.length - 7)}${newLink}`
    : `${newText}${newLink}`
}
/**
 * Function that processes a text and add
 * @param {string} text
 * @param {number} limit
 * @param {string} link
 * @returns {string}
 */
function transformAdExcerpt(text, limit, link) {
  const stripHTML = stripTags(text)
  const willTruncate = stripHTML.trim().split(/\s+/).length > limit
  const newLink = link ? `<u>${link}</u>` : ''
  const trunctated = willTruncate
    ? truncateText(stripHTML, limit, '')
    : stripHTML
  const newText =
    trunctated.indexOf('.') === trunctated.length - 1
      ? trunctated.substring(0, trunctated.length - 1)
      : trunctated
  return `${newText}...${newLink}`
}

const creditFormatHelper = topRightText => {
  let number = topRightText.split(' ')[0]
  let letters = topRightText.split(' ').splice(1).join(' ')
  let rightCreditFormat = `${
    /[1-9](?=0)/.test(number)
      ? !/[.]/.test(number) && !/-/.test(number)
        ? `${number}.0`
        : /.00/.test(number)
        ? /.[1-9]/.test(number)
          ? number.replace(/0/g, '').replace(/-/, '.')
          : number.replace(/\.0.*/, '.0')
        : /(?:[.])\d/.test(number)
        ? number.replace(
            /(?:[.])((?:\d)\d).*/,
            `${number.split(/\d(?=[.])/)[1].split('')[0]}${
              number.split(/\d(?=[.])/)[1].split('')[1]
            }`
          )
        : number.replace(/-/, '.').replace(/0/gi, '')
      : /-/gi.test(number)
      ? number.replace(/-/, '.')
      : /[.]/gi.test(number)
      ? number
      : /(?:\d)0/gi.test(number)
      ? number.replace(/0/, '.0')
      : number.replace(/\d+/, `${number}.0`)
  } ${
    /(?:\s)[/](?=\s)/gi.test(letters)
      ? letters
      : letters.replace(/[/]/gi, ` / `)
  }`.toUpperCase()
  rightCreditFormat = (
    /[1-9](?=0)/.test(rightCreditFormat)
      ? rightCreditFormat
      : /(?:\d)0/gi.test(rightCreditFormat)
      ? rightCreditFormat.replace(/0/gi, '')
      : rightCreditFormat
  ).replace(/0(?=[1-9])/, '')
  return /^[.]/.test(rightCreditFormat)
    ? rightCreditFormat.replace(/./, '0.')
    : /[.](?= )/.test(rightCreditFormat)
    ? rightCreditFormat.replace(/[.](?= )/, '.0')
    : rightCreditFormat
}

/**
 * Function that swaps or weitches elements positions
 * @param {number} from - index of the element that needs to me moved
 * @param {number} to - new index of the element that needs to be moved
 * @param {number} array - array with the elements
 * @returns {Array} New arrey with the swapped elements
 */
function switchElements(from, to, array) {
  if (
    typeof from === 'undefined' ||
    typeof to === 'undefined' ||
    isEmpty(array)
  )
    return array

  const newArray = [...array]

  const item = newArray.splice(from, 1)[0]
  newArray.splice(to, 0, item)

  return newArray
}

/**
 * Function that gets current year
 */

const getCurrentYear = () => {
  const d = new Date()
  return d.getFullYear()
}

/**
 * Function that looks into an object of specialties and returns
 * its value
 * @param {string} specialty
 * @param {object} specialtySynonyms
 * @returns the stringified value of the synonym or the specialty
 */
function getSpecialtySynonyms(specialty = '', specialtySynonyms = {}) {
  if (isEmpty(specialtySynonyms))
    return isEmpty(specialty) ? `""` : '"' + specialty + '"'
  else if (specialtySynonyms.hasOwnProperty(specialty)) {
    const specialtySynonym = specialtySynonyms[specialty]
    return Array.isArray(specialtySynonym)
      ? `[${specialtySynonym.map(item => '"' + item + '"')}]`
      : specialtySynonym
  } else return '"' + specialty + '"'
}

/**
 * Replace the "{%specialty%}" token for the given specialty
 * @param {*} queryString
 * @param {*} specialty
 * @returns the stringified query with the specialty replaced
 */
function replaceSpecialtyToken(queryString, specialty) {
  const specialtyRegex = /"{%specialty%}"/gi
  const emptySpecialtyRegex = /,?"{%specialty%}"/gi
  return isEmpty(specialty)
    ? queryString.replace(emptySpecialtyRegex, '')
    : queryString.replace(specialtyRegex, specialty)
}

/**
 * Validates if passed combination (country, profession, specialty) is valid
 * @param {*} country
 * @param {*} profession
 * @param {*} specialty
 * @returns boolean
 */
function isCombinationValid(country, profession, specialty) {
  return country && profession && (profession !== 'Physician' || specialty)
}

/**
 * Sort object alphabetically
 * @param object
 * @returns {{}}
 */
const sortConstants = object => {
  return Object.keys(object)
    .sort((a, b) => a.localeCompare(b))
    .reduce((result, i) => {
      result[i] = object[i]
      return result
    }, {})
}

/**
 * Capitalize string
 *
 * @param s
 */
const capitalize = s => {
  if (typeof s !== 'string') return ''
  const string = s.toLowerCase()
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Function to get the date formatted in chunks(today, yesterday, etc.)
 * @param {string} date // Must be 'DD/MM/YYYY'
 */
function getDateChunk(date) {
  return (
    date &&
    moment(date, 'DD/MM/YYYY').calendar(null, {
      sameDay: '[Today]',
      lastDay: '[Yesterday]',
      lastWeek: 'dddd',
      sameElse: 'DD/MM/YYYY'
    })
  )
}

/**
 * Filters proper links for each role
 * @param isAuth
 * @param isLimited
 * @param object
 * @returns {*}
 */
const getRoleBasedLinks = (isAuth, isLimited, object) => {
  if (isLimited) return object.links.filter(item => item.limited === true)
  else if (isAuth) return object.links.filter(item => item.auth === true)
  else return object.links.filter(item => item.anon === true)
}

/**
 * Function to find the userflow languageCode
 * @param {*} lang - user translation language
 * @returns - The languacode for userflow
 */
const getUserflowLanguageCode = lang => {
  const { localization } = configuration
  return localization.userflowLanguageCodes
    ? localization.userflowLanguageCodes[lang] ||
        localization.userflowLanguageCodes.default
    : undefined
}

/**
 * Function for tracking, get the ad type name
 * @param {string} type - Type from ad props
 */
const getAdType = type => {
  switch (type) {
    case 'som':
      return type
    case 'somRiver':
      return 'som-river'
    case 'iframe':
      return 'aimatch-screen1'
    default:
      return `aimatch-${type}`
  }
}

/**
 * Function to get the last time value minus the previous value or default values
 * @param {array} times - Time values
 */
const getTimeOnAd = times => {
  if (times.length < 2) return 1
  else {
    const timeOnAd = Math.round(
      (times[times.length - 1] - times[times.length - 2]) / 1000
    )
    return timeOnAd === 0 ? 1 : timeOnAd
  }
}

/**
 * Funtion for retrieving a sibling in certain direction
 * @param {*} event - The click event
 * @param {*} siblingDirection - Is the direction of the sibling, values can be nextElementSibling or previousElementSibling
 * @param {*} tagName - Is the tag Name of the sibling that we are looking for
 * @returns - The first sibling of the specified tag name and the specified direction
 */
function getSibling(
  startElement,
  siblingDirection = 'nextElementSibling',
  tagName = 'A'
) {
  while ((startElement = startElement?.[siblingDirection]))
    if (startElement?.tagName === tagName) return startElement

  return {}
}

/**
 * Function to get closest threshold value.
 * @param {number} entry - Value to check
 * @param {array} thresholdValues -Values to approach
 */
const computePercentValue = (entry, thresholdValues) => {
  return Math.round(
    thresholdValues.reduce((previuos, current) => {
      if (
        Math.abs(current - entry.intersectionRatio) <
        Math.abs(previuos - entry.intersectionRatio)
      )
        return current
      else return previuos
    }) * 100
  )
}

/**
 * A function that provides smooth scrolling to an element
 *
 * @param {string} id - String that identifies an element in the DOM
 * @param {number} offset - Number that will be added to the scroll position
 */
const scrollToTargetByIDWithOffset = (id, offset = 0) => {
  const element = document.getElementById(id)
  const elementPosition = element.getBoundingClientRect().top
  const offsetPosition = elementPosition + window.pageYOffset - offset

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  })
}

/**
 *  Function for obtaining the query corresponding to the given label
 * @param {object} infiniteQuery - Object containing all the different types of rivers
 * @param {string} activeTag - Tag that is selected right now
 * @param {string} property - The property that we are looking for
 * @returns - the query corresponding to the given label
 */
function getTagProperty(
  infiniteQuery,
  activeTag = 'all',
  property = 'query',
  defaultValue = {}
) {
  if (isEmpty(infiniteQuery.rivers))
    return !isEmpty(defaultValue) ? defaultValue : infiniteQuery

  const { rivers } = infiniteQuery

  //Search inside all the rivers in the config
  if (isEmpty(rivers[activeTag]?.[property]))
    return !isEmpty(defaultValue) ? defaultValue : rivers.all[property]
  else return rivers[activeTag][property]
}

/**
 * Function to find the profession id
 * @param {*} professions - Object filled eith the professions object
 * @param {*} profession - The desired profession
 * @returns - The desired profession id
 */
function getProfessionId(professions, profession) {
  return parseInt(
    Object.keys(professions).find(key => professions[key] === profession)
  )
}

/**
 * Function to save a new viewid any time it is needed
 * @param {any} services
 * @param {any} call
 */
function* saveViewId({ services, call }) {
  const ParamsService = services('ParamsService')
  const SessionService = services('SessionService')
  const partyId = yield call([SessionService, 'getFromCache'], 'MemberID', '')
  const viewid = partyId + phnxSiteID + Date.now()
  yield call([ParamsService, 'saveToCache'], {
    viewid
  })
}

/**
 * SupportBCP code langs for i18n
 * @param langCode
 * @returns {string|string|*}
 */
function supportBCP(langCode) {
  try {
    const supportBCP = langCode?.split('-')
    if (isEmpty(supportBCP[1])) {
      return langCode
    } else {
      langCode = `${supportBCP[0]}-${supportBCP[1].toUpperCase()}`
      // support i18n traditional chinese
      return langCode?.includes('zh-HANS') ? 'zh-CN' : langCode
    }
  } catch (e) {
    return ''
  }
}

/**
 * Function to generate secure random numbers
 */
const secureRandom = () => {
  const cryptoObj = window.crypto || window.msCrypto
  const byteArray = new Uint32Array(1)
  return cryptoObj.getRandomValues(byteArray)
}

/**
 * Validate if the error was throw by a 500 Status Code
 * @param error
 * @returns {boolean}
 */
function isInternalServerError(error) {
  const hasErrorInObject = error.response && error.response.status === 500
  const hasErrorInMessage = error?.message?.includes('Internal Server Error')
  const hasErrorInResponse = JSON.stringify(error).includes('Unexpected error.')

  return hasErrorInObject || hasErrorInMessage || hasErrorInResponse
}

export {
  transformExcerpt,
  transformAdExcerpt,
  authorsFormat,
  authorsFormatSize,
  prepareListingLinks,
  bodyFormat,
  scrollToTargetWithOffset,
  unserializeText,
  truncateText,
  stripTags,
  isInteger,
  isEmptyObject,
  isEmpty,
  isDefined,
  hyphenFormat,
  getValue,
  transformTrackingUrl,
  addStyleToUIElement,
  addSiteIdToPayload,
  parseJwt,
  creditFormatHelper,
  switchElements,
  getCurrentYear,
  getSpecialtySynonyms,
  replaceSpecialtyToken,
  isCombinationValid,
  sortConstants,
  capitalize,
  getDateChunk,
  getRoleBasedLinks,
  getUserflowLanguageCode,
  getAdType,
  getTimeOnAd,
  getSibling,
  computePercentValue,
  scrollToTargetByIDWithOffset,
  getTagProperty,
  getProfessionId,
  saveViewId,
  supportBCP,
  secureRandom,
  isInternalServerError
}
