import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as jobStatusSingleActions } from 'modules/jobStatusSingle/JobStatusSingleReducer'
import { smartsEndpoints } from '../../constants'
import { getAccessToken } from 'modules/auth/AuthSelector'
//import { isEmpty } from 'utils'

/**
 * Receives get single job status action and reacts accordingly
 *
 * @param payload
 * @param services
 */
function* jobStatusRequest(services, { payload: { listId } }) {
  const JobStatusService = services('JobStatusService')
  try {
    // GET ML TOKEN
    const token = yield select(getAccessToken())

    // GET LIST DATA
    const { responseData: data } = yield call(
      [JobStatusService, 'request'],
      'get',
      `${smartsEndpoints.listsFetch}/${listId}`,
      {},
      token
    )

    yield fork(fetchEmailsRequest, services, listId, data)
    yield fork(fetchGroupsRequest, services, listId, data)
    yield fork(fetchFilesRequest, services, listId)

    // UPDATE STORE
    yield put(
      jobStatusSingleActions.jobStatusRequestSuccess({
        list: { ...data }
      })
    )
  } catch ({ response }) {
    const {
      data: { message }
    } = response
    yield put(jobStatusSingleActions.jobStatusRequestFail({ error: message }))
    yield put(
      notificationActions.notificationEnqueue({
        message,
        duration: 0
      })
    )
  }
}

function* fetchEmailsRequest(services, listId, data) {
  const JobStatusService = services('JobStatusService')
  // GET ML TOKEN
  const token = yield select(getAccessToken())

  try {
    // GET SHARED EMAIL DATA IF EMAIL COUNTS > 0
    const {
      mapped: { emailsSharingCount }
    } = data
    if (emailsSharingCount > 0) {
      const {
        responseData: { data: emails }
      } = yield call(
        [JobStatusService, 'request'],
        'post',
        `${smartsEndpoints.listsShareEmails}`,
        { listId },
        token
      )
      yield put(
        jobStatusSingleActions.jobStatusEmailsRequestSuccess({ emails })
      )
    } else {
      yield put(
        jobStatusSingleActions.jobStatusEmailsRequestSuccess({ emails: [] })
      )
    }
  } catch ({ response }) {
    const {
      data: { message }
    } = response
    console.error(message)
    yield put(jobStatusSingleActions.jobStatusEmailsRequestFail())
  }
}

function* fetchGroupsRequest(services, listId, data) {
  const JobStatusService = services('JobStatusService')
  // GET ML TOKEN
  const token = yield select(getAccessToken())
  try {
    // GET SHARED GROUP DATA IF GROUPS COUNT > 0
    const {
      mapped: { groupsSharingCount }
    } = data
    if (groupsSharingCount > 0) {
      const {
        responseData: { data: groups }
      } = yield call(
        [JobStatusService, 'request'],
        'post',
        `${smartsEndpoints.listsShareGroups}`,
        { listId },
        token
      )
      yield put(
        jobStatusSingleActions.jobStatusGroupsRequestSuccess({ groups })
      )
    } else {
      yield put(
        jobStatusSingleActions.jobStatusGroupsRequestSuccess({ groups: [] })
      )
    }
  } catch ({ response }) {
    const {
      data: { message }
    } = response
    console.error(message)
    yield put(jobStatusSingleActions.jobStatusGroupsRequestFail())
  }
}

function* fetchFilesRequest(services, listId) {
  const JobStatusService = services('JobStatusService')
  // GET ML TOKEN
  const token = yield select(getAccessToken())
  try {
    // GET EXPORT FILES DATA
    const {
      responseData: { data: exportFiles }
    } = yield call(
      [JobStatusService, 'request'],
      'post',
      `${smartsEndpoints.listsExportFiles}`,
      { uuid: listId },
      token
    )
    yield put(
      jobStatusSingleActions.jobStatusFilesRequestSuccess({ exportFiles })
    )
  } catch ({ response }) {
    const {
      data: { message }
    } = response
    console.error(message)
    yield put(jobStatusSingleActions.jobStatusFilesRequestFail())
  }
}

function* jobStatusDownloadFile(
  services,
  { payload: { listId, exportFileIndex, name } }
) {
  const JobStatusService = services('JobStatusService')
  try {
    // GET ML TOKEN
    const token = yield select(getAccessToken())

    // DOWNLOAD FILE
    yield call(
      [JobStatusService, 'downloadFile'],
      listId,
      exportFileIndex,
      name,
      token
    )
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
  }
}

export default function* watchJobStatus(services) {
  yield all([takeEvery('JOB_STATUS_REQUEST', jobStatusRequest, services)])
  yield all([
    takeEvery('JOB_STATUS_DOWNLOAD_REQUEST', jobStatusDownloadFile, services)
  ])
}
