import { useSelector } from 'react-redux'
import FileInput from 'components/FileInput'
import Button from 'components/Button'
import * as listIngestionSelectors from '../ListIngestionSelector'
import styles from './form.module.sass'

const { getFetchStatus } = listIngestionSelectors

const FileForm = () => {
  const isFetching = useSelector(getFetchStatus())
  return (
    <div className={styles.fileForm} data-testid="fileFormContainer">
      <FileInput
        id="list-ingestion-file"
        label="File"
        name="file"
        type="text"
        required
        traditional
        accept=".xls, .xlsx, .csv"
        multiple={false}
        caption="Upload a file, allowed extensions: CSV, XLSX"
      />
      <Button
        data-testid="listIngestion-uploadFile"
        className={styles.uploadButton}
        type="submit"
        submitting={isFetching}
        disabled={isFetching}
        hasSpinner
      >
        Upload
      </Button>
    </div>
  )
}
export default FileForm
