import {
  piwikCustomVars,
  customVarTotal,
  piwikEnabled,
  siteUrl,
  scriptUrl,
  scriptName,
  piwikSiteId
} from '../constants'
import { setCustomVariable } from '@psl/piwik'
import { transformTrackingUrl } from 'utils'
import siteConfig from 'site.config.json'

const { configuration } = siteConfig

class TrackingService {
  constructor(CookieService) {
    this.newVisit = true
    this.initialized = false
    this._piwikCustomVars = piwikCustomVars
    this.SessionService = CookieService
    this.deferred = []
    // Initialize tracking service just once
    this.initializeTrackingScript({
      url: siteUrl,
      piwikSiteId,
      scriptName,
      scriptUrl,
      secure: true
    })
  }

  /**
   * Singleton service instance.
   *
   * @type {TrackingService}
   */
  initializeTrackingVars() {
    if (process.env.NODE_ENV === 'development' && piwikEnabled === 'false') {
      return
    }
    this._customVarTotal = customVarTotal
    this._piwikCustomVars = piwikCustomVars
    this.setTrackerVars()
    this.runDeferred()
    // this.registerMATracker()
  }

  /**
   * Function that initalize the tracking service
   *
   * @param {string} url
   * @param {number} siteId
   * @param {string} scriptName
   * @param {string} scriptUrl
   * @param {boolean} secure
   */
  initializeTrackingScript({
    url,
    piwikSiteId,
    scriptName,
    scriptUrl = '',
    secure = false
  }) {
    window._paq = window._paq || []

    url = transformTrackingUrl(url, secure)

    window._paq.push(['setSiteId', piwikSiteId])
    window._paq.push(['setTrackerUrl', url])

    const scriptElement = document.createElement('script')
    const refElement = document.getElementsByTagName('script')[0]
    scriptElement.type = 'text/javascript'
    scriptElement.defer = true
    scriptElement.async = true
    scriptElement.src = '' + (scriptUrl ?? url) + scriptName
    refElement.parentNode.insertBefore(scriptElement, refElement)

    this.initialized = true
  }

  /**
   * Sets the custom tracker vars from cached session values up to the maximum `customVarTotal`
   * Only sets tracker vars once
   *
   * @returns void
   */
  setTrackerVars() {
    if (!this.initialized) throw new Error('Piwik not initialized')
    let i = 1
    for (const key in this._piwikCustomVars) {
      let cachedValue = this.SessionService.getFromCache(
        this._piwikCustomVars[key],
        ''
      )
      // For MemberID the value will be always 1
      if (this._piwikCustomVars[key] === 'MemberID') {
        cachedValue = 1
      }
      // For site_uid fet uuid key from cache
      // if (_piwikCustomVars[key] === 'site_uid') {
      //   cachedValue = SessionService.getFromCache('uuid', '')
      // }
      setCustomVariable(i++, this._piwikCustomVars[key], cachedValue, 'visit')
    }
  }

  /**
   * Tracks an action to Piwik server.
   * Used by the tracking methods below.
   *
   * @param action
   * @param params – should never be more than 10 at a time
   * @param user_data - Accepts a Big JSON OBJ, go wild...
   * @returns void
   */
  track(action, params = {}, user_data = {}) {
    if (process.env.NODE_ENV === 'development' && piwikEnabled === 'false') {
      return
    }
    if (!this.initialized) {
      return this.deferTrack({ action, params, user_data })
    }

    if (this.newVisit) {
      this.forceNewVisit()
      this.newVisit = false
    } else {
      this.cleanForceNewVisit()
    }

    // UPDATE session vars
    this.setTrackerVars()

    // Clear old 'page' values.
    let i = 1
    for (i; i <= customVarTotal; i++) {
      setCustomVariable(i, '', '', 'page')
      if (i > customVarTotal) {
        break
      }
    }

    // Push new 'page' values.
    i = 1
    for (let [key, value] of Object.entries(params)) {
      setCustomVariable(i++, key, value, 'page')
      if (i > customVarTotal) {
        break
      }
    }

    // Clean not wanted params
    this.updateUrl(window.location.href.split('?')[0])

    // Append 'user_data' to piwik.php url if any
    this.appendToTrackingUrl(
      `user_data=${encodeURIComponent(JSON.stringify(user_data))}`
    )

    // Track page view
    this.trackPageview(action)
  }

  forceNewVisit() {
    if (!this.initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://developer.matomo.org/guides/tracking-javascript#configuration-of-the-tracker-object
    window._paq.push(['appendToTrackingUrl', 'new_visit=1'])
    window._paq.push(['deleteCookies'])
  }

  cleanForceNewVisit() {
    if (!this.initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://developer.matomo.org/guides/tracking-javascript#configuration-of-the-tracker-object
    window._paq.push(['appendToTrackingUrl', ''])
  }

  trackEvent(action) {
    if (!this.initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://developer.matomo.org/guides/tracking-javascript#configuration-of-the-tracker-object
    window['_paq'].push(['trackEvent', action])
  }

  updateUrl(url) {
    if (!this.initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://developer.matomo.org/guides/tracking-javascript#configuration-of-the-tracker-object
    window['_paq'].push(['setCustomUrl', url])
  }

  trackPageview(action) {
    if (!this.initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://developer.matomo.org/guides/tracking-javascript#configuration-of-the-tracker-object
    window['_paq'].push(['trackPageView', action])
  }

  appendToTrackingUrl(encodedString) {
    if (!this.initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://developer.matomo.org/guides/tracking-javascript#configuration-of-the-tracker-object
    window['_paq'].push(['appendToTrackingUrl', encodedString])
  }

  /**
   * Defers an action to be tracked after initialization
   *
   * @param action
   * @param params – should never be more than 10 at a time
   * @returns the number of actions to be tracked after intialization
   */
  deferTrack({ action, params, user_data }) {
    return this.deferred.push({ action, params, user_data })
  }

  /**
   * Runs all deferred tracking actions in the `deferred` array
   *
   * @returns void
   */
  runDeferred() {
    this.deferred.forEach(({ action, params, user_data }) => {
      this.track(action, params, user_data)
    })
    this.deferred = []
  }

  registerMATracker() {
    window.matomoMediaAnalyticsAsyncInit = () => {
      if (!window['Matomo']) return
      const MA = window['Matomo']['MediaAnalytics']
      if (!MA) return
      MA.removePlayer('html5')
      const {
        tracking: {
          schema: { video }
        }
      } = configuration
      const HTML5Player = (node, mediaType) => {
        // in this class we track interactions with the player
        // instance is created whenever a media for this player was found
        if (node.playerInstantiated) {
          // prevent creating multiple trackers for the same media
          // when scanning for media multiple times
          return
        }
        node.playerInstantiated = true

        const actualResource = MA.element.getAttribute(node, 'src')
        const resource = MA.element.getMediaResource(node, actualResource)
        const title = MA.element.getMediaTitle(node)

        MA.getMatomoTrackers()[0].trackPageView(video.action)

        const videoTracker = new MA.MediaTracker(
          'HTML5Player',
          mediaType,
          resource
        )

        videoTracker.setWidth(node.clientWidth)
        videoTracker.setHeight(node.clientHeight)
        videoTracker.setFullscreen(MA.element.isFullscreen(node))
        videoTracker.setMediaTitle(title)
        videoTracker.setMediaTotalLengthInSeconds(node.duration)
        const useCapture = true
        node.addEventListener(
          'play',
          () => {
            const elementResource = MA.element.getAttribute(node, 'src')
            const rsrc = MA.element.getMediaResource(node, elementResource)
            videoTracker.setResource(rsrc)
            videoTracker.setMediaTitle(`media/${title}`)
            videoTracker.play()
          },
          useCapture
        )

        node.addEventListener(
          'pause',
          () => {
            const elementResource = MA.element.getAttribute(node, 'src')
            const rsrc = MA.element.getMediaResource(node, elementResource)
            videoTracker.setResource(rsrc)
            videoTracker.setMediaTitle(`media/${title}`)
            videoTracker.pause()
          },
          useCapture
        )

        node.addEventListener('ended', () => videoTracker.finish(), useCapture)

        node.addEventListener(
          'emptied',
          () => videoTracker.finish(),
          useCapture
        )

        node.addEventListener(
          'timeupdate',
          () => {
            videoTracker.setMediaProgressInSeconds(node.currentTime)
            videoTracker.setMediaTotalLengthInSeconds(node.duration)
            videoTracker.update()
          },
          useCapture
        )

        node.addEventListener('seeking', () => videoTracker.seekStart(), true)

        node.addEventListener(
          'seeked',
          () => {
            videoTracker.setMediaProgressInSeconds(node.currentTime)
            videoTracker.setMediaTotalLengthInSeconds(node.duration)
            videoTracker.seekFinish()
          },
          useCapture
        )

        node.addEventListener('abort', () => videoTracker.finish())

        window.addEventListener(
          'resize',
          () => {
            videoTracker.setWidth(node.clientWidth)
            videoTracker.setHeight(node.clientHeight)
            videoTracker.setFullscreen(MA.element.isFullscreen(node))
          },
          useCapture
        )

        videoTracker.trackUpdate()
      }

      HTML5Player.scanForMedia = documentOrHTMLElement => {
        const html5Videos = documentOrHTMLElement.getElementsByTagName('video')

        for (const element of html5Videos) {
          if (!MA.element.isMediaIgnored(element)) {
            HTML5Player(element, MA.mediaType.VIDEO)
          }
        }
      }
      MA.addPlayer('HTML5Player', HTML5Player)
    }
  }
}

export default TrackingService
