import { useSelector } from 'react-redux'
import { getFetching, getFaqs } from './FaqSelector'
import PropTypes from 'prop-types'

import PromptList from 'components/Dougall/PromptList/PromptList'
import Typography from 'components/ui/Typography'
import styles from './Faq.module.sass'
import useTracking from 'modules/dougall/tracking/useTracking'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { FileQuestion } from 'static/icons'

const cx = classNames.bind(styles)

const Faq = ({ nightMode = false, openCallback, isOpen }) => {
  const isFetching = useSelector(getFetching())
  const faqs = useSelector(getFaqs())
  const { t } = useTranslation()
  // tracking on view of answer
  const { trackClickAction } = useTracking()
  return (
    <div>
      <PromptList
        id="faq"
        title={t('faq')}
        icon={nightMode ? FileQuestion : null}
        items={faqs}
        customChildrenRender={item => (
          <Typography
            className={cx(styles.answer, nightMode && styles.whiteText)}
          >
            <div
              dangerouslySetInnerHTML={{ __html: item.caption }}
              style={{ whiteSpace: 'pre-line' }}
            />
          </Typography>
        )}
        onExpandClick={e =>
          trackClickAction({
            title: `Site/Click/FAQ/${e}/expand`,
            clickType: 'faq-item-expand'
          })
        }
        onExpandItemAttr="trackingId"
        isFetching={isFetching}
        collapsibleContent={nightMode}
        nightMode={nightMode}
        wrapText
        manualOpenCallback={openCallback}
        isOpen={isOpen}
        containerClassName={styles.promptContainer}
        dividers={false}
      />
      {!isFetching && faqs && faqs.length === 0 && (
        <Typography
          className={cx(nightMode && styles.whiteText)}
          align="center"
        >
          {t('noFrequentlyAskedQuestionsFound')}
        </Typography>
      )}
    </div>
  )
}

Faq.propTypes = {
  /**
   * Styles for dark or light background
   */
  nightMode: PropTypes.bool,
  /**
   * Callback when manually controlled opening
   */
  openCallback: PropTypes.func,
  /**
   * Variable for manual opening
   */
  isOpen: PropTypes.bool
}

export default Faq
