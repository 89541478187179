import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as labAction } from 'modules/lab/LabReducer'
import { limaLabUrl } from '../../constants'

/**
 * Request token for Lima Lab & redirect
 *
 * @param services
 * @param payload
 */
function* loadLabRequest(services, { payload }) {
  const SmartsService = services('SmartsService')
  const CookieService = services('CookieService')
  try {
    const { role } = payload
    const uuid = yield call([CookieService, 'getFromCache'], 'uuid', '')
    const {
      userInfo: { email }
    } = yield call([CookieService, 'getFromCache'], 'state', '')

    const { access } = yield call(
      [SmartsService, 'redirectLab'],
      email,
      role,
      uuid
    )
    window.location.href = `${limaLabUrl}?lp=${access}`
    yield put(labAction.labLoadSuccessRequest())
  } catch (e) {
    console.error(e)
    yield put(
      labAction.labLoadFailRequest({
        error: 'We had an issue processing your request'
      })
    )
    yield put(
      notificationActions.notificationEnqueue({
        message: e?.message || 'We had an issue processing your request',
        duration: 0
      })
    )
  }
}

export default function* watchSiteConfigTransaction(services) {
  yield all([takeEvery('LAB_LOAD_REQUEST', loadLabRequest, services)])
}
