import { useRef } from 'react'
import PropTypes from 'prop-types'
// import classNames from 'classnames/bind'
import Typography from '../ui/Typography'
import { useField, useFormikContext } from 'formik'
import styles from './FileInput.module.sass'
import Input from 'components/ui/Input'
// import Button from 'components/Button'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

// const cx = classNames.bind(styles)
/**
 * PSL UI Text input integration with formik
 * @param traditional
 * @param type
 * @param props
 * @returns {*}
 * @constructor
 */
const FileInput = ({ isLoading, traditional = true, type, ...props }) => {
  const { id, multiple = false, caption } = props
  const inputRef = useRef(null)
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField({ ...props })

  const inputValue = [...field.value].map(res => res.name).join(', ')

  const handleClick = e => {
    e.preventDefault()
    inputRef.current.click()
  }

  return (
    <div
      className={cx(
        props.className,
        styles.container,
        props.disabled && styles.disabled
      )}
      data-testid="file-input"
    >
      {traditional && (
        <label className={[styles.uploadArea]} htmlFor={id || 'file-input'}>
          {props.label || `File${props.required ? ' *' : ''}`}
        </label>
      )}
      <div className={cx(traditional && styles.buttonContainer)}>
        <input
          data-testid="fileinput-hidden"
          ref={inputRef}
          className={styles.hide}
          id={id || 'file-input'}
          name={props.name}
          type="file"
          disabled={props.disabled}
          multiple={multiple}
          accept={props.accept || 'image/*'}
          onChange={event => {
            setFieldValue(props.name, event.currentTarget.files)
          }}
        />
        {/* <Button
          disabled={isLoading}
          hasSpinner
          backgroundColor="unset"
          onClick={handleClick}
          type="button"
        >
          Choose File
        </Button> */}
        {traditional ? (
          <span
            onClick={handleClick}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') handleClick()
            }}
            className={styles.input}
            data-testid="file-input-span"
          >
            {inputValue || 'Select File'}
          </span>
        ) : (
          <label className={[styles.uploadArea]} htmlFor={id || 'file-input'}>
            <Input
              id={id}
              data-testid="fileInput-modern"
              className={cx(
                styles.textInput,
                inputValue && styles.hasValue,
                props.disabled && styles.disabled
              )}
              type="text"
              label={props.label}
              value={inputValue}
              defaultValue=""
              readOnly
              required={props.required}
              disabled={props.disabled}
            />
          </label>
        )}
      </div>
      {caption && (
        <Typography
          type="caption"
          color="text-primary"
          style={{ fontSize: '0.85em', lineHeight: '1.4em' }}
        >
          {caption}
        </Typography>
      )}
      {meta.touched && meta.error && (
        <Typography
          data-testid="errorCaption"
          type="caption"
          color="error"
          style={{ fontSize: '12px', position: 'absolute' }}
        >
          {meta.error}
        </Typography>
      )}
      {/* <label className={[styles.uploadArea]} htmlFor={id || 'file-input'}>
        <Input
          id={id}
          className={cx(styles.textInput, inputValue && styles.hasValue)}
          type="text"
          label={props.label}
          error={meta.touched && meta.error ? meta.error : null}
          value={inputValue}
          defaultValue=""
          readOnly
          required={props.required}
        />
      </label> */}
    </div>
  )
}
FileInput.propTypes = {
  /**
   * Input label
   */
  label: PropTypes.string,
  /**
   * Input Name
   */
  name: PropTypes.string.isRequired,
  /**
   * Input ID
   */
  id: PropTypes.string.isRequired,
  /**
   * Input type
   */
  accept: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  /**
   * flag for traditional render
   */
  traditional: PropTypes.bool,
  type: PropTypes.string,
  multiple: PropTypes.bool,
  caption: PropTypes.string,
  className: PropTypes.string
}
export default FileInput
