/**
 * Color palette based on NTK: https://app.zeplin.io/project/62829d264af8761547a7c273/styleguide
 * @type {{lightgreyblue: string, purplishgrey: string, darkgreyblue: string, marineblue: string, whiteish: string, black: string, browngrey2: string, greyishbrown: string, browngrey3: string, paleblue: string, error: string, darkslateblue: string, browngrey: string, windowsblue: string, verylightpink50: string, brownishgrey: string, brownishgrey2: string, blue: string, white: string, darkGrey: string, darkslateblue2: string, buttonFocus: string}}
 */
const palette = {
  black: '#000',
  darkslateblue: '#1f3661',
  darkslateblue2: '#183564',
  marineblue: '#004765',
  honoluluBlue: '#0164b0',
  blue: '#1055cc',
  darkgreyblue: '#2b426a',
  windowsblue: '#319aca',
  lightgreyblue: '#8cb8e4',
  paleblue: '#e5e6e8',
  darkGrey: '#333',
  greyishbrown: '#595959',
  purplishgrey: '#757475',
  brownishgrey: '#5a5a5a',
  brownishgrey2: '#666',
  browngrey: '#979797',
  browngrey2: '#9b9b9b',
  browngrey3: '#a0a0a0',
  verylightpink50: 'rgba(239, 239, 239, 0.5)',
  whiteish: '#f4f4f4',
  white: '#fff',
  error: '#be1e2d',
  buttonFocus: '#015ea6',
  mdRed: '#cc0202',
  contrastText: '#fff',
  greyishblue: '#B9B9BB',
  gray: '#b9b9bb',
  celadonBlue: '#0778AD',
  lightGrey: '#ebebeb',
  menuTextHover: '#0164b0'
}

/**
 * Font family overrides for MD
 * https://app.zeplin.io/project/62829d264af8761547a7c273/styleguide/textstyles
 * @type {{fontTertiary: string, fontSecondary: string, fontPrimary: string}}
 */
const fontFamily = {
  fontPrimary: ['"Helvetica"', 'Arial', 'sans-serif'].join(','),
  fontSecondary: ['Imperial', 'Georgia', 'serif'].join(','),
  fontTertiary: 'arial'
}
/**
 * MUI Default theme overrides for MD brand
 * https://mui.com/material-ui/customization/default-theme/
 * @type {{typography: {fontFamily: string}, components: {MuiButton: {styleOverrides: {root: {"&:focus": {boxShadow: string, backgroundColor: string}, "&:hover": {boxShadow: string, backgroundColor: string}, "&:active": {boxShadow: string, backgroundColor: string}}}}}, palette: {primary: {main: string}}}}
 */
const dougallmd = {
  palette: {
    primary: {
      main: palette.honoluluBlue
    },
    error: { main: palette.error },
    header: {
      main: palette.white,
      menuIcon: palette.browngrey3
    },
    menu: {
      main: palette.black,
      topText: palette.skyblue,
      divider: palette.lightGrey,
      selected: palette.lightblue,
      searchInput: palette.whiteGrey,
      searchIcon: palette.brownishgrey2,
      searchText: palette.brownishgrey2,
      background: palette.white,
      textHover: palette.menuTextHover
    },
    filter: {
      backgroundColor: palette.white,
      loadingBackgroundColor: palette.white,
      selectedBackgroundColor: palette.celadonBlue,
      borderColor: palette.greyishbrown,
      loadingBorderColor: palette.greyishblue,
      fontColor: palette.greyishbrown,
      loadingFontColor: palette.gray,
      selectedFontColor: palette.white,
      hoverBackgroundColor: palette.white
    }
  },
  typography: {
    fontFamily: fontFamily.fontPrimary
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          '&:focus': {
            boxShadow:
              '0 2px 4px rgba(0, 0, 0, 0.24), 0 -2px 2px rgba(0, 0, 0, 0.05)',
            backgroundColor: palette.buttonFocus
          },
          '&:hover': {
            boxShadow:
              '0 2px 4px rgba(0, 0, 0, 0.24), 0 -2px 2px rgba(0, 0, 0, 0.05)',
            backgroundColor: palette.buttonFocus
          },
          '&:active': {
            boxShadow:
              '0 2px 4px rgba(0, 0, 0, 0.24), 0 -2px 2px rgba(0, 0, 0, 0.05)',
            backgroundColor: palette.lightgreyblue
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '74.5px',
          alignSelf: 'center',
          boxShadow: 'none',
          backgroundColor: palette.white,
          '@media (max-width: 1023px)': {
            height: '66.5px'
          },
          '@media (max-width: 520px)': {
            paddingLeft: '20px'
          },
          '& .MuiToolbar-root': {
            maxWidth: '520px',
            width: '100%'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: { root: { backgroundColor: palette.white } }
    },
    Menu: {
      styleOverrides: {
        container: {
          [`& .MuiDrawer-paper`]: {
            width: '240px',
            marginTop: '66px',
            boxShadow: '2px 2px 10px 2px hsla(240,1%,73%,.5)!important',
            border: '1px solid #d6d6d6',
            borderBottom: '0',
            borderTop: '0',
            '@media (min-width: 1024px)': {
              marginTop: '74px'
            }
          }
        },
        inputContainer: {
          margin: '20px 0px 0px 20px',
          paddingBottom: '5px'
        },
        searchInput: {
          margin: '0px 20px 0px 0px',
          borderRadius: '3px',
          height: '42px'
        },
        divider: {
          color: palette.lightGrey
        },
        listItem: {
          padding: '8px'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#d6d6d6',
          borderBottomWidth: '1px'
        }
      }
    },
    Footer: {
      styleOverrides: {
        container: {
          marginTop: '17px',
          flexWrap: 'wrap'
        },
        row: {
          display: 'inline-flex',
          margin: '0px 5px 7px'
        }
      }
    }
  }
}
export default dougallmd
