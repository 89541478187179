import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as listIdsActions } from './ListIdsReducer'

/**
 * Request submit form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* tooltipRequest(services, { payload }) {
  const { name, listId, requestBody, setFieldValue } = payload
  try {
    const SmartsService = services('SmartsService')
    const SmartsParser = services('SmartsParser')
    // Call endpoint
    const list = yield call([SmartsService, 'listsGet'], listId)
    const {
      raw: { filters }
    } = list
    // Parse list info
    const parsedTooltip = yield call(
      [SmartsParser, 'listIdTooltipParser'],
      list
    )
    // If ExportLists workflow, update the form values with the fetched combinations
    if (
      requestBody?.data?.filters?.workflow === 'ExportLists' &&
      filters?.combinations?.length
    ) {
      setFieldValue(
        'combinations',
        filters.combinations.map((f, index) => ({ ...f, id: index })) || []
      )
      setFieldValue('targetSpeciality', [])
    } else {
      setFieldValue('combinations', [])
    }
    // Update
    yield put(
      listIdsActions.tooltipRequestSuccess({
        name,
        tip: parsedTooltip,
        filters
      })
    )
  } catch (e) {
    yield put(listIdsActions.tooltipRequestFail({ name }))
    console.error(e)
  }
}

export default function* watchListIdRequest(services) {
  yield all([takeEvery('TOOLTIP_REQUEST', tooltipRequest, services)])
}
