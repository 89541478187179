import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as usersBulkCreate } from './UsersBulkReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'

/**
 * Request upload users csv file
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitFileRequest(services, { payload }) {
  const AdminService = services('AdminService')
  try {
    const { file } = payload
    let formData = new FormData()
    formData.append('file', file[0])
    const {
      responseData: { processed, unprocessed }
    } = yield call([AdminService, 'uploadUsersBulkFile'], formData)

    // Build unprocessed error
    const unprocessedError = unprocessed.numRecords
      ? `\n${unprocessed.numRecords} record${
          unprocessed.numRecords > 1 ? 's' : ''
        } were rejected, refer browser Console Log for more details...`
      : ''
    if (unprocessed.numRecords) {
      console.log('Unprocessed Record:', unprocessed.numRecords)
      unprocessed.records.map(m => console.log(m))
    }
    yield put(
      notificationActions.notificationEnqueue({
        message: `${processed.numRecords} profile${
          processed.numRecords > 1 ? 's' : ''
        } were created. ${unprocessedError}`,
        duration: 10000
      })
    )
    yield put(usersBulkCreate.uploadRequestSuccess())
  } catch (e) {
    yield put(usersBulkCreate.uploadRequestFail())
    console.error(e)
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
  }
}

/**
 * Request download users csv file
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* downloadFileRequest(services) {
  const AdminService = services('AdminService')
  try {
    yield call([AdminService, 'downloadUsersBulkFile'])
    yield put(usersBulkCreate.downloadRequestSuccess())
  } catch (e) {
    yield put(usersBulkCreate.downloadRequestFail())
    console.error(e)
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
  }
}

export default function* watchUsersBulkRequest(services) {
  yield all([
    takeEvery('USERSBULK_UPLOAD_REQUEST', submitFileRequest, services)
  ])
  yield all([
    takeEvery('USERSBULK_DOWNLOAD_REQUEST', downloadFileRequest, services)
  ])
}
