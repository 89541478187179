/**
 * Constants used by the TrackingService.
 */

// For page custom variables
const customVarTotal = 6

// For session custom variables
const piwikCustomVars = [
  'MemberID',
  'EmailID',
  'SpecialtyID',
  'country',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  'site_uid',
  'psl_device_id',
  'interactionId',
  '',
  ''
]

// For session custom variables
const secondTrackerCustomVars = [
  'MemberID',
  'EmailID',
  'SpecialtyID',
  'CountryID',
  'qsession',
  'ProfessionID',
  'AOMID',
  'AudienceID',
  'PSLDeviceID'
]

export { piwikCustomVars, customVarTotal, secondTrackerCustomVars }
