import { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import JSZip from 'jszip'
import BarChart from 'components/BarChart'
import { CircularProgress } from 'components/ui/Progress'
import { Button } from '@material-ui/core'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import styles from './form.module.sass'
import downloadImg from 'images/download.png'
import useIsInViewport from './useIsInViewPort'
import classNames from 'classnames/bind'
import { getChartsObject } from '../ChartsSelector'
import { isEmpty } from 'utils'

const cx = classNames.bind(styles)

// This module gets the data passed down, cycles through it and gives each BarChart component its data to display something
const ChartGeneration = ({
  formProps,
  handleDownload,
  chartsData,
  showDownloadZip,
  ...rest
}) => {
  const chartsObject = useSelector(getChartsObject())
  const { activeStep, showDownloadForm } = formProps.values
  const compareLists =
    activeStep === 'download-form' ||
    activeStep === 'specialty-country' ||
    activeStep === 'lists-selection'

  const { affinity, demographics, digital, geographics, influence, practice } =
    chartsData

  const [chartsLoaded, setChartsLoaded] = useState(false)

  const demographicsRef = useRef(null)
  const affinityRef = useRef(null)
  const digitalRef = useRef(null)
  const geographicsRef = useRef(null)
  const influenceRef = useRef(null)
  const practiceRef = useRef(null)

  const demographicsActive = useIsInViewport(demographicsRef)
  const affinityActive = useIsInViewport(affinityRef, 1)
  const digitalActive = useIsInViewport(digitalRef)
  const geographicsActive = useIsInViewport(geographicsRef)
  const influenceActive = useIsInViewport(influenceRef)
  const practiceActive = useIsInViewport(practiceRef)

  const getActiveTab = () => {
    // Return just one active tab
    if (affinityActive) {
      return 'affinity'
    } else if (demographicsActive) {
      return 'demographics'
    } else if (geographicsActive) {
      return 'geographics'
    } else if (practiceActive) {
      return 'practice'
    } else if (influenceActive) {
      return 'influence'
    } else if (digitalActive) {
      return 'digital'
    }
  }

  useEffect(() => {
    if (!isEmpty(chartsObject) && !chartsLoaded) {
      setChartsLoaded(true)
    }
  }, [chartsObject, chartsLoaded])

  const handleScroll = ref => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const handleZipDownload = () => {
    const { listId = null, uuid = null } = formProps.values
    const zip = new JSZip()
    Object.keys(chartsObject).forEach(chart => {
      const base64Img = chartsObject[chart].base64Img.replace(
        'data:image/png;base64,',
        ''
      )
      zip.file(`${chartsObject[chart].title}.png`, base64Img, {
        base64: true
      })
    })
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      const url = window.URL.createObjectURL(content)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${listId || uuid || 'charts'}.zip`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
  }

  return (
    <>
      {/* Header buttons */}
      <ButtonGroup
        size="large"
        variant="text"
        color="default"
        aria-label="large contained default button group"
        disableElevation
        fullWidth
        className={styles.tabList}
      >
        <Button
          color="default"
          onClick={() => {
            handleScroll(demographicsRef)
          }}
          disabled={demographics.length === 0}
          className={cx({ active: getActiveTab() === 'demographics' })}
        >
          Demographics
        </Button>
        <Button
          color="default"
          onClick={() => {
            handleScroll(geographicsRef)
          }}
          disabled={geographics.length === 0}
          className={cx({ active: getActiveTab() === 'geographics' })}
        >
          Geographics
        </Button>
        <Button
          color="default"
          onClick={() => {
            handleScroll(practiceRef)
          }}
          disabled={practice.length === 0}
          className={cx({ active: getActiveTab() === 'practice' })}
        >
          Practice
        </Button>
        <Button
          color="default"
          onClick={() => {
            handleScroll(influenceRef)
          }}
          disabled={influence.length === 0}
          className={cx({ active: getActiveTab() === 'influence' })}
        >
          Influence
        </Button>
        <Button
          color="default"
          onClick={() => {
            handleScroll(digitalRef)
          }}
          disabled={digital.length === 0}
          className={cx({ active: getActiveTab() === 'digital' })}
        >
          Digital
        </Button>
        <Button
          color="default"
          onClick={() => {
            handleScroll(affinityRef)
          }}
          disabled={affinity.length === 0}
          className={cx({ active: getActiveTab() === 'affinity' })}
        >
          Affinity
        </Button>
      </ButtonGroup>
      {activeStep === 'download-form' && !showDownloadForm && chartsLoaded && (
        <div className={styles.download}>
          <button onClick={() => handleDownload()}>
            <img src={downloadImg} alt="Download Button" />
          </button>
        </div>
      )}
      {showDownloadZip && (
        <div className={styles.download}>
          <button onClick={handleZipDownload}>
            <img src={downloadImg} alt="Download Button" />
          </button>
        </div>
      )}
      {activeStep === 'download-form' || activeStep === 'specialty-country' ? (
        <div
          className={
            chartsLoaded ? styles.compareListsLegend : styles.visibilityHidden
          }
        >
          <div className={styles.firstList}>
            <center>
              <span className={styles.square}></span>
              <span className={styles.text}>Target List</span>
            </center>
          </div>
          <div className={styles.secondList}>
            <center>
              <span className={styles.square}></span>
              <span className={styles.text}>Survey Participants</span>
            </center>
          </div>
        </div>
      ) : null}
      {/* Charts */}
      {!chartsLoaded && (
        <CircularProgress fillColor="transparent" size={64} strokeWidth={4} />
      )}
      <div
        className={
          chartsLoaded ? styles.chartsContainer : styles.chartsContainerDownload
        }
      >
        <div
          id="demographic"
          className={cx(styles.chartSection, {
            hideSection: demographics.length === 0
          })}
          ref={demographicsRef}
        >
          {demographics.map(chart => {
            return (
              <BarChart
                key={chart.name}
                chart={chart}
                chartOnly={compareLists}
                {...rest}
              />
            )
          })}
        </div>
        <div
          id="geographic"
          className={cx(styles.chartSection, {
            hideSection: geographics.length === 0
          })}
          ref={geographicsRef}
        >
          {geographics.map(chart => {
            return (
              <BarChart
                key={chart.name}
                chart={chart}
                chartOnly={compareLists}
                {...rest}
              />
            )
          })}
        </div>
        <div
          id="practice"
          className={cx(styles.chartSection, {
            hideSection: practice.length === 0
          })}
          ref={practiceRef}
        >
          {practice.map(chart => {
            return (
              <BarChart
                key={chart.name}
                chart={chart}
                chartOnly={compareLists}
                {...rest}
              />
            )
          })}
        </div>
        <div
          id="influence"
          className={cx(styles.chartSection, {
            hideSection: influence.length === 0
          })}
          ref={influenceRef}
        >
          {influence.map(chart => {
            return (
              <BarChart
                key={chart.name}
                chart={chart}
                chartOnly={compareLists}
                {...rest}
              />
            )
          })}
        </div>
        <div
          id="digital"
          className={cx(styles.chartSection, {
            hideSection: digital.length === 0
          })}
          ref={digitalRef}
        >
          {digital.map(chart => {
            return (
              <BarChart
                key={chart.name}
                chart={chart}
                chartOnly={compareLists}
                {...rest}
              />
            )
          })}
        </div>
        <div
          id="affinity"
          className={cx(styles.chartSection, {
            hideSection: affinity.length === 0
          })}
          ref={affinityRef}
        >
          {affinity.map(chart => {
            return (
              <BarChart
                key={chart.name}
                chart={chart}
                chartOnly={compareLists}
                {...rest}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

ChartGeneration.propTypes = {
  /**
   * responseData from the /v1/smarts/list/charts endpoint
   */
  chartsData: PropTypes.object,
  formProps: PropTypes.object,
  /**
   * Function to handle the reset button for when this module loads
   * inside Compare Lists
   */
  handleDownload: PropTypes.func,
  /**
   * If true the download button will show and user will be able to download all charts
   */
  showDownloadZip: PropTypes.bool
}

export default ChartGeneration
