import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { useHistory } from 'react-router-dom'
import Card from 'components/Admin/Card/Card'
import PageContent from 'components/Admin/Page/PageContent'
import Typography from 'components/ui/Typography'
import TextInput from 'components/TextInput'
import Typeahead from 'modules/typeahead'
import Button from 'components/Admin/Button'
import Icon from 'components/ui/Icon'
import { CircularProgress } from 'components/ui/Progress'
import * as thresholdSelectors from './ThresholdSelector'
import * as thresholdActions from './ThresholdReducer'
import { smartsEndpoints } from 'constants/apiConstants'
import styles from './Threshold.module.sass'

const { getSingleThreshold, getThresholdFetching } = thresholdSelectors

const SingleThreshold = () => {
  const dispatch = useDispatch()
  const routerHistory = useHistory()
  const [clearInput, setClearInput] = useState(false)
  const [creating, setCreating] = useState(false)
  const { location } = routerHistory
  const currentThreshold = useSelector(getSingleThreshold())
  const initialForm = {
    id: '',
    country: '',
    profession: null,
    specialty: null,
    value: 0
  }
  const isFetching = useSelector(getThresholdFetching())
  const urlId = location.pathname.split('/')[3]
  const formStatePr = urlId === 'create' ? initialForm : currentThreshold

  useEffect(() => {
    // eslint-disable-next-line
    if (urlId !== 'create' && urlId != currentThreshold.id) {
      const id = parseInt(urlId)
      dispatch(thresholdActions.fetchThresholdRequest({ id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (urlId === 'create' && creating) {
      routerHistory.push(`/admin/threshold/${currentThreshold.id}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentThreshold])

  const handleBack = () => {
    setCreating(false)
    dispatch(thresholdActions.fetchThresholdRequestSuccess(initialForm))
    routerHistory.push('/admin/threshold')
  }

  const handleSubmit = values => {
    dispatch(thresholdActions.updateThresholdRequest(values))
    if (urlId === 'create') {
      setCreating(true)
    }
  }

  const handleReset = () => {
    setClearInput(!clearInput)
  }

  return (
    <PageContent>
      {isFetching && urlId !== 'create' ? (
        <CircularProgress fillColor="transparent" size={64} strokeWidth={4} />
      ) : (
        <Formik
          key={clearInput}
          enableReinitialize
          initialValues={formStatePr}
          onSubmit={handleSubmit}
        >
          <Form autoComplete="off">
            <Typography
              type="title"
              color="text-primary"
              className={styles.thresholdId}
            >
              {urlId === 'create' ? 'Add Threshold' : 'Edit Threshold'}
            </Typography>
            <Card className={styles.card}>
              <div className={styles.headingContainer}>
                <Icon
                  icon="svg/custom/arrow-back"
                  className={styles.backIcon}
                  onClick={() => handleBack()}
                />
                <div className={styles.headerDisplayInfo}>
                  <Typography
                    type="title"
                    color="text-primary"
                    className={styles.cardTitle}
                  >
                    Back
                  </Typography>
                </div>
              </div>
              {urlId === 'create' && (
                <Typography className={styles.createWarning}>
                  ID is disabled to create a new Threshold
                </Typography>
              )}
              <div className={styles.singleThresholdInputs}>
                <TextInput
                  label="ID"
                  name="id"
                  type="number"
                  disabled
                  traditional={false}
                  readOnly={false}
                  className={styles.disabled}
                  disableNegativeValue
                  disableExponential
                />
                <Typeahead
                  // formName="admin-create-user"
                  label="Country"
                  name="country"
                  type="text"
                  traditional={false}
                  required
                  body={{
                    data: {
                      search: {
                        perPage: 100
                      }
                    },
                    endpoint: smartsEndpoints.variationCountries,
                    parseFunction: 'optionsParser'
                  }}
                />
                <TextInput
                  label="Value"
                  name="value"
                  type="number"
                  required
                  traditional={false}
                  readOnly={false}
                  disableNegativeValue
                  disableExponential
                />
              </div>
              <div className={styles.singleThresholdButtons}>
                <Button
                  className={styles.resetButton}
                  text="Reset"
                  textCase="none"
                  type="reset"
                  backgroundColor="#d3d3d3"
                  onClick={() => handleReset()}
                />
                <Button
                  text={urlId === 'create' ? 'Create' : 'Update'}
                  textCase="none"
                  type="submit"
                />
              </div>
            </Card>
          </Form>
        </Formik>
      )}
    </PageContent>
  )
}

export default SingleThreshold
