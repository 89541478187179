import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as historyActions } from './UniverseHistoryReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import history from '../../../history'

function* requestUniverseHistory(services, { payload }) {
  const AdminService = services('AdminService')

  try {
    const { data = [], pagination = {} } = yield call(
      [AdminService, 'requestUniverseHistory'],
      payload
    )

    yield put(historyActions.completeRequestHistory({ data, pagination }))
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to fetch Universe History',
        duration: 0
      })
    )
  }
}

function* universeHistoryDelete(services, { payload: { id, formState } }) {
  const AdminService = services('AdminService')

  let message = 'Unable to delete Universe History element'

  try {
    const { count = 0 } = yield call(
      [AdminService, 'deleteUniverseHistory'],
      id
    )

    yield put(historyActions.completeHistoryDelete())

    if (count === 1) {
      message = 'Universe History element deleted'
    }

    yield put(
      notificationActions.notificationEnqueue({
        message,
        duration: 0
      })
    )

    const { data = [], pagination = {} } = yield call(
      [AdminService, 'requestUniverseHistory'],
      formState
    )

    yield put(historyActions.completeRequestHistory({ data, pagination }))
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message,
        duration: 0
      })
    )
  }
}

function* requestSingleUniverse(services, { payload }) {
  const AdminService = services('AdminService')

  try {
    const response = yield call(
      [AdminService, 'getSingleUniverseHistory'],
      payload
    )

    yield put(historyActions.setSingleUniverse(response))
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to fetch single universe history.',
        duration: 0
      })
    )
  }
}

function* requestRestoreUniverse(services, { payload }) {
  const AdminService = services('AdminService')

  try {
    const response = yield call(
      [AdminService, 'restoreUniverseHistory'],
      payload
    )

    if (response) {
      const id = response.history
      yield call([history, history.replace], {
        pathname: `/admin/universe-history/${id}`
      })

      const singleUniverse = yield call(
        [AdminService, 'getSingleUniverseHistory'],
        id
      )
      yield put(historyActions.setSingleUniverse(singleUniverse))
    } else {
      yield put(
        notificationActions.notificationEnqueue({
          message: 'Unable to retrieve new history.',
          duration: 0
        })
      )
    }
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to restore universe note.',
        duration: 0
      })
    )
  }
}

export default function* watchUniverseHistory(services) {
  yield all([
    takeEvery('UNIVERSE_HISTORY_REQUEST', requestUniverseHistory, services),
    takeEvery('UNIVERSE_HISTORY_DELETE', universeHistoryDelete, services),
    takeEvery(
      'UNIVERSE_HISTORY_REQUEST_SINGLE',
      requestSingleUniverse,
      services
    ),
    takeEvery('UNIVERSE_HISTORY_RESTORE', requestRestoreUniverse, services)
  ])
}
