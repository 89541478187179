import { takeEvery, all, put, call, select } from 'redux-saga/effects'
import { actions as fullProfileActions } from './FullProfileViewsReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { isEmpty } from 'utils'
import * as authSelectors from 'modules/auth/AuthSelector'

const { getFeaturesTable } = authSelectors
/**
 * Keeps count of Full profile views and prevent unauthorized access
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* fullProfileViewsRequest(services, { payload }) {
  const CookieService = services('CookieService')
  try {
    const fullRequest = payload.fullRequest
    if (fullRequest) {
      const countExpireTime = Date.now() / 1000 + 86400 // 24 hours from now (in seconds)
      const profileCountSession = yield call(
        [CookieService, 'getFromCache'],
        'fullProfileViews'
      )
      const { 'lima-bureau-profile': roleSchema } = yield select(
        getFeaturesTable()
      )

      const allowedViews = roleSchema.profileViewsAllowed

      // If allowedViews equals false, user has no restrictions (yes, we need to make a strict comparison with false)
      if (allowedViews === false) {
        // GRANT ACCESS
        yield call(
          [CookieService, 'saveToCache'],
          { fullProfileViews: { access: true, count: 0 } },
          false,
          countExpireTime
        )
        yield put(
          fullProfileActions.fullProfileViewsSuccess({ access: true, count: 0 })
        )
      } else if (isEmpty(profileCountSession)) {
        // IF CACHE EMPTY, BEGIN COUNT PROCESS
        yield call(
          [CookieService, 'saveToCache'],
          { fullProfileViews: { access: true, count: 1 } },
          false,
          countExpireTime
        )
        yield put(
          fullProfileActions.fullProfileViewsSuccess({ access: true, count: 1 })
        )
      } else {
        // IF CACHE, ADD 1 TO PROFILE COUNT AND VERIFY LIMIT
        const CacheCount = profileCountSession.count + 1
        // IF LIMIT OK, GRANT ACCESS
        if (allowedViews >= CacheCount) {
          yield put(
            fullProfileActions.fullProfileViewsSuccess({
              access: true,
              count: CacheCount
            })
          )
          yield call(
            [CookieService, 'saveToCache'],
            {
              fullProfileViews: { access: true, count: CacheCount }
            },
            false
          )
        } else {
          // IF LIMIT EXCEEDED, DENY ACCESS
          yield call(
            [CookieService, 'saveToCache'],
            {
              fullProfileViews: { access: false, count: CacheCount }
            },
            false
          )
          yield put(
            fullProfileActions.fullProfileViewsSuccess({
              access: false,
              count: CacheCount
            })
          )
        }
      }
    } else {
      const profileCountSession = yield call(
        [CookieService, 'getFromCache'],
        'fullProfileViews'
      )
      const accessTag = isEmpty(profileCountSession?.access)
        ? true
        : profileCountSession?.access
      const count = isEmpty(profileCountSession?.count)
        ? 0
        : profileCountSession.count
      yield put(
        fullProfileActions.fullProfileViewsSuccess({
          access: accessTag,
          count: count
        })
      )
    }
  } catch (e) {
    console.error(e)
    yield put(fullProfileActions.fullProfileViewsFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Error retrieving profile',
        duration: 0
      })
    )
  }
}

/**
 * @param services
 * @returns {Generator<<"ALL", <"FORK", ForkEffectDescriptor>>, void, *>}
 */
export default function* watchFullProfileViewsRequest(services) {
  yield all([
    takeEvery('FULL_PROFILE_VIEWS_REQUEST', fullProfileViewsRequest, services)
  ])
}
