import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './form.module.sass'
import Button from 'components/Admin/Button'
import ConfirmationModal from 'components/Admin/ConfirmationModal'
import Tooltip from 'components/Tooltip'
import { rebuildRequest, deleteRequest } from '../IncomingCountsReducer'
import * as incomingSelector from '../IncomingCountsSelector'

const { getIsFetching, getRequestType } = incomingSelector

const Actions = () => {
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const [action, setAction] = useState('')
  const isFormFetching = useSelector(getIsFetching())
  const requestType = useSelector(getRequestType())

  const handleModalOpen = action => {
    setModalOpen(true)
    setAction(action)
  }

  const handleModalClose = () => {
    setModalOpen(false)
    setAction('')
  }

  const handleSubmit = () => {
    if (action === 'rebuild') {
      dispatch(rebuildRequest())
    } else if (action === 'delete') {
      dispatch(deleteRequest())
    }
    setModalOpen(false)
  }

  return (
    <div className={styles.actionContainer}>
      <div>
        <Button
          className={styles.actionButton}
          text="Rebuild"
          textCase="none"
          disabled={isFormFetching}
          submitting={isFormFetching && requestType === 'rebuild'}
          type="button"
          onClick={() => handleModalOpen('rebuild')}
          hasSpinner
        />
        <Tooltip
          tip="Flush records, then pull latest from RB19 /v1/reference/table/dwprofilecountsallcountries"
          multiline={false}
          iconProps={{
            icon: 'svg/custom/help-outline',
            iconSize: 20
          }}
        />
      </div>
      <div>
        <Button
          className={styles.actionButton}
          text="Flush All"
          textCase="none"
          disabled={isFormFetching}
          type="button"
          onClick={() => handleModalOpen('delete')}
          submitting={isFormFetching && requestType === 'delete'}
          hasSpinner
          isDelete
        />
        <Tooltip
          tip="Remove all local incoming-record records; without them Chart might not working correctly (disabling charts)."
          multiline={false}
          iconProps={{
            icon: 'svg/custom/help-outline',
            iconSize: 20
          }}
        />
      </div>
      <ConfirmationModal
        message={
          action === 'delete'
            ? 'Are you sure to flush all the incoming counts records?'
            : 'Are you sure to rebuild the incoming counts records?'
        }
        open={modalOpen}
        onConfirm={handleSubmit}
        onCancel={handleModalClose}
        actionType={action === 'delete' ? 'delete' : 'submit'}
        actionCaption={action === 'delete' ? 'Flush All' : 'Rebuild'}
      />
    </div>
  )
}

export default Actions
