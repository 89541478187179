import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { isEmpty } from 'utils'
import PageContent from 'components/Admin/Page/PageContent'
import Card from 'components/Admin/Card/Card'
import Typography from 'components/ui/Typography'
import Button from 'components/Admin/Button'
import { Button as MuiButton, Tooltip } from '@material-ui/core'
import DropdownInput from 'components/DropdownInput'
import TextInput from 'components/TextInput'
import { CircularProgress } from 'components/ui/Progress'
import useRoleFeatures from 'modules/auth/useRoleFeatures'
import DataTable from 'react-data-table-component'

import {
  getIsFetching,
  getData,
  getPagination,
  getFormState,
  getRecordName,
  getRecordJSON,
  getIsDownloadReady
} from './DataCachesSelector'
import { actions as dataCachesActions } from './DataCachesReducer'

import styles from './DataCaches.module.sass'

const DataCaches = () => {
  const dispatch = useDispatch()
  const [clearInput, setClearInput] = useState(false)
  const [firstVisit, setFirstVisit] = useState(true)

  const initialValues = {
    id: '',
    functional: null,
    perPage: 20
  }
  const functionalOptions = {
    null: 'Any',
    true: 'Functional',
    false: 'Individual'
  }
  const { features } = useRoleFeatures({
    coin: 'charts-configuration'
  })
  const isFetching = useSelector(getIsFetching())
  const data = useSelector(getData())
  const pagination = useSelector(getPagination())
  const formState = useSelector(getFormState())
  const recordJSON = useSelector(getRecordJSON())
  const recordName = useSelector(getRecordName())
  const download = useSelector(getIsDownloadReady())
  const roleSchema = features?.sections?.actions || []
  const userCanEdit = roleSchema.includes('save')

  const formStatePr = isEmpty(formState) ? initialValues : formState

  useEffect(() => {
    if (firstVisit) {
      setFirstVisit(false)
      dispatch(dataCachesActions.requestDataCaches({ ...initialValues }))
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (download) {
      if (recordJSON) {
        const blob = new Blob([JSON.stringify(recordJSON, null, 2)], {
          type: 'application/json'
        })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', recordName)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      }
      dispatch(dataCachesActions.downloadDataCachesRequestDone(false))
    }
    // eslint-disable-next-line
  }, [download])

  const handleSubmit = values => {
    dispatch(dataCachesActions.setFormState(values))
    dispatch(dataCachesActions.requestDataCaches(values))
  }
  const handleReset = formProps => {
    formProps.resetForm()
    setClearInput(!clearInput)
    dispatch(dataCachesActions.setFormState(initialValues))
    dispatch(dataCachesActions.requestDataCaches(initialValues))
  }

  const handleDownload = (id, type) => {
    dispatch(dataCachesActions.downloadDataCachesRequest({ id, type }))
  }

  const handleRowsChange = (perPage, page) => {
    const payload = {
      ...formStatePr,
      page,
      perPage
    }
    dispatch(dataCachesActions.setFormState(payload))
    dispatch(dataCachesActions.requestDataCaches(payload))
  }

  const handlePageChange = page => {
    const payload = {
      ...formStatePr,
      page
    }
    dispatch(dataCachesActions.setFormState(payload))
    dispatch(dataCachesActions.requestDataCaches(payload))
  }

  const handleSort = ({ sortField: selector }, sortDirection) => {
    const payload = {
      ...formStatePr,
      orderBy: selector,
      order: sortDirection
    }
    dispatch(dataCachesActions.setFormState(payload))
    dispatch(dataCachesActions.requestDataCaches(payload))
  }

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortField: 'id',
      sortable: true,
      compact: true,
      cell: row => {
        return (
          <div className={styles.noteCell}>
            <code>{row.id}</code>
          </div>
        )
      }
    },
    {
      name: 'Functional / Override',
      selector: row => row.functional,
      sortable: false,
      center: true,
      compact: true,
      cell: row => {
        return (
          <div className={styles.noteCell}>
            <Typography>
              {row.functional ? 'Yes' : 'No'} / {row.override ? 'Yes' : 'No'}
            </Typography>
          </div>
        )
      }
    },

    {
      name: 'Handler / Data Raw / Options',
      selector: row => row.handlers,
      sortable: false,
      center: true,
      compact: true,
      cell: row => {
        return (
          <div className={styles.noteCell}>
            <Typography>
              {row.handlers.source ? row.handlers.source : 0} /{' '}
              {row.data.raw ? row.data.raw : 0} /{' '}
              {row.data.options ? row.data.options : 0}
            </Typography>
          </div>
        )
      }
    },
    {
      name: 'Expiry (Hours)',
      selector: row => row.expiryInHour,
      sortable: false,
      center: true,
      compact: true,
      maxWidth: '20px',
      cell: row => {
        return (
          <div className={styles.noteCell}>
            <Typography>{row.expiryInHour ? row.expiryInHour : ''}</Typography>
          </div>
        )
      }
    },
    {
      name: 'Download',
      center: true,
      compact: true,
      sortable: false,
      cell: row => {
        return (
          <div className={styles.tableButtons}>
            <Tooltip title="Handler & Compiled Data into JSON" placement="top">
              <MuiButton
                color="primary"
                size="small"
                onClick={() => handleDownload(row.id, 'record')}
              >
                Record
              </MuiButton>
            </Tooltip>
            <Tooltip title="Compiled Data into JSON" placement="top">
              <MuiButton
                color="primary"
                size="small"
                onClick={() => handleDownload(row.id, 'options')}
              >
                Options
              </MuiButton>
            </Tooltip>
          </div>
        )
      }
    },
    {
      name: 'Created / Updated',
      selector: row => row.createdAt,
      center: true,
      sortable: false,
      compact: true,
      wrap: true,
      cell: row => {
        return (
          <div className={styles.tableWrap}>
            <div className={styles.tableTypography}>
              {row.createdAt ? row.createdAt : ''}
            </div>
            <div className={styles.tableTypography}>
              {row.updatedAt ? row.updatedAt : ''}
            </div>
          </div>
        )
      }
    },
    {
      name: 'Clear / Rebuild',
      center: true,
      sortable: false,
      compact: true,
      omit: !userCanEdit,
      cell: row => {
        return (
          <div className={styles.tableButtons}>
            <Tooltip title="Clear DC Compiled Data" placement="top">
              <MuiButton
                color="secondary"
                size="small"
                onClick={() =>
                  dispatch(
                    dataCachesActions.clearDataCachesRecordRequest({
                      formStatePr,
                      id: row.id
                    })
                  )
                }
              >
                Clear
              </MuiButton>
            </Tooltip>
            <Tooltip
              title="Clear DC Compiled Data, then collect new dataset"
              placement="top"
            >
              <MuiButton
                color="secondary"
                size="small"
                onClick={() =>
                  dispatch(
                    dataCachesActions.rebuildDataCachesRecordRequest({
                      formStatePr,
                      id: row.id
                    })
                  )
                }
              >
                Rebuild
              </MuiButton>
            </Tooltip>
          </div>
        )
      }
    }
  ]

  return (
    <PageContent title="Data Caches">
      <Formik
        key={clearInput}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {formProps => (
          <Form autoComplete="off">
            <div className={styles.description}>
              LiMA is highly dependent on several dataset provided by either 3rd
              party or static list provided by Business Unit.
              <br />
              Data Cache (DC) mechanism is self-reliance, where it automatically
              collect data from 3rd party on given time expiration.
              <br />
              <a
                href="https://jira-pslgroup.atlassian.net/wiki/spaces/LSD/pages/187727878/Data+Caches+DC"
                target="_blank"
                rel="noreferrer"
              >
                Read more here.
              </a>
              <br />
              <br />
              Glossary:
              <ul>
                <li>
                  <strong>Functional DC:</strong> DC made for working
                  mechanisms.
                </li>
                <li>
                  <strong>Individual DC:</strong>(non functional): DC made for
                  specific users.
                </li>
                <li>
                  <strong>Handlers:</strong> a set of instructions.
                </li>
                <li>
                  <strong>Handler:</strong> an instructions & its configuration
                  for the DC process, from sources, collection, sanitization,
                  and presentation.
                </li>
                <li>
                  <strong>Compiled Data:</strong> collected & sanitized dataset.
                </li>
              </ul>
            </div>
            {userCanEdit && (
              <div className={styles.actionContainer}>
                <Button text="Add" textCase="none" disabled type="button" />
                <Button
                  text="Clear Functional DC"
                  textCase="none"
                  disabled={isFetching}
                  type="button"
                  backgroundColor="#cb4e4e"
                  onClick={() => {
                    dispatch(
                      dataCachesActions.clearFunctionalDataCachesRequest({
                        ...formStatePr
                      })
                    )
                  }}
                />
                <Button
                  text="Clear Individual DC"
                  textCase="none"
                  disabled={isFetching}
                  type="button"
                  backgroundColor="#cb4e4e"
                  onClick={() => {
                    dispatch(
                      dataCachesActions.clearIndividualDataCachesRequest({
                        ...formStatePr
                      })
                    )
                  }}
                />
                <Button
                  text="Rebuild all DC"
                  textCase="none"
                  disabled={isFetching}
                  type="button"
                  backgroundColor="#cb4e4e"
                  onClick={() => {
                    dispatch(
                      dataCachesActions.rebuildDataCachesRequest({
                        ...formStatePr
                      })
                    )
                  }}
                />
              </div>
            )}

            <Card className={styles.card}>
              <Typography type="title" color="text-primary">
                Search Criteria
              </Typography>
              <div className={styles.searchForm}>
                <TextInput
                  label="ID"
                  name="id"
                  type="text"
                  traditional={false}
                />
                <DropdownInput
                  name="functional"
                  label="Type"
                  placeholder="Choose a Type"
                  traditional={false}
                  options={functionalOptions}
                />
                <div className={styles.searchButtons}>
                  <Button
                    className={styles.resetButton}
                    text="Reset"
                    textCase="none"
                    disabled={false}
                    type="button"
                    backgroundColor="#d3d3d3"
                    onClick={() => handleReset(formProps)}
                  />
                  <Button text="Search" textCase="none" type="submit" />
                </div>
              </div>
            </Card>
            <Card className={styles.card}>
              <Typography type="title" color="text-primary">
                Search Results
              </Typography>

              <DataTable
                columns={columns}
                data={data}
                keyField="key"
                progressPending={isFetching}
                pagination
                paginationServer
                paginationTotalRows={pagination.filtered}
                onChangeRowsPerPage={handleRowsChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                sortServer
                persistTableHead
                paginationPerPage={pagination.perPage}
                paginationRowsPerPageOptions={[10, 20, 25]}
                progressComponent={
                  <CircularProgress
                    className={styles.circularProgress}
                    fillColor="transparent"
                    size={64}
                    strokeWidth={4}
                  />
                }
              />
            </Card>
          </Form>
        )}
      </Formik>
    </PageContent>
  )
}

export default DataCaches
