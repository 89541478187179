import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as homeActions } from './HomeReducer'

/**
 * Retrieve list of available coins
 *
 * @param services
 * @param payload
 */
function* loadCoinsRequest(services) {
  const SmartsService = services('SmartsService')
  try {
    const { data = [] } = yield call([SmartsService, 'listCoins'], {
      orderBy: 'order',
      order: 'asc'
    })
    yield put(homeActions.listCoinsRequestSuccess(data))
  } catch (e) {
    console.error(e)
    yield put(homeActions.listCoinsRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: e?.message || 'We had an issue processing your request',
        duration: 0
      })
    )
  }
}

export default function* watchSiteConfigTransaction(services) {
  yield all([takeEvery('LIST_COINS_REQUEST', loadCoinsRequest, services)])
}
