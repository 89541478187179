import { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import AutoSelect from './AutoSelect'
import uniqueId from 'lodash/uniqueId'
import isEqual from 'lodash/isEqual'
import { propWarning } from '../utils'
import SelectBase from '../SelectBase'

class SelectMenu extends Component {
  state = {
    isFocused: this.props.inputFocus,
    value: this.props.defaultValue || '',
    change: false
  }
  static defaultProps = {
    meta: {},
    input: {},
    loading: false,
    secondaryLoading: false,
    options: {},
    traditional: false,
    required: false,
    error: '',
    showIcon: true,
    showAllSuggestionsOnClick: true,
    disabled: false,
    freeEntry: false,
    passthrough: false,
    sort: true,
    maxLengthHighlight: false
  }
  static displayName = 'SelectMenu'
  static propTypes = {
    /**
     * @deprecated in version 2.7
     *
     * An optional button element to be contained within the component
     * This is deprecated in favor of `rightElements` prop which can contain multiple elements
     */
    button: propWarning(
      'Deprecated prop: This prop will be removed in version 3. Use `rightElements` prop instead.'
    ),
    /**
     * An optional node of elements to be contained within the SelectMenu component
     */
    rightElements: PropTypes.node,
    /**
     * The text used for the label element
     */
    label: PropTypes.string,
    /**
     * An object with key and a value that is the label to be shown for each option
     */
    options: PropTypes.object.isRequired,
    /**
     * If `true`, show the options extending up from the top of the field
     */
    optionsAbove: PropTypes.bool,
    /**
     * Should the selectmenu use the traditional or material design style
     */
    traditional: PropTypes.bool,
    /**
     * Is the field required for form submission
     */
    required: PropTypes.bool,
    /**
     * The input ID
     */
    id: PropTypes.string,
    /**
     * The name used for the label element "for" and input "name" attributes (if not using redux form)
     */
    name: PropTypes.string,
    /**
     * Metadata about the state of this field (if using redux-form)
     */
    meta: PropTypes.object,
    /**
     * The connected input props (if using redux-form)
     */
    input: PropTypes.object,
    /**
     * Controls the field focus. If `true`, the field is focused. If `false`, it is blurred.
     */
    inputFocus: PropTypes.bool,
    /**
     * An additional custom className for the input
     */
    inputClassName: PropTypes.string,
    /**
     * Called when field is changed by onChange event
     *
     *  onChange(event, { newValue, method })
     *
     *  newValue - the new value of the input
     *  method - string describing how the change has occurred. The possible values are:
     *    'down' - user pressed Down
     *    'up' - user pressed Up
     *    'escape' - user pressed Escape
     *    'enter' - user pressed Enter
     *    'click' - user clicked (or tapped) on suggestion
     *    'type' - none of the methods above
     *      (usually means that user typed something, but can also be that they pressed Backspace,
     *       pasted something into the input, etc.)
     */
    onChange: PropTypes.func,
    /**
     * Default value to initialize the input, will only be taken into consideration on first render
     */
    defaultValue: PropTypes.string,
    /**
     * Current value of the input. Only when used as controlled input
     */
    value: PropTypes.string,
    /**
     * If `true`, the input is read-only
     */
    readOnly: PropTypes.bool,
    /**
     * onSuggestionSelected(event, { suggestion: { key, value } })
     *
     * Called when a suggestion is selected (returns the event and the suggestion object with label and key values)
     */
    onSuggestionSelected: PropTypes.func,
    /**
     * onSuggestionHighlighted({ suggestion })
     *
     * Called when the highlighted suggestion changes for any reason
     */
    onSuggestionHighlighted: PropTypes.func,
    /**
     * Options are not filtered by the value entry (Allows option filtering by external function)
     */
    passthrough: PropTypes.bool,
    /**
     * The placeholder text shown in the selectmenu before a value is entered (only used for traditional style)
     */
    placeholder: PropTypes.string,
    /**
     * An additional custom className
     */
    className: PropTypes.string,
    /**
     * Whether or not the options are loading (use to indicate initial options load)
     */
    loading: PropTypes.bool,
    /**
     * A Tooltip component used for the label
     */
    labelTooltip: PropTypes.node,
    /**
     * Whether or not the options are loading (use to indicate repeated, dynamic options loading)
     */
    secondaryLoading: PropTypes.bool,
    /**
     * Whether or not the selectmenu is disabled
     */
    disabled: PropTypes.bool,
    /*
     * If `true`, this field shows a triangle icon to indicate dropdown options
     */
    showIcon: PropTypes.bool,
    /**
     * The error shown below the selectmenu (not used if there is a redux-form style 'meta.error' value)
     */
    error: PropTypes.node,
    /**
     * The alignment of the error
     */
    errorAlign: PropTypes.string,
    /**
     * A CSS modules style object to override default theme
     */
    altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    /**
     * A custom event for the enter key (returns the current value)
     */
    onEnterKeyDown: PropTypes.func,
    /**
     * If set to `false`, suggestions wont be returned when the search query is empty. Defaults to true
     */
    showAllSuggestionsOnClick: PropTypes.bool,
    /**
     * show error message in input area and the duration for it to be dismissed
     */
    inlineError: PropTypes.string,
    /**
     * It's the number of suggestions that will be shown by Autoselect. Defaults to 0
     */
    suggestionsLimit: PropTypes.number,
    /**
     * If `true`, we allow a selected value that does not match an option
     */
    freeEntry: PropTypes.bool,
    /**
     * If `true`, we fully compare `options` objects when they are changed and reopen menu if it is focused.
     * This can be useful if `options` are changed dynamically.
     * If this is `false`, the menu will render the options as they existed when it was focused
     * and only update visible options if the number of `keys` has changed.
     */
    preciselyCompareOptions: PropTypes.bool,
    /**
     * If `false`, disables the default alphabetical match value sort
     * Also accepts a custom sorting function in the same way as `Array.prototype.sort`
     * The sort argument options can be sorted by label, key, terms or score properties where score is a number
     * representing the match value when compared with the entered text
     *
     * For example,
     * (a, b) => a.label > b.label ? -1 : 1}
     */
    sort: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    /**
     * The number of characters that can be entered before the maxlength indication is displayed
     */
    maxLength: PropTypes.number,
    /**
     * If `true`, the text exceeding the maxlength is highlighted
     */
    maxLengthHighlight: PropTypes.bool,
    /**
     * Called when the field is focused
     */
    onFocus: PropTypes.func,
    /**
     * Called when the field is blurred
     */
    onBlur: PropTypes.func,
    /**
     * An object with key and a value that is the label to be shown for each persistent option
     */
    persistentOptions: PropTypes.objectOf(PropTypes.string),
    /**
     * onPersistentOptionClick(event, { suggestion: { key, label } })
     *
     * Called when a persistent option is selected
     * (returns the event and the suggestion object with label and key values)
     */
    onPersistentOptionClick: PropTypes.func,
    /**
     * suggestionExtraElement({ suggestion: { key, label } })
     *
     * Callback to render extra element in the suggestion element
     */
    suggestionExtraElement: PropTypes.func
  }
  static getDerivedStateFromProps(
    { value: valueProp, input: { value: inputValueProp }, options },
    { value: stateValue, change }
  ) {
    let newState = null
    const passedValue =
      inputValueProp !== undefined ? inputValueProp : valueProp
    if (stateValue !== passedValue && passedValue !== undefined) {
      // Controlled value prop has been provided and is different than the state
      // Value is fully controlled by the component value prop
      newState = {
        value: passedValue || '',
        label: change // Only select a label by key when value changes from a controlled value - not input change
          ? passedValue
          : (options[passedValue] &&
              (Array.isArray(options[passedValue])
                ? options[passedValue][0].split('::')[0]
                : options[passedValue].split('::')[0])) ||
            ''
      }
    }
    return newState
  }
  selectId =
    this.props.input.name ||
    this.props.name ||
    this.props.id ||
    `selectmenu-${uniqueId()}`
  autoSelectRef = createRef()
  shouldScore = false
  componentDidUpdate = prevProps => {
    const {
      loading,
      secondaryLoading,
      options,
      preciselyCompareOptions,
      inputFocus
    } = this.props
    const { isFocused } = this.state
    const {
      loading: previousLoading,
      secondaryLoading: previousSecondaryLoading,
      options: previousOptions
    } = prevProps
    // Field is focused
    if (isFocused) {
      if (
        // Loading prop has changed to `true`
        (previousLoading && !loading) ||
        // Secondary loading prop has changed to `true`
        (previousSecondaryLoading && !secondaryLoading) ||
        // options size has changed
        Object.keys(previousOptions).length !== Object.keys(options).length ||
        // precise prop is `true` and options have any change
        (preciselyCompareOptions && !isEqual(previousOptions, options))
      ) {
        // Reopen the menu
        this.autoSelectRef.current.reopen()
      }
    }
    if (inputFocus === false && prevProps.inputFocus) {
      this.setState({
        isFocused: false
      })
    } else if (inputFocus === true && !prevProps.inputFocus) {
      this.setState({
        isFocused: true
      })
    }
  }
  componentDidMount() {
    const { value } = this.state
    if (value !== undefined) {
      const { label: optionLabel } = this.optionFromKey(value)
      const label = Array.isArray(optionLabel) ? optionLabel[0] : optionLabel
      this.setState({ label })
    }
  }
  handleChange = (event, { newValue, method }) => {
    const {
      input: { onChange: inputOnChange },
      onChange,
      maxLength,
      maxLengthHighlight
    } = this.props
    if (maxLength && !maxLengthHighlight) {
      if (newValue.length > maxLength) {
        newValue = newValue.substr(0, maxLength)
      }
    }
    if (inputOnChange !== undefined) {
      inputOnChange(newValue)
    }
    if (onChange !== undefined) {
      onChange(event, { newValue, method })
    }
    this.setState({ value: newValue, label: newValue, change: true })
  }
  handleSuggestionHighlighted = suggestion => {
    this.setState({ change: false })
    if (this.props.onSuggestionHighlighted) {
      this.props.onSuggestionHighlighted(suggestion)
    }
  }
  handleSuggestionSelected = (event, { suggestion: { key, label } }) => {
    const {
      onSuggestionSelected,
      input: { onChange: inputOnChange }
    } = this.props
    this.setState({ value: key, label, change: false })
    if (onSuggestionSelected) {
      onSuggestionSelected(event, { suggestion: { key, label } })
    }
    if (typeof inputOnChange === 'function') {
      inputOnChange(key)
    }
  }
  sortMatches = (options, value) => {
    const { sort } = this.props
    const sortFunction =
      typeof sort === 'function' ? sort : (a, b) => b.score - a.score
    const sorted = options
      .map(({ label, key, supplement }) => ({
        label,
        key,
        ...(supplement ? { supplement } : {}),
        terms: label.split(' '),
        score: this.shouldScore
          ? value
              .split(' ')
              .reduce(
                (previous, current) =>
                  previous + label.toLowerCase().includes(current),
                0
              )
          : 1
      }))
      .sort(sortFunction)
    return sorted
  }
  findMatches = value => {
    const { showAllSuggestionsOnClick, options, passthrough, sort } = this.props
    const mappedOptions = this.mapOptions(options)
    const inputValue = value.trim().toLowerCase()
    if (inputValue !== '' || showAllSuggestionsOnClick) {
      const matches = passthrough
        ? mappedOptions
        : mappedOptions.filter(
            ({ label }) => label.toLowerCase().indexOf(inputValue) !== -1
          )
      return sort ? this.sortMatches(matches, inputValue) : matches
    } else {
      return []
    }
  }
  optionFromKey = key => {
    const { options } = this.props
    const label = options[key]
    return { key, label }
  }

  /**
   * @deprecated in version 1.66.0
   *
   * This method requires the use of a ref in the parent component
   * This is now deprecated in favor of the `inputFocus` boolean prop,
   * which can be toggled from true/false to focus/blur the input
   */
  focus = () =>
    console.warn(
      'Calling a deprecated method: focus(). This will be removed in version 3.'
    ) || this.autoSelectRef.current.focusInput()

  /**
   * @deprecated in version 1.66.0
   *
   * This method requires the use of a ref in the parent component
   * This is now deprecated in favor of the `inputFocus` boolean prop,
   * which can be toggled from true/false to focus/blur the input
   */
  blur = () =>
    console.warn(
      'Calling a deprecated method: blur(). This will be removed in version 3.'
    ) || this.autoSelectRef.current.blurInput()

  optionFromLabel = value => {
    const { options, persistentOptions } = this.props
    const matchesValue = (match, test) => {
      return match.split('::')[0].toLowerCase().includes(test.toLowerCase())
    }
    // Store an array of label keys that include or equal the value
    const allOptions = {
      ...options,
      ...persistentOptions
    }
    const matchingKeys = Object.keys(allOptions).filter(key =>
      Array.isArray(allOptions[key])
        ? allOptions[key].find(commonKey => matchesValue(commonKey, value))
        : matchesValue(allOptions[key], value)
    )
    // Store the key of the most appropriate match from matchingKeys
    // Prioritize identical matches over partial matches
    const key =
      matchingKeys.find(key =>
        Array.isArray(allOptions[key]) // We support an array of labels for a single key
          ? allOptions[key].find(
              multiLabel => multiLabel.toLowerCase() === value.toLowerCase() // A label in the array matches the value
            )
          : allOptions[key].toLowerCase() === value.toLowerCase()
      ) || matchingKeys[0] // No identical matches - fall back to the first label including the value (partial match)

    const label =
      key &&
      (Array.isArray(allOptions[key])
        ? allOptions[key][0].split('::')[0]
        : allOptions[key].split('::')[0])
    return { key, label }
  }

  handleFocus = event => {
    const {
      onFocus,
      input: { onFocus: inputOnFocus }
    } = this.props
    this.shouldScore = false
    this.setState({ isFocused: true })
    if (inputOnFocus && typeof inputOnFocus === 'function') {
      inputOnFocus(event)
    }
    if (onFocus && typeof onFocus === 'function') {
      onFocus(event)
    }
  }

  handleBlur = event => {
    const { freeEntry } = this.props
    const {
      onBlur,
      input: { onBlur: inputOnBlur, onChange: inputOnChange }
    } = this.props
    // Only call props onBlur if autoSelect is not reopening
    if (
      inputOnBlur &&
      typeof inputOnBlur === 'function' &&
      !this.autoSelectRef.current.isReopening
    ) {
      inputOnBlur(event)
    }
    if (
      onBlur &&
      typeof onBlur === 'function' &&
      !this.autoSelectRef.current.isReopening
    ) {
      onBlur(event)
    }
    const { key, label: matchedLabel } = this.optionFromLabel(
      event.target.value
    )
    const value = key || (freeEntry ? event.target.value : '')
    const label = key ? matchedLabel : freeEntry ? event.target.value : ''
    this.setState({
      isFocused: false,
      value,
      label
    })
    if (typeof inputOnChange === 'function') {
      inputOnChange(value)
    }
  }

  handleKeyDown = event => {
    const { onEnterKeyDown } = this.props
    if (event.keyCode === 13) {
      if (onEnterKeyDown) {
        const { value } = event.target
        const { key, label } = this.optionFromLabel(value)
        onEnterKeyDown(key, label)
      }
      this.setState({ change: false })
      event.preventDefault()
    }
    this.shouldScore = true
  }

  mapOptions = options => {
    const split = (option, key) => {
      const parts = option.split('::')
      const label = parts[0]
      const supplement = parts[1]
      let result = { key, label }
      if (supplement) {
        result = { ...result, supplement }
      }
      return result
    }
    return [].concat.apply(
      [],
      Object.keys(options).map(key => {
        if (Array.isArray(options[key])) {
          // Return - to prevent further mapping
          return options[key].map(option => {
            return split(option, key)
          })
        }
        return split(options[key], key)
      })
    )
  }

  handlePersistentOptionClick = (e, { suggestion: { key, label } }) => {
    const { onPersistentOptionClick } = this.props
    this.setState({ value: label, label, change: false })
    if (typeof onPersistentOptionClick === 'function') {
      onPersistentOptionClick(e, { suggestion: { key, label } })
    }
  }

  render = () => {
    const {
      input,
      altTheme,
      placeholder,
      className,
      onChange,
      onSuggestionSelected,
      loading,
      secondaryLoading,
      traditional,
      defaultValue,
      required,
      error,
      showIcon,
      disabled,
      meta,
      inlineError,
      suggestionsLimit,
      options,
      inputFocus,
      inputClassName,
      readOnly,
      optionsAbove,
      maxLength,
      maxLengthHighlight,
      errorAlign,
      labelTooltip,
      rightElements,
      onPersistentOptionClick,
      suggestionExtraElement,
      freeEntry,
      showAllSuggestionsOnClick,
      name,
      id,
      preciselyCompareOptions,
      passthrough,
      value: ignore7,
      sort,
      onEnterKeyDown,
      onFocus,
      ...other
    } = this.props
    const { isFocused, value, label: optionLabel } = this.state
    const hasValue = value !== ''
    return (
      <SelectBase
        hasValue={hasValue}
        isFocused={isFocused}
        meta={meta}
        altTheme={altTheme}
        className={className}
        loading={loading}
        secondaryLoading={secondaryLoading}
        traditional={traditional}
        required={required}
        error={error}
        readOnly={readOnly}
        disabled={disabled}
        errorAlign={errorAlign}
        name={this.selectId}
        id={this.selectId}
        inlineError={inlineError}
        handleFocus={this.handleFocus}
        labelTooltip={labelTooltip}
        rightElements={rightElements}
        {...other}
      >
        <AutoSelect
          {...other}
          input={input}
          value={value}
          label={optionLabel}
          name={this.selectId}
          disabled={disabled}
          traditional={traditional}
          loading={loading}
          onFocus={!readOnly ? this.handleFocus : null}
          onBlur={!readOnly ? this.handleBlur : null}
          focused={inputFocus}
          altTheme={altTheme}
          placeholder={traditional && !inlineError ? placeholder : undefined}
          ref={this.autoSelectRef}
          required={required}
          secondaryLoading={secondaryLoading}
          showIcon={showIcon}
          error={Boolean((meta.touched && meta.error) || error || inlineError)}
          onChange={this.handleChange}
          onSuggestionHighlighted={this.handleSuggestionHighlighted}
          onKeyDown={this.handleKeyDown}
          onSuggestionSelected={this.handleSuggestionSelected}
          suggestionsLimit={suggestionsLimit}
          findMatches={this.findMatches}
          inputClassName={inputClassName}
          readOnly={readOnly}
          optionsAbove={optionsAbove}
          maxLength={maxLength}
          maxLengthHighlight={maxLengthHighlight}
          onSelect={this.handleHighlightScroll}
          onKeyPress={this.handleHighlightScroll}
          onScroll={this.handleHighlightScroll}
          onPersistentOptionClick={this.handlePersistentOptionClick}
          suggestionExtraElement={suggestionExtraElement}
        />
      </SelectBase>
    )
  }
}

export default SelectMenu
