import { useSelector } from 'react-redux'
import PageHero from './PageHero'
import classNames from 'classnames/bind'
import styles from './Page.module.sass'
import PropTypes from 'prop-types'
import PageHeader from './PageHeader'
import Footer from 'components/Footer'
import * as authSelectors from 'modules/auth/AuthSelector'
import siteConfig from 'site.config.json'

const {
  menuItems,
  sections: { footer },
  configuration
} = siteConfig

const cx = classNames.bind(styles)

const { getIsAuth, getRoleTable } = authSelectors

const Page = ({
  heroTitle,
  children,
  showGoBack = true,
  backIconLink,
  showHero = true,
  showDivider = true,
  showFooter = true,
  showMenu = true,
  isLimaBureau = false,
  showHeaderBottomBorder = true,
  onBackClick,
  headerProps,
  isFull = false,
  contentClassName,
  pageClassName,
  ...restProps
}) => {
  const isAuth = useSelector(getIsAuth())
  const { permissions: userRoles = [] } = useSelector(getRoleTable())
  const { rbac } = configuration
  const footerLinks = isAuth
    ? footer.links.filter(item => item.auth === true)
    : footer.links.filter(item => item.anon === true)
  const menuLinks = isAuth
    ? menuItems.links.filter(item =>
        item.permission
          ? typeof rbac === 'undefined' || rbac.enabled === false
            ? item.auth === true
            : item.auth === true && userRoles.includes(item.permission)
          : item.auth === true
      )
    : menuItems.links.filter(item => item.anon === true)
  return (
    <div
      className={cx(isFull ? styles.fullPage : styles.limaPage, pageClassName, {
        isLimaBureau
      })}
      {...restProps}
    >
      <PageHeader
        showBackIcon={showGoBack}
        showMenu={showMenu}
        backIconLink={backIconLink}
        isLimaBureau={isLimaBureau}
        menuLinks={menuLinks}
        onBackClick={onBackClick}
        showHeaderBottomBorder={showHeaderBottomBorder}
        {...headerProps}
      />
      {showHero && (
        <PageHero
          title={heroTitle}
          divider={showDivider}
          isLimaBureau={isLimaBureau}
        />
      )}
      <div
        className={cx(
          styles.container,
          !isFull && styles.limaContent,
          contentClassName,
          { limaBureauContent: isLimaBureau }
        )}
      >
        {children}
      </div>
      {showFooter && (
        <div className={styles.footer}>
          <Footer
            copyright={footer.copyright.replace('?', new Date().getFullYear())}
            links={footerLinks}
          />
        </div>
      )}
    </div>
  )
}
Page.propTypes = {
  /**
   * Extra class passed to the root element
   */
  pageClassName: PropTypes.string,
  /**
   * Extra class passed to the content element
   */
  contentClassName: PropTypes.string,
  /**
   * Hero section text
   */
  heroTitle: PropTypes.string,
  /**
   * The page content
   */
  children: PropTypes.node.isRequired,
  /**
   * If false back icon button won't appear
   */
  showGoBack: PropTypes.bool,
  /**
   * Custom back icon, if nothing defined, will show default back arrow
   */
  backIconLink: PropTypes.string,
  /**
   * If false Hero section won't appear
   */
  showHero: PropTypes.bool,
  /**
   * If false Hero section won't appear
   */
  showDivider: PropTypes.bool,
  /**
   * If false back footer won't appear
   */
  showFooter: PropTypes.bool,
  /**
   * Action to replace default back behavior
   */
  onBackClick: PropTypes.func,
  /**
   * If false Menu section won't appear
   */
  showMenu: PropTypes.bool,
  /**
   * If false the header bottom border won't appear
   */
  showHeaderBottomBorder: PropTypes.bool,
  /**
   * Props passed to the header
   */
  headerProps: PropTypes.object,
  /**
   * If true the page will not contain any margin
   */
  isFull: PropTypes.bool,
  /**
   * If true it will use the Lima Bureau styles
   */
  isLimaBureau: PropTypes.bool
}

export default Page
