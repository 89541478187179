import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { actions as listIngestionActions } from './ListIngestionReducer'
import {
  getFormState
  // getFinishedStatus
} from 'modules/listIngestion/ListIngestionSelector'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as trackActions } from 'modules/tracking/TrackingReducer'
import SmartsMLError from 'services/SmartsMLError'
import history from '../../history'
import { initialValues } from './config'
import { LOCATION_CHANGE } from 'connected-react-router'
import { smartsEndpoints } from '../../constants'

/**
 * Request submit form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitFormRequest(services, { payload }) {
  const { activeStep } = payload
  try {
    yield put(trackActions.trackSubmitAction({}))
    switch (activeStep) {
      case 'file-upload':
        yield call(fileUploadRequest, services, payload)
        break
      case 'sheet':
        yield call(sheetRequest, services, payload)
        break
      case 'data-location':
        yield call(dataLocationRequest, services, payload)
        break
      case 'project-metadata':
        yield call(projectMetadataRequest, services, payload)
        break
      case 'column-headers':
        yield call(columnHeadersRequest, services, payload)
        break
      case 'third-party-mapping':
        yield call(thirdPartyMappingRequest, services, payload)
        break
      case 'prior-engagement-mapping':
        yield call(priorEngagementMappingRequest, services, payload)
        break
      case 'value-mapping':
        yield call(valueMappingRequest, services, payload)
        break
      default:
        throw new Error('Step does not exist')
    }
  } catch (e) {
    yield put(listIngestionActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request file upload
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* fileUploadRequest(services, payload) {
  const SmartsService = services('SmartsService')
  const SmartsParser = services('SmartsParser')
  const { file } = payload
  try {
    // Get Signed URL endpoint call
    const { url, fields } = yield call([SmartsService, 'getSignedUrl'], {
      workflow: 'ListIngestion',
      fileName: file[0].name,
      contentType: file[0].type
    })

    // Call signedURl with file
    let formData = new FormData()
    formData.append('key', fields.key)
    formData.append('uuid', fields.uuid)
    formData.append('fileName', fields.fileName)
    formData.append('contentType', fields.contentType)
    formData.append('bucket', fields.bucket)
    formData.append('X-Amz-Algorithm', fields['X-Amz-Algorithm'])
    formData.append('X-Amz-Credential', fields['X-Amz-Credential'])
    formData.append('X-Amz-Date', fields['X-Amz-Date'])
    formData.append('X-Amz-Security-Token', fields['X-Amz-Security-Token'])
    formData.append('Policy', fields.Policy)
    formData.append('X-Amz-Signature', fields['X-Amz-Signature'])
    formData.append('file', file[0])

    yield call(
      [SmartsService, 'signedUrl'],
      process.env.NODE_ENV !== 'production'
        ? smartsEndpoints.listsSignedUrl
        : url,
      formData
    )

    // Call upload endpoint
    const {
      uuid: listId,
      type,
      sheetsCaptions = []
    } = yield call([SmartsService, 'uploadFile'], {
      workflow: 'ListIngestion',
      uuid: fields.uuid,
      fileKey: fields.key,
      fileName: fields.fileName
    })
    // Set default values with first sheet option & change form
    const firstSheet = sheetsCaptions[0]
    const isXls = type === 'xls' || type === 'xlsx'
    yield put(
      listIngestionActions.setFormState({
        ...payload,
        activeStep: isXls ? 'sheet' : 'project-metadata',
        listId,
        headingRow: isXls ? '1' : '',
        xlsxSheet: isXls ? firstSheet : '',
        isXls
      })
    )
    const parsedSheetsOptions = yield call(
      [SmartsParser, 'sheetsDropdownParser'],
      sheetsCaptions
    )
    yield put(
      listIngestionActions.setOptions({
        sheets: { ...sheetsCaptions, parsedSheetsOptions }
      })
    )
    yield put(listIngestionActions.submitFormRequestSuccess())
  } catch (e) {
    let errorMessage = e.message
    if (e instanceof SmartsMLError) {
      errorMessage = yield call(
        [SmartsParser, 'uploadFileErrorParser'],
        e.getResponseData()
      )
    }
    yield put(
      notificationActions.notificationEnqueue({
        message: errorMessage,
        duration: 0
      })
    )
    yield put(listIngestionActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request sheet form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* sheetRequest(services, payload) {
  const SmartsService = services('SmartsService')
  const SmartsParser = services('SmartsParser')

  try {
    const { listId, xlsxSheet } = payload

    // Call endpoint
    const {
      headers,
      area: {
        rows: { first: rowFirst, last: rowLast }
      }
    } = yield call([SmartsService, 'setConfig'], {
      uuid: listId,
      sheet: xlsxSheet,
      action: 'SheetSelection'
    })

    // Set default values with first/last column/row option & change form
    const firstColumn = headers[0].key
    const lastColumn = headers[headers.length - 1].key
    yield put(
      listIngestionActions.setFormState({
        ...payload,
        activeStep: 'data-location',
        columnFirst: firstColumn,
        columnLast: lastColumn,
        rowFirst: `${rowFirst}`,
        rowLast: `${rowLast}`
      })
    )
    const parsedColumnHeadersOptions = yield call(
      [SmartsParser, 'columnsHeadersDropdownParser'],
      headers
    )
    yield put(
      listIngestionActions.setOptions({
        'data-location': { ...headers, parsedColumnHeadersOptions }
      })
    )
    yield put(listIngestionActions.submitFormRequestSuccess())
  } catch (e) {
    let errorMessage = e.message
    if (e instanceof SmartsMLError) {
      errorMessage = yield call(
        [SmartsParser, 'uploadFileErrorParser'],
        e.getResponseData()
      )
    }
    yield put(
      notificationActions.notificationEnqueue({
        message: errorMessage,
        duration: 0
      })
    )
    yield put(listIngestionActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request data location form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* dataLocationRequest(services, payload) {
  const SmartsService = services('SmartsService')
  const SmartsParser = services('SmartsParser')

  try {
    const {
      listId,
      columnFirst,
      columnLast,
      headingRow,
      rowFirst,
      rowLast,
      xlsxSheet
    } = payload

    // Call endpoint
    yield call([SmartsService, 'setConfig'], {
      uuid: listId,
      action: 'AreaSelection',
      area: {
        headingRow: parseInt(headingRow),
        columns: {
          first: columnFirst,
          last: columnLast
        },
        rows: {
          first: parseInt(rowFirst),
          last: parseInt(rowLast)
        }
      },
      sheet: xlsxSheet
    })
    yield put(
      listIngestionActions.setFormState({
        ...payload,
        activeStep: 'project-metadata'
      })
    )
    yield put(listIngestionActions.submitFormRequestSuccess())
  } catch (e) {
    let errorMessage = e.message
    if (e instanceof SmartsMLError) {
      errorMessage = yield call(
        [SmartsParser, 'uploadFileErrorParser'],
        e.getResponseData()
      )
    }
    yield put(
      notificationActions.notificationEnqueue({
        message: errorMessage,
        duration: 0
      })
    )
    yield put(listIngestionActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request project metadata form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* projectMetadataRequest(services, payload) {
  const SmartsService = services('SmartsService')
  const SmartsParser = services('SmartsParser')

  try {
    const {
      listId: uuid,
      domain,
      clientProduct,
      brand,
      agency,
      client,
      masterProject,
      project,
      notes,
      defaultCountry,
      listMatching,
      targetListCreate,
      dedupe,
      postalList,
      targetListCreateListIds
    } = payload

    let projectMetadataPayload = {
      uuid,
      domain,
      clientProduct,
      brand: brand === '' ? [] : [brand],
      agency,
      client,
      masterProject,
      project,
      notes,
      defaultCountry,
      listMatching,
      targetListCreate,
      dedupe,
      postalList,
      targetListCreateListIds,
      tags: ''
    }

    // Set specific payload values when domain is 'FW'
    if (domain === 'FW') {
      projectMetadataPayload = {
        ...projectMetadataPayload,
        client: null,
        project: null,
        masterProject: -1,
        clientProduct: null
      }
    }

    // Call endpoint
    yield call([SmartsService, 'setProjectMetadata'], projectMetadataPayload)

    // Fetch BASIC HEADERS
    const {
      columns,
      suggestions = [],
      available
    } = yield call([SmartsService, 'fetchFileHeading'], {
      uuid,
      type: 'BASIC'
    })

    // Parse suggestions
    const parsedSuggestions = yield call(
      [SmartsParser, 'fileHeadersSuggestionsParser'],
      suggestions,
      listMatching ? ['hcpId', 'hcpID', 'partyId', 'partyID'] : []
    )

    const parsedColumnHeadersOptions = yield call(
      [SmartsParser, 'columnsHeadersDropdownParser'],
      columns
    )

    const customOptions = available.reduce((prev, curr) => {
      if (curr.options) {
        const parsedCustomOptions = curr.options.reduce(
          (prev, curr) => {
            return { ...prev, [curr.key]: curr.caption }
          },
          { 0: 'None' }
        )
        return { ...prev, [curr.name]: parsedCustomOptions }
      }
      return prev
    }, {})

    yield put(
      listIngestionActions.setOptions({
        'column-headers': { ...columns, parsedColumnHeadersOptions },
        ...customOptions
      })
    )
    yield put(
      listIngestionActions.setFormState({
        ...payload,
        ...parsedSuggestions,
        activeStep: 'column-headers'
      })
    )
    yield put(listIngestionActions.submitFormRequestSuccess())
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(listIngestionActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request column headers form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* columnHeadersRequest(services, payload) {
  const SmartsService = services('SmartsService')
  try {
    const {
      listId: uuid,
      fullname,
      firstname,
      middlename,
      lastname,
      profession,
      specialty,
      nationalId1,
      nationalIdType1,
      nationalId2,
      nationalIdType2,
      partyId,
      hcpId,
      addressLine1,
      addressLine2,
      addressLine3,
      phonenumber,
      city,
      state,
      postalcode,
      country,
      targetListCreate
    } = payload
    // Call endpoint
    yield call([SmartsService, 'setFileHeading'], {
      uuid,
      type: 'BASIC',
      fields: {
        fullname,
        firstname,
        middlename,
        lastname,
        profession,
        specialty,
        nationalId1,
        nationalIdType1,
        nationalId2,
        nationalIdType2,
        partyId,
        hcpId,
        addressLine1,
        addressLine2,
        addressLine3,
        phonenumber,
        city,
        state,
        postalcode,
        country
      }
    })
    let newStep = 'third-party-mapping'
    let nextMapping = 'CLIENTID'
    let nextLabel = 'thirdPartyID'
    // For Target List, jump to Segment page
    if (targetListCreate) {
      newStep = 'segment-mapping'
      nextLabel = 'segment'
    }

    // Complete heading request
    yield call(
      completeHeadingRequest,
      services,
      payload,
      nextMapping,
      nextLabel,
      newStep
    )

    yield put(listIngestionActions.submitFormRequestSuccess())
  } catch (e) {
    yield call(redirectOnFail, e)
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(listIngestionActions.submitFormRequestFail())
    console.error(e.message)
  }
}

/**
 * Request third party mapping form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* thirdPartyMappingRequest(services, payload) {
  const SmartsService = services('SmartsService')
  const SmartsParser = services('SmartsParser')
  try {
    const { listId: uuid } = payload
    // Get & parse mapping values
    const mapping = yield call(
      [SmartsParser, 'mappingValuesParser'],
      payload,
      'thirdPartyID'
    )
    // Call endpoint
    yield call([SmartsService, 'setFileHeading'], {
      uuid,
      type: 'CLIENTID',
      mapping
    })

    let newStep = 'prior-engagement-mapping'
    let nextMapping = 'ACTIVITY'
    let nextLabel = 'priorEngagementID'

    // Complete heading request
    yield call(
      completeHeadingRequest,
      services,
      payload,
      nextMapping,
      nextLabel,
      newStep
    )

    yield put(listIngestionActions.submitFormRequestSuccess())
  } catch (e) {
    yield call(redirectOnFail, e)
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(listIngestionActions.submitFormRequestFail())
    console.error(e.message)
  }
}

/**
 * Request prior engagement mapping form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* priorEngagementMappingRequest(services, payload) {
  const SmartsService = services('SmartsService')
  const SmartsParser = services('SmartsParser')
  try {
    const { listId: uuid } = payload
    // Get & parse mapping values
    const mapping = yield call(
      [SmartsParser, 'mappingValuesParser'],
      payload,
      'priorEngagementID'
    )
    // Call endpoint
    yield call([SmartsService, 'setFileHeading'], {
      uuid,
      type: 'ACTIVITY',
      mapping
    })

    let newStep = 'value-mapping'
    let nextMapping = 'VALUE'
    let nextLabel = 'value'

    // Complete heading request
    yield call(
      completeHeadingRequest,
      services,
      payload,
      nextMapping,
      nextLabel,
      newStep
    )
    yield put(listIngestionActions.submitFormRequestSuccess())
  } catch (e) {
    yield call(redirectOnFail, e)
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(listIngestionActions.submitFormRequestFail())
    console.error(e.message)
  }
}

/**
 * Request value mapping form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* valueMappingRequest(services, payload) {
  const SmartsService = services('SmartsService')
  const SmartsParser = services('SmartsParser')
  try {
    const { listId: uuid } = payload
    // Get & parse mapping values
    const mapping = yield call(
      [SmartsParser, 'mappingValuesParser'],
      payload,
      'value'
    )
    // Call endpoint
    yield call([SmartsService, 'setFileHeading'], {
      uuid,
      type: 'VALUE',
      mapping
    })

    let newStep = 'segment-mapping'
    let nextMapping = 'SEGMENT'
    let nextLabel = 'segment'

    // Complete heading request
    yield call(
      completeHeadingRequest,
      services,
      payload,
      nextMapping,
      nextLabel,
      newStep
    )
    yield put(listIngestionActions.submitFormRequestSuccess())
  } catch (e) {
    yield call(redirectOnFail, e)
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(listIngestionActions.submitFormRequestFail())
    console.error(e.message)
  }
}

/**
 * Request segment mapping form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* segmentMappingRequest(services, payload) {
  const SmartsService = services('SmartsService')
  const SmartsParser = services('SmartsParser')
  const { listId: uuid } = payload
  // Get & parse mapping values
  const mapping = yield call(
    [SmartsParser, 'mappingValuesParser'],
    payload,
    'segment'
  )
  // Call endpoint
  yield call([SmartsService, 'setFileHeading'], {
    uuid,
    type: 'SEGMENT',
    mapping
  })
}
/**
 *
 * @param {*} services
 * @param {*} payload
 * @param {*} nextMapping Next mapping key to fetch
 * @param {*} nextLabel Next label to be used
 * @param {*} newStep New step
 */
function* completeHeadingRequest(
  services,
  payload,
  nextMapping,
  nextLabel,
  newStep
) {
  const SmartsService = services('SmartsService')
  const SmartsParser = services('SmartsParser')
  const { listId: uuid } = payload
  // Fetch CLIENTID HEADERS
  const { columns, suggestions = [] } = yield call(
    [SmartsService, 'fetchFileHeading'],
    {
      uuid,
      type: nextMapping
    }
  )

  // Parse suggestions
  const parsedSuggestions = yield call(
    [SmartsParser, 'mappingFormSuggestionsParser'],
    suggestions,
    nextLabel,
    payload
  )

  // Parse Dropdwn options
  const parsedColumnHeadersOptions = yield call(
    [SmartsParser, 'columnsHeadersDropdownParser'],
    columns
  )

  // Update options state
  yield put(
    listIngestionActions.setOptions({
      [newStep]: { ...columns, parsedColumnHeadersOptions }
    })
  )
  // Update form state & display next step
  yield put(
    listIngestionActions.setFormState({
      ...payload,
      ...parsedSuggestions,
      activeStep: newStep
    })
  )
}

/**
 * Request list match
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitIngestionRequest(services, payload) {
  const SmartsService = services('SmartsService')
  const { listId: uuid, type } = payload
  // Validate List
  yield call([SmartsService, 'validateList'], { uuid })
  // Submit List
  const { message } = yield call([SmartsService, 'submitList'], { uuid, type })
  yield put(
    notificationActions.notificationEnqueue({
      message,
      duration: 0
    })
  )
}

/**
 * Request run analysis
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* completeIngestionRequest(services, { payload }) {
  try {
    yield put(trackActions.trackSubmitAction({}))
    // Set Segment Value mapping
    yield call(segmentMappingRequest, services, payload)

    // Submit ingestion
    yield call(submitIngestionRequest, services, payload)

    yield put(listIngestionActions.submitFormRequestSuccess())
    // yield put(listIngestionActions.finishIngestionRequest({ finished: true })) //
    yield call([history, history.replace], { pathname: '/' })
    yield put(
      listIngestionActions.setFormState({
        ...initialValues
      })
    )
  } catch (e) {
    yield call(redirectOnFail, e)
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(listIngestionActions.submitFormRequestFail())
    console.error(e.message)
  }
}

/**
 * Redirect user on Failed response
 *
 * @param res
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* redirectOnFail(e) {
  if (e && e instanceof SmartsMLError) {
    const { responseData } = e.getResponseData()
    const formState = yield select(getFormState())
    let newStep = ''
    if (responseData?.mapping) {
      switch (responseData.mapping) {
        case 'BASIC':
          newStep = 'column-headers'
          break
        case 'PROJECTMETADATA':
          newStep = 'project-metadata'
          break
        case 'SEGMENT':
          newStep = 'segment-mapping'
          break
        default:
          break
      }
      if (newStep) {
        // Redirect user to step
        yield put(
          listIngestionActions.setFormState({
            ...formState,
            activeStep: newStep
          })
        )
      }
    }
  }
}

/**
 * Redirect user to landing page if success
 *
 * @param res
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
// function* finishListIngestion() {
//   const { activeStep } = yield select(getFormState())
//   const hasFinished = yield select(getFinishedStatus())
//   if (activeStep === 'segment-mapping' && hasFinished) {
//     yield call([history, history.replace], { pathname: '/' })
//     yield put(listIngestionActions.finishIngestionRequest({ finished: false }))
//   }
// }

/**
 * Redirect user to landing page if success
 *
 * @param res
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
// function* resetFinishState() {
//   yield put(listIngestionActions.finishIngestionRequest({ finished: false }))
// }

function* resetForm(services, { payload }) {
  const { action } = payload

  if (action === 'REPLACE') {
    yield put(listIngestionActions.setFormState({ ...initialValues }))
  }
}

export default function* watchListIngestionRequest(services) {
  yield all([
    takeEvery(
      'LIST_INGESTION_SUBMIT_FORM_REQUEST',
      submitFormRequest,
      services
    ),
    takeEvery(
      'LIST_INGESTION_COMPLETE_REQUEST',
      completeIngestionRequest,
      services
    ),
    takeEvery(LOCATION_CHANGE, resetForm, services)
    // takeEvery('NOTIFICATION_CLEAR', finishListIngestion),
    // takeEvery('NOTIFICATION_ENQUEUE', resetFinishState)
  ])
}
