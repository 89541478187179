import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './Card.module.sass'

const cx = classNames.bind(styles)

const Card = ({ children, className }) => {
  return <div className={cx(styles.container, className)}>{children}</div>
}

Card.propTypes = {
  /**
   * The card content
   */
  children: PropTypes.node.isRequired,
  /**
   * Extra className
   */
  className: PropTypes.string
}

export default Card
