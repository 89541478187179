import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Modal from 'components/Dougall/Modal'
import Typography from 'components/ui/Typography'
import Divider from 'components/ui/Divider/Divider'

import styles from './ShareModal.module.sass'
import {
  MailIcon
  // LinkedInIcon
  // FacebookIcon,
  // TwitterIcon
} from 'static/icons'

const ShareModal = ({
  open = false,
  onConfirm = () => {},
  onCancel = () => {},
  onEmailClick = () => {},
  onLinkedInClick = () => {},
  onTwitterClick = () => {},
  onFacebookClick = () => {}
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={open}
      actionCaption={t('clickHereToInvite')}
      onCancel={onCancel}
      onConfirm={onConfirm}
      buttonClassName={styles.inviteButton}
      closeButtonIconSize={18}
      closeButtonStyles={{
        marginTop: '-22px',
        marginRight: '-7px'
      }}
    >
      <div className={styles.shareModal}>
        <Typography
          type="display2"
          align="center"
          className={styles.shareTitle}
        >
          {t('sharePromptTitle')}
        </Typography>
        <Typography
          type="greeting"
          align="left"
          className={styles.sharePromptText}
        >
          {t('sharePromptText')}
        </Typography>
        <div className={styles.socials}>
          {/* Email Share */}
          <div
            onClick={onEmailClick}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') onEmailClick()
            }}
          >
            <MailIcon sx={{ width: '20px', height: '16px', margin: 'auto' }} />
            <Typography
              type="greeting"
              align="center"
              className={styles.socialName}
            >
              {t('socialElementEmailText')}
            </Typography>
          </div>
          {/* LinkedIn Share */}
          {/* <div onClick={onLinkedInClick}>
            <LinkedInIcon
              sx={{ width: '18px', height: '18px', margin: 'auto' }}
            />
            <Typography
              type="greeting"
              align="center"
              className={styles.socialName}
            >
              {t('linkedIn')}
            </Typography>
          </div> */}
          {/* Twitter Share */}
          {/* <div onClick={onTwitterClick}>
            <TwitterIcon
              sx={{ width: '20px', height: '20px', margin: 'auto' }}
            />
            <Typography type="greeting" align="center">
              {t('twitter')}
            </Typography>
          </div> */}
          {/* Facebook Share */}
          {/* <div onClick={onFacebookClick}>
            <FacebookIcon
              sx={{ width: '18px', height: '18px', margin: 'auto' }}
            />
            <Typography type="greeting" align="center">
              {t('facebook')}
            </Typography>
          </div> */}
        </div>
        <div className={styles.orDivider}>
          <Divider />
          <Typography type="subheading" className={styles.dividerText}>
            {t('signInOr').toUpperCase()}
          </Typography>
          <Divider />
        </div>
        <Typography type="display2" align="center" style={{ marginBottom: 0 }}>
          {t('sharePromptInvite')}
        </Typography>
      </div>
    </Modal>
  )
}

ShareModal.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onEmailClick: PropTypes.func,
  onLinkedInClick: PropTypes.func,
  onTwitterClick: PropTypes.func,
  onFacebookClick: PropTypes.func
}

export default ShareModal
