import { useState } from 'react'
import { Redirect } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import Icon from 'components/ui/Icon'
import PageContent from 'components/Admin/Page/PageContent'
import Typography from 'components/ui/Typography'
import useRoleFeatures from 'modules/auth/useRoleFeatures'
import styles from './UsersBulkCreate.module.sass'
import Actions from './forms/Actions'
import { csvTable } from './config'
import UploadForm from './forms/UploadForm'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const Universe = () => {
  const [openInfo, setOpenInfo] = useState(false)
  const { roles } = useRoleFeatures({})
  const customStyles = {
    cells: {
      style: {
        flexWrap: 'wrap'
      }
    }
  }
  const columns = [
    {
      name: 'Column',
      sortable: false,
      cell: row => {
        let columnsValue = []
        row.column.forEach((e, i) => {
          columnsValue.push(
            <code key={i} className={styles.code}>
              {e}
            </code>
          )
          if (row.column[i + 1]) {
            columnsValue.push(
              <Typography key={`${i}-s`} className={styles.orSeparator}>
                or
              </Typography>
            )
          }
        })
        return columnsValue
      },
      style: {
        flexWrap: 'wrap'
      }
    },
    {
      name: 'Description on User',
      sortable: false,
      cell: row => {
        return <div className={styles.description}>{row.description}</div>
      },
      maxWidth: '150px'
    },
    {
      name: 'Required',
      sortable: false,
      cell: row => {
        return (
          <code className={styles.code}>{row.required ? 'true' : 'false'}</code>
        )
      },
      maxWidth: '150px'
    },
    {
      name: 'Example',
      sortField: 'value',
      sortable: false,
      cell: row => {
        let examples = []
        row.example.forEach((e, i) => {
          examples.push(
            <code key={i} className={styles.code}>
              {e}
            </code>
          )
          if (row.example[i + 1]) {
            examples.push(
              <Typography key={`${i}-s`} className={styles.orSeparator}>
                or
              </Typography>
            )
          }
        })
        return examples
      }
    }
  ]
  // Redirect user if user create & no access
  if (
    !roles.includes('/admin') &&
    !roles.includes('/admin/users/bulk/create')
  ) {
    return (
      <Redirect
        to={{
          pathname: '/admin/users/search'
        }}
      />
    )
  }
  return (
    <PageContent title="Bulk Create Users">
      <div
        data-testid="csvTableInfo-accordion"
        className={styles.descriptionContainer}
        onClick={() => setOpenInfo(!openInfo)}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') setOpenInfo(!openInfo)
        }}
      >
        <Typography className={styles.description}>
          Expected CSV columns:
        </Typography>
        <Icon
          iconColor="#000000d6"
          icon={openInfo ? 'svg/custom/carat-d' : 'svg/custom/carat-r'}
          iconSize={20}
        />
      </div>
      <DataTable
        className={cx(styles.infoTable, openInfo && styles.show)}
        columns={columns}
        data={csvTable}
        keyField="key"
        persistTableHead
        customStyles={customStyles}
      />
      <Actions />
      <UploadForm />
    </PageContent>
  )
}

export default Universe
