import PageContent from 'components/Admin/Page/PageContent'
import Typography from 'components/ui/Typography/Typography'
import SearchForm from './forms/Search'
import styles from './Threshold.module.sass'

const Threshold = () => {
  return (
    <PageContent title="Threshold">
      <Typography className={styles.description}>
        Threshold is a minimum value allowed for a Market in charts generation.
        <br />
        This logic only applied for a single combination of a Market.
        <br />
        <br />
        If Incoming Counts is below than Threshold value, then the generation
        would be rejected.
        <br />
        Even during Market (combination) selection of Profession/Specialty
        itself, said parameter itself should be pre-filtered.
      </Typography>
      <SearchForm />
    </PageContent>
  )
}

export default Threshold
