import axios from 'axios'
import { isEmpty } from 'utils'
import { smartsEndpoints } from '../constants'
/**
 * Job Status endpoints
 */
class JobStatusService {
  constructor(SmartsML) {
    this.SmartsML = SmartsML
  }
  /**
   * Execute query
   * @param method
   * @param endpoint
   * @param query
   * @param token
   * @returns {Promise<any>}
   */
  async request(method, endpoint, query) {
    const response = await this.SmartsML.fetch(
      endpoint,
      method,
      JSON.stringify(query)
    )
    return response.data
  }

  async downloadFile(listId, exportFileIndex, name, token) {
    const response = await this.SmartsML.fetch(
      `${smartsEndpoints.listsExportFiles}/${listId}/${exportFileIndex}`,
      'GET',
      {}
    )

    if (!isEmpty(response.data.responseData)) {
      // const presignedUrl = 'https://mytutari.com/webservices/contract/exportcallnote/10377431' // For testing purposes, make sure to not commit with this url active
      const presignedUrl = response.data.responseData.location
      const downloadedFile = await axios.get(presignedUrl).catch(error => {
        throw error
      })
      const url = window.URL.createObjectURL(new Blob([downloadedFile.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      link.remove()
      return downloadedFile
    }

    return response
  }
}

export default JobStatusService
