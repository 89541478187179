import { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { ShareIcon } from 'static/icons'
import Typography from 'components/ui/Typography'
import { styled } from '@mui/material/styles'

/**
 * Action Bar Container
 */
const ActionBarContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '9px 0px 0px'
}))

/**
 * Action Button Container
 */
const ActionButtonContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}))

/**
 * Action Bar Text
 */
const ActionLabel = styled(Typography)(() => ({
  paddingTop: '3px',
  fontSize: '12px',
  fontWeight: 'bold',
  color: '#363f72'
}))

/**
 * Action Button
 * forwardProps: active
 */
const ActionButton = styled('div', {
  shouldForwardProp: prop => prop !== 'active'
})(({ active }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  minWidth: '40px',
  minHeight: '24px',
  margin: '0px 2.5px',
  border: 'solid 2px #353e71',
  borderRadius: '30px',
  boxSizing: 'border-box',
  mixBlendMode: 'multiply',
  backgroundColor: active ? '#b3b8db' : '#f8f9fc',
  transition: '0.3s',
  '&:hover': {
    backgroundColor: '#717bbc',
    transition: '0.3s',
    p: {
      color: '#fff',
      transition: '0.3s'
    },
    '.MuiSvgIcon-root': {
      stroke: '#fff',
      transition: '0.3s'
    }
  }
}))

/**
 * Share Button
 */
const ShareButton = styled('div')(() => ({
  display: 'flex',
  marginRight: '5px',
  cursor: 'pointer',
  alignItems: 'center'
}))

/**
 * Action SVG
 */
const ActionSVG = ({ icon, ...props }) => {
  const Icon = styled(icon)(() => ({
    stroke: '#363f72',
    fill: 'none',
    width: '16px',
    height: '16px',
    marginTop: '3px',
    marginRight: '3px'
  }))
  return <Icon {...props} />
}
ActionSVG.propTypes = {
  /**
   * Icon component to be rendered
   */
  icon: PropTypes.func
}

const ActionBar = ({
  onAction = () => {},
  prompt = '',
  promptId,
  answerId,
  showLikes,
  showShare
}) => {
  const { t } = useTranslation()
  //  1 - Like
  //  0 - None
  // -1 - Dislike
  const [liked, setLiked] = useState(0)

  const handleAction = action => {
    if (action === 'share') {
      onAction({ action, prompt, promptId, answerId })
      return
    }
    if (action === 'like') {
      if (liked === 1) {
        setLiked(0)
        onAction({ action: 'like', promptId, answerId, undo: true })
      } else {
        setLiked(1)
        onAction({ action: 'like', promptId, answerId, undo: false })
      }
    } else if (liked === -1) {
      setLiked(0)
      onAction({ action: 'dislike', promptId, answerId, undo: true })
    } else {
      setLiked(-1)
      onAction({ action: 'dislike', promptId, answerId, undo: false })
    }
  }

  return (
    <ActionBarContainer>
      {showLikes && (
        <ActionButtonContainer>
          <ActionLabel sx={{ marginRight: '3.5px', fontFamily: 'Inter' }}>
            {t('actionBarText')}
          </ActionLabel>
          <ActionButton
            active={liked === 1}
            onClick={() => handleAction('like')}
            data-testid="like"
          >
            <ActionLabel sx={{ fontFamily: 'Inter' }}>{t('yes')}</ActionLabel>
          </ActionButton>
          <ActionButton
            active={liked === -1}
            onClick={() => handleAction('dislike')}
            data-testid="dislike"
          >
            <ActionLabel sx={{ fontFamily: 'Inter' }}>{t('no')}</ActionLabel>
          </ActionButton>
        </ActionButtonContainer>
      )}
      {showShare && (
        <ShareButton onClick={() => handleAction('share')} data-testid="share">
          <ActionSVG icon={ShareIcon} />
          <ActionLabel sx={{ fontFamily: 'Inter' }}>
            {t('socialElementShareText')}
          </ActionLabel>
        </ShareButton>
      )}
    </ActionBarContainer>
  )
}

ActionBar.propTypes = {
  onAction: PropTypes.func,
  prompt: PropTypes.string,
  promptId: PropTypes.string,
  answerId: PropTypes.string,
  showShare: PropTypes.bool,
  showLikes: PropTypes.bool
}

export default ActionBar
