import * as Yup from 'yup'

export const initialValues = {
  firstName: '',
  lastName: '',
  profession: '',
  workplaceName: '',
  workplaceAddress: '',
  city: '',
  postalCode: '',
  state: '',
  country: '',
  specialties: '',
  tags: '',
  language: '',
  sessionFeeFrom: '',
  sessionFeeTo: '',
  brand: false,
  me: false,
  external: false,
  internal: false
}

export const validationSchema = Yup.object().shape({})
