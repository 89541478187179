import { smartsEndpoints } from '../constants'

/**
 * Performs Axios call to search service
 */
class AdvisorSearchService {
  constructor(SmartsML, AdvisorSearchParser, ListingsParser) {
    this.SmartsML = SmartsML
    this.AdvisorSearchParser = AdvisorSearchParser
    this.ListingsParser = ListingsParser
  }
  /**
   * Function to fetch the Advisor List and parse accordingly
   *
   * Form Fields:
   * advisorTypes
   * firstName
   * lastName
   * state
   * profession
   * specialties
   * language
   * country
   * state
   * city
   * postalCode
   * workplaceName
   * workplaceAddress
   * tags
   * sessionFeeFrom
   * sessionFeeTo
   * tags
   * size
   *
   * @param endpoint
   * @param query
   * @param isAdmin
   * @param token
   */
  async fetchList(query, maxResultsDisplay, isAdmin) {
    // Parse Request Payload
    const parsedPayload = this.AdvisorSearchParser.adaptQuery({
      payload: query,
      isAdmin
    })
    // Send Request
    const {
      data: { responseData }
    } = await this.SmartsML.fetch(
      smartsEndpoints.advisorListEndpoint,
      'POST',
      JSON.stringify(parsedPayload)
    )
    // Parse Response
    const parsedListings = this.ListingsParser.profileRiver({
      data: responseData,
      isAdmin,
      maxResultsDisplay
    })
    return parsedListings
  }

  /**
   * Fetch Specialty List
   */
  async getSpecialtyList() {
    const { data } = await this.SmartsML.fetch(
      smartsEndpoints.specialtyListEndpoint,
      'GET'
    )
    const { specialties } = this.AdvisorSearchParser.parseSpecialties(data)
    return specialties
  }

  /**
   * Fetch Profession List
   */
  async getProfessionList() {
    const { data } = await this.SmartsML.fetch(
      smartsEndpoints.professionListEndpoint,
      'GET'
    )
    const { professions } = this.AdvisorSearchParser.parseProfessions(data)
    return professions
  }
}

export default AdvisorSearchService
