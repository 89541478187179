import Typography from 'components/ui/Typography'
import PropTypes from 'prop-types'
import Button from '../Button'
import styles from './ConfirmationModal.module.sass'
import Modal from '@material-ui/core/Modal'

const ConfirmationModal = ({
  open,
  message,
  onCancel,
  onConfirm,
  actionCaption = '',
  actionType = 'submit',
  submitting
}) => {
  const getColor = () => {
    const color = submitting
      ? '#d3d3d3'
      : actionType === 'delete'
      ? '#cb4e4e'
      : '#25b89b'

    return color
  }

  return (
    <Modal open={open}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Typography className={styles.message} type={'body1'}>
            {message}
          </Typography>
          <div style={{ height: '30px' }}></div>
          <div className={styles.buttonContainer}>
            <Button
              text={actionCaption}
              onClick={onConfirm}
              disabled={false}
              backgroundColor={getColor()}
              submitting={submitting}
              hasSpinner={submitting}
              spinnerStrokeColor="#fff"
            ></Button>
            <Button
              text="Cancel"
              backgroundColor="#d3d3d3"
              onClick={onCancel}
              disabled={submitting}
              className={styles.resetButton}
            ></Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
ConfirmationModal.propTypes = {
  /**
   * Display modal
   */
  open: PropTypes.bool,
  /**
   * Message of the modal
   */
  message: PropTypes.string,
  /**
   * cancel callback
   */
  onCancel: PropTypes.func,
  /**
   * Confirm callback
   */
  onConfirm: PropTypes.func,
  /**
   * Action Type, changes confirm action color
   */
  actionType: PropTypes.oneOf(['delete', 'submit']),
  /**
   * Action caption
   */
  actionCaption: PropTypes.string,
  submitting: PropTypes.bool
}
export default ConfirmationModal
