import { Fragment } from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames/bind'
import styles from './Selected.module.sass'

import { Button as ButtonUI } from '../ui/Button'
import Typography from '../ui/Typography'
import Divider from '../ui/Divider'

const cx = classNames.bind(styles)

const SelectedCombinations = ({
  combinations = [],
  selectedFiltersCaptions,
  universe = 0,
  memberCount = 0,
  onEdit,
  isFetching
}) => {
  return (
    <div className={styles.selectedContainer}>
      <div className={styles.flexContainer}>
        <Typography type="display2" color="text-primary" align="center">
          Combinations
        </Typography>

        <div className={styles.gridTable}>
          <div className={styles.rowHeader}>
            <Fragment key="header">
              <Typography type="display1" color="text-primary">
                Country
              </Typography>
              <Typography type="display1" color="text-primary">
                Profession
              </Typography>
              <Typography type="display1" color="text-primary">
                Specialty
              </Typography>
              <Typography type="display1" color="text-primary" align="right">
                Universe Count
              </Typography>
              <Typography type="display1" color="text-primary" align="right">
                LiMA Member Count
              </Typography>
            </Fragment>
          </div>
          {combinations.map((combination, index) => (
            <Fragment key={index}>
              <div className={cx((index + 1) % 2 === 0 && styles.evenCell)}>
                <Typography>{combination.country || ''}</Typography>
              </div>
              <div className={cx((index + 1) % 2 === 0 && styles.evenCell)}>
                <Typography>{combination.profession || ''}</Typography>
              </div>
              <div className={cx((index + 1) % 2 === 0 && styles.evenCell)}>
                <Typography>{combination.specialty || ''}</Typography>
              </div>
              <div className={cx((index + 1) % 2 === 0 && styles.evenCell)}>
                <Typography align="right">
                  {combination._universe || combination._universe === 0
                    ? combination._universe.toLocaleString()
                    : ''}
                </Typography>
              </div>
              <div className={cx((index + 1) % 2 === 0 && styles.evenCell)}>
                <Typography align="right">
                  {combination._total?.toLocaleString() ?? ''}
                </Typography>
              </div>
              {combinations.length !== index + 1 && (
                <Divider className={styles.gridDivider} />
              )}
            </Fragment>
          ))}
          <div className={styles.rowHeader}>
            <Fragment key={'totals'}>
              <Typography type="display1" color="text-primary">
                Total
              </Typography>
              <Typography>{''}</Typography>
              <Typography>{''}</Typography>
              <Typography type="display1" color="text-primary" align="right">
                {universe ? universe.toLocaleString() : 0}
              </Typography>
              <Typography type="display1" color="text-primary" align="right">
                {memberCount ? memberCount.toLocaleString() : 0}
              </Typography>
            </Fragment>
          </div>
        </div>
        {selectedFiltersCaptions && selectedFiltersCaptions.length > 0 && (
          <>
            <Typography
              type="display2"
              color="text-primary"
              className={styles.selectedHeader}
            >
              Filters
            </Typography>
            <ul>
              {selectedFiltersCaptions.map((filter, idx) => (
                <li key={idx}>{filter}</li>
              ))}
            </ul>
          </>
        )}
        <div style={{ alignSelf: 'center' }}>
          <ButtonUI
            className={styles.editButton}
            onClick={() => onEdit('filters')}
            type="button"
            disabled={isFetching}
            fontSize="0.8em"
          >
            Edit
          </ButtonUI>
        </div>
      </div>
    </div>
  )
}

SelectedCombinations.propTypes = {
  combinations: PropTypes.array,
  selectedFiltersCaptions: PropTypes.array,
  universe: PropTypes.number,
  memberCount: PropTypes.number,
  isFetching: PropTypes.bool,
  onEdit: PropTypes.func
}

export default SelectedCombinations
