import SvgIcon from '@mui/material/SvgIcon'

const MailIcon = props => (
  <SvgIcon viewBox="0 0 20 16" {...props}>
    <defs>
      <path
        d="M26 2H10c-1.1 0-1.99.9-1.99 2L8 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H10V6l8 5 8-5v10zm-8-7-8-5h16l-8 5z"
        id="yc3w3l8fpa"
      />
    </defs>
    <use
      fill="#293056"
      xlinkHref="#yc3w3l8fpa"
      transform="translate(-8 -2)"
      fillRule="evenodd"
    />
  </SvgIcon>
)

export default MailIcon
