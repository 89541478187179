import { createReducer, createAction } from '@reduxjs/toolkit'

// Tooltip actions
export const tooltipRequest = createAction('TOOLTIP_REQUEST')
export const tooltipRequestSuccess = createAction('TOOLTIP_REQUEST_SUCCESS')
export const tooltipRequestFail = createAction('TOOLTIP_REQUEST_FAIL')
export const tooltipRequestRemove = createAction('TOOLTIP_REQUEST_REMOVE')

export const actions = {
  tooltipRequest,
  tooltipRequestSuccess,
  tooltipRequestFail,
  tooltipRequestRemove
}

const listIdsReducer = createReducer(
  {
    tooltips: {}
  },
  {
    [tooltipRequest]: (state, { payload }) => {
      const { name } = payload
      state.tooltips[name] = {
        ...state.tooltips[name],
        tip: null,
        fetching: true
      }
    },
    [tooltipRequestSuccess]: (state, { payload }) => {
      const { name, ...rest } = payload
      state.tooltips[name] = {
        ...state.tooltips[name],
        ...rest,
        fetching: false
      }
    },
    [tooltipRequestFail]: (state, { payload }) => {
      const { name } = payload
      state.tooltips[name] = {
        ...state.tooltips[name],
        tip: null,
        fetching: false
      }
    },
    [tooltipRequestRemove]: (state, { payload }) => {
      const { name } = payload
      const { [name]: _, ...rest } = state.tooltips
      state.tooltips = rest
    }
  }
)

export default listIdsReducer
