import { createReducer, createAction } from '@reduxjs/toolkit'

export const searchRequest = createAction('THRESHOLD_SEARCH_REQUEST')
export const searchRequestSuccess = createAction(
  'THRESHOLD_SEARCH_REQUEST_SUCCESS'
)
export const searchRequestFail = createAction('THRESHOLD_SEARCH_REQUEST_FAIL')

export const fetchThresholdRequest = createAction('THRESHOLD_FETCH_REQUEST')
export const fetchThresholdRequestSuccess = createAction(
  'THRESHOLD_FETCH_REQUEST_SUCCESS'
)
export const fetchThresholdRequestFail = createAction(
  'THRESHOLD_FETCH_REQUEST_FAIL'
)

export const deleteThresholdRequest = createAction('THRESHOLD_DELETE_REQUEST')
export const deleteThresholdRequestSuccess = createAction(
  'THRESHOLD_DELETE_REQUEST_SUCCESS'
)
export const deleteThresholdRequestFail = createAction(
  'THRESHOLD_DELETE_REQUEST_FAIL'
)

export const setSelected = createAction('THRESHOLD_SET_SELECTED')
export const setModalOpen = createAction('THRESHOLD_SET_MODAL')
export const setEditThreshold = createAction('THRESHOLD_SET_EDIT')

export const updateThresholdRequest = createAction('THRESHOLD_UPDATE_REQUEST')
// export const updateThresholdRequestSuccess = createAction(
//   'THRESHOLD_UPDATE_REQUEST_SUCCESS'
// )
// export const updateThresholdRequestFail = createAction(
//   'THRESHOLD_UPDATE_REQUEST_FAIL'
// )

export const actions = {
  searchRequest,
  searchRequestSuccess,
  searchRequestFail,
  fetchThresholdRequest,
  fetchThresholdRequestFail,
  fetchThresholdRequestSuccess,
  deleteThresholdRequest,
  deleteThresholdRequestSuccess,
  deleteThresholdRequestFail,
  setSelected,
  setModalOpen,
  setEditThreshold,
  updateThresholdRequest
  // updateThresholdRequestSuccess,
  // updateThresholdRequestFail
}

const thresholdReducer = createReducer(
  {
    searchState: {
      country: '',
      // profession: '',
      // specialty: '',
      orderBy: '',
      order: '',
      page: 1,
      perPage: 25,
      countryEdit: '',
      id: null,
      value: 0
    },
    fetching: false,
    thresholdObject: [],
    pagination: {
      total: 0,
      filtered: 0,
      maxPage: 0,
      results: 0,
      page: 1,
      perPage: 25
    },
    thresholdFetch: false,
    singleThreshold: {
      id: 0,
      country: '',
      profesion: null,
      specialty: null,
      value: 0
    },
    deleteFetching: false,
    modalOpen: false,
    selected: null
  },
  {
    [searchRequest]: (state, { payload }) => {
      state.fetching = true
      state.searchState = payload.search || state.searchState
    },
    [searchRequestSuccess]: (state, { payload }) => {
      state.fetching = false
      state.thresholdObject = payload.thresholdObject
      state.pagination = payload.pagination
    },
    [searchRequestFail]: state => {
      state.fetching = false
      state.thresholdObject = [...state.thresholdObject]
      state.pagination = { ...state.pagination }
    },
    [fetchThresholdRequest]: state => {
      state.thresholdFetch = true
    },
    [fetchThresholdRequestSuccess]: (state, { payload }) => {
      state.singleThreshold = { ...state.singleThreshold, ...payload }
      state.thresholdFetch = false
    },
    [fetchThresholdRequestFail]: state => {
      state.thresholdFetch = false
    },
    [deleteThresholdRequest]: state => {
      state.deleteFetching = true
      state.modalOpen = true
    },
    [deleteThresholdRequestSuccess]: state => {
      state.deleteFetching = false
      state.modalOpen = false
    },
    [deleteThresholdRequestFail]: state => {
      state.deleteFetching = false
    },
    [setSelected]: (state, { payload }) => {
      state.selected = payload.id
    },
    [setModalOpen]: (state, { payload }) => {
      state.modalOpen = payload.modalOpen
    },
    [setEditThreshold]: (state, { payload }) => {
      state.searchState = { ...state.searchState, ...payload }
    },
    [updateThresholdRequest]: (state, { payload }) => {
      state.searchState = payload.search || state.searchState
      state.thresholdFetch = true
    }
  }
)

export default thresholdReducer
