import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as advisorScheduleActions } from 'modules/formAdvisorSchedule/AdvisorScheduleReducer'
import { actions as trackActions } from 'modules/tracking/TrackingReducer'
import { smartsEndpoints } from '../../constants'
import { getAccessToken } from '../auth/AuthSelector'

const { advisorScheduleEndpoint } = smartsEndpoints

/**
 * Receives schedule request action and reacts accordingly
 *
 * @param payload
 * @param services
 */
function* scheduleRequest(services, { payload }) {
  const AdvisorSchedule = services('AdvisorScheduleService')
  try {
    yield put(trackActions.trackSubmitAction({}))
    const token = yield select(getAccessToken())
    yield call(
      [AdvisorSchedule, 'schedule'],
      advisorScheduleEndpoint,
      payload,
      token
    )
    yield put(advisorScheduleActions.scheduleSuccess())
  } catch (e) {
    console.error(e)
    yield put(advisorScheduleActions.scheduleFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: 'We had an issue processing your request',
        duration: 0
      })
    )
  }
}

export default function* watchSearch(services) {
  yield all([takeEvery('SCHEDULE_REQUEST', scheduleRequest, services)])
}
