import PropTypes from 'prop-types'
import Typography from '../Typography'
import styles from './FieldError.module.sass'
import cx from 'classnames'

const FieldError = ({
  className,
  style,
  children,
  align,
  altTheme,
  ...rest
}) => {
  const themeStyles = { ...styles, ...altTheme }
  //console.log(align)
  return (
    <Typography
      role="typographyContainer"
      type="error"
      style={style}
      className={cx(themeStyles.error, className)}
      align={align}
      {...rest}
    >
      {children}
    </Typography>
  )
}

FieldError.displayName = 'FieldError'
FieldError.defaultProps = {
  align: 'left'
}
FieldError.propTypes = {
  /**
   * An additional custom className for the root element
   */
  className: PropTypes.string,
  /**
   * An additional custom style for the root element
   */
  style: PropTypes.object,
  /**
   * The error message
   */
  children: PropTypes.node.isRequired,
  /**
   * The position of the error message
   */
  align: PropTypes.oneOf(['', 'left', 'center', 'right', 'justify']),
  /**
   * A CSS modules style object to override default theme
   */
  altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
}
export default FieldError
