import { useSelector } from 'react-redux'
import * as filterSelectors from './FilterSelector'

const { getFilters } = filterSelectors

const getGteLteCaption = (from, to) => {
  if (from && !to) {
    return `More than ${from}`
  } else if (!from && to) {
    return `Less than ${to}`
  } else if (from && to) {
    return `Between ${from} and ${to}`
  }
  return ''
}

const useFilters = fieldValue => {
  const { options: filterOptions = [] } = useSelector(getFilters())
  const selectedFilters = Object.keys(fieldValue).reduce((prev, curr) => {
    const filterValues = { ...fieldValue[curr] }
    Object.keys(filterValues).forEach(f => {
      if (!filterValues[f] || !f) {
        delete filterValues[f]
      }
    })
    return { ...prev, [curr]: filterValues }
  }, {})

  const selectedFiltersCaptions = Object.keys(selectedFilters).reduce(
    (prev, curr) => {
      const filterOption = filterOptions.find(o => o.name === curr)
      const filterValues = { ...selectedFilters[curr] }
      let valuesCaptions = []
      if (filterOption && filterOption.template === 'booleanMultipleCheckbox') {
        Object.keys(filterValues).forEach(f => {
          const finalValue = filterOption.values.find(value => value.key === f)
          valuesCaptions = [...valuesCaptions, finalValue.caption]
        })
      } else if (
        filterOption &&
        filterOption.template === 'integerAnyInputGteLte'
      ) {
        const inputGteLte = getGteLteCaption(
          selectedFilters[curr].from,
          selectedFilters[curr].to
        )
        if (inputGteLte) {
          valuesCaptions = [inputGteLte]
        }
      }
      if (valuesCaptions.length) {
        return [
          ...prev,
          `${filterOption.caption}: ${valuesCaptions.join(', ')}`
        ]
      }
      return prev
    },
    []
  )

  return {
    selectedFilters,
    selectedFiltersCaptions
  }
}

export default useFilters
