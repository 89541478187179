import React, { Children } from 'react'
import PropTypes from 'prop-types'
import icons from '@psl/icons/listIcons'
import styles from './SubMenu.module.sass'
import { ListItem, ListItemIcon, ListItemText } from '../List'

const SubMenu = ({
  active,
  children: childrenProp,
  icon,
  iconColor,
  iconPosition,
  title,
  component,
  divider,
  mode,
  ...other
}) => {
  const handleTitleClick = event => {
    event.stopPropagation()
  }
  const children = Children.map(childrenProp, child => {
    if (child) {
      return typeof child.type === 'string'
        ? child
        : React.cloneElement(child, {
            className: styles.item,
            componentClassName: styles.component,
            childClassName: styles.itemChild
          })
    }
  })
  return (
    <ListItem
      listStyle="none"
      component={component}
      componentClassName={styles.root}
      childClassName={styles.child}
      divider={mode === 'sidebar' && divider}
      backgroundColor="transparent"
    >
      <div
        className={styles.title}
        onClick={handleTitleClick}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') handleTitleClick()
        }}
      >
        {iconPosition === 'right' && (
          <ListItemText
            className={styles.listItemText}
            type="menu-item"
            primary={<span className={styles.titleText}>{title}</span>}
          />
        )}
        {icon && (
          <ListItemIcon icon={icon} colorTier={active ? 'action' : iconColor} />
        )}
        {iconPosition === 'left' && (
          <ListItemText
            className={styles.listItemText}
            type="menu-item"
            primary={<span className={styles.titleText}>{title}</span>}
          />
        )}
      </div>
      {children}
    </ListItem>
  )
}

SubMenu.displayName = 'SubMenu'
SubMenu.defaultProps = {
  component: 'ul',
  iconPosition: 'right'
}
SubMenu.propTypes = {
  /**
   * Is the menu item active
   */
  active: PropTypes.bool,
  /**
   * The svg icon to show for the left button
   */
  icon: PropTypes.oneOf(['', ...icons]),
  /**
   * The icon button color
   */
  iconColor: PropTypes.oneOf([
    '',
    'icon',
    'action',
    'primary',
    'secondary',
    'tertiary',
    'default'
  ]),
  /**
   * The position of the icon
   */
  iconPosition: PropTypes.oneOf(['left', 'right']),
  /**
   * The menu children (should contain an array of MenuItem components)
   */
  children: PropTypes.node,
  /**
   * The element tag
   */
  component: PropTypes.string,
  /**
   * The submenu title
   */
  title: PropTypes.string,
  /**
   * If `true`, a bottom divider will be shown.
   */
  divider: PropTypes.bool,
  /**
   * The menu display mode
   */
  mode: PropTypes.oneOf(['sidebar', 'popout'])
}
export default SubMenu
