import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { isEmpty } from 'utils'
import SelectInput from 'components/SelectInput'
import TextInput from 'components/TextInput'

import { Button } from 'components/ui/Button'
import ButtonStyles from 'components/ui/Button/Button.module.sass'
import DatePicker from 'components/ui/DatePicker'
// import { CircularProgress } from 'components/ui'

import * as jobStatusListSelectors from 'modules/jobStatusList/JobStatusListSelector'
import { actions } from './JobStatusListReducer'
import styles from './JobStatusSearch.module.sass'
import validationSchema from './validationSchema'

const {
  getFetchingStatus,
  getSearchState,
  getListStatus,
  // getListIds,
  getListDomains
} = jobStatusListSelectors

const DATE_FORMAT = 'YYYY-MM-DD'

const JobStatusSearch = ({ onReset = () => {}, onSubmit = () => {} }) => {
  ButtonStyles.title = styles.title
  let initialForm = {
    startDate: null,
    endDate: null,
    listId: '',
    // listId_key: null,
    status: null,
    domain: null,
    masterProject: ''
  }
  const [firstVisit, setFirstVisit] = useState(true)
  const isFetching = useSelector(getFetchingStatus())
  const formState = useSelector(getSearchState())
  const { options: listStatuses, fetching: isFetchingListStatus } = useSelector(
    getListStatus()
  )
  // const { options: listIds, fetching: isFetchingListIds } = useSelector(
  //   getListIds()
  // )
  const { options: metadataDomains } = useSelector(getListDomains())
  const dispatch = useDispatch()
  let formStatePr = isEmpty(formState) ? initialForm : formState
  const [initialValues, setInitialValues] = useState(formStatePr)
  const [clearInput, setClearInput] = useState(false)
  const handleReset = () => {
    // FORCES NESTED COMPONENTS TO UPDATE
    setClearInput(!clearInput)
    setInitialValues(initialForm)
    onReset()
  }
  useEffect(() => {
    if (firstVisit && !isFetchingListStatus) {
      dispatch(actions.requestListStatus())
      dispatch(actions.requestListMetadataDomains())
    }
    setFirstVisit(false)
  }, [dispatch, firstVisit, isFetchingListStatus])
  return (
    <Formik
      key={clearInput}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Form autoComplete="off">
        <div className={styles.container}>
          <div className={styles.dateInput}>
            <Field name="startDate">
              {({ field, form: { setFieldValue }, meta }) => (
                <DatePicker
                  label="Start Date"
                  name="startDate"
                  // meta={meta}
                  onChange={(date, value) => {
                    const formattedDate = date ? date.format(DATE_FORMAT) : null
                    setFieldValue('startDate', formattedDate)
                  }}
                  value={field.value}
                  format={DATE_FORMAT}
                  mask="____-__-__"
                  disableFuture
                />
              )}
            </Field>
          </div>
          <TextInput
            label="List ID"
            name="listId"
            type="text"
            traditional={false}
          />
          <Field name="domain">
            {({ field, form: { setFieldValue }, meta }) => (
              <SelectInput
                label="Domain"
                name="domain"
                options={metadataDomains}
                autoComplete="off"
                onChange={event => setFieldValue('domain', event.target.value)}
                onSuggestionSelected={(event, { suggestion: { key, value } }) =>
                  setFieldValue('domain', key)
                }
              />
            )}
          </Field>
          <div className={styles.dateInput}>
            <Field name="endDate">
              {({ field, form: { setFieldValue }, meta }) => (
                <DatePicker
                  label="End Date"
                  name="endDate"
                  meta={meta}
                  onChange={(date, value) => {
                    const formattedDate = date ? date.format(DATE_FORMAT) : null
                    setFieldValue('endDate', formattedDate)
                  }}
                  value={field.value}
                  format={DATE_FORMAT}
                  mask="____-__-__"
                  disableFuture
                />
              )}
            </Field>
          </div>
          {/* <Field name="listId">
            {({ field, form: { setFieldValue }, meta }) => (
              <SelectMenu
                label="List ID"
                name="listId"
                meta={meta}
                onChange={(event, { newValue }) => {
                  dispatch(actions.requestListIds({ search_term: newValue }))
                  setFieldValue('listId_key', '')
                  setFieldValue('listId', newValue)
                }}
                onSuggestionSelected={(event, { suggestion: { key, value } }) =>
                  setFieldValue('listId_key', key)
                }
                value={field.value}
                options={listIds}
                showIcon={false}
                autoComplete="off"
                rightElements={
                  isFetchingListIds && (
                    <CircularProgress
                      fillColor="transparent"
                      size={24}
                      strokeWidth={4}
                      className={styles.selectMenuProgress}
                    />
                  )
                }
              />
            )}
          </Field> */}
          <SelectInput
            label="Status"
            name="status"
            options={listStatuses}
            autoComplete="off"
          />
          <TextInput
            label="Master Project"
            name="masterProject"
            type="number"
            traditional={false}
          />
          <div className={styles.buttonContainer}>
            <Button
              iconLeft="svg/material-design-icons/av/shuffle"
              textCase="none"
              minWidth="12em"
              type="reset"
              onClick={handleReset}
              hasSpinner
            >
              Reset
            </Button>
            <Button
              iconLeft="svg/material-design-icons/action/search"
              textCase="none"
              minWidth="12em"
              type="submit"
              submitting={isFetching}
              disabled={isFetching}
              hasSpinner
              backgroundColor="#02296d"
            >
              Search
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  )
}
JobStatusSearch.propTypes = {
  /**
   * Called when the user resets the search form
   */
  onReset: PropTypes.func,
  /**
   * Called when the user submits the search form
   * Returns the form values
   */
  onSubmit: PropTypes.func
}
export default JobStatusSearch
