import PageContent from 'components/Admin/Page/PageContent'
import Typography from 'components/ui/Typography'
import FileForm from './forms/FileForm'
import SearchForm from './forms/Search'
import styles from './Universe.module.sass'
const Universe = () => {
  return (
    <PageContent title="Universe">
      <Typography className={styles.description}>
        Universe is a artificial count that mask incoming/result counts post
        charts generation.
        <br />
        It&apos;s a count-value of a combination, pre-approved from Business.
        <br />
        Generally to govern on duplicated/dirty Member Profiles.
        <br />
        <br />
        Create a new Sheet with date (YYYY-MM-DD) format,{' '}
        <a
          href="https://docs.google.com/spreadsheets/d/1gB0Q6QTlb-AMHNxsL9G-4HQqtZIOknAsO58XrSrCKkE/edit#gid=954986424"
          target="_blank"
          rel="noreferrer"
        >
          on this document
        </a>
        .<br />
        Then export into CSV, by go to <b>File</b> &gt; <b>Download</b> &gt;{' '}
        <b>Comma Separated Values (.csv)</b>.<br />
        Upload the file below to ingest the updated values.
      </Typography>
      <FileForm />
      <SearchForm />
    </PageContent>
  )
}

export default Universe
