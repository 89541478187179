import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PageContent from 'components/Admin/Page/PageContent'
import Typography from 'components/ui/Typography/Typography'
import { Formik, Form } from 'formik'
import { isEmpty } from 'utils'
import Button from 'components/Admin/Button'
import Card from 'components/Admin/Card/Card'
import TextInput from 'components/TextInput'
import DataTable from 'react-data-table-component'
import Icon from 'components/ui/Icon'
import { CircularProgress } from 'components/ui/Progress'
import useRoleFeatures from 'modules/auth/useRoleFeatures'
import ConfirmationModal from 'components/Admin/ConfirmationModal/ConfirmationModal'

import {
  getFirstFetching,
  getFetching,
  getFormState,
  getVariables,
  getPagination,
  getSelected
} from './VariablesSelector'
import { actions as variablesActions } from './VariablesReducer'

import styles from './Variables.module.sass'

const Variables = () => {
  const dispatch = useDispatch()
  const routerHistory = useHistory()
  const initialForm = {
    id: '',
    note: '',
    orderBy: 'id',
    order: 'asc',
    page: 1,
    perPage: 20
  }
  const { features } = useRoleFeatures({
    coin: 'charts-configuration'
  })
  const [modalOpen, setModalOpen] = useState(false)
  const [clearInput, setClearInput] = useState(false)
  const isFirstFetching = useSelector(getFirstFetching())
  const isFetching = useSelector(getFetching())
  const variables = useSelector(getVariables())
  const pagination = useSelector(getPagination())
  const formState = useSelector(getFormState())
  const selected = useSelector(getSelected())
  const roleSchema = features?.sections?.actions || []
  const userCanEdit = roleSchema.includes('save')
  const formStatePr = isEmpty(formState) ? initialForm : formState
  const { location } = routerHistory

  const handleSubmit = values => {
    const { id, note } = values
    dispatch(
      variablesActions.setFormState({
        ...initialForm,
        id,
        note
      })
    )
    dispatch(
      variablesActions.requestVariables({
        id,
        note
      })
    )
  }

  const handleReset = () => {
    dispatch(variablesActions.setFormState({ ...initialForm }))
    dispatch(variablesActions.requestVariables({}))
    setClearInput(!clearInput)
  }

  const handleSort = ({ sortField: selector }, sortDirection) => {
    const payload = {
      ...formStatePr,
      orderBy: selector,
      order: sortDirection
    }
    dispatch(variablesActions.setFormState(payload))
    dispatch(variablesActions.requestVariables(payload))
  }

  const handleView = (id, note) => {
    dispatch(
      variablesActions.setSelected({
        id,
        note
      })
    )
    const urlId = location.pathname.slice(-1) === '/' ? `${id}` : `/${id}`
    routerHistory.push(`${location.pathname}${urlId}`)
  }

  const handleModalOpen = (id, note) => {
    dispatch(
      variablesActions.setSelected({
        id,
        note
      })
    )
    setModalOpen(true)
  }

  const handleModalClose = () => {
    dispatch(
      variablesActions.setSelected({
        id: null,
        note: ''
      })
    )
    setModalOpen(false)
  }

  const handleRowsChange = (perPage, page) => {
    const payload = {
      ...formStatePr,
      page,
      perPage
    }
    dispatch(variablesActions.setFormState(payload))
    dispatch(variablesActions.requestVariables(payload))
  }

  const handlePageChange = page => {
    const payload = {
      ...formStatePr,
      page
    }
    dispatch(variablesActions.setFormState(payload))
    dispatch(variablesActions.requestVariables(payload))
  }

  const handleDelete = () => {
    dispatch(
      variablesActions.requestVariablesDelete({
        id: selected.id,
        formState: formStatePr
      })
    )
    setModalOpen(false)
  }

  if (isFirstFetching) {
    dispatch(variablesActions.requestVariables({}))
  }

  const handleCreate = () => {
    routerHistory.push('/admin/variables/create')
    dispatch(variablesActions.resetSingleVariable({}))
  }

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortField: 'id',
      sortable: true,
      cell: row => {
        return (
          <div className={styles.noteCell}>
            <Typography>{row.id}</Typography>
          </div>
        )
      }
    },
    {
      name: 'Note',
      selector: row => row.note,
      sortField: 'note',
      sortable: true,
      cell: row => {
        return (
          <div className={styles.noteCell}>
            <Typography>{row.note ? row.note : ''}</Typography>
          </div>
        )
      }
    },
    {
      name: 'Created',
      selector: row => row.createdAt,
      sortField: 'date',
      center: true,
      sortable: false,
      cell: row => {
        return <Typography>{row.createdAt ? row.createdAt : ''}</Typography>
      }
    },
    {
      name: 'Updated',
      selector: row => row.updatedAt,
      sortField: 'date',
      center: true,
      sortable: false,
      cell: row => {
        return <Typography>{row.updatedAt ? row.updatedAt : ''}</Typography>
      }
    },
    {
      sortable: false,
      maxWidth: '20px',
      cell: row => {
        return (
          <Icon
            icon="svg/custom/visibility"
            className={styles.tableIcon}
            onClick={() => handleView(row.id, row.note)}
          />
        )
      }
    },
    {
      sortable: false,
      maxWidth: '20px',
      omit: !userCanEdit,
      cell: row => {
        return (
          <Icon
            icon="svg/material-design-icons/action/delete"
            className={styles.tableIcon}
            onClick={() => handleModalOpen(row.id, row.note)}
          />
        )
      }
    }
  ]

  return (
    <PageContent title="Variables">
      <Formik
        key={clearInput}
        enableReinitialize
        initialValues={formStatePr}
        onSubmit={handleSubmit}
      >
        <Form autoComplete="off">
          <Typography className={styles.description}>
            Variable is a general storage for the application.
            <br />
            The usage and structure is open,based on its functionality.
            <br />
            So ensure your config/cahnges in the right structure, otherwise it
            may possibly break some functionality.
            <br />
            <br />
            Features that depending on this entity is not limited to:
            <br />
            - Charts Generations
            <br />
            <span className={styles.indent}>-</span>{' '}
            <code className={styles.code}>charts_configurations</code>
            <br />
            - Data Cache
            <br />
            <span className={styles.indent}>-</span>{' '}
            <code className={styles.code}>datacache-core-mapping</code>
            <br />
            <span className={styles.indent}>-</span>{' '}
            <code className={styles.code}>datacache-static-*</code>
            <br />
            - Content
            <br />
            <span className={styles.indent}>-</span>{' '}
            <code className={styles.code}>content-*</code>
          </Typography>
          {userCanEdit && (
            <div className={styles.addButton}>
              <Button
                text="Create"
                textCase="none"
                disabled={isFetching}
                type="button"
                onClick={() => handleCreate()}
              />
            </div>
          )}
          <Card className={styles.card}>
            <Typography type="title" color="text-primary">
              Search Criteria
            </Typography>
            <div className={styles.searchForm}>
              <TextInput label="ID" name="id" type="text" traditional={false} />
              <TextInput
                label="Note"
                name="note"
                type="text"
                traditional={false}
              />
              <div className={styles.formButtons}>
                <Button
                  className={styles.resetButton}
                  text="Reset"
                  textCase="none"
                  disabled={false}
                  type="reset"
                  backgroundColor="#d3d3d3"
                  onClick={() => handleReset()}
                />
                <Button
                  text="Search"
                  textCase="none"
                  disabled={false}
                  type="submit"
                />
              </div>
            </div>
          </Card>
          <Card className={styles.card}>
            <Typography type="title" color="text-primary">
              Search Results
            </Typography>
            <DataTable
              columns={columns}
              data={variables}
              keyField="key"
              progressPending={isFetching}
              pagination
              paginationServer
              paginationTotalRows={pagination.filtered}
              onChangeRowsPerPage={handleRowsChange}
              onChangePage={handlePageChange}
              onSort={handleSort}
              sortServer
              persistTableHead
              paginationPerPage={pagination.perPage}
              paginationRowsPerPageOptions={[10, 20, 25]}
              progressComponent={
                <CircularProgress
                  className={styles.circularProgress}
                  fillColor="transparent"
                  size={64}
                  strokeWidth={4}
                />
              }
            />
          </Card>
        </Form>
      </Formik>

      <ConfirmationModal
        message={
          'Are you sure you want to delete element with ID: ' + selected.id
        }
        open={modalOpen}
        onConfirm={handleDelete}
        onCancel={handleModalClose}
        actionType="delete"
        actionCaption="Delete"
      />
    </PageContent>
  )
}

export default Variables
