import { useState, useEffect } from 'react'
import ListIds from 'modules/listIds'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import { Button as ButtonUI } from 'components/ui/Button'
import DropdownInput from 'components/DropdownInput'
import Input from 'components/TextInput'
import { actions as exportListActions } from '../ExportListReducer'
import * as exportListSelectors from '../ExportListSelector'
import { useDispatch, useSelector } from 'react-redux'
import styles from './form.module.sass'
import CombinationTable from 'modules/CombinationsTable'
// import TargetSpecialityTable from 'modules/TargetSpecialityTable'
// import FiltersComponent from 'modules/filter'
import HopperExportForm from './HopperExportForm'
import classNames from 'classnames/bind'
import useRoleFeatures from 'modules/auth/useRoleFeatures'
import { isCombinationValid } from 'utils'

const cx = classNames.bind(styles)

const { getFormState, getBrandOptions } = exportListSelectors

const ExportListForm = ({
  formProps,
  handleReset
  // combinationButtonDisabled,
  // SetCombinationButtonDisabled,
  // targetButtonDisabled,
  // SetTargetButtonDisabled
}) => {
  const { setFieldValue, values } = formProps
  const {
    ids,
    ids2,
    combinationsCountry,
    combinationsProfession,
    combinationsSpecialty,
    reportName
  } = values
  const dispatch = useDispatch()
  const formState = useSelector(getFormState())
  const brandOptions = useSelector(getBrandOptions())
  const { features } = useRoleFeatures({
    coin: 'export-list'
  })
  const combineButtonDisabled = ids[0] === '' || ids[0] === reportName
  const combineButtonTwoDisabled = ids2[0] === '' || !reportName
  const [combineHidden, setCombineHidden] = useState(true)

  useEffect(() => {
    if (Object.keys(brandOptions).length === 0) {
      getOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getOptions = () => {
    dispatch(exportListActions.getBrandsRequest())
  }

  const handleAddCombinations = () => {
    if (
      isCombinationValid(
        combinationsCountry,
        combinationsProfession,
        combinationsSpecialty
      )
    ) {
      setFieldValue('combinations', [
        ...values['combinations'],
        {
          id: values['combinations'].length + 1,
          country: combinationsCountry,
          profession: combinationsProfession,
          specialty: combinationsSpecialty
        }
      ])
      setFieldValue('combinationsCountry', '')
      setFieldValue('combinationsProfession', '')
      setFieldValue('combinationsSpecialty', '')
      // setFieldValue('targetSpecialtyBoolean', false)

      // SetTargetButtonDisabled(true)
    }
  }

  // const handleAddTargetSpeciality = () => {
  //   if (
  //     isCombinationValid(
  //       combinationsCountry,
  //       combinationsProfession,
  //       combinationsSpecialty
  //     )
  //   ) {
  //     setFieldValue('targetSpeciality', [
  //       ...values['targetSpeciality'],
  //       {
  //         id: values['targetSpeciality'].length + 1,
  //         country: combinationsCountry,
  //         profession: combinationsProfession,
  //         specialty: combinationsSpecialty
  //       }
  //     ])
  //     setFieldValue('combinationsCountry', '')
  //     setFieldValue('combinationsProfession', '')
  //     setFieldValue('combinationsSpecialty', '')
  //     setFieldValue('targetSpecialtyBoolean', true)

  //     SetTargetButtonDisabled(true)
  //     SetCombinationButtonDisabled(true)
  //   }
  // }

  const handleCombineOpen = () => {
    // Here open an accordion
    setCombineHidden(false)
  }

  const handleCombine = () => {
    // Concat ids and ids2 (this way we don't have to create another ListIds component or refactor it too much)
    const newIds = ids.concat(ids2)

    // set the selectedLists to hold our new value (since if we use ids it displays another ListIds item)
    // also sets the ids typeahead to show "Combination" so the user knows we are using his combination
    dispatch(
      exportListActions.setFormState({
        ...values,
        selectedLists: newIds,
        ids: [reportName],
        ids2: ['']
      })
    )
    // hide the accordion
    setCombineHidden(true)
  }

  const handleCancel = () => {
    // set ids2 to nothing
    dispatch(exportListActions.setFormState({ ...formState, ids2: [''] }))
    // hide accordion
    setCombineHidden(true)
  }

  return (
    <>
      <ListIds
        formName="export-list"
        name="ids"
        disabled={false}
        requestBody={{
          data: {
            filters: {
              workflow: 'ExportLists'
            },
            search: {
              perPage: 100
            }
          }
        }}
      />
      <DropdownInput
        className={styles.brandInput}
        name="brand"
        label="Brand"
        placeholder="Choose a Brand"
        options={brandOptions}
        disabled={false}
        required
      />
      {combineHidden && features.sections?.actions.includes('combine') && (
        <div className={styles.buttonsContainer}>
          <ButtonUI
            className={cx(styles.customButton, {
              disabled: combineButtonDisabled
            })}
            type="button"
            text="Combine"
            textCase="uppercase"
            disabled={combineButtonDisabled}
            onClick={() => handleCombineOpen()}
          />
        </div>
      )}
      {!combineHidden && (
        <div className={styles.combineContainer}>
          <ListIds
            formName="export-list"
            name="ids2"
            label={'Select a list to combine with'}
            disabled={combineHidden}
            multiple={false}
            required={false}
            requestBody={{
              data: {
                filters: {
                  workflow: 'ExportLists',
                  selectedLists: ids
                },
                search: {
                  perPage: 100
                }
              }
            }}
          />
          <Input
            label="Name your combined list"
            name="reportName"
            type="text"
            required={false}
            traditional
          />
          <div className={styles.buttonsRow}>
            <ButtonUI
              className={cx(styles.customButton, {
                disabled: combineButtonTwoDisabled,
                noBackground: true
              })}
              type="button"
              text="Combine"
              textCase="uppercase"
              disabled={combineButtonTwoDisabled}
              onClick={handleCombine}
            />
            <ButtonUI
              className={cx(styles.customButton, styles.noBackground)}
              type="button"
              text="Cancel"
              textCase="uppercase"
              onClick={handleCancel}
            />
          </div>
        </div>
      )}
      <CombinationTable
        name="combinations"
        formProps={formProps}
        workflow="ExportLists"
        // SetTargetButtonDisabled={SetTargetButtonDisabled}
        // combinationsFlag={values.combinations.length > 0}
      />
      {/* <TargetSpecialityTable
        formProps={formProps}
        targetButtonDisabled={targetButtonDisabled}
      /> */}
      <div className={styles.addCombination}>
        {/* <Button
          type="button"
          disabled={targetButtonDisabled}
          onClick={() => handleAddTargetSpeciality()}
        >
          Target Specialty
        </Button> */}
        {features.sections?.actions.includes('add-combination') && (
          <>
            <Button type="button" onClick={() => handleAddCombinations()}>
              Add Combination
            </Button>
            {/* <Button
              type="button"
              disabled={
                combinationButtonDisabled &&
                !(values.targetSpeciality.length === 0)
              }
              onClick={() => handleAddCombinations()}
            >
              Add Combination
            </Button> */}
          </>
        )}
      </div>
      {/* <FiltersComponent name="filters" /> */}
      <HopperExportForm formProps={formProps} />
    </>
  )
}

ExportListForm.propTypes = {
  /**
   * Form props
   */
  formProps: PropTypes.object,
  /**
   * Reset Form Func
   */
  handleReset: PropTypes.func
  // combinationButtonDisabled: PropTypes.bool,
  // SetCombinationButtonDisabled: PropTypes.func,
  // targetButtonDisabled: PropTypes.bool,
  // SetTargetButtonDisabled: PropTypes.func
}

export default ExportListForm
