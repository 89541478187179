import FileForm from './forms/FileForm'
import XlsxForm from './forms/XlsxForm'
import DataLocation from './forms/DataLocation'
import ProjectMetadata from 'modules/projectMetadata'
import ColumnHeaders from './forms/ColumnHeaders'
import MappingForm from './forms/MappingForm'

export const initialValues = {
  activeStep: 'file-upload',
  listId: '',

  file: '',
  isXls: false,

  xlsxSheet: '',
  headingRow: '',

  columnFirst: '',
  columnLast: '',
  rowFirst: '',
  rowLast: '',

  domain: '',
  clientProduct: '',
  brand: '',
  agency: '',
  client: '',
  masterProject: '',
  project: '',
  notes: '',
  defaultCountry: '',
  listMatching: true,
  targetListCreate: false,
  dedupe: false,
  postalList: false,
  targetListCreateListIds: [],
  noAssociatedListID: false,

  fullname: '',
  firstname: '',
  middlename: '',
  lastname: '',
  profession: '',
  specialty: '',
  nationalId1: '',
  nationalIdType1: '',
  nationalId2: '',
  nationalIdType2: '',
  partyId: '',
  hcpId: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  phonenumber: '',
  city: '',
  state: '',
  postalcode: '',
  country: '',

  thirdPartyID1: '',
  thirdPartyIDLabel1: '',
  thirdPartyID2: '',
  thirdPartyIDLabel2: '',
  thirdPartyID3: '',
  thirdPartyIDLabel3: '',
  thirdPartyID4: '',
  thirdPartyIDLabel4: '',
  thirdPartyID5: '',
  thirdPartyIDLabel5: '',
  thirdPartyID6: '',
  thirdPartyIDLabel6: '',
  thirdPartyID7: '',
  thirdPartyIDLabel7: '',
  thirdPartyID8: '',
  thirdPartyIDLabel8: '',
  thirdPartyID9: '',
  thirdPartyIDLabel9: '',
  thirdPartyID10: '',
  thirdPartyIDLabel10: '',

  priorEngagementID1: '',
  priorEngagementIDLabel1: '',
  priorEngagementID2: '',
  priorEngagementIDLabel2: '',
  priorEngagementID3: '',
  priorEngagementIDLabel3: '',
  priorEngagementID4: '',
  priorEngagementIDLabel4: '',
  priorEngagementID5: '',
  priorEngagementIDLabel5: '',
  priorEngagementID6: '',
  priorEngagementIDLabel6: '',
  priorEngagementID7: '',
  priorEngagementIDLabel7: '',
  priorEngagementID8: '',
  priorEngagementIDLabel8: '',
  priorEngagementID9: '',
  priorEngagementIDLabel9: '',
  priorEngagementID10: '',
  priorEngagementIDLabel10: '',

  value1: '',
  valueLabel1: '',
  value2: '',
  valueLabel2: '',
  value3: '',
  valueLabel3: '',
  value4: '',
  valueLabel4: '',
  value5: '',
  valueLabel5: '',
  value6: '',
  valueLabel6: '',
  value7: '',
  valueLabel7: '',
  value8: '',
  valueLabel8: '',
  value9: '',
  valueLabel9: '',
  value10: '',
  valueLabel10: '',

  segment1: '',
  segmentLabel1: '',
  segment2: '',
  segmentLabel2: '',
  segment3: '',
  segmentLabel3: '',
  segment4: '',
  segmentLabel4: '',
  segment5: '',
  segmentLabel5: '',
  segment6: '',
  segmentLabel6: '',
  segment7: '',
  segmentLabel7: '',
  segment8: '',
  segmentLabel8: '',
  segment9: '',
  segmentLabel9: '',
  segment10: '',
  segmentLabel10: ''
}

export const steps = {
  'file-upload': {
    component: <FileForm />
  },
  sheet: {
    component: <XlsxForm />
  },
  'data-location': {
    component: <DataLocation />
  },
  'project-metadata': {
    component: <ProjectMetadata isListIngestion formName="list-ingestion" />
  },
  'column-headers': {
    component: <ColumnHeaders />
  },
  'third-party-mapping': {
    component: <MappingForm name="thirdPartyID" label="Third Party ID" />
  },
  'prior-engagement-mapping': {
    component: (
      <MappingForm name="priorEngagementID" label="Prior Competitor ID" />
    )
  },
  'value-mapping': {
    component: <MappingForm name="value" label="Value" />
  },
  'segment-mapping': {
    component: <MappingForm name="segment" label="Segment" />
  }
}
