import { cloneElement } from 'react'
import PropTypes from 'prop-types'
import modernStyles from './SelectBaseModern.module.sass'
import traditionalStyles from './SelectBaseTraditional.module.sass'
import Spinner from '../Spinner'
import Ripple from '../Ripple'
import classNames from 'classnames/bind'
import FieldError from '../FieldError'
import { propWarning } from '../utils'

const SelectBase = ({
  id,
  isFocused,
  altTheme,
  label,
  className,
  loading,
  secondaryLoading,
  traditional,
  labelTooltip,
  required,
  error,
  errorAlign,
  disabled,
  meta: { touched, error: metaError },
  inlineError,
  button,
  readOnly,
  rightElements,
  children,
  hasValue,
  handleFocus,
  onClick = () => {},
  ...other
}) => {
  const styles = traditional ? traditionalStyles : modernStyles
  const themeStyles = { ...styles, ...altTheme }
  const cx = classNames.bind(themeStyles)
  const ParentTag = traditional || disabled || readOnly ? 'div' : Ripple
  const EnhancedLabelTooltip =
    labelTooltip &&
    cloneElement(labelTooltip, {
      className: cx(labelTooltip.props.className, themeStyles.tooltipButton),
      id: `${id}-tooltip`
    })
  const childrenWithProps = cloneElement(children, {
    ...other
  })
  return (
    <div
      className={cx(themeStyles.container, className)}
      style={{ position: 'relative' }}
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') onClick()
      }}
    >
      {label ? (
        <label
          onClick={handleFocus}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') handleFocus()
          }}
          htmlFor={id}
          className={cx(themeStyles.label, {
            focused: isFocused,
            active: hasValue || inlineError,
            disabled,
            labelReadOnly: readOnly
          })}
        >
          {label}
          {required ? <span className={themeStyles.required}> *</span> : null}
          {EnhancedLabelTooltip}
        </label>
      ) : null}
      <div
        className={cx(themeStyles.selectMenuContainer, {
          isFocused,
          readOnly,
          disabled,
          hasError: error || inlineError
        })}
      >
        <ParentTag className={themeStyles.select}>
          {childrenWithProps}
        </ParentTag>
        {secondaryLoading && isFocused && (
          <div className={themeStyles.secondarySpinner}>
            <Spinner fillColor="transparent" strokeColor="#cccccc" size={24} />
          </div>
        )}
        {!secondaryLoading && !loading && (rightElements || button) && (
          <div className={themeStyles.rightElements}>
            {rightElements}
            {button}
          </div>
        )}
      </div>
      {loading && isFocused && (
        <div className={themeStyles.selectLoadingContainer}>
          <div className={themeStyles.selectLoadingList}>
            <div className={themeStyles.selectLoadingText}>
              {'Options loading'}
            </div>
            <Spinner size={60} fillColor="transparent" strokeColor="#cccccc" />
          </div>
        </div>
      )}
      {(touched && metaError) || error || inlineError ? (
        <FieldError
          className={cx(themeStyles.errorMessage, { inlineError })}
          styles={{ width: '100%' }}
          align={errorAlign}
        >
          {metaError || error || inlineError}
        </FieldError>
      ) : null}
    </div>
  )
}

SelectBase.displayName = 'SelectBase'
SelectBase.defaultProps = {
  meta: {}
}
SelectBase.propTypes = {
  /**
   * @deprecated in version 2.7
   *
   * An optional button element to be contained within the component
   * This is deprecated in favor of `rightElements` prop which can contain multiple elements
   */
  button: propWarning(
    'Deprecated prop: This prop will be removed in version 3. Use `rightElements` prop instead.'
  ),
  /**
   * An optional node of elements to be contained within the SelectMenu component
   */
  rightElements: PropTypes.node,
  /**
   * The text used for the label element
   */
  label: PropTypes.string,
  /**
   * Should the selectmenu use the traditional or material design style
   */
  traditional: PropTypes.bool,
  /**
   * Is the field required for form submission
   */
  required: PropTypes.bool,
  /**
   * The input ID
   */
  id: PropTypes.string,
  /**
   * Metadata about the state of this field (if using redux-form)
   */
  meta: PropTypes.object,
  /**
   * If `true`, the input is read-only
   */
  readOnly: PropTypes.bool,
  /**
   * An additional custom className
   */
  className: PropTypes.string,
  /**
   * Whether or not the options are loading (use to indicate initial options load)
   */
  loading: PropTypes.bool,
  /**
   * A Tooltip component used for the label
   */
  labelTooltip: PropTypes.node,
  /**
   * Whether or not the options are loading (use to indicate repeated, dynamic options loading)
   */
  secondaryLoading: PropTypes.bool,
  /**
   * Whether or not the selectmenu is disabled
   */
  disabled: PropTypes.bool,
  /**
   * The error shown below the selectmenu (not used if there is a redux-form style 'meta.error' value)
   */
  error: PropTypes.node,
  /**
   * The alignment of the error
   */
  errorAlign: PropTypes.string,
  /**
   * A CSS modules style object to override default theme
   */
  altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  /**
   * show error message in input area and the duration for it to be dismissed
   */
  inlineError: PropTypes.string,
  /**
   * Focuses the field
   */
  handleFocus: PropTypes.func,
  /**
   * If `true`, the component indicates focus
   */
  isFocused: PropTypes.bool,
  /**
   * The component children
   */
  children: PropTypes.node,
  /**
   * If `true`, the component renders to accommodate a value
   */
  hasValue: PropTypes.bool,
  /**
   * Handles container click
   */
  onClick: PropTypes.func
}
export default SelectBase
