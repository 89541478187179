import { isEmpty } from 'utils'
import { smartsEndpoints } from '../constants'

const UsersCreateService = SmartsML => ({
  async getPossibleRoles() {
    const response = await SmartsML.fetch(
      smartsEndpoints.smartsRoles,
      'GET',
      {}
    )

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },

  async getPossibleCountries(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.variationCountries,
      'POST',
      { ...data }
    )

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },

  async putNewUser(values) {
    const response = await SmartsML.fetch(
      smartsEndpoints.smartsUsers,
      'PUT',
      values
    )

    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },

  async fetchUser(uuid) {
    const response = await SmartsML.fetch(smartsEndpoints.smartsUsers, 'POST', {
      uuid
    })

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  }
})

export default UsersCreateService
