import { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import Ripple from '../Ripple'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'
import traditionalStyles from './AutoSelectTraditional.module.sass'
import modernStyles from './AutoSelectModern.module.sass'
import classNames from 'classnames/bind'

class AutoSelect extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onSuggestionSelected: PropTypes.func,
    onSuggestionHighlighted: PropTypes.func,
    name: PropTypes.string,
    /**
     * A CSS modules style object to override default theme
     */
    altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    loading: PropTypes.bool,
    traditional: PropTypes.bool,
    disabled: PropTypes.bool,
    focused: PropTypes.bool,
    error: PropTypes.bool,
    onClick: PropTypes.func,
    /**
     * If `true`, this field shows a triangle icon to indicate dropdown options
     */
    showIcon: PropTypes.bool,
    /**
     * If `true`, the secondary loading indication is shown
     * (use for dynamic option loading to show loading status without removing existing options)
     */
    secondaryLoading: PropTypes.bool,
    onKeyDown: PropTypes.func,
    /**
     * The number of suggestions that will be shown by Autoselect at one time. Defaults to 0
     */
    suggestionsLimit: PropTypes.number,
    /**
     * The connected input props (if using redux-form)
     */
    input: PropTypes.object,
    /**
     * An additional custom className for the input
     */
    inputClassName: PropTypes.string,
    /**
     * If `true`, the input is read-only
     */
    readOnly: PropTypes.bool,
    /**
     * If `true`, show the options extending up from the top of the field
     */
    optionsAbove: PropTypes.bool,
    /**
     * Returns all value matches from options
     */
    findMatches: PropTypes.func,
    /**
     * The number of characters that can be entered before the maxlength indication is displayed
     */
    maxLength: PropTypes.number,
    /**
     * If `true`, the text exceeding the maxlength is highlighted
     */
    maxLengthHighlight: PropTypes.bool,
    /**
     * An object with key and a value that is the label to be shown for each persistent option
     */
    persistentOptions: PropTypes.objectOf(PropTypes.string),
    /**
     * onPersistentOptionClick(event, { suggestion: { key, label } })
     *
     * Called when a persistent option is selected
     * (returns the event and the suggestion object with label and key values)
     */
    onPersistentOptionClick: PropTypes.func,
    /**
     * suggestionExtraElement({ suggestion: { key, label } })
     *
     * Callback to render extra element in the suggestion element
     */
    suggestionExtraElement: PropTypes.func
  }
  static displayName = 'AutoSelect'

  static getDerivedStateFromProps(
    { error: nextError, focused, name },
    { error: prevError, focused: prevFocused }
  ) {
    let newState = null
    if ((!prevFocused && focused) || (prevFocused && !focused)) {
      // Regenerate key on focus change - will trigger autofocus on input
      newState = {
        key: `${name}-focused-${focused}`,
        prevFocused,
        focused
      }
    }
    if (nextError !== undefined && prevError !== nextError) {
      newState = {
        ...newState,
        error: nextError,
        prevError
      }
    }
    return newState
  }

  static defaultProps = {
    suggestionsLimit: 0,
    focused: false,
    onPersistentOptionClick: () => {}
  }

  state = {
    suggestions: [],
    localFocused: false,
    showSuggestionContainer: false
  }
  isReopening = false
  shouldSelectTextOnFocus = true
  autosuggestRef = createRef()
  maxLengthHighlightRef = createRef()
  onChange = (event, { newValue, method }) => {
    const { maxLength, maxLengthHighlight } = this.props
    if (maxLength && !maxLengthHighlight) {
      if (newValue.length > maxLength) {
        newValue = newValue.substr(0, maxLength)
      }
    }
    if (this.props.onChange) {
      this.props.onChange(event, { newValue, method })
    }
    this.setState({ showSuggestionContainer: true })
  }
  onSuggestionHighlighted = suggestion => {
    if (this.props.onSuggestionHighlighted) {
      this.props.onSuggestionHighlighted(suggestion)
    }
  }
  // Stop mousedown propagation which incorrectly shows selectmenu input's ripple animation when options are clicked
  stopMouseDown(event) {
    event.stopPropagation()
  }

  handleOnPersistentOptionClick = (e, { key, label }) => {
    const { onPersistentOptionClick } = this.props
    this.onSuggestionsClearRequested()
    this.setState({ showSuggestionContainer: false })
    onPersistentOptionClick(e, { suggestion: { key, label } })
  }

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    const { altTheme, traditional, persistentOptions } = this.props
    const styles = traditional ? traditionalStyles : modernStyles
    const themeStyles = { ...styles, ...altTheme }
    const cx = classNames.bind(themeStyles)
    return (
      <div
        {...containerProps}
        className={cx(containerProps.className, {
          suggestionsContainerOpen: this.state.showSuggestionContainer
        })}
        onMouseDown={this.stopMouseDown}
      >
        {children}
        {persistentOptions && (
          <ul className={themeStyles.persistentContainer}>
            {Object.keys(persistentOptions).map((key, index) => (
              <li key={index} className={themeStyles.persistentItem}>
                <Ripple
                  className={themeStyles.suggestion}
                  onClick={e =>
                    this.handleOnPersistentOptionClick(e, {
                      key,
                      label: persistentOptions[key]
                    })
                  }
                >
                  <span>{persistentOptions[key]}</span>
                </Ripple>
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }
  matchIndices(source, find) {
    let result = []
    for (let i = 0; i < source.length; ++i) {
      if (
        source.substring(i, i + find.length).toLowerCase() ===
        find.toLowerCase()
      ) {
        result.push(i)
      }
    }
    return result
  }
  getMatches(text, query) {
    let matchedResult = []
    if (query !== '') {
      const foundAt = this.matchIndices(text, query)
      const matches = foundAt.map(index => {
        return [index, index + query.length]
      })
      matchedResult = AutosuggestHighlightParse(text, matches)
    } else {
      matchedResult.push({ text })
    }
    return matchedResult
  }
  renderSuggestion = (suggestion, { query }) => {
    const parts = this.getMatches(suggestion.label, query)
    const { altTheme, traditional, suggestionExtraElement } = this.props
    const styles = traditional ? traditionalStyles : modernStyles
    const themeStyles = { ...styles, ...altTheme }
    const { supplement } = suggestion
    const result = (
      <Ripple className={themeStyles.suggestion}>
        {parts.map((part, index) => {
          const className = part.highlight ? themeStyles.suggestionMatch : null
          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          )
        })}
        {supplement && (
          <span className={themeStyles.supplement}>{` ${supplement}`}</span>
        )}
        {suggestionExtraElement && suggestionExtraElement(suggestion)}
      </Ripple>
    )
    return result
  }

  renderInputComponent = props => {
    const { maxLength, maxLengthHighlight, traditional, altTheme } = this.props
    const styles = traditional ? traditionalStyles : modernStyles
    const themeStyles = { ...styles, ...altTheme }
    return (
      <>
        <input {...props} />
        {maxLengthHighlight && maxLength > 0 && (
          <div
            ref={this.maxLengthHighlightRef}
            className={themeStyles.maxLength}
          >
            {this.highlightArea()}
          </div>
        )}
      </>
    )
  }

  reopen = () => {
    this.isReopening = true
    this.blurInput()
    this.focusInput()
    this.isReopening = false
  }

  blurInput = () => this.autosuggestRef.current.input.blur()

  focusInput = () => this.autosuggestRef.current.input.focus()

  onBlur = event => {
    this.setState({ localFocused: false, showSuggestionContainer: false })
    if (this.props.onBlur) {
      this.props.onBlur(event)
    }
    // Update highlight scroll
    if (this.maxLengthHighlightRef.current) {
      this.maxLengthHighlightRef.current.scrollLeft = 0
    }
  }
  onFocus = event => {
    this.shouldSelectTextOnFocus = true
    if (this.isReopening) {
      this.shouldSelectTextOnFocus = false
    }
    // this.isReopening = false
    this.setState({ localFocused: true, showSuggestionContainer: true })
    if (this.props.onFocus) {
      this.props.onFocus(event)
    }
  }
  onKeyDown = event => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event)
    }
  }

  shouldRenderSuggestions() {
    return true
  }
  shouldNotRenderSuggestions() {
    return false
  }

  getSuggestions = value => {
    const { suggestionsLimit, findMatches } = this.props
    const suggestions = findMatches(value)
    return suggestionsLimit
      ? suggestions.slice(0, suggestionsLimit)
      : suggestions
  }

  getSuggestionValue(suggestion) {
    return suggestion.label
  }

  onSuggestionsFetchRequested = ({ value, reason }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    })
    if (reason === 'input-focused') {
      this.resetSuggestions(value)
    }
  }
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  onSuggestionSelected = (event, { suggestion }) => {
    const { onSuggestionSelected } = this.props
    this.setState({ showSuggestionContainer: false })
    onSuggestionSelected(event, { suggestion })
  }
  inputClick = event => {
    const { onClick } = this.props
    this.resetSuggestions(event.target.value)
    if (onClick !== undefined) {
      onClick(event)
    }
  }
  resetSuggestions = value => {
    const suggestions = this.getSuggestions(value)
    this.setState({ suggestions })
  }
  highlightArea = () => {
    const { maxLength, label } = this.props
    const actualValue = label || ''
    const insideLength = actualValue.slice(0, maxLength)
    const outsideLength = actualValue.slice(maxLength)
    return (
      <>
        {insideLength}
        <span>{outsideLength}</span>
      </>
    )
  }
  handleHighlightScroll = () => {
    if (this.maxLengthHighlightRef.current) {
      this.maxLengthHighlightRef.current.scrollLeft =
        this.autosuggestRef.current.input.scrollLeft
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { localFocused } = this.state
    const { localFocused: wasFocused } = prevState
    if (!wasFocused && localFocused && this.shouldSelectTextOnFocus) {
      // We select the previous value on focus so any keystroke will replace it
      // However, in safari (iOS and desktop), click is moving the insert point afterward - reverting the text selection
      // Make sure text selection is done after click with timeout
      this.selectTimeout = setTimeout(() => {
        this.autosuggestRef.current.input.setSelectionRange(
          0,
          this.autosuggestRef.current.input.value.length
        )
      }, 10)
    }
  }
  componentWillUnmount = () => {
    clearTimeout(this.selectTimeout)
  }
  preventContextMenu(event) {
    event.preventDefault()
    return false
  }
  render = () => {
    const { suggestions, error, key, focused } = this.state
    const {
      placeholder,
      name,
      input,
      value,
      label,
      loading,
      traditional,
      disabled,
      onFocus,
      altTheme,
      showIcon,
      secondaryLoading,
      optionsAbove,
      inputClassName,
      readOnly,
      maxLength,
      maxLengthHighlight,
      persistentOptions,
      onSuggestionSelected,
      onSuggestionHighlighted,
      findMatches,
      onKeyDown,
      error: ignore5,
      suggestionsLimit,
      focused: ignore7,
      onPersistentOptionClick,
      suggestionExtraElement,
      ...other
    } = this.props
    const styles = traditional ? traditionalStyles : modernStyles
    const themeStyles = { ...styles, ...altTheme }
    const cx = classNames.bind(themeStyles)
    const inputProps = {
      ...other,
      ...input,
      className: cx(themeStyles.select, inputClassName, {
        inputError: error
      }),
      placeholder,
      value: label || value,
      maxLength: !maxLengthHighlight && maxLength,
      onKeyDown: this.onKeyDown,
      onClick: this.inputClick,
      onBlur: this.onBlur,
      onFocus: this.onFocus,
      onChange: this.onChange,
      onScroll: this.handleHighlightScroll,
      onKeyPress: this.handleHighlightScroll,
      onSelect: this.handleHighlightScroll,
      disabled,
      name,
      // Prevent the context menu showing on refocus due to text selection and 2nd input click
      // (seen in chrome mobile emulation)
      onContextMenu: this.preventContextMenu,
      id: name,
      autoFocus: focused,
      readOnly
    }
    const theme = { ...themeStyles }
    theme.container = cx(themeStyles.container, {
      disabled,
      readOnly,
      containerNoIcon: !showIcon || secondaryLoading,
      suggestionsContainerLoading: loading
    })
    theme.item = persistentOptions ? themeStyles.dynamicItem : themeStyles.item
    theme.suggestionsContainer = optionsAbove
      ? themeStyles.suggestionsContainerAbove
      : themeStyles.suggestionsContainer
    return (
      <Autosuggest
        ref={this.autosuggestRef}
        key={key}
        theme={theme}
        suggestions={suggestions}
        onSuggestionSelected={this.onSuggestionSelected}
        onSuggestionHighlighted={this.onSuggestionHighlighted}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderInputComponent={this.renderInputComponent}
        inputProps={inputProps}
        shouldRenderSuggestions={
          readOnly
            ? this.shouldNotRenderSuggestions
            : this.shouldRenderSuggestions
        }
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        highlightFirstSuggestion
      />
    )
  }
}

export default AutoSelect
