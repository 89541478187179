import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { ShareIcon } from 'static/icons'
import Typography from 'components/ui/Typography'
import { styled } from '@mui/material/styles'

import cx from 'classnames'
import styles from './HelpfulMessage.module.sass'
import { useState } from 'react'

/**
 * Share Button
 */
const ShareButton = styled('div')(() => ({
  display: 'flex',
  marginRight: '5px',
  cursor: 'pointer',
  alignItems: 'center'
}))

/**
 * Action SVG
 */
const ActionSVG = ({ icon, ...props }) => {
  const Icon = styled(icon)(() => ({
    stroke: '#363f72',
    fill: 'none',
    width: '16px',
    height: '16px',
    marginTop: '3px',
    marginRight: '3px'
  }))
  return <Icon {...props} />
}
ActionSVG.propTypes = {
  /**
   * Icon component to be rendered
   */
  icon: PropTypes.func
}

/**
 * Action Bar Text
 */
const ActionLabel = styled(Typography)(() => ({
  paddingTop: '3px',
  fontSize: '12px',
  fontWeight: 'bold',
  color: '#363f72'
}))

const HelpfulMessage = ({
  onAction = () => {},
  promt = '',
  promptId,
  answerId
}) => {
  const { t } = useTranslation()
  const [liked, setLiked] = useState(0)

  const handleAction = action => {
    if (action === 'share') {
      onAction({ action, promt, promptId, answerId })
      return
    }
    if (action === 'like') {
      if (liked === 1) {
        setLiked(0)
        onAction({ action: 'like', promptId, answerId, undo: true })
      } else {
        setLiked(1)
        onAction({ action: 'like', promptId, answerId, undo: false })
      }
    } else if (liked === -1) {
      setLiked(0)
      onAction({ action: 'dislike', promptId, answerId, undo: true })
    } else {
      setLiked(-1)
      onAction({ action: 'dislike', promptId, answerId, undo: false })
    }
  }

  return (
    <div className={cx(styles.container)}>
      <Typography type="caption" className={styles.text} align="left">
        <span>
          {t('isThisAnswerText')}
          <button
            data-testid="helpful-yes"
            className={styles.answerButton}
            onClick={() => handleAction('like')}
          >
            Yes
          </button>
          <button
            data-testid="helpful-no"
            className={styles.answerButton}
            onClick={() => handleAction('dislike')}
          >
            No
          </button>
        </span>
        <span>
          <ShareButton
            onClick={() => handleAction('share')}
            data-testid="share"
          >
            <ActionSVG icon={ShareIcon} />
            <ActionLabel sx={{ fontFamily: 'Inter' }}>
              {t('socialElementShareText')}
            </ActionLabel>
          </ShareButton>
        </span>
      </Typography>
    </div>
  )
}

HelpfulMessage.propTypes = {
  onAction: PropTypes.func,
  promt: PropTypes.string,
  promptId: PropTypes.string,
  answerId: PropTypes.string
}

export default HelpfulMessage
