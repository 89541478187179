import { createSelector } from '@reduxjs/toolkit'

export const typeaheadSelector = state => state.typeaheadReducer

export const getOptions = key =>
  createSelector(
    typeaheadSelector,
    typeahead => typeahead.typeaheads[key]?.options
  )
export const getFetching = key =>
  createSelector(
    typeaheadSelector,
    typeahead => typeahead.typeaheads[key]?.fetching
  )
export const getTooltips = key =>
  createSelector(
    typeaheadSelector,
    typeahead => typeahead.typeaheads[key]?.tooltips
  )
export const getPagination = key =>
  createSelector(
    typeaheadSelector,
    typeahead => typeahead.typeaheads[key]?.pagination || {}
  )
