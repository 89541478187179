import ChartGeneration from './forms/ChartGeneration'
import ShareResults from './forms/ShareResult'
// import { useSelector } from 'react-redux'
// import { getActiveStep } from './ChartsSelector'
import PropTypes from 'prop-types'
// import { Typography } from '@psl/ui/build/es'
// import styles from './Charts.module.sass'
// import Button from 'components/Button'
// import { isEmpty } from 'utils'

const Charts = ({ formProps, handleDownload, chartsData, ...props }) => {
  return (
    <ChartGeneration
      chartsData={chartsData}
      formProps={formProps}
      handleDownload={handleDownload}
      {...props}
    />
  )
}

Charts.propTypes = {
  /**
   * formProps
   */
  formProps: PropTypes.object,
  handleReset: PropTypes.func,
  chartsData: PropTypes.object,
  /**
   * Function to handle the reset button for when this module loads
   * inside Compare Lists
   */
  handleDownload: PropTypes.func
}

const ShareResultsPage = ({ formProps }) => {
  return <ShareResults formProps={formProps} />
}

ShareResultsPage.propTypes = {
  /**
   * formProps
   */
  formProps: PropTypes.object
}

export default Charts
export { ShareResultsPage }
