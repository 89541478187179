/**
 * Color palette obtained from: https://app.zeplin.io/project/61fbe7b1a295a676007ddbe8/styleguide/colors?seid=61fbe7b1a295a676007ddbe8
 * @type {{lightgreyblue: string, purplishgrey: string, darkgreyblue: string, marineblue: string, whiteish: string, black: string, browngrey2: string, greyishbrown: string, browngrey3: string, paleblue: string, error: string, browngrey: string, windowsblue: string, verylightpink50: string, brownishgrey: string, brownishgrey2: string, blue: string, white: string, darkGrey: string, darkslateblue2: string, contrastText: string, buttonFocus: string, darkslateblue1: string}}
 */
const palette = {
  black: '#000',
  darkslateblue1: '#1f3661',
  darkslateblue2: '#183564',
  marineblue: '#004765',
  blue: '#1055cc',
  skyblue: '#95bfe9',
  darkgreyblue: '#2b426a',
  windowsblue: '#319aca',
  navyblue: '#293056',
  paleblue: '#e5e6e8',
  darkGrey: '#333',
  grey: '#cececd',
  whiteGrey: '#999999',
  lightGrey: '#ebebeb',
  greydark: '#252525',
  greyishbrown: '#595959',
  greyishblue: '#B9B9BB',
  greyishpink: '#efefef',
  purplishgrey: '#757475',
  brownishgrey: '#5a5a5a',
  brownishgrey2: '#666',
  browngrey: '#979797',
  browngrey2: '#9b9b9b',
  browngrey3: '#a0a0a0',
  verylightpink50: 'rgba(239, 239, 239, 0.5)',
  whiteish: '#f4f4f4',
  whiteishGrey: '#d8d8d8',
  white: '#fff',
  error: '#be1e2d',
  contrastText: '#fff',
  buttonFocus: '#222848',
  menuTextHover: '#0164b0'
}
/**
 * Font family overrides for DG
 * https://app.zeplin.io/project/61fbe7b1a295a676007ddbe8/styleguide/textstyles
 * @type {{fontTertiary: string, fontSecondary: string, fontPrimary: string}}
 */
const fontFamily = {
  fontPrimary: ['"Inter"', 'Arial', 'sans-serif'].join(','),
  fontSecondary: ['Imperial', 'Georgia', 'serif'].join(','),
  fontTertiary: 'arial'
}
/**
 * MUI Default theme overrides for DG brand
 * https://mui.com/material-ui/customization/default-theme/
 * @type {{typography: {fontFamily: string}, components: {MuiButton: {styleOverrides: {root: {"&:focus": {boxShadow: string, backgroundColor: string}, "&:hover": {boxShadow: string, backgroundColor: string}, "&:active": {boxShadow: string, backgroundColor: string}}}}}, palette: {primary: {contrastText: string, main: string}}}}
 */
const dougallgpt = {
  palette: {
    primary: {
      main: palette.navyblue,
      contrastText: palette.contrastText
    },
    error: { main: palette.error },
    header: {
      main: palette.white,
      menuIcon: palette.browngrey3
    },
    menu: {
      main: palette.black,
      topText: palette.skyblue,
      divider: palette.lightGrey,
      selected: palette.lightblue,
      searchInput: palette.whiteGrey,
      searchIcon: palette.brownishgrey2,
      searchText: palette.brownishgrey2,
      background: palette.white,
      textHover: palette.menuTextHover
    },
    divider: { main: palette.grey },
    sharerModal: { divider: palette.whiteishGrey },
    search: { divider: palette.white },
    comments: {
      divider: palette.black,
      button: palette.contrastText,
      inputBorder: palette.paleblue,
      inputBackground: palette.greyishpink
    },
    modal: {
      content: palette.greyishbrown,
      icons: palette.browngrey2
    }
  },
  typography: {
    fontFamily: fontFamily.fontPrimary
  },
  components: {
    //  Name of the component
    //  https://mui.com/material-ui/api/button/#theme-default-props
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          '&:focus': {
            boxShadow:
              '0 2px 4px rgba(0, 0, 0, 0.24), 0 -2px 2px rgba(0, 0, 0, 0.05)',
            backgroundColor: palette.buttonFocus
          },
          '&:hover': {
            boxShadow:
              '0 2px 4px rgba(0, 0, 0, 0.24), 0 -2px 2px rgba(0, 0, 0, 0.05)',
            backgroundColor: palette.buttonFocus
          },
          '&:active': {
            boxShadow:
              '0 2px 4px rgba(0, 0, 0, 0.24), 0 -2px 2px rgba(0, 0, 0, 0.05)',
            backgroundColor: palette.navyblue
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '60px',
          alignSelf: 'center',
          boxShadow: 'none',
          backgroundColor: palette.white,
          paddingLeft: '45px',
          paddingRight: '21px',
          '@media (max-width: 1023px)': {
            height: '52px'
          },
          '& .brandContainer': {
            justifyContent: 'center'
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          width: '100%',
          paddingLeft: '0 !important',
          paddingRight: '0 !important'
        }
      }
    },
    MuiPaper: {
      styleOverrides: { root: { backgroundColor: palette.white } }
    },
    Menu: {
      styleOverrides: {
        container: {
          [`& .MuiDrawer-paper`]: {
            width: '240px',
            marginTop: '57px',
            boxShadow: '2px 2px 10px 2px hsla(240,1%,73%,.5)!important',
            border: '1px solid #d6d6d6',
            borderBottom: '0',
            borderTop: '0',
            '@media (min-width: 821px) and (max-width: 1023px)': {
              marginTop: '72px'
            },
            '@media (min-width: 1024px)': {
              marginTop: '80px'
            }
          }
        },
        inputContainer: {
          margin: '20px 0px 0px 20px',
          paddingBottom: '5px'
        },
        searchInput: {
          margin: '0px 20px 0px 0px',
          borderRadius: '3px',
          height: '42px'
        },
        divider: {
          color: palette.lightGrey
        },
        listItem: {
          padding: '8px'
        }
      }
    },
    Header: {
      styleOverrides: {
        logo: {
          height: '40px'
        },
        title: {
          color: palette.darkslateblue2,
          fontFamily: 'Inter',
          fontSize: '20px',
          marginLeft: '19px'
        }
      }
    },
    Modal: {
      styleOverrides: {
        title: {
          fontSize: '28px',
          fontWeight: 'normal',
          color: palette.greyishbrown,
          padding: '20px 20px 5px 20px',
          fontFamily: fontFamily.fontPrimary
        },
        divider: {}
      }
    },
    Footer: {
      styleOverrides: {
        container: {
          marginTop: '0px',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: palette.white
        },
        row: {
          display: 'flex',
          alignItems: 'baseline',
          margin: '0px 5px 4px'
        },
        link: {
          '@media all and (max-width: 550px)': {
            margin: '5px 0px'
          }
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 360,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  zIndex: { appBar: 2, drawer: 4 }
}
export default dougallgpt
