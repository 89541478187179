import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as authActions } from 'modules/auth/AuthReducer'
import SmartsMLError from './SmartsMLError'
import { parseJwt } from 'utils'

const SmartsMLInterceptors = (dispatch, CookieService, AuthService) => {
  return {
    validateCacheTokens: async config => {
      let newConfig = { ...config }
      const controller = new AbortController()

      const accessToken = CookieService.getFromCache('access', '')
      const refreshToken = CookieService.getFromCache('refresh', '')

      if (!accessToken || !refreshToken) {
        // No valid tokens, execute soft logout. abort endpoint request
        newConfig = {
          ...config,
          signal: controller.signal
        }
        dispatch(authActions.softlogoutRequest())
        controller.abort()
        return newConfig
      }

      const { exp: accessExpiration } = parseJwt(accessToken)
      const { exp: refreshExpiration } = parseJwt(refreshToken)
      // Validate token expiration before any request
      if (accessExpiration && (accessExpiration - 60) * 1000 < Date.now()) {
        // Acces Token Expired, validate refresh token expiration
        if (refreshExpiration && refreshExpiration * 1000 > Date.now()) {
          // Execute refresh logic
          const verifyResponse = await AuthService.refreshToken(refreshToken)
          // Update Authorization header
          newConfig = {
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${verifyResponse.access}`
            }
          }
        } else {
          // No valid refresh token, execute soft logout. abort endpoint request
          newConfig = {
            ...config,
            signal: controller.signal
          }
          dispatch(authActions.softlogoutRequest())
          controller.abort()
          return newConfig
        }
      }

      return newConfig
    },
    catchNetworkErrors: async error => {
      if (error.code === 'ERR_CANCELED') return
      if (!error.response) {
        console.error('The MiddleLayer is unreachable.', error)
        const errorMessage =
          'Something went wrong! Please refresh the page and try again.'
        dispatch(
          notificationActions.notificationEnqueue({
            message: errorMessage
          })
        )
        throw new Error(errorMessage)
      }
      throw error
    },
    detectMLfailuresOnFail: async error => {
      if (error.code === 'ERR_CANCELED') return
      if (
        error.response?.data?.message &&
        error.response.data.status !== 'OK'
      ) {
        const { data: responseError } = error.response
        const err = new SmartsMLError(responseError.message, responseError)
        throw err
      }
      throw error
    }
  }
}

export default SmartsMLInterceptors
