import { createReducer, createAction } from '@reduxjs/toolkit'

export const rebuildRequest = createAction('INCOMING_REBUILD_REQUEST')
export const rebuildRequestSuccess = createAction(
  'INCOMING_REBUILD_REQUEST_SUCCESS'
)
export const rebuildRequestFail = createAction('INCOMING_REBUILD_REQUEST_FAIL')

export const deleteRequest = createAction('INCOMING_DELETE_REQUEST')
export const deleteRequestSuccess = createAction(
  'INCOMING_DELETE_REQUEST_SUCCESS'
)
export const deleteRequestFail = createAction('INCOMING_DELETE_REQUEST_FAIL')

export const searchRequest = createAction('INCOMING_SEARCH_REQUEST')
export const searchRequestSuccess = createAction(
  'INCOMING_SEARCH_REQUEST_SUCCESS'
)
export const searchRequestFail = createAction('INCOMING_SEARCH_REQUEST_FAIL')

export const searchSpecialtiesRequest = createAction(
  'INCOMING_SPECIALTIES_REQUEST'
)
export const searchSpecialtiesSuccess = createAction(
  'INCOMING_SPECIALTIES_SUCCESS'
)
export const searchSpecialtiesFail = createAction('INCOMING_SPECIALTIES_FAIL')

export const searchProfessionsRequest = createAction(
  'INCOMING_PROFESSIONS_REQUEST'
)
export const searchProfessionsSuccess = createAction(
  'INCOMING_PROFESSIONS_SUCCESS'
)
export const searchProfessionsFail = createAction('INCOMING_PROFESSIONS_FAIL')

export const actions = {
  rebuildRequest,
  rebuildRequestSuccess,
  rebuildRequestFail,
  deleteRequest,
  deleteRequestSuccess,
  deleteRequestFail,
  searchRequest,
  searchRequestSuccess,
  searchRequestFail,
  searchSpecialtiesRequest,
  searchSpecialtiesSuccess,
  searchSpecialtiesFail,
  searchProfessionsRequest,
  searchProfessionsSuccess,
  searchProfessionsFail
}

const incomingReducer = createReducer(
  {
    searchState: {
      country: '',
      profession: '',
      specialty: '',
      orderBy: '',
      order: '',
      page: 1,
      perPage: 25
    },
    fetching: false,
    type: '',
    counts: [],
    pagination: {
      total: 0,
      filtered: 0,
      maxPage: 0,
      results: 0,
      page: 1,
      perPage: 25
    },
    searchFetching: false,
    fetchingSpecialties: false,
    specialties: {},
    fetchingProfessions: false,
    professions: {}
  },
  {
    [rebuildRequest]: state => {
      state.fetching = true
      state.type = 'rebuild'
    },
    [rebuildRequestSuccess]: state => {
      state.fetching = false
      state.type = ''
    },
    [rebuildRequestFail]: state => {
      state.fetching = false
      state.type = ''
    },
    [deleteRequest]: state => {
      state.fetching = true
      state.type = 'delete'
    },
    [deleteRequestSuccess]: state => {
      state.fetching = false
      state.type = ''
    },
    [deleteRequestFail]: state => {
      state.fetching = false
      state.type = ''
    },
    [searchRequest]: (state, { payload }) => {
      state.searchFetching = true
      state.searchState = payload.search || state.searchState
    },
    [searchRequestSuccess]: (state, { payload }) => {
      state.searchFetching = false
      state.counts = payload.counts
      state.pagination = payload.pagination
    },
    [searchRequestFail]: state => {
      state.searchFetching = false
      state.counts = [...state.counts]
      state.pagination = { ...state.pagination }
    },
    [searchSpecialtiesRequest]: state => {
      state.fetchingSpecialties = true
    },
    [searchSpecialtiesSuccess]: (state, { payload }) => {
      state.fetchingSpecialties = false
      state.specialties = { ...payload }
    },
    [searchSpecialtiesFail]: state => {
      state.fetchingSpecialties = false
      state.specialties = {}
    },
    [searchProfessionsRequest]: state => {
      state.fetchingProfessions = true
    },
    [searchProfessionsSuccess]: (state, { payload }) => {
      state.fetchingProfessions = false
      state.professions = { ...payload }
    },
    [searchProfessionsFail]: state => {
      state.fetchingProfessions = false
      state.professions = {}
    }
  }
)

export default incomingReducer
