import React from 'react'
import Page from 'components/Page'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { initialValues, steps } from './config'
import { isEmpty } from 'utils'
import { actions as exportListActions } from './ExportListReducer'
import * as exportListSelectors from './ExportListSelector'
import Button from 'components/Button'
import { Checkbox } from 'components/Checkbox'
import styles from './ExportList.module.sass'
import useRoleFeatures from 'modules/auth/useRoleFeatures'
import validationSchema from './validationSchema'

const { getFormState, getFetchStatus } = exportListSelectors

const ExportList = () => {
  let initialForm = {
    ...initialValues
  }
  const dispatch = useDispatch()
  const history = useHistory()
  const formState = useSelector(getFormState())
  const isFetching = useSelector(getFetchStatus())
  const { features } = useRoleFeatures({
    coin: 'export-list'
  })
  // const [combinationButtonDisabled, SetCombinationButtonDisabled] =
  //   useState(false)
  // const [targetButtonDisabled, SetTargetButtonDisabled] = useState(true)
  const { activeStep } = formState
  const formStatePr = isEmpty(formState) ? initialForm : formState
  const currentValidationSchema = validationSchema[activeStep]

  const handleBack = () => {
    history.goBack()
  }

  const handleReset = () => {
    dispatch(exportListActions.setFormState({ initialForm }))
  }

  const handleSubmit = values => {
    // dispatch complete request
    if (typeof values !== 'undefined') {
      dispatch(exportListActions.submitFormRequest({ values }))
      // SetCombinationButtonDisabled(false)
      // SetTargetButtonDisabled(true)
    }
  }

  const handleOnChange = e => {
    // Now we change to the other step, meaning if we are on 'export-list' we go to 'create-list'
    if (activeStep === 'export-list') {
      dispatch(
        exportListActions.setFormState({
          ...formState,
          activeStep: 'create-list'
        })
      )
    } else {
      dispatch(
        exportListActions.setFormState({
          ...formState,
          activeStep: 'export-list'
        })
      )
    }
  }

  const ActiveStep = ({
    formProps
    // combinationButtonDisabled,
    // SetCombinationButtonDisabled,
    // targetButtonDisabled,
    // SetTargetButtonDisabled
  }) => {
    return React.cloneElement(steps[activeStep].component, {
      ...steps[activeStep].component.props,
      formProps,
      handleReset
      // combinationButtonDisabled,
      // SetCombinationButtonDisabled,
      // targetButtonDisabled,
      // SetTargetButtonDisabled
    })
  }

  return (
    <Page
      heroTitle="Export List"
      showHero
      showGoBack
      onBackClick={handleBack}
      showDivider
      showFooter={false}
    >
      <Formik
        initialValues={formStatePr}
        validationSchema={currentValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {formProps => (
          <Form>
            <div className={styles.buttonGroup}>
              <Checkbox
                label="Choose a List"
                name="activeStep"
                value={formProps.values.activeStep === 'export-list'}
                onChange={handleOnChange}
              />
              {features.sections?.actions.includes('upload') && (
                <Checkbox
                  label="Upload a List"
                  name="activeStep"
                  value={formProps.values.activeStep === 'create-list'}
                  onChange={handleOnChange}
                />
              )}
            </div>
            <ActiveStep
              formProps={formProps}
              // combinationButtonDisabled={combinationButtonDisabled}
              // SetCombinationButtonDisabled={SetCombinationButtonDisabled}
              // targetButtonDisabled={targetButtonDisabled}
              // SetTargetButtonDisabled={SetTargetButtonDisabled}
            />
            <div className={styles.buttonContainer}>
              <Button
                type="submit"
                submitting={isFetching}
                disabled={isFetching}
                hasSpinner
                isLimaSubmit
                icon="svg/material-design-icons/file/file_download"
              />
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  )
}

export default ExportList
