const countries = {
  Afghanistan: 'Afghanistan',
  'Aland Islands': 'Aland Islands',
  Albania: 'Albania',
  Algeria: 'Algeria',
  'American Samoa': 'American Samoa',
  Andorra: 'Andorra',
  Angola: 'Angola',
  Anguilla: 'Anguilla',
  Antarctica: 'Antarctica',
  'Antigua and Barbuda': 'Antigua and Barbuda',
  Argentina: 'Argentina',
  Armenia: 'Armenia',
  Aruba: 'Aruba',
  Australia: 'Australia',
  Austria: 'Austria',
  Azerbaijan: 'Azerbaijan',
  Bahamas: 'Bahamas',
  Bahrain: 'Bahrain',
  Bangladesh: 'Bangladesh',
  Barbados: 'Barbados',
  Belarus: 'Belarus',
  Belgium: 'Belgium',
  Belize: 'Belize',
  Benin: 'Benin',
  Bermuda: 'Bermuda',
  Bhutan: 'Bhutan',
  Bolivia: 'Bolivia',
  'Caribbean Netherlands': 'Caribbean Netherlands',
  'Bosnia and Herzegovina': 'Bosnia and Herzegovina',
  Botswana: 'Botswana',
  'Bouvet Island': 'Bouvet Island',
  Brazil: 'Brazil',
  'British Indian Ocean Territory': 'British Indian Ocean Territory',
  'British Virgin Islands': 'British Virgin Islands',
  'Brunei Darussalam': 'Brunei Darussalam',
  Bulgaria: 'Bulgaria',
  'Burkina Faso': 'Burkina Faso',
  Burundi: 'Burundi',
  'Cape Verde': 'Cape Verde',
  Cambodia: 'Cambodia',
  Cameroon: 'Cameroon',
  Canada: 'Canada',
  'Cayman Islands': 'Cayman Islands',
  'Central African Republic': 'Central African Republic',
  Chad: 'Chad',
  Chile: 'Chile',
  China: 'China',
  'Hong Kong': 'Hong Kong',
  'Christmas Island': 'Christmas Island',
  'Cocos (Keeling) Islands': 'Cocos (Keeling) Islands',
  Colombia: 'Colombia',
  Comoros: 'Comoros',
  'Congo-Brazzaville': 'Congo-Brazzaville',
  'Congo-Kinshasa': 'Congo-Kinshasa',
  'Cook Islands': 'Cook Islands',
  'Costa Rica': 'Costa Rica',
  "Cote d'Ivoire": "Cote d'Ivoire",
  Croatia: 'Croatia',
  Cuba: 'Cuba',
  Curacao: 'Curacao',
  Cyprus: 'Cyprus',
  Czech: 'Czech',
  'North Korea': 'North Korea',
  Denmark: 'Denmark',
  Djibouti: 'Djibouti',
  Dominica: 'Dominica',
  'Dominican Republic': 'Dominican Republic',
  Ecuador: 'Ecuador',
  Egypt: 'Egypt',
  'El Salvador': 'El Salvador',
  'Equatorial Guinea': 'Equatorial Guinea',
  Eritrea: 'Eritrea',
  Estonia: 'Estonia',
  Ethiopia: 'Ethiopia',
  'Falkland Islands': 'Falkland Islands',
  'Faroe Islands': 'Faroe Islands',
  Fiji: 'Fiji',
  Finland: 'Finland',
  France: 'France',
  'French Guiana': 'French Guiana',
  'French Polynesia': 'French Polynesia',
  'French Southern Territories': 'French Southern Territories',
  Gabon: 'Gabon',
  Gambia: 'Gambia',
  Palestine: 'Palestine',
  Georgia: 'Georgia',
  Germany: 'Germany',
  Ghana: 'Ghana',
  Gibraltar: 'Gibraltar',
  Greece: 'Greece',
  Greenland: 'Greenland',
  Grenada: 'Grenada',
  Guadeloupe: 'Guadeloupe',
  Guam: 'Guam',
  Guatemala: 'Guatemala',
  Guernsey: 'Guernsey',
  Guinea: 'Guinea',
  'Guinea-Bissau': 'Guinea-Bissau',
  Guyana: 'Guyana',
  Haiti: 'Haiti',
  'Heard and McDonald Islands': 'Heard and McDonald Islands',
  Vatican: 'Vatican',
  Honduras: 'Honduras',
  Hungary: 'Hungary',
  Iceland: 'Iceland',
  India: 'India',
  Indonesia: 'Indonesia',
  Iran: 'Iran',
  Iraq: 'Iraq',
  Ireland: 'Ireland',
  'Isle of Man': 'Isle of Man',
  Israel: 'Israel',
  Italy: 'Italy',
  Jamaica: 'Jamaica',
  Japan: 'Japan',
  Jersey: 'Jersey',
  Jordan: 'Jordan',
  Kazakhstan: 'Kazakhstan',
  Kenya: 'Kenya',
  Kiribati: 'Kiribati',
  Kosovo: 'Kosovo',
  Kuwait: 'Kuwait',
  Kyrgyzstan: 'Kyrgyzstan',
  Laos: 'Laos',
  Latvia: 'Latvia',
  Lebanon: 'Lebanon',
  Lesotho: 'Lesotho',
  Liberia: 'Liberia',
  Libya: 'Libya',
  Liechtenstein: 'Liechtenstein',
  Lithuania: 'Lithuania',
  Luxembourg: 'Luxembourg',
  Macau: 'Macau',
  Madagascar: 'Madagascar',
  Malawi: 'Malawi',
  Malaysia: 'Malaysia',
  Maldives: 'Maldives',
  Mali: 'Mali',
  Malta: 'Malta',
  'Marshall Islands': 'Marshall Islands',
  Martinique: 'Martinique',
  Mauritania: 'Mauritania',
  Mauritius: 'Mauritius',
  Mayotte: 'Mayotte',
  Mexico: 'Mexico',
  Micronesia: 'Micronesia',
  Monaco: 'Monaco',
  Mongolia: 'Mongolia',
  Montenegro: 'Montenegro',
  Montserrat: 'Montserrat',
  Morocco: 'Morocco',
  Mozambique: 'Mozambique',
  Myanmar: 'Myanmar',
  Namibia: 'Namibia',
  Nauru: 'Nauru',
  Nepal: 'Nepal',
  Netherlands: 'Netherlands',
  'Netherlands Antilles': 'Netherlands Antilles',
  'New Caledonia': 'New Caledonia',
  'New Zealand': 'New Zealand',
  Nicaragua: 'Nicaragua',
  Niger: 'Niger',
  Nigeria: 'Nigeria',
  Niue: 'Niue',
  'Norfolk Island': 'Norfolk Island',
  'Northern Mariana Islands': 'Northern Mariana Islands',
  Norway: 'Norway',
  Oman: 'Oman',
  Pakistan: 'Pakistan',
  Palau: 'Palau',
  Panama: 'Panama',
  'Papua New Guinea': 'Papua New Guinea',
  Paraguay: 'Paraguay',
  Peru: 'Peru',
  Philippines: 'Philippines',
  Pitcairn: 'Pitcairn',
  Poland: 'Poland',
  Portugal: 'Portugal',
  'Puerto Rico': 'Puerto Rico',
  Qatar: 'Qatar',
  Korea: 'Korea',
  Moldova: 'Moldova',
  Reunion: 'Reunion',
  Romania: 'Romania',
  Russia: 'Russia',
  Rwanda: 'Rwanda',
  'Saint Barthelemy': 'Saint Barthelemy',
  'Saint Helena': 'Saint Helena',
  'Saint Kitts and Nevis': 'Saint Kitts and Nevis',
  'Saint Lucia': 'Saint Lucia',
  'Saint Martin': 'Saint Martin',
  'Saint Pierre and Miquelon': 'Saint Pierre and Miquelon',
  'Saint Vincent and The Grenadines': 'Saint Vincent and The Grenadines',
  Samoa: 'Samoa',
  'San Marino': 'San Marino',
  'Sao Tome and Principe': 'Sao Tome and Principe',
  'Saudi Arabia': 'Saudi Arabia',
  Senegal: 'Senegal',
  Serbia: 'Serbia',
  Seychelles: 'Seychelles',
  'Sierra Leone': 'Sierra Leone',
  Singapore: 'Singapore',
  'Sint Maarten': 'Sint Maarten',
  Slovakia: 'Slovakia',
  Slovenia: 'Slovenia',
  'Solomon Islands': 'Solomon Islands',
  Somalia: 'Somalia',
  'South Africa': 'South Africa',
  'South Georgia and the South Sandwich Islands':
    'South Georgia and the South Sandwich Islands',
  'South Sudan': 'South Sudan',
  Spain: 'Spain',
  'Sri Lanka': 'Sri Lanka',
  Sudan: 'Sudan',
  Suriname: 'Suriname',
  'Svalbard and Jan Mayen': 'Svalbard and Jan Mayen',
  Swaziland: 'Swaziland',
  Sweden: 'Sweden',
  Switzerland: 'Switzerland',
  Syria: 'Syria',
  Taiwan: 'Taiwan',
  Tajikistan: 'Tajikistan',
  Thailand: 'Thailand',
  Macedonia: 'Macedonia',
  'Timor-Leste': 'Timor-Leste',
  Tanzania: 'Tanzania',
  Togo: 'Togo',
  Tokelau: 'Tokelau',
  Tonga: 'Tonga',
  'Trinidad and Tobago': 'Trinidad and Tobago',
  Tunisia: 'Tunisia',
  Turkey: 'Turkey',
  Turkmenistan: 'Turkmenistan',
  'Turks and Caicos Islands': 'Turks and Caicos Islands',
  Tuvalu: 'Tuvalu',
  Uganda: 'Uganda',
  Ukraine: 'Ukraine',
  UAE: 'UAE',
  UK: 'UK',
  'United States Minor Outlying Islands':
    'United States Minor Outlying Islands',
  USA: 'USA',
  'United States Virgin Islands': 'United States Virgin Islands',
  Uruguay: 'Uruguay',
  Uzbekistan: 'Uzbekistan',
  Vanuatu: 'Vanuatu',
  Venezuela: 'Venezuela',
  'Viet Nam': 'Viet Nam',
  'Wallis and Futuna': 'Wallis and Futuna',
  'Western Sahara': 'Western Sahara',
  Yemen: 'Yemen',
  Zambia: 'Zambia',
  Zimbabwe: 'Zimbabwe'
}
export default countries
