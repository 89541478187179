import { createReducer, createAction } from '@reduxjs/toolkit'
import { initialValues } from './config'

export const submitFormRequest = createAction('CONTACT_US_SUBMIT_FORM_REQUEST')
export const submitFormRequestSuccess = createAction(
  'CONTACT_US_SUBMIT_FORM_REQUEST_SUCCESS'
)
export const submitFormRequestFail = createAction(
  'CONTACT_US_SUBMIT_FORM_REQUEST_FAIL'
)
export const submitCompleteRequest = createAction('CONTACT_US_COMPLETE_REQUEST')

export const actions = {
  submitFormRequest,
  submitFormRequestSuccess,
  submitFormRequestFail
}

const contactUsReducer = createReducer(
  {
    formState: {
      ...initialValues
    }
  },
  {
    [submitFormRequest]: state => {
      state.fetching = true
    },
    [submitFormRequestSuccess]: state => {
      state.fetching = false
    },
    [submitFormRequestFail]: state => {
      state.fetching = false
    }
  }
)

export default contactUsReducer
