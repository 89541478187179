import { useSelector } from 'react-redux'
import Dropdown from 'components/DropdownInput'
import TextInput from 'components/TextInput'
import * as listIngestionSelectors from '../ListIngestionSelector'

const { getOptions } = listIngestionSelectors

const DataLocation = () => {
  const dataLocationOptions = useSelector(getOptions('data-location'))
  const { parsedColumnHeadersOptions = {} } = dataLocationOptions
  return (
    <div data-testid="dataLocationContainer">
      <Dropdown
        label="Read Columns From"
        name="columnFirst"
        required
        traditional
        options={parsedColumnHeadersOptions}
      />
      <Dropdown
        label="Read Columns To"
        name="columnLast"
        required
        traditional
        options={parsedColumnHeadersOptions}
      />
      <TextInput
        label="Read Rows From"
        name="rowFirst"
        type="text"
        required
        traditional
      />
      <TextInput
        label="Read Rows To"
        name="rowLast"
        type="text"
        required
        traditional
      />
    </div>
  )
}
export default DataLocation
