import Card from 'components/Admin/Card/Card'
import PageContent from 'components/Admin/Page/PageContent'

const Universe = () => {
  return (
    <PageContent title="Users">
      <Card>
        <div>Hello Users!!!!</div>
      </Card>
    </PageContent>
  )
}

export default Universe
