import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uniq } from 'lodash'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames/bind'
import Modal from 'components/ui/Modal'
import Icon from 'components/ui/Icon'
import Input from 'components/ui/Input'
import Typography from 'components/ui/Typography'
import { Button } from 'components/ui/Button'
import * as jobStatusListSelectors from './JobStatusListSelector'
import * as actions from './JobStatusListReducer'
import styles from './JobStatusAssign.module.sass'

const cx = classNames.bind(styles)

const { getAssignForm } = jobStatusListSelectors

const validateEmail = email => {
  const schema = Yup.string().required().email()
  return schema.isValidSync(email)
}

const JobStatusAssign = () => {
  let initialForm = {
    email: ''
  }
  const dispatch = useDispatch()
  const [emailValue, setEmailValue] = useState('')
  const [formUpdated, setFormUpdated] = useState(false)
  const [initialValues, setInitialValues] = useState(initialForm)
  const [clearInput, setClearInput] = useState(false)

  const { open, fetching, emailsList, listId } = useSelector(getAssignForm())

  const handleAddEmail = () => {
    if (validateEmail(emailValue)) {
      dispatch(
        actions.assignListUpdateEmails({
          emailsList: uniq([...emailsList, emailValue])
        })
      )
      setEmailValue('')
      setClearInput(!clearInput)
      setInitialValues(initialForm)
      setFormUpdated(true)
    }
  }

  const handleDeleteEmail = email => {
    dispatch(
      actions.assignListUpdateEmails({
        emailsList: emailsList.filter(e => e !== email)
      })
    )
    setFormUpdated(true)
  }

  const handleSubmit = () => {
    dispatch(actions.assignListRequest())
  }

  const handleDismiss = () => {
    setEmailValue('')
    dispatch(actions.assignListFormClose())
  }

  return (
    <Modal
      id="assign-form-modal"
      modalOpen={open}
      maxWidth={450}
      onDismiss={handleDismiss}
      altTheme={{ content: styles.assignFormModalContent }}
    >
      <div className={styles.formTitleContainer}>
        <Typography className={styles.formTitleTitle} type="title" paragraph>
          Email Sharing Assignments
        </Typography>
        <Typography
          className={styles.formTitleSubHeading}
          type="subheading"
          title={`ML ID: ${listId}`}
        >
          ID: {listId || 'N/A'}
        </Typography>
      </div>

      <div className={styles.formContainer}>
        <Formik
          key={clearInput}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object({
            email: Yup.string().email()
          })}
        >
          <Form>
            <Field name="email">
              {({ field }) => (
                <Input
                  label="Enter a new Email"
                  name="email"
                  onChange={event => setEmailValue(event.target.value)}
                  type="email"
                  value={emailValue}
                  error={
                    emailValue &&
                    !validateEmail(emailValue) &&
                    'Please enter a valid email'
                  }
                  input={{ value: field.value }}
                  rightElements={
                    <Icon
                      colorTier="action"
                      icon="svg/fw/add-circle"
                      button
                      disabled={!!emailValue && !validateEmail(emailValue)}
                      onClick={handleAddEmail}
                    />
                  }
                />
              )}
            </Field>
          </Form>
        </Formik>
      </div>

      {emailsList.length > 0 && (
        <div className={styles.emailsListContainer}>
          <Typography className={styles.formTitleSubHeading} type="subheading">
            {`${emailsList.length} Assigned ${
              emailsList.length > 1 ? 'Emails' : 'Email'
            }`}
          </Typography>
          <div className={styles.emailsListContainerListingWrapper}>
            {emailsList.map((email, i) => (
              <div key={email} className={styles.emailsListItem}>
                <Icon
                  colorTier="tertiary"
                  icon="svg/material-design-icons/navigation/cancel"
                  button
                  onClick={() => handleDeleteEmail(email)}
                />
                <Typography align="center">{email}</Typography>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className={styles.formSubmit}>
        <Button
          type="button"
          iconLeft="svg/material-design-icons/action/update"
          textCase="none"
          minWidth="12em"
          submitting={fetching}
          disabled={fetching || !formUpdated}
          hasSpinner
          onClick={handleSubmit}
          className={cx(styles.button, {
            buttonDisabled: fetching || !formUpdated
          })}
        >
          {fetching || !formUpdated ? 'Save' : 'Save Changes'}
        </Button>
      </div>
    </Modal>
  )
}

JobStatusAssign.propTypes = {}

export default JobStatusAssign
