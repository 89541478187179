import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as thresholdActions } from './ThresholdReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'

/**
 * Request threshold search
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* searchRequest(services, { payload }) {
  const AdminService = services('AdminService')
  try {
    const {
      search: {
        country = '',
        //   profession = '',
        //   specialty = '',
        orderBy = '',
        order = '',
        page = 1,
        perPage = 25
      }
    } = payload
    const { data, pagination } = yield call([AdminService, 'searchThreshold'], {
      country,
      // profession,
      // specialty,
      orderBy,
      order,
      page,
      perPage
    })
    yield put(
      thresholdActions.searchRequestSuccess({
        thresholdObject: data,
        pagination
      })
    )
  } catch (e) {
    yield put(thresholdActions.searchRequestFail())
    console.error(e)
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
  }
}

function* fetchThreshold(services, { payload }) {
  const AdminService = services('AdminService')

  try {
    const { id } = payload
    const responseData = yield call([AdminService, 'getSingleThreshold'], id)
    const parsedId = parseInt(id)
    const parsedResponseData = { ...responseData, id: parsedId }
    yield put(thresholdActions.fetchThresholdRequestSuccess(parsedResponseData))
  } catch (e) {
    yield put(thresholdActions.fetchThresholdRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to fetch threshold.',
        duration: 0
      })
    )
  }
}

function* deleteThreshold(services, { payload }) {
  const AdminService = services('AdminService')

  try {
    const { selected } = payload
    const id = selected
    yield call([AdminService, 'deleteThreshold'], id)
    yield put(thresholdActions.deleteThresholdRequestSuccess())
  } catch (e) {
    yield put(thresholdActions.deleteThresholdRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to delete threshold.',
        duration: 0
      })
    )
  }
}

function* updateThreshold(services, { payload }) {
  const AdminService = services('AdminService')
  try {
    const { id = null, country = '', value = 0 } = payload
    const message = id ? 'Threshold updated.' : 'Threshold added.'
    const { responseData, status } = yield call(
      [AdminService, 'updateThreshold'],
      {
        id,
        country,
        value
      }
    )
    if (status === 'Error') {
      throw new Error(responseData.message)
    }
    const parsedId = parseInt(responseData.id)
    const parsedResponseData = { ...responseData, id: parsedId }
    yield put(thresholdActions.fetchThresholdRequestSuccess(parsedResponseData))
    yield put(
      notificationActions.notificationEnqueue({
        message,
        duration: 0
      })
    )
  } catch (e) {
    yield put(thresholdActions.fetchThresholdRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: `Unable to update/add threshold.`,
        duration: 0
      })
    )
  }
}

export default function* watchThresholdRequest(services) {
  yield all([takeEvery('THRESHOLD_SEARCH_REQUEST', searchRequest, services)])
  yield all([takeEvery('THRESHOLD_FETCH_REQUEST', fetchThreshold, services)])
  yield all([takeEvery('THRESHOLD_DELETE_REQUEST', deleteThreshold, services)])
  yield all([takeEvery('THRESHOLD_UPDATE_REQUEST', updateThreshold, services)])
}
