import { useState } from 'react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Card from 'components/Admin/Card/Card'
import Typography from 'components/ui/Typography'
import ConfirmationModal from 'components/Admin/ConfirmationModal'
import FileInput from 'components/FileInput/FileInput'
import styles from './form.module.sass'
import Button from 'components/Admin/Button'
import { uploadRequest, downloadRequest } from '../UniverseReducer'
import * as universeSelector from '../UniverseSelector'
import classNames from 'classnames/bind'
import useRoleFeatures from 'modules/auth/useRoleFeatures'

const cx = classNames.bind(styles)
const { getIsFetching, getRequestType } = universeSelector

const FileForm = () => {
  const { features } = useRoleFeatures({
    coin: 'charts-configuration'
  })
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const isFormFetching = useSelector(getIsFetching())
  const requestType = useSelector(getRequestType())
  const roleSchema = features?.sections?.actions || []
  const userCanEdit = roleSchema.includes('save')

  const handleModalOpen = () => {
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleSubmit = values => {
    dispatch(uploadRequest(values))
    setModalOpen(false)
  }
  const handleDownload = () => {
    dispatch(downloadRequest())
  }
  return (
    <div className={styles.container}>
      <Card className={cx(styles.card, styles.fileCard)}>
        <Typography
          type="title"
          className={styles.cardTitle}
          color="text-primary"
        >
          Universe File
        </Typography>
        <Formik initialValues={{ file: '' }} onSubmit={handleSubmit}>
          {formProps => (
            <Form>
              <FileInput
                id="universeFile"
                name="file"
                label="Select File"
                traditional={false}
                multiple={false}
                accept=".csv"
                disabled={!userCanEdit}
              />
              <div className={styles.actionContainer}>
                <Button
                  className={styles.uploadButton}
                  text="Upload"
                  textCase="none"
                  disabled={
                    !formProps.values.file || isFormFetching || !userCanEdit
                  }
                  submitting={isFormFetching && requestType === 'upload'}
                  type="button"
                  onClick={handleModalOpen}
                  hasSpinner
                />
                <Button
                  text="Download All"
                  textCase="none"
                  disabled={isFormFetching}
                  type="button"
                  onClick={handleDownload}
                  submitting={isFormFetching && requestType === 'download'}
                  hasSpinner
                />
              </div>
              <ConfirmationModal
                message="Are you sure you want to upload the selected universe file?"
                open={modalOpen}
                onConfirm={() => handleSubmit(formProps.values)}
                onCancel={handleModalClose}
                actionType="submit"
                actionCaption="Confirm"
              />
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  )
}

export default FileForm
