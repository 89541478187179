import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { Formik, Form } from 'formik'
import { isEmpty } from 'utils'

import {
  getFetchingSingle,
  getSingleUniverse,
  getBeforeFormState,
  getAfterFormState
} from './UniverseHistorySelector'
import { actions as universeHistoryActions } from './UniverseHistoryReducer'

import TextInput from 'components/TextInput'
import Button from 'components/Admin/Button'
import Card from 'components/Admin/Card/Card'
import Typography from 'components/ui/Typography'
import PageContent from 'components/Admin/Page/PageContent'
import Icon from 'components/ui/Icon'
import DataTable from 'react-data-table-component'
import { CircularProgress } from 'components/ui/Progress'

import styles from './UniverseHistory.module.sass'

const SingleUniverseHistory = () => {
  const dispatch = useDispatch()
  const routerHistory = useHistory()

  const initialForm = {
    note: ''
  }
  const isFetching = useSelector(getFetchingSingle())
  const beforeFormState = useSelector(getBeforeFormState())
  const afterFormState = useSelector(getAfterFormState())
  const beforeFormStatePr = isEmpty(beforeFormState)
    ? initialForm
    : beforeFormState
  const afterFormStatePr = isEmpty(afterFormState)
    ? initialForm
    : afterFormState

  const {
    id = '',
    note = '',
    data = { before: [], after: [] },
    createdAt = 0,
    updatedAt = 0
  } = useSelector(getSingleUniverse())

  const { location } = routerHistory
  const urlId = location.pathname.split('/').slice(-1)[0]

  const columns = [
    {
      name: 'Country',
      selector: row => row.country,
      sortField: 'country',
      sortable: false,
      center: true,
      cell: row => {
        return <Typography align="center">{row.country}</Typography>
      }
    },
    {
      name: 'Profession',
      selector: row => row.profession,
      sortField: 'profession',
      sortable: false,
      center: true,
      cell: row => {
        return <Typography align="center">{row.profession}</Typography>
      }
    },
    {
      name: 'Specialty',
      selector: row => row.specialty,
      sortField: 'specialty',
      sortable: false,
      center: true,
      wrap: false,
      cell: row => {
        return <Typography align="center">{row.specialty}</Typography>
      }
    },
    {
      name: 'Value',
      selector: row => row.value,
      sortField: 'value',
      sortable: false,
      center: true,
      cell: row => {
        const value = row.value ? row.value.toLocaleString() : 0
        return <Typography align="center">{value}</Typography>
      }
    }
  ]

  const handleBack = () => {
    dispatch(universeHistoryActions.resetSingleUniverse({}))
    routerHistory.push('/admin/universe-history')
  }

  const handleBeforeSubmit = values => {
    dispatch(
      universeHistoryActions.restoreUniverse({
        ...values,
        id: urlId,
        type: 'before'
      })
    )
  }
  const handleAfterSubmit = values => {
    dispatch(
      universeHistoryActions.restoreUniverse({
        ...values,
        id: urlId,
        type: 'after'
      })
    )
  }

  useEffect(() => {
    dispatch(universeHistoryActions.requestSingleUniverse(urlId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageContent title="Universe History">
      <Card className={styles.card}>
        <div className={styles.headingContainer}>
          <Icon
            icon="svg/custom/arrow-back"
            className={styles.backIcon}
            onClick={() => handleBack()}
          />
          <div>
            <Typography
              type="title"
              color="text-primary"
              className={styles.cardSubtitle}
            >
              {id}
            </Typography>
            <Typography
              type="subheading"
              color="text-primary"
              className={styles.cardTitle}
            >
              {note}
            </Typography>
          </div>
        </div>
        {isFetching ? (
          <CircularProgress fillColor="transparent" size={64} strokeWidth={4} />
        ) : (
          <>
            <div className={styles.columnsContainer}>
              <div className={styles.singleColumn}>
                <Typography
                  type="title"
                  color="text-primary"
                  className={styles.cardHeading}
                >
                  Before
                </Typography>
                <Formik
                  enableReinitialize
                  initialValues={beforeFormStatePr}
                  onSubmit={handleBeforeSubmit}
                >
                  <Form className={styles.formFlex}>
                    <TextInput
                      label="Note"
                      name="note"
                      type="text"
                      required
                      traditional={false}
                    />
                    <Button
                      text="Restore"
                      textCase="none"
                      disabled={false}
                      type="submit"
                    />
                  </Form>
                </Formik>
                <DataTable
                  columns={columns}
                  data={data.before}
                  keyField="key"
                  progressPending={isFetching}
                  persistTableHead
                  progressComponent={
                    <CircularProgress
                      fillColor="transparent"
                      size={64}
                      strokeWidth={4}
                    />
                  }
                />
              </div>
              <div className={styles.singleColumn}>
                <Typography
                  type="title"
                  color="text-primary"
                  className={styles.cardHeading}
                >
                  After
                </Typography>
                <Formik
                  enableReinitialize
                  initialValues={afterFormStatePr}
                  onSubmit={handleAfterSubmit}
                >
                  <Form className={styles.formFlex}>
                    <TextInput
                      label="Note"
                      name="note"
                      type="text"
                      required
                      traditional={false}
                    />
                    <Button
                      text="Restore"
                      textCase="none"
                      disabled={false}
                      type="submit"
                    />
                  </Form>
                </Formik>
                <DataTable
                  columns={columns}
                  data={data.after}
                  keyField="key"
                  progressPending={isFetching}
                  persistTableHead
                  progressComponent={
                    <CircularProgress
                      fillColor="transparent"
                      size={64}
                      strokeWidth={4}
                    />
                  }
                />
              </div>
            </div>
            <Typography
              type="title"
              color="text-primary"
              className={styles.cardParagraph}
            >
              Created At: <span>{createdAt}</span>
            </Typography>
            <Typography
              type="title"
              color="text-primary"
              className={styles.cardParagraph}
            >
              Updated At: <span>{updatedAt}</span>
            </Typography>
          </>
        )}
      </Card>
    </PageContent>
  )
}

export default SingleUniverseHistory
