import { createReducer, createAction } from '@reduxjs/toolkit'

export const setFormState = createAction('UNIVERSE_HISTORY_SET_FORM_STATE')
export const requestUniverseHistory = createAction('UNIVERSE_HISTORY_REQUEST')
export const completeRequestHistory = createAction(
  'UNIVERSE_HISTORY_COMPLETE_REQUEST'
)
export const setSelected = createAction('UNIVERSE_HISTORY_SET_SELECTED')
export const requestHistoryDelete = createAction('UNIVERSE_HISTORY_DELETE')
export const completeHistoryDelete = createAction(
  'UNIVERSE_HISTORY_COMPLETE_DELETE'
)
export const requestSingleUniverse = createAction(
  'UNIVERSE_HISTORY_REQUEST_SINGLE'
)
export const setSingleUniverse = createAction('UNIVERSE_HISTORY_SET_SINGLE')
export const resetSingleUniverse = createAction('UNIVERSE_HISTORY_SINGLE_RESET')
export const restoreUniverse = createAction('UNIVERSE_HISTORY_RESTORE')

export const actions = {
  setFormState,
  requestUniverseHistory,
  completeRequestHistory,
  setSelected,
  requestHistoryDelete,
  completeHistoryDelete,
  requestSingleUniverse,
  setSingleUniverse,
  resetSingleUniverse,
  restoreUniverse
}

const universeHistoryReducer = createReducer(
  {
    formState: {
      note: '',
      orderBy: '',
      order: '',
      page: 1,
      perPage: 25
    },
    isFirstFetching: true,
    isFetching: true,
    history: [],
    pagination: {
      total: 0,
      filtered: 0,
      maxPage: 0,
      results: 0,
      page: 1,
      perPage: 25
    },
    selected: {
      id: null,
      note: ''
    },
    isFetchingSingle: true,
    singleUniverse: {
      id: '',
      note: '',
      data: {
        before: [],
        after: []
      },
      createdAt: 0,
      updatedAt: 0
    },
    beforeFormState: {
      note: ''
    },
    afterFormState: {
      note: ''
    }
  },
  {
    [setFormState]: (state, { payload }) => {
      state.formState = { ...state.formState, ...payload }
    },
    [requestUniverseHistory]: state => {
      state.isFetching = true
    },
    [completeRequestHistory]: (state, { payload }) => {
      state.history = payload.data
      state.pagination = payload.pagination
      state.isFirstFetching = false
      state.isFetching = false
    },
    [setSelected]: (state, { payload }) => {
      state.selected.id = payload.id
      state.selected.note = payload.note
    },
    [requestHistoryDelete]: state => {
      state.isFetching = true
    },
    [completeHistoryDelete]: state => {
      state.selected.id = null
      state.selected.note = ''
    },
    [requestSingleUniverse]: state => {
      state.isFetchingSingle = true
    },
    [setSingleUniverse]: (state, { payload }) => {
      state.singleUniverse.id = payload.id
      state.singleUniverse.note = payload.note
      state.singleUniverse.data = payload.data
      state.singleUniverse.createdAt = payload.createdAt
      state.singleUniverse.updatedAt = payload.updatedAt
      state.isFetchingSingle = false
    },
    [resetSingleUniverse]: state => {
      state.singleUniverse = {
        id: '',
        note: '',
        data: {
          before: [],
          after: []
        },
        createdAt: 0,
        updatedAt: 0
      }
      state.isFetchingSingle = true
      state.selected.id = null
      state.selected.note = ''
    },
    [restoreUniverse]: state => {
      state.isFetchingSingle = true
    }
  }
)

export default universeHistoryReducer
