import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Page from 'components/Page'
import { actions } from './HomeReducer'
import { actions as combinationsActions } from '../CombinationsTable/CombinationsTableReducer'
import { actions as chatActions } from 'modules/dougall/chat/ChatReducer'
import Spinner from '../../components/ui/Spinner'
import { getCoinsList, getIsFetching } from './HomeSelector'
import { getCombinations } from '../CombinationsTable/CombinationsTableSelector'
import SessionService from 'services/SessionService'
import CookieService from 'services/CookieService'
import styles from './Home.module.sass'
import Coin from 'components/Coin'
import useRoleFeatures from 'modules/auth/useRoleFeatures'
import siteConfig from 'site.config.json'
import { isEmpty, parseJwt } from 'utils'

const {
  coins: coinsConfig,
  configuration: { gpt }
} = siteConfig

const Home = () => {
  const dispatch = useDispatch()
  const data = useSelector(getCoinsList() || [])
  const isFetching = useSelector(getIsFetching() || false)
  const { roles } = useRoleFeatures({})
  const combinationOptions = useSelector(getCombinations())

  const access = CookieService().getFromCache('access')
  const { roleId } = parseJwt(access)

  useEffect(() => {
    const gptToken = new SessionService().getFromCache('gpt')
    if (gptToken?.access) {
      // Refresh GPT token
      const currentTimestamp = Math.round(Date.now() / 1000)
      const { iat, exp } =
        process.env.NODE_ENV === 'development'
          ? {
              iat: currentTimestamp,
              exp: currentTimestamp + 30 * 24 * 3600
            }
          : parseJwt(gptToken.access)
      const forceExpiryTimestamp =
        iat + (gpt?.forceTokenExpiryMinute || 60) * 60
      if (currentTimestamp > exp || currentTimestamp > forceExpiryTimestamp)
        dispatch(chatActions.gptToken())
      // New GPT token
    } else if (roleId.includes('DOUGALL_GPT')) dispatch(chatActions.gptToken())

    if (!data.length) {
      dispatch(actions.listCoinsRequest())

      // Added to get the Country/region list for Explore Market
      if (isEmpty(combinationOptions)) {
        dispatch(combinationsActions.combinationsTableRequest())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const refreshGptToken = setInterval(() => {
      dispatch(chatActions.gptToken())
    }, (gpt?.forceTokenExpiryMinute || 60) * 60 * 1000)

    return () => clearInterval(refreshGptToken)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page
      contentClassName={styles.page}
      showHero={false}
      showGoBack={false}
      showFooter={false}
    >
      <div className={styles.coinsList}>
        {isFetching || !data.length ? (
          <div className={styles.loaderContainer}>
            <Spinner
              strokeColor={null}
              fillColor="transparent"
              size={64}
              strokeWidth={4}
            />
          </div>
        ) : (
          coinsConfig.map((r, idx) => {
            return (
              <div key={idx} className={styles.coinsRow}>
                {r.map(c => {
                  const endpointCoin = data.find(d => d.id === c.id) || {}
                  return (
                    <Coin
                      key={c.id}
                      className={styles.coin}
                      icon={c.icon}
                      title={c.title}
                      route={c.route || '/'}
                      disabled={
                        !endpointCoin.active ||
                        !roles.includes(c.route) ||
                        !(c.route === '/gpt'
                          ? roleId.includes('DOUGALL_GPT')
                          : true)
                      }
                      externalLink={endpointCoin?.params?.externalLink}
                      openNewTab={c.openNewTab || false}
                    />
                  )
                })}
              </div>
            )
          })
        )}
      </div>
    </Page>
  )
}

export default Home
