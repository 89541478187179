import { createReducer, createAction } from '@reduxjs/toolkit'

export const setFormState = createAction('USERS_CREATE_SET_FORM_STATE')
export const requestPossibleCountries = createAction(
  'USERS_CREATE_POSSIBLE_COUNTRIES'
)
export const requestPossibleRoles = createAction('USERS_CREATE_POSSIBLE_ROLES')
export const setPossibleCountries = createAction(
  'USERS_CREATE_SET_POSSIBLE_COUNTRIES'
)
export const setPossibleRoles = createAction('USERS_CREATE_SET_POSSIBLE_ROLES')
export const submitCreate = createAction('USERS_CREATE_SUBMIT')
export const completeSubmit = createAction('USERS_CREATE_SUBMIT_COMPLETED')
export const completeUpdate = createAction('USERS_CREATE_UPDATE_COMPLETED')

export const fetchUserRequest = createAction('USERS_CREATE_FETCH_REQUEST')
export const fetchUserRequestSuccess = createAction(
  'USERS_CREATE_FETCH_REQUEST_SUCCESS'
)
export const fetchUserRequestFail = createAction(
  'USERS_CREATE_FETCH_REQUEST_FAIL'
)

export const actions = {
  setFormState,
  requestPossibleCountries,
  requestPossibleRoles,
  setPossibleCountries,
  setPossibleRoles,
  submitCreate,
  completeSubmit,
  fetchUserRequest,
  fetchUserRequestSuccess,
  fetchUserRequestFail,
  completeUpdate
}

const usersCreateReducer = createReducer(
  {
    formState: {
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      city: '',
      postalCode: '',
      province: '',
      telephone: '',
      company: '',
      jobFunction: '',
      jobTitle: '',
      status: 'active',
      roles: []
    },
    countriesFetching: true,
    rolesFetching: true,
    possibleCountries: {},
    rolesAttributes: {},
    submitting: false,
    userFetching: false
  },
  {
    [setFormState]: (state, { payload }) => {
      state.formState = { ...state.formState, ...payload }
    },
    [requestPossibleCountries]: state => {
      state.countriesFetching = true
    },
    [requestPossibleRoles]: state => {
      state.rolesFetching = true
    },
    [setPossibleCountries]: (state, { payload: { countries = {} } }) => {
      state.countriesFetching = false
      state.possibleCountries = countries
    },
    [setPossibleRoles]: (
      state,
      { payload: { roles = [], attributes = {} } }
    ) => {
      state.rolesFetching = false
      state.rolesAttributes = attributes
    },
    [submitCreate]: state => {
      state.submitting = true
    },
    [completeSubmit]: state => {
      state.submitting = false
      state.formState = {
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        city: '',
        postalCode: '',
        province: '',
        telephone: '',
        company: '',
        jobFunction: '',
        jobTitle: '',
        status: 'active',
        roles: []
      }
    },
    [completeUpdate]: state => {
      state.submitting = false
    },
    [fetchUserRequest]: state => {
      state.userFetching = true
    },
    [fetchUserRequestSuccess]: state => {
      state.userFetching = false
    },
    [fetchUserRequestFail]: state => {
      state.userFetching = false
    }
  }
)

export default usersCreateReducer
