import PropTypes from 'prop-types'
import TextInput from 'components/TextInput'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'utils'
import Checkbox from 'components/Checkbox'
import styles from './ProjectMetadata.module.sass'
import Typeahead from 'modules/typeahead'
import { smartsEndpoints } from 'constants/apiConstants'
import classNames from 'classnames/bind'
import { actions as typeaheadActions } from 'modules/typeahead/TypeaheadReducer'
import useRoleFeatures from 'modules/auth/useRoleFeatures'
import { useEffect } from 'react'

const cx = classNames.bind(styles)

const ProjectMetadata = ({
  formProps,
  hideDefaultCountry = false,
  hideTypeForm = false,
  hideAgency = false,
  showReportName = false,
  isListIngestion = false,
  formName = ''
}) => {
  const {
    listId = '',
    domain,
    clientProduct,
    // brand,
    // agency,
    client,
    masterProject,
    // project,
    // notes,
    // defaultCountry,
    // listMatching,
    targetListCreate,
    targetListCreateListIds,
    noAssociatedListID
  } = formProps.values
  const { setFieldValue } = formProps
  const dispatch = useDispatch()

  const isFirstWordDomain = () => domain === 'FW'

  const {
    features: {
      sections: { actions }
    }
  } = useRoleFeatures({
    coin: formName
  })

  const listMatchDisabled = isListIngestion && !actions.includes('list-match')
  // const deduplicateDisabled =
  //   isListIngestion && !actions.includes('de-duplicate')
  const targetListCreateDisabled =
    isListIngestion && !actions.includes('target-list-create')
  const postalListDisabled = isListIngestion && !actions.includes('postal-list')

  const handleCheckBoxChange = field => {
    if (field === 'listMatching') {
      setFieldValue('listMatching', true)
      setFieldValue('targetListCreate', false)
      setFieldValue('postalList', false)
    } else if (field === 'targetListCreate') {
      setFieldValue('targetListCreate', true)
      setFieldValue('listMatching', false)
      setFieldValue('dedupe', false)
    } else if (field === 'noAssociatedListID') {
      const newNoAssociatedListIDValue = !noAssociatedListID
      if (newNoAssociatedListIDValue) {
        setFieldValue('targetListCreateListIds', [])
      }
    }
  }

  const onDomainChange = () => {
    // Reset typeahead options
    dispatch(
      typeaheadActions.typeaheadRequestReset({
        ids: [`${formName}-clientProduct`, `${formName}-brand`]
      })
    )
    // Reset form values
    setFieldValue('clientProduct', '')
    setFieldValue('brand', '')
  }

  const onClientProductChange = () => {
    // Reset typeahead options
    dispatch(
      typeaheadActions.typeaheadRequestReset({
        ids: [`${formName}-brand`]
      })
    )
    // Reset form values
    setFieldValue('brand', '')
  }

  const onClientChange = () => {
    // Reset typeahead options
    dispatch(
      typeaheadActions.typeaheadRequestReset({
        ids: [
          `${formName}-agency`,
          `${formName}-masterProject`,
          `${formName}-project`
        ]
      })
    )
    // Reset form values
    setFieldValue('agency', '')
    setFieldValue('masterProject', '')
    setFieldValue('project', '')
  }

  const onMasterProjectChange = () => {
    // Reset all typeahead options
    dispatch(
      typeaheadActions.typeaheadRequestReset({
        ids: [`${formName}-project`]
      })
    )
    // Reset form values
    setFieldValue('project', '')
  }

  useEffect(() => {
    if (listMatchDisabled) {
      setFieldValue('targetListCreate', true)
      setFieldValue('listMatching', false)
      setFieldValue('dedupe', false)
    }
  }, [listMatchDisabled, setFieldValue])

  return (
    <div className={styles.container}>
      <Typeahead
        formName={formName}
        className={cx(isListIngestion && styles.fullRow)}
        label="Domain"
        placeholder="Domain"
        name="domain"
        required={!isFirstWordDomain()}
        traditional
        onChange={onDomainChange}
        body={{
          data: {
            uuid: listId
          },
          endpoint: smartsEndpoints.listsMetadataFetchDomainsEndpoint,
          parseFunction: 'optionsParser'
        }}
      />
      <Typeahead
        formName={formName}
        label="Business Group"
        placeholder="Search Business Group"
        name="clientProduct"
        type="text"
        required={!isFirstWordDomain()}
        traditional
        disabled={isEmpty(domain)}
        onChange={onClientProductChange}
        body={{
          data: {
            uuid: listId,
            filters: {
              domain
            }
          },
          endpoint: smartsEndpoints.listsMetadataFetchBusinessGroupsEndpoint,
          parseFunction: 'optionsParser'
        }}
      />
      <Typeahead
        formName={formName}
        label="Business Group Products"
        placeholder="Search Product"
        name="brand"
        type="text"
        required={!isFirstWordDomain()}
        traditional
        disabled={isEmpty(clientProduct) || isFirstWordDomain()}
        body={{
          data: {
            uuid: listId,
            filters: {
              domain,
              clientProduct
            }
          },
          endpoint:
            smartsEndpoints.listsMetadataFetchBusinessGroupsProductsEndpoint,
          parseFunction: 'optionsParser'
        }}
      />
      <Typeahead
        formName={formName}
        label="Client"
        placeholder="Search Client"
        name="client"
        type="text"
        required={!isFirstWordDomain()}
        traditional
        body={{
          data: {
            uuid: listId
          },
          endpoint: smartsEndpoints.listsMetadataFetchClientsEndpoint,
          parseFunction: 'optionsParser'
        }}
        disabled={isFirstWordDomain()}
        onChange={onClientChange}
      />
      {!hideAgency && (
        <Typeahead
          formName={formName}
          label="Agency"
          placeholder="Search Agency"
          name="agency"
          type="text"
          traditional
          disabled={isEmpty(client)}
          body={{
            data: {
              uuid: listId,
              filters: {
                clientId: client
              }
            },
            endpoint: smartsEndpoints.listsMetadataFetchAgencyEndpoint,
            parseFunction: 'optionsParser'
          }}
        />
      )}
      <Typeahead
        formName={formName}
        label="Master Project"
        placeholder="Search Master Project"
        name="masterProject"
        type="text"
        required={!isFirstWordDomain()}
        traditional
        disabled={isEmpty(client) || isFirstWordDomain()}
        body={{
          data: {
            uuid: listId,
            filters: {
              clientId: client
            }
          },
          endpoint: smartsEndpoints.listsMetadataFetchMasterProjectsEndpoint,
          parseFunction: 'optionsParser'
        }}
        onChange={onMasterProjectChange}
      />
      <Typeahead
        formName={formName}
        label="Project"
        placeholder="Search Project"
        name="project"
        type="text"
        traditional
        disabled={['AMI', 'FW'].includes(domain) || isEmpty(masterProject)}
        body={{
          data: {
            uuid: listId
          },
          endpoint: smartsEndpoints.listsMetadataFetchProjectsEndpoint,
          parseFunction: 'optionsParser'
        }}
      />
      {showReportName && (
        <TextInput
          label="Report Name"
          name="reportName"
          type="text"
          required
          traditional
        />
      )}
      <TextInput label="Notes" name="notes" type="text" traditional />
      {!hideDefaultCountry && (
        <Typeahead
          formName={formName}
          label="Default Country"
          placeholder="Search Default Country"
          name="defaultCountry"
          type="text"
          traditional
          body={{
            data: {
              uuid: listId
            },
            endpoint: smartsEndpoints.listsMetadataFetchCountriesEndpoint,
            parseFunction: 'optionsParser'
          }}
        />
      )}
      {!hideTypeForm && (
        <>
          <Checkbox
            label="List Match"
            name="listMatching"
            onChange={() => handleCheckBoxChange('listMatching')}
            disabled={listMatchDisabled}
          />
          <Checkbox
            label="Target List Create"
            name="targetListCreate"
            disabled={domain === 'AMI' || targetListCreateDisabled}
            onChange={() => handleCheckBoxChange('targetListCreate')}
          />
          {/* <Checkbox
            label="Deduplicate"
            name="dedupe"
            disabled={!listMatching || deduplicateDisabled}
            isRadio
          /> */}
          <div />
          <Checkbox
            label="Postal List"
            name="postalList"
            disabled={!targetListCreate || postalListDisabled}
            isRadio
          />
          {targetListCreate && (
            <>
              <Checkbox
                className={styles.fullRow}
                label="No associated List ID"
                name="noAssociatedListID"
                onChange={() => handleCheckBoxChange('noAssociatedListID')}
                isRadio
              />
              <Typeahead
                formName={formName}
                containerClassName={styles.fullRow}
                label="Enter List ID(s) *"
                placeholder="Enter List formName"
                name="targetListCreateListIds"
                traditional
                disabled={noAssociatedListID}
                multiselect
                body={{
                  data: {
                    uuid: listId,
                    filters: {
                      workflow: isListIngestion ? 'ListIngestion' : undefined,
                      selectedLists: targetListCreateListIds
                    }
                  },
                  endpoint: smartsEndpoints.listsMetadataFetchListIdsEndpoint,
                  parseFunction: 'optionsParser'
                }}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

ProjectMetadata.propTypes = {
  /**
   * Form props
   */
  formProps: PropTypes.object,
  /**
   * If true default country will not be shown
   */
  hideDefaultCountry: PropTypes.bool,
  /**
   * If true type section will not be shown
   */
  hideTypeForm: PropTypes.bool,
  /**
   * If true type section will not be shown
   */
  hideAgency: PropTypes.bool,
  /**
   * If true type section will not be shown
   */
  showReportName: PropTypes.bool,
  isListIngestion: PropTypes.bool,
  /**
   * formName to differentiate between multiple Components
   */
  formName: PropTypes.string
}

export default ProjectMetadata
