import { createReducer, createAction } from '@reduxjs/toolkit'

// Combinations Table actions
export const combinationsTableRequest = createAction(
  'COMBINATIONS_TABLE_REQUEST'
)
export const combinationsTableRequestSuccess = createAction(
  'COMBINATIONS_TABLE_REQUEST_SUCCESS'
)
export const combinationsTableRequestFail = createAction(
  'COMBINATIONS_TABLE_REQUEST_FAIL'
)

export const actions = {
  combinationsTableRequest,
  combinationsTableRequestSuccess,
  combinationsTableRequestFail
}

const combinationsTableReducer = createReducer(
  {
    combinationsTable: {},
    tooltips: {},
    fetching: false,
    loadedCountries: false
  },
  {
    [combinationsTableRequest]: state => {
      state.fetching = true
    },
    [combinationsTableRequestSuccess]: (state, { payload }) => {
      state.combinationsTable = payload.combinationsTable
      state.tooltips = payload.tooltips
      state.fetching = false
      state.loadedCountries = true
    },
    [combinationsTableRequestFail]: state => {
      state.combinationsTable = {}
      state.tooltips = {}
      state.fetching = false
    }
  }
)

export default combinationsTableReducer
