import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as universeActions } from './UniverseReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'

/**
 * Request upload universe File
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitFileRequest(services, { payload }) {
  const AdminService = services('AdminService')
  try {
    const { file } = payload
    let formData = new FormData()
    formData.append('file', file[0])
    const response = yield call([AdminService, 'uploadUniverseFile'], formData)
    yield put(
      notificationActions.notificationEnqueue({
        message: response.message,
        duration: 0
      })
    )
    yield put(universeActions.uploadRequestSuccess())
  } catch (e) {
    yield put(universeActions.uploadRequestFail())
    console.error(e)
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
  }
}

/**
 * Request upload universe File
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* downloadFileRequest(services) {
  const AdminService = services('AdminService')
  try {
    yield call([AdminService, 'downloadUniverseFile'])
    yield put(universeActions.downloadRequestSuccess())
  } catch (e) {
    yield put(universeActions.downloadRequestFail())
    console.error(e)
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
  }
}

/**
 * Request upload universe File
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* searchRequest(services, { payload }) {
  const AdminService = services('AdminService')
  try {
    const {
      search: {
        country = '',
        profession = '',
        specialty = '',
        orderBy = '',
        order = '',
        page = 1,
        perPage = 25
      }
    } = payload
    const { data, pagination } = yield call([AdminService, 'searchUniverse'], {
      country,
      profession,
      specialty,
      orderBy,
      order,
      page,
      perPage
    })
    yield put(
      universeActions.searchRequestSuccess({ universe: data, pagination })
    )
  } catch (e) {
    yield put(universeActions.searchRequestFail())
    console.error(e)
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
  }
}

function* specialtyListRequest(services) {
  const AdvisorSearch = services('AdvisorSearchService')
  try {
    const specialties = yield call([AdvisorSearch, 'getSpecialtyList'])
    yield put(universeActions.searchSpecialtiesSuccess(specialties))
  } catch (e) {
    console.error({ specialtiesListError: e })
    yield put(universeActions.searchSpecialtiesFail())
  }
}

function* professionListRequest(services) {
  const AdvisorSearch = services('AdvisorSearchService')
  try {
    const professions = yield call([AdvisorSearch, 'getProfessionList'])
    yield put(universeActions.searchProfessionsSuccess(professions))
  } catch (e) {
    console.error({ professionsListError: e })
    yield put(universeActions.searchProfessionsFail())
  }
}

export default function* watchUniverseRequest(services) {
  yield all([takeEvery('UNIVERSE_UPLOAD_REQUEST', submitFileRequest, services)])
  yield all([
    takeEvery('UNIVERSE_DOWNLOAD_REQUEST', downloadFileRequest, services)
  ])
  yield all([takeEvery('UNIVERSE_SEARCH_REQUEST', searchRequest, services)])
  yield all([
    takeEvery('UNIVERSE_SPECIALTIES_REQUEST', specialtyListRequest, services),
    takeEvery('UNIVERSE_PROFESSIONS_REQUEST', professionListRequest, services)
  ])
}
