import PropTypes from 'prop-types'
import styles from './Divider.module.sass'
import cx from 'classnames'

const Divider = ({ altTheme, error, className, marginLeft, marginRight }) => {
  const themeStyles = { ...styles, ...altTheme }
  return (
    <hr
      style={{
        ...(marginLeft && { marginLeft: marginLeft }),
        ...(marginRight && { marginRight: marginRight })
      }}
      className={cx(themeStyles.full, error && themeStyles.error, className)}
    />
  )
}
Divider.displayName = 'Divider'
Divider.defaultProps = {
  error: false
}
Divider.propTypes = {
  /**
   * An additional custom className for the root element
   */
  className: PropTypes.string,
  /**
   * A CSS modules style object to override default theme
   */
  altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  /**
   * The divider left margin
   */
  marginLeft: PropTypes.number,
  /**
   * The divider right margin
   */
  marginRight: PropTypes.number,
  /**
   * If `true`, the divider displays the 'error' color
   */
  error: PropTypes.bool
}
export default Divider
