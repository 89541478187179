import { createSelector } from '@reduxjs/toolkit'

export const listIngestionSelector = state => state.listIngestionReducer

export const getFormState = () =>
  createSelector(
    listIngestionSelector,
    listIngestion => listIngestion.formState
  )
export const getFetchStatus = () =>
  createSelector(listIngestionSelector, listIngestion => listIngestion.fetching)
export const getOptions = key =>
  createSelector(
    listIngestionSelector,
    listIngestion => listIngestion.options[key] || {}
  )
// Not used, uncomment if needed
// export const getFinishedStatus = () =>
//   createSelector(listIngestionSelector, listIngestion => listIngestion.finished)
