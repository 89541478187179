import { Button as ButtonUI } from 'components/ui/Button'
import PropTypes from 'prop-types'
import styles from './Button.module.sass'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const Button = ({ className, children, isDelete = false, ...rest }) => {
  const { disabled, type = 'button' } = rest
  return (
    <ButtonUI
      className={cx(styles.container, disabled && styles.disabled, className)}
      type={type}
      backgroundColor={isDelete ? '#cb4e4e' : '#25b89b'}
      {...rest}
    >
      {children}
    </ButtonUI>
  )
}
Button.propTypes = {
  /**
   * Extra class name passed to Button container
   */
  className: PropTypes.string,
  /**
   * Component children
   */
  children: PropTypes.node,
  /**
   * Component children
   */
  isDelete: PropTypes.bool
}
export default Button
