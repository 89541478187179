import PropTypes from 'prop-types'
import FormButton from 'components/Dougall/Button/FormButton'
import styles from './Modal.module.sass'
import ModalUI from 'components/Dougall/ui/Modal'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'

/**
 * Close Button
 * forwardProps: customStyles
 */
const CloseButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'customStyles'
})(({ customStyles }) => ({
  margin: 0,
  padding: 0,
  ...customStyles
}))

const Modal = ({
  open,
  message,
  onCancel,
  onConfirm,
  actionCaption = '',
  actionType = 'submit',
  submitting,
  customActions,
  children,
  closeButtonStyles,
  closeButtonIconSize = 24,
  altButtonTheme: propButtonTheme
}) => {
  const altButtonTheme = {
    marginTop: '20px',
    textTransform: 'none',
    width: '100%',
    fontSize: '12px',
    borderRadius: '7px',
    ...propButtonTheme
  }
  return (
    <ModalUI
      modalOpen={open}
      hasBackdropBlur
      modalType={1}
      className={styles.modal}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.closeContainer}>
            <CloseButton customStyles={closeButtonStyles} onClick={onCancel}>
              <CloseIcon
                sx={{
                  fontSize: closeButtonIconSize,
                  marginRight: 0,
                  transform: 'none',
                  color: '#00000080'
                }}
              />
            </CloseButton>
          </div>
          {children}
          {customActions ? (
            <div className={styles.buttonContainer}>{customActions}</div>
          ) : (
            <FormButton
              type="submit"
              loading={submitting}
              disabled={submitting}
              altButtonTheme={altButtonTheme}
              onClick={onConfirm}
              onMouseDown={event => {
                event.preventDefault()
              }}
            >
              {actionCaption}
            </FormButton>
          )}
        </div>
      </div>
    </ModalUI>
  )
}
Modal.propTypes = {
  /**
   * Display modal
   */
  open: PropTypes.bool,
  /**
   * Message of the modal
   */
  message: PropTypes.string,
  /**
   * cancel callback
   */
  onCancel: PropTypes.func,
  /**
   * Confirm callback
   */
  onConfirm: PropTypes.func,
  /**
   * Action Type, changes confirm action color
   */
  actionType: PropTypes.oneOf(['delete', 'submit']),
  /**
   * Action caption
   */
  actionCaption: PropTypes.string,
  submitting: PropTypes.bool,
  children: PropTypes.node,
  customActions: PropTypes.node,
  /**
   * Custom styles to be passed to the closeButton container
   */
  closeButtonStyles: PropTypes.object,
  closeButtonIconSize: PropTypes.number,
  altButtonTheme: PropTypes.object
}
export default Modal
