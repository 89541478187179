import { createSelector } from '@reduxjs/toolkit'

export const jobStatusSingleSelector = state => state.jobStatusSingleReducer
export const getJobStatusSingle = () =>
  createSelector(
    jobStatusSingleSelector,
    jobStatusSingle => jobStatusSingle.jobStatus
  )
export const getFetchingStatus = () =>
  createSelector(
    jobStatusSingleSelector,
    jobStatusSingle => jobStatusSingle.fetching
  )
export const getErrorStatus = () =>
  createSelector(
    jobStatusSingleSelector,
    jobStatusSingle => jobStatusSingle.error
  )
export const getEmailsFetchingStatus = () =>
  createSelector(
    jobStatusSingleSelector,
    jobStatusSingle => jobStatusSingle.emailsFetching
  )
export const getGroupsFetchingStatus = () =>
  createSelector(
    jobStatusSingleSelector,
    jobStatusSingle => jobStatusSingle.groupsFetching
  )
export const getFilesFetchingStatus = () =>
  createSelector(
    jobStatusSingleSelector,
    jobStatusSingle => jobStatusSingle.filesFetching
  )
