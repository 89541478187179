import SvgIcon from '@mui/material/SvgIcon'

const DougallLogoWhite = props => (
  <SvgIcon viewBox="0 0 129 26" fill="none" {...props}>
    <path
      fill="#ffffff"
      d="M27.43,19.8V6.61h4.68c1.07,0,2,.17,2.8.52.8.35,1.47.82,2.01,1.41.54.6.94,1.29,1.22,2.09.27.8.41,1.65.41,2.56,0,1-.15,1.91-.46,2.72-.3.81-.73,1.51-1.29,2.08-.56.58-1.23,1.02-2.03,1.34-.79.32-1.68.47-2.66.47h-4.68ZM36.42,13.19c0-.69-.1-1.33-.29-1.91-.19-.58-.47-1.08-.85-1.5-.37-.42-.82-.74-1.36-.98-.53-.24-1.14-.35-1.82-.35h-2.6v9.52h2.6c.69,0,1.31-.12,1.85-.36.54-.24.99-.58,1.36-1,.37-.43.64-.93.83-1.51.19-.58.28-1.21.28-1.9Z"
    />
    <path
      fill="#ffffff"
      d="M44.5,19.99c-.77,0-1.46-.14-2.07-.41-.61-.27-1.14-.64-1.57-1.1-.43-.46-.77-.99-1-1.61-.24-.61-.35-1.25-.35-1.92s.12-1.31.35-1.92c.23-.61.57-1.15,1.01-1.61.44-.46.97-.82,1.58-1.1.61-.27,1.3-.41,2.05-.41s1.44.14,2.05.41c.61.27,1.14.64,1.58,1.1.44.46.78.99,1.01,1.61.23.61.35,1.25.35,1.92s-.12,1.31-.35,1.92c-.24.61-.57,1.15-1.01,1.61-.44.46-.97.82-1.58,1.1-.61.27-1.3.41-2.05.41ZM41.6,14.97c0,.47.07.91.22,1.3.15.4.35.74.61,1.03s.57.52.92.69.73.25,1.14.25.79-.08,1.14-.25c.35-.17.66-.4.92-.7.26-.3.46-.64.61-1.04.15-.4.22-.83.22-1.3s-.07-.89-.22-1.29c-.15-.4-.35-.75-.61-1.05-.26-.3-.57-.53-.92-.7-.35-.17-.73-.25-1.14-.25s-.77.09-1.12.26c-.35.17-.66.41-.92.71-.26.3-.47.65-.62,1.05-.16.4-.23.83-.23,1.29Z"
    />
    <path
      fill="#ffffff"
      d="M53.87,19.99c-1.02,0-1.78-.33-2.3-.99s-.78-1.65-.78-2.97v-5.95h2.04v5.54c0,1.75.62,2.62,1.86,2.62.58,0,1.13-.18,1.65-.54s.92-.88,1.21-1.56v-6.06h2.04v7.31c0,.23.04.4.12.5.08.1.23.16.44.17v1.75c-.21.04-.38.06-.52.07-.14.01-.27.02-.39.02-.37,0-.69-.1-.95-.31s-.4-.47-.41-.79l-.04-.97c-.43.71-1,1.24-1.69,1.61-.69.37-1.46.55-2.29.55Z"
    />
    <path
      fill="#ffffff"
      d="M65.79,19.9c-.66,0-1.26-.13-1.8-.4-.55-.27-1.01-.63-1.4-1.09-.39-.46-.69-.99-.91-1.58-.22-.6-.33-1.22-.33-1.88,0-.69.11-1.35.33-1.96.22-.61.53-1.15.93-1.6.4-.45.87-.81,1.42-1.08.55-.27,1.16-.4,1.83-.4.78,0,1.47.19,2.05.56.59.37,1.08.86,1.46,1.45v-1.84h1.8v9.44c0,.71-.13,1.33-.39,1.88-.26.54-.62,1.01-1.09,1.38-.46.38-1.01.67-1.65.86-.63.2-1.31.3-2.04.3-1.08,0-1.97-.18-2.69-.55-.71-.37-1.31-.88-1.79-1.53l1.15-1.08c.37.52.85.92,1.44,1.19s1.22.41,1.89.41c.42,0,.82-.06,1.2-.17.38-.11.71-.29.99-.52.28-.24.51-.53.68-.89s.25-.79.25-1.28v-1.49c-.35.6-.82,1.05-1.43,1.38-.61.32-1.25.48-1.91.48ZM66.46,18.24c.31,0,.61-.05.89-.16s.55-.25.79-.43c.24-.18.45-.38.62-.61.17-.23.3-.47.37-.73v-2.55c-.12-.31-.28-.59-.48-.85-.2-.25-.42-.47-.68-.66-.25-.19-.52-.33-.81-.44-.29-.11-.57-.16-.86-.16-.45,0-.85.1-1.2.29s-.65.45-.9.76c-.25.32-.44.67-.58,1.07-.14.4-.2.81-.2,1.23,0,.45.08.86.23,1.25.16.39.37.73.64,1.02.27.29.59.52.96.7.37.17.77.26,1.2.26Z"
    />
    <path
      fill="#ffffff"
      d="M75.79,19.99c-.47,0-.91-.08-1.31-.23-.4-.16-.75-.37-1.04-.64-.29-.27-.52-.6-.69-.97-.17-.37-.25-.77-.25-1.21,0-.46.1-.87.3-1.25.2-.37.48-.69.85-.97.37-.27.8-.48,1.3-.63s1.06-.22,1.66-.22c.46,0,.91.04,1.36.12s.85.19,1.21.33v-.67c0-.72-.2-1.28-.61-1.68-.41-.4-1-.6-1.77-.6-.52,0-1.03.1-1.53.29-.5.19-1.02.47-1.55.83l-.63-1.3c1.25-.84,2.55-1.26,3.9-1.26s2.38.34,3.12,1.02c.74.68,1.12,1.66,1.12,2.94v3.51c0,.43.18.66.54.67v1.75c-.2.04-.37.06-.51.07-.14.01-.28.02-.42.02-.4,0-.7-.1-.92-.3-.22-.2-.35-.45-.4-.76l-.06-.61c-.43.57-.97,1.01-1.61,1.31-.64.3-1.32.46-2.05.46ZM76.34,18.48c.5,0,.96-.09,1.4-.27s.78-.43,1.03-.75c.26-.22.39-.46.39-.71v-1.28c-.35-.14-.72-.24-1.11-.32-.39-.07-.77-.11-1.14-.11-.73,0-1.33.16-1.79.47-.46.32-.7.73-.7,1.25,0,.48.18.89.54,1.22.36.33.82.49,1.38.49Z"
    />
    <path
      fill="#ffffff"
      d="M83.22,6.23h2.04v10.76c0,.36.09.64.28.85s.45.31.78.31c.14,0,.29-.02.47-.07s.35-.11.51-.17l.3,1.6c-.3.14-.65.25-1.06.32-.41.08-.77.12-1.1.12-.71,0-1.25-.19-1.64-.58-.39-.38-.59-.93-.59-1.64V6.23Z"
    />
    <path
      fill="#ffffff"
      d="M88.48,6.23h2.04v10.76c0,.36.09.64.28.85s.45.31.78.31c.14,0,.29-.02.47-.07s.35-.11.51-.17l.3,1.6c-.3.14-.65.25-1.06.32-.41.08-.77.12-1.1.12-.71,0-1.25-.19-1.64-.58-.39-.38-.59-.93-.59-1.64V6.23Z"
    />
    <path
      fill="#c50201"
      d="M97.7,19.8V6.61h5.54c.6,0,1.14.12,1.65.37.5.25.93.57,1.29.98.36.4.64.86.85,1.38.2.51.31,1.04.31,1.57s-.1,1.09-.29,1.61c-.19.51-.46.97-.81,1.38s-.77.73-1.25.97c-.49.24-1.03.36-1.63.36h-3.57v4.59h-2.08ZM99.78,13.37h3.44c.3,0,.57-.06.81-.19.24-.12.45-.3.62-.52.17-.22.31-.48.41-.78.1-.3.15-.63.15-.99s-.06-.71-.17-1c-.11-.3-.27-.55-.46-.77-.2-.22-.42-.38-.68-.5-.25-.12-.52-.18-.79-.18h-3.33v4.93Z"
    />
    <path
      fill="#c50201"
      d="M116.61,9.71c-.12-.14-.3-.28-.54-.44-.23-.15-.51-.3-.83-.44-.32-.14-.66-.25-1.04-.33s-.77-.13-1.16-.13c-.88,0-1.53.16-1.94.48-.42.32-.62.77-.62,1.34,0,.32.07.59.2.79.13.2.33.38.6.54.27.16.61.29,1.02.41.41.12.89.25,1.43.38.67.16,1.27.34,1.81.54.54.2.99.44,1.37.73s.66.63.86,1.05c.2.42.31.92.31,1.52,0,.67-.13,1.25-.38,1.73s-.6.88-1.04,1.18c-.44.3-.95.53-1.53.67-.58.14-1.21.21-1.88.21-1,0-1.97-.15-2.91-.46s-1.78-.75-2.54-1.33l.95-1.78c.16.16.39.34.7.54.3.2.66.38,1.06.55s.84.31,1.31.43c.47.12.96.18,1.47.18.82,0,1.45-.14,1.9-.43.45-.28.67-.71.67-1.26,0-.33-.08-.61-.25-.84-.17-.22-.41-.42-.72-.58s-.69-.32-1.13-.46c-.45-.14-.95-.28-1.52-.43-.66-.17-1.23-.36-1.72-.55s-.9-.42-1.22-.7c-.32-.27-.57-.59-.73-.96-.17-.37-.25-.81-.25-1.33,0-.64.12-1.22.37-1.72.25-.5.58-.92,1.01-1.26.43-.34.93-.6,1.51-.77.58-.17,1.21-.26,1.9-.26.89,0,1.72.14,2.47.43.76.29,1.42.64,1.99,1.06l-.95,1.71Z"
    />
    <path fill="#c50201" d="M119.73,19.8V6.61h2.08v11.36h7.08v1.84h-9.16Z" />
    <path
      fill="#c50201"
      d="M21,8.01c-.54-1.57-1.33-2.94-2.39-4.1-1.06-1.17-2.37-2.09-3.94-2.77C13.11.45,11.28.11,9.19.11H0v11.15h6.78l-1.38-3.98c-.12-.34.25-.64.56-.46l10.28,5.9c.26.15.26.52,0,.67l-10.28,5.91c-.31.18-.67-.12-.56-.46l1.38-3.98H0v11.15h9.19c1.92,0,3.66-.31,5.21-.93,1.56-.62,2.88-1.49,3.97-2.62,1.09-1.13,1.94-2.49,2.53-4.08.6-1.59.89-3.37.89-5.34,0-1.77-.27-3.45-.8-5.01Z"
    />
  </SvgIcon>
)

export default DougallLogoWhite
