import axios from 'axios'
import { isEmpty } from 'utils'
import { authEndpoints, smartsEndpoints, getFaqEndpoint } from '../constants'
import { ChartsParser, BreakdownChartsParser } from './ChartsParser'
import SmartsMLError from './SmartsMLError'

/**
 * Smarts endpoints Service
 */
const SmartsService = (SmartsParser, SmartsML) => ({
  /**
   * Get a signedURl to upload the file to S3
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async getSignedUrl(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.listsUploadSignedUrl,
      'POST',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Call Signed URL endpoint
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async signedUrl(url, data) {
    const response = await axios({
      url,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).catch(error => {
      if (
        error.response?.data?.message &&
        error.response.data.status !== 'OK'
      ) {
        const { data: responseError } = error.response
        const err = new SmartsMLError(responseError.message, responseError)
        throw err
      }
      throw error
    })
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Upload list ingestion file
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async uploadFile(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.listsUploadEndpoint,
      'POST',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Specifies configuration for an uploaded file list
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async setConfig(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.listsUploadEndpoint,
      'PUT',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },

  /**
   * Updates Project Metadata
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async setProjectMetadata(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.listsMetadataEndpoint,
      'PUT',
      SmartsParser.projectMetadataBodyParser(data)
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Fetch file headers
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async fetchFileHeading(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.listsFetchFileHeading,
      'POST',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Set file headers
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async setFileHeading(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.listsSetFileHeading,
      'PUT',
      SmartsParser.basicHeadingParser(data)
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Validate list
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async validateList(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.listsSubmit,
      'POST',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Submit List
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async submitList(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.listsSubmit,
      'PUT',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },
  /**
   * Create List
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async listsCreate(data) {
    const response = await SmartsML.fetch(smartsEndpoints.listsCreate, 'PUT', {
      ...data,
      combinations: SmartsParser.combinationsParser(data.combinations),
      filters: SmartsParser.filtersParser(data.filters)
    })
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * List Fetch
   * @param listId
   * @returns {Promise<*[]|*>}
   */
  async listsGet(listId) {
    const response = await SmartsML.fetch(
      `${smartsEndpoints.listsFetch}/${listId}`,
      'GET',
      {}
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },

  /**
   * Get Brand Options
   */
  async getBrands() {
    const response = await SmartsML.fetch(
      smartsEndpoints.variationExportBrandsEndpoint,
      'POST',
      {}
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * List Creation or Update
   * @param listId
   * @returns {Promise<*[]|*>}
   */
  async listCreateUpdate(data) {
    const response = await SmartsML.fetch(smartsEndpoints.listsFetch, 'PUT', {
      ...data,
      combinations: SmartsParser.combinationsParser(data.combinations),
      filters: SmartsParser.filtersParser(data.filters)
    })
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * List Creation or Update
   * @param listId
   * @returns {Promise<*[]|*>}
   */
  async fetchFilters() {
    const response = await SmartsML.fetch(
      smartsEndpoints.listFilters,
      'POST',
      {}
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Charts Analysis
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async listsChartsAnalysis(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.listsChartsAnalysis,
      'POST',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Chart Generation
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async listsChartsGeneration(uuid) {
    const response = await SmartsML.fetch(smartsEndpoints.listsCharts, 'POST', {
      uuid
    })

    if (!isEmpty(response?.data)) {
      // Response data is parsed for better readability and data management in Charts
      const parsedResponse = ChartsParser(response.data.responseData)

      return parsedResponse
    }
    return {}
  },
  /**
   * Breakdown Charts Generation
   * @param uuid
   * @returns {Promise<*[]|*>}
   */
  async listsBreakdownChartsGeneration(uuid) {
    const response = await SmartsML.fetch(
      smartsEndpoints.listsBreakdownCharts,
      'POST',
      {
        uuid
      }
    )

    if (!isEmpty(response?.data)) {
      // Response data is parsed for better readability and data management in Charts
      const parsedResponse = BreakdownChartsParser(response.data.responseData)

      return parsedResponse
    }
    return {}
  },
  /**
   * Charts Upload
   * @param data - multipart/formData array containing images to upload
   * @returns {Promise<*[]|*>}
   */
  async listsChartsUpload(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.listsChartsUpload,
      'PUT',
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Image Convert
   * @param base64Img - Img encoded in base64
   * @param name - name to give to the file
   * @returns File
   */
  async convertImgToFile(base64Img, name) {
    return fetch(base64Img)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], name, { type: 'image/png' })
        return file
      })
  },
  /**
   * Share Chart
   * @param data
   * @returns
   */
  async listsChartShare(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.chartsShare,
      'POST',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.status
    }
    return {}
  },
  async sendFeedback(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.feedback,
      'POST',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.message
    }
    return {}
  },
  async sendFeedbackDougall(data, token) {
    const response = await SmartsML.fetchDougall(
      authEndpoints.feedbackEndpoint,
      'POST',
      data,
      token
    )
    if (!isEmpty(response?.data)) {
      return response.data.message
    }
    return {}
  },
  async listCoins(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.listCoins,
      'POST',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  async redirectLab(email, role, uuid) {
    const response = await SmartsML.fetch(
      `${authEndpoints.limaLabEndpoint}?email=${email}&${role}&limaid=${uuid}`,
      'GET',
      {}
    )
    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },
  async fetchFaq(payload) {
    const response = await SmartsML.fetch(
      getFaqEndpoint,
      'POST',
      JSON.stringify(payload),
      {
        timeout: 300000
      }
    )
    return response
  },
  /**
   * Combinations Fetch All
   * @returns {Promise<*[]|*>}
   */
  async combinationsFetchAll() {
    const response = await SmartsML.fetch(
      smartsEndpoints.listsCombinationsFetchAll,
      'POST',
      {}
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * GPT Token Request
   * @param {*} promptId
   * @returns
   */
  async gptTokenRequest() {
    const response = await SmartsML.fetch(smartsEndpoints.gptToken, 'GET')

    if (!isEmpty(response?.data)) {
      // return response.data.responseData
      return response.data
    }
    return {}
  }
})

export default SmartsService
