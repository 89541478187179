import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as themeActions } from './ThemeProviderReducer'
import { getActive, getThemeList } from './ThemeProviderSelector'
import { createTheme } from '@mui/material/styles'
import gpt from 'styles/themes/dougallgpt'
import md from 'styles/themes/dougallmd'
/**
 * Theme Hook, allow us to reuse the theme logic through the project
 * @returns {{getActiveTheme}}
 */
const useTheme = () => {
  const dispatch = useDispatch()

  // Selectors
  const active = useSelector(getActive())
  const list = useSelector(getThemeList())

  // Triggers
  const change = theme =>
    dispatch(themeActions.themeRequestChange({ active: theme }))

  /**
   * Adding a new theme:
   * 1. Create a new styles/themes/theme.js file
   * 2. Add the desired overrides
   * 3. Add it to the switch statement
   * 4. Add it to the valid theme list on the site.config.json file
   * 4. Enable the theme from the site.config.json file
   *
   * See the default theme values: https://mui.com/material-ui/customization/default-theme/
   * @param theme - theme name
   * @returns {Theme|{}} - theme object
   */
  const create = theme => {
    switch (theme) {
      case 'dougallgpt':
        return createTheme(gpt)
      case 'dougallmd':
        return createTheme(md)
      default:
        return {}
    }
  }

  // Constructor
  useEffect(() => {
    dispatch(themeActions.themeRequest())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return { active, create, list, change }
}

export default useTheme
