import { Route, Switch } from 'react-router-dom'
import LoginWithEmail from 'modules/login/LoginWithEmail'
import LoginWithEmailSuccess from 'modules/login/LoginWithEmailSuccess'
import ListIngestion from 'modules/listIngestion'
import ExploreMarket from 'modules/exploreMarket'
import AdvisorList from 'modules/advisorList'
import AdvisorSearch from 'modules/formAdvisorSearch'
import ExploreList from 'modules/exploreList'
import ExploreProject from 'modules/exploreProject'
import SoundBoard from 'modules/soundboard'
import AuthControl from 'modules/auth/AuthControl'
import FaqPage from 'modules/faq'
import JobStatusList from 'modules/jobStatusList/JobStatusList'
import JobStatusSingle from 'modules/jobStatusSingle/JobStatusSingle'
import CompareLists from 'modules/compareLists'
import Logout from 'modules/logout'
import ContactUs from 'modules/contactUs'
import Home from 'modules/home'
import TypographyDemo from './components/ui/Typography/TypographyDemo'
import ExportList from 'modules/exportList'
import LimaLab from 'modules/lab'
import siteConfig from 'site.config.json'
import AdvisorSchedule from 'modules/formAdvisorSchedule'
import AdvisorProfile from 'modules/advisorProfile/AdvisorProfile'
import AdvisorProfileSummary from 'modules/advisorProfile/AdvisorProfileSummary'
import Universe from 'modules/admin/universe/Universe'
import UniverseHistory from 'modules/admin/universeHistory/UniverseHistory'
import SingleUniverseHistory from 'modules/admin/universeHistory/SingleUniverseHistory'
import Threshold from 'modules/admin/threshold/Threshold'
import SingleThreshold from 'modules/admin/threshold/SingleThreshold'
import IncomingCounts from 'modules/admin/incomingCounts/IncomingCounts'
import Users from 'modules/admin/usersList/Users'
import UsersSearch from 'modules/admin/usersSearch/UsersSearch'
import UsersCreate from 'modules/admin/usersCreate/UsersCreate'
import UsersBulkCreate from 'modules/admin/usersBulkCreate/UsersBulkCreate'
import AdminPage from 'components/Admin/Page'
import Variables from 'modules/admin/variables/Variables'
import SingleVariable from 'modules/admin/variables/SingleVariable'
import DataCaches from 'modules/admin/dataCaches/DataCaches'
// import ChartsGeneric from 'modules/admin/chartsGeneric/ChartsGeneric'
// import ChartsSearch from 'modules/admin/chartsSearch/ChartsSearch'
// import SingleChart from 'modules/admin/chartsSearch/SingleChart'
import { default as DougallHome } from 'modules/dougall/home/Home'
import FeedbackForm from 'modules/dougall/feedback/FeedbackForm'

const { routes, sections } = siteConfig

const Routes = () => {
  const routeList = [
    {
      auth: routes.advisorSearch.auth,
      name: routes.advisorSearch.name,
      path: routes.advisorSearch.path,
      component: AdvisorSearch
    },
    {
      auth: routes.advisorSchedule.auth,
      name: routes.advisorSchedule.name,
      path: routes.advisorSchedule.path,
      component: AdvisorSchedule
    },
    {
      auth: routes.advisorList.auth,
      name: routes.advisorList.name,
      path: routes.advisorList.path,
      component: AdvisorList
    },
    {
      auth: routes.advisorProfile.auth,
      name: routes.advisorProfile.name,
      path: routes.advisorProfile.path,
      component: AdvisorProfileSummary
    },
    {
      auth: routes.advisorProfileFull.auth,
      name: routes.advisorProfileFull.name,
      path: routes.advisorProfileFull.path,
      component: AdvisorProfile
    },
    {
      auth: routes.emailLogin.auth,
      name: routes.emailLogin.name,
      path: routes.emailLogin.path,
      component: LoginWithEmailSuccess
    },
    {
      auth: routes.login.auth,
      name: routes.login.name,
      path: routes.login.path,
      component: LoginWithEmail
    },
    {
      auth: routes.home.auth,
      name: routes.home.name,
      path: routes.home.path,
      component: Home
    },
    {
      auth: routes.listIngestion.auth,
      name: routes.listIngestion.name,
      path: routes.listIngestion.path,
      component: ListIngestion
    },
    {
      auth: routes.exploreMarket.auth,
      name: routes.exploreMarket.name,
      path: routes.exploreMarket.path,
      component: ExploreMarket
    },
    {
      auth: routes.exploreList.auth,
      name: routes.exploreList.name,
      path: routes.exploreList.path,
      component: ExploreList
    },
    {
      auth: routes.exploreProject.auth,
      name: routes.exploreProject.name,
      path: routes.exploreProject.path,
      component: ExploreProject
    },
    {
      auth: routes.exportList.auth,
      name: routes.exportList.name,
      path: routes.exportList.path,
      component: ExportList
    },
    {
      auth: routes.faq.auth,
      name: routes.faq.name,
      path: routes.faq.path,
      component: FaqPage
    },
    {
      auth: routes.jobStatus.auth,
      name: routes.jobStatus.name,
      path: routes.jobStatus.path,
      component: JobStatusList
    },
    {
      auth: routes.jobStatus.auth,
      name: routes.jobStatus.name,
      path: `${routes.jobStatus.path}/:jobid`,
      component: JobStatusSingle
    },
    {
      auth: routes.soundboard.auth,
      name: routes.soundboard.name,
      path: routes.soundboard.path,
      component: SoundBoard
    },
    {
      auth: routes.compareLists.auth,
      name: routes.compareLists.name,
      path: `${routes.compareLists.path}`,
      component: CompareLists
    },
    {
      auth: routes.limaLab.auth,
      name: routes.limaLab.name,
      path: `${routes.limaLab.path}`,
      component: LimaLab
    },
    {
      auth: routes.logout.auth,
      name: routes.logout.name,
      path: routes.logout.path,
      component: Logout
    },
    {
      auth: routes.contactUs.auth,
      name: routes.contactUs.name,
      path: routes.contactUs.path,
      component: ContactUs
    },
    {
      auth: routes.limaGPT.auth,
      name: routes.limaGPT.name,
      path: routes.limaGPT.path,
      component: DougallHome
    },
    {
      auth: routes.feedback?.auth,
      name: routes.feedback?.name,
      path: routes.feedback?.path,
      component: FeedbackForm,
      componentProps: {
        title: sections.feedback.form?.dougallGpt?.title,
        subHeader: sections.feedback.form?.dougallGpt?.subheader,
        brandType: sections.feedback.form?.dougallGpt?.brandType
      }
    }
  ]
  const adminRoutes = [
    {
      auth: routes.adminUsers.auth,
      name: routes.adminUsers.name,
      path: routes.adminUsers.path,
      component: Users
    },
    {
      auth: routes.adminUsersSearch.auth,
      name: routes.adminUsersSearch.name,
      path: routes.adminUsersSearch.path,
      component: UsersSearch
    },
    {
      auth: routes.adminUsersCreate.auth,
      name: routes.adminUsersCreate.name,
      path: routes.adminUsersCreate.path,
      component: UsersCreate
    },
    {
      auth: routes.adminUsersEdit.auth,
      name: routes.adminUsersEdit.name,
      path: routes.adminUsersEdit.path,
      component: UsersCreate
    },
    {
      auth: routes.adminUsersBulkCreate.auth,
      name: routes.adminUsersBulkCreate.name,
      path: routes.adminUsersBulkCreate.path,
      component: UsersBulkCreate
    },
    {
      auth: routes.adminUniverse.auth,
      name: routes.adminUniverse.name,
      path: routes.adminUniverse.path,
      component: Universe
    },
    {
      auth: routes.adminUniverseHistory.auth,
      name: routes.adminUniverseHistory.name,
      path: routes.adminUniverseHistory.path,
      component: UniverseHistory
    },
    {
      auth: routes.adminUniverseHistory.auth,
      name: routes.adminUniverseHistory.name,
      path: `${routes.adminUniverseHistory.path}/:universeHistoryId`,
      component: SingleUniverseHistory
    },
    {
      auth: routes.adminThreshold.auth,
      name: routes.adminThreshold.name,
      path: routes.adminThreshold.path,
      component: Threshold
    },
    {
      auth: routes.adminThreshold.auth,
      name: routes.adminThreshold.name,
      path: `${routes.adminThreshold.path}/:thresholdId`,
      component: SingleThreshold
    },
    {
      auth: routes.adminIncoming.auth,
      name: routes.adminIncoming.name,
      path: routes.adminIncoming.path,
      component: IncomingCounts
    },
    {
      auth: routes.adminVariables.auth,
      name: routes.adminVariables.name,
      path: routes.adminVariables.path,
      component: Variables
    },
    {
      auth: routes.adminVariables.auth,
      name: routes.adminVariables.name,
      path: `${routes.adminVariables.path}/:variableId`,
      component: SingleVariable
    },
    {
      auth: routes.adminDataCaches.auth,
      name: routes.adminDataCaches.name,
      path: routes.adminDataCaches.path,
      component: DataCaches
    }
    // {
    //   auth: routes.adminChartsGeneric.auth,
    //   name: routes.adminChartsGeneric.name,
    //   path: routes.adminChartsGeneric.path,
    //   component: ChartsGeneric
    // },
    // {
    //   auth: routes.adminChartsSearch.auth,
    //   name: routes.adminChartsSearch.name,
    //   path: routes.adminChartsSearch.path,
    //   component: ChartsSearch
    // },
    // {
    //   auth: routes.adminChartsSearch.auth,
    //   name: routes.adminChartsSearch.name,
    //   path: `${routes.adminChartsSearch.path}/:chartId`,
    //   component: SingleChart
    // }
  ]
  return (
    <Switch>
      {routeList.map((route, key) => {
        return <AuthControl exact key={key + 'screen'} {...route} />
      })}
      <Route exact path={['/admin/*', '/admin']}>
        <AdminPage>
          <Switch>
            {adminRoutes.map(adminRoute => {
              return (
                <AuthControl
                  exact
                  isAdmin
                  key={adminRoute.name + 'screen'}
                  {...adminRoute}
                />
              )
            })}
          </Switch>
        </AdminPage>
      </Route>
      <Route path="/typo" exact component={TypographyDemo} />
    </Switch>
  )
}

export default Routes
