import PropTypes from 'prop-types'
import Typography from '../Typography'
import styles from './ListItemText.module.sass'
import cx from 'classnames'

const ListItemText = (
  { primary, secondary, className, type = 'list-primary', ...other },
  { dense }
) => {
  return (
    <div
      data-testid="listItemText"
      className={cx(styles.text, dense && styles.dense, className)}
      {...other}
    >
      {primary && <Typography type={type}>{primary}</Typography>}
      {secondary && <Typography type="list-secondary">{secondary}</Typography>}
    </div>
  )
}
ListItemText.displayName = 'ListItemText'
ListItemText.defaultProps = {}
ListItemText.propTypes = {
  /**
   * An additional custom className for the root element
   */
  className: PropTypes.string,
  /**
   * The primary list item text
   */
  primary: PropTypes.node,
  /**
   * Secondary list item text
   */
  secondary: PropTypes.node,
  /**
   * The typography type used for the primary text
   */
  type: PropTypes.string
}
ListItemText.contextTypes = {
  /**
   * If `true`, compact vertical padding designed for keyboard and mouse input will be used.
   */
  dense: PropTypes.bool
}
export default ListItemText
