import { takeEvery, all, put, call, select, fork } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { actions as authActions } from './AuthReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as combinationsActions } from 'modules/CombinationsTable/CombinationsTableReducer'
import { deviceIdFull, getDeviceId } from 'modules/deviceId/DeviceIdSaga'
import { getAccessToken, getAuthFailedMsg, getAuthState } from './AuthSelector'
import { authEndpoints, roleLevel } from '../../constants'
import { isEmpty, parseJwt } from 'utils'
import queryString from 'query-string'
import history from '../../history'
import siteConfig from 'site.config.json'

const { configuration, routes } = siteConfig

const { deviceID } = configuration

/**
 * Auth API Middleware
 * @param services
 * @param reqPathName - Path identifier, for role-table path lookup
 * @param reqAuth - Trigger path lookup
 */
function* authRequest(services, { payload: { reqPathName, reqAuth } }) {
  try {
    const CookieService = services('CookieService')
    const {
      location: { search, pathname, state }
    } = history

    // GET DEVICE ID
    if (deviceID.sendOnOtp) {
      // if sendOnOtp required, request DeviceId
      yield call(deviceIdFull, services, deviceID.useCacheOnRefresh)
    }

    // EXTRACT CACHE
    const [cookieState, access, refresh] = yield all([
      call([CookieService, 'getFromCache'], 'state'),
      call([CookieService, 'getFromCache'], 'access'),
      call([CookieService, 'getFromCache'], 'refresh')
    ])

    const cacheToken = { state: cookieState, access, refresh }

    // EXTRACT TOKEN - CLEAN URL
    const parsedParams = queryString.parse(search)
    yield call([CookieService, 'saveToCache'], { ...parsedParams })
    yield put(replace({ pathname, search: '', hash: '', state: state }))

    // ATTEMPT ALL THE WEIRD STUFF TO LOGIN USER
    try {
      return yield call(authExec, {
        services,
        reqPathName,
        reqAuth,
        parsedParams,
        search,
        cacheToken
      })
    } catch (error) {
      console.warn(error)
    }

    // IF LOGIN FAILS - ANON LOGIN
    //console.log('5. IF LOGIN FAILS - ANON LOGIN')
    return yield call(authAnon, {
      reqPathName,
      reqAuth,
      services
    })
  } catch (e) {
    console.error(e)
    yield put(authActions.authFailedMsg({ msg: 'Auth failed' }))
    yield put(authActions.authRequestFail())
    yield call(logoutRequest, services)
  } finally {
    const TrackingService = services('TrackingService')

    // TRIGGER ERROR NOTIFICATION
    if (!isEmpty(yield select(getAuthFailedMsg())))
      yield put(
        notificationActions.notificationEnqueue({
          message: yield select(getAuthFailedMsg()),
          duration: 0
        })
      )
    // INIT PIWIK TRACKING VARIABLES
    yield call([TrackingService, 'initializeTrackingVars'])
  }
}

/**
 * Executes Auth API Calls for OTP, DP or Cache
 * Follow Role and Auth Type hierarchy logic
 * @param services
 * @param reqPathName
 * @param reqAuth
 * @param parsedParams - URL query string to JSON
 * @param search
 * @param cacheToken
 */
function* authExec({
  services,
  reqPathName,
  reqAuth,
  parsedParams,
  search,
  cacheToken
}) {
  try {
    //console.log(`ATTEMPTING AUTH WITH VER.`)

    // IF NO CACHE & NO TOKEN - ATTEMPT ANON LOGIN
    if (
      isEmpty(cacheToken.state) &&
      isEmpty(cacheToken.access) &&
      isEmpty(cacheToken.refresh) &&
      isEmpty(search)
    ) {
      //console.log('1. IF NO CACHE & NO TOKEN - ATTEMPT ANON LOGIN')
      return yield call(authAnon, {
        reqPathName,
        reqAuth,
        services
      })
    }

    // IF NO TOKEN & CACHE - ATTEMPT CACHE LOGIN
    if (
      !isEmpty(cacheToken.access) &&
      !isEmpty(cacheToken.refresh) &&
      isEmpty(search)
    ) {
      //console.log('2. IF NO TOKEN & CACHE - ATTEMPT CACHE LOGIN')
      return yield call(authCache, {
        cacheToken,
        reqPathName,
        reqAuth,
        services
      })
    }

    // IF NO CACHE & TOKEN  - ATTEMPT TOKEN LOGIN
    if (isEmpty(cacheToken.state) && !isEmpty(search)) {
      //console.log('3. IF NO CACHE & TOKEN  - ATTEMPT TOKEN LOGIN')
      return yield call(tokenLogin, {
        services,
        reqPathName,
        reqAuth,
        parsedParams
      })
    }

    // IF TOKEN & CACHE - EVAL CACHE TYPE & DO VALID AUTH
    if (!isEmpty(cacheToken.state) && !isEmpty(search)) {
      //console.log('4. IF TOKEN & CACHE - EVAL CACHE TYPE & DO VALID AUTH')
      // IF CACHE FULL IGNORE TOKEN - ATTEMPT CACHE LOGIN
      if (roleLevel.full.some(role => cacheToken.state.name.includes(role))) {
        try {
          //console.log('4.1 IF CACHE FULL, IGNORE TOKEN - ATTEMPT CACHE LOGIN')
          return yield call(authCache, {
            cacheToken,
            reqPathName,
            reqAuth,
            services
          })
        } catch (e) {
          // IF CACHE FAILS - ATTEMPT TOKEN LOGIN
          //console.log('4.1 IF CACHE FAILS - ATTEMPT TOKEN LOGIN')
          console.warn(e)
          return yield call(tokenLogin, {
            services,
            reqPathName,
            reqAuth,
            parsedParams
          })
        }
      }
      // IF CACHE PARTIAL/LIMITED AND TOKEN OTP - ATTEMPT OTP LOGIN
      else if (
        (roleLevel.partial.some(role => cacheToken.state.name.includes(role)) ||
          roleLevel.limited.some(role =>
            cacheToken.state.name.includes(role)
          )) &&
        !isEmpty(parsedParams.otp)
      ) {
        try {
          //console.log('4.2 IF CACHE PARTIAL/LIMITED, AND TOKEN OTP - ATTEMPT OTP LOGIN')
          return yield call(tokenLogin, {
            services,
            reqPathName,
            reqAuth,
            parsedParams
          })
        } catch (e) {
          // IF OTP FAILS - ATTEMPT CACHE LOGIN
          //console.log('4.2 IF OTP FAILS - ATTEMPT CACHE LOGIN')
          console.warn(e)
          return yield call(authCache, {
            cacheToken,
            reqPathName,
            reqAuth,
            services
          })
        }
      }
      // IF CACHE PARTIAL AND TOKEN PARTIAL - ATTEMPT CACHE LOGIN
      else if (
        roleLevel.partial.some(role => cacheToken.state.name.includes(role)) &&
        (!isEmpty(parsedParams.dp) || !isEmpty(parsedParams.dspId))
      ) {
        try {
          //console.log('4.3 IF CACHE PARTIAL AND TOKEN PARTIAL - ATTEMPT CACHE LOGIN')
          return yield call(authCache, {
            cacheToken,
            reqPathName,
            reqAuth,
            services
          })
        } catch (e) {
          // IF CACHE FAILS - ATTEMPT TOKEN LOGIN
          //console.log('4.3 IF CACHE FAILS - ATTEMPT TOKEN LOGIN')
          return yield call(tokenLogin, {
            services,
            reqPathName,
            reqAuth,
            parsedParams
          })
        }
      }
      // IF CACHE LIMITED AND TOKEN PARTIAL - ATTEMPT TOKEN LOGIN
      else if (
        roleLevel.limited.some(role => cacheToken.state.name.includes(role)) &&
        !isEmpty(parsedParams.dp)
      ) {
        try {
          //console.log('4.4 IF CACHE LIMITED AND TOKEN PARTIAL - ATTEMPT TOKEN LOGIN')
          return yield call(tokenLogin, {
            services,
            reqPathName,
            reqAuth,
            parsedParams
          })
        } catch (e) {
          // IF TOKEN FAILS - ATTEMPT CACHE LOGIN
          //console.log('4.4 IF TOKEN FAILS - ATTEMPT CACHE LOGIN')
          return yield call(authCache, {
            cacheToken,
            reqPathName,
            reqAuth,
            services
          })
        }
      }
      // IF CACHE ANON, AND TOKEN ALL - ATTEMPT TOKEN LOGIN
      else if (
        roleLevel.anon.some(role => cacheToken.state.name.includes(role))
      ) {
        try {
          //console.log('4.5 IF CACHE ANON, AND TOKEN ALL - ATTEMPT TOKEN LOGIN')
          return yield call(tokenLogin, {
            services,
            reqPathName,
            reqAuth,
            parsedParams
          })
        } catch (e) {
          // IF TOKEN FAILS - ATTEMPT CACHE LOGIN
          //console.log('4.5 IF TOKEN FAILS - ATTEMPT CACHE LOGIN')
          return yield call(authCache, {
            cacheToken,
            reqPathName,
            reqAuth,
            services
          })
        }
      }
      // IF NO VALID TOKEN, TRY CACHE
      else {
        //console.log('4.6 IF NO VALID TOKEN, TRY CACHE')
        return yield call(authCache, {
          cacheToken,
          reqPathName,
          reqAuth,
          services
        })
      }
    }
  } catch (e) {
    console.warn(e)
    throw new Error(`Auth Process Failed`)
  }
}

/**
 * Attempts login when url params are available
 * @param services
 * @param reqPathName
 * @param reqAuth
 * @param parsedParams - URL query string to JSON
 **/
function* tokenLogin({ services, reqPathName, reqAuth, parsedParams }) {
  // OTP LOGIN
  if (!isEmpty(parsedParams.otp) || !isEmpty(parsedParams.lp)) {
    return yield call(authOTP, {
      otpToken: parsedParams.otp || parsedParams.lp,
      reqPathName,
      reqAuth,
      services
    })
  }
  throw new Error('No valid token was sent in the search params')
}

/**
 * Executes OTP Auth process
 * @param AuthService
 * @param reqPathName
 * @param reqAuth
 * @param services
 */
function* authOTP({ otpToken, reqPathName, reqAuth, services }) {
  //console.log(`otp check AuthService`)
  const AuthService = services(`AuthService`)
  const CookieService = services('CookieService')

  // VERIFY OTP TOKEN
  const deviceId = yield call(
    [CookieService, 'getFromCache'],
    'psl_device_id',
    ''
  )
  const payload = {
    token: otpToken,
    deviceId: deviceID.sendOnOtp ? deviceId : undefined
  }

  const authCheck = yield call([AuthService, 'otpRequest'], payload)

  // Get the JWT token parsed
  const { exp: expirationDate } = parseJwt(authCheck.access)

  // IF OTP CHECKS OK DELETE STATE
  yield call([CookieService, 'deleteFromCache'], 'state')

  // UPDATE STATE
  yield call(
    [CookieService, 'saveToCache'],
    {
      state: yield call([AuthService, 'extractCacheStateValues'], {
        ...authCheck
      }),
      ...authCheck.userInfo
    },
    false,
    expirationDate
  )

  // SAVE ACCESS & REFRESH TOKENS IN DIFFERENT KEYS
  yield call(
    [CookieService, 'saveToCache'],
    {
      isAnon: false,
      access: authCheck.access,
      refresh: authCheck.refresh
    },
    false
  )

  // REQUEST COMBINATIONS TABLE FOR CURRENT USER
  yield put(combinationsActions.combinationsTableRequest())

  yield call(pathCheck, { authCheck, reqPathName, reqAuth, services })
  // RELOAD DEVICE ID ASYNC with user uuid
  if (deviceID.sendOnOtp) {
    yield fork(getDeviceId, services, deviceID.useCacheOnRefresh)
  }
}

/**
 * Performs Cache token verification process
 * @param AuthService
 * @param cacheToken
 * @param reqPathName
 * @param reqAuth
 * @param services
 */
function* authCache({ cacheToken, reqPathName, reqAuth, services }) {
  const AuthService = services(`AuthService`)
  const { exp: expirationDate } = parseJwt(cacheToken.access)
  const { exp: refreshExpiration } = parseJwt(cacheToken.refresh)
  const tokenHasExpired = expirationDate * 1000 < Date.now()
  const refreshTokenHasExpired = refreshExpiration * 1000 < Date.now()
  //console.log(`authcache check AuthService`)

  // IF CACHE IS COMPLETE VALIDATE INTEGRITY & CHECK PATH
  if (
    !isEmpty(cacheToken.state) &&
    !isEmpty(expirationDate) &&
    !tokenHasExpired
  ) {
    // console.log('authcache - validate cache ')
    yield call([AuthService, 'validateCacheIntegrity'], cacheToken)

    // Get auth state from redux
    let authState = yield select(getAuthState())
    if (isEmpty(authState.access)) {
      // Call verify if access token is not in store
      authState = yield call([AuthService, 'tokenVerify'], cacheToken.access)
    }

    yield call(pathCheck, {
      authCheck: {
        ...cacheToken.state,
        features: authState.features,
        role: authState.role
      },
      reqPathName,
      reqAuth,
      services,
      tokens: {
        access: cacheToken.access,
        refresh: cacheToken.access
      }
    })
  } else if (tokenHasExpired) {
    if (!refreshTokenHasExpired) {
      // Request new set of tokens
      // console.log('Requesting refresh flow')
      const verifyResponse = yield call(
        [AuthService, 'refreshToken'],
        cacheToken.refresh
      )
      yield call(pathCheck, {
        authCheck: verifyResponse,
        reqPathName,
        reqAuth,
        services
      })
    } else {
      // Refresh token has expired, Execute first visit anon flow
      yield put(
        notificationActions.notificationEnqueue({
          message: 'Session has expired, please login again.',
          duration: 0
        })
      )
      yield call(authAnon, {
        reqPathName,
        reqAuth,
        services
      })
    }
  }
}

/**
 * Fetch Anon token
 * @param reqPathName
 * @param reqAuth
 * @param services
 */
function* authAnon({ reqPathName, reqAuth, services }) {
  //console.log(`auth anon`)
  const AuthService = services(`AuthService`)
  const CookieService = services('CookieService')

  // RESET TRACK PARAMS
  yield call(resetTrackAuthParams, services, [
    'state',
    'EmailID',
    'MemberID',
    'SpecialtyID',
    'profession_id',
    'country',
    'CampaignID'
  ])

  // GET ANON TOKEN
  const anonToken = yield call([AuthService, 'getAnonToken'])

  // VERIFY ANON TOKEN
  const authCheck = yield call([AuthService, 'tokenVerify'], anonToken.access)

  // Get the JWT token parsed
  const { exp: expirationDate } = parseJwt(anonToken.access)

  // SAVE TOKEN PARAMS
  yield call(
    [CookieService, 'saveToCache'],
    {
      state: yield call([AuthService, 'extractCacheStateValues'], {
        ...authCheck
      }),
      ...authCheck.userInfo
    },
    false,
    expirationDate
  )

  // SAVE ACCESS & REFRESH TOKENS IN DIFFERENT KEYS
  yield call(
    [CookieService, 'saveToCache'],
    {
      isAnon: true,
      access: authCheck.access,
      refresh: authCheck.refresh
    },
    false
  )

  yield call(pathCheck, {
    authCheck,
    reqPathName,
    reqAuth,
    services
  })
}

/**
 * Check role table for Routes and store access table on redux
 * @param authCheck
 * @param reqPathName
 * @param reqAuth
 * @param services
 */
function* pathCheck({ authCheck, reqPathName, reqAuth, services, tokens }) {
  const AuthService = services('AuthService')
  const pathCheck = yield call([AuthService, 'pathCheck'], {
    role: authCheck.role,
    reqPathName: reqPathName,
    reqAuth: reqAuth
  })
  yield put(
    authActions.authRequestSuccess({
      ...authCheck,
      ...tokens,
      isAllowed: pathCheck
    })
  )
}

/**
 * Logs out user by removing browser Cache data
 * And Invalidates Auth token
 * @param services
 */
function* logoutRequest(services) {
  try {
    const userTransaction = services('UserTransactionService')
    const CookieService = services('CookieService')
    const SessionService = services('SessionService')
    const token = yield select(getAccessToken())
    yield call(logoutCall, { token, userTransaction })
    yield call([CookieService, 'bulkDeleteFromCache'], ['access', 'refresh'])
    yield call([SessionService, 'deleteFromCache'], 'gpt', '')
  } catch (error) {
    // logout endpoint failed, execute soft logout
    yield call(softLogout, services)
    throw new Error(error)
  } finally {
    yield put(authActions.logoutRequestSuccess())
    yield call(resetTrackAuthParams, services)
    yield call([history, history.replace], routes.login.path)
  }
}

/**
 * Companion function for logoutRequest
 * @param token
 * @param userTransaction
 */
function* logoutCall({ token, userTransaction }) {
  const { logoutEndpoint } = authEndpoints
  yield call([userTransaction, 'logout'], logoutEndpoint, token)
}

/**
 * Clears cookies and redirect user to login, no endpoint call
 * @param token
 * @param userTransaction
 */
function* softLogout(services) {
  const CookieService = services('CookieService')
  try {
    yield call(
      [CookieService, 'bulkDeleteFromCache'],
      ['state', 'access', 'refresh']
    )
    yield call([history, history.push], '/user/login')
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Something failed while login out',
        duration: 0
      })
    )
  }
}

/**
 * Clears Tracking and Auth vars from cache
 * @param services
 * @param params
 */
function* resetTrackAuthParams(
  services,
  params = [
    'state',
    'EmailID',
    'MemberID',
    'profession_id',
    'SpecialtyID',
    'country',
    'uuid',
    'site_uid'
  ]
) {
  const CookieService = services('CookieService')
  yield call([CookieService, 'bulkDeleteFromCache'], params)
}

/**
 * @param services
 */
export default function* watchAuth(services) {
  yield all([takeEvery('AUTH_REQUEST', authRequest, services)])
  yield all([takeEvery('LOGOUT_REQUEST', logoutRequest, services)])
  yield all([takeEvery('SOFT_LOGOUT_REQUEST', softLogout, services)])
}
