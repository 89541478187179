import { smartsEndpoints } from 'constants/apiConstants'
import { isEmpty } from 'utils'
/**
 * Performs Axios call to search service
 */
class AdvisorProfileRequestService {
  constructor(SmartsML) {
    this.SmartsML = SmartsML
  }
  /**
   * Function to fetch a single Advisor and parse accordingly
   *
   * @param memberId
   */
  async fetch(memberId) {
    const response = await this.SmartsML.fetch(
      smartsEndpoints.advisorProfileEndpoint,
      'POST',
      {
        hashedPartyId: memberId
      }
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  }
}

export default AdvisorProfileRequestService
