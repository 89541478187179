import ExportListForm from './forms/ExportListForm'
import CreateListForm from './forms/CreateListForm'

export const initialValues = {
  activeStep: 'export-list',
  listId: '',
  ids: [''],
  ids2: [''],
  reportName: '',
  selectedLists: [''],
  combinationsCountry: '',
  combinationsProfession: '',
  combinationsSpecialty: '',
  combinations: [],
  // targetSpeciality: [],
  // targetSpecialtyBoolean: false,
  // filters
  filters: {
    practice: {
      '': true,
      countOfGroupPractice: false,
      countOfSoloPractice: false,
      countOfHospital: false
    },
    groupPractice: {
      '': true,
      '0_9': false,
      '10_49': false,
      '50_99': false,
      '100_plus': false
    },
    hospitalSize: {
      '': true,
      '0_99': false,
      '100_249': false,
      '250_499': false,
      '500_plus': false
    },
    yearsInPractice: {
      '': true,
      '1_10': false,
      '11_20': false,
      '21_30': false,
      '31_40': false,
      '41_plus': false
    },
    gender: {
      '': true,
      female: false,
      male: false
    },
    lift: {
      from: '',
      to: ''
    },
    generational: {
      '': true,
      boomers: false,
      genX: false,
      millenials: false
    },
    leadershipStature: {
      '': true,
      internationalLeader: false,
      nationalLeader: false,
      wla: false,
      recentAuthor: false
    },
    industryAffinity: {
      '': true,
      isPharmaChampion: false,
      isPharmaCordial: false,
      isPharmaShy: false
    },
    hospitalType: {
      '': true,
      no: false,
      countOfPlaceTypeHospitalCommunity: false,
      countOfPlaceTypeHospitalTeaching: false,
      countOfPlaceTypeHospitalUniversity: false
    },
    idn: {
      '': true,
      no: false,
      yes: false
    },
    propensity: {
      '': true,
      no: false,
      yes: false
    }
  },

  brand: '',
  allMembersSelected: false,
  exportToFile: 'No',
  exportToHopper: 'No',
  maconomyNumber: '',
  waveNumber: '',
  lengthOfInterview: '5',
  // maxSize: '',
  file: '',
  uuid: '',
  isXls: false,

  // for the dropdown input for brands
  brandOptions: {},

  // To enable/disable Upload a List options
  fileUploaded: false
}

export const steps = {
  'export-list': {
    component: <ExportListForm />
  },
  'create-list': {
    component: <CreateListForm />
  }
}

export const buildLoiOptions = () => {
  let options = {}
  let step = 5
  while (step <= 120) {
    options = { ...options, [`${step}`]: `${step}` }
    step += 5
  }
  return options
}
