import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Page from 'components/Page'
import { actions } from './LabReducer'
import Spinner from '../../components/ui/Spinner'
import styles from './Lab.module.sass'
import Typography from 'components/ui/Typography'
import useRoleFeatures from 'modules/auth/useRoleFeatures'
import { getError, getIsFetching } from './LabSelector'

const Lab = () => {
  const dispatch = useDispatch()
  const isFetching = useSelector(getIsFetching())
  const error = useSelector(getError())
  const { features } = useRoleFeatures({ coin: 'lima-lab' })
  const limaLabRole = features?.role || ''

  useEffect(() => {
    dispatch(actions.labLoadRequest({ role: limaLabRole }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page contentClassName={styles.page} showDivider={false} showFooter={false}>
      <Typography type="title" color="primary" className={styles.title}>
        {!isFetching && error ? error : 'Redirecting...'}
      </Typography>
      {isFetching && (
        <Spinner
          strokeColor={null}
          fillColor="transparent"
          size={64}
          strokeWidth={4}
        />
      )}
    </Page>
  )
}

export default Lab
