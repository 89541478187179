import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import icons from '@psl/icons/listIcons'
import Ripple from 'components/ui/Ripple'
import styles from './Icon.module.sass'
import classNames from 'classnames/bind'
import { between } from '../utils/propValidators'
import { ReactSVG } from 'react-svg'

export const parseIconString = iconString => {
  /**
   * Supplying icon names without file type and category is @deprecated in version 2.5.0
   *
   * The icon string should now be prefixed with the file type and category
   * For example, 'badge' should be 'svg/custom/badge' and 'fw-delete' should be 'svg/fw/delete'
   *
   * See @psl/icons/index.json or dev.ui.pslgroup.com/?path=/story/icons--overview for a full list of available icons
   *
   * Below corrects deprecated icon string format for backwards compatibility
   *
   */
  const iconTest = /^(.*?)\// // Matches the first part of string preceding slash
  const iconValid = iconTest.test(iconString)
  let iconPath = iconString.substring(4, iconString.length)
  let prefix = ''
  if (iconString.substring(0, 3) === 'fw-') {
    iconPath = iconString.substring(3, iconString.length)
    prefix = 'fw/'
  } else if (!iconValid) {
    iconPath = iconString
    prefix = 'custom/'
  }
  if (!iconValid) {
    console.warn(
      'Using a deprecated format for icon string. This icon format cannot be used in version >= 3.'
    )
  }
  return { prefix, iconPath }
}

const parseClassString = classString => {
  const classArray = classString.split(' ')
  return classArray
}

export const IconSuspenseWrapper = ({ iconSize = 24, children, ...other }) => {
  return (
    <React.Suspense
      fallback={<div style={{ width: iconSize, height: iconSize }} />}
    >
      {React.cloneElement(children, { ...other })}
    </React.Suspense>
  )
}

IconSuspenseWrapper.propTypes = {
  /**
   * The icon to use
   */
  icon: PropTypes.oneOf(icons),
  /**
   * The component children
   */
  children: PropTypes.object,
  /**
   * The icon size, in pixels (1 - 60)
   */
  iconSize: between({ gte: 1, lte: 60 })
}
IconSuspenseWrapper.displayName = 'IconSuspenseWrapper'

const IconWrapper = ({ children, ...other }) => {
  if (process.env.NODE_ENV === 'test') {
    return React.cloneElement(children, { ...other })
  }
  return (
    <IconSuspenseWrapper data-testid="suspense" {...other}>
      {children}
    </IconSuspenseWrapper>
  )
}

IconWrapper.propTypes = {
  /**
   * The component children
   */
  children: PropTypes.object
}
IconWrapper.displayName = 'IconWrapper'

const ButtonIcon = ({ children, button, className, themeStyles, ...other }) => {
  if (button) {
    const cx = classNames.bind(themeStyles)
    return (
      <Ripple
        data-testid="ripple"
        {...other}
        className={cx({ button, [className]: className })}
      >
        {children}
      </Ripple>
    )
  } else {
    return React.cloneElement(children, { ...other })
  }
}
ButtonIcon.propTypes = {
  /**
   * An additional custom className for the root element
   */
  className: PropTypes.string,
  /**
   * If `true`, this is rendered as an icon button
   */
  button: PropTypes.bool,
  /**
   * The component children (May be used for the button text and will override the "text" prop)
   */
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /**
   * The merged styles from the parent component
   */
  themeStyles: PropTypes.object
}

// eslint-disable-next-line react/prop-types
const IconSVG = ({
  prefix,
  iconPath,
  className,
  iconSize,
  iconColor,
  dataTestId,
  ...other
}) => {
  const ImportedIconRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const parsedClasses = parseClassString(styles.svg)
  useEffect(() => {
    setLoading(true)

    const importIcon = async () => {
      try {
        ImportedIconRef.current = (
          await import(`@psl/icons/svg/${prefix}${iconPath}.svg`)
        ).default
      } catch (err) {
        console.error(`Error importing icon: ${err}`)
        throw err
      } finally {
        setLoading(false)
      }
    }
    importIcon()
    return () => {
      setLoading(false)
    }
  }, [prefix, iconPath])

  if (!loading && ImportedIconRef.current) {
    const { current: SvgIcon } = ImportedIconRef
    return (
      <ReactSVG
        src={SvgIcon.toString()}
        {...other}
        data-testid="icon"
        className={className}
        wrapper="svg"
        beforeInjection={svg => {
          svg.setAttribute('width', iconSize ?? 19)
          svg.setAttribute('height', iconSize ?? 19)
          svg.classList.add(...parsedClasses)
          iconColor && svg.setAttribute('style', `fill: ${iconColor}`)
        }}
      />
    )
  }

  return <div data-testid={dataTestId}></div>
}

IconSVG.prototype = {
  prefix: PropTypes.string,
  iconPath: PropTypes.string
}
IconSVG.propTypes = {
  /**
   * Prefix in Icon route
   */
  iconPath: PropTypes.string,
  /**
   * Prefix in Icon route
   */
  prefix: PropTypes.string,
  /**
   * An additional custom className for the root element
   */
  className: PropTypes.string,
  /**
   * The icon color as a CSS color string (hex, rgba, etc). This will override any colors determined by the theme
   */
  iconColor: PropTypes.string,
  /**
   * The icon size, in pixels (1 - 60)
   */
  iconSize: between({ gte: 1, lte: 60 }),
  /**
   * ID for React Testing Library
   */
  dataTestId: PropTypes.string
}
const Icon = ({
  icon,
  iconSize,
  button,
  colorTier,
  iconColor,
  className,
  disabled,
  altTheme,
  dataTestId,
  ...other
}) => {
  const themeStyles = { ...styles, ...altTheme }
  const cx = classNames.bind(themeStyles)
  const { prefix, iconPath } = parseIconString(icon)

  return (
    <ButtonIcon
      data-testid="iconContainer"
      button={button}
      {...other}
      themeStyles={themeStyles}
      className={className}
    >
      <IconWrapper data-testid="svgWrapper" icon={icon} iconsize={iconSize}>
        <IconSVG
          dataTestId={dataTestId}
          prefix={prefix}
          iconPath={iconPath}
          style={{ ...(iconColor && { fill: iconColor }) }}
          width={iconSize}
          height={iconSize}
          iconSize={iconSize}
          iconColor={iconColor}
          className={cx({
            buttonSVG: button,
            svg: !button,
            [className]: !button && className,
            [colorTier]: colorTier,
            disabled
          })}
        />
      </IconWrapper>
    </ButtonIcon>
  )
}
Icon.displayName = 'Icon'
Icon.defaultProps = {
  icon: 'svg/material-design-icons/navigation/check',
  iconSize: 24,
  colorTier: 'icon',
  button: false,
  disabled: false
}
Icon.propTypes = {
  /**
   * An additional custom className for the root element
   */
  className: PropTypes.string,
  /**
   * If `true`, the icon displays a disabled style
   */
  disabled: PropTypes.bool,
  /**
   * The icon color as a CSS color string (hex, rgba, etc). This will override any colors determined by the theme
   */
  iconColor: PropTypes.string,
  /**
   * The icon color where the tier colors are determined by the theme
   */
  colorTier: PropTypes.oneOf([
    '',
    'icon',
    'action',
    'primary',
    'secondary',
    'tertiary',
    'knockout',
    'default',
    'text-secondary',
    'text-primary'
  ]),
  /**
   * The icon to use
   */
  icon: PropTypes.oneOf(icons),
  /**
   * The icon size, in pixels (1 - 60)
   */
  iconSize: between({ gte: 1, lte: 60 }),
  /**
   * Is this an icon button?
   */
  button: PropTypes.bool,
  /**
   * A CSS modules style object to override default theme
   */
  altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  /**
   * ID for React Testing Library
   */
  dataTestId: PropTypes.string
}
export default Icon
