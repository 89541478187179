import { pptxc } from '../PptxConstants'
import { images } from '../images'

const primaryColor = '9dbe0d'
const primaryFontColor = pptxc.fontColor
const fontSizeNormal = pptxc.fontSizeNormal
const fontSizeSlideTitle = 21

export const medelists = (formType, listType) => {
  // Image Logo
  const logo = images.medelists.logo
  const pageOneLogo = images.medelists.pageOneLogo
  const pageOneFooterLogo = images.medelists.pageOneFooterLogo
  const logoTagLine = images.medelists.logoTagLine

  // LAR || SAR values
  const formValues = getFormValue(formType)

  // Master Slide ( For new slide usage, this is like a background / template
  // for any new slide to choose from.)
  const titleSlide = {
    title: 'TITLE_SLIDE',
    bkgd: pptxc.pptxBgFill
  }
  const masterSlide = {
    title: 'MASTER_SLIDE',
    bkgd: pptxc.pptxBgFill,
    margin: [0.5, 0.25, 1.0, 0.25],
    objects: [
      { rect: { x: 0.1, y: 0.5, w: 0.2, h: 0.6, fill: primaryColor } }, // Top left green bar.
      { rect: { x: 10.4, y: 0.5, w: 0.6, h: 0.6, fill: primaryColor } }, // Top right green slide number box.
      { rect: { x: 0, y: 8.1, w: '86%', h: 0.4, fill: primaryColor } }, // Footer green bar.
      {
        text: {
          options: {
            x: 0.12,
            y: 8.0,
            w: '86%',
            h: 0.6,
            align: 'l',
            valign: 'm',
            color: 'FFFFFF',
            fontSize: 10,
            fontFace: 'Arial'
          },
          text: 'Private and confidential. The copying or redistributing of any part of this document without the express permission of Medefield is prohibited'
        }
      },
      { image: { data: logo, x: 9.43, y: 8.1, w: 1.36, h: 0.44 } } // Footer bottom right image logo.
    ],
    slideNumber: {
      x: 10.57,
      y: 0.68,
      color: 'FFFFFF',
      fontFace: 'Arial',
      fontSize: 10
    }
  }

  // First Page (Title Slide)
  const page1 = {
    masterSlide: 'TITLE_SLIDE',
    texts: [
      {
        texts: [
          {
            text: 'Your solution to declining response rates\nAND compromised samples',
            options: {}
          }
        ],
        options: {
          x: 1.44,
          y: 3.48,
          h: 0.1,
          color: '656565',
          fontSize: 24,
          fontFace: 'Arial',
          autofit: true
        }
      }
    ],
    images: [
      { data: logo, x: 1.33, y: 2.08, w: 3.68, h: 1.18 },
      { data: pageOneLogo, x: 8.69, y: 2.42, w: 2.32, h: 1.57 },
      { data: pageOneFooterLogo, x: 8.82, y: 7.71, w: 2.05, h: 0.63 }
    ],
    shapes: [
      {
        shape: 'LINE',
        options: {
          x: 0.16,
          y: 3.08,
          w: 1.8,
          h: 0,
          line: primaryColor,
          lineSize: 7,
          rotate: 90
        }
      }
    ]
  }

  const projectPage = {
    masterSlide: 'MASTER_SLIDE',
    texts: [
      {
        texts: [
          {
            text:
              listType === 'CLIENT_LIST' ? 'Client Target List' : 'LiMA List',
            options: { align: 'center', breakLine: true, bold: true }
          },
          {
            text: formValues.projectPageLabel,
            options: { align: 'center', bold: true, breakLine: true }
          },
          {
            text: formValues.projectPageDesc,
            options: { align: 'center' }
          }
        ],
        options: {
          x: 1.38,
          y: 2.66,
          h: 0.1,
          color: primaryFontColor,
          fontSize: 18,
          fontFace: 'Arial'
        }
      },
      {
        texts: [
          {
            text: 'Project Name: ',
            options: { align: 'center', bold: true }
          },
          {
            text: '%%projectName%%',
            options: {}
          },
          {
            text: 'Project Number: ',
            options: { align: 'center', bold: true }
          },
          {
            text: '%%projectNumber%%',
            options: {}
          },
          {
            text: 'Client Name: ',
            options: { align: 'center', bold: true }
          },
          {
            text: '%%clientName%%',
            options: {}
          }
        ],
        options: {
          x: 1.38,
          y: 3.99,
          h: 0.1,
          color: primaryFontColor,
          fontSize: fontSizeNormal,
          fontFace: 'Arial'
        }
      },
      {
        texts: [
          {
            text: 'For questions and sales, please contact: ',
            options: { align: 'center', bold: true, breakLine: true }
          },
          {
            text: 'Consultant Name: ',
            options: { align: 'center', bold: true }
          },
          {
            text: '%%consultantName%%',
            options: {}
          },
          {
            text: 'Telephone: ',
            options: { align: 'center', bold: true }
          },
          {
            text: '%%telephone%%',
            options: {}
          },
          {
            text: 'Email: ',
            options: { align: 'center', bold: true }
          },
          {
            text: '%%email%%',
            options: {}
          }
        ],
        options: {
          x: 1.38,
          y: 5.16,
          h: 0.1,
          color: primaryFontColor,
          fontSize: fontSizeNormal,
          fontFace: 'Arial'
        }
      }
    ],
    images: [
      { data: logo, x: 3.97, y: 1.45, w: 3.06, h: 0.98 },
      { data: formValues.projectPageIcon, x: 7.94, y: 2.41, w: 3.06, h: 2.12 }
    ],
    shapes: []
  }

  const introPage = {
    masterSlide: 'MASTER_SLIDE',
    texts: [
      {
        texts: [
          {
            text: formValues.introPageTitle,
            options: { align: 'left' }
          }
        ],
        options: {
          x: 0.4,
          y: 0.59,
          h: 0.1,
          color: primaryFontColor,
          fontSize: fontSizeSlideTitle,
          fontFace: 'Arial'
        }
      },
      {
        texts: formValues.introPageContent[listType],
        options: {
          x: 1.38,
          y: 4,
          h: 0.1,
          color: primaryFontColor,
          fontSize: fontSizeNormal,
          fontFace: 'Arial'
        }
      }
    ],
    images: [{ data: logoTagLine, x: 4.7, y: 6.35, w: 1.61, h: 0.74 }],
    shapes: [
      {
        shape: 'LINE',
        options: { x: 5, y: 6.27, w: 1, h: 0, line: primaryColor, line_size: 4 }
      }
    ]
  }

  // const clientStatistic = {
  //   masterSlide: 'MASTER_SLIDE',
  //   name: 'clientStatistic',
  //   texts: [
  //     {
  //       texts: [
  //         {
  //           text:
  //             listType === 'CLIENT_LIST'
  //               ? 'CLIENT TARGET LIST STATISTICS:'
  //               : 'LiMA LIST STATISTICS:',
  //           options: {}
  //         }
  //       ],
  //       options: {
  //         x: 0.4,
  //         y: 0.46,
  //         h: 0.1,
  //         color: primaryFontColor,
  //         fontSize: fontSizeSlideTitle,
  //         fontFace: 'Arial'
  //       }
  //     },
  //     {
  //       texts: [
  //         {
  //           text: 'List and Participant Statistics',
  //           options: {}
  //         }
  //       ],
  //       options: {
  //         x: 0.4,
  //         y: 0.75,
  //         color: primaryFontColor,
  //         fontSize: 18,
  //         fontFace: 'Arial'
  //       }
  //     }
  //   ],
  //   images: [],
  //   shapes: []
  // }

  const filteredClientStatistic = {
    masterSlide: 'MASTER_SLIDE',
    name: 'filteredClientStatistic',
    texts: [
      {
        texts: [
          {
            text:
              listType === 'CLIENT_LIST'
                ? 'CLIENT TARGET LIST STATISTICS:'
                : 'LiMA LIST STATISTICS:',
            options: {}
          }
        ],
        options: {
          x: 0.4,
          y: 0.59,
          h: 0.1,
          color: primaryFontColor,
          fontSize: fontSizeSlideTitle,
          fontFace: 'Arial'
        }
      }
    ],
    images: [],
    shapes: []
  }

  const lastPage = {
    masterSlide: 'TITLE_SLIDE',
    texts: [
      {
        texts: [
          {
            text: 'Your solution to declining response rates\nAND compromised samples\n\n\n\n\n',
            options: {}
          },
          {
            text: 'For questions and sales, please contact: ',
            options: {
              align: 'center',
              bold: true,
              breakLine: true,
              fontSize: fontSizeNormal,
              fontFace: 'Arial'
            }
          },
          {
            text: 'Consultant Name: ',
            options: {
              align: 'center',
              bold: true,
              fontSize: fontSizeNormal,
              fontFace: 'Arial'
            }
          },
          {
            text: '%%consultantName%%',
            options: {
              fontSize: fontSizeNormal,
              fontFace: 'Arial',
              breakLine: true
            }
          },
          {
            text: 'Telephone: ',
            options: {
              align: 'center',
              bold: true,
              fontSize: fontSizeNormal,
              fontFace: 'Arial'
            }
          },
          {
            text: '%%telephone%%',
            options: {
              fontSize: fontSizeNormal,
              fontFace: 'Arial',
              breakLine: true
            }
          },
          {
            text: 'Email: ',
            options: {
              align: 'center',
              bold: true,
              fontSize: fontSizeNormal,
              fontFace: 'Arial'
            }
          },
          {
            text: '%%email%%',
            options: { fontSize: fontSizeNormal, fontFace: 'Arial' }
          }
        ],
        options: {
          x: 1.44,
          y: 4.88,
          h: 0.1,
          color: '656565',
          fontSize: 24,
          fontFace: 'Arial'
        }
      }
    ],
    images: [
      { data: logo, x: 1.33, y: 2.08, w: 3.68, h: 1.18 },
      { data: pageOneLogo, x: 8.69, y: 2.42, w: 2.32, h: 1.57 },
      { data: pageOneFooterLogo, x: 8.82, y: 7.71, w: 2.05, h: 0.63 }
    ],
    shapes: [
      {
        shape: 'LINE',
        options: {
          x: 0.16,
          y: 3.08,
          w: 1.8,
          h: 0,
          line: primaryColor,
          lineSize: 7,
          rotate: 90
        }
      }
    ]
  }

  const tableOptions = {
    tableOption: {
      x: 0.5,
      y: 2.0,
      w: 10,
      border: { pt: '1', color: '9e9e9e' },
      fontSize: 14,
      color: '000000',
      colW: [2, 3.5, 2, 2],
      rowH: 0.5,
      valign: 'middle',
      fontFace: 'Arial'
    },
    headerOption: { align: 'center', bold: true, fill: primaryColor },
    rowPerTable: 8
  }

  const chartOptions = {
    colors: {
      primary: ['#6e6e6e', '#2b6b7b'],
      secondary: ['#6e6e6e', '#2b6b7b']
    }
  }

  return {
    name: 'MedeLists',
    layout: { ...pptxc.pptxLayout, width: 11 },
    masterSlides: [titleSlide, masterSlide], // slides layout.
    defaultSlides: [page1, projectPage, introPage],
    statisticSlides: [filteredClientStatistic],
    endingSlides: [lastPage],
    tableOptions,
    chartOptions
  }
}

const getFormValue = formType => {
  const formValues = {
    introPageTitle: '',
    introPageContent: { LIMA_LIST: [], CLIENT_LIST: [] },
    projectPageLabel: '',
    projectPageDesc: '',
    projectPageIcon: ''
  }

  switch (formType) {
    case 'LAR':
      formValues.introPageTitle = 'LIST ASSESSMENT REPORT'
      formValues.introPageContent.CLIENT_LIST = [
        {
          text: "This List Assessment Report harnesses the power of LiMA, Leaders in Medicine Atlas, the world's largest physician directory with 3.5m+ vetted physician profiles. This report provides  unprecedented transparency into your Target List, from which Medefield will recruit your Survey Participants,  revealing the:\n",
          options: { align: 'left', breakLine: true }
        },
        {
          text: 'Number of Client List usable records',
          options: {
            fill: 'F2F2F2',
            bullet: { type: 'number' },
            breakLine: true,
            indentLevel: 1
          }
        },
        {
          text: 'How the matched records break down by key LiMA segments relating to:',
          options: {
            fill: 'F2F2F2',
            bullet: { type: 'number' },
            breakLine: true,
            indentLevel: 1
          }
        },
        {
          text: 'Demographics\nGeographics\nPractice\nInfluence\nDigital\nAffinity\n',
          options: {
            fill: 'F2F2F2',
            bullet: true,
            breakLine: true,
            indentLevel: 2
          }
        },
        {
          text: 'This report underlines MedeLists’ promise to deliver high match rates and successfully deliver on Client List recruit projects.\n',
          options: { align: 'left', breakLine: true }
        }
      ]
      formValues.introPageContent.LIMA_LIST = [
        {
          text: "This List Assessment Report harnesses the power of LiMA, Leaders in Medicine Atlas, the world's largest physician directory with 3.5m+ vetted physician profiles. This report provides you unprecedented transparency into your customised LiMA List, from which Medefield will recruit your Survey Participants, revealing the:\n",
          options: { align: 'left', breakLine: true }
        },
        {
          text: 'Number of LiMA members in your LiMA List',
          options: {
            fill: 'F2F2F2',
            bullet: { type: 'number' },
            breakLine: true,
            indentLevel: 1
          }
        },
        {
          text: 'How these break down by key LiMA segments relating to:',
          options: {
            fill: 'F2F2F2',
            bullet: { type: 'number' },
            breakLine: true,
            indentLevel: 1
          }
        },
        {
          text: 'Demographics\nGeographics\nPractice\nInfluence\nDigital\nAffinity\n',
          options: {
            fill: 'F2F2F2',
            bullet: true,
            breakLine: true,
            indentLevel: 2
          }
        },
        {
          text: 'This report underlines MedeLists’ promise to always field your projects using a list of vetted physicians, ensuring that only real, practicing physicians are included in your projects and that disingenuous respondents and fraudsters are excluded.\n',
          options: { align: 'left', breakLine: true }
        }
      ]
      formValues.projectPageLabel = 'List Assessment Report'
      formValues.projectPageDesc = 'For %%clientcompany%%'
      formValues.projectPageIcon = images.medelists.lar.projectPageIcon
      break
    case 'SAR':
      formValues.introPageTitle = 'SAMPLE AUDIT REPORT'
      formValues.introPageContent.CLIENT_LIST = [
        {
          text: "This Sample Audit Report harnesses the power of LiMA, Leaders in Medicine Atlas, the world's largest physician directory, with 3.5m+ vetted physician profiles. This report compares Survey Participants against the matched Client List they were recruited from, revealing:\n",
          options: { align: 'left', breakLine: true }
        },
        {
          text: 'Number of Client List usable records',
          options: {
            fill: 'F2F2F2',
            bullet: { type: 'number' },
            breakLine: true,
            indentLevel: 1
          }
        },
        {
          text: 'Number of matched LiMA members',
          options: {
            fill: 'F2F2F2',
            bullet: { type: 'number' },
            breakLine: true,
            indentLevel: 1
          }
        },
        {
          text: 'Number of Survey Participants',
          options: {
            fill: 'F2F2F2',
            bullet: { type: 'number' },
            indentLevel: 1
          }
        },
        {
          text: 'How these break down by key LiMA segments relating to:',
          options: {
            fill: 'F2F2F2',
            bullet: { type: 'number' },
            indentLevel: 1
          }
        },
        {
          text: 'Demographics\nGeographics\nPractice\nInfluence\nDigital\nAffinity\n',
          options: {
            fill: 'F2F2F2',
            bullet: true,
            breakLine: true,
            indentLevel: 2
          }
        },
        {
          text: 'This report provides unprecedented transparency into how representative your sample is of the matched Client List it was recruited from.\n',
          options: { align: 'left', breakLine: true }
        }
      ]
      formValues.introPageContent.LIMA_LIST = [
        {
          text: "This Sample Audit Report harnesses the power of LiMA, Leaders in Medicine Atlas, the world's largest physician directory, with 3.5m+ vetted physician profiles.  This report compares Survey Participants against the bespoke LiMA List they were recruited from, revealing:\n",
          options: { align: 'left', breakLine: true }
        },
        {
          text: 'Number of LiMA members in your LiMA List',
          options: {
            fill: 'F2F2F2',
            bullet: { type: 'number' },
            breakLine: true,
            indentLevel: 1
          }
        },
        {
          text: 'Number of Survey Participants',
          options: {
            fill: 'F2F2F2',
            bullet: { type: 'number' },
            breakLine: true,
            indentLevel: 1
          }
        },
        {
          text: 'How these break down by key LiMA segments relating to:',
          options: {
            fill: 'F2F2F2',
            bullet: { type: 'number' },
            indentLevel: 1
          }
        },
        {
          text: 'Demographics\nGeographics\nPractice\nInfluence\nDigital\nAffinity\n',
          options: {
            fill: 'F2F2F2',
            bullet: true,
            breakLine: true,
            indentLevel: 2
          }
        },
        {
          text: 'This report provides  unprecedented transparency into how representative your sample is of the LiMA List it was recruited from, and assurance that only real, practicing physicians have been included in your project.\n',
          options: { align: 'left', breakLine: true }
        }
      ]
      formValues.projectPageLabel = 'Sample Audit Report'
      formValues.projectPageDesc = 'For %%clientcompany%%'
      formValues.projectPageIcon = images.medelists.sar.projectPageIcon
      break
    default:
      break
  }
  return formValues
}

export const template = {
  name: 'MedeLists'
}
