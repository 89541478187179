import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Divider from 'components/ui/Divider'
import Icon from 'components/ui/Icon'
import Typography from 'components/ui/Typography'
// import Button from 'components/Button'
import styles from './CombinationTable.module.sass'
import { isEmpty } from 'utils'
import classNames from 'classnames/bind'
import SelectMenuInput from 'components/SelectMenuInput'
import Tooltip from 'components/Tooltip'
import { actions as combinationsActions } from './CombinationsTableReducer'
import * as combinationsSelector from './CombinationsTableSelector'

const {
  getFetchStatus,
  getCombinations,
  getTooltips,
  getLoadedCountriesStatus
} = combinationsSelector

const cx = classNames.bind(styles)

const CustomTypeahead = ({
  options,
  name,
  disabled,
  // combinationsFlag,
  ...props
}) => {
  const tooltips = useSelector(getTooltips())
  const renderTooltip = ({ key }) => {
    return (
      tooltips[key] && (
        <div
          data-testid="combinationTooltip"
          className={styles.tooltipContainer}
        >
          <Tooltip
            tip={tooltips[key]}
            multiline={true}
            iconProps={{
              icon: 'svg/custom/help-outline',
              iconSize: 20
            }}
          />
        </div>
      )
    )
  }

  return (
    <SelectMenuInput
      {...props}
      name={name}
      options={options}
      suggestionExtraElement={renderTooltip}
      disabled={disabled}
      // combinationsFlag={combinationsFlag}
    />
  )
}

CustomTypeahead.propTypes = {
  /**
   * SelectMenu options
   */
  options: PropTypes.object,
  /**
   * Typeahead name to be used
   */
  name: PropTypes.string.isRequired,
  /**
   * If true input will be disabled
   */
  disabled: PropTypes.bool,
  /**
   * Fetching flag
   */
  isFetching: PropTypes.bool
  /**
   * flag for traditional render
   */
  // combinationsFlag: PropTypes.bool
}
function CombinationTable({
  name,
  formProps,
  disabled = false,
  workflow
  // SetTargetButtonDisabled = disabled => {},
  // combinationsFlag
}) {
  const dispatch = useDispatch()
  const combinationOptions = useSelector(getCombinations())
  const {
    countries = {},
    // professions = {},
    // specialties = {},
    selections = {},
    map = {}
  } = combinationOptions
  const isFetching = useSelector(getFetchStatus())
  const loadedCountries = useSelector(getLoadedCountriesStatus())
  const { setFieldValue, values } = formProps
  const { allMembersSelected, combinationsCountry, combinationsProfession } =
    values
  const specialtyEnabled = combinationsProfession === 'Physician'
  const workflowSelection = map[workflow] || '_default'

  useEffect(() => {
    if (isEmpty(combinationOptions) && !isFetching) {
      dispatch(combinationsActions.combinationsTableRequest())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Add combination button will be outside component
  // Uncomment this function if we need it back here
  // const addElement = (setFieldValue, values) => {
  //   setFieldValue('combinations', [
  //     ...values['combinations'],
  //     {
  //       id: values['combinations'].length + 1,
  //       countries,
  //       professions,
  //       specialties
  //     }
  //   ])
  // }

  const deleteElement = (id, values, setFieldValue) => {
    const newCombinations = values[name].filter(
      combination => combination.id !== id
    )
    setFieldValue(name, newCombinations)
  }

  const handleProfessionChange = () => {
    setFieldValue('combinationsSpecialty', '')
  }

  const handleCountryChange = () => {
    setFieldValue('combinationsProfession', '')
    setFieldValue('combinationsSpecialty', '')
  }

  const getCountriesOptions = () => {
    if (workflowSelection !== '_default') {
      return Object.keys(selections[workflowSelection]).reduce(
        (prev, curr) => ({ ...prev, [curr]: curr }),
        {}
      )
    }
    return countries
  }

  const getProfessionsOptions = () => {
    if (workflowSelection && combinationsCountry) {
      // Check if workflow !== default
      if (workflowSelection !== '_default') {
        // return professions by selected country
        return Object.keys(
          selections[workflowSelection][combinationsCountry]
        ).reduce((prev, curr) => ({ ...prev, [curr]: curr }), {})
      } else {
        // default return default professions
        return Object.keys(selections[workflowSelection]).reduce(
          (prev, curr) => ({ ...prev, [curr]: curr }),
          {}
        )
      }
    }
    return {}
  }

  const getSpecialtiesOptions = () => {
    if (workflowSelection && combinationsCountry && combinationsProfession) {
      // Check if workflow !== default
      if (workflowSelection !== '_default') {
        if (
          Array.isArray(
            selections[workflowSelection][combinationsCountry][
              combinationsProfession
            ]
          )
        ) {
          return selections[workflowSelection][combinationsCountry][
            combinationsProfession
          ].reduce((prev, curr) => ({ ...prev, [curr]: curr }), {})
        }
        return {}
      } else {
        // default
        if (
          Array.isArray(selections[workflowSelection][combinationsProfession])
        ) {
          return selections[workflowSelection][combinationsProfession].reduce(
            (prev, curr) => ({ ...prev, [curr]: curr }),
            {}
          )
        }
        return {}
      }
    }
    return {}
  }

  return (
    <div data-testid="combinationModule">
      <CustomTypeahead
        data-testid="combinationCountryInput"
        label="Select Country/region"
        name="combinationsCountry"
        placeholder="Search Country/Region"
        className={styles.input}
        traditional
        options={getCountriesOptions()}
        onChange={handleCountryChange}
        disabled={allMembersSelected || !loadedCountries}
      />
      <CustomTypeahead
        data-testid="combinationProfessionInput"
        label="Profession"
        name="combinationsProfession"
        placeholder="Search Profession"
        className={styles.input}
        traditional
        options={getProfessionsOptions()}
        onChange={handleProfessionChange}
        disabled={allMembersSelected || !combinationsCountry}
        // SetTargetButtonDisabled={SetTargetButtonDisabled}
        // combinationsFlag={combinationsFlag}
      />
      <CustomTypeahead
        data-testid="combinationSpecialtyInput"
        label="Specialty"
        name="combinationsSpecialty"
        placeholder="Search Specialty"
        className={styles.input}
        traditional
        options={getSpecialtiesOptions()}
        disabled={allMembersSelected || !specialtyEnabled}
      />
      {/* 
      // Add combination button will be outside component
      // Uncomment this code if we need it back here
      <div>
        <Button
          onClick={() => addElement(setFieldValue, values)}
          fontSize={'15px'}
        >
          Add combination
          <Icon icon={'svg/custom/add'} iconSize={15} />
        </Button>
      </div> */}

      {!allMembersSelected && !disabled && !isEmpty(values[name]) && (
        <div className={styles.gridTable}>
          <div className={styles.rowHeader}>
            <Typography type="display1" color="text-primary">
              Filter by Country/Profession/Specialty
            </Typography>
          </div>
          {/* <div className={styles.rowHeader}>
            <Typography type="display1">Profession</Typography>
          </div>
          <div className={styles.rowHeader}>
            <Typography type="display1">Specialty</Typography>
          </div>
          <div /> */}
          {values[name].map((item, index) => (
            <Fragment key={item.id}>
              <div className={cx((index + 1) % 2 === 0 && styles.evenCell)}>
                <Typography>{item.country || ''}</Typography>
              </div>
              <div className={cx((index + 1) % 2 === 0 && styles.evenCell)}>
                <Typography>{item.profession || ''}</Typography>
              </div>
              <div className={cx((index + 1) % 2 === 0 && styles.evenCell)}>
                <Typography>{item.specialty || ''}</Typography>
              </div>
              <div
                className={cx(
                  (index + 1) % 2 === 0 && styles.evenCell,
                  styles.buttonCell
                )}
              >
                <Icon
                  data-testid={`deleteRow-${item.id}`}
                  className={cx(styles.deleteButton)}
                  icon="svg/material-design-icons/navigation/close"
                  colorTier="tertiary"
                  button
                  onClick={() => deleteElement(item.id, values, setFieldValue)}
                />
              </div>
              {values[name].length !== index + 1 && (
                <Divider className={styles.gridDivider} />
              )}
            </Fragment>
          ))}
        </div>
      )}
    </div>
  )
}

CombinationTable.propTypes = {
  /**
   * Form name
   */
  name: PropTypes.string,
  /**
   * Form props
   */
  formProps: PropTypes.object,
  /**
   * if true it will disable all inputs
   */
  disabled: PropTypes.object,
  /**
   * Workflow passed to the endpoint payload
   */
  workflow: PropTypes.string
  // SetTargetButtonDisabled: PropTypes.func,
  // combinationsFlag: PropTypes.bool
}

export default CombinationTable
