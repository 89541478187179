import React, { Children, useState, useEffect, cloneElement } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './Menu.module.sass'
import List from '../List'
import Icon from '../Icon'
import AnimateHeight from 'react-animate-height'
import Typography from '../Typography'

export const Popout = ({
  children,
  closeIcon = 'svg/material-design-icons/navigation/close',
  iconSize = 24,
  altTheme,
  handleMenuToggle,
  button,
  backgroundColor,
  className,
  active
}) => {
  const themeStyles = { ...styles, ...altTheme }
  const cx = classNames.bind(themeStyles)
  return (
    <AnimateHeight
      id="popout-menu"
      duration={250}
      height={active ? 'auto' : 0}
      easing="ease-in-out"
      className={cx(themeStyles.container, themeStyles.autoHeight, className)}
    >
      {button ? (
        cloneElement(button, {
          'aria-expanded': active,
          'aria-controls': 'popout-menu',
          onClick: handleMenuToggle
        })
      ) : (
        <Icon
          button
          icon={closeIcon}
          iconSize={iconSize}
          onClick={handleMenuToggle}
          colorTier="knockout"
          className={themeStyles.button}
          aria-expanded={active}
          aria-controls="popout-menu"
          style={{ backgroundColor }}
        />
      )}
      {children}
    </AnimateHeight>
  )
}
Popout.propTypes = {
  active: PropTypes.bool,
  altTheme: PropTypes.object,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  closeIcon: PropTypes.string,
  handleMenuToggle: PropTypes.func,
  iconSize: PropTypes.number,
  button: PropTypes.element,
  className: PropTypes.string
}
Popout.displayName = 'Popout'

export const MenuList = ({
  mode = 'sidebar',
  brandImage,
  brandLabel,
  brandLabelOverride = '',
  textInput,
  textInputPlaceholder,
  handleInput,
  textInputOverride,
  children,
  active,
  handleMenuToggle,
  altTheme,
  backgroundColor,
  className,
  logoOnClick,
  ...rest
}) => {
  const themeStyles = { ...styles, ...altTheme }
  const cx = classNames.bind(themeStyles)
  const allowEnhancement = child => {
    return ['SubMenu', 'MenuItem', 'ListItem'].includes(child.type.displayName)
  }
  const enhancedChildren = Children.map(children, child => {
    if (child) {
      return allowEnhancement(child)
        ? React.cloneElement(child, {
            mode,
            ...child.props
          })
        : child
    }
  })
  return (
    <>
      <div
        className={cx(
          themeStyles.menu,
          themeStyles[mode],
          {
            active
          },
          className
        )}
        style={{ backgroundColor }}
      >
        {brandImage && (
          <img
            className={styles.brandImage}
            src={brandImage}
            alt="brand"
            onClick={logoOnClick}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') logoOnClick()
            }}
          />
        )}
        {brandLabel && (
          <Typography className={cx(brandLabelOverride)} type="body1">
            {brandLabel}
          </Typography>
        )}
        {textInput && (
          <div className={cx(styles.textInput, textInputOverride)}>
            <Icon
              icon="svg/custom/search"
              iconSize={20}
              onClick={handleInput}
              className={styles.iconSearch}
            />
            <input
              type="text"
              placeholder={textInputPlaceholder}
              onKeyPress={handleInput}
            />
          </div>
        )}
        <List onClick={handleMenuToggle} dense={mode === 'sidebar'} {...rest}>
          {enhancedChildren}
        </List>
      </div>
      <div
        className={cx(themeStyles.overlay, {
          overlayActive: active
        })}
        onClick={handleMenuToggle}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') handleMenuToggle()
        }}
      />
    </>
  )
}
MenuList.propTypes = {
  backgroundColor: PropTypes.string,
  mode: PropTypes.string,
  children: PropTypes.node,
  active: PropTypes.bool,
  handleMenuToggle: PropTypes.func,
  altTheme: PropTypes.object,
  className: PropTypes.string,
  brandImage: PropTypes.string,
  brandLabel: PropTypes.string,
  brandLabelOverride: PropTypes.string,
  textInput: PropTypes.bool,
  textInputPlaceholder: PropTypes.string,
  handleInput: PropTypes.func,
  logoOnClick: PropTypes.func,
  textInputOverride: PropTypes.string
}

const Menu = ({
  menuOpen,
  brandImage,
  brandLabel,
  brandLabelOverride,
  textInput,
  textInputPlaceholder,
  handleInput,
  textInputOverride,
  onSlideMenu,
  closeIcon,
  mode,
  backgroundColor,
  className,
  popoutClassName,
  button,
  logoOnClick,
  ...rest
}) => {
  const [active, setActive] = useState(menuOpen)
  const handleMenuToggle = () => {
    setActive(!active)
    if (onSlideMenu) {
      onSlideMenu(!active)
    }
  }
  useEffect(() => {
    setActive(menuOpen)
  }, [menuOpen])
  const menu = (
    <MenuList
      {...rest}
      mode={mode}
      handleMenuToggle={handleMenuToggle}
      active={active}
      backgroundColor={backgroundColor}
      className={className}
      brandImage={brandImage}
      brandLabel={brandLabel}
      brandLabelOverride={brandLabelOverride}
      textInput={textInput}
      textInputPlaceholder={textInputPlaceholder}
      handleInput={handleInput}
      textInputOverride={textInputOverride}
      logoOnClick={logoOnClick}
    />
  )
  return mode === 'sidebar' ? (
    menu
  ) : (
    <Popout
      {...rest}
      button={button}
      handleMenuToggle={handleMenuToggle}
      active={active}
      closeIcon={closeIcon}
      backgroundColor={backgroundColor}
      className={popoutClassName}
    >
      {menu}
    </Popout>
  )
}

Menu.displayName = 'Menu'
Menu.propTypes = {
  /**
   * Called when the menu is toggled internally and provides the new menu open state as a parameter
   * onSlideMenu(isMenuOpen)
   */
  onSlideMenu: PropTypes.func,
  /**
   * If `true`, the menu is open
   */
  menuOpen: PropTypes.bool,
  /**
   * Brand image url
   */
  brandImage: PropTypes.string,
  /**
   * Brand label text
   */
  brandLabel: PropTypes.string,
  /**
   * Brand label text overridden class name
   */
  brandLabelOverride: PropTypes.string,
  /**
   * If `true`, shows search input
   */
  textInput: PropTypes.bool,
  /**
   * The menu children (should contain an array of MenuItem components)
   */
  children: PropTypes.node,
  /**
   * A CSS modules style object to override default theme
   */
  altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  /**
   * The menu display mode
   */
  mode: PropTypes.oneOf(['sidebar', 'popout']),
  /**
   * The icon used for the default close button in `popout` mode
   */
  closeIcon: PropTypes.string,
  /**
   * A component used in place of the close icon button in `popout` mode
   */
  button: PropTypes.element,
  /**
   * The background color used for the menu (overrides the theme)
   */
  backgroundColor: PropTypes.string,
  /**
   * An additional custom className for the root element
   */
  className: PropTypes.string,
  /**
   * An additional custom className for the popout menu element
   */
  popoutClassName: PropTypes.string,
  /**
   * Search input placeholder text
   */
  textInputPlaceholder: PropTypes.string,
  /**
   * Search input handler
   */
  handleInput: PropTypes.func,
  /**
   * Search input overriden class name for styling
   */
  textInputOverride: PropTypes.string,
  /**
   * OnClick function that redirects to home
   */
  logoOnClick: PropTypes.func
}

export default Menu
