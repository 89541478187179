import { takeEvery, all, put, call } from 'redux-saga/effects'
import { actions } from 'modules/bootstrap/BootstrapReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import siteConfig from 'site.config.json'

const { configuration } = siteConfig

function* initialize() {
  yield put(actions.requestConfig())
  yield put(actions.requestNotifications())
  yield put(actions.successBootstrap())
}

function* requestConfig(services) {
  const ConfigParser = services('ConfigParser')
  try {
    const parsedConfig = yield call(
      [ConfigParser, 'parseConfig'],
      configuration
    )
    yield put(actions.successConfig(parsedConfig))
  } catch (error) {
    console.error(error)
    yield put(
      notificationActions.notificationEnqueue({
        message: 'There was a problem fetching the configuration.',
        duration: 0
      })
    )
    yield put(actions.errorConfig())
  }
}

function* requestNotifications(services) {
  const Cookies = services('CookieService')
  try {
    const hasOptedIn = Cookies.get('opt')
    if (hasOptedIn !== 'y') {
      yield put(
        notificationActions.notificationEnqueue({
          message:
            'We use cookies to improve your experience. By using this website, you accept cookies or have set your browser or device to not accept cookies.',
          duration: 0,
          onDismiss: () => {
            Cookies.set('opt', 'y', { expires: 30 })
          }
        })
      )
    }
    yield put(actions.successNotifications())
  } catch (error) {
    console.error(error)
    yield put(actions.errorNotifications())
  }
}

export default function* watchBootstrap(services) {
  yield all([
    takeEvery('BOOTSTRAP__REQUEST', initialize, services),
    takeEvery('CONFIG__REQUEST', requestConfig, services),
    takeEvery('NOTIFICATIONS__REQUEST', requestNotifications, services)
  ])
}
