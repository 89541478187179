import SvgIcon from '@mui/material/SvgIcon'

const SendIcon = props => {
  return (
    <SvgIcon viewBox="0 0 17 16" {...props}>
      <path
        d="m7.5 9 7-7M7.585 9.219l1.752 4.505c.154.397.231.595.343.653.096.05.21.05.307 0 .111-.057.189-.256.344-.652l4.393-11.259c.14-.358.21-.537.172-.651a.333.333 0 0 0-.21-.21c-.115-.04-.294.03-.652.17L2.774 6.17c-.397.155-.595.232-.653.344a.333.333 0 0 0 0 .307c.058.111.257.189.654.343L7.28 8.915c.08.031.12.047.155.071.03.022.056.048.078.078.024.034.04.074.07.155z"
        stroke="#fff"
        strokeWidth="1.333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default SendIcon
