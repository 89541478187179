import React from 'react'
import Page from 'components/Page'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { actions as compareListsActions } from './CompareListsReducer'
import * as compareListsSelectors from './CompareListsSelector'
import validationSchema from './validationSchema'
import { initialValues, steps } from './config'
import { isEmpty } from 'utils'
import Charts from 'modules/Charts'
import styles from './CompareLists.module.sass'

const { getFormState } = compareListsSelectors

const CompareLists = () => {
  const dispatch = useDispatch()
  const formState = useSelector(getFormState())
  const { activeStep, showCharts, showDownloadForm } = formState
  const currentValidationSchema = validationSchema[activeStep]
  const formStatePr = isEmpty(formState) ? initialValues : formState
  const history = useHistory()
  const chartsData = isEmpty(formState) ? {} : formState.chartsData

  const isLastStep = activeStep === 'specialty-country'

  const handleSubmit = values => {
    if (!isLastStep) {
      dispatch(
        compareListsActions.submitFormRequest({
          ...values
        })
      )
    } else {
      dispatch(compareListsActions.submitCompleteRequest({ ...values }))
    }
  }

  const handleDownload = () => {
    if (!isEmpty(chartsData)) {
      dispatch(
        compareListsActions.setFormState({
          ...formState,
          showDownloadForm: !showDownloadForm
        })
      )
    }
  }

  const handleShareResults = () => {
    dispatch(
      compareListsActions.setFormState({
        ...formState,
        activeStep: 'share-results'
      })
    )
  }

  const ActiveStep = formProps =>
    React.cloneElement(steps[activeStep].component, {
      ...steps[activeStep].component.props,
      formProps
    })

  const handleBack = () => {
    let newStep = ''
    switch (activeStep) {
      case 'lists-selection':
        history.goBack()
        break
      case 'download-form':
        newStep = 'lists-selection'
        break
      case 'specialty-country':
        newStep = 'download-form'
        break
      default:
        break
    }
    if (newStep) {
      dispatch(
        compareListsActions.setFormState({ ...formState, activeStep: newStep })
      )
    }
  }

  return (
    <Page
      heroTitle={'Compare Lists'}
      showHero
      showGoBack
      onBackClick={handleBack}
      showDivider
      showFooter={false}
      contentClassName={styles.contentContainer}
    >
      <Formik
        initialValues={formStatePr}
        validationSchema={currentValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {formProps => (
          <>
            <Form>
              <ActiveStep {...formProps} />
            </Form>
            {showCharts && activeStep !== 'lists-selection' && (
              <Charts
                chartsData={chartsData}
                formProps={formProps}
                handleDownload={handleDownload}
                onShareResults={handleShareResults}
              />
            )}
          </>
        )}
      </Formik>
    </Page>
  )
}

export default CompareLists
