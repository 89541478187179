// HELPERS
import i18next from 'i18next'
import moment from 'moment'

// ACTIONS
import { actions as chatActions } from 'modules/dougall/chat/ChatReducer'
// import { actions as trackingActions } from 'modules/tracking/TrackingReducer'

// CONFIG
import siteConfig from 'site.config.json'
const { sections } = siteConfig

const DougallController = (dispatch, DougallParser, SessionService) => ({
  /**
   * Function handler for stream response through SSE
   * @param {*} response
   * @param {#} linkedPromptId
   * @param {''} hcpPrompt
   */
  handleStreamAnswer({ response, linkedPromptId, hcpPrompt, threadId }) {
    const showSocial = !!sections?.chat?.answer?.showSocial

    // Answer
    let streamAnswer = ''
    response.addEventListener('gpt_answer', e => {
      if (e?.data !== '[DONE]') {
        // If first chunk
        if (streamAnswer === '') {
          // Adds message to be edited
          dispatch(
            chatActions.addMessage({
              answer: '',
              tags: [],
              showWarning: true,
              isAnswer: true,
              showSocial: false,
              typewriter: true,
              date: moment().format(),
              hcpPrompt
            })
          )
          // Start typing
          dispatch(chatActions.setTyping(true))
        }

        const data = e.data
          .substring(1, e.data.length - 1)
          .replace(/\\n/g, '\n')
          .replace('\\"', '"')
        streamAnswer = streamAnswer + `${data}`

        dispatch(
          chatActions.editLastMessage({
            answer: streamAnswer
          })
        )
      } else if (e.data === '[DONE]') {
        dispatch(
          chatActions.editLastMessage({
            done: true,
            answer: streamAnswer
          })
        )
      }
    })

    // Metadata
    let answerId = ''
    let promptId = ''
    let metadataThreadId = ''
    response.addEventListener('gpt_metadata', e => {
      if (e?.data !== '[DONE]') {
        const metadata = JSON.parse(e.data)
        answerId = metadata.answerId || metadata.messageId
        promptId = metadata.applicationPromptId || metadata.messageId
        metadataThreadId = metadata.threadId
        dispatch(chatActions.editLastMessage({ answerId, promptId }))
      }
    })

    // Live Feed
    // const liveFeed = []
    // response.addEventListener('gpt_liveFeed', e => {
    //   if (e.data && e.data !== '[DONE]') {
    //     liveFeed.push(JSON.parse(e.data))
    //   } else if (e.data === '[DONE]') {
    //     dispatch(chatActions.editLastMessage({ liveFeed }))
    //     dispatch(chatActions.addTags({ tags: liveFeed.map(tag => tag.tagId) }))
    //   }
    // })
    // Tags
    // const tags = []
    // response.addEventListener('gpt_tags', e => {
    //   if (e.data && e.data !== '[DONE]') {
    //     tags.push(JSON.parse(e.data))
    //   } else if (e.data === '[DONE]') {
    //     SessionService.saveToCache('tags', tags.map(tag => tag.tagId).join(','))
    //   }
    // })

    // On Stream Finished
    let finished = false
    response.addEventListener('readystatechange', e => {
      if (e.readyState >= 2 && !finished) {
        finished = true
        if (answerId && promptId) {
          // Enable socials if true in config
          dispatch(chatActions.editLastMessage({ showSocial }))

          // Set last(current) Prompt and Answer
          dispatch(chatActions.setCurrentPrompt({ answerId, promptId }))

          // If no linkedPromptId then this is first prompt
          // so its promptId would be the linkedPromptId
          if (!linkedPromptId) {
            dispatch(chatActions.editLastMessage({ linkedPromptId: promptId }))
            dispatch(chatActions.setLinkedPromptId(promptId))
          }
          if (!threadId) {
            dispatch(
              chatActions.editLastMessage({ threadId: metadataThreadId })
            )
            dispatch(chatActions.setThreadId(metadataThreadId))
          }

          // if (tags.length > 0) {
          //   const replacedTags = DougallParser.replaceTagSource({
          //     answerId,
          //     promptId,
          //     tags
          //   })
          //   dispatch(
          //     chatActions.editLastMessage({
          //       tags: tags && tags.length > 0 ? replacedTags : []
          //     })
          //   )
          //   const references = DougallParser.referencesParser({
          //     answerId,
          //     promptId,
          //     tags
          //   })
          //   // Highlight Answer
          //   const highlightedAnswer = DougallParser.highlightAnswer({
          //     answer: streamAnswer,
          //     tags
          //   })
          //   dispatch(chatActions.editLastMessage({ highlightedAnswer }))
          //   if (references) {
          //     // Add Dougall MD Trusted Links message
          //     dispatch(
          //       chatActions.addMessage({
          //         hcpPrompt,
          //         answer: streamAnswer,
          //         answerId,
          //         promptId,
          //         linkedPromptId,
          //         liveFeed,
          //         isDougall: true,
          //         isAnswer: true,
          //         showWarning: false,
          //         urls: references,
          //         tags: tags && tags.length > 0 ? tags : [],
          //         showSocial: false,
          //         date: moment().format()
          //       })
          //     )
          //   }
          //   dispatch(chatActions.showInterests())
          // }
          dispatch(chatActions.promptSuccess())
        }
        // If connection ends with no answer
        else if (streamAnswer === '') {
          dispatch(
            chatActions.editLastMessage({
              answer: i18next.t('dougallGptTimeoutAnswer'),
              showSocial: false,
              isError: true,
              showWarning: false
            })
          )
          // dispatch(
          //   trackingActions.trackClickAction({
          //     title: 'Site/View/Answer/prompt/failure'
          //   })
          // )
          dispatch(chatActions.promptFail())
        }
        // If only answer arrived with no Ids
        else if (streamAnswer) {
          dispatch(chatActions.promptSuccess())
        }
      }
    })

    response.stream()
  }
})

export default DougallController
