import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useField, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import Icon from 'components/ui/Icon'
import Typography from '../../components/ui/Typography'
import { actions as filterActions } from './FilterReducer'
import * as filterSelectors from './FilterSelector'
import styles from './Filter.module.sass'
import { isEmpty } from 'utils'
import classNames from 'classnames/bind'
import Button, { RaisedButton } from 'components/Button'
import { Checkbox } from 'components/Checkbox'
import { TextInput } from 'components/TextInput'

const { getFetchStatus, getFilters } = filterSelectors

const cx = classNames.bind(styles)

// Due to enableReinitialize meta.initialValue could not be the same
const emptyFilters = {
  practice: {
    '': true,
    countOfGroupPractice: false,
    countOfSoloPractice: false,
    countOfHospital: false
  },
  groupPractice: {
    '': true,
    '0_9': false,
    '10_49': false,
    '50_99': false,
    '100_plus': false
  },
  hospitalSize: {
    '': true,
    '0_99': false,
    '100_249': false,
    '250_499': false,
    '500_plus': false
  },
  yearsInPractice: {
    '': true,
    '1_10': false,
    '11_20': false,
    '21_30': false,
    '31_40': false,
    '41_plus': false
  },
  gender: {
    '': true,
    female: false,
    male: false
  },
  lift: {
    from: '',
    to: ''
  },
  generational: {
    '': true,
    boomers: false,
    genX: false,
    millenials: false
  },
  leadershipStature: {
    '': true,
    internationalLeader: false,
    nationalLeader: false,
    wla: false,
    recentAuthor: false
  },
  industryAffinity: {
    '': true,
    isPharmaChampion: false,
    isPharmaCordial: false,
    isPharmaShy: false
  },
  hospitalType: {
    '': true,
    no: false,
    countOfPlaceTypeHospitalCommunity: false,
    countOfPlaceTypeHospitalTeaching: false,
    countOfPlaceTypeHospitalUniversity: false
  },
  idn: {
    '': true,
    no: false,
    yes: false
  },
  propensity: {
    '': true,
    no: false,
    yes: false
  }
}

const getGteLteCaption = (from, to) => {
  if (from && !to) {
    return `More than ${from}`
  } else if (!from && to) {
    return `Less than ${to}`
  } else if (from && to) {
    return `Between ${from} and ${to}`
  }
  return ''
}

const ModalContent = ({ activeFilter, onClose, onConfirm, field }) => {
  const { value: fieldValue } = field
  const { template, values, name } = activeFilter
  let filterValue = {}
  if (template === 'booleanMultipleCheckbox') {
    filterValue = values.reduce(
      (prev, curr) => ({ ...prev, [curr.key]: fieldValue[name][curr.key] }),
      {}
    )
    // If all keys are false check empty (All) value
    if (Object.values(filterValue).every(value => value === false)) {
      filterValue = { ...filterValue, '': true }
    }
  } else {
    filterValue = values.reduce(
      (prev, curr) => ({ ...prev, [curr.key]: fieldValue[name][curr.key] }),
      {}
    )
  }
  const [value, setValue] = useState(filterValue)

  const handleCheckBoxChange = key => {
    let newValue = { ...value }
    // If the key is empty (All) need to uncheck rest of values
    if (key === '') {
      if (!value[key]) {
        const restValues = values.reduce(
          (prev, curr) => ({ ...prev, [curr.key]: false }),
          {}
        )
        newValue = { ...restValues, [key]: true }
      }
    } else {
      newValue = { ...value, [key]: !value[key], '': false }
    }
    // If all keys are false check empty (All) value
    if (Object.values(newValue).every(value => value === false)) {
      newValue = { ...newValue, '': true }
    }
    setValue(newValue)
  }

  const handleTextInputChange = (key, inputValue) => {
    const newValue = { ...value, [key]: inputValue }
    setValue(newValue)
  }

  const renderContent = () => {
    switch (template) {
      case 'integerAnyInputGteLte':
        return (
          <>
            <Typography className={styles.modalTitle}>
              Please enter household income range ($) to filter by:
            </Typography>
            <div className={cx(styles.fullRow)}>
              {values.map(v => (
                <TextInput
                  key={`${v.key}-${v.caption}`}
                  id={`${v.key}-${v.caption}`}
                  className={styles.modalInput}
                  label={v.caption}
                  type="number"
                  name=""
                  traditional
                  placeholder={v.placeholder}
                  value={value[v.key]}
                  onChange={e => handleTextInputChange(v.key, e.target.value)}
                />
              ))}
            </div>
          </>
        )
      case 'booleanMultipleCheckbox':
      default:
        return (
          <>
            <Typography className={styles.modalTitle}>
              Please select one or more items to filter by:
            </Typography>
            <div className={styles.modalForm}>
              {values.map(v => (
                <Checkbox
                  key={`${v.key}-${v.caption}`}
                  label={v.caption}
                  isRadio
                  value={value[v.key]}
                  onChange={() => handleCheckBoxChange(v.key)}
                  className={styles.checkBox}
                  labelClassName={cx(value[v.key] && styles.checkedBox)}
                />
              ))}
            </div>
          </>
        )
    }
  }

  return (
    <div
      style={{
        top: document.getElementById(activeFilter.name).offsetTop
      }}
      className={styles.filterModal}
    >
      {renderContent()}
      <div className={styles.modalActionsContainer}>
        <RaisedButton
          onClick={onClose}
          textCase="capitalize"
          color=""
          fontSize="16px"
          type="button"
        >
          Cancel
        </RaisedButton>
        <RaisedButton
          onClick={() => onConfirm(value)}
          textCase="capitalize"
          fontSize="16px"
          type="button"
        >
          Confirm
        </RaisedButton>
      </div>
    </div>
  )
}
ModalContent.propTypes = {
  /**
   * The filter object
   */
  activeFilter: PropTypes.object,
  /**
   * The filter object
   */
  field: PropTypes.object,
  /**
   * close modal callback
   */
  onClose: PropTypes.func,
  /**
   * confirm button callback
   */
  onConfirm: PropTypes.func
}

/**
 * Filter button component
 * @param { filter, values, onClick } param props
 * @returns React.Node
 */
const FilterButton = ({ filter, values, onClick = () => {} }) => {
  let filterValue = []
  let valuesCaption = ''
  if (values[filter.name]) {
    if (filter.template === 'booleanMultipleCheckbox') {
      // Find the captions for each selected filter
      Object.keys(values[filter.name]).forEach(v => {
        if (values[filter.name][v] && !!v) {
          const finalValue = filter.values.find(value => value.key === v)
          filterValue = [...filterValue, finalValue.caption]
        }
      })
      valuesCaption = filterValue.join(', ')
    } else if (filter.template === 'integerAnyInputGteLte') {
      valuesCaption = getGteLteCaption(
        values[filter.name].from,
        values[filter.name].to
      )
    }
  }
  return (
    <div
      id={filter.name}
      className={styles.filterButton}
      onClick={() => onClick(filter)}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') onClick(filter)
      }}
    >
      <span className={styles.filterName}>{filter.caption}</span>
      <span className={styles.filterValues}>{valuesCaption}</span>
      <Icon iconColor="#cdcdcd" icon="svg/custom/carat-r" iconSize={20} />
    </div>
  )
}
FilterButton.propTypes = {
  /**
   * The filter object
   */
  filter: PropTypes.object,
  /**
   * The value of the filter
   */
  values: PropTypes.object,
  /**
   * The callback for the filter click
   */
  onClick: PropTypes.func
}

const Filter = ({ name }) => {
  const dispatch = useDispatch()
  const [field] = useField({ name })
  const { setFieldValue, setTouched, touched } = useFormikContext()
  const { options: filterOptions = [] } = useSelector(getFilters())
  const fetching = useSelector(getFetchStatus())
  // Accordion state
  const [open, setOpen] = useState(false)
  // Modal state
  const [modalOpen, setModalOpen] = useState(false)
  const [activeFilter, setActiveFilter] = useState({})

  useEffect(() => {
    if (!fetching) {
      dispatch(filterActions.filterRequest())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleFilter = () => {
    setOpen(!open)
  }

  const openFilterModal = filter => {
    setModalOpen(true)
    setActiveFilter(filter)
  }

  const closeFilterModal = () => {
    setModalOpen(false)
    setActiveFilter({})
  }

  const clearFilters = () => {
    // Set empty selection value
    setFieldValue(name, emptyFilters)
    setTouched({ ...touched, [name]: false })
  }

  const handleConfirmModal = filterValue => {
    const newFieldValue = { ...field.value, [activeFilter.name]: filterValue }
    setFieldValue(name, newFieldValue)
    setModalOpen(false)
    setActiveFilter({})
    setTouched({ ...touched, [name]: true })
  }

  const filters = filterOptions.map(conf => (
    <FilterButton
      key={conf.name}
      filter={conf}
      values={field.value}
      onClick={openFilterModal}
    />
  ))

  // Get all Selected Filters captions
  const selectedFilters = Object.keys(field.value)
    .reduce((prev, curr) => {
      let filterValue = []
      const filterOption = filterOptions.find(o => o.name === curr)
      if (filterOption && filterOption.template === 'booleanMultipleCheckbox') {
        // Find and replace selected filters with caption
        Object.keys(field.value[curr]).forEach(v => {
          if (field.value[curr][v] && !!v) {
            const finalValue = filterOption.values.find(
              value => value.key === v
            )
            filterValue = [...filterValue, finalValue.caption]
          }
        })
      } else if (
        filterOption &&
        filterOption.template === 'integerAnyInputGteLte'
      ) {
        if (field.value[curr].from || field.value[curr].to) {
          filterValue = [
            ...filterValue,
            getGteLteCaption(field.value[curr].from, field.value[curr].to)
          ]
        }
      }
      return [...prev, ...filterValue]
    }, [])
    .join(', ')

  return (
    <div className={styles.container}>
      <div
        className={styles.filterButton}
        onClick={toggleFilter}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') toggleFilter()
        }}
        style={{ backgroundColor: '#e2e2e2' }}
      >
        <span className={styles.filterName}>Filters</span>
        <span className={styles.filterValues}>
          {!open ? selectedFilters || 'No filters' : ''}
        </span>
        <Icon
          iconColor="#000000d6"
          icon={open ? 'svg/custom/carat-d' : 'svg/custom/carat-r'}
          iconSize={20}
        />
      </div>
      <div className={cx(styles.filtersContainer, { open })}>{filters}</div>
      {selectedFilters && (
        <div className={styles.clearContainer}>
          <Button
            type="button"
            onClick={clearFilters}
            className={styles.clearButton}
            text="Clear filters"
          />
        </div>
      )}
      {!isEmpty(activeFilter) && modalOpen && (
        <>
          <div
            id="filter-backdrop"
            className={styles.filterBackDrop}
            onClick={closeFilterModal}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') closeFilterModal()
            }}
          />
          <ModalContent
            field={field}
            activeFilter={activeFilter}
            onClose={closeFilterModal}
            onConfirm={handleConfirmModal}
          />
        </>
      )}
    </div>
  )
}

Filter.propTypes = {
  /**
   * The actual value of the component
   */
  name: PropTypes.string
}

export default Filter
