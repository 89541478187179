import PropTypes from 'prop-types'
import styles from './Selected.module.sass'
import { Button as ButtonUI } from '../ui/Button'
import Typography from '../ui/Typography'

const SelectedListIds = ({
  selectedListLabels,
  onEdit,
  isFetching,
  alignEditButton
}) => {
  return (
    <div className={styles.selectedContainer}>
      <div className={styles.flexContainer}>
        <Typography type="display2" color="text-primary">
          Selected List IDs
        </Typography>
        <div className={styles.contentContainer}>
          {selectedListLabels?.map((id, idx) => {
            return (
              <Typography key={id} className={styles.listIdCaption}>{`${
                idx + 1
              }.${id}`}</Typography>
            )
          })}
        </div>
        <div style={{ alignSelf: alignEditButton || 'center' }}>
          <ButtonUI
            className={styles.editButton}
            onClick={onEdit}
            type="button"
            disabled={isFetching}
            fontSize="0.8em"
          >
            Edit
          </ButtonUI>
        </div>
      </div>
    </div>
  )
}

SelectedListIds.propTypes = {
  selectedListLabels: PropTypes.array,
  isFetching: PropTypes.bool,
  onEdit: PropTypes.func,
  alignEditButton: PropTypes.string
}

export default SelectedListIds
