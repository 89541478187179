import * as Yup from 'yup'

const validationSchema = {
  // List Selection Form
  'list-selection': Yup.object().shape({
    selectedList: Yup.string().required('List ID is required')
  })
}

export default validationSchema
