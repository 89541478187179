import { createSelector } from '@reduxjs/toolkit'

export const exportListSelector = state => state.exportListReducer

export const getFormState = () =>
  createSelector(exportListSelector, exportList => exportList.formState)
export const getFetchStatus = () =>
  createSelector(exportListSelector, exportList => exportList.fetching)
export const getBrandOptions = () =>
  createSelector(
    exportListSelector,
    exportList => exportList.brandOptions.brandOptions || {}
  )
export const getUploadingStatus = () =>
  createSelector(exportListSelector, exportList => exportList.uploading)
