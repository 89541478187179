import { useField, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
// import CheckboxUI from '@psl/ui/build/es/Checkbox'
import styles from './Checkbox.module.sass'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

/**
 * Checkbox input
 * @param traditional
 * @param type
 * @param props
 * @returns {*}
 * @constructor
 */
const Checkbox = ({ isRadio, className, labelClassName, ...props }) => {
  const checked = props.value
  const isDisabled = props.disabled
  return (
    <div className={cx(styles.container, className)}>
      {/* <CheckboxUI
        {...field}
        {...props}
        value=""
        checked={field.value}
        error={meta.touched && meta.error ? meta.error : null}
      /> */}
      <label
        className={cx(
          checked && styles.checked,
          { isRadio, isDisabled },
          labelClassName
        )}
        htmlFor={props.id}
      >
        {props.label}
        <input
          {...props}
          type="checkbox"
          id={props.id}
          name={props.name}
          value={props.value}
        />
      </label>
    </div>
  )
}
Checkbox.propTypes = {
  /**
   * custom className fot the container
   */
  className: PropTypes.string,
  /**
   * custom className fot the label
   */
  labelClassName: PropTypes.string,
  /**
   * Label to display
   */
  label: PropTypes.string,
  /**
   * Component id
   */
  id: PropTypes.string,
  /**
   * Component name
   */
  name: PropTypes.string,
  /**
   * Checkbox value
   */
  value: PropTypes.bool,
  /**
   * Display the checkbox as radio if true
   */
  isRadio: PropTypes.bool,
  disabled: PropTypes.bool
}

/**
 * Checkbox input integration with formik
 * @param traditional
 * @param type
 * @param props
 * @returns {*}
 * @constructor
 */
const CheckboxInput = ({ isRadio, ...props }) => {
  const [field] = useField(props)
  const checked = field.value
  const isDisabled = props.disabled
  const { setFieldValue } = useFormikContext()

  const handleClick = () => {
    if (!isDisabled) {
      setFieldValue(props.name, !checked)

      if (typeof props.onChange === 'function') {
        props.onChange(!checked)
      }
    }
  }
  return (
    <div className={cx(styles.container, props.className)}>
      {/* <CheckboxUI
        {...field}
        {...props}
        value=""
        checked={field.value}
        error={meta.touched && meta.error ? meta.error : null}
      /> */}
      <label
        className={cx(checked && styles.checked, { isRadio, isDisabled })}
        htmlFor={field.name}
        onClick={handleClick}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') handleClick()
        }}
        data-testid="checkbox-label"
      >
        {props.label}
      </label>
      <input
        {...props}
        type="checkbox"
        id={field.name}
        name={field.name}
        value={field.value}
      />
    </div>
  )
}
CheckboxInput.propTypes = {
  /**
   * custom className fot the container
   */
  className: PropTypes.string,
  /**
   * Label to display
   */
  label: PropTypes.string,
  /**
   * Display the checkbox as radio if true
   */
  isRadio: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func
}
export { Checkbox, CheckboxInput }
export default CheckboxInput
