import { useDispatch, useSelector } from 'react-redux'
import { actions as authActions } from './AuthReducer'
import {
  getAccessToken,
  getFetchingAuthStatus,
  getIsAuth,
  getIsLimited,
  getIsAllowed,
  getSpecialty,
  getFeaturesTable
} from './AuthSelector'

/**
 * Use Auth
 * Improves access to actions and selectors for Auth
 */
const useAuth = () => {
  const dispatch = useDispatch()

  // Auth Selectors
  const accessToken = useSelector(getAccessToken())
  const fetchingAuthStatus = useSelector(getFetchingAuthStatus())
  const isAuth = useSelector(getIsAuth())
  const isLimited = useSelector(getIsLimited())
  const isAllowed = useSelector(getIsAllowed())
  const specialty = useSelector(getSpecialty())
  const featuresTable = useSelector(getFeaturesTable())

  // Auth Actions
  /**
   * Auth Request
   * @param {*} object
   */
  const authRequest = obj => {
    dispatch(authActions.authRequest(obj))
  }

  /**
   * Logout Request
   */
  const logoutRequest = () => {
    dispatch(authActions.logoutRequest())
  }

  return {
    accessToken,
    fetchingAuthStatus,
    isAuth,
    isLimited,
    isAllowed,
    specialty,
    featuresTable,
    authRequest,
    logoutRequest
  }
}

export default useAuth
