import { createReducer, createAction } from '@reduxjs/toolkit'
import { initialValues } from './config'

export const setFormState = createAction('EXPLORE_PROJECT_SET_FORM_STATE')

export const submitFormRequest = createAction(
  'EXPLORE_PROJECT_SUBMIT_FORM_REQUEST'
)
export const submitFormRequestSuccess = createAction(
  'EXPLORE_PROJECT_SUBMIT_FORM_REQUEST_SUCCESS'
)
export const submitFormRequestFail = createAction(
  'EXPLORE_PROJECT_SUBMIT_FORM_REQUEST_FAIL'
)
export const submitFormCompleteRequest = createAction(
  'EXPLORE_PROJECT_COMPLETE_REQUEST'
)

export const actions = {
  setFormState,
  submitFormRequest,
  submitFormRequestSuccess,
  submitFormRequestFail,
  submitFormCompleteRequest
}

const exploreListReducer = createReducer(
  {
    formState: {
      ...initialValues
    },
    fetching: false
  },
  {
    [setFormState]: (state, { payload }) => {
      state.formState = { ...state.formState, ...payload }
    },
    [submitFormRequest]: state => {
      state.fetching = true
    },
    [submitFormCompleteRequest]: state => {
      state.fetching = true
    },
    [submitFormRequestSuccess]: state => {
      state.fetching = false
    },
    [submitFormRequestFail]: state => {
      state.fetching = false
    }
  }
)

export default exploreListReducer
