import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Modal from 'components/Dougall/Modal'
import Typography from 'components/ui/Typography'
import TextArea from 'components/ui/TextArea'

import styles from './FeedbackModal.module.sass'

const altButtonTheme = {
  marginBottom: '0',
  fontSize: '14px',
  fontWeight: 'bold',
  lineHeight: '1.43',
  paddingTop: '8px',
  paddingBottom: '8px',
  height: '36px'
}

const FeedbackModal = ({
  open = false,
  onConfirm = () => {},
  onCancel = () => {},
  onTextAreaChange = () => {}
}) => {
  const { t } = useTranslation()
  const [shareText1, shareText2] = t('shareTitle').split(': ')

  return (
    <Modal
      open={open}
      actionCaption={t('inviteFormSubmit')}
      onCancel={onCancel}
      onConfirm={onConfirm}
      altButtonTheme={altButtonTheme}
    >
      <div className={styles.feedbackModal}>
        <Typography type="display2">{shareText1 ?? ''}:</Typography>
        <Typography type="display2">{shareText2 ?? ''}</Typography>
        <TextArea
          name="feedback"
          label=""
          placeholder={t('emailShareFormMessage')}
          traditional={false}
          minHeight={100}
          className={styles.textarea}
          onChange={onTextAreaChange}
        />
      </div>
    </Modal>
  )
}

FeedbackModal.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onTextAreaChange: PropTypes.func
}

export default FeedbackModal
