import React, { Component, cloneElement } from 'react'
import PropTypes from 'prop-types'
import Divider from '../Divider'
import styles from './ListItem.module.sass'
import { isParticularElement } from '../utils'
import Ripple from '../Ripple'
import classNames from 'classnames/bind'
import isEmpty from 'lodash/isEmpty'

class ListItem extends Component {
  static displayName = 'ListItem'
  static defaultProps = {
    component: 'div'
  }
  static propTypes = {
    /**
     * The ListItem children. This can contain an array of strings, native elements, ListItem, ListItemAvatar,
     * ListItemIcon, or ListItemText components
     */
    children: PropTypes.node,
    /**
     * The component used for the root node. Use a string for a native DOM element or pass a React component.
     */
    component: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.node,
      PropTypes.object
    ]),
    /**
     * A CSS modules style object to override default theme
     */
    altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    /**
     * If `true`, compact vertical padding designed for keyboard and mouse input will be used.
     */
    dense: PropTypes.bool,
    /**
     * Inline style to be applied to the root element
     */
    style: PropTypes.object,
    /**
     * If `true`, a ripple click/touch indication will be shown
     */
    ripple: PropTypes.bool,
    /**
     * Accepts a list-style CSS property
     */
    listStyle: PropTypes.string,
    /**
     * An object containing a custom list item marker image and its optional size attribute
     */
    listImage: PropTypes.shape({
      image: PropTypes.string.isRequired,
      size: PropTypes.string
    }),
    /**
     * If `true`, a bottom divider will be shown.
     */
    divider: PropTypes.bool,
    /**
     * @ignore
     */
    onClick: PropTypes.func,
    /**
     * An additional custom className for the root element
     */
    className: PropTypes.string,
    /**
     * If `true`, the list item is displayed as active
     */
    active: PropTypes.bool,
    /**
     * An additional custom className for the element defined by the component prop
     */
    componentClassName: PropTypes.string,
    /**
     * An additional custom className for the component child element
     */
    childClassName: PropTypes.string,
    /**
     * An optional background color for the component child element
     */
    backgroundColor: PropTypes.string
  }
  static contextTypes = {
    dense: PropTypes.bool
  }
  static childContextTypes = {
    dense: PropTypes.bool
  }
  getChildContext = () => {
    return {
      dense: this.props.dense || this.context.dense
    }
  }
  /* istanbul ignore next */
  itemClick = event => {
    const { onClick } = this.props
    typeof onClick === 'function' && onClick(event)
  }
  render = () => {
    const {
      component: Component,
      children: childrenProp,
      divider,
      dense: ignore,
      ripple,
      onClick,
      listStyle,
      listImage,
      style,
      altTheme,
      className,
      active,
      componentClassName,
      childClassName,
      backgroundColor,
      ...other
    } = this.props
    const { dense } = this.context
    const children = React.Children.toArray(childrenProp)
    const hasAvatarLeft = isParticularElement(children[0], ['ListItemAvatar'])
    const hasAvatarRight =
      children.length &&
      isParticularElement(children[children.length - 1], ['ListItemAvatar'])
    const hasIconRight =
      children.length &&
      isParticularElement(children[children.length - 1], ['ListItemIcon'])
    const hasIconLeft =
      children.length && isParticularElement(children[0], ['ListItemIcon'])
    /* istanbul ignore next */
    const dividerMargin = dense ? 56 : 72
    const themeStyles = { ...styles, ...altTheme }
    const cx = classNames.bind(themeStyles)
    if (hasAvatarRight || hasIconRight) {
      // Clone a right avatar or icon and allow it to align to the right
      children[children.length - 1] = cloneElement(
        children[children.length - 1],
        {
          right: true,
          ...children[children.length - 1].props
        }
      )
    }
    const flex = !listStyle && isEmpty(listImage)
    /* istanbul ignore next */
    const imageStyle = listImage && {
      background: `${listImage.image} no-repeat left`,
      listStyleType: 'none',
      paddingLeft: `${listImage.size ? listImage.size : 24}px`,
      paddingTop: listImage.size > 18 ? `${(listImage.size - 18) / 2}px` : null,
      paddingBottom:
        listImage.size > 18 ? `${(listImage.size - 18) / 2}px` : null
    }
    const RootElement = ripple && !active ? Ripple : 'li'
    return (
      <RootElement
        element={ripple ? 'li' : null}
        className={cx(
          themeStyles.item,
          {
            flex,
            dense,
            active
          },
          className
        )}
        style={{ ...imageStyle, ...style }}
      >
        <Component
          {...other}
          onClick={this.itemClick}
          className={cx(
            themeStyles.component,
            { cursor: typeof onClick === 'function' },
            componentClassName
          )}
        >
          <div
            className={cx(themeStyles.child, childClassName)}
            style={{
              ...(hasIconLeft && { paddingLeft: 0 }),
              backgroundColor
            }}
          >
            {children}
            {(hasAvatarRight || hasIconRight) && children.pop()}
          </div>
        </Component>
        {divider && isEmpty(listImage) && (
          <Divider
            {...(hasAvatarLeft && { marginLeft: dividerMargin })}
            {...(hasAvatarRight && { marginRight: dividerMargin })}
          />
        )}
      </RootElement>
    )
  }
}

export default ListItem
