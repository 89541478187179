import { takeEvery, all, put, call } from 'redux-saga/effects'
import { actions } from './DeviceIdReducer'
import { isEmpty } from 'utils'
import siteConfig from 'site.config.json'

const { configuration } = siteConfig
/**
 * Retrieves the deviceID
 * @param services
 * @param useCache
 */
export function* getDeviceId(services, useCache) {
  const { cacheStateLife = 7776000 } = configuration.deviceID
  try {
    const CookieService = services('CookieService')
    const deviceService = services('DeviceIdService')
    const [, userID] = yield all([
      // Initalize Device Service
      call([deviceService, 'initialize']),
      // GET USER ID FOR PROFILE
      call([CookieService, 'getFromCache'], 'uuid', 0)
    ])
    // GET DEVICE-ID
    const psl_device_id = yield call([deviceService, 'getDeviceId'], {
      ...configuration.deviceID,
      onlineId: userID,
      useCache
    })
    yield call(
      [CookieService, 'saveToCache'],
      {
        psl_device_id: psl_device_id.deviceID || psl_device_id
      },
      false,
      Date.now() / 1000 + cacheStateLife // expiration date in seconds
    )
  } catch (e) {
    console.error(e)
  } finally {
    yield put(actions.deviceIdReady())
  }
}

/**
 * - initial deviceID process for AUTH
 * - to make it faster eval if device id exists in cookies or local
 * - If deviceId/psl_device_id does not exists - init script and get one
 * @param services
 * @param useCache
 */
export function* deviceIdFull(services, useCache) {
  try {
    const CookieService = services('CookieService')
    const deviceCache = yield call(
      [CookieService, 'getFromCache'],
      'psl_device_id'
    )
    //IF DEVICE-ID NOT IN COOKIES, REQUEST NEW ONE
    if (isEmpty(deviceCache)) {
      yield call(getDeviceId, services, useCache)
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * @param services
 */
export default function* watchAuth(services) {
  yield all([takeEvery('DEVICEID_GET', getDeviceId, services)])
}
