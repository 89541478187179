import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as variablesActions } from './VariablesReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'

function* requestVariables(services, { payload }) {
  const AdminService = services('AdminService')

  const { id = '', note = '' } = payload

  const objectToSearch = { ...payload, id: { like: id }, note: { like: note } }

  try {
    const { data = [], pagination = {} } = yield call(
      [AdminService, 'requestVariables'],
      objectToSearch
    )

    yield put(variablesActions.completeRequestVariables({ data, pagination }))
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to fetch Variables',
        duration: 0
      })
    )
  }
}

function* requestSingleVariable(services, { payload }) {
  const AdminService = services('AdminService')

  try {
    const response = yield call([AdminService, 'getSingleVariable'], payload)

    yield put(variablesActions.setSingleVariable(response))
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to fetch single variable.',
        duration: 0
      })
    )
  }
}

function* variablesDelete(
  services,
  {
    payload: {
      id,
      formState = {
        id: '',
        note: '',
        order: 'asc',
        orderBy: 'id',
        page: 1,
        perPage: 20
      }
    }
  }
) {
  const AdminService = services('AdminService')

  let message = 'Unable to delete Variables element'

  try {
    const { count = 0 } = yield call([AdminService, 'variablesDelete'], id)

    yield put(variablesActions.completeVariablesDelete())

    if (count === 1) {
      message = 'Variables element deleted'
    }

    yield put(
      notificationActions.notificationEnqueue({
        message,
        duration: 0
      })
    )

    const { data = [], pagination = {} } = yield call(
      [AdminService, 'requestVariables'],
      {
        ...formState,
        id: { like: formState.id },
        note: { like: formState.note }
      }
    )

    yield put(variablesActions.completeRequestVariables({ data, pagination }))
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message,
        duration: 0
      })
    )
  }
}

function* completeVariablesSingle(
  services,
  { payload: { id, data, note = '' } }
) {
  const AdminService = services('AdminService')

  const { message, responseData } = yield call(
    [AdminService, 'updateVariable'],
    {
      id,
      note,
      data
    }
  )

  yield put(variablesActions.setSingleVariable(responseData))

  yield put(
    notificationActions.notificationEnqueue({
      message,
      duration: 0
    })
  )
}

export default function* watchVariables(services) {
  yield all([
    takeEvery('VARIABLES_REQUEST', requestVariables, services),
    takeEvery('VARIABLES_DELETE', variablesDelete, services),
    takeEvery('VARIABLES_REQUEST_SINGLE', requestSingleVariable, services),
    takeEvery('VARIABLES_COMPLETE_SINGLE', completeVariablesSingle, services)
  ])
}
