import { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import Ripple from '../Ripple'
import Spinner from '../Spinner'
import classNames from 'classnames/bind'
import icons from '@psl/icons/listIcons'
import Icon from '../Icon'
import styles from './Button.module.sass'
import Typography from '../Typography'
import { between } from '../utils/propValidators'

class Button extends Component {
  constructor(props) {
    super(props)
    this.buttonFocus = this.buttonFocus.bind(this)
    this.buttonBlur = this.buttonBlur.bind(this)
  }
  static displayName = 'Button'
  static defaultProps = {
    text: 'Submit',
    tier: 'default',
    raised: true,
    rippleHasColor: false,
    hasSpinner: false,
    disabled: false,
    submitting: false,
    login: false,
    iconSize: 24,
    spinnerSize: 35
  }
  static propTypes = {
    /**
     * An border style to be applied to the button
     */
    border: PropTypes.string,
    /**
     * A button background color
     */
    backgroundColor: PropTypes.string,
    /**
     * An additional custom className for the root element
     */
    className: PropTypes.string,
    /**
     * The button text color
     */
    color: PropTypes.oneOf([
      '',
      'primary',
      'secondary',
      'tertiary',
      'action',
      'text-primary',
      'text-secondary'
    ]),
    /**
     * Is this a "raised" button? `false` will make it a "flat" button
     */
    raised: PropTypes.bool,
    /**
     * Does this button show a progress spinner when submitting? (Not available for a "flat" button)
     */
    hasSpinner: PropTypes.bool,
    /**
     * Is this a login style input? (Cannot be used with "type='textarea'"
     */
    login: PropTypes.bool,
    /**
     * The button color tier (defined by the theme)
     * Using `default` will take the button icon, text, and progress spinner colors from the 'button 'typography theme
     */
    tier: PropTypes.oneOf([
      'primary',
      'secondary',
      'tertiary',
      'default',
      'knockout',
      'action'
    ]),
    /**
     * Whether or not the button is submitting
     */
    submitting: PropTypes.bool,
    /**
     * Whether or not the button is disabled
     */
    disabled: PropTypes.bool,
    /**
     * The text shown in the button
     */
    text: PropTypes.string,
    /**
     * The text case. Uses the same format as a `text-transform` css property
     */
    textCase: PropTypes.oneOf([
      '',
      'uppercase',
      'none',
      'lowercase',
      'capitalize'
    ]),
    /**
     * The ripple animation shown on click is grey or color
     */
    rippleHasColor: PropTypes.bool,
    /**
     * The component children (May be used for the button text and will override the "text" prop)
     */
    children: PropTypes.node,
    /**
     * An svg icon to show to the left of the button text
     */
    iconLeft: PropTypes.oneOf(['', ...icons]),
    /**
     * An svg icon to show to the right of the button text
     */
    iconRight: PropTypes.oneOf(['', ...icons]),
    /**
     * The icon size, in pixels (1 - 60)
     */
    iconSize: between({ gte: 1, lte: 60 }),
    /**
     * Minimum width of button. Uses the same format as a `min-width` css property
     */
    minWidth: PropTypes.string,
    /**
     * If provided, the 'font-family' used for the button text
     */
    fontFamily: PropTypes.string,
    /**
     * If provided, the 'font-size' used for the button text
     */
    fontSize: PropTypes.string,
    /**
     * A CSS modules style object to override default theme
     */
    altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    /**
     * Size of the spinner
     */
    spinnerSize: PropTypes.number,
    /**
     * Color of the spinner
     */
    spinnerStrokeColor: PropTypes.string
  }
  rippleRef = createRef()
  buttonFocus(event) {
    this.rippleRef.current.focus(event)
  }
  buttonBlur() {
    this.rippleRef.current.blur()
  }
  render = () => {
    const {
      text,
      submitting,
      disabled,
      tier,
      raised,
      rippleHasColor,
      hasSpinner,
      login,
      children,
      className,
      iconLeft,
      iconRight,
      iconSize,
      textCase,
      altTheme,
      minWidth,
      border,
      backgroundColor,
      fontFamily,
      fontSize,
      color,
      spinnerSize,
      spinnerStrokeColor,
      ...other
    } = this.props
    const themeStyles = { ...styles, ...altTheme }
    const cx = classNames.bind(themeStyles)
    const containerClass = raised ? 'raised' : 'flat'
    const buttonColor = raised && tier === 'knockout' ? 'default' : tier
    const iconColor =
      color ||
      (raised
        ? tier === 'default' || tier === 'knockout'
          ? 'action'
          : 'knockout'
        : tier)
    return (
      <Ripple
        role="ripple"
        ref={this.rippleRef}
        hasColor={!disabled && !submitting && rippleHasColor}
        className={cx(
          themeStyles[containerClass],
          {
            disabled,
            submitting,
            login
          },
          className
        )}
        style={{
          ...(minWidth && { minWidth: minWidth }),
          border,
          backgroundColor
        }}
      >
        <button
          type="submit"
          aria-label={text}
          disabled={disabled || submitting}
          className={cx(themeStyles.button, themeStyles[buttonColor], {
            [themeStyles.buttonSubmitting]: hasSpinner && raised && submitting
          })}
          onFocus={this.buttonFocus}
          onBlur={this.buttonBlur}
          style={{
            backgroundColor
          }}
          {...other}
        >
          {iconLeft && (
            <Icon
              className={themeStyles.icon}
              icon={iconLeft}
              iconSize={iconSize}
              colorTier={iconColor}
              disabled={disabled}
            />
          )}
          <span
            data-testid="span"
            className={cx(themeStyles.title, themeStyles[color])}
          >
            <Typography
              role="typography"
              style={{ ...(textCase && { textTransform: textCase }), fontSize }}
              className={themeStyles.text}
              type="button"
              align="center"
              color="inherit"
              fontFamily={fontFamily}
            >
              {children || text}
            </Typography>
          </span>
          {iconRight && (
            <Icon
              className={themeStyles.icon}
              icon={iconRight}
              iconSize={iconSize}
              colorTier={iconColor}
              disabled={disabled}
            />
          )}
          {hasSpinner && (
            <Spinner
              size={spinnerSize}
              altTheme={styles}
              fillColor="none"
              strokeColor={spinnerStrokeColor}
              style={{
                minHeight: null
              }}
              colorTier={iconColor}
            />
          )}
        </button>
      </Ripple>
    )
  }
}

export default Button
