import { isEmpty } from 'utils'
import { smartsEndpoints } from '../constants'

/**
 * Smarts endpoints Service
 */
const AdminService = SmartsML => ({
  /**
   * Upload Universe file
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async uploadUniverseFile(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.universeFileEndpoint,
      'PUT',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },
  /**
   * Download Universe file
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async downloadUniverseFile() {
    // Please don't judge me for this, json-server doesn't support raw data response
    const mockResponse = {
      data: "'country','profession','specialty','universe'\n'Australia','Physician','Endocrinology','704'\n'Austria','Physician','Endocrinology','0'\n'Belgium','Physician','Endocrinology','0'\n'Brazil','Physician','Endocrinology','5856'\n'Canada','Physician','Endocrinology','741'\n'China','Physician','Endocrinology','45000'\n'Colombia','Physician','Endocrinology','0'\n'Czech','Physician','Endocrinology','0'\n'Denmark','Physician','Endocrinology','478'\n'Finland','Physician','Endocrinology','0'\n'France','Physician','Endocrinology','1390'\n'Germany','Physician','Endocrinology','481'\n'Greece','Physician','Endocrinology','767'\n'Hong Kong','Physician','Endocrinology','0'\n'Hungary','Physician','Endocrinology','0'\n'India','Physician','Endocrinology','1410'\n'Indonesia','Physician','Endocrinology','476'\n'Ireland','Physician','Endocrinology','0'\n'Italy','Physician','Endocrinology','5183'\n'Japan','Physician','Endocrinology','4889'\n'Korea','Physician','Endocrinology','766'\n'Malaysia','Physician','Endocrinology','419'\n'Mexico','Physician','Endocrinology','993'\n'Netherlands','Physician','Endocrinology','500'\n'Norway','Physician','Endocrinology','0'\n'Philippines','Physician','Endocrinology','387'\n'Poland','Physician','Endocrinology','939'\n'Portugal','Physician','Endocrinology','0'\n'Romania','Physician','Endocrinology','577'\n'Russia','Physician','Endocrinology','15200'\n'Saudi Arabia','Physician','Endocrinology','354'\n'Singapore','Physician','Endocrinology','0'\n'South Africa','Physician','Endocrinology','0'\n'Spain','Physician','Endocrinology','1857'\n'Sweden','Physician','Endocrinology','415'\n'Switzerland','Physician','Endocrinology','0'\n'Taiwan','Physician','Endocrinology','780'"
    }
    const response =
      process.env.NODE_ENV === 'production'
        ? await SmartsML.fetch(smartsEndpoints.universeFileEndpoint, 'POST', {})
        : mockResponse
    // Create CSV File
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'text/csv' })
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `universe.csv`)
    document.body.appendChild(link)
    link.click()
    link.remove()

    return {}
  },
  /**
   * Search Universe
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async searchUniverse(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.universeSearchEndpoint,
      'POST',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Rebuild Incoming
   *
   * @returns {Promise<*[]|*>}
   */
  async rebuildIncomingCounts() {
    const response = await SmartsML.fetch(
      smartsEndpoints.rebuildIncomingEndpoint,
      'POST',
      {}
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Delete Incoming
   *
   * @returns {Promise<*[]|*>}
   */
  async deleteIncomingCounts() {
    const response = await SmartsML.fetch(
      smartsEndpoints.incomingSearchEndpoint,
      'DELETE',
      {}
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Search Incoming Counts
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async searchIncoming(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.incomingSearchEndpoint,
      'POST',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },

  /**
   * Search Threshold
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async searchThreshold(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.thresholdEndpoint,
      'POST',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },

  /**
   * Updates or Creates Threshold Value
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async updateThreshold(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.thresholdEndpoint,
      'PUT',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },

  /**
   * Get Single Threshold by ID
   *
   * @param id
   * @returns {Promise<*[]|*>}
   */
  async getSingleThreshold(id) {
    const response = await SmartsML.fetch(
      smartsEndpoints.thresholdEndpoint + `/${id}`,
      'GET'
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },

  /**
   * Delete Threshold Element
   *
   * @param id
   * @returns {Promise<*[]|*>}
   */
  async deleteThreshold(id) {
    const response = await SmartsML.fetch(
      smartsEndpoints.thresholdEndpoint + `/${id}`,
      'DELETE'
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },

  /**
   * Request Universe History
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async requestUniverseHistory(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.universeHistory,
      'POST',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },

  /**
   * Delete Universe History Element
   *
   * @param id
   * @returns {Promise<*[]|*>}
   */
  async deleteUniverseHistory(id) {
    const response = await SmartsML.fetch(
      smartsEndpoints.universeHistory + `/${id}`,
      'DELETE'
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Get Single Universe History
   *
   * @param id
   * @returns {Promise<*[]|*>}
   */
  async getSingleUniverseHistory(id) {
    const response = await SmartsML.fetch(
      smartsEndpoints.universeHistory + `/${id}`,
      'GET'
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Restore Universe History
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async restoreUniverseHistory(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.universeHistory,
      'PUT',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return null
  },
  /**
   * Upload Users csv file
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async uploadUsersBulkFile(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.smartsUsersUpload,
      'PUT',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },
  /**
   * Download Users csv file
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async downloadUsersBulkFile() {
    // Please don't judge me for this, json-server doesn't support raw data response
    const mockResponse = {
      data: "'email','created','info','roles','status','updated','uuid'\n'haridasan.balakrishnan@pslgroup.com','1660313261727',{'firstName':'haridasan.balakrishnan'},['BASIC'],'active','1660313261727','2659fc95-efff-4740-ad7b-a4b1d7987d80'"
    }
    const response =
      process.env.NODE_ENV === 'production'
        ? await SmartsML.fetch(smartsEndpoints.smartsUsersDownload, 'GET', {})
        : mockResponse
    // Create CSV File
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'text/csv' })
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `users.csv`)
    document.body.appendChild(link)
    link.click()
    link.remove()

    return {}
  },

  /**
   * Gets the user's possible roles
   *
   * @param data
   * @returns {Promise<*[]|*>}
   */
  async getPossibleRoles() {
    const response = await SmartsML.fetch(
      smartsEndpoints.smartsRoles,
      'GET',
      {}
    )

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },

  async searchUsers(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.smartsUsersSearch,
      'POST',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },

  async userInvalidate(email) {
    const response = await SmartsML.fetch(
      smartsEndpoints.smartsUsersInvalidate,
      'POST',
      { email }
    )
    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },

  async requestVariables(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.variablesEndpoint,
      'POST',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Deletes a variable by id
   *
   * @param {*} id
   * @returns
   */
  async variablesDelete(id) {
    const response = await SmartsML.fetch(
      smartsEndpoints.variablesEndpoint + `/${id}`,
      'DELETE'
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Gets a single variable by ID
   *
   * @param {*} id
   * @returns
   */
  async getSingleVariable(id) {
    const response = await SmartsML.fetch(
      smartsEndpoints.variablesEndpoint + `/${id}`,
      'GET'
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  async updateVariable(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.variablesEndpoint,
      'PUT',
      data
    )
    return response.data
  },
  async searchDataCaches(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.dataCachesEndpoint + '/search',
      'POST',
      data
    )
    return response.data.responseData
  },
  async clearFunctionalDataCaches() {
    const response = await SmartsML.fetch(
      smartsEndpoints.dataCachesEndpoint + '/clear-type/functional',
      'DELETE'
    )
    return response.data
  },
  async clearIndividualDataCaches() {
    const response = await SmartsML.fetch(
      smartsEndpoints.dataCachesEndpoint + '/clear-type/individual',
      'DELETE'
    )
    return response.data
  },
  async rebuildAllDataCaches() {
    const response = await SmartsML.fetch(
      smartsEndpoints.dataCachesEndpoint + '/rebuild-all',
      'GET'
    )
    return response.data
  },
  /**
   * Deletes a single DC record by ID
   * @param {*} id - ID of the record to delete
   * @returns {Promise<*{}|*>} - Returns a message with the result of the operation
   */
  async deleteDataCache(id) {
    const response = await SmartsML.fetch(
      smartsEndpoints.dataCachesEndpoint + `/${id}`,
      'DELETE'
    )
    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },
  /**
   * @param {String} id - ID of the record to fetch
   * @returns {Promise<*{}|*>} - Returns "Handler & Compiled Data into JSON"
   */
  async getDataCacheRecord(id, type) {
    const url =
      type === 'record' ? `/download/${id}` : `/download-options/${id}`
    const response = await SmartsML.fetch(
      smartsEndpoints.dataCachesEndpoint + url,
      'GET'
    )
    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },

  /**
   * @param {String} id - ID of the record clear options
   * @returns {Promise<*{}|*>} - Returns the result of the operation (message)
   */
  async clearDataCacheOptions(id) {
    const response = await SmartsML.fetch(
      smartsEndpoints.dataCachesEndpoint + `/clear-id/${id}`,
      'DELETE'
    )
    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },

  /**
   *
   * @param {String} id - ID of the record to rebuild
   * @returns {Promise<*{}|*>} - Returns the result of the operation (message)
   */
  async rebuildDataCachesById(id) {
    const response = await SmartsML.fetch(
      smartsEndpoints.dataCachesEndpoint + `/rebuild/${id}`,
      'GET'
    )
    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },

  async getChartsGenericConfiguration() {
    const response = await SmartsML.fetch(
      smartsEndpoints.chartsGenericEndpoint,
      'GET'
    )
    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },

  async updateChartsGenericConfiguration(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.chartsGenericEndpoint,
      'PUT',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },

  async searchChartsConfiguration(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.chartsSearchEndpoint,
      'POST',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },

  async getIndividualChartConfig(id) {
    const response = await SmartsML.fetch(
      smartsEndpoints.chartsConfigEndpoint + `/${id}`,
      'GET'
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },

  async deleteIndividualChartConfig(id) {
    const response = await SmartsML.fetch(
      smartsEndpoints.chartsConfigEndpoint + `/${id}`,
      'DELETE'
    )
    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },

  async getVariations(key) {
    const data = { search: { perPage: 200 } }
    const response = await SmartsML.fetch(
      smartsEndpoints.chartsVariationsEndpoint + `/${key}`,
      'POST',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data.responseData.data
    }
    return {}
  },

  async updateChartConfig(data) {
    const response = await SmartsML.fetch(
      smartsEndpoints.chartsConfigEndpoint,
      'PUT',
      data
    )
    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  }
})
export default AdminService
