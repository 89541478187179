import { createSelector } from '@reduxjs/toolkit'

export const exploreProjectSelector = state => state.exploreProjectReducer

export const getFormState = () =>
  createSelector(
    exploreProjectSelector,
    exploreProject => exploreProject.formState
  )
export const getFetchStatus = () =>
  createSelector(
    exploreProjectSelector,
    exploreProject => exploreProject.fetching
  )
