import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as combinationsActions } from './CombinationsTableReducer'

/**
 * Request combinations
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* combinationsRequest(services, { payload }) {
  try {
    const SmartsService = services('SmartsService')
    const TypeaheadParser = services('TypeaheadParser')
    // Call endpoint
    const { options, selections, map } = yield call([
      SmartsService,
      'combinationsFetchAll'
    ])

    // Parse options
    const parsedCountries = yield call(
      [TypeaheadParser, 'optionsParser'],
      options.countries
    )
    const parsedProfessions = yield call(
      [TypeaheadParser, 'optionsParser'],
      options.professions
    )
    const parsedSpecialties = yield call(
      [TypeaheadParser, 'optionsParser'],
      options.specialties
    )

    // Parse Tooltips
    const countriesTooltips = yield call(
      [TypeaheadParser, 'tooltipParser'],
      options.countries
    )
    const professionsTooltips = yield call(
      [TypeaheadParser, 'tooltipParser'],
      options.professions
    )
    const specialtiesTooltips = yield call(
      [TypeaheadParser, 'tooltipParser'],
      options.specialties
    )
    // Update
    yield put(
      combinationsActions.combinationsTableRequestSuccess({
        combinationsTable: {
          countries: parsedCountries,
          professions: parsedProfessions,
          specialties: parsedSpecialties,
          selections,
          map
        },
        tooltips: {
          ...countriesTooltips,
          ...professionsTooltips,
          ...specialtiesTooltips
        }
      })
    )
  } catch (e) {
    yield put(combinationsActions.combinationsTableRequestFail())
    console.error(e)
  }
}

export default function* watchListIdRequest(services) {
  yield all([
    takeEvery('COMBINATIONS_TABLE_REQUEST', combinationsRequest, services)
  ])
}
