import { createReducer, createAction } from '@reduxjs/toolkit'

export const jobStatusRequest = createAction('JOB_STATUS_REQUEST')
export const jobStatusRequestSuccess = createAction(
  'JOB_STATUS_REQUEST_SUCCESS'
)
export const jobStatusRequestFail = createAction('JOB_STATUS_REQUEST_FAIL')

export const jobStatusDownloadRequest = createAction(
  'JOB_STATUS_DOWNLOAD_REQUEST'
)

// FETCH EMAILS ACTIONS
export const jobStatusEmailsRequestSuccess = createAction(
  'JOB_STATUS_EMAILS_REQUEST_SUCCESS'
)
export const jobStatusEmailsRequestFail = createAction(
  'JOB_STATUS_EMAILS_REQUEST_FAIL'
)

// FETCH GROUPS ACTIONS
export const jobStatusGroupsRequestSuccess = createAction(
  'JOB_STATUS_GROUPS_REQUEST_SUCCESS'
)
export const jobStatusGroupsRequestFail = createAction(
  'JOB_STATUS_GROUPS_REQUEST_FAIL'
)

// FETCH FILES ACTIONS
export const jobStatusFilesRequestSuccess = createAction(
  'JOB_STATUS_FILES_REQUEST_SUCCESS'
)
export const jobStatusFilesRequestFail = createAction(
  'JOB_STATUS_FILES_REQUEST_FAIL'
)

export const actions = {
  jobStatusRequest,
  jobStatusRequestSuccess,
  jobStatusRequestFail,
  jobStatusDownloadRequest,
  jobStatusEmailsRequestFail,
  jobStatusEmailsRequestSuccess,
  jobStatusGroupsRequestSuccess,
  jobStatusGroupsRequestFail,
  jobStatusFilesRequestSuccess,
  jobStatusFilesRequestFail
}

const JobStatusSingleReducer = createReducer(
  {
    jobStatus: {},
    fetching: false,
    error: '',
    emailsFetching: false,
    groupsFetching: false,
    filesFetching: false
  },
  {
    [jobStatusRequest]: state => {
      state.fetching = true
      state.jobStatus = []
      state.emailsFetching = true
      state.groupsFetching = true
      state.filesFetching = true
    },
    [jobStatusRequestSuccess]: (state, { payload: { list } }) => {
      state.jobStatus = { ...state.jobStatus, ...list }
      state.fetching = false
      state.error = ''
    },
    [jobStatusRequestFail]: (state, { payload: { error = '' } }) => {
      state.fetching = false
      state.error = error
      state.jobStatus = {}
    },
    [jobStatusEmailsRequestSuccess]: (state, { payload: { emails = [] } }) => {
      state.emailsFetching = false
      state.jobStatus = { ...state.jobStatus, emails }
    },
    [jobStatusEmailsRequestFail]: state => {
      state.emailsFetching = false
      state.jobStatus = { ...state.jobStatus, emails: [] }
    },
    [jobStatusGroupsRequestSuccess]: (state, { payload: { groups = [] } }) => {
      state.groupsFetching = false
      state.jobStatus = { ...state.jobStatus, groups }
    },
    [jobStatusGroupsRequestFail]: state => {
      state.groupsFetching = false
      state.jobStatus = { ...state.jobStatus, groups: [] }
    },
    [jobStatusFilesRequestSuccess]: (
      state,
      { payload: { exportFiles = [] } }
    ) => {
      state.filesFetching = false
      state.jobStatus = { ...state.jobStatus, exportFiles }
    },
    [jobStatusFilesRequestFail]: state => {
      state.filesFetching = false
      state.jobStatus = { ...state.jobStatus, exportFiles: [] }
    }
  }
)

export default JobStatusSingleReducer
