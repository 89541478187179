import { useSelector } from 'react-redux'
import useMediaSize from 'hooks/useMediaSize'

// import PopularPrompts from 'modules/dougall/popularPrompts/PopularPrompts'
import History from 'modules/dougall/history/History'
import Faq from 'modules/dougall/faq/Faq'

import { getDisplayContainer } from 'modules/dougall/chatPage/ChatPageSelector'

const RightBar = () => {
  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 800px)'
    }
  })
  const displayContainer = useSelector(getDisplayContainer())

  return (
    <div>
      {mediaSize === 'small' ? (
        displayContainer === 'popular' ? (
          {
            /* <PopularPrompts /> */
          }
        ) : displayContainer === 'faqs' ? (
          <Faq />
        ) : (
          <History />
        )
      ) : null}
    </div>
  )
}

export default RightBar
