import { takeEvery, all, call } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'
import queryString from 'query-string'
import { isEmpty, transformTrackingUrl } from '../../utils'
import { secondTrackerCustomVars } from '../../constants'
import history from '../../history'
import siteConfig from 'site.config.json'

const { routes } = siteConfig

/**
 * Sends a click event to Piwik.
 */
function* trackClick(services, clickObj, userData, type = '') {
  try {
    const { title } = clickObj
    delete clickObj.title
    const Tracking = services('TrackingService')
    // CUSTOM TYPE
    const action = !isEmpty(type) ? `${type}/Click/${title}` : `Click/${title}`
    yield call([Tracking, 'track'], action, clickObj, userData)
  } catch (e) {
    console.error(e)
  }
}

/**
 * Trigger custom tracking action
 *
 * @param services
 * @param title
 * @param data
 * @param articleId
 * @returns {Generator<<"CALL", CallEffectDescriptor<any>>, void, *>}
 */
function* trackSubmitAction(services, { payload: { title } }) {
  try {
    const {
      location: { pathname }
    } = history
    const CookieService = services('CookieService')
    const Tracking = services('TrackingService')
    // GET USER ID
    const uuid = yield call([CookieService, 'getFromCache'], 'uuid', 0)
    let userData = ''
    userData = {
      uuid
    }
    // TRIGGER ACTION
    yield call(
      [Tracking, 'track'],
      isEmpty(title) ? `View - ${pathname}` : `View - ${title}`,
      {},
      userData
    )
  } catch (e) {
    console.error(e)
  }
}

/**
 * @param services
 * @param payload
 * @returns {Generator<any, void, *>}
 *
 */
function* locationChange(services, { payload }) {
  try {
    const CookieService = services('CookieService')
    let {
      location: { pathname, search },
      action
    } = payload
    // Instant URL Params
    const parsedParams = queryString.parse(search)

    let title = ''
    let userData = ''
    // UPDATE CACHE
    yield call([CookieService, 'saveToCache'], { ...parsedParams })
    // GET USER ID
    const uuid = yield call([CookieService, 'getFromCache'], 'uuid', 0)
    userData = {
      uuid
    }
    // TRACK EXTERNAL NAV. OR CUSTOM CLICK
    if (!['REPLACE', 'POP'].includes(action)) {
      if (routes.feedback && !pathname.includes(routes.feedback.path))
        yield call([CookieService, 'saveToCache'], { lastAction: pathname })
      // Home
      if (pathname === '/') {
        title = 'home'
      }

      // TRACK VIEW
      yield call(trackView, services, {
        payload: {
          pathname,
          title,
          userData
        }
      })
    }
  } catch (e) {
    console.error(e)
  }
}

function* trackView(services, { payload }) {
  try {
    const { pathname, title, userData = '' } = payload
    const TrackingService = services('TrackingService')
    const action = isEmpty(title) ? `View - ${pathname}` : `View - ${title}`
    yield call([TrackingService, 'track'], action, {}, userData)
  } catch (error) {
    console.error(error)
  }
}

/**
 * @param services
 * @param payload
 * @returns {Generator<any, void, *>}
 *
 */
function* trackCoinClick(services, { payload }) {
  try {
    const CookieService = services('CookieService')
    const { pathname } = payload

    let userData = ''
    // GET USER ID
    const uuid = yield call([CookieService, 'getFromCache'], 'uuid', 0)
    userData = {
      uuid
    }

    const TrackingService = services('TrackingService')
    const action = `Authenticated - Coin Click: Home ${pathname}`
    yield call([TrackingService, 'track'], action, {}, userData)
  } catch (e) {
    console.error(e)
  }
}

/**
 * @param services
 * @param payload
 * @returns {Generator<any, void, *>}
 *
 */
function* trackCustomAction(services, { payload }) {
  try {
    const CookieService = services('CookieService')
    const { title } = payload

    let userData = ''
    // GET USER ID
    const uuid = yield call([CookieService, 'getFromCache'], 'uuid', 0)
    userData = {
      uuid
    }

    const TrackingService = services('TrackingService')
    yield call([TrackingService, 'track'], title, {}, userData)
  } catch (e) {
    console.error(e)
  }
}

function* trackClickAction(services, { payload }) {
  try {
    const {
      pathname,
      title,
      campaignId,
      entityID = '',
      articleID = '',
      userData = '',
      clickType = ''
    } = payload
    const TrackingService = services('TrackingService')
    const CookieService = services('CookieService')
    const action = isEmpty(title) ? `Click${pathname}` : `Click/${title}`
    const projectNumber = isEmpty(campaignId)
      ? yield call([CookieService, 'getFromCache'], 'CampaignID', '')
      : campaignId
    yield call(
      [TrackingService, 'track'],
      action,
      clickObj({
        projectNumber: projectNumber,
        source: pathname,
        entityID: entityID,
        fbID: articleID,
        clickType: clickType
      }),
      userData
    )
  } catch (error) {
    console.error(error)
  }
}

function* trackToSecondTracker(
  services,
  { payload: { url, siteId, id, customVariables } }
) {
  try {
    const Matomo = window.frames['Matomo']

    if ('undefined' === typeof Matomo) return
    if ('undefined' === typeof Matomo.MediaAnalytics) return

    const tracker = Matomo.getTracker(transformTrackingUrl(url, true), siteId)
    const CookieService = services('CookieService')

    // remove all previously assigned custom variables,
    // requires Matomo (formerly Piwik) 3.0.2
    tracker.deleteCustomVariables('page')
    tracker.deleteCustomVariables('visit')

    let i = 1
    // Push new 'page' values.
    for (const key in secondTrackerCustomVars) {
      const name = secondTrackerCustomVars[key]
      let cachedValue
      switch (name) {
        case 'PSLDeviceID':
          cachedValue = yield call(
            [CookieService, 'getFromCache'],
            'id_key',
            ''
          )
          break
        case 'CountryID':
          cachedValue = yield call(
            [CookieService, 'getFromCache'],
            'country',
            ''
          )
          break
        case 'ProfesionID':
          cachedValue = yield call(
            [CookieService, 'getFromCache'],
            'profession_id',
            ''
          )
          break
        default:
          cachedValue = yield call([CookieService, 'getFromCache'], name, '')
      }
      tracker.setCustomVariable(i++, name, cachedValue, 'visit')
    }

    i = 1
    for (const key in customVariables) {
      tracker.setCustomVariable(i++, key, customVariables[key], 'page')
    }
    Matomo.MediaAnalytics.scanForMedia(document.getElementById(id))
    Matomo.MediaAnalytics.setMatomoTrackers(tracker)
  } catch (error) {
    console.error(error)
  }
}

/**
 * Parses url nl_site to get idh siteID
 *
 * @param nlSite
 * @returns {string|number}
 */
// const getSiteId = nlSite => {
//   if (!isEmpty(nlSite)) {
//     return parseInt(nlSite)
//   } else {
//     return ''
//   }
// }
/**
 *
 * @param projectNumber
 * @param source
 * @param entityID
 * @param fbID
 * @returns {{fb_id: *, "Project number": *, source: *, entity_id: *}}
 */
// const viewObj = ({
//   projectNumber = '',
//   source = '',
//   entityID = '',
//   fbID = ''
// }) => ({
//   'Project number': projectNumber,
//   source: source,
//   entity_id: entityID,
//   fb_id: fbID
// })
/**
 *
 * @param projectNumber
 * @param source
 * @param destURL
 * @param entityID
 * @param fbID
 * @param clickType
 * @returns {{"Dest URL": *, fb_id: *, "Project number": *, source: *, entity_id: *, "Click Type": *}}
 */
const clickObj = ({
  projectNumber = '',
  source = '',
  destURL = '',
  entityID = '',
  fbID = '',
  clickType = ''
}) => ({
  'Project number': projectNumber,
  source: source,
  'Dest URL': destURL,
  entity_id: entityID,
  fb_id: fbID,
  'Click Type': clickType,
  title: clickType
})
/**
 *
 * @param projectNumber
 * @param source
 * @param entityID
 * @param fbID
 * @returns {{fb_id: *, "Project number": *, source: *, entity_id: *}}
 */
// const submitObj = ({
//   projectNumber = '',
//   source = '',
//   entityID = '',
//   fbID = ''
// }) => ({
//   'Project number': projectNumber,
//   source: source,
//   entity_id: entityID,
//   fb_id: fbID
// })
export default function* watchTracking(services) {
  yield all([
    takeEvery('TRACK_SUBMIT_ACTION', trackSubmitAction, services),
    takeEvery('TRACK_CLICK', trackClick, services),
    takeEvery('TRACK_ACTION_SECOND_TRACKER', trackToSecondTracker, services),
    takeEvery('TRACK_PAGE_VIEW', trackView, services),
    takeEvery('TRACK_CLICK_ACTION', trackClickAction, services),
    takeEvery(LOCATION_CHANGE, locationChange, services),
    takeEvery('TRACK_COIN_CLICK', trackCoinClick, services),
    takeEvery('TRACK_CUSTOM_ACTION', trackCustomAction, services)
  ])
}
