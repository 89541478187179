import { all, call, put, takeEvery, select } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as advisorSearchActions } from 'modules/formAdvisorSearch/AdvisorSearchReducer'
import { actions as trackActions } from 'modules/tracking/TrackingReducer'
import { getIsAllowed } from '../auth/AuthSelector'
import history from '../../history'
import { isEmpty } from 'utils'
import { getFeaturesTable } from 'modules/auth/AuthSelector'
import { initialValues } from './config'
/**
 * Receives search request action and reacts accordingly
 *
 * @param payload
 * @param services
 */
function* searchRequest(services, { payload }) {
  const AdvisorSearch = services('AdvisorSearchService')
  try {
    yield put(trackActions.trackSubmitAction({}))
    // GET IS ADMIN FOR LISTINGS PARSER
    const isAdmin = yield select(getIsAllowed())
    const { 'lima-bureau-advisor': bureauRole } = yield select(
      getFeaturesTable()
    )
    const maxResultsDisplay = bureauRole.maxResultsDisplay

    // SAVE FORM STATE
    yield put(
      advisorSearchActions.searchRequestFormState({ formState: payload })
    )

    // EXEC. QUERY
    const parsedListings = yield call(
      [AdvisorSearch, 'fetchList'],
      payload,
      maxResultsDisplay,
      isAdmin
    )

    // VALIDATE SEARCH RESULTS
    if (isEmpty(parsedListings)) {
      yield put(
        notificationActions.notificationEnqueue({
          message: 'No advisors found, please update your search criteria',
          duration: 0
        })
      )
      yield put(advisorSearchActions.searchRequestFail())
    } else {
      yield put(
        advisorSearchActions.searchRequestSuccess({
          advisorList: parsedListings
        })
      )
      yield call(forwardTo, '/lima-bureau-advisor/list')
    }
  } catch (e) {
    console.error(e)
    yield put(advisorSearchActions.searchRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message:
          'Oops, there was an error in the search. Please try again later',
        duration: 0
      })
    )
  }
}

function* specialtyListRequest(services) {
  const AdvisorSearch = services('AdvisorSearchService')
  try {
    const specialties = yield call([AdvisorSearch, 'getSpecialtyList'])
    yield put(advisorSearchActions.searchSpecialtiesSuccess(specialties))
  } catch (e) {
    console.error({ specialtiesListError: e })
    yield put(advisorSearchActions.searchSpecialtiesFail())
  }
}

function* professionListRequest(services) {
  const AdvisorSearch = services('AdvisorSearchService')
  try {
    const professions = yield call([AdvisorSearch, 'getProfessionList'])
    yield put(advisorSearchActions.searchProfessionsSuccess(professions))
  } catch (e) {
    console.error({ professionsListError: e })
    yield put(advisorSearchActions.searchProfessionsFail())
  }
}

const forwardTo = location => {
  history.push(location)
}

function* resetForm(services, { payload }) {
  const { action, location } = payload

  if (action === 'REPLACE' && location.pathname === '/') {
    // Reset form if user navigates back to home
    yield put(
      advisorSearchActions.searchRequestFormState({
        formState: { ...initialValues }
      })
    )
    // Reset list result status
    yield put(advisorSearchActions.searchRequestSuccess({ advisorList: [] }))
  }
}

export default function* watchSearch(services) {
  yield all([
    takeEvery('SEARCH_REQUEST', searchRequest, services),
    takeEvery('SEARCH_SPECIALTIES_REQUEST', specialtyListRequest, services),
    takeEvery('SEARCH_PROFESSIONS_REQUEST', professionListRequest, services),
    takeEvery(LOCATION_CHANGE, resetForm, services)
  ])
}
