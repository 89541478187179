import PropTypes from 'prop-types'

import styles from './FeedbackButton.module.sass'

const FeedbackButton = ({ onClick }) => (
  <div
    className={styles.sideFeedback}
    onClick={onClick}
    onKeyDown={e => {
      if (e.key === 'Enter' || e.key === ' ') onClick()
    }}
  >
    Feedback
  </div>
)

FeedbackButton.propTypes = {
  /**
   * On click callback
   */
  onClick: PropTypes.func
}

export default FeedbackButton
