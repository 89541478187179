import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { styled } from '@mui/material/styles'

import Typography from 'components/ui/Typography'
import { actions as chatPageActions } from 'modules/dougall/chatPage/ChatPageReducer'

import styles from './MobileButtonBar.module.sass'
import { ClockRewind, FileQuestion } from 'static/icons'

/**
 * ButtonIcon
 */
const ButtonIcon = ({ icon, ...props }) => {
  const Icon = styled(icon)(() => ({
    marginRight: '12px',
    marginLeft: '0px',
    fontSize: '20px',
    fill: 'none',
    path: {
      stroke: '#4e5ba6'
    }
  }))
  return <Icon {...props} />
}
ButtonIcon.propTypes = {
  /**
   * Icon component to be rendered
   */
  icon: PropTypes.func
}

const BarButton = ({ icon = '', label = '', onClick = () => {} }) => {
  return (
    <div
      className={styles.barButton}
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') onClick()
      }}
    >
      <ButtonIcon icon={icon} />
      <Typography type="display2">{label}</Typography>
    </div>
  )
}

BarButton.propTypes = {
  icon: PropTypes.func,
  label: PropTypes.string,
  onClick: PropTypes.func
}

const MobileButtonBar = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleShowContainer = container => {
    dispatch(chatPageActions.displayContainer({ container }))
  }

  return (
    <div className={styles.buttonBar}>
      {/* <BarButton
        icon={Star}
        label={t('popularPrompt')}
        onClick={() => handleShowContainer('popular')}
      /> */}
      <BarButton
        icon={ClockRewind}
        label={t('history')}
        onClick={() => handleShowContainer('history')}
      />
      <BarButton
        icon={FileQuestion}
        label={t('chatBoxFaqs')}
        onClick={() => handleShowContainer('faqs')}
      />
    </div>
  )
}

export default MobileButtonBar
