import { useEffect, useState } from 'react'
import Page from 'components/Page'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'utils'
import { actions as contactUsActions } from './ContactUsReducer'
import * as contactUsSelectors from './ContactUsSelector'
import { initialValues } from './config'
import TextInputField from 'components/TextInput'
import TextAreaInput from 'components/TextAreaInput'
import Button from 'components/Button'
import styles from './ContactUs.module.sass'

const { getFormState } = contactUsSelectors

const ContactUsSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  message: Yup.string().required('Required')
})

const ContactUs = () => {
  let initialForm = {
    ...initialValues
  }
  const dispatch = useDispatch()
  const formState = useSelector(getFormState())
  const history = useHistory()
  const formStatePr = isEmpty(formState) ? initialForm : formState
  const [lastAction, setLastAction] = useState('Home')

  const handleBack = () => {
    history.goBack()
  }

  const handleSubmit = values => {
    const payload = {
      ...values,
      userAgent: window.navigator.userAgent,
      lastAction,
      history
    }
    if (typeof values !== 'undefined') {
      dispatch(contactUsActions.submitFormRequest({ payload }))
    }
  }

  useEffect(() => {
    if (typeof history.location.state === 'undefined') {
      setLastAction('Home')
    } else {
      const { prevPath } = history.location.state
      switch (prevPath) {
        case '/explore-market':
          setLastAction('Explore Market')
          break
        case '/explore-soundboard':
          setLastAction('Explore Soundboard')
          break
        case '/explore-list':
          setLastAction('Explore Project List')
          break
        case '/list-ingestion':
          setLastAction('Ingest List')
          break
        case '/compare-list':
          setLastAction('Compare List')
          break
        case '/export-list':
          setLastAction('Export List')
          break
        case '/explore-project':
          setLastAction('Explore Projects')
          break
        case '/job-status':
          setLastAction('Job Status')
          break
        case '/faq':
          setLastAction('FAQ')
          break
        default:
          break
      }
    }

    // eslint-disable-next-line
  }, [])

  return (
    <Page
      heroTitle="Contact us"
      showHero
      showGoBack
      onBackClick={handleBack}
      showDivider
      showFooter={false}
    >
      <Formik
        initialValues={formStatePr}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={ContactUsSchema}
      >
        {formProps => (
          <Form>
            <TextInputField
              required
              label="Your Name"
              name="name"
              type="text"
            />
            <TextAreaInput
              required
              label="Message"
              name="message"
              minHeight={150}
            />
            <div className={styles.buttonContainer}>
              <Button isLimaSubmit disabled={!formProps.isValid} />
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  )
}

export default ContactUs
