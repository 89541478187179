import PropTypes from 'prop-types'
import ProjectMetadata from 'modules/projectMetadata'
import styles from './form.module.sass'
import Button from 'components/Button'
import Divider from '../../../components/ui/Divider'
import Typography from '../../../components/ui/Typography'
import { useSelector, useDispatch } from 'react-redux'
import * as exploreMarketSelectors from '../ExploreMarketSelector'
import * as exploreMarketActions from '../ExploreMarketReducer'
import useFilters from 'modules/filter/useFilters'
import { SelectedFilters } from 'components/Selected'
import useRoleFeatures from 'modules/auth/useRoleFeatures'
import ListSize from 'components/ListSize'

const { getFormState, getFetchStatus } = exploreMarketSelectors

const ProjectMetadataForm = ({ formProps }) => {
  const isFetching = useSelector(getFetchStatus())
  const formState = useSelector(getFormState())
  const { values } = formProps
  const { combinations, filters, type, universe, listSize } = values
  const { selectedFiltersCaptions } = useFilters(filters)
  const { features } = useRoleFeatures({
    coin: 'explore-market'
  })
  const dispatch = useDispatch()

  const handleChangeForm = step => {
    dispatch(
      exploreMarketActions.setFormState({
        ...formState,
        combinationsCountry: '',
        combinationsProfession: '',
        combinationsSpecialty: '',
        activeStep: step
      })
    )
  }

  // const handleTypeSubmit = type => {
  //   setFieldValue('type', type)
  // }

  // Call Charts request without form validation
  const onGraphClick = () => {
    dispatch(
      exploreMarketActions.setFormState({
        ...values,
        type: 'graph',
        activeStep: 'charts'
      })
    )
  }

  return (
    <div data-testid="projectMetadataContainer">
      <SelectedFilters
        combinations={combinations}
        selectedFiltersCaptions={selectedFiltersCaptions}
        onEdit={() => handleChangeForm('filters')}
        isFetching={isFetching}
      />
      <div className={styles.buttonsRow}>
        {type !== 'graph' && features.sections?.actions.includes('graph') && (
          <Button
            className={styles.button}
            hasSpinner={values.type === 'graph'}
            submitting={isFetching}
            disabled={isFetching}
            type="button"
            onClick={onGraphClick}
          >
            Graph
          </Button>
        )}
        {/* {type !== 'save-list' && (
          <Button
            className={styles.button}
            onClick={() => handleTypeSubmit('save-list')}
            hasSpinner={values.type === 'save-list'}
            submitting={isFetching}
            disabled={isFetching}
          >
            Save List
          </Button>
        )} */}
        {/* Run analysis currently disabled */}
        {/* {type !== 'run-analysis' && (
          <Button
            className={styles.button}
            onClick={() => handleTypeSubmit('run-analysis')}
            hasSpinner={values.type === 'run-analysis'}
            submitting={isFetching}
            disabled={isFetching}
          >
            Run Analysis
          </Button>
        )} */}
      </div>
      <ListSize hidden={false} universeSize={universe} listSize={listSize} />
      <div className={styles.center}>
        <Typography
          type="title"
          color="text-primary"
          className={styles.title}
          align="center"
        >
          Select Project Metadata
        </Typography>
        <Divider />
      </div>
      <div>
        <ProjectMetadata
          formName="explore-market"
          formProps={formProps}
          hideDefaultCountry
          hideTypeForm
          hideAgency
          showReportName
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          hasSpinner={values.type === 'save-list'}
          submitting={isFetching}
          disabled={isFetching}
          isLimaSubmit
        >
          Submit
        </Button>
      </div>
    </div>
  )
}

ProjectMetadataForm.propTypes = {
  /**
   * Form props
   */
  formProps: PropTypes.object
}

export default ProjectMetadataForm
