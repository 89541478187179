import * as Yup from 'yup'
// import { isEmpty } from 'utils'

const validationSchema = Yup.object().shape(
  {
    // Search Form
    startDate: Yup.date()
      .typeError('Start Date is required')
      .required('Start Date is required')
      .when('endDate', {
        is: val => val,
        then: Yup.date()
          .max(Yup.ref('startDate'), "Start Date can't be after End Date")
          .nullable()
          .required('Start Date is required')
      })
      .optional()
      .nullable(),
    endDate: Yup.date()
      .typeError('End Date is required')
      .required('End Date is required')
      .when('startDate', {
        is: val => val,
        then: Yup.date()
          .min(Yup.ref('startDate'), "End Date can't be before Start Date")
          .nullable()
          .required('End Date is required')
      })
      .optional()
      .nullable()
  },
  ['endDate', 'startDate']
)

export default validationSchema
