import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'

import Modal from 'components/Dougall/Modal'
import TextInput from 'components/TextInput'
import TextArea from 'components/ui/TextArea'
import Typography from 'components/ui/Typography'

import styles from './EmailModal.module.sass'

const EmailModal = ({
  open = false,
  title = '',
  defaultMessage = '',
  onConfirm = () => {},
  onCancel = () => {},
  invite = false
}) => {
  const { t } = useTranslation()

  const altButtonTheme = {
    marginBottom: '0',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '1.43',
    paddingTop: '8px',
    paddingBottom: '8px',
    height: '36px'
  }

  const validationSchema = Yup.object({
    toEmail: Yup.string()
      .trim()
      .required(t('shareToEmailRequired'))
      .email(t('feedbackValidationInvalidEmail'))
  })

  return (
    <Formik
      enableReinitialize
      initialValues={{
        toEmail: '',
        message: defaultMessage
      }}
      validationSchema={validationSchema}
      onSubmit={onConfirm}
    >
      {formProps => (
        <Form>
          <Modal
            open={open}
            actionCaption={t('inviteFormSubmit')}
            onCancel={onCancel}
            onConfirm={() => {
              formProps.submitForm()
            }}
            altButtonTheme={altButtonTheme}
          >
            <div className={styles.emailModal}>
              <Typography type="display2" className={styles.title}>
                {title}
              </Typography>
              <TextInput
                name="toEmail"
                label={t('inviteFormToEmail')}
                placeholder="DrPatel-Schwartz@gmail.com"
                traditional
                type="text"
              />
              <TextArea
                name="message"
                label={t('inviteFormMessage')}
                traditional
                minHeight={invite ? 146 : 96}
              />
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  )
}

EmailModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  defaultMessage: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  invite: PropTypes.bool
}

export default EmailModal
