import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Card from 'components/Admin/Card/Card'
import PageContent from 'components/Admin/Page/PageContent'
import { Formik, Form } from 'formik'
import Button from 'components/Admin/Button'
import DataTable from 'react-data-table-component'
import Icon from 'components/ui/Icon'
import Roles from 'components/Admin/Roles'
import TextInput from 'components/TextInput'
import Typography from 'components/ui/Typography'

import DropdownInput from 'components/DropdownInput'
import { CircularProgress } from 'components/ui/Progress'
import { Drawer } from '@material-ui/core'
import ConfirmationModal from 'components/Admin/ConfirmationModal/ConfirmationModal'
import classNames from 'classnames/bind'
import { isEmpty } from 'utils'

import {
  getFormState,
  getRolesFetching,
  getDataFetching,
  getUsers,
  getSelected,
  getModalOpen,
  getInvalidateFetching,
  getPagination,
  getRolesAttributes,
  getSelectedUser,
  getUserFetching
} from './UsersSearchSelector'

import useRoleFeatures from 'modules/auth/useRoleFeatures'
import { actions as usersSearchActions } from './UsersSearchReducer'

import styles from './UsersSearch.module.sass'

const cx = classNames.bind(styles)

const UsersSearch = () => {
  const initialForm = {
    name: '',
    email: '',
    uuid: '',
    status: null,
    roles: [],
    page: 1,
    pageLastEmails: [''],
    perPage: 25
  }
  const statusOptions = {
    any: 'Any',
    active: 'Active',
    inactive: 'Inactive'
  }
  const routerHistory = useHistory()
  const dispatch = useDispatch()
  const { features } = useRoleFeatures({
    coin: 'admin-users'
  })
  const roleSchema = features?.sections?.actions || []
  const [firstVisit, setFirstVisit] = useState(true)
  const isRolesFetching = useSelector(getRolesFetching())
  const isFetching = useSelector(getDataFetching())
  const rolesAttributes = useSelector(getRolesAttributes())
  const formState = useSelector(getFormState())
  const data = useSelector(getUsers())
  const selected = useSelector(getSelected())
  const modalOpen = useSelector(getModalOpen())
  const pagination = useSelector(getPagination())
  const invalidateFetching = useSelector(getInvalidateFetching())
  const formStatePr = isEmpty(formState) ? initialForm : formState
  const [clearInput, setClearInput] = useState(false)
  const selectedUser = useSelector(getSelectedUser())
  const [drawerOpen, setDrawerOpen] = useState(false)
  const isUserFetching = useSelector(getUserFetching())
  const userCanEdit = roleSchema.includes('edit')

  //Important to convert from Epoch to Local Time
  const convertToDate = epochDate => {
    let date = new Date(epochDate)
    const dateString = date.toISOString().split('T')
    return `${dateString[0]} ${dateString[1].split('.')[0]}`
  }

  useEffect(() => {
    if (firstVisit && !isFetching) {
      dispatch(usersSearchActions.requestPossibleRoles())
      dispatch(
        usersSearchActions.searchRequest({
          search: {
            name: '',
            email: '',
            uuid: '',
            status: null,
            roles: [],
            page: 1,
            pageLastEmails: [''],
            perPage: 20,
            newSearch: true
          }
        })
      )
    }
    setFirstVisit(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleModalOpen = (id, email) => {
    dispatch(
      usersSearchActions.setSelected({
        id,
        email
      })
    )
    dispatch(usersSearchActions.setModalOpen({ modalOpen: true }))
  }

  const handleModalClose = () => {
    dispatch(
      usersSearchActions.setSelected({
        id: null,
        email: ''
      })
    )
    dispatch(usersSearchActions.setModalOpen({ modalOpen: false }))
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  const handleView = id => {
    if (id !== selectedUser.uuid) {
      dispatch(usersSearchActions.fetchUserRequest({ id }))
    }

    setDrawerOpen(true)
  }

  const handleClick = () => {
    routerHistory.push(`/admin/users/${selectedUser?.uuid}`)
  }

  const columns = [
    {
      name: 'UUID',
      selector: row => row.uuid,
      sortField: 'uuid',
      sortable: false,
      cell: row => {
        return <Typography>{row.uuid}</Typography>
      }
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortField: 'email',
      sortable: false,
      cell: row => {
        return <Typography>{row.email}</Typography>
      }
    },
    {
      name: 'First Name',
      selector: row => row.info.firstName,
      sortField: 'firstName',
      sortable: false,
      cell: row => {
        return <Typography>{row.info.firstName}</Typography>
      }
    },
    {
      name: 'Last Name',
      selector: row => row.info.lastName,
      sortField: 'lastName',
      sortable: false,
      cell: row => {
        return <Typography>{row.info.lastName}</Typography>
      }
    },
    {
      name: 'Roles',
      selector: row => row.roles,
      sortField: 'roles',
      sortable: false,
      cell: row => {
        return <Typography>{row.roles.join(', ')}</Typography>
      }
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortField: 'status',
      sortable: false,
      cell: row => {
        return <Typography>{row.status}</Typography>
      }
    },
    {
      name: 'Created',
      selector: row => row.created,
      sortField: 'created',
      sortable: false,
      cell: row => {
        return <Typography>{convertToDate(row.created)}</Typography>
      }
    },
    {
      name: 'Updated',
      selector: row => row.updated,
      sortField: 'updated',
      sortable: false,
      cell: row => {
        return <Typography>{convertToDate(row.updated)}</Typography>
      }
    },
    {
      name: 'View',
      sortable: false,
      maxWidth: '15px',
      omit: !roleSchema.includes('view') && !roleSchema.includes('edit'),
      cell: row => {
        return (
          <Icon
            icon="svg/custom/visibility"
            className={styles.tableIcon}
            onClick={() => handleView(row.uuid)}
            button
          />
        )
      }
    },
    {
      name: 'Deactivate',
      sortable: false,
      maxWidth: '15px',
      omit: !roleSchema.includes('invalidate'),
      cell: row => {
        return (
          <Icon
            icon="svg/material-design-icons/action/delete"
            className={styles.tableIcon}
            onClick={() => handleModalOpen(row.id, row.email)}
            button
          />
        )
      }
    }
  ]

  const handlePageChange = page => {
    let newPageLastEmails = formStatePr.pageLastEmails.slice(0, page)
    if (page > formStatePr.page) {
      newPageLastEmails = [...newPageLastEmails, data[data.length - 1].email]
    }
    dispatch(
      usersSearchActions.searchRequest({
        search: {
          ...formStatePr,
          pageLastEmails: newPageLastEmails,
          page,
          newSearch: false
        }
      })
    )
  }
  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(
      usersSearchActions.searchRequest({
        search: {
          ...formStatePr,
          page,
          perPage: newPerPage,
          newSearch: false
        }
      })
    )
  }
  const handleSort = ({ sortField: selector }, sortDirection) => {
    dispatch(
      usersSearchActions.searchRequest({
        search: {
          ...formStatePr,
          orderBy: selector,
          order: sortDirection,
          newSearch: false
        }
      })
    )
  }

  const handleRoleChange = (e, role, formProps) => {
    if (e.target.checked) {
      dispatch(
        usersSearchActions.setFormState({
          ...formProps.values,
          roles: formProps.values.roles.filter(r => r !== role)
        })
      )
    } else {
      dispatch(
        usersSearchActions.setFormState({
          ...formProps.values,
          roles: [...formProps.values.roles, role]
        })
      )
    }
  }

  const handleReset = () => {
    setClearInput(!clearInput)
    dispatch(usersSearchActions.setFormState({ ...initialForm }))
    handleSubmit({ ...initialForm })
  }

  const handleSubmit = values => {
    dispatch(
      usersSearchActions.searchRequest({
        search: { ...values, newSearch: true }
      })
    )
  }

  const handleDelete = () => {
    dispatch(
      usersSearchActions.invalidateUserRequest({
        email: selected.email
      })
    )
  }

  return (
    <PageContent title="Search Users">
      <Typography className={styles.description}>
        Here you can search for a user/multiple users
      </Typography>
      <Formik
        key={clearInput}
        initialValues={formStatePr}
        enableReinitialize
        onSubmit={values => {
          handleSubmit(values)
        }}
      >
        {formProps => (
          <Form autoComplete="off">
            <Card className={cx(styles.card, styles.searchCard)}>
              <Typography type="title" color="text-primary">
                Search Criteria
              </Typography>
              <Typography className={styles.note}>
                <span className={styles.bold}>**Name</span>,{' '}
                <span className={styles.bold}>Email</span> &{' '}
                <span className={styles.bold}>UUID</span> allows{' '}
                <span className={styles.bold}>case-insensitive</span>, but
                either in <span className={styles.underlined}>full</span> or{' '}
                <span className={styles.underlined}>begins with</span> lookup
                only.
              </Typography>
              <div className={styles.container}>
                <div className={styles.inputContainer}>
                  <TextInput
                    label="Name"
                    name="name"
                    type="text"
                    traditional={false}
                  />
                  <TextInput
                    label="Email"
                    name="email"
                    type="text"
                    traditional={false}
                  />
                  <TextInput
                    label="UUID"
                    name="uuid"
                    type="text"
                    traditional={false}
                  />
                </div>
                <div className={styles.statusDropdown}>
                  <DropdownInput
                    name="status"
                    label="Status"
                    options={statusOptions}
                    disabled={false}
                    traditional={false}
                  />
                </div>
                <Typography
                  className={styles.subtitle}
                  type="subheading"
                  color="text-primary"
                >
                  Roles:
                </Typography>
                {isRolesFetching ? (
                  <div className={styles.loadingComponent}>
                    <CircularProgress
                      fillColor="transparent"
                      size={64}
                      strokeWidth={4}
                    />
                  </div>
                ) : (
                  <Roles
                    handleRoleChange={handleRoleChange}
                    formProps={formProps}
                    rolesAttributes={rolesAttributes}
                  />
                )}
                <div className={styles.actionContainer}>
                  <Button
                    className={styles.resetButton}
                    text="Reset"
                    textCase="none"
                    disabled={false}
                    type="button"
                    backgroundColor="#d3d3d3"
                    onClick={() => handleReset()}
                  />
                  <Button
                    text="Search"
                    textCase="none"
                    disabled={false}
                    hasSpinner
                    type="submit"
                  />
                </div>
              </div>
            </Card>
            <Card className={styles.searchCard}>
              <Typography
                type="title"
                className={styles.cardTitle}
                color="text-primary"
              >
                Search Results
              </Typography>

              <DataTable
                columns={columns}
                data={data}
                keyField="key"
                progressPending={isFetching}
                pagination
                paginationServer
                paginationTotalRows={pagination.filtered}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                sortServer
                persistTableHead
                paginationPerPage={pagination.perPage}
                paginationRowsPerPageOptions={[10, 20, 25]}
                progressComponent={
                  <div className={styles.loadingComponent}>
                    <CircularProgress
                      fillColor="transparent"
                      size={64}
                      strokeWidth={4}
                    />
                  </div>
                }
              />
            </Card>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={handleDrawerClose}
            >
              <div className={styles.userContainer}>
                {isUserFetching ? (
                  <div className={styles.userProgress}>
                    <CircularProgress
                      fillColor="transparent"
                      size={64}
                      strokeWidth={4}
                    />
                  </div>
                ) : (
                  <div className={styles.userInfo}>
                    <Typography className={styles.userTitle}>User </Typography>
                    <Typography
                      type="title"
                      color="text-primary"
                      className={styles.userName}
                    >
                      {selectedUser?.firstName
                        ? selectedUser.firstName + selectedUser.lastName
                        : selectedUser.displayNames[0]}
                    </Typography>

                    <Typography className={styles.userDescription}>
                      Email:{' '}
                    </Typography>
                    <Typography className={styles.userData}>
                      {selectedUser?.email}
                    </Typography>

                    <Typography className={styles.userDescription}>
                      Role:{' '}
                    </Typography>
                    <Typography className={styles.userData}>
                      {selectedUser?.roles.join(', ')}
                    </Typography>

                    {selectedUser?.company && (
                      <>
                        <Typography className={styles.userDescription}>
                          Company:{' '}
                        </Typography>
                        <Typography className={styles.userData}>
                          {selectedUser?.company}
                        </Typography>
                      </>
                    )}

                    {selectedUser?.jobFunction && (
                      <>
                        <Typography className={styles.userDescription}>
                          Job Function:{' '}
                        </Typography>
                        <Typography className={styles.userData}>
                          {selectedUser?.jobFunction}
                        </Typography>
                      </>
                    )}

                    {selectedUser?.jobTitle && (
                      <>
                        <Typography className={styles.userDescription}>
                          Job Title:{' '}
                        </Typography>
                        <Typography className={styles.userData}>
                          {selectedUser?.jobTitle}
                        </Typography>
                      </>
                    )}

                    {selectedUser?.country && (
                      <>
                        <Typography className={styles.userDescription}>
                          Country:{' '}
                        </Typography>
                        <Typography className={styles.userData}>
                          {selectedUser?.country}
                        </Typography>
                      </>
                    )}

                    {selectedUser?.province && (
                      <>
                        <Typography className={styles.userDescription}>
                          Province:{' '}
                        </Typography>
                        <Typography className={styles.userData}>
                          {selectedUser?.province}
                        </Typography>
                      </>
                    )}

                    {selectedUser?.postalCode && (
                      <>
                        <Typography className={styles.userDescription}>
                          Postal Code:{' '}
                        </Typography>
                        <Typography className={styles.userData}>
                          {selectedUser?.postalCode}
                        </Typography>
                      </>
                    )}

                    {selectedUser?.city && (
                      <>
                        <Typography className={styles.userDescription}>
                          City:{' '}
                        </Typography>
                        <Typography className={styles.userData}>
                          {selectedUser?.city}
                        </Typography>
                      </>
                    )}

                    {selectedUser?.telephone && (
                      <>
                        <Typography className={styles.userDescription}>
                          Telephone:{' '}
                        </Typography>
                        <Typography className={styles.userData}>
                          {selectedUser?.telephone}
                        </Typography>
                      </>
                    )}

                    <Typography className={styles.userDescription}>
                      Created:{' '}
                    </Typography>
                    <Typography className={styles.userData}>
                      {convertToDate(selectedUser?.created)}
                    </Typography>

                    <Typography className={styles.userDescription}>
                      Updated:{' '}
                    </Typography>
                    <Typography className={styles.userData}>
                      {convertToDate(selectedUser?.updated)}
                    </Typography>

                    {userCanEdit ? (
                      <Button
                        className={styles.userButton}
                        onClick={handleClick}
                      >
                        Edit User
                      </Button>
                    ) : null}
                  </div>
                )}
              </div>
            </Drawer>
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        message={`Are you sure you want to deactivate user with email: ${selected.email}`}
        open={modalOpen}
        onConfirm={handleDelete}
        onCancel={handleModalClose}
        actionType="delete"
        actionCaption="Deactivate"
        submitting={invalidateFetching}
      />
    </PageContent>
  )
}

export default UsersSearch
