import { createReducer, createAction } from '@reduxjs/toolkit'

export const requestPrompts = createAction('POPULAR_PROMPTS_REQUEST')
export const requestSuccess = createAction('POPULAR_PROMPTS_SUCCESS')
export const requestFail = createAction('POPULAR_PROMPTS_FAIL')

export const actions = {
  requestPrompts,
  requestSuccess,
  requestFail
}

const popularPromptsReducer = createReducer(
  {
    fetching: true,
    prompts: []
  },
  {
    [requestPrompts]: state => {
      state.fetching = true
    },
    [requestSuccess]: (state, { payload: { prompts } }) => {
      state.fetching = false
      state.prompts = prompts
    },
    [requestFail]: state => {
      state.fetching = false
      state.prompts = []
    }
  }
)

export default popularPromptsReducer
