import axios from 'axios'

/**
 * Contacts advisor schedule endpoint
 */
class AdvisorScheduleService {
  /**
   * Execute query
   * @param endpoint
   * @param query
   * @param token
   * @returns {Promise<any>}
   */
  async schedule(endpoint, query, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'POST',
      data: JSON.stringify(query),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const response = await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
    return response.data
  }
}

export default AdvisorScheduleService
