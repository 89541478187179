import axios from 'axios'
import { addSiteIdToPayload, isEmpty } from 'utils'
import { authEndpoints } from '../../constants'

const { contactUsEndpoint, feedbackEndpoint } = authEndpoints

const UserTransactionService = (
  UserTransactionML,
  UserTransactionParser,
  getState
) => ({
  /**
   * @param endpoint
   * @param payload
   * @param token - access token
   */
  async login(endpoint, payload, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'POST',
      data: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    return await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
  },
  /**
   * @param endpoint
   * @param payload
   * @param token - access token
   */
  async loginWithEmail(endpoint, payload, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
    return await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
  },
  /**
   * Invalidates Auth Token
   *
   * @param endpoint
   * @param token - access token
   */
  async logout(endpoint, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    return await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
  },
  /**
   * @param endpoint
   */
  async getAnonToken(endpoint) {
    let axiosOptions = {
      url: endpoint,
      method: 'GET'
    }
    const response = await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
    return response.data
  },
  /**
   * @param endpoint
   * @param token - access token
   */
  async getProfile(endpoint, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
    return await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
  },
  /**
   *
   * @param endpoint
   * @param token - access token
   * @param payload
   *
   * @returns {Promise<any>}
   */
  async updateProfile(endpoint, payload, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'POST',
      data: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    return await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
  },
  /**
   * @param endpoint
   * @param payload
   * {
   *  "idhSiteId": 123,
   *  "idhBrandId": 123,
   *  "idhCampaignId": 123
   * }
   * @param token - access token
   * @returns {Promise<boolean>}
   */
  async unsubscribe(endpoint, payload, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'POST',
      data: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    return await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
  },
  /**
   * @param payload
   */
  async sendFeedback({ payload }) {
    const isAuth = getState().authReducer.isAuth
    const isLimited = getState().authReducer.isLimited

    // Get correct endpoint based on user role
    const endpoint = isAuth
      ? isLimited
        ? contactUsEndpoint
        : feedbackEndpoint
      : contactUsEndpoint

    // Call endpoint with desired payload
    const response = await UserTransactionML.fetchWithStandardEmail(
      endpoint,
      'POST',
      UserTransactionParser.sendFeedbackParser(payload, endpoint), // Parse payload
      {
        lastAction: UserTransactionParser.lastAction()
      }
    )
    return response.data
  },
  /**
   * Invite your colleague request
   * @param {*} data
   * @returns
   */
  async inviteSubmit(data) {
    const response = await UserTransactionML.fetchWithStandardEmail(
      authEndpoints.sendEmailEndpoint,
      'POST',
      UserTransactionParser.inviteSubmitParser(data),
      {
        articleId: undefined // Remove articleId
      }
    )

    if (!isEmpty(response)) {
      return response
    }
    return {}
  },
  /**
   * @param endpoint
   * @param payload
   * @param token - access token
   */
  async sendRegistration({ payload }) {
    const response = await UserTransactionML.fetchWithStandardEmail(
      authEndpoints.registrationEndpoint,
      'POST',
      UserTransactionParser.registrationSubmitParser(payload),
      {
        articleId: undefined // Remove articleId
      }
    )

    if (!isEmpty(response)) {
      return response.data
    }
    return {}
  },
  /**
   *
   * @param endpoint
   * {
   *      "idhEmail": "some.email@somedomain.com",
   *      "idhProfileId": 123456789,
   *      "idhBrandId": 123
   *  }
   * @param token - access token
   * @param payload
   * @returns {Promise<any>}
   */
  async resetPassword(endpoint, payload, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'POST',
      data: addSiteIdToPayload(payload),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
    return await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
  }
})

export default UserTransactionService
