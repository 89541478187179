import PageContent from 'components/Admin/Page/PageContent'
import Typography from 'components/ui/Typography'
import SearchForm from './forms/Search'
import Actions from './forms/Actions'
import styles from './IncomingCounts.module.sass'
import useRoleFeatures from 'modules/auth/useRoleFeatures'
const IncomingCounts = () => {
  const { features } = useRoleFeatures({
    coin: 'charts-configuration'
  })
  const roleSchema = features?.sections?.actions || []
  const userCanEdit = roleSchema.includes('save')
  return (
    <PageContent title="Incoming Counts">
      <Typography className={styles.description}>
        An Incoming counts is value from data source.
        <br />
        Data will be pulled from RB19 for faster local lookup.
        <br />
        <br />
        This will be use to <b>pre-filter</b> Market Selection{' '}
        <code className={styles.code}>combination</code> working with Data Cache
        module.
        <br />
        <br />
        On Schedule to refresh daily at 3am (server time)
      </Typography>
      {userCanEdit && <Actions />}
      <SearchForm />
    </PageContent>
  )
}

export default IncomingCounts
