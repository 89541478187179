import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Header from 'components/ui/Header'
import Menu from 'components/Menu'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'utils'
import styles from './PageHeader.module.sass'
import classNames from 'classnames/bind'
import { actions as trackActions } from 'modules/tracking/TrackingReducer'
import * as authSelectors from 'modules/auth/AuthSelector'
import siteConfig from 'site.config.json'

const { logo, bureauLogo } = siteConfig

const cx = classNames.bind(styles)

const { getIsAuth } = authSelectors

const PageHeader = ({
  showBackIcon,
  showMenu = true,
  backIconLink,
  isLimaBureau = false,
  menuLinks = [],
  onBackClick,
  showHeaderBottomBorder = true,
  ...rest
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isAuth = useSelector(getIsAuth())

  const logoLink = '/'

  const onClickBackIcon = () => {
    dispatch(
      trackActions.trackCustomAction({
        title: `${
          isAuth ? 'Authenticated' : 'Anonymous'
        } - header back-button click`
      })
    )
    if (onBackClick) {
      onBackClick()
    } else if (!isEmpty(backIconLink)) {
      // Reverting back to push since the replace already happens on the AuthSaga
      // and we need to track the push action
      history.push({ pathname: backIconLink })
    } else {
      // Reverting back to push since the replace already happens on the AuthSaga
      // and we need to track the push action
      history.push(logoLink)
    }
  }

  const handleLogoClick = () => {
    dispatch(
      trackActions.trackCustomAction({
        title: `${isAuth ? 'Authenticated' : 'Anonymous'} - Header logo link`
      })
    )
    // Reverting back to push since the replace already happens on the AuthSaga
    // and we need to track the push action
    history.push(logoLink)
  }

  const [menuOpen, setMenuOpen] = useState(false)
  const handleMenuAction = event => {
    dispatch(
      trackActions.trackCustomAction({
        title: `${
          isAuth ? 'Authenticated' : 'Anonymous'
        } - Header menu-button click`
      })
    )
    event.preventDefault()
    setMenuOpen(true)
  }

  return (
    <>
      <Header
        className={cx(styles.header)}
        iconColor={'primary'}
        actionRight={handleMenuAction}
        actionLeft={onClickBackIcon}
        logoSrc={isLimaBureau ? bureauLogo.source : logo.source}
        iconLeft={showBackIcon ? 'svg/custom/arrow-back' : ''}
        iconRight={showMenu ? 'svg/material-design-icons/navigation/menu' : ''}
        borderBottom={showHeaderBottomBorder ? '1px solid #e0e0e0' : ''}
        logoOnClick={handleLogoClick}
        {...rest}
      />
      <Menu
        menuItems={menuLinks}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
    </>
  )
}
PageHeader.propTypes = {
  /**
   * Icon used for back button
   */
  backIconLink: PropTypes.string,
  /**
   * If true back button icon will appear
   */
  showBackIcon: PropTypes.bool,
  /**
   * If true it will change header logo and class
   */
  isLimaBureau: PropTypes.bool,
  /**
   * List of links to show in side menu
   */
  menuLinks: PropTypes.array,
  /**
   * Action to replace default back behavior
   */
  onBackClick: PropTypes.func,
  /**
   * If false menu icon will disappear
   */
  showMenu: PropTypes.bool,
  /**
   * If false the header bottom border won't appear
   */
  showHeaderBottomBorder: PropTypes.bool
}

export default PageHeader
