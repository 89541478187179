import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Dropdown from 'components/DropdownInput'
import TextInput from 'components/TextInput'
import { isEmpty } from 'utils'
import * as listIngestionSelectors from '../ListIngestionSelector'
import styles from './form.module.sass'

const { getOptions } = listIngestionSelectors

const MappingForm = ({ formProps, name, label }) => {
  const { values, setFieldValue } = formProps
  const columnHeadersOptions = useSelector(getOptions(values.activeStep))
  const { parsedColumnHeadersOptions = {} } = columnHeadersOptions
  const createInputsConfig = () => {
    let inputsConfig = []

    for (let index = 0; index < 10; index++) {
      inputsConfig.push({
        type: 'dropdown',
        label: `${label} ${index + 1}`,
        name: `${name}${index + 1}`,
        options: parsedColumnHeadersOptions,
        onChange: key =>
          handleDropdownChange(
            key,
            `${name}Label${index + 1}`,
            parsedColumnHeadersOptions
          )
      })
      inputsConfig.push({
        type: 'text',
        label: `${label} Label ${index + 1}`,
        name: `${name}Label${index + 1}`,
        disabled:
          isEmpty(values[`${name}${index + 1}`]) ||
          values[`${name}${index + 1}`] === '0',
        required:
          !isEmpty(values[`${name}${index + 1}`]) &&
          values[`${name}${index + 1}`] !== '0'
      })
    }

    return inputsConfig
  }

  const inputsConfig = createInputsConfig()
  const {
    fullname,
    firstname,
    middlename,
    lastname,
    profession,
    specialty,
    nationalId1,
    nationalIdType1,
    nationalId2,
    nationalIdType2,
    partyId,
    hcpId,
    addressLine1,
    addressLine2,
    addressLine3,
    phonenumber,
    city,
    state,
    postalcode,
    country,

    thirdPartyID1,
    thirdPartyID2,
    thirdPartyID3,
    thirdPartyID4,
    thirdPartyID5,
    thirdPartyID6,
    thirdPartyID7,
    thirdPartyID8,
    thirdPartyID9,
    thirdPartyID10,

    priorEngagementID1,
    priorEngagementID2,
    priorEngagementID3,
    priorEngagementID4,
    priorEngagementID5,
    priorEngagementID6,
    priorEngagementID7,
    priorEngagementID8,
    priorEngagementID9,
    priorEngagementID10,

    value1,
    value2,
    value3,
    value4,
    value5,
    value6,
    value7,
    value8,
    value9,
    value10,

    segment1,
    segment2,
    segment3,
    segment4,
    segment5,
    segment6,
    segment7,
    segment8,
    segment9,
    segment10
  } = formProps.values

  const handleDropdownChange = (key, labelName, options) => {
    if (key && key !== '0') {
      // Set label input with dropdown selection
      const selectedLabel = options[key]
      setFieldValue(labelName, selectedLabel)
    } else {
      setFieldValue(labelName, '')
    }
  }

  return (
    <div className={styles.gridForm} data-testid="mappingFormContainer">
      {inputsConfig.map(input => {
        switch (input.type) {
          case 'dropdown':
            return (
              <Dropdown
                key={input.name}
                label={input.label}
                name={input.name}
                placeholder="Select Option"
                required={input.required || false}
                traditional
                options={input.options}
                disabled={input.disabled || false}
                onChange={input.onChange}
                uniqueFields={{
                  fullname,
                  firstname,
                  middlename,
                  lastname,
                  profession,
                  specialty,
                  nationalId1,
                  nationalIdType1,
                  nationalId2,
                  nationalIdType2,
                  partyId,
                  hcpId,
                  addressLine1,
                  addressLine2,
                  addressLine3,
                  phonenumber,
                  city,
                  state,
                  postalcode,
                  country,

                  thirdPartyID1,
                  thirdPartyID2,
                  thirdPartyID3,
                  thirdPartyID4,
                  thirdPartyID5,
                  thirdPartyID6,
                  thirdPartyID7,
                  thirdPartyID8,
                  thirdPartyID9,
                  thirdPartyID10,

                  priorEngagementID1,
                  priorEngagementID2,
                  priorEngagementID3,
                  priorEngagementID4,
                  priorEngagementID5,
                  priorEngagementID6,
                  priorEngagementID7,
                  priorEngagementID8,
                  priorEngagementID9,
                  priorEngagementID10,

                  value1,
                  value2,
                  value3,
                  value4,
                  value5,
                  value6,
                  value7,
                  value8,
                  value9,
                  value10,

                  segment1,
                  segment2,
                  segment3,
                  segment4,
                  segment5,
                  segment6,
                  segment7,
                  segment8,
                  segment9,
                  segment10
                }}
              />
            )
          case 'text':
          default:
            return (
              <TextInput
                key={input.name}
                label={input.label}
                name={input.name}
                required={input.required || false}
                traditional
                type="text"
                disabled={input.disabled || false}
              />
            )
        }
      })}
    </div>
  )
}

MappingForm.propTypes = {
  /**
   * Form props
   */
  formProps: PropTypes.object,
  /**
   * Name to be used
   */
  name: PropTypes.string,
  /**
   * Label to be used
   */
  label: PropTypes.string
}

export default MappingForm
