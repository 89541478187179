import { useDispatch, useSelector } from 'react-redux'
import styles from './form.module.sass'
import Button from 'components/Admin/Button'
import { downloadRequest } from '../UsersBulkReducer'
import * as usersBulkSelector from '../UsersBulkSelector'
const { getIsFetching, getRequestType } = usersBulkSelector

const Actions = () => {
  const dispatch = useDispatch()
  const isFormFetching = useSelector(getIsFetching())
  const requestType = useSelector(getRequestType())
  const handleDownload = () => {
    dispatch(downloadRequest())
  }
  return (
    <div data-testid="adminCreateBulkUsers" className={styles.actionContainer}>
      <Button
        data-testid="adminCreateBulkUsers-download"
        text="Download All"
        textCase="none"
        disabled={isFormFetching}
        type="button"
        onClick={handleDownload}
        submitting={isFormFetching && requestType === 'download'}
        hasSpinner
      />
    </div>
  )
}

export default Actions
