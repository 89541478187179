import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'modules/dougall/themeProvider'
import { getCurrentYear, getRoleBasedLinks, parseJwt } from 'utils'
import cx from 'classnames'

import CookieService from 'services/CookieService'
import SessionService from 'services/SessionService'

import Page from 'components/Page'
import ChatPage from 'modules/dougall/chatPage'
import LeftBar from './sections/LeftBar'
import RightBar from './sections/RightBar'
import FeedbackButton from './sections/FeedbackButton'
import Chat from 'modules/dougall/chat/Chat'
import MobileButtonBar from './sections/MobileButtonBar'
import Footer from 'components/Dougall/ui/Footer'

import useAuth from 'modules/auth/useAuth'

import { getLanguageCode } from 'modules/dougall/translation/TranslationSelector'
import { actions as chatActions } from 'modules/dougall/chat/ChatReducer'
// import { actions as popularPromptsActions } from 'modules/dougall/popularPrompts/PopularPromptsReducer'
import { actions as historyActions } from 'modules/dougall/history/HistoryReducer'
import { actions as faqActions } from 'modules/dougall/faq/FaqReducer'
import useMediaSize from 'hooks/useMediaSize'
import siteConfig from 'site.config'
import styles from './Home.module.sass'

const {
  sections: { footer },
  configuration: { gpt }
} = siteConfig

const Home = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const { isLimited, isAuth } = useAuth()
  const { active } = useTheme()
  const lang = useSelector(getLanguageCode())
  const footerLinks = getRoleBasedLinks(isAuth, isLimited, footer)
  const footerText = null // [t('footerText1'), t('footerText2'), t('footerText3')]
  const translatedLinks = footerLinks.map(element => {
    return { ...element, title: t(element?.title) }
  })
  // const emailId = CookieService().getFromCache('EmailID', '')
  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 800px)'
    }
  })

  const { state = {} } = history.location
  // Default: Popular Prompts expanded (1)
  const { listExpanded = 1, empty = true } = state

  const access = CookieService().getFromCache('access')
  const { roleId } = parseJwt(access)

  useEffect(() => {
    const gptToken = new SessionService().getFromCache('gpt')
    if (gptToken?.access) {
      // Refresh GPT token
      const currentTimestamp = Math.round(Date.now() / 1000)
      const { iat, exp } =
        process.env.NODE_ENV === 'development'
          ? {
              iat: currentTimestamp,
              exp: currentTimestamp + 30 * 24 * 3600
            }
          : parseJwt(gptToken.access)
      const forceExpiryTimestamp =
        iat + (gpt?.forceTokenExpiryMinute || 60) * 60
      if (currentTimestamp > exp || currentTimestamp > forceExpiryTimestamp)
        dispatch(chatActions.gptToken())
      // New GPT token
    } else if (roleId.includes('DOUGALL_GPT')) dispatch(chatActions.gptToken())

    // dispatch(popularPromptsActions.requestPrompts(lang))
    dispatch(historyActions.requestHistory())
    dispatch(faqActions.requestFaq(lang))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const refreshGptToken = setInterval(() => {
      dispatch(chatActions.gptToken())
    }, (gpt?.forceTokenExpiryMinute || 60) * 60 * 1000)

    return () => clearInterval(refreshGptToken)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFeedbackButtonClick = () => {
    history.push('/feedback', { from: history.location.pathname })
    // trackClickAction({
    //   title: 'Site/Click/Home/feedback',
    //   clickType: 'home-feedback'
    // })
  }

  // const handleFooterClick = t => {
  //   if (t) {
  //     trackClickAction({
  //       title: `${t}`,
  //       clickType: 'footer-link-click'
  //     })
  //   }
  // }

  const footerComponent = (
    <Footer
      copyright={`© ${getCurrentYear()} ${t('footerCopyRightText')}`}
      text={footerText}
      links={translatedLinks}
      hasDivider={false}
      linkComponent={Link}
      activeTheme={active}
      // handleFooterClick={handleFooterClick}
    />
  )

  return (
    <Page
      contentClassName={styles.page}
      showHero={false}
      showGoBack={true}
      showFooter={false}
      // leftContent={<LeftBar listExpanded={listExpanded} />}
      // footer={mediaSize === 'small' ? <MobileButtonBar /> : footerComponent}
      // style={{ maxWidth: 'unset' }}
    >
      <div className={cx(styles.chatContainer, styles.fullHeight)}>
        <ChatPage
          contentClassName={styles.page}
          showHero={false}
          showGoBack={false}
          showFooter={true}
          leftContent={<LeftBar listExpanded={listExpanded} />}
          rightContent={<RightBar />}
          footer={mediaSize === 'small' ? <MobileButtonBar /> : footerComponent}
        >
          <FeedbackButton onClick={handleFeedbackButtonClick} />
          <Chat empty={empty} />
        </ChatPage>
      </div>
    </Page>
  )
}

export default Home
