import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styles from './form.module.sass'
import Button from 'components/Button'
import * as compareListsSelectors from '../CompareListsSelector'
import Typeahead from 'modules/typeahead'
import { smartsEndpoints } from 'constants/apiConstants'

const { getFetchStatus } = compareListsSelectors

const ListsSelection = ({ formProps }) => {
  const isFetching = useSelector(getFetchStatus())

  return (
    <>
      <div className={styles.listSelectionContainer}>
        <Typeahead
          formName="compare-list"
          label="Choose a Target List"
          name="targetList"
          type="text"
          className={styles.input}
          required
          traditional
          body={{
            endpoint: smartsEndpoints.listsCombinationsFetchListsEndpoint,
            parseFunction: 'optionsParser',
            data: {
              filters: {
                workflow: 'CompareLists',
                selectedLists: [formProps.values.surveyParticipantList]
              },
              search: {
                perPage: 100
              }
            }
          }}
          // disabled={disabled}
        />
        <Typeahead
          formName="compare-list"
          label="Choose a Survey Participant List"
          name="surveyParticipantList"
          type="text"
          className={styles.input}
          required
          traditional
          body={{
            endpoint: smartsEndpoints.listsCombinationsFetchListsEndpoint,
            parseFunction: 'optionsParser',
            data: {
              filters: {
                workflow: 'CompareLists',
                selectedLists: [formProps.values.targetList]
              },
              search: {
                perPage: 100
              }
            }
          }}
          // disabled={disabled}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          className={styles.button}
          type="submit"
          submitting={isFetching}
          disabled={isFetching}
          hasSpinner
          isLimaSubmit
        />
      </div>
    </>
  )
}

ListsSelection.propTypes = {
  /**
   * Form props
   */
  formProps: PropTypes.object
}

export default ListsSelection
