import { all, call, put, fork, take, cancel } from 'redux-saga/effects'
import { actions as typeaheadActions } from './TypeaheadReducer'

/**
 * Request submit form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* typeaheadRequest(services, { payload }) {
  const { name, endpoint, data, parseFunction } = payload
  try {
    const TypeaheadService = services('TypeaheadService')
    const TypeaheadParser = services('TypeaheadParser')
    // Call endpoint
    const { data: options, pagination } = yield call(
      [TypeaheadService, 'fetch'],
      endpoint,
      data
    )
    // Parse options
    const parsedOptions = yield call(
      [TypeaheadParser, parseFunction || 'optionsParser'],
      options
    )
    // Parse options
    const parsedTooltips = yield call(
      [TypeaheadParser, 'tooltipParser'],
      options
    )
    // Update
    yield put(
      typeaheadActions.typeaheadRequestSuccess({
        name,
        options: parsedOptions,
        tooltips: parsedTooltips,
        pagination
      })
    )
  } catch (e) {
    yield put(typeaheadActions.typeaheadRequestFail({ name }))
    console.error(e)
  }
}

// Helper function to only cancel sagas for same typeahead
// Fixes infinite loading on multiple calls
const takeLatestByName = (patternOrChannel, saga, ...args) =>
  fork(function* () {
    // hold a reference to each forked saga identified by the name property
    let lastTasks = {}

    while (true) {
      const action = yield take(patternOrChannel)
      const {
        payload: { name }
      } = action

      // if there is a forked saga running with the same type, cancel it.
      if (lastTasks[name]) {
        yield cancel(lastTasks[name])
      }

      lastTasks[name] = yield fork(saga, ...args.concat(action))
    }
  })

export default function* watchTypeaheadRequest(services) {
  yield all([takeLatestByName('TYPEAHEAD_REQUEST', typeaheadRequest, services)])
}
