import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as exploreProjectActions } from './ExploreProjectReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as trackActions } from 'modules/tracking/TrackingReducer'
import { isEmpty } from 'utils'
import { initialValues } from './config'
import { LOCATION_CHANGE } from 'connected-react-router'

/**
 * Request submit form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitFormRequest(services, { payload }) {
  const { activeStep } = payload
  try {
    yield put(trackActions.trackSubmitAction({}))
    switch (activeStep) {
      case 'project':
        yield call(submitProjectRequest, services, payload)
        break
      case 'list-selection':
        yield call(submitListsRequest, services, payload)
        break
      case 'filters':
        yield call(submitFiltersRequest, services, payload)
        break
      default:
        break
    }
  } catch (e) {
    yield put(exploreProjectActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request submit project form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitProjectRequest(services, payload) {
  try {
    // Save Form state & redirect user to next step
    yield put(
      exploreProjectActions.setFormState({
        ...payload,
        activeStep: 'list-selection'
      })
    )
    yield put(exploreProjectActions.submitFormRequestSuccess())
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(exploreProjectActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request submit lists ids form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitListsRequest(services, payload) {
  try {
    // Save Form state & redirect user to next step
    yield put(
      exploreProjectActions.setFormState({
        ...payload,
        activeStep: 'filters'
      })
    )
    yield put(exploreProjectActions.submitFormRequestSuccess())
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(exploreProjectActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request submit filters form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitFiltersRequest(services, payload) {
  try {
    const SmartsService = services('SmartsService')
    const {
      projects,
      masterProjects,
      startDate,
      endDate,
      engagementType,
      ids,
      combinationsCountry,
      combinationsProfession,
      combinationsSpecialty,
      combinations,
      allMembersSelected,
      filters
    } = payload

    // Parse projects & master projects
    const parsedProjects = projects.map(p => p.key)
    const parsedMasterProjects = masterProjects.map(m => m.key)

    // Build Combinations data
    let combinationsData = []
    if (!allMembersSelected) {
      if (
        combinationsCountry ||
        combinationsProfession ||
        combinationsSpecialty
      ) {
        combinationsData = [
          ...combinations,
          {
            id: combinations.length + 1,
            country: combinationsCountry,
            profession: combinationsProfession,
            specialty: combinationsSpecialty
          }
        ]
      } else {
        combinationsData = [...combinations]
      }
    }

    // Create List
    const { id: uuid } = yield call([SmartsService, 'listsCreate'], {
      workflow: 'ExploreProject',
      parameters: {
        ExploreProject: {
          projects: !isEmpty(parsedProjects) ? parsedProjects : null,
          masterProjects: !isEmpty(parsedMasterProjects)
            ? parsedMasterProjects
            : null,
          startDate,
          endDate,
          engagementType,
          lists: ids
        }
      },
      combinations: combinationsData,
      filters
    })
    // Save Form state & redirect user to next step
    yield put(
      exploreProjectActions.setFormState({
        ...payload,
        combinationsCountry: '',
        combinationsProfession: '',
        combinationsSpecialty: '',
        activeStep: 'project-metadata',
        uuid
      })
    )
    yield put(exploreProjectActions.submitFormRequestSuccess())
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(exploreProjectActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request project metadata form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitProjectMetadataRequest(services, payload) {
  const SmartsService = services('SmartsService')

  try {
    const {
      listId: uuid,
      domain,
      clientProduct,
      brand,
      client,
      masterProject,
      project,
      notes,
      defaultCountry,
      type: action,
      reportName
    } = payload

    let projectMetadataPayload = {
      uuid,
      domain,
      clientProduct,
      brand: brand === '' ? [] : [brand],
      client,
      masterProject,
      project,
      notes,
      defaultCountry,
      tags: '',
      action,
      reportName
    }

    // Set specific payload values when domain is 'FW'
    if (domain === 'FW') {
      projectMetadataPayload = {
        ...projectMetadataPayload,
        client: null,
        project: null,
        masterProject: -1,
        clientProduct: null
      }
    }

    // Call endpoint
    yield call([SmartsService, 'setProjectMetadata'], projectMetadataPayload)
  } catch (e) {
    console.error(e)
  }
}

/**
 * Request list creation, validation & submit
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitExploreProjectRequest(services, payload) {
  const SmartsService = services('SmartsService')
  const { uuid, type } = payload

  // Validate List
  yield call([SmartsService, 'validateList'], { uuid })
  // Submit List
  const { message } = yield call([SmartsService, 'submitList'], { uuid, type })
  yield put(
    notificationActions.notificationEnqueue({
      message,
      duration: 0
    })
  )
}

/**
 * Request run analysis
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* completeExploreProjectRequest(services, { payload }) {
  try {
    yield put(trackActions.trackSubmitAction({}))

    // Submit Project Metadata
    yield call(submitProjectMetadataRequest, services, payload)

    // Submit Explore Project
    yield call(submitExploreProjectRequest, services, payload)

    yield put(exploreProjectActions.submitFormRequestSuccess())
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(exploreProjectActions.submitFormRequestFail())
    console.error(e)
  }
}

function* resetForm(services, { payload }) {
  const { action } = payload

  if (action === 'REPLACE') {
    yield put(exploreProjectActions.setFormState({ ...initialValues }))
  }
}

export default function* watchExploreProjectRequest(services) {
  yield all([
    takeEvery(
      'EXPLORE_PROJECT_SUBMIT_FORM_REQUEST',
      submitFormRequest,
      services
    ),
    takeEvery(
      'EXPLORE_PROJECT_COMPLETE_REQUEST',
      completeExploreProjectRequest,
      services
    ),
    takeEvery(LOCATION_CHANGE, resetForm, services)
  ])
}
