import axios from 'axios'
import { isEmpty } from 'utils'
import SmartsMLError from './SmartsMLError'
import { authEndpoints } from '../constants'

const UserTransactionService = (UserTransactionML, UserTransactionParser) => ({
  /**
   * @param endpoint
   * @param payload
   * @param token - access token
   */
  async login(endpoint, payload, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'POST',
      data: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    return await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
  },
  /**
   * @param endpoint
   * @param payload
   * @param token - access token
   */
  async loginWithEmail(endpoint, payload, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
    return await axios(axiosOptions).catch(error => {
      if (
        error.response?.data?.message &&
        error.response.data.status !== 'OK'
      ) {
        const { data: responseError } = error.response
        const err = new SmartsMLError(responseError.message, responseError)
        throw err
      }
      throw error
    })
  },
  /**
   * Invalidates Auth Token
   *
   * @param endpoint
   * @param token - access token
   */
  async logout(endpoint, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    return await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
  },
  /**
   * @param endpoint
   */
  async getAnonToken(endpoint) {
    let axiosOptions = {
      url: endpoint,
      method: 'GET'
    }
    const response = await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
    return response.data
  },
  /**
   * @param endpoint
   * @param token - access token
   */
  async getProfile(endpoint, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
    return await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
  },
  /**
   *
   * @param endpoint
   * @param token - access token
   * @param payload
   *
   * @returns {Promise<any>}
   */
  async updateProfile(endpoint, payload, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'POST',
      data: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    return await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
  },
  /**
   *
   * @param endpoint
   * @param token - access token
   * @param payload
   * @returns {Promise<any>}
   */
  async validatePassword(endpoint, payload, token) {
    return true
  },
  /**
   * @param endpoint
   * @param payload
   * {
   *  "idhSiteId": 123,
   *  "idhBrandId": 123,
   *  "idhCampaignId": 123
   * }
   * @param token - access token
   * @returns {Promise<boolean>}
   */
  async unsubscribe(endpoint, payload, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'POST',
      data: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    return await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
  },
  /**
   * @param endpoint
   * @param payload
   * @param token - access token
   */
  async sendFeedback(endpoint, payload, token) {
    let processFeedbackPayload = payload => ({
      ...payload,
      device: navigator.userAgent
    })

    let axiosOptions = {
      url: endpoint,
      method: 'POST',
      data: JSON.stringify(processFeedbackPayload(payload)),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const response = await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
    return response.data
  },

  /**
   *
   * @param endpoint
   * {
   *      "idhEmail": "some.email@somedomain.com",
   *      "idhProfileId": 123456789,
   *      "idhBrandId": 123
   *  }
   * @param token - access token
   * @param payload
   * @returns {Promise<any>}
   */
  async resetPassword(endpoint, payload, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'POST',
      data: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
    return await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
  },
  /**
   * Invite your colleague request
   * @param {*} data
   * @returns
   */
  async inviteSubmit(data) {
    const response = await UserTransactionML.fetchWithStandardEmail(
      authEndpoints.sendEmailEndpoint,
      'POST',
      UserTransactionParser.inviteSubmitParser(data),
      {
        articleId: undefined // Remove articleId
      }
    )

    if (!isEmpty(response)) {
      return response
    }
    return {}
  }
})

export default UserTransactionService
