import { createReducer, createAction } from '@reduxjs/toolkit'
import { initialValues } from './config'

export const setFormState = createAction('EXPORT_LIST_SET_FORM_STATE')

export const submitFormRequest = createAction('EXPORT_LIST_SUBMIT_FORM_REQUEST')
export const submitFormRequestSuccess = createAction(
  'EXPORT_LIST_SUBMIT_FORM_REQUEST_SUCCESS'
)
export const submitFormRequestFail = createAction(
  'EXPORT_LIST_SUBMIT_FORM_REQUEST_FAIL'
)
export const submitCompleteRequest = createAction(
  'EXPORT_LIST_COMPLETE_REQUEST'
)

export const getBrands = createAction('EXPORT_LIST_GET_BRANDS')

export const getBrandsRequest = createAction('EXPORT_LIST_GET_BRANDS_REQUEST')
export const getBrandsRequestSuccess = createAction(
  'EXPORT_LIST_GET_BRANDS_REQUEST_SUCCESS'
)
export const getBrandsRequestFail = createAction(
  'EXPORT_LIST_GET_BRANDS_REQUEST_FAIL'
)

export const uploadFileRequest = createAction('EXPORT_LIST_UPLOAD_FILE_REQUEST')
export const uploadFileRequestSuccess = createAction(
  'EXPORT_LIST_UPLOAD_FILE_REQUEST_SUCCESS'
)
export const uploadFileRequestFail = createAction(
  'EXPORT_LIST_UPLOAD_FILE_FAIL'
)

export const actions = {
  setFormState,
  submitFormRequest,
  submitFormRequestSuccess,
  submitFormRequestFail,
  submitCompleteRequest,
  getBrands,
  getBrandsRequest,
  getBrandsRequestSuccess,
  getBrandsRequestFail,
  uploadFileRequest,
  uploadFileRequestSuccess,
  uploadFileRequestFail
}

const exportListReducer = createReducer(
  {
    formState: {
      ...initialValues
    },
    fetching: false,
    brandOptions: {},
    uploading: false
  },
  {
    [setFormState]: (state, { payload }) => {
      state.formState = { ...state.formState, ...payload }
    },
    [submitFormRequest]: state => {
      state.fetching = true
    },
    [submitCompleteRequest]: state => {
      state.fetching = true
    },
    [submitFormRequestSuccess]: state => {
      state.fetching = false
    },
    [submitFormRequestFail]: state => {
      state.fetching = false
    },
    [getBrands]: (state, { payload }) => {
      state.brandOptions = { ...state.brandOptions, ...payload }
    },
    [getBrandsRequest]: state => {
      state.fetching = true
    },
    [getBrandsRequestSuccess]: state => {
      state.fetching = false
    },
    [getBrandsRequestFail]: state => {
      state.fetching = false
    },
    [uploadFileRequest]: state => {
      state.uploading = true
    },
    [uploadFileRequestSuccess]: state => {
      state.uploading = false
      state.fileUploaded = true
    },
    [uploadFileRequestFail]: state => {
      state.uploading = false
    }
  }
)

export default exportListReducer
