import { createSelector } from '@reduxjs/toolkit'

export const universeHistorySelector = state => state.universeHistoryReducer

export const getFormState = () =>
  createSelector(
    universeHistorySelector,
    universeHistory => universeHistory.formState
  )

export const getFirstFetching = () =>
  createSelector(
    universeHistorySelector,
    universeHistory => universeHistory.isFirstFetching
  )

export const getFetching = () =>
  createSelector(
    universeHistorySelector,
    universeHistory => universeHistory.isFetching
  )

export const getHistory = () =>
  createSelector(
    universeHistorySelector,
    universeHistory => universeHistory.history
  )

export const getPagination = () =>
  createSelector(
    universeHistorySelector,
    universeHistory => universeHistory.pagination
  )

export const getSelected = () =>
  createSelector(
    universeHistorySelector,
    universeHistory => universeHistory.selected
  )

export const getFetchingSingle = () =>
  createSelector(
    universeHistorySelector,
    universeHistory => universeHistory.isFetchingSingle
  )

export const getSingleUniverse = () =>
  createSelector(
    universeHistorySelector,
    universeHistory => universeHistory.singleUniverse
  )

export const getBeforeFormState = () =>
  createSelector(
    universeHistorySelector,
    universeHistory => universeHistory.beforeFormState
  )

export const getAfterFormState = () =>
  createSelector(
    universeHistorySelector,
    universeHistory => universeHistory.afterFormState
  )
