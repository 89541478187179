import React, { useRef, useCallback, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  SubTitle,
  Legend,
  Tooltip
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import PropTypes from 'prop-types'
import styles from './BarChart.module.sass'
import PSLTooltip from 'components/Tooltip'
import { actions as chartsAction } from '../../modules/Charts/ChartsReducer'
import { useDispatch } from 'react-redux'
import { isNull } from 'lodash'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  SubTitle,
  Legend,
  Tooltip,
  ChartDataLabels
)

const PNG_FONT_SIZE = 20
const PNG_WIDTH = 1012

const BarChart = ({ chart, chartOnly, onShareResults }) => {
  const chartRef = useRef(null)
  const dispatch = useDispatch()

  const { title, tooltip, name, yAxis, height, options, chartData } = chart

  const [animationCompleted, setAnimationCompleted] = useState(false)

  const createBase64Img = chartToCopy => {
    if (chartToCopy) {
      const chartEl = document.createElement('canvas')
      const ctx = chartEl.getContext('2d')
      chartEl.style.display = 'none'

      document.body.append(chartEl)
      let chart = new ChartJS(ctx, {
        data: chartToCopy.config.data,
        options: {
          ...chartToCopy.config.options,
          animation: false, // disable animation
          plugins: {
            ...chartToCopy.config.options.plugins,
            datalabels: {
              ...chartToCopy.config.options.plugins.datalabels,
              display: true,
              font: {
                size: PNG_FONT_SIZE
              }
            },
            legend: {
              labels: {
                font: {
                  size: PNG_FONT_SIZE
                }
              }
            },
            subtitle: {
              ...chartToCopy.config.options.plugins.subtitle,
              font: {
                size: PNG_FONT_SIZE
              }
            }
          },
          scales: {
            ...chartToCopy.config.options.scales,
            y: {
              ...chartToCopy.config.options.scales.y,
              ticks: {
                ...chartToCopy.config.options.scales.y.ticks,
                font: {
                  size: PNG_FONT_SIZE
                }
              }
            },
            x: {
              ...chartToCopy.config.options.scales.x,
              ticks: {
                ...chartToCopy.config.options.scales.x.ticks,
                font: {
                  size: PNG_FONT_SIZE
                }
              }
            }
          },
          responsive: true, // Enable resize
          maintainAspectRatio: false // Avoid maintaining aspect ratio
        },
        platform: chartToCopy.config.platform,
        plugins: chartToCopy.config.plugins,
        type: chartToCopy.config.type
      })

      chart.resize(PNG_WIDTH, chartToCopy.height * 2) // resize chart

      chart.render()
      const base64 = chart.toBase64Image('image/png', 1)
      chartEl.remove()
      return base64
    }
  }

  const downloadChart = useCallback(() => {
    const link = document.createElement('a')
    link.download = `${title}.png`
    link.href = createBase64Img(chartRef.current)
    link.click()
    link.remove()
  }, [title])

  const shareResults = () => {
    dispatch(chartsAction.uploadChartRequest(createBase64Img(chartRef.current)))
    dispatch(chartsAction.setCurrentChart(chart))
    dispatch(chartsAction.changeStep())
    onShareResults()
  }

  const handleOnAnimationComplete = useCallback(
    animation => {
      if (!animationCompleted && !isNull(chartRef.current)) {
        dispatch(
          chartsAction.addChart({
            [name]: {
              title,
              base64Img: createBase64Img(animation.chart),
              width: PNG_WIDTH / 2, // for pptx we need to cut the width by half
              height: chartRef.current.height
            }
          })
        )

        setAnimationCompleted(true)
      }
    }, // eslint-disable-next-line
    [animationCompleted]
  )

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>{title}</span>
        <PSLTooltip
          tip={tooltip}
          multiline={false}
          iconProps={{
            icon: 'svg/custom/help-outline',
            iconSize: 20
          }}
        />
      </div>

      <div
        className={yAxis ? styles.containerWLabel : styles.chartContainer}
        style={{ height: height }}
      >
        {yAxis && <div className={styles.sideLabel}>{yAxis}</div>}
        <Bar
          ref={chartRef}
          data={chartData}
          options={{
            ...options,
            animation: {
              onComplete: handleOnAnimationComplete
            },
            duration: 0
          }}
          redraw={true}
        />
      </div>

      <div className={styles.linkContainer}>
        <button
          data-testid="barChart-share"
          className={chartOnly ? styles.linkHidden : styles.link}
          onClick={() => shareResults()}
        >
          Share Results
        </button>

        <button
          className={chartOnly ? styles.linkHidden : styles.link}
          onClick={() => downloadChart()}
        >
          Save as Image
        </button>
      </div>
    </div>
  )
}

BarChart.propTypes = {
  /**
   * The current chart
   */
  chart: PropTypes.object.isRequired,
  /**
   * If this prop is used the component hides "Save as image" and "Share results" links
   */
  chartOnly: PropTypes.bool,
  /**
   * Callback when share button is clicked
   */
  onShareResults: PropTypes.func
}

export default React.memo(BarChart)
