import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { scrollToTargetWithOffset } from 'utils'
import PSLMenu, { MenuItem } from 'components/ui/Menu'
import styles from './Menu.module.sass'
import classNames from 'classnames/bind'
import useRoleFeatures from 'modules/auth/useRoleFeatures'
import { actions as trackActions } from 'modules/tracking/TrackingReducer'

const cx = classNames.bind(styles)

const Menu = ({ menuItems = [], menuOpen, setMenuOpen }) => {
  const history = useHistory()
  const { roles = [] } = useRoleFeatures({})
  const dispatch = useDispatch()
  const handleSlideMenu = () => {
    setMenuOpen(false)
  }
  const handleMenuItemClick = ({ pathname }) => {
    const path = pathname === '/' ? 'home' : pathname
    dispatch(
      trackActions.trackCustomAction({
        title: `Authenticated - Side Menu Click: ${path}`
      })
    )
  }

  const hasPermission = link => {
    // Validate specific url permissions (needed form admin routes)
    return roles?.some(r => r.includes(link))
  }

  return (
    <PSLMenu
      onSlideMenu={handleSlideMenu}
      menuOpen={menuOpen}
      mode="sidebar"
      className={styles.menuList}
    >
      {menuItems.map(
        (
          { link, title, disabled, checkPermission = true, showOnAuth = false },
          index
        ) => {
          const linkWithHistory = {
            pathname: link,
            state: { prevPath: history.location.pathname }
          }
          const isHashLink = link.includes('#')
          const offset = link.includes('#listings') ? 30 : 0
          const isExternalLink =
            link && (link.startsWith('http') || link.startsWith('//'))
          const to = isExternalLink ? undefined : linkWithHistory || '/'
          const target = isExternalLink ? '_blank' : undefined
          const isDisabled =
            disabled || (checkPermission && !hasPermission(link))
          const hide = showOnAuth && !hasPermission(link)
          return (
            <MenuItem
              key={title}
              className={cx(
                styles.menuItem,
                isDisabled && styles.itemDisabled,
                hide && styles.hidden
              )}
              component={isHashLink ? HashLink : isExternalLink ? 'a' : Link}
              scroll={
                isHashLink
                  ? element => scrollToTargetWithOffset(element, offset)
                  : undefined
              }
              icon="svg/custom/carat-r"
              iconColor="action"
              to={!disabled ? to : ''}
              href={!disabled ? link : undefined}
              target={!disabled ? target : undefined}
              active={isDisabled}
              onClick={() => handleMenuItemClick(to)}
              data-testid={`menuItem${index}`}
            >
              {title}
            </MenuItem>
          )
        }
      )}
    </PSLMenu>
  )
}
Menu.propTypes = {
  /**
   * The array of menu items
   */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Link URL
       */
      link: PropTypes.string.isRequired,
      /**
       * Link text
       */
      title: PropTypes.string.isRequired
    })
  ),
  /**
   * If true side menu will appear open
   */
  menuOpen: PropTypes.bool,
  /**
   * Function to trigger side menu item
   */
  setMenuOpen: PropTypes.func
}
export default Menu
