import React from 'react'
import Page from 'components/Page'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button, { RaisedButton } from 'components/Button'
import Charts from 'modules/Charts'
import { actions as soundBoardActions } from './SoundBoardReducer'
import { actions as chartsActions } from '../Charts/ChartsReducer'
import * as soundBoardSelectors from './SoundBoardSelector'
import { getActiveStep } from '../Charts/ChartsSelector'
import styles from './SoundBoard.module.sass'
import { initialValues, steps } from './config'
import { isEmpty } from 'utils'
import classNames from 'classnames/bind'
import validationSchema from './validationSchema'

const cx = classNames.bind(styles)

const { getFormState, getFetchStatus } = soundBoardSelectors

const SoundBoard = () => {
  let initialForm = {
    ...initialValues
  }
  const dispatch = useDispatch()
  const history = useHistory()
  const formState = useSelector(getFormState())
  const isFetching = useSelector(getFetchStatus())
  const chartsStep = useSelector(getActiveStep())
  const { activeStep } = formState
  const formStatePr = isEmpty(formState) ? initialForm : formState
  const chartsData = isEmpty(formState) ? {} : formState.chartsData
  const currentValidationSchema = validationSchema[activeStep]

  const handleBack = () => {
    let newStep = ''
    switch (activeStep) {
      case 'charts':
      case 'list-selection':
        history.push('/')
        break
      case 'share-results':
        newStep = 'charts'
        break
      // case 'project-metadata':
      //   newStep = 'filters'
      //   break
      default:
        break
    }
    if (newStep) {
      dispatch(
        soundBoardActions.setFormState({
          ...formState,
          activeStep: newStep
        })
      )
    }
  }

  const handleShareResults = () => {
    dispatch(
      soundBoardActions.setFormState({
        ...formState,
        activeStep: 'share-results'
      })
    )
  }

  const handleSubmit = (values, actions, ...rest) => {
    dispatch(soundBoardActions.submitCompleteRequest({ ...values }))
  }

  const handleReset = ({ resetForm }) => {
    if (typeof resetForm === 'function') {
      resetForm()
    }
    dispatch(
      soundBoardActions.setFormState({
        ...initialValues
      })
    )
    if (chartsStep === 'share-results') {
      dispatch(chartsActions.changeStep())
    }
  }
  const ActiveStep = formProps =>
    React.cloneElement(steps[activeStep].component, {
      ...steps[activeStep].component.props,
      formProps,
      handleReset
    })

  return (
    <Page
      heroTitle={
        activeStep === 'share-results'
          ? 'Share your results'
          : 'Explore SoundBoard'
      }
      showHero
      showGoBack
      onBackClick={handleBack}
      showDivider
      showFooter={false}
      contentClassName={styles.contentContainer}
    >
      <Formik
        initialValues={formStatePr}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={currentValidationSchema}
      >
        {formProps => (
          <>
            <Form>
              <ActiveStep {...formProps} />
              <div className={styles.buttonContainer}>
                {activeStep !== 'share-results' && (
                  <Button
                    className={styles.button}
                    type="submit"
                    submitting={isFetching}
                    disabled={isFetching}
                    hasSpinner
                    isLimaSubmit
                  />
                )}
              </div>
              <div className={cx(styles.resetContainer)}>
                <RaisedButton
                  type="button"
                  className={cx(styles.resetButton)}
                  onClick={() => handleReset(formProps)}
                  disabled={isFetching}
                >
                  Reset Form
                </RaisedButton>
              </div>
            </Form>
            {activeStep === 'charts' && !isFetching && (
              <Charts
                chartsData={chartsData}
                formProps={formProps}
                onShareResults={handleShareResults}
              />
            )}
          </>
        )}
      </Formik>
    </Page>
  )
}

export default SoundBoard
