import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useField, useFormikContext } from 'formik'
import SelectMenu from 'components/ui/SelectMenu'
import styles from './SelectMenuInput.module.sass'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

/**
 * PSL UI select input integration with formik
 * @param options
 * @param suggestionsLimit
 * @param traditional
 * @param props
 * @returns {*}
 * @constructor
 */
const SelectMenuInput = ({
  traditional = true,
  options,
  className,
  // combinationsFlag,
  ...props
}) => {
  const [field, meta] = useField({ ...props, type: 'select' })
  const [inputValue, setInputValue] = useState(field.value)
  const { setFieldValue } = useFormikContext()
  const { disabled, name: fieldName } = props

  // Update state value if fieldvalue was updated
  // Note: Ensure initialValue is set correctly, otherwise it will ignore
  // first character, this happens because the component pass from uncontrolled to controlled
  useEffect(() => {
    setInputValue(field.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value])

  const onChange = (e, { newValue }) => {
    if (typeof props.onChange === 'function') {
      props.onChange(newValue)
      // if (props.SetTargetButtonDisabled) {
      //   props.SetTargetButtonDisabled(
      //     !(newValue === 'Physician' && !combinationsFlag)
      //   )
      // }
    }
    setInputValue(newValue)
    if (!Object.values(options).find(o => o === newValue)) {
      setFieldValue(fieldName, '')
    }
  }
  const onSuggestionSelected = (e, { suggestion }) => {
    setInputValue(suggestion.key)
    setFieldValue(fieldName, suggestion.key)
    if (typeof props.onSuggestionSelected === 'function') {
      props.onSuggestionSelected(suggestion)
    }
  }
  const onBlur = () => {
    if (!Object.keys(options).find(o => o === inputValue)) {
      setInputValue('')
      setFieldValue(fieldName, '')
    } else {
      setFieldValue(fieldName, inputValue)
    }
  }
  return (
    <SelectMenu
      {...props}
      id={props.name}
      className={cx(
        traditional && styles.traditional,
        disabled && styles.disabled,
        className
      )}
      name={props.name}
      value={inputValue}
      onChange={onChange}
      options={options}
      traditional={traditional}
      showIcon={true}
      autoComplete="off" // Using unsupported autocomplete value to disable Chrome autocomplete
      error={meta.touched && meta.error ? meta.error : null}
      onSuggestionSelected={onSuggestionSelected}
      input={{ onBlur }}
    />
  )
}

SelectMenuInput.propTypes = {
  /**
   * Input name
   */
  name: PropTypes.string.isRequired,
  /**
   * Input label
   */
  label: PropTypes.string.isRequired,
  /**
   * Additional data for default select input
   */
  props: PropTypes.object,
  /**
   * object for select input options
   */
  options: PropTypes.object,
  /**
   * flag for traditional render
   */
  traditional: PropTypes.bool,
  /**
   * flag for disable render
   */
  disabled: PropTypes.bool,
  /**
   * Input value
   */
  value: PropTypes.string,
  /**
   * Additional className passed to the root component
   */
  className: PropTypes.string,
  /**
   * onChange callback
   */
  onChange: PropTypes.func,
  /**
   * onSuggestionSelected callback
   */
  onSuggestionSelected: PropTypes.func
  /**
   * SetTargetButtonDisabled callback
   */
  // SetTargetButtonDisabled: PropTypes.func,
  /**
   * flag for traditional render
   */
  // combinationsFlag: PropTypes.bool
}
export default SelectMenuInput
