import { createReducer, createAction } from '@reduxjs/toolkit'
import { initialValues } from './config'

export const setFormState = createAction('SOUNDBOARD_SET_FORM_STATE')

export const submitFormRequest = createAction('SOUNDBOARD_SUBMIT_FORM_REQUEST')
export const submitFormRequestSuccess = createAction(
  'SOUNDBOARD_SUBMIT_FORM_REQUEST_SUCCESS'
)
export const submitFormRequestFail = createAction(
  'SOUNDBOARD_SUBMIT_FORM_REQUEST_FAIL'
)
export const submitCompleteRequest = createAction('SOUNDBOARD_COMPLETE_REQUEST')
export const submitCompleteRequestSuccess = createAction(
  'SOUNDBOARD_COMPLETE_REQUEST_SUCCESS'
)
export const submitCompleteRequestFail = createAction(
  'SOUNDBOARD_COMPLETE_REQUEST_FAIL'
)

export const actions = {
  setFormState,
  submitFormRequest,
  submitFormRequestSuccess,
  submitFormRequestFail,
  submitCompleteRequest,
  submitCompleteRequestSuccess,
  submitCompleteRequestFail
}

const soundBoardReducer = createReducer(
  {
    formState: {
      ...initialValues
    },
    fetching: false
  },
  {
    [setFormState]: (state, { payload }) => {
      state.formState = { ...state.formState, ...payload }
    },
    [submitFormRequest]: state => {
      state.fetching = true
    },
    [submitCompleteRequest]: state => {
      state.fetching = true
    },
    [submitCompleteRequestSuccess]: state => {
      state.fetching = false
    },
    [submitCompleteRequestFail]: state => {
      state.fetching = false
    },
    [submitFormRequestSuccess]: state => {
      state.fetching = false
    },
    [submitFormRequestFail]: state => {
      state.fetching = false
    }
  }
)

export default soundBoardReducer
