import { createReducer, createAction } from '@reduxjs/toolkit'

export const setFormState = createAction('JOB_STATUS_SET_FORM_STATE')

export const searchRequest = createAction('JOB_STATUS_SEARCH_REQUEST')
export const searchRequestSuccess = createAction(
  'JOB_STATUS_SEARCH_REQUEST_SUCCESS'
)
export const searchRequestFail = createAction('JOB_STATUS_SEARCH_REQUEST_FAIL')

export const requestListStatus = createAction('JOB_STATUS_LIST_STATUS_REQUEST')
export const requestListStatusSuccess = createAction(
  'JOB_STATUS_LIST_STATUS_REQUEST_SUCCESS'
)
export const requestListStatusFail = createAction(
  'JOB_STATUS_LIST_STATUS_REQUEST_FAIL'
)

export const requestListIds = createAction('JOB_STATUS_LIST_IDS_REQUEST')
export const requestListIdsSuccess = createAction(
  'JOB_STATUS_LIST_IDS_REQUEST_SUCCESS'
)
export const requestListIdsFail = createAction(
  'JOB_STATUS_LIST_IDS_REQUEST_FAIL'
)

export const requestListMetadataDomains = createAction(
  'JOB_STATUS_LIST_METADATA_DOMAINS_REQUEST'
)
export const requestListMetadataDomainsSuccess = createAction(
  'JOB_STATUS_LIST_METADATA_DOMAINS_REQUEST_SUCCESS'
)
export const requestListMetadataDomainsFail = createAction(
  'JOB_STATUS_LIST_METADATA_DOMAINS_REQUEST_FAIL'
)

export const assignListFormOpen = createAction('JOB_STATUS_ASSIGN_LIST_OPEN')
export const assignListFormClose = createAction('JOB_STATUS_ASSIGN_LIST_CLOSE')
export const assignListUpdateEmails = createAction(
  'JOB_STATUS_ASSIGN_UPDATE_EMAILS'
)
export const assignListRequest = createAction('JOB_STATUS_ASSIGN_LIST_REQUEST')
export const assignListSuccess = createAction('JOB_STATUS_ASSIGN_LIST_SUCCESS')
export const assignListFail = createAction('JOB_STATUS_ASSIGN_LIST_FAIL')

export const actions = {
  setFormState,
  searchRequest,
  searchRequestSuccess,
  searchRequestFail,
  requestListStatus,
  requestListStatusSuccess,
  requestListStatusFail,
  requestListIds,
  requestListIdsSuccess,
  requestListIdsFail,
  requestListMetadataDomains,
  requestListMetadataDomainsSuccess,
  requestListMetadataDomainsFail,
  assignListFormOpen,
  assignListFormClose,
  assignListUpdateEmails,
  assignListRequest,
  assignListSuccess,
  assignListFail
}

const JobStatusListReducer = createReducer(
  {
    list: [],
    total: 0,
    fetching: false,
    error: '',
    searchState: {
      startDate: null,
      endDate: null,
      listId: '',
      status: null,
      domain: null,
      masterProject: ''
    },
    paginationState: {
      page: null,
      perPage: null,
      sortBy: null,
      sortOrder: null
    },
    listStatus: {
      fetching: false,
      error: '',
      options: {}
    },
    listIds: {
      fetching: false,
      error: '',
      options: {}
    },
    listDomains: {
      fetching: false,
      error: '',
      options: {}
    },
    assignForm: {
      open: false,
      emailsList: [],
      fetching: false,
      listId: '',
      key: ''
    }
  },
  {
    [setFormState]: (state, { payload }) => {
      state.searchState = { ...state.searchState, ...payload }
    },
    [searchRequest]: (
      state,
      {
        payload: {
          page = 1,
          perPage = null,
          sortBy = '',
          sortOrder = '',
          startDate = null,
          endDate = null,
          listId = '',
          status = null,
          domain = null,
          masterProject = ''
        }
      }
    ) => {
      state.fetching = true
      state.paginationState.page = page
      state.paginationState.perPage = perPage
      state.paginationState.sortBy = sortBy
      state.paginationState.sortOrder = sortOrder
      state.searchState.startDate = startDate
      state.searchState.endDate = endDate
      state.searchState.listId = listId
      state.searchState.status = status
      state.searchState.domain = domain
      state.searchState.masterProject = masterProject
    },
    [searchRequestSuccess]: (state, { payload: { list, total } }) => {
      state.list = list
      state.total = total
      state.fetching = false
      state.error = ''
    },
    [searchRequestFail]: (state, { payload: { error = '' } }) => {
      state.fetching = false
      state.error = error
    },
    [requestListStatus]: state => {
      state.listStatus.fetching = true
    },
    [requestListStatusSuccess]: (state, { payload: { options } }) => {
      state.listStatus.fetching = false
      state.listStatus.error = ''
      state.listStatus.options = options
    },
    [requestListStatusFail]: (state, { payload: { error = '' } }) => {
      state.listStatus.fetching = false
      state.listStatus.error = error
    },
    [requestListIds]: state => {
      state.listIds.fetching = true
    },
    [requestListIdsSuccess]: (state, { payload: { options } }) => {
      state.listIds.fetching = false
      state.listIds.error = ''
      state.listIds.options = options
    },
    [requestListIdsFail]: (state, { payload: { error = '' } }) => {
      state.listIds.fetching = false
      state.listIds.error = error
    },
    [requestListMetadataDomains]: state => {
      state.listDomains.fetching = true
    },
    [requestListMetadataDomainsSuccess]: (state, { payload: { options } }) => {
      state.listDomains.fetching = false
      state.listDomains.error = ''
      state.listDomains.options = options
    },
    [requestListMetadataDomainsFail]: (state, { payload: { error = '' } }) => {
      state.listDomains.fetching = false
      state.listDomains.error = error
    },
    [assignListFormOpen]: (state, { payload: { listId } }) => {
      state.assignForm.open = true
      state.assignForm.listId = listId
      state.assignForm.fetching = true
    },
    [assignListFormClose]: state => {
      state.assignForm.open = false
      state.assignForm.emailsList = []
      state.assignForm.listId = ''
      state.assignForm.key = ''
    },
    [assignListUpdateEmails]: (state, { payload: { emailsList } }) => {
      state.assignForm.emailsList = emailsList
      state.assignForm.fetching = false
    },
    [assignListRequest]: state => {
      state.assignForm.fetching = true
    },
    [assignListSuccess]: state => {
      state.assignForm.open = false
      state.assignForm.emailsList = []
      state.assignForm.fetching = false
      state.assignForm.listId = ''
      state.assignForm.key = ''
    },
    [assignListFail]: state => {
      state.assignForm.fetching = false
    }
  }
)

export default JobStatusListReducer
