import Typography from '../ui/Typography'
import Divider from '../ui/Divider'
import PropTypes from 'prop-types'
import styles from './PageHero.module.sass'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const PageHero = ({
  title = '',
  divider = true,
  children,
  isLimaBureau = false
}) => (
  <section className={cx(styles.hero, { isLimaBureau })}>
    <Typography type="title" color="primary" className={styles.title}>
      {title}
    </Typography>
    {divider && <Divider className={styles.divider} />}
    {children}
  </section>
)
PageHero.propTypes = {
  /**
   * Text used for the hero section "title" element
   */
  title: PropTypes.string,
  /**
   * Hero element children rendered below heading text
   */
  children: PropTypes.node,
  /**
   * if false it will hide hero divider
   */
  divider: PropTypes.bool,
  /**
   * If true it will use the Lima Bureau styles
   */
  isLimaBureau: PropTypes.bool
}

export default PageHero
