import parse from 'html-react-parser'
import MuiAccordion from '@material-ui/core/ExpansionPanel'
import MuiAccordionSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiAccordionDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import Icon from 'components/ui/Icon'
import PropTypes from 'prop-types'
import {
  createMuiTheme,
  ThemeProvider,
  withStyles
} from '@material-ui/core/styles'
// import styles from './Faq.module.sass'

const theme = createMuiTheme({
  typography: {
    fontFamily: ['lato', 'Arial', 'sans-serif'].join(',')
  },
  overrides: {
    MuiExpansionPanelDetails: {
      root: {
        flexDirection: 'column'
      }
    }
  }
})

const Accordion = withStyles({
  root: {
    position: 'unset',
    boxShadow: 'none',
    backgroundColor: 'transparent'
  }
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    flexDirection: 'row-reverse',
    '&$expanded': {
      minHeight: '48px'
    }
  },
  content: {
    '& p': {
      color: 'rgba(0,0,0,0.84)',
      fontWeight: '400',
      fontSize: '18px',
      '&:hover': {
        color: '#25b89b'
      }
    },
    '&$expanded': {
      margin: '12px 0 0'
    }
  },
  expandIcon: {
    marginRight: 0
  },
  expanded: {
    '&$expandIcon': {
      transform: 'rotate(90deg)'
    }
  }
})(MuiAccordionSummary)

const AccordionDetails = withStyles({
  root: {
    paddingLeft: '64px'
  }
})(MuiAccordionDetails)

const Faq = ({ data }) => {
  const faqContent = data['en']

  return (
    <div>
      {Object.keys(faqContent).map(item => {
        return (
          <Accordion key={item}>
            <AccordionSummary
              expandIcon={
                <Icon icon={'svg/custom/carat-r'} iconColor="#25b89b" />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{parse(faqContent[item].header)}</Typography>
            </AccordionSummary>
            <AccordionDetails width="100%">
              {faqContent[item].items.map(item => {
                return (
                  <div width="100%" key={item.question}>
                    <Accordion width="100%">
                      <div>
                        <AccordionSummary
                          expandIcon={
                            <Icon
                              icon={'svg/custom/carat-r'}
                              iconColor="#25b89b"
                            />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          width="100%"
                        >
                          <Typography
                            style={{
                              fontSize: '17px',
                              fontWeight: 300
                            }}
                            width="100%"
                          >
                            {parse(item.question)}
                          </Typography>
                        </AccordionSummary>
                      </div>
                      <div>
                        <ThemeProvider theme={theme}>
                          <AccordionDetails width="100%">
                            <Typography width="100%" data-testid="item-answer">
                              {parse(item.answer)}
                            </Typography>
                          </AccordionDetails>
                        </ThemeProvider>
                      </div>
                    </Accordion>
                  </div>
                )
              })}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  )
}

Faq.propTypes = {
  data: PropTypes.object
}

export default Faq
