import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import CombinationsTable from 'modules/CombinationsTable'
import FiltersComponent from 'modules/filter'
import styles from './form.module.sass'
import Typography from '../../../components/ui/Typography'
import Button from 'components/Button'
import { actions as exploreMarketActions } from '../ExploreMarketReducer'
import * as exploreMarketSelectors from '../ExploreMarketSelector'
import useRoleFeatures from 'modules/auth/useRoleFeatures'
import { isCombinationValid } from 'utils'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'

const { getFetchStatus } = exploreMarketSelectors

const Filters = ({ formProps }) => {
  const isFetching = useSelector(getFetchStatus())
  const { setFieldValue, values } = formProps
  const { features } = useRoleFeatures({
    coin: 'explore-market'
  })
  const {
    combinations,
    combinationsCountry,
    combinationsProfession,
    combinationsSpecialty
  } = values
  const dispatch = useDispatch()

  const handleSubmit = type => {
    if (
      combinationsCountry ||
      combinationsProfession ||
      combinationsSpecialty
    ) {
      // Combinations inputs dirty, validate
      if (
        isCombinationValid(
          combinationsCountry,
          combinationsProfession,
          combinationsSpecialty
        )
      ) {
        // Combination valid, proceed to submit
        setFieldValue('type', type)
        dispatch(exploreMarketActions.submitFormRequest({ ...values, type }))
      } else {
        // Combination invalid, display error
        dispatch(
          notificationActions.notificationEnqueue({
            message: 'Please select a valid combination.',
            duration: 0
          })
        )
      }
    } else if (combinations.length !== 0) {
      // There's at leat 1 combination, proceed with the submit
      setFieldValue('type', type)
      dispatch(exploreMarketActions.submitFormRequest({ ...values, type }))
    } else {
      // No valid combinations, display error
      dispatch(
        notificationActions.notificationEnqueue({
          message: 'At least 1 combination set is required',
          duration: 0
        })
      )
    }
  }

  const handleAddCombinations = () => {
    if (
      isCombinationValid(
        combinationsCountry,
        combinationsProfession,
        combinationsSpecialty
      )
    ) {
      setFieldValue('combinations', [
        ...values['combinations'],
        {
          id: values['combinations'].length + 1,
          country: combinationsCountry,
          profession: combinationsProfession,
          specialty: combinationsSpecialty
        }
      ])
      setFieldValue('combinationsCountry', '')
      setFieldValue('combinationsProfession', '')
      setFieldValue('combinationsSpecialty', '')
    } else {
      dispatch(
        notificationActions.notificationEnqueue({
          message: 'Please select a valid combination.',
          duration: 0
        })
      )
    }
  }

  return (
    <div data-testid="filtersContainer">
      <Typography className={styles.center}>
        Use the provided filters to generate a list of HCPs from the LiMA
        universe.
      </Typography>
      <div>
        <CombinationsTable
          name="combinations"
          formProps={formProps}
          workflow="ExploreMarket"
        />
        <FiltersComponent name="filters" />
      </div>
      <div className={styles.buttonsRow}>
        {features.sections?.actions.includes('graph') && (
          <Button
            data-testid="graphSubmit"
            className={styles.button}
            onClick={() => handleSubmit('graph')}
            text="Graph"
            type="button"
            hasSpinner={values.type === 'graph'}
            submitting={isFetching}
            disabled={isFetching}
          />
        )}
        {features.sections?.actions.includes('save-list') && (
          <Button
            data-testid="saveListSubmit"
            type="button"
            className={styles.button}
            onClick={() => handleSubmit('save-list')}
            text="Save List"
            hasSpinner={values.type === 'save-list'}
            submitting={isFetching}
            disabled={isFetching}
          />
        )}
        {features.sections?.actions.includes('add-combination') && (
          <Button
            data-testid="addCombination"
            type="button"
            className={styles.button}
            text="Add Combination"
            onClick={handleAddCombinations}
          />
        )}
      </div>
      {/* <div className={styles.buttonsRow}>
        <Button
          type="button"
          className={styles.button}
          text="Add Combination"
          onClick={handleAddCombinations}
        />
        <Button
          className={styles.button}
          onClick={() => handleSubmit('run-analysis')}
          text="Run Analysis"
          type="button"
          hasSpinner={values.type === 'run-analysis'}
          submitting={isFetching}
          disabled={isFetching}
        />
        <Button
          type="button"
          onClick={() => handleReset()}
          className={styles.button}
          text="Reset Form"
          disabled={isFetching}
        />
      </div> */}
    </div>
  )
}

Filters.propTypes = {
  /**
   * Form props
   */
  formProps: PropTypes.object
}

export default Filters
