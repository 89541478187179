import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useTracking from 'modules/dougall/tracking/useTracking'
import PropTypes from 'prop-types'

import { styled } from '@mui/material/styles'
import { DougallLogoWhite, MessageTextSquareIcon } from 'static/icons'

import History from 'modules/dougall/history/History'
// import PopularPrompts from 'modules/dougall/popularPrompts/PopularPrompts'
import Faq from 'modules/dougall/faq/Faq'

import Typography from 'components/ui/Typography'
import styles from './../Home.module.sass'

/**
 * Button Icon
 */
const ButtonIcon = ({ icon, ...props }) => {
  const Icon = styled(icon)(() => ({
    marginRight: '12px',
    marginLeft: '0px',
    fontSize: '24px'
  }))
  return <Icon {...props} />
}
ButtonIcon.propTypes = {
  /**
   * Icon component to be rendered
   */
  icon: PropTypes.func
}

const LeftBarButton = ({ icon = '', label = '', onClick = () => {} }) => {
  return (
    <div
      className={styles.button}
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') onClick()
      }}
    >
      <ButtonIcon icon={icon} />
      <Typography className={styles.whiteText} type="display2">
        {label}
      </Typography>
    </div>
  )
}

LeftBarButton.propTypes = {
  icon: PropTypes.func,
  label: PropTypes.string,
  onClick: PropTypes.func
}

const LeftBar = ({ listExpanded = 0 }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { trackClickAction } = useTracking()

  // 0 - None
  // 1 - Popular Prompts
  // 2 - History
  // 3 - FAQ
  const [listOpen, setListOpen] = useState(listExpanded)

  const listsOpenHandler = id => {
    switch (id) {
      case 'popular':
        listOpen === 1 ? setListOpen(0) : setListOpen(1)
        break
      case 'history':
        listOpen === 2 ? setListOpen(0) : setListOpen(2)
        break
      case 'faq':
        listOpen === 3 ? setListOpen(0) : setListOpen(3)
        break
      default:
        setListOpen(0)
    }
  }

  const feedbackRedirect = () => {
    trackClickAction({
      title: `Site/Click/Home/feedback/side-panel`,
      clickType: 'feedback-side-panel'
    })
    return history.push('/feedback', { from: history.location.pathname })
  }

  return (
    <div className={styles.leftBarContainer}>
      <DougallLogoWhite
        sx={{
          height: 'unset',
          width: '136px', // '96px'
          marginTop: '20px',
          marginLeft: '10px',
          marginBottom: '26px'
        }}
        alt={'DGPT Logo'}
      />
      <div className={styles.leftBarScrollable}>
        <div>
          {/* <PopularPrompts
            nightMode
            openCallback={listsOpenHandler}
            isOpen={listOpen === 1}
          /> */}
          <History
            nightMode
            openCallback={listsOpenHandler}
            isOpen={listOpen === 2}
          />
        </div>
        <div>
          <Faq
            nightMode
            openCallback={listsOpenHandler}
            isOpen={listOpen === 3}
          />
          <LeftBarButton
            icon={MessageTextSquareIcon}
            label={t('feedbackLeftBar')}
            onClick={feedbackRedirect}
          />
        </div>
      </div>
    </div>
  )
}

LeftBar.propTypes = {
  /**
   * Number of list that should be expanded on mount
   */
  listExpanded: PropTypes.number
}

export default LeftBar
