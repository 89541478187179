import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styles from './form.module.sass'
import Checkbox from 'components/Checkbox'
import Button, { RaisedButton } from 'components/Button'
import FiltersComponent from 'modules/filter'
import Typography from '../../../components/ui/Typography'
import Icon from 'components/ui/Icon'
import CombinationTable from 'modules/CombinationsTable'
import * as exploreListSelectors from '../ExploreListSelector'
import ListIds from 'modules/listIds'
import Divider from '../../../components/ui/Divider'
import useRoleFeatures from 'modules/auth/useRoleFeatures'

const { getFetchStatus } = exploreListSelectors

const ExploreListForm = ({ formProps }) => {
  const isFetching = useSelector(getFetchStatus())
  const { setFieldValue, values } = formProps
  const { activeStep, selectedListLabels } = values
  const { features } = useRoleFeatures({
    coin: 'explore-list'
  })

  // Update type value when user click on submit buttons
  // (ExploreList submit will be called after this function)
  const handleSubmit = type => {
    setFieldValue('type', type)
  }
  const handleAddCombinations = () => {
    if (
      values.combinationsCountry ||
      values.combinationsProfession ||
      values.combinationsSpecialty
    ) {
      setFieldValue('combinations', [
        ...values['combinations'],
        {
          id: values['combinations'].length + 1,
          country: values.combinationsCountry,
          profession: values.combinationsProfession,
          specialty: values.combinationsSpecialty
        }
      ])
      setFieldValue('combinationsCountry', '')
      setFieldValue('combinationsProfession', '')
      setFieldValue('combinationsSpecialty', '')
    }
  }
  const handleListIdSelection = (id, suggestion) => {
    const newSelectedLabels = [...selectedListLabels]
    newSelectedLabels[id] = suggestion.label
    setFieldValue('selectedListLabels', newSelectedLabels)
  }

  const handleListIdRemove = index => {
    const newSelectedLabels = [...selectedListLabels]
    newSelectedLabels.splice(index, 1)
    setFieldValue('selectedListLabels', newSelectedLabels)
  }

  return (
    <div className={styles.divider}>
      <ListIds
        formName="explore-project"
        name="ids"
        disabled={activeStep === 'filters'}
        onSuggestionSelected={handleListIdSelection}
        onListIdRemove={handleListIdRemove}
        noDuplicate
        requestBody={{
          data: {
            filters: {
              workflow: 'ExploreList'
            },
            search: {
              perPage: 100
            }
          }
        }}
      />
      {activeStep === 'filters' ? (
        <>
          <Checkbox
            label="Select all List Members"
            className={styles.selectAllMembers}
            name="allMembersSelected"
            isRadio
          />
          <div>
            <Typography
              type="title"
              color="text-primary"
              className={styles.title}
              align="center"
            >
              Select Filters
            </Typography>
            <Divider />
            <Typography type="body1" align="center" className={styles.subtitle}>
              Optional - Use the provided filters to generate a list of HCPs
              from the LiMA universe
            </Typography>
          </div>

          <div>
            <CombinationTable
              name="combinations"
              formProps={formProps}
              workflow="ExploreList"
            />
            {!values.allMembersSelected && <FiltersComponent name="filters" />}
          </div>
          <div className={styles.buttonsRow}>
            {features.sections?.actions.includes('graph') && (
              <Button
                className={styles.button}
                onClick={() => handleSubmit('graph')}
                text="Graph"
                hasSpinner={values.type === 'graph'}
                submitting={isFetching}
                disabled={isFetching}
              />
            )}

            {features.sections?.actions.includes('save-list') && (
              <Button
                className={styles.button}
                onClick={() => handleSubmit('save-list')}
                text="Save List"
                hasSpinner={values.type === 'save-list'}
                submitting={isFetching}
                disabled={isFetching}
              />
            )}

            {features.sections?.actions.includes('add-combination') && (
              <Button
                type="button"
                className={styles.button}
                text="Add Combination"
                onClick={handleAddCombinations}
                disabled={isFetching}
              />
            )}
          </div>
          {/* <div className={styles.buttonsRow}>
            <Button
              type="button"
              className={styles.button}
              text="Add Combination"
              onClick={handleAddCombinations}
              disabled={isFetching}
            />
            <Button
              className={styles.button}
              onClick={() => handleSubmit('run-analysis')}
              text="Run Analysis"
              hasSpinner={values.type === 'run-analysis'}
              submitting={isFetching}
              disabled={isFetching}
            />
            <Button
              type="button"
              className={styles.button}
              text="Reset Form"
              onClick={handleReset}
              disabled={isFetching}
            />
          </div> */}
        </>
      ) : (
        <div className={styles.addListContainer}>
          {values.ids.slice(-1) !== '' && (
            <RaisedButton
              type="button"
              onClick={() => {
                if (values.ids[values.ids.length - 1]) {
                  setFieldValue('ids', [...values.ids, ''])
                }
              }}
              disabled={isFetching}
              fontSize="16px"
            >
              Add List Id{' '}
              <Icon icon="svg/custom/add-circle" colorTier="tertiary" />
            </RaisedButton>
          )}
        </div>
      )}
    </div>
  )
}

ExploreListForm.propTypes = {
  /**
   * Form props
   */
  formProps: PropTypes.object
  /**
   * Reset Form Func
   * handleReset: PropTypes.func
   */
}

export default ExploreListForm
