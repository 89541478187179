import PropTypes from 'prop-types'
import { useField, useFormikContext } from 'formik'
import Checkbox from 'components/ui/Checkbox'
import { isEmpty } from '../../utils'
import Typography from 'components/ui/Typography'

const CheckboxInputLB = ({
  multi = false,
  boxes = [],
  inline = false,
  hasDivider = false,
  showError = true,
  ...props
}) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' })
  const { values } = useFormikContext()
  const fieldName = props.name
  if (multi === true) {
    return (
      <>
        {boxes.map((box, i) => {
          return (
            <Checkbox
              {...props}
              key={box.label}
              isRadio
              label={box.label}
              checked={
                !isEmpty(values[fieldName]) && field.value.includes(box.value)
              }
            />
          )
        })}
        {showError && meta.touched && meta.error ? (
          <Typography color="error">{meta.error}</Typography>
        ) : null}
      </>
    )
  } else {
    return (
      <Checkbox
        inline
        hasDivider
        {...field}
        {...props}
        error={
          showError && (meta.submitCount || meta.touched ? meta.error : null)
        }
        traditional={true}
      />
    )
  }
}
CheckboxInputLB.propTypes = {
  multi: PropTypes.bool,
  boxes: PropTypes.array,
  inline: PropTypes.bool,
  hasDivider: PropTypes.bool,
  props: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  showError: PropTypes.bool
}
export default CheckboxInputLB
