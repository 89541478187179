import { useState } from 'react'
import PropTypes from 'prop-types'
import MomentUtils from '@date-io/moment'
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider'
import { DatePicker } from '@material-ui/pickers/DatePicker'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import styles from './DatePicker.module.sass'
import FieldError from '../FieldError'
import tinycolor from 'tinycolor2'
import classnames from 'classnames/bind'

const cx = classnames.bind(styles)

const DatePickerComponent = ({
  primaryColor,
  actionColor,
  meta: { touched, error: metaError },
  error,
  errorAlign,
  disableFuture,
  onChange,
  className,
  dialogProps,
  ...rest
}) => {
  let materialTheme = {
    overrides: {
      MuiPickersToolbar: {
        dateTitleContainer: {
          flex: 1,
          alignItems: 'center'
        },
        toolbar: {
          backgroundColor: primaryColor
        }
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: actionColor,
          '&:hover': actionColor && {
            backgroundColor: tinycolor(actionColor).lighten(10).toString()
          },
          '&:focus': actionColor && {
            backgroundColor: tinycolor(actionColor).lighten(10).toString()
          }
        }
      },
      MuiPickersYear: {
        yearSelected: {
          backgroundColor: actionColor,
          '&:hover': actionColor && {
            backgroundColor: tinycolor(actionColor).lighten(10).toString()
          },
          '&:focus': actionColor && {
            backgroundColor: tinycolor(actionColor).lighten(10).toString()
          }
        }
      },
      MuiButton: {
        textPrimary: {
          color: actionColor,
          '&:hover': {
            color: tinycolor(actionColor).darken(10).toString()
          }
        }
      }
    }
  }
  const hasError = Boolean((touched && metaError) || error)
  const renderDay = (date, selectedDate, dayInCurrentMonth, dayComponent) => (
    <div className={cx(styles.day, { dayTheme: !actionColor })}>
      {dayComponent}
    </div>
  )
  const handleChange = (date, value) => {
    setSelectedDate(date)
    if (typeof onChange === 'function') {
      onChange(date, value)
    }
  }
  const [selectedDate, setSelectedDate] = useState(new Date())
  return (
    <MuiThemeProvider theme={createMuiTheme(materialTheme)}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <>
          <DatePicker
            className={cx(styles.datepicker, { hasError }, className)}
            disableFuture={disableFuture}
            DialogProps={{
              className: cx(styles.dialog, {
                theme: !primaryColor,
                yearTheme: !actionColor,
                buttonTheme: !actionColor
              }),
              ...dialogProps
            }}
            value={selectedDate}
            onChange={handleChange}
            renderDay={renderDay}
            mask="__/__/____" // Can be removed when updated to non-alpha version 4
            {...rest}
          />
          {hasError && (
            <FieldError align={errorAlign}>{metaError || error}</FieldError>
          )}
        </>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  )
}
DatePickerComponent.defaultProps = {
  disableFuture: false,
  meta: {},
  errorAlign: 'center'
}
DatePickerComponent.propTypes = {
  /**
   * The primary color used for the datepicker (this will override the theme)
   */
  primaryColor: PropTypes.string,
  /**
   * The color of the actionable items (this will override the theme)
   */
  actionColor: PropTypes.string,
  /**
   * To hide the future year selection
   */
  disableFuture: PropTypes.bool,
  /**
   * Metadata about the state of this field (if using redux-form)
   */
  meta: PropTypes.object,
  /**
   * The error shown below the input (not used if there is a redux-form style 'meta.error' value)
   */
  error: PropTypes.node,
  /**
   * The alignment of the error
   */
  errorAlign: PropTypes.string,
  /**
   * Called when date is changed
   *
   *  onChange(date, value)
   *
   *  date - the date object
   *  value - the raw field value
   */
  onChange: PropTypes.func,
  /**
   * An additional custom className applied to the field element
   */
  className: PropTypes.string,
  /**
   * Props to be passed directly to material-ui Dialog
   */
  dialogProps: PropTypes.object
}
DatePickerComponent.displayName = 'DatePicker'
export default DatePickerComponent
