import { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import FormButton from 'components/Dougall/Button/FormButton'
import Page from 'components/Page'
import Typography from 'components/ui/Typography'
import TextInput from 'components/TextInput'
import TextAreaInput from 'components/ui/TextArea'

import { actions } from './FeedbackReducer'
import { getFeedbackFetchStatus } from './FeedbackSelector'
import useAuth from 'modules/auth/useAuth'
// import useTracking from 'modules/tracking/useTracking'

// import { recaptchaKey } from '../../constants'
import styles from './FeedbackForm.module.sass'
import { range } from 'lodash'
import siteConfig from 'site.config.json'

const { configuration } = siteConfig

const altButtonTheme = {
  margin: '20px 0 0',
  width: '100%',
  borderRadius: '7px',
  textTransform: 'capitalize',
  fontSize: '15px'
}

const FeedbackForm = ({ title, brandType, subHeader }) => {
  const dispatch = useDispatch()
  const { isAuth, isLimited } = useAuth()
  const [grade, setGrade] = useState(null)
  const [gradeErrorMsg, setGradeErrorMsg] = useState(false)
  const isFetching = useSelector(getFeedbackFetchStatus())
  // const { trackPageView, trackClickAction } = useTracking()
  const channelName = configuration.localization.brand
  const { t } = useTranslation()

  const initialValues = {
    message: '',
    firstName: '',
    lastName: '',
    email: '',
    brandType
  }

  let scoreArray = range(11)

  const validationSchema = Yup.object({
    message: Yup.string()
      .trim()
      .max(1024, t('feedbackFormMessageLimit1024'))
      .required(t('feedbackFormMessageRequired'))
      .matches(/^\S/, t('feedbackFormMessageBlankSpaces')),
    firstName: Yup.string()
      .trim()
      .required(t('editProfileValidationRequiredFirstName'))
      .matches(/^[aA-zZ\s]+$/, t('sharePageValidationTextName')),
    lastName: Yup.string()
      .trim()
      .required(t('editProfileValidationRequiredLastName'))
      .matches(/^[aA-zZ\s]+$/, t('sharePageValidationTextName')),
    email: Yup.string()
      .trim()
      .required(t('emailRequired'))
      .email(t('feedbackFormMessageInvalidEmail'))
  })

  const handleSubmit = ({ values }) => {
    dispatch(
      actions.feedbackRequest({
        ...values,
        userAgent: window.navigator.userAgent,
        grade
      })
    )
  }

  return (
    <Page
      contentClassName={styles.page}
      showHero={false}
      showGoBack={true}
      showFooter={false}
      backIconLink={`/gpt`}
    >
      <Typography type="title" className={styles.title}>
        {t([title], { channelName })}
      </Typography>
      <div className={styles.feedbackContainer}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            if (!grade && grade !== 0) {
              return
            }
            if (isAuth && !isLimited) {
              handleSubmit({ values })
            }
            setSubmitting(isFetching)
          }}
        >
          <Form>
            <TextInput
              name="firstName"
              label={t('feedbackFieldFirstName')}
              placeholder={t('feedbackFieldFirstNamePlaceholder')}
              className={styles.input}
              required
              traditional
            />
            <TextInput
              name="lastName"
              label={t('feedbackFieldLastName')}
              placeholder={t('feedbackFieldLastNamePlaceholder')}
              className={styles.input}
              required
              traditional
            />
            <TextInput
              name="email"
              label={t('feedbackFieldEmail')}
              placeholder={t('feedbackFieldEmailPlaceholder')}
              type="email"
              className={styles.input}
              required
              traditional
            />
            <Typography className={styles.formLabel}>
              {t('feedbackInstructionalText')}
            </Typography>
            <TextAreaInput
              label={''}
              placeholder={t('feedbackInstructionalTextPlaceholder')}
              name="message"
              minHeight={100}
              traditional
              className={styles.textarea}
            />
            <Typography
              className={styles.formLabel}
              style={{ marginTop: '16px' }}
            >
              {t('feedbackRatingText')}
            </Typography>
            <div
              className={styles.btnContainer}
              style={{
                borderColor: gradeErrorMsg && 'red'
              }}
            >
              {scoreArray.map((val, key) => {
                let selected = grade === val
                return (
                  <div
                    key={val}
                    className={styles.btnScale}
                    onClick={() => {
                      setGradeErrorMsg(false)
                      setGrade(val)
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        setGradeErrorMsg(false)
                        setGrade(val)
                      }
                    }}
                    style={{
                      background: selected ? '#363f72' : '#fff',
                      color: selected ? '#fff' : '#363f72'
                    }}
                  >
                    {val}
                  </div>
                )
              })}
            </div>
            <div className={styles.btnTextContainer}>
              <Typography
                className={styles.formLabel}
                style={{ width: '54px' }}
              >
                {t('notLikely')}
              </Typography>
              <Typography
                className={styles.formLabel}
                align="right"
                style={{ width: '70px', direction: 'rtl' }}
              >
                {t('feedbackFormPositiveGrade').toLowerCase()}
              </Typography>
            </div>
            {gradeErrorMsg && (
              <Typography type="error" style={{ marginTop: '3px' }}>
                {t('feedbackFormGradeRequired')}
              </Typography>
            )}
            <div className={styles.buttonStyle}>
              <FormButton
                type="submit"
                loading={isFetching}
                disabled={isFetching}
                altButtonTheme={altButtonTheme}
                onClick={() => {
                  if (!grade && grade !== 0) {
                    setGradeErrorMsg(true)
                  }
                }}
              >
                {t('inviteFormSubmit')}
              </FormButton>
            </div>
          </Form>
        </Formik>
      </div>
    </Page>
  )
}
FeedbackForm.propTypes = {
  /**
   * Form page title
   */
  title: PropTypes.string.isRequired,
  /**
   * Form page subheader
   */
  subHeader: PropTypes.string,
  /**
   * Type of feedback button
   */
  brandType: PropTypes.string.isRequired
}
export default FeedbackForm
