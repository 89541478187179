import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as soundBoardActions } from './SoundBoardReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as trackActions } from 'modules/tracking/TrackingReducer'
import { initialValues } from './config'
import { LOCATION_CHANGE } from 'connected-react-router'

/**
 * Request submit form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitFormRequest(services, { payload }) {
  try {
    yield put(trackActions.trackSubmitAction({}))
    yield call(submitSaveListRequest, services, payload)
  } catch (e) {
    yield put(soundBoardActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request save list form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* submitSaveListRequest(services, payload) {
  const SmartsService = services('SmartsService')

  try {
    const { selectedList, saveListName, filters, listId = undefined } = payload

    // Call endpoint
    const { id: uuid } = yield call([SmartsService, 'listsCreate'], {
      id: listId,
      workflow: 'ExploreSoundboard',
      parameters: {
        ExploreSoundboard: {
          lists: [selectedList]
        }
      },
      filters
    })

    // Submit Project metadata
    yield call(projectMetadataRequest, services, {
      uuid,
      reportName: saveListName
    })

    // Validate & Submit List
    yield call([SmartsService, 'validateList'], { uuid })
    // Submit List
    const { message } = yield call([SmartsService, 'submitList'], {
      uuid,
      type: 'save-list'
    })

    yield put(
      notificationActions.notificationEnqueue({
        message,
        duration: 0
      })
    )
    yield put(
      soundBoardActions.setFormState({
        ...payload,
        saveListHidden: true,
        saveListName: ''
      })
    )
    yield put(
      notificationActions.notificationEnqueue({
        message: 'List saved successfully',
        duration: 0
      })
    )
    yield put(soundBoardActions.submitFormRequestSuccess())
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(soundBoardActions.submitFormRequestFail())
    console.error(e)
  }
}

/**
 * Request project metadata form
 *
 * @param services
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<PayloadAction<undefined, string>>>|<"CALL", CallEffectDescriptor>, void, *>}
 */
function* projectMetadataRequest(services, payload) {
  const SmartsService = services('SmartsService')
  const { uuid, reportName = '' } = payload

  // Call endpoint
  yield call([SmartsService, 'setProjectMetadata'], {
    uuid,
    reportName
  })
}

function* completeRequest(services, { payload }) {
  const SmartsService = services('SmartsService')

  try {
    const { filters, selectedList } = payload
    yield put(trackActions.trackSubmitAction({}))
    // Create List
    const { id: uuid } = yield call([SmartsService, 'listsCreate'], {
      workflow: 'ExploreSoundboard',
      parameters: {
        ExploreSoundboard: {
          lists: [selectedList]
        }
      },
      filters
    })

    // Generate Charts
    const { charts, listSize, universe } = yield call(
      [SmartsService, 'listsChartsGeneration'],
      uuid
    )

    yield put(
      soundBoardActions.setFormState({
        ...payload,
        listId: uuid,
        activeStep: 'charts',
        chartsData: charts,
        listSize,
        universe
      })
    )

    yield put(soundBoardActions.submitCompleteRequestSuccess())
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: e.message,
        duration: 0
      })
    )
    yield put(soundBoardActions.submitCompleteRequestFail())
    console.error(e)
  }
}

function* resetForm(services, { payload }) {
  const { action } = payload

  if (action === 'REPLACE') {
    yield put(soundBoardActions.setFormState({ ...initialValues }))
  }
}

export default function* watchSoundBoardRequest(services) {
  yield all([
    takeEvery('SOUNDBOARD_SUBMIT_FORM_REQUEST', submitFormRequest, services),
    takeEvery('SOUNDBOARD_COMPLETE_REQUEST', completeRequest, services),
    takeEvery(LOCATION_CHANGE, resetForm, services)
  ])
}
