import Filters from './forms/Filters'
import ProjectMetadataForm from './forms/ProjectMetadataForm'
import { ShareResultsPage } from 'modules/Charts/Charts'
import ChartsForm from './forms/ChartsForm'

export const initialValues = {
  // first screen
  activeStep: 'filters',
  combinationsCountry: '',
  combinationsProfession: '',
  combinationsSpecialty: '',
  combinations: [],
  // targetSpeciality: [],
  // targetSpecialtyBoolean: false,
  type: '',
  listId: '',
  // filters
  filters: {
    practice: {
      '': true,
      countOfGroupPractice: false,
      countOfSoloPractice: false,
      countOfHospital: false
    },
    groupPractice: {
      '': true,
      '0_9': false,
      '10_49': false,
      '50_99': false,
      '100_plus': false
    },
    hospitalSize: {
      '': true,
      '0_99': false,
      '100_249': false,
      '250_499': false,
      '500_plus': false
    },
    yearsInPractice: {
      '': true,
      '1_10': false,
      '11_20': false,
      '21_30': false,
      '31_40': false,
      '41_plus': false
    },
    gender: {
      '': true,
      female: false,
      male: false
    },
    lift: {
      from: '',
      to: ''
    },
    generational: {
      '': true,
      boomers: false,
      genX: false,
      millenials: false
    },
    leadershipStature: {
      '': true,
      internationalLeader: false,
      nationalLeader: false,
      wla: false,
      recentAuthor: false
    },
    industryAffinity: {
      '': true,
      isPharmaChampion: false,
      isPharmaCordial: false,
      isPharmaShy: false
    },
    hospitalType: {
      '': true,
      no: false,
      countOfPlaceTypeHospitalCommunity: false,
      countOfPlaceTypeHospitalTeaching: false,
      countOfPlaceTypeHospitalUniversity: false
    },
    idn: {
      '': true,
      no: false,
      yes: false
    },
    propensity: {
      '': true,
      no: false,
      yes: false
    }
  },

  // second screen
  domain: '',
  clientProduct: '',
  brand: '',
  agency: '',
  client: '',
  masterProject: '',
  project: '',
  reportName: '',
  notes: '',
  defaultCountry: '',
  listMatching: false,
  targetListCreate: false,
  dedupe: false,
  postalList: false,
  targetListCreateListIds: [],
  noAssociatedListID: false,
  listSize: 0,
  universe: 0,

  // Charts
  chartsData: {},
  tableCombinations: [],
  _totalMembersCount: 0,
  _totalUniversesCount: 0
}

export const steps = {
  filters: {
    component: <Filters />
  },
  'project-metadata': {
    component: <ProjectMetadataForm />
  },
  charts: {
    component: <ChartsForm />
  },
  'share-results': {
    component: <ShareResultsPage />
  }
}
