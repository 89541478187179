import { useSelector, useDispatch } from 'react-redux'
// import { FieldArray } from 'formik'
import PropTypes from 'prop-types'
// import Icon from 'components/ui/Icon/Icon'
import Typography from '../../../components/ui/Typography'
import { RaisedButton } from 'components/Button'
import Checkbox from 'components/Checkbox'
import TextInput from 'components/TextInput'
import SpecialtyCountryTable from 'components/SpecialtyCountryTable'
import styles from './form.module.sass'
import * as compareListsSelectors from '../CompareListsSelector'
// import { getChartsObject } from 'modules/Charts/ChartsSelector'
import { actions as compareListsActions } from '../CompareListsReducer'
import Typeahead from 'modules/typeahead'
import { smartsEndpoints } from 'constants/apiConstants'

const { getFetchStatus, getFormState } = compareListsSelectors

const ProjectMetadataForm = ({ formProps }) => {
  const dispatch = useDispatch()
  const formState = useSelector(getFormState())
  const isFetching = useSelector(getFetchStatus())
  const { values, setFieldValue } = formProps
  const { activeStep, showDownloadForm } = values

  const handleCheckBoxChange = field => {
    if (field === 'limaList') {
      setFieldValue('limaList', true)
      setFieldValue('clientList', false)
    } else if (field === 'clientList') {
      setFieldValue('limaList', false)
      setFieldValue('clientList', true)
    }
  }

  const handleCancel = () => {
    dispatch(
      compareListsActions.setFormState({
        ...formState,
        showDownloadForm: !showDownloadForm,
        activeStep: 'download-form'
      })
    )
  }

  const handleBack = () => {
    dispatch(
      compareListsActions.setFormState({
        ...formState,
        ...values,
        activeStep: 'download-form'
      })
    )
  }

  const handleNext = () => {
    dispatch(
      compareListsActions.setFormState({
        ...formState,
        ...values,
        activeStep: 'specialty-country'
      })
    )
  }

  const handleTargetListChange = ({ key }) => {
    dispatch(
      compareListsActions.setFormState({
        ...formState,
        ...values,
        activeStep: 'lists-selection',
        showCharts: false,
        targetList: key
      })
    )
  }

  const handleSurveyParticipantListChange = ({ key }) => {
    dispatch(
      compareListsActions.setFormState({
        ...formState,
        ...values,
        activeStep: 'lists-selection',
        showCharts: false,
        surveyParticipantList: key
      })
    )
  }

  if (activeStep === 'specialty-country') {
    return (
      <>
        {/* <FieldArray
          name="specialtyCountry"
          render={arrayHelpers => (
            <>
              <div className={styles.specialtyContainer}>
                {values.specialtyCountry &&
                  values.specialtyCountry.length > 0 &&
                  values.specialtyCountry.map((sc, index) => (
                    <Fragment key={index}>
                      <div className={styles.specialtyCountry}>
                        <TextInput
                          label="Country"
                          className={styles.countryArray}
                          name={`specialtyCountry[${index}].country`}
                          type="text"
                          traditional
                        />
                        <TextInput
                          label="Area of medicine"
                          name={`specialtyCountry[${index}].specialty`}
                          type="text"
                          traditional
                        />
                        <TextInput
                          label="List Size"
                          className={styles.listSizeArray}
                          name={`specialtyCountry[${index}].listSize`}
                          type="number"
                          traditional
                        />
                        <TextInput
                          label="Target Sample Size"
                          name={`specialtyCountry[${index}].targetSampleSize`}
                          type="number"
                          traditional
                        />
                        <TextInput
                          label="Survey Participants"
                          name={`specialtyCountry[${index}].surveyParticipants`}
                          type="number"
                          traditional
                        />
                        <TextInput
                          label="Screened Out"
                          name={`specialtyCountry[${index}].screenedOut`}
                          type="number"
                          traditional
                        />
                        <Icon
                          icon="svg/custom/delete"
                          colorTier="action"
                          button
                          onClick={() => arrayHelpers.remove(index)}
                        />
                      </div>
                    </Fragment>
                  ))}
              </div>
              <Button
                type="button"
                onClick={() =>
                  arrayHelpers.push({
                    country: '',
                    specialty: '',
                    listSize: '',
                    targetSampleSize: '',
                    surveyParticipants: '',
                    screenedOut: ''
                  })
                }
              >
                Add Country/Specialty
              </Button>
            </>
          )}
        /> */}
        <div className={styles.listSelectionContainer}>
          <Typeahead
            formName="compare-list"
            label="Choose a Target List"
            name="targetList"
            type="text"
            className={styles.input}
            required
            traditional
            body={{
              endpoint: smartsEndpoints.listsCombinationsFetchListsEndpoint,
              parseFunction: 'optionsParser'
            }}
            onSuggestionSelected={handleTargetListChange}
          />
          <Typeahead
            formName="compare-list"
            label="Choose a Survey Participant List"
            name="surveyParticipantList"
            type="text"
            className={styles.input}
            required
            traditional
            body={{
              endpoint: smartsEndpoints.listsCombinationsFetchListsEndpoint,
              parseFunction: 'optionsParser'
            }}
            onSuggestionSelected={handleSurveyParticipantListChange}
          />
        </div>
        {showDownloadForm && (
          <div className={styles.downloadContainer}>
            <SpecialtyCountryTable
              name="specialtyCountry"
              formProps={formProps}
            />
            <div className={styles.exportContainer}>
              <Typography type="body1">Select export format</Typography>
              <Checkbox
                label="Microsoft PowerPoint (PPTX)"
                name="ppt"
                onChange={() => handleCheckBoxChange('limaList')}
              />
              <Checkbox
                label="Portable Document Format (PDF)"
                name="pdf"
                disabled
                onChange={() => handleCheckBoxChange('clientList')}
              />
            </div>
            <div className={styles.actionsContainer}>
              <RaisedButton
                hasSpinner={isFetching}
                submitting={isFetching}
                disabled={isFetching}
                fontSize="16px"
                type="button"
                onClick={handleCancel}
              >
                Cancel
              </RaisedButton>
              <RaisedButton
                hasSpinner={isFetching}
                submitting={isFetching}
                disabled={isFetching}
                fontSize="16px"
                type="button"
                onClick={handleBack}
              >
                Back
              </RaisedButton>
              <RaisedButton
                hasSpinner={isFetching}
                submitting={isFetching}
                disabled={isFetching}
                fontSize="16px"
              >
                Confirm
              </RaisedButton>
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <div className={styles.form}>
      <div className={styles.listSelectionContainer}>
        <Typeahead
          formName="compare-list"
          label="Choose a Target List"
          name="targetList"
          type="text"
          className={styles.input}
          required
          traditional
          body={{
            endpoint: smartsEndpoints.listsCombinationsFetchListsEndpoint,
            parseFunction: 'optionsParser'
          }}
          onSuggestionSelected={handleTargetListChange}
        />
        <Typeahead
          formName="compare-list"
          label="Choose a Survey Participant List"
          name="surveyParticipantList"
          type="text"
          className={styles.input}
          required
          traditional
          body={{
            endpoint: smartsEndpoints.listsCombinationsFetchListsEndpoint,
            parseFunction: 'optionsParser'
          }}
          onSuggestionSelected={handleSurveyParticipantListChange}
        />
      </div>
      {showDownloadForm && (
        <div className={styles.downloadContainer}>
          <div className={styles.checkboxContainer}>
            <Checkbox
              label="LiMA List"
              name="limaList"
              onChange={() => handleCheckBoxChange('limaList')}
            />
            <Checkbox
              label="Client List"
              name="clientList"
              onChange={() => handleCheckBoxChange('clientList')}
            />
          </div>
          <TextInput
            label="Client Name"
            name="clientName"
            type="text"
            traditional
          />
          <TextInput
            label="Client Company Name"
            name="clientCompanyName"
            type="text"
            traditional
          />
          <TextInput
            label="Project Name"
            name="projectName"
            type="text"
            traditional
          />
          <TextInput
            label="Project Number"
            name="projectNumber"
            type="text"
            traditional
          />
          <TextInput
            label="Consultant Name"
            name="consultantName"
            type="text"
            traditional
          />
          <TextInput
            label="Telephone"
            name="telephone"
            type="text"
            traditional
          />
          <TextInput label="Email" name="email" type="text" traditional />
          <TextInput
            label="Survey ID"
            name="surveyId"
            type="text"
            traditional
          />
          <div className={styles.actionsContainer}>
            <RaisedButton
              hasSpinner={isFetching}
              submitting={isFetching}
              disabled={isFetching}
              fontSize="16px"
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </RaisedButton>
            <RaisedButton
              hasSpinner={isFetching}
              submitting={isFetching}
              disabled={isFetching}
              fontSize="16px"
              onClick={handleNext}
            >
              Next
            </RaisedButton>
          </div>
        </div>
      )}
    </div>
  )
}

ProjectMetadataForm.propTypes = {
  /**
   * Form props
   */
  formProps: PropTypes.object
}

export default ProjectMetadataForm
