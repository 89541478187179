import { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  getFetchingAuthStatus,
  getIsAllowed,
  getIsAuth
} from 'modules/auth/AuthSelector'
import { actions } from 'modules/auth/AuthReducer'
import ListingLoader from 'components/ListingLoader'
import siteConfig from 'site.config.json'

const { routes } = siteConfig

/**
 * Triggers AuthSaga
 * Validates path against role table
 * @param auth
 * @param name
 * @param path
 * @param Component
 * @param componentProps
 * @param rest
 */
const AuthControl = ({
  auth = false,
  name,
  path,
  component: Component,
  componentProps,
  isAdmin,
  ...rest
}) => {
  const [termsValidation, setTermsValidation] = useState(true)
  const dispatch = useDispatch()
  const fetchingAuthStatus = useSelector(getFetchingAuthStatus())
  const getIsAllowedStatus = useSelector(getIsAllowed())
  const isAuth = useSelector(getIsAuth())

  // DISPATCH ON LOCATION OR COMP. CHANGE
  useEffect(() => {
    dispatch(actions.authRequest({ reqAuth: auth, reqPathName: path }))
    setTermsValidation(false)
  }, [dispatch, name, auth, path])

  const mainGate = props => {
    const customProps = { ...props, ...componentProps }
    // RENDER LOADER WHEN FETCHING
    if (fetchingAuthStatus || termsValidation) {
      if (isAdmin) return <div />
      return <ListingLoader />
    }

    // IF ROLE VALIDATION IS REQUIRED - IF USER WAS NOT ALLOWED REDIRECT TO LOGIN
    if (auth === true) {
      if (getIsAllowedStatus === false)
        return (
          <Redirect
            to={{
              pathname: isAuth ? '/' : routes.login.path,
              state: { loginRedirect: path, pathName: name }
            }}
          />
        )
      // RENDER PROTECTED ROUTE
      return <Component {...customProps} />
    }
    return <Component {...customProps} />
  }

  // EVAL ROUTE REQUEST
  return <Route {...rest} render={props => mainGate(props)} />
}
AuthControl.propTypes = {
  /**
   * Requested path
   */
  path: PropTypes.string.isRequired,
  /**
   * Path identifier
   */
  name: PropTypes.string.isRequired,
  /**
   * Component to render
   */
  component: PropTypes.elementType,
  /**
   * Component props
   */
  componentProps: PropTypes.object,
  /**
   * Mark true if route requires Auth Validation
   */
  auth: PropTypes.bool,
  /**
   * Mark true if route is from admin
   */
  isAdmin: PropTypes.bool
}
export default AuthControl
