import ContentLoader from 'react-content-loader'
import Page from 'components/Page'
import styles from './ListingLoader.module.sass'
import PropTypes from 'prop-types'

const ListingLoader = ({ repeat = 5 }) => {
  const loaderImage = repeat => {
    let loader = []
    for (let i = 0; i <= repeat; i++) {
      loader.push(
        <ContentLoader
          key={i}
          width={'100%'}
          height={170}
          primarycolor="#e4e4e4"
          secondarycolor="#d3d3d3"
        >
          <rect x="0" y="0" rx="3" ry="3" width="40" height="40"></rect>
          <rect x="48" y="8" rx="3" ry="3" width="85%" height="6"></rect>
          <rect x="48" y="26" rx="3" ry="3" width="52" height="6"></rect>
          <rect x="0" y="60" rx="3" ry="3" width="80%" height="10"></rect>
          <rect x="0" y="80" rx="3" ry="3" width="100%" height="6"></rect>
          <rect x="0" y="98" rx="3" ry="3" width="50%" height="6"></rect>
          <rect x="0" y="115" rx="3" ry="3" width="178" height="6"></rect>
        </ContentLoader>
      )
    }
    return <div className={styles.container}>{loader}</div>
  }

  return (
    <Page showHero={false} showFooter={false}>
      {loaderImage(repeat)}
    </Page>
  )
}
ListingLoader.propTypes = {
  /**
   * Number of times will the content loader will render
   */
  repeat: PropTypes.number
}

export default ListingLoader
