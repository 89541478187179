import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as Yup from 'yup'
import Button from 'components/Button'
import TextInput from 'components/TextInput'
import styles from './LoginWithEmail.module.sass'
import { actions as loginWithEmailActions } from './LoginWithEmailReducer'
import * as loginWithEmail from './LoginWithEmailSelector'
import Typography from '../../components/ui/Typography'
import Page from 'components/Page'
import { getIsAuth } from 'modules/auth/AuthSelector'

const { getLoginWithEmailFetchStatus } = loginWithEmail

const LoginWithEmail = () => {
  const dispatch = useDispatch()
  const loginWithEmailFetching = useSelector(getLoginWithEmailFetchStatus())
  const isAuth = useSelector(getIsAuth())

  if (isAuth) {
    return (
      <Redirect
        to={{
          pathname: '/'
        }}
      />
    )
  }

  return (
    <Page
      showHero={false}
      showGoBack={false}
      showDivider={false}
      showFooter={false}
      showMenu={false}
      showHeaderBottomBorder={false}
      headerProps={{
        className: styles.headerContainer
      }}
      isFull
    >
      <div className={styles.container}>
        <Typography
          type="title"
          style={{
            fontWeight: '300',
            fontSize: '21px',
            color: 'rgba(0,0,0,0.84)'
          }}
        >
          Sign In
        </Typography>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email('Invalid email address')
              .required('Required')
          })}
          onSubmit={async values => {
            dispatch(loginWithEmailActions.loginWithEmailRequest(values))
          }}
        >
          <Form className={styles.formContainer}>
            <TextInput
              name="email"
              type="email"
              placeholder="Email"
              required
              traditional
              className={styles.emailInput}
            />
            <div className={styles.containerMiddle}>
              <Button
                className={styles.loginBtn}
                type="submit"
                submitting={loginWithEmailFetching}
                disabled={loginWithEmailFetching}
                hasSpinner
              >
                Sign In
              </Button>
              <div className={styles.containerBottom}>
                <div>
                  <Typography type={'body1'}>
                    A log-in link will be sent to the email provided above.
                  </Typography>
                  {/* <div className={styles.formDivider}>
                    <hr />
                    <Typography type="body1">or</Typography>
                    <hr />
                  </div> */}
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </Page>
  )
}

export default LoginWithEmail
