import axios from 'axios'

const TranslationService = () => ({
  async fetchTranslation(endpoint, payload) {
    let axiosOptions = {
      url: endpoint,
      method: 'GET',
      data: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const response = await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
    return response.data.resources
  }
})

export default TranslationService
