import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames/bind'
import Button, { RaisedButton } from 'components/Button'
import Page from 'components/Page'
import { isEmpty } from 'utils'
import styles from './ListIngestion.module.sass'
import { actions as listIngestionActions } from './ListIngestionReducer'
import * as listIngestionSelectors from './ListIngestionSelector'
import validationSchema from './validationSchema'
import { initialValues, steps } from './config'

const cx = classNames.bind(styles)

const { getFormState, getFetchStatus } = listIngestionSelectors

const ListIngestion = () => {
  let initialForm = {
    ...initialValues
  }
  const [submitType, setSubmitType] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()
  const formState = useSelector(getFormState())
  const formStatePr = isEmpty(formState) ? initialForm : formState
  const { activeStep, isXls, targetListCreate } = formStatePr
  const isFetching = useSelector(getFetchStatus())
  const currentValidationSchema = validationSchema[activeStep]

  const isLastStep = activeStep === 'segment-mapping'

  const handleSubmit = (values, actions) => {
    if (!isLastStep) {
      dispatch(
        listIngestionActions.submitFormRequest({
          ...values
        })
      )
      actions.setTouched({})
      actions.setSubmitting(false)
    }
  }

  const handleListSubmit = (values, type) => {
    setSubmitType(type)
    dispatch(
      listIngestionActions.completeIngestionRequest({
        ...values,
        type
      })
    )
  }

  const handleBack = () => {
    let newStep = ''
    // eslint-disable-next-line default-case
    switch (activeStep) {
      case 'file-upload':
        history.push('/')
        break
      case 'sheet':
        newStep = 'file-upload'
        break
      case 'data-location':
        newStep = 'sheet'
        break
      case 'project-metadata':
        if (isXls) {
          newStep = 'data-location'
        } else {
          newStep = 'file-upload'
        }
        break
      case 'column-headers':
        newStep = 'project-metadata'
        break
      case 'third-party-mapping':
        newStep = 'column-headers'
        break
      case 'prior-engagement-mapping':
        newStep = 'third-party-mapping'
        break
      case 'value-mapping':
        newStep = 'prior-engagement-mapping'
        break
      case 'segment-mapping':
        if (targetListCreate) {
          newStep = 'column-headers'
        } else {
          newStep = 'value-mapping'
        }
        break
    }
    if (newStep) {
      dispatch(
        listIngestionActions.setFormState({
          ...formState,
          activeStep: newStep
        })
      )
    }
  }

  const handleReset = ({ resetForm }) => {
    resetForm()
    dispatch(
      listIngestionActions.setFormState({
        ...initialValues
      })
    )
  }

  const ActiveStep = formProps =>
    React.cloneElement(steps[activeStep].component, {
      ...steps[activeStep].component.props,
      formProps
    })

  return (
    <Page
      data-testid="listIngestion-page"
      heroTitle={'Ingest List'}
      showHero
      showGoBack
      onBackClick={handleBack}
      showDivider
      showFooter={false}
    >
      <Formik
        initialValues={formStatePr}
        validationSchema={currentValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {formProps => (
          <Form>
            <div className={styles.container}>
              <ActiveStep {...formProps} />
              <div className={styles.buttonContainer}>
                {isLastStep ? (
                  <>
                    <Button
                      data-testid="listIngestion-submitList"
                      className={cx(styles.rowButton)}
                      submitting={isFetching}
                      disabled={isFetching}
                      hasSpinner={submitType === 'save-list'}
                      onClick={() =>
                        handleListSubmit(formProps.values, 'save-list')
                      }
                    >
                      {formProps.values.targetListCreate
                        ? 'Run Target List Create'
                        : 'List Match'}
                    </Button>
                    {/* <Button
                      className={cx(styles.rowButton)}
                      submitting={isFetching}
                      disabled={isFetching}
                      hasSpinner={submitType === 'run-analysis'}
                      onClick={() =>
                        handleListSubmit(formProps.values, 'run-analysis')
                      }
                    >
                      Run Analysis
                    </Button> */}
                  </>
                ) : (
                  activeStep !== 'file-upload' && (
                    <Button
                      className={styles.button}
                      type="submit"
                      submitting={isFetching}
                      disabled={isFetching}
                      hasSpinner
                      isLimaSubmit
                    />
                  )
                )}
              </div>
              <div className={cx(styles.resetContainer)}>
                <RaisedButton
                  data-testid="listIngestion-resetForm"
                  type="button"
                  className={cx(styles.resetButton)}
                  onClick={() => handleReset(formProps)}
                  disabled={isFetching}
                >
                  Reset Form
                </RaisedButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  )
}
export default ListIngestion
