import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import FileInput from 'components/FileInput'
import Button from 'components/Button'
import HopperExportForm from './HopperExportForm'
import * as exportListActions from '../ExportListReducer'
import * as exportListSelectors from '../ExportListSelector'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import styles from './form.module.sass'
import Typography from 'components/ui/Typography/Typography'

const { getUploadingStatus } = exportListSelectors

const CreateListForm = ({ formProps }) => {
  const { values } = formProps
  const dispatch = useDispatch()
  const isUploading = useSelector(getUploadingStatus())

  const handleUpload = () => {
    // dispatch an action that triggers only the file upload
    if (values.file.length > 0) {
      if (
        values.file[0].type !== 'text/csv' &&
        values.file[0].type !== 'application/vnd.ms-excel' &&
        values.file[0].type !==
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        dispatch(
          notificationActions.notificationEnqueue({
            message:
              'Unsupported file extension, please upload a CSV, XLS or XLSX file.',
            duration: 0
          })
        )
      } else {
        dispatch(exportListActions.uploadFileRequest({ ...values }))
      }
    } else {
      dispatch(
        notificationActions.notificationEnqueue({
          message: 'No file selected.',
          duration: 0
        })
      )
    }
  }

  return (
    <>
      <Typography className={styles.fileNote}>
        **File with one column with &quot;partyid&quot; heading only.
      </Typography>
      <div className={styles.fileForm}>
        <FileInput
          id="export-list-file"
          label="File"
          name="file"
          type="text"
          required
          traditional
          accept=".csv, .xls, .xlsx"
          multiple={false}
          caption="Allowed extensions: CSV, XLS & XLSX"
          className={styles.fullRow}
        />
        <Button
          className={styles.uploadButton}
          type="button"
          backgroundColor="#4c689a"
          onClick={handleUpload}
          submitting={isUploading}
          disabled={isUploading}
          hasSpinner
        >
          Upload File
        </Button>
      </div>
      <HopperExportForm formProps={formProps} />
    </>
  )
}

CreateListForm.propTypes = {
  /**
   * Form props
   */
  formProps: PropTypes.object,
  /**
   * Reset function passed down
   */
  handleReset: PropTypes.func
}

export default CreateListForm
