import { useRef, useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import Field from '../Field'
import Icon from '../Icon'
import Ripple from '../Ripple'
import classNames from 'classnames/bind'
import uniqueId from 'lodash/uniqueId'
import SelectBase from '../SelectBase'
import modernStyles from './DropDownModern.module.sass'
import traditionalStyles from './DropDownTraditional.module.sass'

const DropDownItem = ({
  keyProp: key,
  value,
  index,
  highlighted,
  setHighlighted,
  handleItemSelected,
  handleItemMouseDown,
  themeStyles,
  setRef
}) => {
  const ref = useRef(null)
  const cx = classNames.bind(themeStyles)
  setRef(ref)
  return (
    <li
      key={index}
      ref={ref}
      onClick={() => handleItemSelected(key)}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') handleItemSelected(key)
      }}
      onMouseDown={handleItemMouseDown}
      onMouseOver={() => setHighlighted(index)}
      onFocus={() => setHighlighted(index)}
      className={cx(themeStyles.item, {
        highlighted: highlighted === index
      })}
    >
      <Ripple className={themeStyles.option}>{value}</Ripple>
    </li>
  )
}
DropDownItem.displayName = 'DropDownItem'
DropDownItem.propTypes = {
  /**
   * The item key corresponding to the value displayed
   */
  keyProp: PropTypes.string,
  /**
   * The value displayed in the item
   */
  value: PropTypes.string,
  /**
   * The item index
   */
  index: PropTypes.number,
  /**
   * The the index of a highlighted item
   */
  highlighted: PropTypes.number,
  /**
   * Set this item to be highlighted
   */
  setHighlighted: PropTypes.func,
  /**
   * Allows ref to be set and used by the parent component
   */
  setRef: PropTypes.func,
  /**
   * Called when the item is clicked
   *
   * handleItemSelected(key)
   * key - the item key which corresponds to the selected value
   */
  handleItemSelected: PropTypes.func,
  /**
   * Called for the mousedown event on the item
   */
  handleItemMouseDown: PropTypes.func,
  /*
   * The imported styles merged with any altTheme that was provided to the parent component
   */
  themeStyles: PropTypes.object
}

const Dropdown = ({
  input,
  name,
  id,
  altTheme,
  placeholder,
  className,
  traditional,
  required,
  error,
  showIcon,
  disabled,
  meta,
  inlineError,
  inputClassName,
  readOnly,
  errorAlign,
  labelTooltip,
  onChange,
  onItemSelected,
  onItemHighlighted,
  value: propValue,
  options: propOptions,
  label,
  loading,
  iconSize = 24,
  customIcon,
  ...other
}) => {
  const options = useMemo(
    () =>
      Object.keys(propOptions).map((key, index) => ({
        key,
        value: propOptions[key]
      })),
    [propOptions]
  )

  const getValueFromKey = key => {
    return propOptions[key] || ''
  }
  // For controlled value and/or dynamic options
  useEffect(() => {
    const controlledValue = getValueFromKey(input.value || propValue)
    setValue(controlledValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.value, propValue, propOptions])
  // state
  const [value, setValue] = useState('')
  const [focused, setFocused] = useState(false)
  const [collapsed, setCollapsed] = useState(true)
  const [keyBuffer, setKeyBuffer] = useState('')
  const [scrollPosition, setScrollPosition] = useState(0)
  const [highlighted, setHighlighted] = useState(0)
  const [navigating, setNavigating] = useState(false)

  // refs
  const optionRefs = {}
  const assignRefByKey = key => ref => {
    // Assign a ref to each listitem by key
    optionRefs[key] = ref
  }
  const fieldRef = useRef(null)
  const listRef = useRef(null)
  let justSelectedItem = false

  const handleFocus = event => {
    setFocused(true)
    setCollapsed(false)
    if (typeof input.onFocus === 'function') {
      input.onFocus(event)
    }
  }
  const handleBlur = () => {
    // Collapse list only if the user did not just mousedown an option
    if (!justSelectedItem) {
      if (typeof input.onBlur === 'function') {
        input.onBlur() // Omitting event to prevent redux form from setting improper value through onChange
      }
      setFocused(false)
      setCollapsed(true)
    }
  }
  const isAcceptedKeyCode = keyCode =>
    keyCode === 32 || // spacebar
    (keyCode > 46 && keyCode < 58) || // numbers
    (keyCode > 63 && keyCode < 92) || // letters
    (keyCode > 95 && keyCode < 112) || // number pad
    (keyCode > 185 && keyCode < 193) || // ;=,-./`
    (keyCode > 218 && keyCode < 223) // [\]'
  const handleKeyDown = event => {
    const { keyCode, key } = event
    switch (keyCode) {
      case 27: // Escape key
        fieldRef.current.blur()
        break
      case 40: // ArrowDown
        if (highlighted < options.length - 1) {
          setHighlighted(highlighted + 1)
          setNavigating(true)
        }
        break
      case 38: // ArrowUp
        if (highlighted > 0) {
          setHighlighted(highlighted - 1)
          setNavigating(true)
        }
        break
      case 9: // Tab
        // Works as normal
        break
      case 13: // Return key
        handleItemSelected(options[highlighted].key)
        fieldRef.current.blur()
        break
      default: // prevent field text entry
        event.preventDefault()
        if (isAcceptedKeyCode(keyCode)) {
          setKeyBuffer(`${keyBuffer}${key}`)
        }
        break
    }
  }
  const handleItemSelected = key => {
    const value = getValueFromKey(key)
    setValue(value)
    justSelectedItem = false
    handleBlur()

    if (typeof input.onChange === 'function') {
      input.onChange(key)
    }

    if (typeof onChange === 'function') {
      onChange(key)
    }

    if (typeof onItemSelected === 'function') {
      onItemSelected({
        key,
        value
      })
    }
  }
  const handleItemMouseDown = event => {
    event.stopPropagation() // Prevent second ripple effect on field when clicking option
    if (!justSelectedItem) {
      justSelectedItem = true // To prevent collapse of options before they are able to be selected
    }
  }
  const scrollToOption = optionKey => {
    const option = optionRefs[optionKey]
    if (option.current && listRef.current) {
      const optionTopPosition = option.current.offsetTop
      const listScrollPosition = listRef.current.scrollTop
      const listHeight = listRef.current.offsetHeight
      if (
        optionTopPosition - listScrollPosition > listHeight || // Highlighted option is beneath list container boundary
        optionTopPosition - listScrollPosition < 0 // Highlighted option is above list container boundary
      ) {
        setScrollPosition(optionTopPosition)
      }
    }
  }

  // Set scroll position of list to `scrollPosition` value
  useEffect(() => {
    if (listRef?.current) {
      listRef.current.scrollTop = scrollPosition
    }
    setNavigating(false)
  }, [scrollPosition, navigating])

  // Maintain visibility of highlighted option when using arrow keys to navigate options
  useEffect(() => {
    if (navigating && options[highlighted]) {
      const optionKey = options[highlighted].key
      scrollToOption(optionKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlighted, navigating])

  // Highlight and scroll to option that has value matching `keyBuffer` typed text
  useEffect(() => {
    const match =
      keyBuffer &&
      options.findIndex(
        ({ value }) =>
          value.toLowerCase().indexOf(keyBuffer.toLowerCase()) === 0 // matches beginning of option value
      )
    if (match !== '' && match >= 0) {
      setHighlighted(match)
      const optionKey = options[match].key
      setNavigating(true)
      scrollToOption(optionKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyBuffer])

  // Reset typed text keyBuffer to '' after timeout
  useEffect(() => {
    let timeout
    if (keyBuffer.length) {
      // reset is necessary
      timeout = setTimeout(() => {
        setKeyBuffer('')
      }, 1000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [keyBuffer])

  // Reset `highlighted` to 0 when option list is opened
  useEffect(() => {
    if (!collapsed) {
      setHighlighted(0)
    }
  }, [collapsed])

  // Call `onItemHighlighted` when option is highlighted
  useEffect(() => {
    if (typeof onItemHighlighted === 'function') {
      onItemHighlighted({
        index: highlighted,
        key: options[highlighted].key,
        value: options[highlighted].value
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlighted])

  const dropDownId = input.name || name || id || `dropdown-${uniqueId()}`
  const styles = traditional ? traditionalStyles : modernStyles
  const themeStyles = { ...styles, ...altTheme }
  const hasValue = value !== ''
  const { touched, error: metaError } = meta
  const hasError = Boolean((touched && metaError) || error)
  const dropdownValue =
    typeof propValue === 'string'
      ? getValueFromKey(propValue) || ''
      : typeof input.value === 'string'
      ? getValueFromKey(input.value) || ''
      : value
  return (
    <SelectBase
      hasValue={hasValue}
      isFocused={focused}
      meta={meta}
      altTheme={altTheme}
      className={className}
      traditional={traditional}
      required={required}
      error={error}
      readOnly={readOnly}
      disabled={disabled}
      errorAlign={errorAlign}
      name={dropDownId}
      id={dropDownId}
      inlineError={inlineError}
      handleFocus={handleFocus}
      labelTooltip={labelTooltip}
      label={label}
      loading={loading}
      {...other}
    >
      <>
        <Field
          {...other} // Useful to pass event handlers to Field
          id={dropDownId}
          hasError={hasError}
          isFocused={focused}
          readOnly={readOnly}
          disabled={disabled}
          altTheme={themeStyles}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          traditional={traditional}
          placeholder={placeholder}
          rightElements={
            !readOnly && (
              <Icon
                style={{
                  ...(!traditional && { paddingTop: '20px' }),
                  cursor: 'pointer',
                  outline: 'none'
                }}
                iconSize={iconSize}
                icon={
                  customIcon ||
                  (traditional
                    ? 'svg/custom/carat-d'
                    : 'svg/custom/arrow-drop-down')
                }
              />
            )
          }
          required={required}
          value={dropdownValue}
          fieldRef={fieldRef}
        />
        {!collapsed && (
          <div
            className={themeStyles.container}
            onMouseDown={handleItemMouseDown}
          >
            <ul className={themeStyles.list} ref={listRef}>
              {options.map(({ key, value }, index) => (
                <DropDownItem
                  setRef={assignRefByKey(key)}
                  key={key}
                  keyProp={key}
                  value={value}
                  index={index}
                  themeStyles={themeStyles}
                  highlighted={highlighted}
                  setHighlighted={setHighlighted}
                  handleItemSelected={handleItemSelected}
                  handleItemMouseDown={handleItemMouseDown}
                />
              ))}
            </ul>
          </div>
        )}
      </>
    </SelectBase>
  )
}
Dropdown.defaultProps = {
  meta: {},
  input: {},
  options: {},
  value: '',
  traditional: false,
  required: false,
  error: '',
  showIcon: true,
  disabled: false
}
Dropdown.displayName = 'Dropdown'
Dropdown.propTypes = {
  /**
   * The text used for the label element
   */
  label: PropTypes.string,
  /**
   * An object with key and a value that is the label to be shown for each option
   */
  options: PropTypes.PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  /**
   * Should the field use the traditional or material design style
   */
  traditional: PropTypes.bool,
  /**
   * Is the field required for form submission
   */
  required: PropTypes.bool,
  /**
   * The input ID
   */
  id: PropTypes.string,
  /**
   * The name used for the label element "for" and input "name" attributes (if not using redux form)
   */
  name: PropTypes.string,
  /**
   * Metadata about the state of this field (if using redux-form)
   */
  meta: PropTypes.object,
  /**
   * The connected input props (if using redux-form)
   */
  input: PropTypes.object,
  /**
   * An additional custom className for the input
   */
  inputClassName: PropTypes.string,
  /**
   * Current value of the input. Only when used as controlled input
   */
  value: PropTypes.string,
  /**
   * If `true`, the input is read-only
   */
  readOnly: PropTypes.bool,
  /**
   * Called when an item is selected (returns the selected key)
   */
  onChange: PropTypes.func,
  /**
   * onItemSelected({ key, value })
   *
   * Called when an item is selected (returns the item object with value and key)
   */
  onItemSelected: PropTypes.func,
  /**
   * onItemHighlighted({ index, key, value })
   *
   * Called when the highlighted suggestion changes for any reason
   */
  onItemHighlighted: PropTypes.func,
  /**
   * The placeholder text shown in the field before a value is entered (only used for traditional style)
   */
  placeholder: PropTypes.string,
  /**
   * An additional custom className
   */
  className: PropTypes.string,
  /**
   * A Tooltip component used for the label
   */
  labelTooltip: PropTypes.node,
  /**
   * Whether or not the field is disabled
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, this field shows a triangle icon to indicate dropdown options
   */
  showIcon: PropTypes.bool,
  /**
   * The error shown below the field (not used if there is a redux-form style 'meta.error' value)
   */
  error: PropTypes.node,
  /**
   * The alignment of the error
   */
  errorAlign: PropTypes.string,
  /**
   * A CSS modules style object to override default theme
   */
  altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  /**
   * A custom event for the enter key (returns the current value)
   */
  onEnterKeyDown: PropTypes.func,
  /**
   * show error message in input area and the duration for it to be dismissed
   */
  inlineError: PropTypes.string,
  /**
   * If `true`, a loading indication is displayed
   */
  loading: PropTypes.bool,
  /**
   * Dropdown icon size
   */
  iconSize: PropTypes.number,
  /**
   * Custom dropdown icon
   */
  customIcon: PropTypes.string
}

export default Dropdown
