// import { useTranslation } from 'react-i18next'
// import { Link } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

// import Footer from 'components/Footer'
// import { NavBar } from 'modules/dougall/page'

// import useAuth from 'modules/auth/useAuth'
import useMediaSize from 'hooks/useMediaSize'
import { getDisplayContainer } from 'modules/dougall/chatPage/ChatPageSelector'
import { getFetching } from 'modules/dougall/chat/ChatSelector'
import { actions as chatPageActions } from 'modules/dougall/chatPage/ChatPageReducer'
import styles from './ChatPage.module.sass'
// import siteConfig from 'site.config'

// const { menuItems } = siteConfig
const cx = classNames.bind(styles)

const ChatPage = ({
  children,
  headerClassName,
  className,
  showHeader = true,
  leftContent,
  rightContent,
  footer
}) => {
  const dispatch = useDispatch()
  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 800px)'
    }
  })
  // const { isAuth, isLimited } = useAuth()
  // const footerLinks = getRoleBasedLinks(isAuth, isLimited, footer)
  // const menuLinks = getRoleBasedLinks(isAuth, isLimited, menuItems)
  const displayContainer = useSelector(getDisplayContainer())

  const isFetching = useSelector(getFetching())

  const rightScroll = useRef(null)
  useEffect(() => {
    if (!isFetching) {
      setTimeout(() => {
        if (rightScroll?.current) {
          rightScroll.current.scrollTo({ top: 0 })
        }
      }, 500)
    }
  }, [isFetching])

  const handleCloseClick = () => {
    dispatch(chatPageActions.displayContainer({ container: 'chat' }))
  }
  return (
    <>
      {/* <div id="chatPage_header" className={cx(styles.header, headerClassName)}>
        {showHeader && <NavBar menuItems={menuLinks} />}
      </div> */}
      <div id="chatPage_container" className={cx(styles.page, className)}>
        <div className={cx(styles.leftContainer, styles.hideContainer)}>
          {mediaSize === 'small' && (
            <div className={styles.closeContainer}>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '-15px',
                  right: '0',
                  width: '45px',
                  height: '45px'
                }}
                onClick={handleCloseClick}
              >
                <CloseIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </div>
          )}
          {leftContent}
        </div>
        <div
          className={cx(styles.middleContainer, {
            hideContainer: displayContainer !== 'chat'
          })}
        >
          <div className={styles.middleChildren}>{children}</div>
          <div className={cx(styles.footer)}>{footer}</div>
        </div>
        <div
          id="listingContainer"
          ref={rightScroll}
          className={cx(styles.rightContainer, {
            hideContainer: displayContainer === 'chat'
          })}
        >
          {mediaSize === 'small' && (
            <div className={styles.closeContainer}>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '-15px',
                  right: '0',
                  width: '45px',
                  height: '45px'
                }}
                onClick={handleCloseClick}
              >
                <CloseIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </div>
          )}
          {rightContent}
        </div>
      </div>
    </>
  )
}
ChatPage.propTypes = {
  /**
   * The page children
   */
  children: PropTypes.node.isRequired,
  /**
   * A className applied to the children container element
   */
  className: PropTypes.string,
  /**
   * A className applied to the header container element
   */
  headerClassName: PropTypes.string,
  /**
   * If false Header section will not be shown
   */
  showHeader: PropTypes.bool,
  /**
   * Content to be displayed on the left panel
   */
  leftContent: PropTypes.node,
  /**
   * Content to be displayed on the right panel
   */
  rightContent: PropTypes.node,
  /**
   * Footer Component
   */
  footer: PropTypes.node
}

export default ChatPage
