import ChartsParser from './ChartsParser'

describe('Charts Parser', () => {
  const baseCase = {
    listSize: 0,
    universe: 0,
    charts: {
      affinity: [],
      demographics: [],
      digital: [],
      geographics: [],
      influence: [],
      practice: []
    }
  }

  it('should return parsed response when every category has at least one chart with data', () => {
    const entry = {
      listSize: 100316,
      universe: 0,
      charts: {
        activity_by_day_of_week: {
          title: 'Activity by Day of Week',
          tooltip:
            'Activity by Day of Week provides an analysis of the days of the week physicians engage with online content and activities supported by life-sciences companies, or with content published by peer-reviewed journals, medical schools and other institutions/organisations that form part of the LiMA Knowledge Network.',
          total: 4729337,
          percentile: true,
          rows: [
            [
              'Day',
              '% of Engagements',
              {
                role: 'annotation'
              }
            ],
            ['Sunday', 5, '5%'],
            ['Monday', 14, '14%'],
            ['Tuesday', 18, '18%'],
            ['Wednesday', 19, '19%'],
            ['Thursday', 18, '18%'],
            ['Friday', 18, '18%'],
            ['Saturday', 9, '9%']
          ]
        },
        activity_by_device: null,
        activity_by_os: null,
        activity_by_time_of_day: null,
        authorship: {
          title: 'LiMA Recent Authors',
          tooltip:
            'LiMA Recent Authors are LiMA Members of Interest who have published one or more articles in at least one of the 3,500+ peer-reviewed LiMA-Scored Journals within the past 10 years.',
          total: 18928,
          percentile: true,
          rows: [
            {
              0: 'Region',
              1: 'Percent',
              2: {
                role: 'annotation'
              },
              3: 'Percent',
              4: {
                role: 'annotation'
              }
            },
            {
              0: 'Recent Authors',
              1: 5,
              2: '5%',
              3: 36,
              4: '36%'
            }
          ]
        },
        city_leaders: null,
        gender: {
          title: 'LiMA Members by Gender',
          tooltip:
            'The gender of LiMA Members of Interest, as provided by the members or obtained from other LiMA sources.',
          total: 99021,
          percentile: true,
          rows: [
            {
              0: 'Gender',
              1: 'Percent',
              2: {
                role: 'annotation'
              },
              3: 'Percent',
              4: {
                role: 'annotation'
              }
            },
            {
              0: 'Male',
              1: 55,
              2: '55%',
              3: 83,
              4: '83%'
            },
            {
              0: 'Female',
              1: 45,
              2: '45%',
              3: 17,
              4: '17%'
            }
          ]
        },
        group_practice_members: null,
        hospital_size_beds: null,
        hospital_type: null,
        idns: {
          title: 'LiMA Members Affiliated with an IDN, by IDN',
          tooltip:
            'An integrated delivery network (IDN), or health system, is an organization that owns and operates a network of one or more healthcare facilities.',
          total: 3,
          percentile: false,
          rows: [
            {
              0: 'IDN Affiliation',
              1: 'Count',
              2: {
                role: 'annotation'
              },
              3: 'Count',
              4: {
                role: 'annotation'
              }
            },
            {
              0: 'Trinity Health',
              1: 0,
              2: '0',
              3: 1,
              4: '1'
            },
            {
              0: 'Northwestern Medicine',
              1: 0,
              2: '0',
              3: 1,
              4: '1'
            },
            {
              0: 'Lehigh Valley Health Network',
              1: 0,
              2: '0',
              3: 1,
              4: '1'
            },
            {
              0: 'LCMC Health System',
              1: 0,
              2: '0',
              3: 1,
              4: '1'
            },
            {
              0: 'Grand View Hospital',
              1: 0,
              2: '0',
              3: 1,
              4: '1'
            },
            {
              0: 'Geisinger Health System',
              1: 0,
              2: '0',
              3: 1,
              4: '1'
            }
          ]
        },
        industry_affinity_by_quintile: null,
        lima_metropolitan_city: null,
        mdm_country: null,
        pharma_affinity: {
          title: 'Industry Affinity Segment',
          tooltip:
            'Membership in Industry Affinity Segments is assessed based on participation in industry-supported activities and/or transfers of value to physicians from life-sciences companies',
          total: 39993,
          percentile: true,
          rows: [
            {
              0: 'Affinity',
              1: 'Percent',
              2: {
                role: 'annotation'
              },
              3: 'Percent',
              4: {
                role: 'annotation'
              }
            },
            {
              0: 'Pharma Champion',
              1: 0,
              2: '0%',
              3: 13,
              4: '13%'
            },
            {
              0: 'Pharma Cordial',
              1: 0,
              2: '0%',
              3: 53,
              4: '53%'
            },
            {
              0: 'Pharma Shy',
              1: 100,
              2: '100%',
              3: 34,
              4: '34%'
            }
          ]
        },
        practice: null,
        recent_publication_date: {
          title: 'LiMA Recent Authors by Most Recent Article',
          tooltip:
            'LiMA Recent Authors whose most recent article dates back to: less than a year; more than a year and up to three years; more than three years and up to five years; more than five years and up to ten years.',
          total: 18814,
          percentile: true,
          rows: [
            {
              0: 'Time',
              1: 'Percent',
              2: {
                role: 'annotation'
              },
              3: 'Percent',
              4: {
                role: 'annotation'
              }
            },
            {
              0: '<13 Months',
              1: 31,
              2: '31%',
              3: 52,
              4: '52%'
            },
            {
              0: '13-36 Months',
              1: 27,
              2: '27%',
              3: 22,
              4: '22%'
            },
            {
              0: '37-60 Months',
              1: 17,
              2: '17%',
              3: 11,
              4: '11%'
            },
            {
              0: '61-120 Months',
              1: 26,
              2: '26%',
              3: 16,
              4: '16%'
            }
          ]
        },
        stature: {
          title: 'LiMA Members by Leadership Stature',
          tooltip:
            'The three levels of LIMA Leadership Stature are: International Leader, National Leader, and Workplace Leader. These levels are designed to reflect the geographic scope of a LiMA Leader&apos;s influence on the practice and clinical advancement of medicine. LIMA Leadership Stature Levels are nested such that International Leaders are also deemed to be National Leaders, and National Leaders, in turn, also deemed to be Workplace Leaders.',
          total: 19339,
          percentile: true,
          rows: [
            {
              0: '% of LiMA Members',
              1: 'International',
              2: 'National',
              3: 'Workplace',
              4: 'Recent Authors',
              5: {
                role: 'annotation'
              }
            },
            {
              0: 'International',
              1: null,
              2: null,
              3: null,
              4: null,
              5: null
            },
            {
              0: 'Targets',
              1: 27,
              2: null,
              3: null,
              4: null,
              5: '27%'
            },
            {
              0: 'Participants',
              1: 46,
              2: null,
              3: null,
              4: null,
              5: '46%'
            },
            {
              0: null,
              1: null,
              2: null,
              3: null,
              4: null,
              5: null
            },
            {
              0: 'National',
              1: null,
              2: null,
              3: null,
              4: null,
              5: null
            },
            {
              0: 'Targets',
              1: 27,
              2: 22,
              3: null,
              4: null,
              5: '22%'
            },
            {
              0: 'Participants',
              1: 46,
              2: 21,
              3: null,
              4: null,
              5: '21%'
            },
            {
              0: null,
              1: null,
              2: null,
              3: null,
              4: null,
              5: null
            },
            {
              0: 'Workplace',
              1: null,
              2: null,
              3: null,
              4: null,
              5: null
            },
            {
              0: 'Targets',
              1: 27,
              2: 22,
              3: 17,
              4: null,
              5: '17%'
            },
            {
              0: 'Participants',
              1: 46,
              2: 21,
              3: 17,
              4: null,
              5: '17%'
            },
            {
              0: null,
              1: null,
              2: null,
              3: null,
              4: null,
              5: null
            },
            {
              0: 'Recent Authors',
              1: null,
              2: null,
              3: null,
              4: null,
              5: null
            },
            {
              0: 'Targets',
              1: 27,
              2: 22,
              3: 17,
              4: 34,
              5: '34%'
            },
            {
              0: 'Participants',
              1: 46,
              2: 21,
              3: 17,
              4: 16,
              5: '16%'
            },
            {
              0: null,
              1: null,
              2: null,
              3: null,
              4: null,
              5: null
            }
          ],
          cumulative: true
        },
        urban_suburban_rural: {
          title: 'LiMA Members by Location Setting',
          tooltip:
            'The setting in which LiMA Members of Interest practice, based on population size, including large metropolitan (>1.5M), metropolitan (500K-1.5M), medium urban (200-500K), small urban (50-200K) and town/village (<50K) settings.',
          total: 93944,
          percentile: true,
          rows: [
            {
              0: 'Area',
              1: '% of Location Settings',
              2: {
                role: 'annotation'
              },
              3: '% of Location Settings',
              4: {
                role: 'annotation'
              }
            },
            {
              0: 'Large metro',
              1: 58,
              2: '58%',
              3: 63,
              4: '63%'
            },
            {
              0: 'Metro',
              1: 18,
              2: '18%',
              3: 22,
              4: '22%'
            },
            {
              0: 'Medium urban',
              1: 13,
              2: '13%',
              3: 15,
              4: '15%'
            },
            {
              0: 'Small urban',
              1: 13,
              2: '13%',
              3: 16,
              4: '16%'
            },
            {
              0: 'Town/village',
              1: 5,
              2: '5%',
              3: 6,
              4: '6%'
            }
          ]
        },
        years_in_practice: {
          title: 'LiMA Members by Years of Practice',
          tooltip:
            'Years of Practice represent the number of years spent in active practice, as provided by LiMA Members of Interest, or calculated based on their year of graduation.',
          total: 39722,
          percentile: true,
          rows: [
            {
              0: 'Years',
              1: 'Percent',
              2: {
                role: 'annotation'
              },
              3: 'Percent',
              4: {
                role: 'annotation'
              }
            },
            {
              0: '1-10',
              1: 6,
              2: '6%',
              3: 10,
              4: '10%'
            },
            {
              0: '11-20',
              1: 20,
              2: '20%',
              3: 22,
              4: '22%'
            },
            {
              0: '21-30',
              1: 20,
              2: '20%',
              3: 19,
              4: '19%'
            },
            {
              0: '31-40',
              1: 17,
              2: '17%',
              3: 22,
              4: '22%'
            },
            {
              0: '41+',
              1: 38,
              2: '38%',
              3: 27,
              4: '27%'
            }
          ]
        }
      }
      // Can have more properties like:
      //  uuid: '9c078636-ad84-4097-9535-c77a7847c3e1',
      //  filters: {...},
      //  originalSize: 100316,
      //  marketSelection: {...},
      //  tooltips: {...}
      // But will be ignored
    }

    const expected = {
      listSize: 100316,
      universe: 0,
      charts: {
        affinity: [
          {
            name: 'pharma_affinity',
            title: 'Industry Affinity Segment',
            tooltip:
              'Membership in Industry Affinity Segments is assessed based on participation in industry-supported activities and/or transfers of value to physicians from life-sciences companies',
            height: '155px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'Industry Affinity Segment'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: 'Percent'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: ['Pharma Champion', 'Pharma Cordial', 'Pharma Shy'],
              datasets: [
                {
                  label: 'Percent',
                  data: [0, 0, 100],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          }
        ],
        demographics: [
          {
            name: 'years_in_practice',
            title: 'LiMA Members by Years of Practice',
            tooltip:
              'Years of Practice represent the number of years spent in active practice, as provided by LiMA Members of Interest, or calculated based on their year of graduation.',
            height: '185px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'LiMA Members by Years of Practice'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: 'Percent'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: ['1-10', '11-20', '21-30', '31-40', '41+'],
              datasets: [
                {
                  label: 'Percent',
                  data: [6, 20, 20, 17, 38],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          },
          {
            name: 'gender',
            title: 'LiMA Members by Gender',
            tooltip:
              'The gender of LiMA Members of Interest, as provided by the members or obtained from other LiMA sources.',
            height: '110px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'LiMA Members by Gender'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: 'Percent'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: ['Male', 'Female'],
              datasets: [
                {
                  label: 'Percent',
                  data: [55, 45],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          }
        ],
        digital: [
          {
            chartData: {
              datasets: [
                {
                  backgroundColor: ['rgb(37, 172, 137)'],
                  data: [5, 14, 18, 19, 18, 18, 9],
                  label: '% of Engagements'
                }
              ],
              labels: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday'
              ]
            },
            height: '215px',
            name: 'activity_by_day_of_week',
            options: {
              barPercentage: 0.7,
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              indexAxis: 'y',
              maintainAspectRatio: false,
              plugins: {
                datalabels: {
                  align: 'end',
                  anchor: 'end',
                  display: false,
                  offset: 10
                },
                legend: {
                  display: false
                },
                subtitle: {
                  align: 'center',
                  display: true,
                  fullSize: false,
                  position: 'bottom',
                  text: '% of Engagements'
                },
                title: {
                  display: false,
                  text: 'Activity by Day of Week'
                },
                tooltip: {
                  callbacks: {}
                }
              },
              responsive: true,
              scales: {
                x: {
                  max: 100,
                  min: 0,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            title: 'Activity by Day of Week',
            tooltip:
              'Activity by Day of Week provides an analysis of the days of the week physicians engage with online content and activities supported by life-sciences companies, or with content published by peer-reviewed journals, medical schools and other institutions/organisations that form part of the LiMA Knowledge Network.',
            yAxis: undefined
          }
        ],
        geographics: [
          {
            name: 'urban_suburban_rural',
            title: 'LiMA Members by Location Setting',
            tooltip:
              'The setting in which LiMA Members of Interest practice, based on population size, including large metropolitan (>1.5M), metropolitan (500K-1.5M), medium urban (200-500K), small urban (50-200K) and town/village (<50K) settings.',
            height: '185px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'LiMA Members by Location Setting'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: '% of Location Settings'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: [
                'Large metro',
                'Metro',
                'Medium urban',
                'Small urban',
                'Town/village'
              ],
              datasets: [
                {
                  label: '% of Location Settings',
                  data: [58, 18, 13, 13, 5],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          }
        ],
        influence: [
          {
            name: 'authorship',
            title: 'LiMA Recent Authors',
            tooltip:
              'LiMA Recent Authors are LiMA Members of Interest who have published one or more articles in at least one of the 3,500+ peer-reviewed LiMA-Scored Journals within the past 10 years.',
            height: '110px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'LiMA Recent Authors'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: 'Percent'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: ['Recent Authors'],
              datasets: [
                {
                  label: 'Percent',
                  data: [5],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          },
          {
            name: 'recent_publication_date',
            title: 'LiMA Recent Authors by Most Recent Article',
            tooltip:
              'LiMA Recent Authors whose most recent article dates back to: less than a year; more than a year and up to three years; more than three years and up to five years; more than five years and up to ten years.',
            height: '170px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'LiMA Recent Authors by Most Recent Article'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: 'Percent'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: [
                '<13 Months',
                '13-36 Months',
                '37-60 Months',
                '61-120 Months'
              ],
              datasets: [
                {
                  label: 'Percent',
                  data: [31, 27, 17, 26],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          },
          {
            name: 'stature',
            title: 'LiMA Members by Leadership Stature',
            tooltip:
              'The three levels of LIMA Leadership Stature are: International Leader, National Leader, and Workplace Leader. These levels are designed to reflect the geographic scope of a LiMA Leader&apos;s influence on the practice and clinical advancement of medicine. LIMA Leadership Stature Levels are nested such that International Leaders are also deemed to be National Leaders, and National Leaders, in turn, also deemed to be Workplace Leaders.',
            height: '350px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'LiMA Members by Leadership Stature'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: '% of LiMA Members'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'left',
                  offset: 4,
                  color: 'white'
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  stacked: true,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  },
                  stacked: true
                }
              }
            },
            chartData: {
              labels: [
                'International',
                'Targets',
                'Participants',
                '',
                'National',
                'Targets',
                'Participants',
                '',
                'Workplace',
                'Targets',
                'Participants',
                '',
                'Recent Authors',
                'Targets',
                'Participants',
                ''
              ],
              datasets: [
                {
                  label: 'International',
                  data: [
                    null,
                    27,
                    46,
                    null,
                    null,
                    27,
                    46,
                    null,
                    null,
                    27,
                    46,
                    null,
                    null,
                    27,
                    46,
                    null
                  ],
                  backgroundColor: 'rgb(48, 39, 116)'
                },
                {
                  label: 'National',
                  data: [
                    null,
                    null,
                    null,
                    null,
                    null,
                    22,
                    21,
                    null,
                    null,
                    22,
                    21,
                    null,
                    null,
                    22,
                    21,
                    null
                  ],
                  backgroundColor: 'rgb(126, 177, 216)'
                },
                {
                  label: 'Workplace',
                  data: [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    17,
                    17,
                    null,
                    null,
                    17,
                    17,
                    null
                  ],
                  backgroundColor: 'rgb(104, 96, 170)'
                },
                {
                  label: 'Recent Authors',
                  data: [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    34,
                    16,
                    null
                  ],
                  backgroundColor: 'rgb(110, 110, 110)'
                }
              ]
            }
          }
        ],
        practice: [
          {
            name: 'idns',
            title: 'LiMA Members Affiliated with an IDN, by IDN',
            tooltip:
              'An integrated delivery network (IDN), or health system, is an organization that owns and operates a network of one or more healthcare facilities.',
            height: '200px',
            options: {
              maintainAspectRatio: false,
              aspectRatio: 2,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'LiMA Members Affiliated with an IDN, by IDN'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: 'Count'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 3,
                  precision: 0,
                  ticks: {
                    stepSize: 0.75
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: [
                'Trinity Health',
                'Northwestern Medicine',
                'Lehigh Valley Health Network',
                'LCMC Health System',
                'Grand View Hospital',
                'Geisinger Health System'
              ],
              datasets: [
                {
                  label: 'Count',
                  data: [0, 0, 0, 0, 0, 0],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          }
        ]
      }
    }

    expect(ChartsParser(entry)).toMatchObject(expected)
  })

  it('should return base case when nothing is received', () => {
    expect(ChartsParser(null)).toMatchObject(baseCase)

    expect(ChartsParser()).toMatchObject(baseCase)
  })

  it('should return base case when an empty object is received', () => {
    const entry = {}

    expect(ChartsParser(entry)).toMatchObject(baseCase)
  })

  it('should return base case when no charts attribute is received', () => {
    const entry = {
      listSize: 0,
      universe: 0
    }

    expect(ChartsParser(entry)).toMatchObject(baseCase)

    entry.charts = null

    expect(ChartsParser(entry)).toMatchObject(baseCase)

    entry.charts = {}

    expect(ChartsParser(entry)).toMatchObject(baseCase)
  })

  it('should return listSize and universe with value 0 when these attributes are not received', () => {
    const entry = {
      charts: {}
    }

    expect(ChartsParser(entry)).toMatchObject(baseCase)

    entry.listSize = null
    entry.universe = null

    expect(ChartsParser(entry)).toMatchObject(baseCase)
  })

  it('should return base case when all charts are null', () => {
    const entry = {
      listSize: 0,
      universe: 0,
      charts: {
        activity_by_day_of_week: null,
        activity_by_device: null,
        activity_by_os: null,
        activity_by_time_of_day: null,
        authorship: null,
        city_leaders: null,
        gender: null,
        group_practice_members: null,
        hospital_size_beds: null,
        hospital_type: null,
        idns: null,
        industry_affinity_by_quintile: null,
        lima_metropolitan_city: null,
        mdm_country: null,
        pharma_affinity: null,
        practice: null,
        recent_publication_date: null,
        stature: null,
        urban_suburban_rural: null,
        years_in_practice: null
      }
    }

    expect(ChartsParser(entry)).toMatchObject(baseCase)
  })

  it('should return parsed response even when just one chart has data', () => {
    const entry = {
      listSize: 0,
      universe: 0,
      charts: {
        activity_by_day_of_week: null,
        activity_by_device: null,
        activity_by_os: null,
        activity_by_time_of_day: null,
        authorship: {
          title: 'LiMA Recent Authors',
          tooltip:
            'LiMA Recent Authors are LiMA Members of Interest who have published one or more articles in at least one of the 3,500+ peer-reviewed LiMA-Scored Journals within the past 10 years.',
          total: 18928,
          percentile: true,
          rows: [
            {
              0: 'Region',
              1: 'Percent',
              2: {
                role: 'annotation'
              },
              3: 'Percent',
              4: {
                role: 'annotation'
              }
            },
            {
              0: 'Recent Authors',
              1: 5,
              2: '5%',
              3: 36,
              4: '36%'
            }
          ]
        },
        city_leaders: null,
        gender: null,
        group_practice_members: null,
        hospital_size_beds: null,
        hospital_type: null,
        idns: null,
        industry_affinity_by_quintile: null,
        lima_metropolitan_city: null,
        mdm_country: null,
        pharma_affinity: null,
        practice: null,
        recent_publication_date: null,
        stature: null,
        urban_suburban_rural: null,
        years_in_practice: null
      }
    }

    const expected = {
      listSize: 0,
      universe: 0,
      charts: {
        affinity: [],
        demographics: [],
        digital: [],
        geographics: [],
        influence: [
          {
            name: 'authorship',
            title: 'LiMA Recent Authors',
            tooltip:
              'LiMA Recent Authors are LiMA Members of Interest who have published one or more articles in at least one of the 3,500+ peer-reviewed LiMA-Scored Journals within the past 10 years.',
            height: '110px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'LiMA Recent Authors'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: 'Percent'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: ['Recent Authors'],
              datasets: [
                {
                  label: 'Percent',
                  data: [5],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          }
        ],
        practice: []
      }
    }

    expect(ChartsParser(entry)).toMatchObject(expected)
  })

  it('should return parsed response even when all charts have data', () => {
    const entry = {
      listSize: 100316,
      universe: 0,
      charts: {
        activity_by_day_of_week: {
          title: 'Activity by Day of Week',
          tooltip:
            'Activity by Day of Week provides an analysis of the days of the week physicians engage with online content and activities supported by life-sciences companies, or with content published by peer-reviewed journals, medical schools and other institutions/organisations that form part of the LiMA Knowledge Network.',
          total: 4729337,
          percentile: true,
          rows: [
            [
              'Day',
              '% of Engagements',
              {
                role: 'annotation'
              }
            ],
            ['Sunday', 5, '5%'],
            ['Monday', 14, '14%'],
            ['Tuesday', 18, '18%'],
            ['Wednesday', 19, '19%'],
            ['Thursday', 18, '18%'],
            ['Friday', 18, '18%'],
            ['Saturday', 9, '9%']
          ]
        },
        activity_by_device: {
          title: 'Device Usage',
          tooltip:
            'Device Usage provides an analysis of the devices physicians use to engage with online content and activities supported by life-sciences companies, or with content published by peer-reviewed journals, medical schools and other institutions/organisations that form part of the LiMA Knowledge Network.',
          total: 1908353,
          percentile: true,
          rows: [
            [
              'Device',
              '% of Engagements',
              {
                role: 'annotation'
              }
            ],
            ['Mac/PC', 40, '40%'],
            ['Smartphone', 58, '58%'],
            ['Tablet', 2, '2%']
          ]
        },
        activity_by_os: {
          title: 'Operating System Usage',
          tooltip:
            'Operating System Usage provides an analysis of the operating system running on the devices physicians use to engage with online content and activities supported by life-sciences companies, or with content published by peer-reviewed journals, medical schools and other institutions/organisations that form part of the LiMA Knowledge Network.',
          total: 1875013,
          percentile: true,
          rows: [
            [
              'OS',
              '% of Engagements',
              {
                role: 'annotation'
              }
            ],
            ['iOS', 90, '90%'],
            ['Mac', 0, '0%'],
            ['Windows', 10, '10%'],
            ['Android', 0, '0%']
          ]
        },
        activity_by_time_of_day: {
          title: 'Activity by Time of Day',
          tooltip:
            'Activity by Time of Day provides an analysis of the time of day physicians engage with online content and activities supported by life-sciences companies,or with content published by peer-reviewed journals, medical schools and other institutions/organisations that form part of the LiMA Knowledge Network.',
          total: 4729337,
          percentile: true,
          rows: [
            [
              'Hour',
              '% of Engagements',
              {
                role: 'annotation'
              }
            ],
            ['18:00 - 23:59', 35, '35%'],
            ['15:00 - 17:59', 18, '18%'],
            ['12:00 - 14:59', 14, '14%'],
            ['09:00 - 11:59', 10, '10%'],
            ['06:00 - 08:59', 6, '6%'],
            ['00:00 - 05:59', 18, '18%']
          ],
          yAxis: 'Local Time'
        },
        authorship: {
          title: 'LiMA Recent Authors',
          tooltip:
            'LiMA Recent Authors are LiMA Members of Interest who have published one or more articles in at least one of the 3,500+ peer-reviewed LiMA-Scored Journals within the past 10 years.',
          total: 18928,
          percentile: true,
          rows: [
            {
              0: 'Region',
              1: 'Percent',
              2: {
                role: 'annotation'
              },
              3: 'Percent',
              4: {
                role: 'annotation'
              }
            },
            {
              0: 'Recent Authors',
              1: 5,
              2: '5%',
              3: 36,
              4: '36%'
            }
          ]
        },
        city_leaders: {
          title: 'LiMA City Leaders by Gender',
          tooltip:
            'The gender of LiMA Members of Interest, as provided by the members or obtained from other LiMA sources.',
          total: 99021,
          percentile: true,
          rows: [
            {
              0: 'Gender',
              1: 'Percent',
              2: {
                role: 'annotation'
              },
              3: 'Percent',
              4: {
                role: 'annotation'
              }
            },
            {
              0: 'Male',
              1: 55,
              2: '55%',
              3: 83,
              4: '83%'
            },
            {
              0: 'Female',
              1: 45,
              2: '45%',
              3: 17,
              4: '17%'
            }
          ]
        },
        gender: {
          title: 'LiMA Members by Gender',
          tooltip:
            'The gender of LiMA Members of Interest, as provided by the members or obtained from other LiMA sources.',
          total: 99021,
          percentile: true,
          rows: [
            {
              0: 'Gender',
              1: 'Percent',
              2: {
                role: 'annotation'
              },
              3: 'Percent',
              4: {
                role: 'annotation'
              }
            },
            {
              0: 'Male',
              1: 55,
              2: '55%',
              3: 83,
              4: '83%'
            },
            {
              0: 'Female',
              1: 45,
              2: '45%',
              3: 17,
              4: '17%'
            }
          ]
        },
        group_practice_members: {
          title: 'LiMA Group Practice Members by Gender',
          tooltip:
            'The gender of LiMA Members of Interest, as provided by the members or obtained from other LiMA sources.',
          total: 99021,
          percentile: true,
          rows: [
            {
              0: 'Gender',
              1: 'Percent',
              2: {
                role: 'annotation'
              },
              3: 'Percent',
              4: {
                role: 'annotation'
              }
            },
            {
              0: 'Male',
              1: 55,
              2: '55%',
              3: 83,
              4: '83%'
            },
            {
              0: 'Female',
              1: 45,
              2: '45%',
              3: 17,
              4: '17%'
            }
          ]
        },
        hospital_size_beds: {
          title: 'Beds by Hospital Type',
          tooltip:
            'Hospital type refers to whether a member practices in one or more teaching hospitals and/or one or more community hospitals. When a hospital&apos;s association with a university is not known, it is assumed to be a community hospital until proof that it is a medical school hospital is found by the LiMA team.',
          total: 26105,
          percentile: true,
          rows: [
            [
              'Hospital Type',
              '% of Hospital Physicians',
              {
                role: 'annotation'
              }
            ],
            ['University', 47, '47%'],
            ['Teaching', 67, '67%'],
            ['Community', 36, '36%']
          ]
        },
        hospital_type: {
          title: 'Physicians by Hospital Type',
          tooltip:
            'Hospital type refers to whether a member practices in one or more teaching hospitals and/or one or more community hospitals. When a hospital&apos;s association with a university is not known, it is assumed to be a community hospital until proof that it is a medical school hospital is found by the LiMA team.',
          total: 26105,
          percentile: true,
          rows: [
            [
              'Hospital Type',
              '% of Hospital Physicians',
              {
                role: 'annotation'
              }
            ],
            ['University', 47, '47%'],
            ['Teaching', 67, '67%'],
            ['Community', 36, '36%']
          ]
        },
        idns: {
          title: 'LiMA Members Affiliated with an IDN, by IDN',
          tooltip:
            'An integrated delivery network (IDN), or health system, is an organization that owns and operates a network of one or more healthcare facilities.',
          total: 3,
          percentile: false,
          rows: [
            {
              0: 'IDN Affiliation',
              1: 'Count',
              2: {
                role: 'annotation'
              },
              3: 'Count',
              4: {
                role: 'annotation'
              }
            },
            {
              0: 'Trinity Health',
              1: 0,
              2: '0',
              3: 1,
              4: '1'
            },
            {
              0: 'Northwestern Medicine',
              1: 0,
              2: '0',
              3: 1,
              4: '1'
            },
            {
              0: 'Lehigh Valley Health Network',
              1: 0,
              2: '0',
              3: 1,
              4: '1'
            },
            {
              0: 'LCMC Health System',
              1: 0,
              2: '0',
              3: 1,
              4: '1'
            },
            {
              0: 'Grand View Hospital',
              1: 0,
              2: '0',
              3: 1,
              4: '1'
            },
            {
              0: 'Geisinger Health System',
              1: 0,
              2: '0',
              3: 1,
              4: '1'
            }
          ]
        },
        industry_affinity_by_quintile: {
          title: 'Physicians by Key LiMA Cities',
          tooltip:
            'Key LiMA Cities are the cities with the largest medical communities and LiMA City Leader presence in any given country.',
          total: 41996,
          percentile: false,
          rows: [
            [
              'City',
              '# of Physicians',
              {
                role: 'annotation'
              }
            ],
            ['New York', 4368, '4,368'],
            ['Los Angeles', 1662, '1,662'],
            ['Philadelphia', 1567, '1,567'],
            ['Chicago', 1562, '1,562'],
            ['Boston', 1468, '1,468'],
            ['Miami', 1081, '1,081'],
            ['Washington', 1016, '1,016'],
            ['Houston', 987, '987'],
            ['Atlanta', 792, '792'],
            ['Dallas', 782, '782'],
            ['Detroit', 757, '757'],
            ['San Francisco', 691, '691'],
            ['Baltimore', 641, '641'],
            ['Cleveland', 615, '615'],
            ['Rochester', 581, '581'],
            ['Phoenix', 564, '564'],
            ['St. Louis', 561, '561'],
            ['Minneapolis', 532, '532'],
            ['Seattle', 531, '531'],
            ['Pittsburgh', 522, '522']
          ]
        },
        lima_metropolitan_city: {
          title: 'Physicians by Key LiMA Cities',
          tooltip:
            'Key LiMA Cities are the cities with the largest medical communities and LiMA City Leader presence in any given country.',
          total: 41996,
          percentile: false,
          rows: [
            [
              'City',
              '# of Physicians',
              {
                role: 'annotation'
              }
            ],
            ['New York', 4368, '4,368'],
            ['Los Angeles', 1662, '1,662'],
            ['Philadelphia', 1567, '1,567'],
            ['Chicago', 1562, '1,562'],
            ['Boston', 1468, '1,468'],
            ['Miami', 1081, '1,081'],
            ['Washington', 1016, '1,016'],
            ['Houston', 987, '987'],
            ['Atlanta', 792, '792'],
            ['Dallas', 782, '782'],
            ['Detroit', 757, '757'],
            ['San Francisco', 691, '691'],
            ['Baltimore', 641, '641'],
            ['Cleveland', 615, '615'],
            ['Rochester', 581, '581'],
            ['Phoenix', 564, '564'],
            ['St. Louis', 561, '561'],
            ['Minneapolis', 532, '532'],
            ['Seattle', 531, '531'],
            ['Pittsburgh', 522, '522']
          ]
        },
        mdm_country: {
          title: 'Industry Affinity Country',
          tooltip:
            'Membership in Industry Affinity Segments is assessed based on participation in industry-supported activities and/or transfers of value to physicians from life-sciences companies',
          total: 39993,
          percentile: true,
          rows: [
            {
              0: 'Affinity',
              1: 'Percent',
              2: {
                role: 'annotation'
              },
              3: 'Percent',
              4: {
                role: 'annotation'
              }
            },
            {
              0: 'Pharma Champion',
              1: 0,
              2: '0%',
              3: 13,
              4: '13%'
            },
            {
              0: 'Pharma Cordial',
              1: 0,
              2: '0%',
              3: 53,
              4: '53%'
            },
            {
              0: 'Pharma Shy',
              1: 100,
              2: '100%',
              3: 34,
              4: '34%'
            }
          ]
        },
        pharma_affinity: {
          title: 'Industry Affinity Segment',
          tooltip:
            'Membership in Industry Affinity Segments is assessed based on participation in industry-supported activities and/or transfers of value to physicians from life-sciences companies',
          total: 39993,
          percentile: true,
          rows: [
            {
              0: 'Affinity',
              1: 'Percent',
              2: {
                role: 'annotation'
              },
              3: 'Percent',
              4: {
                role: 'annotation'
              }
            },
            {
              0: 'Pharma Champion',
              1: 0,
              2: '0%',
              3: 13,
              4: '13%'
            },
            {
              0: 'Pharma Cordial',
              1: 0,
              2: '0%',
              3: 53,
              4: '53%'
            },
            {
              0: 'Pharma Shy',
              1: 100,
              2: '100%',
              3: 34,
              4: '34%'
            }
          ]
        },
        practice: {
          title: 'Physicians by Practice Type',
          tooltip:
            'Practice type refers to whether a member practices in a solo practice, a group practice, a hospital practice or more than one of these.',
          total: 35496,
          percentile: true,
          rows: [
            [
              'Practice Type',
              '% of Physicians',
              {
                role: 'annotation'
              }
            ],
            ['Hospital', 77, '77%'],
            ['Group Practice', 68, '68%'],
            ['Solo Practice', 9, '9%']
          ]
        },
        recent_publication_date: {
          title: 'LiMA Recent Authors by Most Recent Article',
          tooltip:
            'LiMA Recent Authors whose most recent article dates back to: less than a year; more than a year and up to three years; more than three years and up to five years; more than five years and up to ten years.',
          total: 18814,
          percentile: true,
          rows: [
            {
              0: 'Time',
              1: 'Percent',
              2: {
                role: 'annotation'
              },
              3: 'Percent',
              4: {
                role: 'annotation'
              }
            },
            {
              0: '<13 Months',
              1: 31,
              2: '31%',
              3: 52,
              4: '52%'
            },
            {
              0: '13-36 Months',
              1: 27,
              2: '27%',
              3: 22,
              4: '22%'
            },
            {
              0: '37-60 Months',
              1: 17,
              2: '17%',
              3: 11,
              4: '11%'
            },
            {
              0: '61-120 Months',
              1: 26,
              2: '26%',
              3: 16,
              4: '16%'
            }
          ]
        },
        stature: {
          title: 'LiMA Members by Leadership Stature',
          tooltip:
            'The three levels of LIMA Leadership Stature are: International Leader, National Leader, and Workplace Leader. These levels are designed to reflect the geographic scope of a LiMA Leader&apos;s influence on the practice and clinical advancement of medicine. LIMA Leadership Stature Levels are nested such that International Leaders are also deemed to be National Leaders, and National Leaders, in turn, also deemed to be Workplace Leaders.',
          total: 19339,
          percentile: true,
          rows: [
            {
              0: '% of LiMA Members',
              1: 'International',
              2: 'National',
              3: 'Workplace',
              4: 'Recent Authors',
              5: {
                role: 'annotation'
              }
            },
            {
              0: 'International',
              1: null,
              2: null,
              3: null,
              4: null,
              5: null
            },
            {
              0: 'Targets',
              1: 27,
              2: null,
              3: null,
              4: null,
              5: '27%'
            },
            {
              0: 'Participants',
              1: 46,
              2: null,
              3: null,
              4: null,
              5: '46%'
            },
            {
              0: null,
              1: null,
              2: null,
              3: null,
              4: null,
              5: null
            },
            {
              0: 'National',
              1: null,
              2: null,
              3: null,
              4: null,
              5: null
            },
            {
              0: 'Targets',
              1: 27,
              2: 22,
              3: null,
              4: null,
              5: '22%'
            },
            {
              0: 'Participants',
              1: 46,
              2: 21,
              3: null,
              4: null,
              5: '21%'
            },
            {
              0: null,
              1: null,
              2: null,
              3: null,
              4: null,
              5: null
            },
            {
              0: 'Workplace',
              1: null,
              2: null,
              3: null,
              4: null,
              5: null
            },
            {
              0: 'Targets',
              1: 27,
              2: 22,
              3: 17,
              4: null,
              5: '17%'
            },
            {
              0: 'Participants',
              1: 46,
              2: 21,
              3: 17,
              4: null,
              5: '17%'
            },
            {
              0: null,
              1: null,
              2: null,
              3: null,
              4: null,
              5: null
            },
            {
              0: 'Recent Authors',
              1: null,
              2: null,
              3: null,
              4: null,
              5: null
            },
            {
              0: 'Targets',
              1: 27,
              2: 22,
              3: 17,
              4: 34,
              5: '34%'
            },
            {
              0: 'Participants',
              1: 46,
              2: 21,
              3: 17,
              4: 16,
              5: '16%'
            },
            {
              0: null,
              1: null,
              2: null,
              3: null,
              4: null,
              5: null
            }
          ],
          cumulative: true
        },
        urban_suburban_rural: {
          title: 'LiMA Members by Location Setting',
          tooltip:
            'The setting in which LiMA Members of Interest practice, based on population size, including large metropolitan (>1.5M), metropolitan (500K-1.5M), medium urban (200-500K), small urban (50-200K) and town/village (<50K) settings.',
          total: 93944,
          percentile: true,
          rows: [
            {
              0: 'Area',
              1: '% of Location Settings',
              2: {
                role: 'annotation'
              },
              3: '% of Location Settings',
              4: {
                role: 'annotation'
              }
            },
            {
              0: 'Large metro',
              1: 58,
              2: '58%',
              3: 63,
              4: '63%'
            },
            {
              0: 'Metro',
              1: 18,
              2: '18%',
              3: 22,
              4: '22%'
            },
            {
              0: 'Medium urban',
              1: 13,
              2: '13%',
              3: 15,
              4: '15%'
            },
            {
              0: 'Small urban',
              1: 13,
              2: '13%',
              3: 16,
              4: '16%'
            },
            {
              0: 'Town/village',
              1: 5,
              2: '5%',
              3: 6,
              4: '6%'
            }
          ]
        },
        years_in_practice: {
          title: 'LiMA Members by Years of Practice',
          tooltip:
            'Years of Practice represent the number of years spent in active practice, as provided by LiMA Members of Interest, or calculated based on their year of graduation.',
          total: 39722,
          percentile: true,
          rows: [
            {
              0: 'Years',
              1: 'Percent',
              2: {
                role: 'annotation'
              },
              3: 'Percent',
              4: {
                role: 'annotation'
              }
            },
            {
              0: '1-10',
              1: 6,
              2: '6%',
              3: 10,
              4: '10%'
            },
            {
              0: '11-20',
              1: 20,
              2: '20%',
              3: 22,
              4: '22%'
            },
            {
              0: '21-30',
              1: 20,
              2: '20%',
              3: 19,
              4: '19%'
            },
            {
              0: '31-40',
              1: 17,
              2: '17%',
              3: 22,
              4: '22%'
            },
            {
              0: '41+',
              1: 38,
              2: '38%',
              3: 27,
              4: '27%'
            }
          ]
        }
      }
    }

    const expected = {
      listSize: 100316,
      universe: 0,
      charts: {
        affinity: [
          {
            name: 'industry_affinity_by_quintile',
            title: 'Physicians by Key LiMA Cities',
            tooltip:
              'Key LiMA Cities are the cities with the largest medical communities and LiMA City Leader presence in any given country.',
            height: '410px',
            options: {
              maintainAspectRatio: false,
              aspectRatio: 2,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'Physicians by Key LiMA Cities'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: '# of Physicians'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 6000,
                  precision: 0,
                  ticks: {
                    stepSize: 1500
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: [
                'New York',
                'Los Angeles',
                'Philadelphia',
                'Chicago',
                'Boston',
                'Miami',
                'Washington',
                'Houston',
                'Atlanta',
                'Dallas',
                'Detroit',
                'San Francisco',
                'Baltimore',
                'Cleveland',
                'Rochester',
                'Phoenix',
                'St. Louis',
                'Minneapolis',
                'Seattle',
                'Pittsburgh'
              ],
              datasets: [
                {
                  label: '# of Physicians',
                  data: [
                    4368, 1662, 1567, 1562, 1468, 1081, 1016, 987, 792, 782,
                    757, 691, 641, 615, 581, 564, 561, 532, 531, 522
                  ],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          },
          {
            name: 'pharma_affinity',
            title: 'Industry Affinity Segment',
            tooltip:
              'Membership in Industry Affinity Segments is assessed based on participation in industry-supported activities and/or transfers of value to physicians from life-sciences companies',
            height: '155px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'Industry Affinity Segment'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: 'Percent'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: ['Pharma Champion', 'Pharma Cordial', 'Pharma Shy'],
              datasets: [
                {
                  label: 'Percent',
                  data: [0, 0, 100],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          }
        ],
        demographics: [
          {
            name: 'years_in_practice',
            title: 'LiMA Members by Years of Practice',
            tooltip:
              'Years of Practice represent the number of years spent in active practice, as provided by LiMA Members of Interest, or calculated based on their year of graduation.',
            height: '185px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'LiMA Members by Years of Practice'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: 'Percent'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: ['1-10', '11-20', '21-30', '31-40', '41+'],
              datasets: [
                {
                  label: 'Percent',
                  data: [6, 20, 20, 17, 38],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          },
          {
            name: 'gender',
            title: 'LiMA Members by Gender',
            tooltip:
              'The gender of LiMA Members of Interest, as provided by the members or obtained from other LiMA sources.',
            height: '110px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'LiMA Members by Gender'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: 'Percent'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: ['Male', 'Female'],
              datasets: [
                {
                  label: 'Percent',
                  data: [55, 45],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          }
        ],
        digital: [
          {
            name: 'activity_by_device',
            title: 'Device Usage',
            tooltip:
              'Device Usage provides an analysis of the devices physicians use to engage with online content and activities supported by life-sciences companies, or with content published by peer-reviewed journals, medical schools and other institutions/organisations that form part of the LiMA Knowledge Network.',
            height: '155px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'Device Usage'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: '% of Engagements'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: ['Mac/PC', 'Smartphone', 'Tablet'],
              datasets: [
                {
                  label: '% of Engagements',
                  data: [40, 58, 2],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          },
          {
            name: 'activity_by_os',
            title: 'Operating System Usage',
            tooltip:
              'Operating System Usage provides an analysis of the operating system running on the devices physicians use to engage with online content and activities supported by life-sciences companies, or with content published by peer-reviewed journals, medical schools and other institutions/organisations that form part of the LiMA Knowledge Network.',
            height: '170px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'Operating System Usage'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: '% of Engagements'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: ['iOS', 'Mac', 'Windows', 'Android'],
              datasets: [
                {
                  label: '% of Engagements',
                  data: [90, 0, 10, 0],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          },
          {
            name: 'activity_by_day_of_week',
            title: 'Activity by Day of Week',
            tooltip:
              'Activity by Day of Week provides an analysis of the days of the week physicians engage with online content and activities supported by life-sciences companies, or with content published by peer-reviewed journals, medical schools and other institutions/organisations that form part of the LiMA Knowledge Network.',
            height: '215px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'Activity by Day of Week'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: '% of Engagements'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday'
              ],
              datasets: [
                {
                  label: '% of Engagements',
                  data: [5, 14, 18, 19, 18, 18, 9],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          },
          {
            name: 'activity_by_time_of_day',
            title: 'Activity by Time of Day',
            tooltip:
              'Activity by Time of Day provides an analysis of the time of day physicians engage with online content and activities supported by life-sciences companies,or with content published by peer-reviewed journals, medical schools and other institutions/organisations that form part of the LiMA Knowledge Network.',
            yAxis: 'Local Time',
            height: '200px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'Activity by Time of Day'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: '% of Engagements'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: [
                '18:00 - 23:59',
                '15:00 - 17:59',
                '12:00 - 14:59',
                '09:00 - 11:59',
                '06:00 - 08:59',
                '00:00 - 05:59'
              ],
              datasets: [
                {
                  label: '% of Engagements',
                  data: [35, 18, 14, 10, 6, 18],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          }
        ],
        geographics: [
          {
            name: 'lima_metropolitan_city',
            title: 'Physicians by Key LiMA Cities',
            tooltip:
              'Key LiMA Cities are the cities with the largest medical communities and LiMA City Leader presence in any given country.',
            height: '410px',
            options: {
              maintainAspectRatio: false,
              aspectRatio: 2,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'Physicians by Key LiMA Cities'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: '# of Physicians'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 6000,
                  precision: 0,
                  ticks: {
                    stepSize: 1500
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: [
                'New York',
                'Los Angeles',
                'Philadelphia',
                'Chicago',
                'Boston',
                'Miami',
                'Washington',
                'Houston',
                'Atlanta',
                'Dallas',
                'Detroit',
                'San Francisco',
                'Baltimore',
                'Cleveland',
                'Rochester',
                'Phoenix',
                'St. Louis',
                'Minneapolis',
                'Seattle',
                'Pittsburgh'
              ],
              datasets: [
                {
                  label: '# of Physicians',
                  data: [
                    4368, 1662, 1567, 1562, 1468, 1081, 1016, 987, 792, 782,
                    757, 691, 641, 615, 581, 564, 561, 532, 531, 522
                  ],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          },
          {
            name: 'mdm_country',
            title: 'Industry Affinity Country',
            tooltip:
              'Membership in Industry Affinity Segments is assessed based on participation in industry-supported activities and/or transfers of value to physicians from life-sciences companies',
            height: '155px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'Industry Affinity Country'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: 'Percent'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: ['Pharma Champion', 'Pharma Cordial', 'Pharma Shy'],
              datasets: [
                {
                  label: 'Percent',
                  data: [0, 0, 100],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          },
          {
            name: 'urban_suburban_rural',
            title: 'LiMA Members by Location Setting',
            tooltip:
              'The setting in which LiMA Members of Interest practice, based on population size, including large metropolitan (>1.5M), metropolitan (500K-1.5M), medium urban (200-500K), small urban (50-200K) and town/village (<50K) settings.',
            height: '185px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'LiMA Members by Location Setting'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: '% of Location Settings'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: [
                'Large metro',
                'Metro',
                'Medium urban',
                'Small urban',
                'Town/village'
              ],
              datasets: [
                {
                  label: '% of Location Settings',
                  data: [58, 18, 13, 13, 5],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          }
        ],
        influence: [
          {
            name: 'authorship',
            title: 'LiMA Recent Authors',
            tooltip:
              'LiMA Recent Authors are LiMA Members of Interest who have published one or more articles in at least one of the 3,500+ peer-reviewed LiMA-Scored Journals within the past 10 years.',
            height: '110px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'LiMA Recent Authors'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: 'Percent'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: ['Recent Authors'],
              datasets: [
                {
                  label: 'Percent',
                  data: [5],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          },
          {
            name: 'city_leaders',
            title: 'LiMA City Leaders by Gender',
            tooltip:
              'The gender of LiMA Members of Interest, as provided by the members or obtained from other LiMA sources.',
            height: '110px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'LiMA City Leaders by Gender'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: 'Percent'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: ['Male', 'Female'],
              datasets: [
                {
                  label: 'Percent',
                  data: [55, 45],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          },
          {
            name: 'recent_publication_date',
            title: 'LiMA Recent Authors by Most Recent Article',
            tooltip:
              'LiMA Recent Authors whose most recent article dates back to: less than a year; more than a year and up to three years; more than three years and up to five years; more than five years and up to ten years.',
            height: '170px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'LiMA Recent Authors by Most Recent Article'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: 'Percent'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: [
                '<13 Months',
                '13-36 Months',
                '37-60 Months',
                '61-120 Months'
              ],
              datasets: [
                {
                  label: 'Percent',
                  data: [31, 27, 17, 26],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          },
          {
            name: 'stature',
            title: 'LiMA Members by Leadership Stature',
            tooltip:
              'The three levels of LIMA Leadership Stature are: International Leader, National Leader, and Workplace Leader. These levels are designed to reflect the geographic scope of a LiMA Leader&apos;s influence on the practice and clinical advancement of medicine. LIMA Leadership Stature Levels are nested such that International Leaders are also deemed to be National Leaders, and National Leaders, in turn, also deemed to be Workplace Leaders.',
            height: '350px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'LiMA Members by Leadership Stature'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: '% of LiMA Members'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'left',
                  offset: 4,
                  color: 'white'
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  stacked: true,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  },
                  stacked: true
                }
              }
            },
            chartData: {
              labels: [
                'International',
                'Targets',
                'Participants',
                '',
                'National',
                'Targets',
                'Participants',
                '',
                'Workplace',
                'Targets',
                'Participants',
                '',
                'Recent Authors',
                'Targets',
                'Participants',
                ''
              ],
              datasets: [
                {
                  label: 'International',
                  data: [
                    null,
                    27,
                    46,
                    null,
                    null,
                    27,
                    46,
                    null,
                    null,
                    27,
                    46,
                    null,
                    null,
                    27,
                    46,
                    null
                  ],
                  backgroundColor: 'rgb(48, 39, 116)'
                },
                {
                  label: 'National',
                  data: [
                    null,
                    null,
                    null,
                    null,
                    null,
                    22,
                    21,
                    null,
                    null,
                    22,
                    21,
                    null,
                    null,
                    22,
                    21,
                    null
                  ],
                  backgroundColor: 'rgb(126, 177, 216)'
                },
                {
                  label: 'Workplace',
                  data: [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    17,
                    17,
                    null,
                    null,
                    17,
                    17,
                    null
                  ],
                  backgroundColor: 'rgb(104, 96, 170)'
                },
                {
                  label: 'Recent Authors',
                  data: [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    34,
                    16,
                    null
                  ],
                  backgroundColor: 'rgb(110, 110, 110)'
                }
              ]
            }
          }
        ],
        practice: [
          {
            name: 'practice',
            title: 'Physicians by Practice Type',
            tooltip:
              'Practice type refers to whether a member practices in a solo practice, a group practice, a hospital practice or more than one of these.',
            height: '155px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'Physicians by Practice Type'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: '% of Physicians'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: ['Hospital', 'Group Practice', 'Solo Practice'],
              datasets: [
                {
                  label: '% of Physicians',
                  data: [77, 68, 9],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          },
          {
            name: 'hospital_type',
            title: 'Physicians by Hospital Type',
            tooltip:
              'Hospital type refers to whether a member practices in one or more teaching hospitals and/or one or more community hospitals. When a hospital&apos;s association with a university is not known, it is assumed to be a community hospital until proof that it is a medical school hospital is found by the LiMA team.',
            height: '155px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'Physicians by Hospital Type'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: '% of Hospital Physicians'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: ['University', 'Teaching', 'Community'],
              datasets: [
                {
                  label: '% of Hospital Physicians',
                  data: [47, 67, 36],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          },
          {
            name: 'hospital_size_beds',
            title: 'Beds by Hospital Type',
            tooltip:
              'Hospital type refers to whether a member practices in one or more teaching hospitals and/or one or more community hospitals. When a hospital&apos;s association with a university is not known, it is assumed to be a community hospital until proof that it is a medical school hospital is found by the LiMA team.',
            height: '155px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'Beds by Hospital Type'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: '% of Hospital Physicians'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: ['University', 'Teaching', 'Community'],
              datasets: [
                {
                  label: '% of Hospital Physicians',
                  data: [47, 67, 36],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          },
          {
            name: 'group_practice_members',
            title: 'LiMA Group Practice Members by Gender',
            tooltip:
              'The gender of LiMA Members of Interest, as provided by the members or obtained from other LiMA sources.',
            height: '110px',
            options: {
              maintainAspectRatio: false,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'LiMA Group Practice Members by Gender'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: 'Percent'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 100,
                  ticks: {
                    stepSize: 25
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: ['Male', 'Female'],
              datasets: [
                {
                  label: 'Percent',
                  data: [55, 45],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          },
          {
            name: 'idns',
            title: 'LiMA Members Affiliated with an IDN, by IDN',
            tooltip:
              'An integrated delivery network (IDN), or health system, is an organization that owns and operates a network of one or more healthcare facilities.',
            height: '200px',
            options: {
              maintainAspectRatio: false,
              aspectRatio: 2,
              barPercentage: 0.7,
              indexAxis: 'y',
              elements: {
                bar: {
                  borderWidth: 0
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false,
                  text: 'LiMA Members Affiliated with an IDN, by IDN'
                },
                subtitle: {
                  display: true,
                  position: 'bottom',
                  fullSize: false,
                  align: 'center',
                  text: 'Count'
                },
                datalabels: {
                  display: false,
                  anchor: 'end',
                  align: 'end',
                  offset: 10
                },
                tooltip: {
                  callbacks: {}
                }
              },
              scales: {
                x: {
                  min: 0,
                  max: 3,
                  precision: 0,
                  ticks: {
                    stepSize: 0.75
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            },
            chartData: {
              labels: [
                'Trinity Health',
                'Northwestern Medicine',
                'Lehigh Valley Health Network',
                'LCMC Health System',
                'Grand View Hospital',
                'Geisinger Health System'
              ],
              datasets: [
                {
                  label: 'Count',
                  data: [0, 0, 0, 0, 0, 0],
                  backgroundColor: ['rgb(37, 172, 137)']
                }
              ]
            }
          }
        ]
      }
    }

    expect(ChartsParser(entry)).toMatchObject(expected)
  })
})
