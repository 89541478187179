import { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import Card from 'components/Admin/Card/Card'
import DataTable from 'react-data-table-component'
import { Drawer } from '@material-ui/core'
import ConfirmationModal from 'components/Admin/ConfirmationModal'
import { CircularProgress } from 'components/ui/Progress'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DropdownInput from 'components/DropdownInput'
import Typography from 'components/ui/Typography'
import styles from './form.module.sass'
import Button from 'components/Admin/Button'
import Typeahead from 'modules/typeahead'
import Icon from 'components/ui/Icon'
import * as thresholdSelectors from '../ThresholdSelector'
import * as thresholdActions from '../ThresholdReducer'
import { isEmpty } from 'utils'
import { smartsEndpoints } from 'constants/apiConstants'
import classNames from 'classnames/bind'

import useRoleFeatures from 'modules/auth/useRoleFeatures'
const cx = classNames.bind(styles)

const {
  getIsFetching,
  getSearchState,
  getThresholdObject,
  getPagination,
  getThresholdFetching,
  getSingleThreshold,
  getModalOpen,
  getDeleteFetching,
  getSelected
} = thresholdSelectors

const SearchForm = () => {
  let initialForm = {
    country: '',
    // profession: '',
    // specialty: '',
    orderBy: '',
    order: '',
    page: 1,
    perPage: 25,
    countryEdit: '',
    value: 0,
    id: null
  }

  const dispatch = useDispatch()
  const { features } = useRoleFeatures({
    coin: 'admin-users'
  })
  const roleSchema = features?.sections?.actions || []
  const [firstVisit, setFirstVisit] = useState(true)
  const [clearInput, setClearInput] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const formState = useSelector(getSearchState())
  let formStatePr = isEmpty(formState) ? initialForm : formState
  const [initialValues, setInitialValues] = useState(formStatePr)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const isFetching = useSelector(getIsFetching())
  const data = useSelector(getThresholdObject())
  const pagination = useSelector(getPagination())
  const isThresholdFetching = useSelector(getThresholdFetching())
  const currentThreshold = useSelector(getSingleThreshold())
  const modalOpen = useSelector(getModalOpen())
  const isDeleteFetching = useSelector(getDeleteFetching())
  const selected = useSelector(getSelected())
  const routerHistory = useHistory()

  useEffect(() => {
    if (firstVisit && !isFetching) {
      dispatch(
        thresholdActions.searchRequest({
          search: {
            country: '',
            // profession: '',
            // specialty: '',
            orderBy: '',
            order: '',
            page: 1,
            perPage: 25
          }
        })
      )
    }
    setFirstVisit(false)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (deleted && !isDeleteFetching) {
      dispatch(
        thresholdActions.searchRequest({
          search: {
            country: '',
            // profession: '',
            // specialty: '',
            orderBy: '',
            order: '',
            page: 1,
            perPage: 25
          }
        })
      )
      setDeleted(false)
    }
    // eslint-disable-next-line
  }, [isDeleteFetching])

  const columns = [
    {
      name: 'id',
      selector: row => row.id,
      sortField: 'id',
      sortable: true,
      cell: row => {
        return <Typography>{row.id}</Typography>
      }
    },
    {
      name: 'Country',
      selector: row => row.country,
      sortField: 'country',
      sortable: true,
      cell: row => {
        return <Typography>{row.country}</Typography>
      }
    },
    // {
    //   name: 'Profession',
    //   selector: row => row.profession,
    //   sortField: 'profession',
    //   sortable: true,
    //   cell: row => {
    //     return <Typography>{row.profession}</Typography>
    //   }
    // },
    // {
    //   name: 'Specialty',
    //   selector: row => row.specialty,
    //   sortField: 'specialty',
    //   sortable: true,
    //   cell: row => {
    //     return <Typography>{row.specialty}</Typography>
    //   }
    // },
    {
      name: 'Value',
      selector: row => row.value,
      sortField: 'value',
      sortable: false,
      cell: row => {
        return <Typography>{row.value}</Typography>
      }
    },
    {
      name: 'View',
      sortable: false,
      maxWidth: '15px',
      omit: !roleSchema.includes('view') && !roleSchema.includes('edit'),
      cell: row => {
        return (
          <Icon
            icon="svg/custom/visibility"
            className={styles.tableIcon}
            onClick={() => handleView(row.id)}
            button
          />
        )
      }
    },
    // {
    //   name: 'Edit',
    //   sortable: false,
    //   maxWidth: '15px',
    //   omit: !roleSchema.includes('edit'),
    //   cell: row => {
    //     return (
    //       <Icon
    //         icon="svg/custom/edit"
    //         className={styles.tableIcon}
    //         onClick={() => handleEdit(row)}
    //         button
    //       />
    //     )
    //   }
    // },
    {
      name: 'Delete',
      sortable: false,
      maxWidth: '15px',
      omit: !roleSchema.includes('invalidate'),
      cell: row => {
        return (
          <Icon
            icon="svg/material-design-icons/action/delete"
            className={styles.tableIcon}
            onClick={() => handleModalOpen(row.id)}
            button
          />
        )
      }
    }
  ]
  const handlePageChange = page => {
    dispatch(
      thresholdActions.searchRequest({
        search: {
          ...formStatePr,
          page: page
        }
      })
    )
  }
  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(
      thresholdActions.searchRequest({
        search: {
          ...formStatePr,
          page,
          perPage: newPerPage
        }
      })
    )
  }
  const handleSort = ({ sortField: selector }, sortDirection) => {
    dispatch(
      thresholdActions.searchRequest({
        search: {
          ...formStatePr,
          orderBy: selector,
          order: sortDirection
        }
      })
    )
  }
  const handleSubmit = values => {
    dispatch(
      thresholdActions.searchRequest({
        search: {
          ...values,
          orderBy: '',
          order: '',
          page: 1
        }
      })
    )
  }

  const handleReset = formProps => {
    dispatch(
      thresholdActions.searchRequest({
        search: {
          country: '',
          // profession: '',
          // specialty: '',
          orderBy: '',
          order: '',
          page: 1,
          perPage: 25
        }
      })
    )
    setClearInput(!clearInput)
    setInitialValues(initialForm)
  }

  const handleView = id => {
    if (id !== currentThreshold.id) {
      dispatch(thresholdActions.fetchThresholdRequest({ id }))
    }

    setDrawerOpen(true)
  }

  const handleModalOpen = id => {
    dispatch(thresholdActions.setSelected({ id }))
    dispatch(thresholdActions.setModalOpen({ modalOpen: true }))
  }

  const handleModalClose = () => {
    dispatch(thresholdActions.setSelected({ id: null }))
    dispatch(thresholdActions.setModalOpen({ modalOpen: false }))
  }

  const handleDelete = () => {
    dispatch(thresholdActions.deleteThresholdRequest({ selected }))
    setDeleted(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  const handleEdit = currentThreshold => {
    dispatch(
      thresholdActions.setEditThreshold({
        id: currentThreshold.id,
        countryEdit: currentThreshold.country,
        value: currentThreshold.value
      })
    )
    routerHistory.push(`/admin/threshold/${currentThreshold.id}`)
  }

  const handleCreate = () => {
    routerHistory.push(`/admin/threshold/create`)
  }

  return (
    <Formik
      key={clearInput}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {formProps => (
        <Form>
          {roleSchema.includes('edit') && (
            <div className={styles.actionContainer}>
              <Button
                text="Add"
                textCase="none"
                disabled={isFetching}
                type="button"
                onClick={() => handleCreate()}
              />
            </div>
          )}

          <div className={styles.searchContainer}>
            <Card className={cx(styles.card, styles.searchCard)}>
              <Typography
                type="title"
                className={styles.cardTitle}
                color="text-primary"
              >
                Search Criteria
              </Typography>
              <div className={styles.searchForm}>
                <Typeahead
                  formName="admin-create-user"
                  label="Country"
                  name="country"
                  type="text"
                  traditional={false}
                  body={{
                    data: {
                      search: {
                        perPage: 100
                      }
                    },
                    endpoint: smartsEndpoints.variationCountries,
                    parseFunction: 'optionsParser'
                  }}
                />
                <DropdownInput
                  className={styles.disabled}
                  label="Profession"
                  name="profession"
                  //   options={professionList}
                  disabled
                  traditional={false}
                  customIcon="svg/custom/arrow-drop-down"
                />
                <DropdownInput
                  className={styles.disabled}
                  label="Specialty"
                  name="specialty"
                  //   options={sortConstants(specialtyList)}
                  disabled
                  traditional={false}
                  customIcon="svg/custom/arrow-drop-down"
                />
              </div>
              <div className={styles.actionContainer}>
                <Button
                  className={styles.resetButton}
                  text="Reset"
                  textCase="none"
                  disabled={false}
                  type="button"
                  backgroundColor="#d3d3d3"
                  onClick={() => handleReset(formProps)}
                />
                <Button
                  text="Search"
                  textCase="none"
                  disabled={isFetching}
                  type="submit"
                />
              </div>
            </Card>
            <Card className={styles.card}>
              <Typography
                type="title"
                className={styles.cardTitle}
                color="text-primary"
              >
                Threshold Results
              </Typography>
              <DataTable
                columns={columns}
                data={data}
                keyField="key"
                progressPending={isFetching}
                pagination
                paginationServer
                paginationTotalRows={pagination.total}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                sortServer
                persistTableHead
                paginationPerPage={pagination.perPage}
                paginationRowsPerPageOptions={[15, 25, 35, 45, 55, 85, 150]}
                progressComponent={
                  <div className={styles.loadingComponent}>
                    <CircularProgress
                      fillColor="transparent"
                      size={64}
                      strokeWidth={4}
                    />
                  </div>
                }
              />
            </Card>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={handleDrawerClose}
            >
              <div className={styles.thresholdContainer}>
                {isThresholdFetching ? (
                  <div className={styles.thresholdProgress}>
                    <CircularProgress
                      fillColor="transparent"
                      size={64}
                      strokeWidth={4}
                    />
                  </div>
                ) : (
                  <>
                    <div className={styles.thresholdInfo}>
                      <Typography className={styles.thresholdTitle}>
                        Threshold{' '}
                      </Typography>

                      <Typography className={styles.thresholdDescription}>
                        ID:{' '}
                      </Typography>
                      <Typography className={styles.thresholdData}>
                        {currentThreshold?.id}
                      </Typography>

                      <Typography className={styles.thresholdDescription}>
                        Country:{' '}
                      </Typography>
                      <Typography className={styles.thresholdData}>
                        {currentThreshold?.country}
                      </Typography>

                      {/*
                    <Typography className={styles.thresholdDescription}>
                      Profession:{' '}
                    </Typography>
                    <Typography className={styles.thresholdData}>
                      {currentThreshold?.profession}
                    </Typography>

                    <Typography className={styles.thresholdDescription}>
                      Specialty:{' '}
                    </Typography>
                    <Typography className={styles.thresholdData}>
                      {currentThreshold?.specialty}
                    </Typography>
                    */}

                      <Typography className={styles.thresholdDescription}>
                        Value:{' '}
                      </Typography>
                      <Typography className={styles.thresholdData}>
                        {currentThreshold?.value}
                      </Typography>
                    </div>
                    <div className={styles.thresholdEditButton}>
                      <Button
                        text="Edit"
                        textCase="none"
                        disabled={isFetching}
                        type="button"
                        onClick={() => handleEdit(currentThreshold)}
                      />
                    </div>
                  </>
                )}
              </div>
            </Drawer>
          </div>
          <ConfirmationModal
            message={`Are you sure you want to delete the threshold with id ${selected}`}
            open={modalOpen}
            onConfirm={handleDelete}
            onCancel={handleModalClose}
            actionType="delete"
            actionCaption="Delete"
            submitting={isDeleteFetching}
          />
        </Form>
      )}
    </Formik>
  )
}

export default SearchForm
