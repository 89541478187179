import PropTypes from 'prop-types'
import SwitchBase from '../SwitchBase'

const Checkbox = props => {
  return <SwitchBase type="checkbox" {...props} />
}
Checkbox.displayName = 'Checkbox'
Checkbox.propTypes = {
  type: PropTypes.string
}
export default Checkbox
