import { createReducer, createAction } from '@reduxjs/toolkit'

export const setFormState = createAction('DATA_CACHES_SET_FORM_STATE')
export const requestDataCaches = createAction('DATA_CACHES_REQUEST')
export const requestDataCachesSuccess = createAction(
  'DATA_CACHES_REQUEST_SUCCESS'
)
export const requestDataCachesFail = createAction('DATA_CACHES_REQUEST_FAIL')

export const clearFunctionalDataCachesRequest = createAction(
  'DATA_CACHES_CLEAR_FUNCTIONAL_REQUEST'
)
export const clearFunctionalDataCachesRequestSuccess = createAction(
  'DATA_CACHES_CLEAR_FUNCTIONAL_REQUEST_SUCCESS'
)
export const clearFunctionalDataCachesRequestFail = createAction(
  'DATA_CACHES_CLEAR_FUNCTIONAL_REQUEST_FAIL'
)
export const clearIndividualDataCachesRequest = createAction(
  'DATA_CACHES_CLEAR_INDIVIDUAL_REQUEST'
)
export const clearIndividualDataCachesRequestSuccess = createAction(
  'DATA_CACHES_CLEAR_INDIVIDUAL_REQUEST_SUCCESS'
)
export const clearIndividualDataCachesRequestFail = createAction(
  'DATA_CACHES_CLEAR_INDIVIDUAL_REQUEST_FAIL'
)
export const rebuildDataCachesRequest = createAction(
  'DATA_CACHES_REBUILD_REQUEST'
)
export const rebuildDataCachesRequestSuccess = createAction(
  'DATA_CACHES_REBUILD_REQUEST_SUCCESS'
)
export const rebuildDataCachesRequestFail = createAction(
  'DATA_CACHES_REBUILD_REQUEST_FAIL'
)

export const deleteDataCachesRequest = createAction(
  'DATA_CACHES_DELETE_REQUEST'
)
export const deleteDataCachesRequestSuccess = createAction(
  'DATA_CACHES_DELETE_REQUEST_SUCCESS'
)
export const deleteDataCachesRequestFail = createAction(
  'DATA_CACHES_DELETE_REQUEST_FAIL'
)

export const downloadDataCachesRequest = createAction(
  'DATA_CACHES_DOWNLOAD_REQUEST'
)
export const downloadDataCachesRequestSuccess = createAction(
  'DATA_CACHES_DOWNLOAD_REQUEST_SUCCESS'
)
export const downloadDataCachesRequestFail = createAction(
  'DATA_CACHES_DOWNLOAD_REQUEST_FAIL'
)
export const downloadDataCachesRequestDone = createAction(
  'DATA_CACHES_DOWNLOAD_REQUEST_DONE'
)

export const clearDataCachesRecordRequest = createAction(
  'DATA_CACHES_CLEAR_RECORD_REQUEST'
)
export const clearDataCachesRecordFinished = createAction(
  'DATA_CACHES_CLEAR_RECORD_FINISHED'
)

export const rebuildDataCachesRecordRequest = createAction(
  'DATA_CACHES_REBUILD_RECORD_REQUEST'
)
export const rebuildDataCachesRecordFinished = createAction(
  'DATA_CACHES_REBUILD_RECORD_FINISHED'
)

export const actions = {
  setFormState,
  requestDataCaches,
  requestDataCachesSuccess,
  requestDataCachesFail,
  clearFunctionalDataCachesRequest,
  clearFunctionalDataCachesRequestSuccess,
  clearFunctionalDataCachesRequestFail,
  clearIndividualDataCachesRequest,
  clearIndividualDataCachesRequestSuccess,
  clearIndividualDataCachesRequestFail,
  rebuildDataCachesRequest,
  rebuildDataCachesRequestSuccess,
  rebuildDataCachesRequestFail,
  deleteDataCachesRequest,
  deleteDataCachesRequestSuccess,
  deleteDataCachesRequestFail,
  downloadDataCachesRequest,
  downloadDataCachesRequestSuccess,
  downloadDataCachesRequestFail,
  downloadDataCachesRequestDone,
  clearDataCachesRecordRequest,
  clearDataCachesRecordFinished,
  rebuildDataCachesRecordRequest,
  rebuildDataCachesRecordFinished
}

const dataCachesReducer = createReducer(
  {
    formState: {
      id: '',
      functional: null,
      orderBy: 'id',
      order: 'asc',
      page: 1,
      perPage: 20
    },
    isFetching: true,
    isJSONFetching: false,
    data: [],
    pagination: {
      total: 0,
      filtered: 0,
      maxPage: 0,
      results: 0,
      page: 1,
      perPage: 20
    },
    recordJSON: {},
    recordName: '',
    downloadReady: false,
    isClearing: false,
    isRebuilding: false
  },
  {
    [setFormState]: (state, { payload }) => {
      state.formState = { ...state.formState, ...payload }
    },
    [requestDataCaches]: state => {
      state.isFetching = true
    },
    [requestDataCachesSuccess]: (state, { payload }) => {
      state.isFetching = false
      state.data = payload.data
      state.pagination = payload.pagination
    },
    [requestDataCachesFail]: state => {
      state.isFetching = false
    },
    [clearFunctionalDataCachesRequest]: state => {
      state.isFetching = true
    },
    [clearFunctionalDataCachesRequestSuccess]: state => {
      state.isFetching = false
    },
    [clearFunctionalDataCachesRequestFail]: state => {
      state.isFetching = false
    },
    [clearIndividualDataCachesRequest]: state => {
      state.isFetching = true
    },
    [clearIndividualDataCachesRequestSuccess]: state => {
      state.isFetching = false
    },
    [clearIndividualDataCachesRequestFail]: state => {
      state.isFetching = false
    },
    [rebuildDataCachesRequest]: state => {
      state.isFetching = true
    },
    [rebuildDataCachesRequestSuccess]: state => {
      state.isFetching = false
    },
    [rebuildDataCachesRequestFail]: state => {
      state.isFetching = false
    },
    [deleteDataCachesRequest]: state => {
      state.isFetching = true
    },
    [deleteDataCachesRequestSuccess]: state => {
      state.isFetching = false
    },
    [deleteDataCachesRequestFail]: state => {
      state.isFetching = false
    },
    [downloadDataCachesRequest]: state => {
      state.isJSONFetching = true
    },
    [downloadDataCachesRequestSuccess]: (
      state,
      { payload: { response, name } }
    ) => {
      state.recordJSON = response
      state.recordName = name
      state.isJSONFetching = false
      state.downloadReady = true
    },
    [downloadDataCachesRequestFail]: state => {
      state.isJSONFetching = false
    },
    [downloadDataCachesRequestDone]: state => {
      state.downloadReady = false
      state.recordName = ''
    },
    [clearDataCachesRecordRequest]: state => {
      state.isClearing = true
    },
    [clearDataCachesRecordFinished]: state => {
      state.isClearing = false
    },
    [rebuildDataCachesRecordRequest]: state => {
      state.isRebuilding = true
    },
    [rebuildDataCachesRecordFinished]: state => {
      state.isRebuilding = false
    }
  }
)

export default dataCachesReducer
