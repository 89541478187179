import { createReducer, createAction } from '@reduxjs/toolkit'

// Filter actions
export const filterRequest = createAction('FILTERS_REQUEST')
export const filterRequestSuccess = createAction('FILTERS_REQUEST_SUCCESS')
export const filterRequestFail = createAction('FILTERS_REQUEST_FAIL')

export const actions = {
  filterRequest,
  filterRequestSuccess,
  filterRequestFail
}

const filterReducer = createReducer(
  {
    filters: {},
    fetching: false
  },
  {
    [filterRequest]: state => {
      state.fetching = true
    },
    [filterRequestSuccess]: (state, { payload }) => {
      state.filters = payload
      state.fetching = false
    },
    [filterRequestFail]: state => {
      state.filters = {}
      state.fetching = false
    }
  }
)

export default filterReducer
