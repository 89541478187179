import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Markup from 'components/ui/Markup'
import Typography from './ui/Typography'
import Divider from './ui/Divider'
import styles from './Footer.module.sass'

const Footer = ({ text, copyright, links = [] }) => (
  <section className={styles.container}>
    <Divider className={styles.divider} />
    <div className={styles.linkContainer}>
      {text && (
        <Typography type="footnote" className={styles.text}>
          <Markup html={text.join('</br>')} />
        </Typography>
      )}
      {copyright && (
        <Typography type="footnote" className={styles.copyright}>
          {copyright}
        </Typography>
      )}
      {links.map(({ link, title: linkTitle }, linkIndex) => {
        const isExternalLink = link.startsWith('http') || link.startsWith('//')
        return (
          <Fragment key={linkTitle}>
            <span className={styles.textDivider}>|</span>
            <Typography
              component={isExternalLink ? 'a' : Link}
              type="footnote"
              target={isExternalLink ? '_blank' : null}
              className={styles.link}
              to={isExternalLink ? undefined : link}
              href={isExternalLink ? link : undefined}
            >
              {linkTitle}
            </Typography>
          </Fragment>
        )
      })}
    </div>
  </section>
)
Footer.propTypes = {
  /**
   * An array with the footer menu links
   */
  links: PropTypes.array,
  /**
   * Text on the left of footer menu
   */
  text: PropTypes.array,
  /**
   * Copyright statement for the footer
   */
  copyright: PropTypes.string
}

export default Footer
