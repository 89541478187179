import { createSelector } from '@reduxjs/toolkit'

export const usersSearchSelector = state => state.usersSearchReducer

export const getFormState = () =>
  createSelector(usersSearchSelector, usersSearch => usersSearch.formState)

export const getRolesFetching = () =>
  createSelector(usersSearchSelector, usersSearch => usersSearch.rolesFetching)

export const getDataFetching = () =>
  createSelector(usersSearchSelector, usersSearch => usersSearch.dataFetching)

export const getUsers = () =>
  createSelector(usersSearchSelector, usersSearch => usersSearch.data)

export const getSelected = () =>
  createSelector(usersSearchSelector, usersSearch => usersSearch.selected)

export const getModalOpen = () =>
  createSelector(usersSearchSelector, usersSearch => usersSearch.modalOpen)

export const getInvalidateFetching = () =>
  createSelector(
    usersSearchSelector,
    usersSearch => usersSearch.invalidateFetching
  )

export const getPagination = () =>
  createSelector(usersSearchSelector, usersSearch => usersSearch.pagination)

export const getRolesAttributes = () =>
  createSelector(
    usersSearchSelector,
    usersSearch => usersSearch.rolesAttributes
  )

export const getSelectedUser = () =>
  createSelector(usersSearchSelector, usersSearch => usersSearch.user)

export const getUserFetching = () =>
  createSelector(usersSearchSelector, usersSearch => usersSearch.userFetching)
