import { useDispatch } from 'react-redux'

import { actions as trackingActions } from './TrackingReducer'

/**
 * Tracking Hook, allow us to reuse the tracking logic & actions through the project
 * @returns { trackSubmitAction, trackPageView, trackClickAction }
 */
const useTracking = () => {
  const dispatch = useDispatch()

  // Tracking Actions

  /**
   * Track Submit Action
   * @param {*} object
   */
  const trackSubmitAction = obj => {
    dispatch(trackingActions.trackSubmitAction(obj))
  }

  /**
   * Track Page View
   * @param {*} object
   */
  const trackPageView = obj => {
    dispatch(trackingActions.trackPageView(obj))
  }

  /**
   * Track Click Action
   * @param {*} object
   */
  const trackClickAction = obj => {
    dispatch(trackingActions.trackClickAction(obj))
  }

  return { trackSubmitAction, trackPageView, trackClickAction }
}

useTracking.propTypes = {}

export default useTracking
