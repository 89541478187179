import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { isEmpty } from 'utils'
import PropTypes from 'prop-types'
import Roles from 'components/Admin/Roles'
import Card from 'components/Admin/Card/Card'
import PageContent from 'components/Admin/Page/PageContent'
import { CircularProgress } from 'components/ui/Progress'
import Button from 'components/Admin/Button'
import TextInput from 'components/TextInput'
import styles from './UsersCreate.module.sass'
import validationSchema from './validationSchema'
import { smartsEndpoints } from 'constants/apiConstants'

import {
  getFormState,
  getCountriesFetching,
  getRolesFetching,
  // getPossibleCountries,
  getSubmitting,
  getRolesAttributes
} from './UsersCreateSelector'
import useRoleFeatures from 'modules/auth/useRoleFeatures'
import { actions as usersCreateActions } from './UsersCreateReducer'
import { Checkbox } from 'components/Checkbox'
import Typography from 'components/ui/Typography'
import Typeahead from 'modules/typeahead'
// import SelectInput from 'components/SelectInput'

const UsersCreate = ({ match }) => {
  const {
    params: { userid = undefined }
  } = match
  const dispatch = useDispatch()
  const initialForm = {
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    city: '',
    postalCode: '',
    province: '',
    telephone: '',
    company: '',
    jobFunction: '',
    jobTitle: '',
    status: 'active',
    roles: []
  }
  const { features } = useRoleFeatures({
    coin: 'admin-users'
  })
  const isCountriesFetching = useSelector(getCountriesFetching())
  const isRolesFetching = useSelector(getRolesFetching())
  // const possibleCountries = useSelector(getPossibleCountries())
  const rolesAttributes = useSelector(getRolesAttributes())
  const isSubmitting = useSelector(getSubmitting())
  const formState = useSelector(getFormState())
  const formStatePr = isEmpty(formState) ? initialForm : formState
  const [clearInput, setClearInput] = useState(false)

  const roleSchema = features?.sections?.actions || []
  const userCanView = roleSchema.includes('view')
  const userCanEdit = roleSchema.includes('edit')
  const userCanCreate = roleSchema.includes('create')
  const isViewForm = userid && userCanView && !userCanEdit

  useEffect(() => {
    if (isCountriesFetching) {
      dispatch(usersCreateActions.requestPossibleCountries())
    }

    if (isRolesFetching) {
      dispatch(usersCreateActions.requestPossibleRoles())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userid) {
      dispatch(usersCreateActions.fetchUserRequest({ userid }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid])

  const handleActiveChange = (e, formProps) => {
    const status = e.target.checked ? 'inactive' : 'active'

    dispatch(
      usersCreateActions.setFormState({
        ...formProps.values,
        status: status
      })
    )
  }

  const handleRoleChange = (e, role, formProps) => {
    if (e.target.checked) {
      formProps.setFieldValue(
        'roles',
        formProps.values.roles.filter(r => r !== role)
      )
    } else {
      formProps.setFieldValue('roles', [...formProps.values.roles, role])
      formProps.setFieldTouched('roles')
    }
  }

  const handleReset = () => {
    setClearInput(!clearInput)
    dispatch(usersCreateActions.setFormState(initialForm))
  }

  const handleSubmit = values => {
    dispatch(usersCreateActions.submitCreate({ ...values, userid }))
  }

  // Redirect user if user create & no access
  if (!userid && !userCanCreate) {
    return (
      <Redirect
        to={{
          pathname: '/admin/users/search'
        }}
      />
    )
  }

  return (
    <PageContent
      title={userid ? (userCanEdit ? 'Update User' : 'User') : 'Create User'}
    >
      <Formik
        key={clearInput}
        initialValues={formStatePr}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {formProps => (
          <Form autoComplete="off">
            <Card className={styles.card}>
              <div className={styles.container}>
                <Typography
                  className={styles.subtitle}
                  type="title"
                  color="text-primary"
                >
                  Profile
                </Typography>
                <div className={styles.inputContainer}>
                  <TextInput
                    label="First Name"
                    name="firstName"
                    type="text"
                    required={!isViewForm}
                    traditional={false}
                    readOnly={isViewForm}
                  />
                  <TextInput
                    label="Last Name"
                    name="lastName"
                    type="text"
                    required={!isViewForm}
                    traditional={false}
                    readOnly={isViewForm}
                  />
                  <TextInput
                    label="Email"
                    name="email"
                    type="email"
                    required={!isViewForm}
                    traditional={false}
                    readOnly={isViewForm}
                  />
                </div>
                <div className={styles.checkbox}>
                  <Checkbox
                    isRadio
                    name="status"
                    label="Active"
                    value={formProps.values.status === 'active'}
                    onChange={e => {
                      handleActiveChange(e, formProps)
                    }}
                    disabled={isViewForm}
                  />
                </div>
              </div>
            </Card>
            <Card className={styles.card}>
              <div className={styles.container}>
                <Typography
                  className={styles.subtitle}
                  type="title"
                  color="text-primary"
                >
                  Information
                </Typography>
                <div className={styles.inputContainer}>
                  {/* <SelectInput
                    label="Country"
                    name="country"
                    options={possibleCountries}
                    autoComplete="off"
                    suggestionsLimit={8}
                    onChange={event =>
                      formProps.setFieldValue('country', event.target.value)
                    }
                    onSuggestionSelected={(
                      event,
                      { suggestion: { key, value } }
                    ) => formProps.setFieldValue('country', key)}
                  /> */}
                  <Typeahead
                    formName="admin-create-user"
                    label="Country"
                    name="country"
                    type="text"
                    traditional={false}
                    body={{
                      data: {
                        search: {
                          perPage: 100
                        }
                      },
                      endpoint: smartsEndpoints.variationCountries,
                      parseFunction: 'optionsParser'
                    }}
                    readOnly={isViewForm}
                  />
                  <TextInput
                    label="City"
                    name="city"
                    type="text"
                    traditional={false}
                    readOnly={isViewForm}
                  />
                  <TextInput
                    label="Postal Code"
                    name="postalCode"
                    type="text"
                    traditional={false}
                    readOnly={isViewForm}
                  />
                  <TextInput
                    label="Province"
                    name="province"
                    type="text"
                    traditional={false}
                    readOnly={isViewForm}
                  />
                  <TextInput
                    label="Telephone"
                    name="telephone"
                    type="text"
                    traditional={false}
                    readOnly={isViewForm}
                  />
                  <div></div>
                  <TextInput
                    label="Company"
                    name="company"
                    type="text"
                    traditional={false}
                    readOnly={isViewForm}
                  />
                  <TextInput
                    label="Job Function"
                    name="jobFunction"
                    type="text"
                    traditional={false}
                    readOnly={isViewForm}
                  />
                  <TextInput
                    label="Job Title"
                    name="jobTitle"
                    type="text"
                    traditional={false}
                    readOnly={isViewForm}
                  />
                </div>
              </div>
            </Card>
            <Card>
              <div className={styles.container}>
                <Typography
                  className={styles.subtitle}
                  type="title"
                  color="text-primary"
                >
                  Roles
                </Typography>
                {isRolesFetching ? (
                  <div className={styles.loadingComponent}>
                    <CircularProgress
                      fillColor="transparent"
                      size={64}
                      strokeWidth={4}
                    />
                  </div>
                ) : (
                  <Roles
                    handleRoleChange={handleRoleChange}
                    formProps={formProps}
                    rolesAttributes={rolesAttributes}
                    disabled={isViewForm}
                  />
                )}
                {Object.keys(formProps.errors).includes('roles') &&
                  Object.keys(formProps.touched).includes('roles') &&
                  formProps.values.roles.length === 0 && (
                    <Typography type={'error'}>
                      {formProps.errors.roles}
                    </Typography>
                  )}
                <div className={styles.actionContainer}>
                  {!userid && (
                    <Button
                      className={styles.resetButton}
                      text="Reset"
                      textCase="none"
                      disabled={false}
                      type="button"
                      backgroundColor="#d3d3d3"
                      onClick={handleReset}
                    />
                  )}
                  {!isViewForm && (
                    <Button
                      text={userid ? 'Update' : 'Create'}
                      textCase="none"
                      disabled={isSubmitting}
                      hasSpinner
                      type="submit"
                    />
                  )}
                </div>
              </div>
            </Card>
          </Form>
        )}
      </Formik>
    </PageContent>
  )
}

UsersCreate.propTypes = {
  match: PropTypes.object
}

export default UsersCreate
