import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Button as ButtonUI } from '../../../components/ui/Button'
import Icon from 'components/ui/Icon'
import Typography from '../../../components/ui/Typography'
import { RaisedButton } from 'components/Button'
import FiltersComponent from 'modules/filter'
import useFilters from 'modules/filter/useFilters'
import Typeahead from 'modules/typeahead'
import { smartsEndpoints } from 'constants/apiConstants'
import * as soundBoardSelectors from '../SoundBoardSelector'
import styles from './form.module.sass'
import TextInputField from 'components/TextInput'
import { actions as soundBoardActions } from '../SoundBoardReducer'
import classNames from 'classnames/bind'
import useRoleFeatures from 'modules/auth/useRoleFeatures'

const cx = classNames.bind(styles)

const { getFetchStatus } = soundBoardSelectors

const ExploreListForm = ({ formProps, handleReset }) => {
  const isFetching = useSelector(getFetchStatus())
  const dispatch = useDispatch()
  const { values } = formProps
  const { filters, selectedList, listSize } = values
  const { selectedFiltersCaptions } = useFilters(filters)
  const [saveHidden, setSaveHidden] = useState(true)
  const { features } = useRoleFeatures({
    coin: 'explore-soundboard'
  })
  const handleSaveSubmit = () => {
    dispatch(
      soundBoardActions.submitFormRequest({
        ...values
      })
    )
  }
  return (
    <div className={styles.divider}>
      <Typeahead
        formName="soundboard"
        label="List ID"
        className={styles.listIds}
        placeholder="Enter List ID"
        name="selectedList"
        traditional
        required
        body={{
          data: {
            filters: {
              workflow: 'ExploreSoundboard'
            },
            search: {
              perPage: 100
            }
          },
          endpoint: smartsEndpoints.listsCombinationsFetchListsEndpoint,
          parseFunction: 'optionsParser'
        }}
      />
      <div className={styles.filtersContainer}>
        <FiltersComponent name="filters" />
        {saveHidden && features.sections?.actions.includes('save-list') && (
          <RaisedButton
            type="button"
            onClick={() => setSaveHidden(false)}
            disabled={
              !selectedFiltersCaptions.length || !selectedList || isFetching
            }
            fontSize="16px"
          >
            <Icon
              icon="svg/custom/add-circle"
              colorTier={
                !selectedFiltersCaptions.length || !selectedList || isFetching
                  ? 'default'
                  : 'tertiary'
              }
            />{' '}
            Save List
          </RaisedButton>
        )}
      </div>
      {!saveHidden && selectedFiltersCaptions.length > 0 && selectedList && (
        <div className={styles.saveContainer}>
          <TextInputField
            name="saveListName"
            label="NAME YOUR LIST"
            type="text"
          />
          <div className={styles.buttonsRow}>
            <ButtonUI
              className={cx(styles.customButton, styles.noBackground)}
              type="button"
              text="Cancel"
              textCase="uppercase"
              onClick={() => setSaveHidden(true)}
            />
            <ButtonUI
              className={cx(styles.customButton, {
                disabled: !values.saveListName || isFetching,
                noBackground: true,
                isFetching
              })}
              type="button"
              text="Save"
              textCase="uppercase"
              disabled={!values.saveListName || isFetching}
              onClick={handleSaveSubmit}
              hasSpinner
              submitting={isFetching}
            />
          </div>
        </div>
      )}
      {listSize > 0 && (
        <Typography type="body2" className={styles.listSize}>
          Current filtered list size: <strong> {listSize}</strong>
        </Typography>
      )}
    </div>
  )
}

ExploreListForm.propTypes = {
  /**
   * Form props
   */
  formProps: PropTypes.object,
  /**
   * Reset Form Func
   */
  handleReset: PropTypes.func
}

export default ExploreListForm
