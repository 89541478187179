import { paramsDuration } from './constants'
/**
 * Constants used by the ParamsService.
 */

const domainCacheValues = [
  'EmailID',
  'country',
  'isAnon',
  'MemberID',
  'ProfessionID',
  'profession_id',
  'SpecialtyID',
  'state',
  'uuid',
  'site_uid',
  'psl_device_id',
  'access',
  'refresh',
  'fullProfileViews'
]

const sessionCacheValues = [
  'action',
  'adCount',
  'articleLanguage',
  'article_id',
  'article_title',
  'audience_id',
  'CampaignID',
  'comments',
  'destUrl',
  'event_id',
  'expirationDate',
  'external',
  'idhSiteId',
  'idhBrandId',
  'interactionId',
  'isFirstTimeHome',
  'isFirstTimeArticle',
  'lastAction',
  'likes',
  'nl_site',
  'nl_ref',
  'share',
  'source'
]

const cacheValuesSynonyms = {
  action: ['action', 'nl_action'],
  adCount: ['adCount', 'adcount'],
  article_id: ['article_id', 'articleID', 'articleId', 'articleid'],
  article_title: ['article_title', 'articleTitle', 'articletitle'],
  audience_id: ['nl_site', 'audience_id'],
  CampaignID: [
    'CampaignID',
    'CampaignId',
    'Campaignid',
    'campaignid',
    'campaignId',
    'campaignID',
    'nl_campaignid',
    'nl_campaignID',
    'nl_campaignId'
  ],
  comments: ['comments', 'comment'],
  country: [
    'country',
    'countryCode',
    'countrycode',
    'CountryCode',
    'ccid',
    'ccId'
  ],
  destUrl: ['destUrl', 'destURL'],
  EmailID: [
    'EmailID',
    'PartyEmailID',
    'PartyEmailId',
    'PartyEmailid',
    'PartyemailID',
    'partyemailid',
    'partyEmailId',
    'partyEmailID'
  ],
  event_id: ['event_id', 'nl_eventid'],
  expirationDate: ['expirationDate'],
  external: ['external'],
  idhSiteId: ['idhSiteId'],
  idhBrandId: ['idhBrandId'],
  interactionId: ['interactionId', 'interactionid'],
  articleLanguage: ['articleLanguage', 'articlelanguage'],
  lastAction: ['lastaction', 'lastAction'],
  likes: ['likes', 'like'],
  MemberID: ['MemberID', 'PartyId', 'PartyID', 'partyid', 'partyId', 'partyID'],
  profession_id: [
    'ProfessionID',
    'ProfessionId',
    'Professionid',
    'professionid',
    'professionID',
    'professionId',
    'profession_id',
    'prid',
    'prId'
  ],
  share: ['share'],
  SpecialtyID: [
    'SpecialtyID',
    'SpecialtyId',
    'Specialtyid',
    'specialtyID',
    'specialtyId',
    'specialtyid',
    'spid',
    'spId'
  ],
  state: ['state'],
  isFirstTimeHome: ['isFirstTimeHome'],
  isFirstTimeArticle: ['isFirstTimeArticle'],
  uuid: ['uuid'],
  site_uid: ['site_uid', 'siteUid', 'siteUID', 'siteUId', 'SiteID', 'SiteId'],
  psl_device_id: [
    'psl_device_id',
    'pslDeviceId',
    'pslDeviceID',
    'PSLDeviceId',
    'PslDeviceId',
    'PSLDeviceID',
    'PslDeviceID'
  ],
  access: ['access', 'accessToken'],
  refresh: ['refresh', 'refreshToken'],
  isAnon: ['isAnon', 'isanon'],
  fullProfileViews: ['fullProfileViews', 'fullprofileviews', 'fullProfileView'],
  source: ['source']
}

const sessionValuesDuration = paramsDuration // 24hrs

export {
  cacheValuesSynonyms,
  domainCacheValues,
  sessionCacheValues,
  sessionValuesDuration
}
