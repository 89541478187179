import { createSelector } from '@reduxjs/toolkit'

export const chatSelector = state => state.chatReducer

export const getFetching = () =>
  createSelector(chatSelector, chat => chat.fetching)

export const getFormState = () =>
  createSelector(chatSelector, chat => chat.formState)

export const getMessages = () =>
  createSelector(chatSelector, chat => chat.messages)

export const getTags = () => createSelector(chatSelector, chat => chat.tags)

export const getShowInterests = () =>
  createSelector(chatSelector, chat => chat.showInterests)

// Modal
export const getFeedbackModal = () =>
  createSelector(chatSelector, chat => chat.feedbackModal)
export const getShareModal = () =>
  createSelector(chatSelector, chat => chat.shareModal)
export const getEmailModal = () =>
  createSelector(chatSelector, chat => chat.emailModal)
export const getInviteModal = () =>
  createSelector(chatSelector, chat => chat.inviteModal)

export const getActionHandler = () =>
  createSelector(chatSelector, chat => chat.actionHandler)

export const getLinkedPromptId = () =>
  createSelector(chatSelector, chat => chat.linkedPromptId)
export const getThreadId = () =>
  createSelector(chatSelector, chat => chat.threadId)

export const getCurrentPrompt = () =>
  createSelector(chatSelector, chat => chat.current)

export const getPromptNum = () =>
  createSelector(chatSelector, chat => chat.promptNum)
export const getUrlRequested = () =>
  createSelector(chatSelector, chat => chat.urlRequested)

export const getIsTyping = () =>
  createSelector(chatSelector, chat => chat.isTyping)
