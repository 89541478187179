/**
 * Smarts Parser
 */
const SmartsParser = () => ({
  /**
   * Parse upload file error response
   *
   * @param sheetOptions
   */
  uploadFileErrorParser(data) {
    const { responseData, message } = data
    if (responseData?.duplicatedHeaders || responseData?.invalidHeaders) {
      const duplicatedString = responseData?.duplicatedHeaders
        .map(d => d.heading)
        .join(', ')
      const invalidString = responseData?.invalidHeaders
        .map(i => `${i.heading} (found: ${i.found})`)
        .join(', ')
      return (
        `${message} ${
          duplicatedString ? `\nDuplicated Headers: [${duplicatedString}]` : ''
        } ` + (invalidString ? `\nInvalid Headers: [${invalidString}]` : '')
      )
    }
    if (Array.isArray(responseData)) {
      return responseData.join('\n')
    }
    if (responseData?.parser?.[0]?.caption) {
      return responseData?.parser[0]?.caption
    }
    return message
  },
  /**
   * Parse sheets options for dropdown
   *
   * @param sheetOptions
   */
  sheetsDropdownParser(sheetOptions) {
    return sheetOptions.reduce((prev, curr) => {
      return { ...prev, [curr]: curr }
    }, {})
  },
  /**
   * Parse columnHeaders options for dropdown
   *
   * @param columnHeaders
   */
  columnsHeadersDropdownParser(columnHeaders = []) {
    return columnHeaders.reduce(
      (prev, curr) => {
        return { ...prev, [curr.key]: curr.caption }
      },
      { 0: 'None' }
    )
  },
  /**
   * Parse suggestions to formState values
   *
   * @param suggestions
   * @param ignoreFields
   */
  fileHeadersSuggestionsParser(suggestions = [], ignoreFields = []) {
    return suggestions.reduce((previousValue, currentValue) => {
      // Avoid adding duplicated suggestions
      if (
        !Object.values(previousValue).includes(currentValue.key) &&
        !ignoreFields.includes(currentValue.attribute)
      ) {
        return { ...previousValue, [currentValue.attribute]: currentValue.key }
      }
      return previousValue
    }, {})
  },
  /**
   * Parse mapping form suggetsions to formState values
   *
   * @param suggestions
   * @param fieldName
   */
  mappingFormSuggestionsParser(
    suggestions = [],
    fieldName = '',
    formState = {}
  ) {
    const {
      fullname,
      firstname,
      middlename,
      lastname,
      profession,
      specialty,
      nationalId1,
      nationalIdType1,
      nationalId2,
      nationalIdType2,
      partyId,
      hcpId,
      addressLine1,
      addressLine2,
      addressLine3,
      phonenumber,
      city,
      state,
      postalcode,
      country,

      thirdPartyID1,
      thirdPartyID2,
      thirdPartyID3,
      thirdPartyID4,
      thirdPartyID5,
      thirdPartyID6,
      thirdPartyID7,
      thirdPartyID8,
      thirdPartyID9,
      thirdPartyID10,

      priorEngagementID1,
      priorEngagementID2,
      priorEngagementID3,
      priorEngagementID4,
      priorEngagementID5,
      priorEngagementID6,
      priorEngagementID7,
      priorEngagementID8,
      priorEngagementID9,
      priorEngagementID10,

      value1,
      value2,
      value3,
      value4,
      value5,
      value6,
      value7,
      value8,
      value9,
      value10,

      segment1,
      segment2,
      segment3,
      segment4,
      segment5,
      segment6,
      segment7,
      segment8,
      segment9,
      segment10
    } = formState

    const headerValues = [
      fullname,
      firstname,
      middlename,
      lastname,
      profession,
      specialty,
      nationalId1,
      nationalIdType1,
      nationalId2,
      nationalIdType2,
      partyId,
      hcpId,
      addressLine1,
      addressLine2,
      addressLine3,
      phonenumber,
      city,
      state,
      postalcode,
      country,

      thirdPartyID1,
      thirdPartyID2,
      thirdPartyID3,
      thirdPartyID4,
      thirdPartyID5,
      thirdPartyID6,
      thirdPartyID7,
      thirdPartyID8,
      thirdPartyID9,
      thirdPartyID10,

      priorEngagementID1,
      priorEngagementID2,
      priorEngagementID3,
      priorEngagementID4,
      priorEngagementID5,
      priorEngagementID6,
      priorEngagementID7,
      priorEngagementID8,
      priorEngagementID9,
      priorEngagementID10,

      value1,
      value2,
      value3,
      value4,
      value5,
      value6,
      value7,
      value8,
      value9,
      value10,

      segment1,
      segment2,
      segment3,
      segment4,
      segment5,
      segment6,
      segment7,
      segment8,
      segment9,
      segment10
    ]
    return suggestions
      .filter(s => !headerValues.includes(s.key))
      .reduce((previousValue, currentValue, index) => {
        return {
          ...previousValue,
          [`${fieldName}${index + 1}`]: currentValue.key,
          [`${fieldName}Label${index + 1}`]: currentValue.label
        }
      }, {})
  },
  /**
   * Parse mapping form values to endpoint
   *
   * @param columnHeaders
   */
  mappingValuesParser(formState = {}, fieldName = '') {
    let mapping = []
    Array.from({ length: 10 }).forEach((_, i) => {
      if (
        formState[`${fieldName}${i + 1}`] &&
        formState[`${fieldName}${i + 1}`] !== '0'
      ) {
        mapping = [
          ...mapping,
          {
            key: formState[`${fieldName}${i + 1}`],
            label: formState[`${fieldName}Label${i + 1}`]
          }
        ]
      }
    })
    return mapping
  },
  /**
   * Parse list info for list id tooltip
   *
   * @param list
   */
  listIdTooltipParser(list) {
    const {
      mapped: { idhListId, masterProject, project, client },
      raw: {
        type,
        metadata: { reportName, tags, notes }
      }
    } = list
    return (
      `<strong>List ID</strong>: ${idhListId}<br/>` +
      (['list', 'export'].includes(type) && reportName
        ? `<strong>List Name</strong>: ${reportName}<br/>`
        : '') +
      (['analysis'].includes(type) && reportName
        ? `<strong>Report Name</strong>: ${reportName}<br/>`
        : '') +
      (client && `<strong>Client</strong>: ${client}<br/>`) +
      (masterProject &&
        `<strong>Master Project</strong>: ${masterProject}<br/>`) +
      (project && `<strong>Project</strong>: ${project}<br/>`) +
      (tags && `<strong>Tags</strong>: ${tags}<br/>`) +
      (notes && `<strong>Notes</strong>: ${notes}`)
    )
  },
  /**
   * Parse project metadata body data
   *
   * @param list
   */
  projectMetadataBodyParser(data) {
    const {
      brand,
      client,
      masterProject,
      clientProduct,
      agency,
      targetListCreateListIds
    } = data
    let newTargetListIds
    if (targetListCreateListIds !== undefined) {
      if (!targetListCreateListIds) {
        return null
      }
      newTargetListIds = targetListCreateListIds.map(t => t.key)
    }
    const newData = {
      ...data,
      brand: brand ? brand.map(b => parseInt(b)) : null,
      client: client ? parseInt(client) : null,
      masterProject: masterProject
        ? !isNaN(masterProject)
          ? parseInt(masterProject)
          : masterProject
        : null,
      clientProduct: clientProduct
        ? !isNaN(clientProduct)
          ? parseInt(clientProduct)
          : clientProduct
        : null,
      agency: agency ? parseInt(agency) : null,
      targetListCreateListIds: newTargetListIds
    }
    // Clean all empty values
    Object.keys(newData).forEach(key => {
      if (newData[key] === '') {
        delete newData[key]
      }
    })
    return newData
  },
  /**
   * Parse basic heading for request
   *
   * @param data
   */
  basicHeadingParser(data) {
    const { fields = {}, mapping = [], type = '' } = data
    let newData = {}
    if (type === 'BASIC') {
      const newFields = Object.keys(fields)
        .filter(f => fields[f] && fields[f] !== '0')
        .reduce((prev, curr) => ({ ...prev, [curr]: fields[curr] }), {})
      newData = { ...data, fields: newFields }
    } else {
      let newMapping = []
      mapping.forEach(m => {
        if (m.key !== '0') {
          newMapping = [...newMapping, m]
        }
      })
      newData = { ...data, mapping: newMapping }
    }
    return newData
  },
  /**
   * Parse combinations for request
   *
   * @param combinations
   */
  combinationsParser(combinations) {
    if (!combinations) {
      return undefined
    }
    let newCombinations = []
    newCombinations = combinations.map(c => ({ ...c, id: undefined }))
    return newCombinations
  },
  /**
   * Parse combinations for request
   *
   * @param filters
   */
  filtersParser(filters) {
    if (!filters) {
      return null
    }
    const finalFilter = Object.keys(filters).reduce((prevGroup, currGroup) => {
      const filter = Object.keys(filters[currGroup]).reduce(
        (prevFilter, currFilter) => {
          if (currFilter && filters[currGroup][currFilter]) {
            // parse values for number values (i.e. 'lift' filter)
            let value = filters[currGroup][currFilter]
            return {
              ...prevFilter,
              [currFilter]:
                isNaN(value) || typeof value === 'boolean'
                  ? value
                  : parseInt(value)
            }
          }
          return prevFilter
        },
        {}
      )
      if (Object.keys(filter).length) {
        return { ...prevGroup, [currGroup]: filter }
      } else {
        return prevGroup
      }
    }, {})
    return finalFilter
  },
  /**
   * Parse list Search body for request
   *
   * @param data
   */
  listsSearchParser(data) {
    return Object.fromEntries(Object.entries(data).filter(([_, v]) => !!v))
  }
})

export default SmartsParser
