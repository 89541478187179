import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Dropdown from 'components/DropdownInput'
import * as listIngestionSelectors from '../ListIngestionSelector'
import styles from './form.module.sass'

const { getOptions } = listIngestionSelectors

const ColumnHeaders = ({ formProps }) => {
  const columnHeadersOptions = useSelector(getOptions('column-headers'))
  const nationalIDType1Options = useSelector(getOptions('nationalIdType1'))
  const nationalIDType2Options = useSelector(getOptions('nationalIdType2'))
  const { parsedColumnHeadersOptions = {} } = columnHeadersOptions
  const { listMatching } = formProps.values
  const inputsConfig = [
    {
      label: 'Full Name',
      name: 'fullname'
    },
    {
      label: 'First Name',
      name: 'firstname'
    },
    {
      label: 'Middle Name',
      name: 'middlename'
    },
    {
      label: 'Last Name',
      name: 'lastname'
    },
    {
      label: 'Profession',
      name: 'profession'
    },
    {
      label: 'Specialty',
      name: 'specialty'
    },
    {
      label: 'National ID 1',
      name: 'nationalId1'
    },
    {
      label: 'National ID Type 1',
      name: 'nationalIdType1',
      options: nationalIDType1Options
    },
    {
      label: 'National ID 2',
      name: 'nationalId2'
    },
    {
      label: 'National ID Type 2',
      name: 'nationalIdType2',
      options: nationalIDType2Options
    },
    {
      label: 'Party ID',
      name: 'partyId',
      disabled: listMatching
    },
    {
      label: 'HCPID',
      name: 'hcpId',
      disabled: listMatching
    },
    {
      label: 'Address Line 1',
      name: 'addressLine1'
    },
    {
      label: 'Address Line 2',
      name: 'addressLine2'
    },
    {
      label: 'Address Line 3',
      name: 'addressLine3'
    },
    {
      label: 'Phone',
      name: 'phonenumber'
    },
    {
      label: 'City',
      name: 'city'
    },
    {
      label: 'State',
      name: 'state'
    },
    {
      label: 'Postal Code',
      name: 'postalcode'
    },
    {
      label: 'Country',
      name: 'country'
    }
  ]
  const {
    fullname,
    firstname,
    middlename,
    lastname,
    profession,
    specialty,
    nationalId1,
    nationalIdType1,
    nationalId2,
    nationalIdType2,
    partyId,
    hcpId,
    addressLine1,
    addressLine2,
    addressLine3,
    phonenumber,
    city,
    state,
    postalcode,
    country
  } = formProps.values
  return (
    <div className={styles.gridForm} data-testid="columnHeadersContainer">
      {inputsConfig.map(input => (
        <Dropdown
          key={input.name}
          label={input.label}
          placeholder="Select Option"
          name={input.name}
          required={input.required || false}
          traditional
          options={input.options || parsedColumnHeadersOptions}
          disabled={input.disabled || false}
          uniqueFields={{
            fullname,
            firstname,
            middlename,
            lastname,
            profession,
            specialty,
            nationalId1,
            nationalIdType1,
            nationalId2,
            nationalIdType2,
            partyId,
            hcpId,
            addressLine1,
            addressLine2,
            addressLine3,
            phonenumber,
            city,
            state,
            postalcode,
            country
          }}
        />
      ))}
    </div>
  )
}

ColumnHeaders.propTypes = {
  /**
   * Form props
   */
  formProps: PropTypes.object
}

export default ColumnHeaders
