import { createSelector } from '@reduxjs/toolkit'

export const advisorSearchSelector = state => state.advisorSearchReducer
export const getSearchStatus = () =>
  createSelector(advisorSearchSelector, advisorList => advisorList.fetching)
export const getAdvisorList = () =>
  createSelector(advisorSearchSelector, advisorList => advisorList.advisorList)
export const getFormState = () =>
  createSelector(advisorSearchSelector, advisorList => advisorList.formState)
export const getSpecialtyList = () =>
  createSelector(advisorSearchSelector, search => search.specialties)
export const getProfessionList = () =>
  createSelector(advisorSearchSelector, search => search.professions)
