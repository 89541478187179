import { all, call, put, takeEvery } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'
import { actions as usersCreateActions } from './UsersCreateReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'

function* possibleRolesRequest(services) {
  const UsersCreateService = services('UsersCreateService')

  try {
    const { attributes = {} } = yield call([
      UsersCreateService,
      'getPossibleRoles'
    ])

    yield put(usersCreateActions.setPossibleRoles({ attributes }))
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to fetch possible roles for Users',
        duration: 0
      })
    )
  }
}

function* possibleCountriesRequest(service) {
  const UsersCreateService = service('UsersCreateService')

  try {
    const { data = [] } = yield call(
      [UsersCreateService, 'getPossibleCountries'],
      { search: { perPage: 200 } }
    )

    const countries = {}

    data.forEach(country => {
      if (country) {
        countries[country.key] = country.caption
      }
    })

    yield put(usersCreateActions.setPossibleCountries({ countries }))
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to fetch possible countries for Users',
        duration: 0
      })
    )
  }
}

function* submitCreateRequest(services, { payload }) {
  const UsersCreateService = services('UsersCreateService')

  try {
    const { userid, ...restPayload } = payload
    const notInfoFields = ['email', 'status', 'roles']
    const fields = Object.keys(restPayload)

    // Update formState
    yield put(usersCreateActions.setFormState({ ...restPayload }))

    // Build payload
    const data = { info: {} }
    fields.forEach(field => {
      if (notInfoFields.includes(field)) {
        data[field] = restPayload[field]
      } else {
        data.info[field] = restPayload[field]
      }
    })

    // Call endpoint
    const response = yield call([UsersCreateService, 'putNewUser'], data)

    yield put(
      notificationActions.notificationEnqueue({
        message: userid ? 'User updated' : response.message,
        duration: 0
      })
    )
    // Finish submission (reset form if new user)
    if (!userid) {
      yield put(usersCreateActions.completeSubmit({}))
    } else {
      yield put(usersCreateActions.completeUpdate({}))
    }
  } catch (e) {
    yield put(usersCreateActions.completeUpdate({}))
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to complete user submission.',
        duration: 0
      })
    )
  }
}

function* fetchUser(services, { payload }) {
  const UsersCreateService = services('UsersCreateService')

  try {
    const { userid } = payload
    const { info, ...rest } = yield call(
      [UsersCreateService, 'fetchUser'],
      userid
    )
    yield put(usersCreateActions.setFormState({ ...info, ...rest }))
    yield put(usersCreateActions.fetchUserRequestSuccess())
  } catch (e) {
    yield put(usersCreateActions.fetchUserRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to fetch user.',
        duration: 0
      })
    )
  }
}

function* resetForm(services, { payload }) {
  const { action } = payload

  if (action === 'REPLACE') {
    yield put(usersCreateActions.completeSubmit({}))
  }
}

export default function* watchUsersCreate(services) {
  yield all([
    takeEvery('USERS_CREATE_POSSIBLE_ROLES', possibleRolesRequest, services),
    takeEvery(
      'USERS_CREATE_POSSIBLE_COUNTRIES',
      possibleCountriesRequest,
      services
    ),
    takeEvery('USERS_CREATE_SUBMIT', submitCreateRequest, services),
    takeEvery('USERS_CREATE_FETCH_REQUEST', fetchUser, services),
    takeEvery(LOCATION_CHANGE, resetForm, services)
  ])
}
