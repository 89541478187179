import PropTypes from 'prop-types'
import styles from './form.module.sass'
import Button from 'components/Button'
import { useSelector, useDispatch } from 'react-redux'
import * as exploreMarketSelectors from '../ExploreMarketSelector'
import * as exploreMarketActions from '../ExploreMarketReducer'
import useFilters from 'modules/filter/useFilters'
import { SelectedFilters, SelectedCombinations } from 'components/Selected'
import useRoleFeatures from 'modules/auth/useRoleFeatures'
import ListSize from 'components/ListSize'

const { getFormState, getFetchStatus } = exploreMarketSelectors

const ProjectMetadataForm = ({ formProps }) => {
  const isFetching = useSelector(getFetchStatus())
  const formState = useSelector(getFormState())
  const { values } = formProps
  const {
    combinations,
    filters,
    type,
    listSize,
    universe,
    tableCombinations,
    _totalMembersCount,
    _totalUniversesCount
  } = values
  const { selectedFiltersCaptions } = useFilters(filters)
  const dispatch = useDispatch()

  const { features } = useRoleFeatures({
    coin: 'explore-market'
  })

  const handleChangeForm = step => {
    dispatch(
      exploreMarketActions.setFormState({
        ...formState,
        combinationsCountry: '',
        combinationsProfession: '',
        combinationsSpecialty: '',
        activeStep: step
      })
    )
  }

  const onSaveListClick = () => {
    dispatch(
      exploreMarketActions.setFormState({
        ...formState,
        type: 'save-list',
        activeStep: 'project-metadata'
      })
    )
  }

  return (
    <div data-testid="chartsFormContainer">
      {combinations.length > 1 ? (
        <SelectedCombinations
          combinations={tableCombinations}
          selectedFiltersCaptions={selectedFiltersCaptions}
          universe={_totalUniversesCount}
          memberCount={_totalMembersCount}
          onEdit={() => handleChangeForm('filters')}
          isFetching={isFetching}
        />
      ) : (
        <SelectedFilters
          combinations={combinations}
          selectedFiltersCaptions={selectedFiltersCaptions}
          onEdit={() => handleChangeForm('filters')}
          isFetching={isFetching}
        />
      )}
      <div className={styles.buttonsRow}>
        {type !== 'save-list' &&
          features.sections?.actions.includes('save-list') && (
            <Button
              className={styles.button}
              onClick={onSaveListClick}
              hasSpinner={values.type === 'save-list'}
              submitting={isFetching}
              disabled={isFetching}
              type="button"
            >
              Save List
            </Button>
          )}
        {/* Run analysis currently disabled */}
        {/* {type !== 'run-analysis' && (
          <Button
            className={styles.button}
            onClick={() => handleTypeSubmit('run-analysis')}
            hasSpinner={values.type === 'run-analysis'}
            submitting={isFetching}
            disabled={isFetching}
          >
            Run Analysis
          </Button>
        )} */}
      </div>
      <ListSize hidden={false} universeSize={universe} listSize={listSize} />
    </div>
  )
}

ProjectMetadataForm.propTypes = {
  /**
   * Form props
   */
  formProps: PropTypes.object
}

export default ProjectMetadataForm
