import ListsSelection from './forms/ListsSelection'
import DownloadForm from './forms/DownloadForm'

export const initialValues = {
  // first screen
  activeStep: 'lists-selection',
  targetList: '',
  surveyParticipantList: '',

  // second screen
  limaList: true,
  clientList: false,
  clientName: '',
  clientCompanyName: '',
  projectName: '',
  projectNumber: '',
  consultantName: '',
  telephone: '',
  email: '',
  surveyId: '',

  // third screen
  specialtyCountry: {
    country: '',
    specialty: '',
    listSize: '',
    targetSampleSize: '',
    surveyParticipants: '',
    screenedOut: ''
  },
  specialtyCountryArray: [],
  ppt: true,
  pdf: false,

  // Charts
  chartsData: {},
  showDownloadForm: false,
  showCharts: false
}

export const steps = {
  'lists-selection': {
    component: <ListsSelection />
  },
  'download-form': {
    component: <DownloadForm />
  },
  'specialty-country': {
    component: <DownloadForm />
  }
}
