import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styles from './Coin.module.sass'
import classNames from 'classnames/bind'
import Typography from '../ui/Typography'
// import * as authSelectors from 'modules/auth/AuthSelector'
import { actions as trackActions } from 'modules/tracking/TrackingReducer'

//const { getRoleTable } = authSelectors

const cx = classNames.bind(styles)

const Coin = ({
  className,
  title,
  icon,
  route,
  externalLink,
  openNewTab,
  disabled = true
}) => {
  //const roles = useSelector(getRoleTable())
  const dispatch = useDispatch()
  const to = externalLink ? { pathname: externalLink } : route
  const isDisabled = disabled // || !roles.includes(route)

  const handleCoinClick = () => {
    dispatch(trackActions.trackCoinClick({ pathname: to }))
  }
  return (
    <div className={cx(styles.container, className)}>
      <Link
        className={cx(styles.link, { disabled: isDisabled })}
        to={to}
        onClick={handleCoinClick}
        target={externalLink || openNewTab ? '_blank' : ''}
      >
        <div
          className={cx(styles.coinContainer, {
            disabled: isDisabled,
            coinDougall: title === 'Dougall GPT'
          })}
        >
          {icon && (
            <img
              alt={title}
              className={cx(styles.icon, { coinGPT: icon.includes('gpt') })}
              src={require(`images/coins/${icon}.svg`)?.default}
            />
          )}
        </div>
        <Typography className={cx(styles.title, { disabled: isDisabled })}>
          {title}
        </Typography>
      </Link>
    </div>
  )
}
Coin.displayName = 'Coin'
Coin.propTypes = {
  /**
   * An additional custom className
   */
  className: PropTypes.string,
  /**
   * The title for the coin
   */
  title: PropTypes.string,
  /**
   * the coin route
   */
  route: PropTypes.string,
  /**
   * the coin icon
   */
  icon: PropTypes.string,
  /**
   * external link to redirect
   */
  externalLink: PropTypes.string,
  /**
   * flag to enable/disable the coin
   */
  disabled: PropTypes.bool,
  /**
   * flag to open in new tab
   */
  openNewTab: PropTypes.bool
}
export default Coin
