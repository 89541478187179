import { createReducer, createAction } from '@reduxjs/toolkit'

export const chartsLoadRequest = createAction('CHARTS_LOAD_REQUEST')
export const chartsLoadSuccessRequest = createAction(
  'CHARTS_LOAD_SUCCESS_REQUEST'
)
export const changeStep = createAction('CHANGE_STEP')
export const setCurrentChart = createAction('SET_CURRENT_CHART')
export const uploadChartRequest = createAction('UPLOAD_CHART_REQUEST')
export const uploadChartRequestSuccess = createAction(
  'UPLOAD_CHART_REQUEST_SUCCESS'
)
export const uploadChartRequestFail = createAction('UPLOAD_CHART_REQUEST_FAIL')

export const chartShareRequest = createAction('CHART_SHARE_REQUEST')
export const chartShareRequestSuccess = createAction(
  'CHART_SHARE_REQUEST_SUCCESS'
)
export const chartShareRequestFail = createAction('CHART_SHARE_REQUEST_FAIL')

export const addChart = createAction('ADD_CHART')

export const actions = {
  chartsLoadRequest,
  chartsLoadSuccessRequest,
  changeStep,
  setCurrentChart,
  uploadChartRequest,
  uploadChartRequestSuccess,
  uploadChartRequestFail,
  chartShareRequest,
  chartShareRequestSuccess,
  chartShareRequestFail,
  addChart
}

const initialValue = {
  chartsData: {},
  isFetching: true,
  activeStep: 'chart-generation',
  currentChart: {},
  chartsLocation: [],
  isSharing: false,
  chartsObject: {}
}

const chartsReducer = createReducer(initialValue, {
  [chartsLoadSuccessRequest]: (state, { payload }) => {
    state.isFetching = false
    state.chartsData = payload
  },
  [changeStep]: state => {
    if (state.activeStep === 'chart-generation') {
      state.activeStep = 'share-results'
    } else {
      state.activeStep = 'chart-generation'
    }
  },
  [setCurrentChart]: (state, { payload }) => {
    state.currentChart = payload
  },
  [uploadChartRequestSuccess]: (state, { payload }) => {
    state.chartsLocation = payload
  },
  [chartShareRequest]: state => {
    state.isSharing = true
  },
  [chartShareRequestFail]: state => {
    state.isSharing = false
  },
  [chartShareRequestSuccess]: state => {
    state.isSharing = false
  },
  [addChart]: (state, { payload }) => {
    state.chartsObject = { ...state.chartsObject, ...payload }
  }
})

export default chartsReducer
