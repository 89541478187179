import { configureStore } from '@reduxjs/toolkit'
import {
  connectRouter,
  routerMiddleware as createRouterMiddleware
} from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import * as reducers from './reducers'
import rootSaga from './sagas'
import { persistStore, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage'

const createStore = (history, container) => {
  const { resolve } = container // Get Services resolver

  const rootReducer = persistCombineReducers(
    {
      key: 'root',
      storage,
      whitelist: [
        'authReducer',
        'scheduleReducer',
        'eventsReducer',
        'siteConfigReducer',
        'statisticsReducer',
        'eventDetailsReducer',
        'advisorSearchReducer'
      ],
      blacklist: ['notificationReducer']
    },
    { ...reducers, router: connectRouter(history) }
  )

  const sagaMiddleware = createSagaMiddleware()
  const routerMiddleware = createRouterMiddleware(history)
  const storeOptions = {
    reducer: rootReducer,
    middleware: [sagaMiddleware, routerMiddleware]
  }
  const store = configureStore(storeOptions)
  sagaMiddleware.run(rootSaga, resolve)
  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(rootReducer)
    })
  }
  const persistor = persistStore(store)
  return { store, persistor }
}

export default createStore
