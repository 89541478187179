import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as advisorProfileActions } from 'modules/advisorProfile/AdvisorProfileReducer'
import { getAdvisorList } from '../formAdvisorSearch/AdvisorSearchSelector'
import { isEmpty } from 'utils'

/**
 * Performs single profile search
 *
 * @param payload
 * @param services
 */
function* Request(services, { payload }) {
  const profileRequest = services('AdvisorProfileRequestService')
  const ListingsParser = services('ListingsParser')
  try {
    // GET IS ADMIN FOR LISTINGS PARSER
    const advisorList = yield select(getAdvisorList())

    const memberId = payload.memberId
    const hasList = payload.hasList
    const isSummary = payload.isSummary
    // console.log(memberId)
    // console.log(hasList)
    // console.log(isSummary)

    if (isSummary) {
      // IF IS PROFILE SUMMARY DON'T DO PUBMED, JUST ADVISORSEARCH
      // console.log('is summary')
      const data = yield call([profileRequest, 'fetch'], memberId)
      let parsedListings = yield call([ListingsParser, 'profileRiver'], {
        data
      })
      yield put(
        advisorProfileActions.profileSuccess({ profile: parsedListings })
      )
    } else if (hasList) {
      // IF HAS LIST, UPDATE EXISTING
      // console.log('has list')
      // console.log('BEFORE')
      // console.log(advisorList)
      const newAdvisors = [...advisorList]
      const parsedListings = yield call([ListingsParser, 'getPubmedData'], {
        data: newAdvisors
      })
      yield put(
        advisorProfileActions.profileSuccess({ profile: parsedListings })
      )
      // console.log('AFTER')
      // console.log(parsedListings)
    } else {
      // console.log('FULL')
      const data = yield call([profileRequest, 'fetch'], memberId)
      const parsedListings = yield call([ListingsParser, 'profileRiver'], {
        data
      })
      const pubmedListing = yield call([ListingsParser, 'getPubmedData'], {
        data: parsedListings
      })
      // console.log('AFTER')
      // console.log(pubmedListing)
      // VALIDATE SEARCH RESULTS
      if (!isEmpty(pubmedListing)) {
        yield put(
          advisorProfileActions.profileSuccess({ profile: pubmedListing })
        )
      } else {
        yield put(
          notificationActions.notificationEnqueue({
            message: 'No advisors found with that id, please make a new search',
            duration: 0
          })
        )
        yield put(advisorProfileActions.profileFail())
      }
    }
  } catch (e) {
    console.error(e)
    yield put(advisorProfileActions.profileFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: 'No advisors found with that id, please make a new search',
        duration: 0
      })
    )
  }
}

export default function* watchRequest(services) {
  yield all([takeEvery('PROFILE_REQUEST', Request, services)])
}
