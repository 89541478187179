import PropTypes from 'prop-types'
import AvatarComponent from 'components/ui/Avatar'
import styles from './Avatar.module.sass'
import { isEmpty } from 'utils'

const Avatar = ({
  fullName = '',
  headShot = '',
  size = 70,
  border = false
}) => {
  return (
    <div className={styles.avatar}>
      <AvatarComponent
        image={!isEmpty(headShot) ? headShot : ''}
        name={fullName}
        size={size}
        border={border}
      />
    </div>
  )
}
Avatar.propTypes = {
  /**
   * Link to headShot img
   */
  headShot: PropTypes.string,
  /**
   * Will display the initials in case there is no headShot
   */
  fullName: PropTypes.string,
  /**
   * Avatar size
   */
  size: PropTypes.number,
  border: PropTypes.bool
}
export default Avatar
