import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

const ScrollToTop = ({ children }) => {
  const location = useLocation()
  useEffect(() => {
    if (
      // Scroll to top if not explicitly prevented (prevented for pagination links)
      location.state?.scroll !== false
    ) {
      window.scrollTo(0, 0)
    }
  }, [location.pathname, location.state])
  return children
}
ScrollToTop.propTypes = {
  /**
   * Children wrapped with ScrollToTop functionality
   */
  children: PropTypes.node
}
export default ScrollToTop
