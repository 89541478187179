// Chart keys ordered and in categories
const chartCatalog = {
  affinity: [
    'brand_champions',
    'industry_affinity_by_quintile',
    'pharma_affinity'
  ],
  demographics: ['years_in_practice', 'gender'],
  digital: [
    'activity_by_device',
    'activity_by_os',
    'activity_by_day_of_week',
    'activity_by_time_of_day'
  ],
  geographics: [
    'lima_metropolitan_city',
    'mdm_country',
    'urban_suburban_rural'
  ],
  influence: [
    'authorship',
    'city_leaders',
    'recent_publication_date',
    'stature'
  ],
  practice: [
    'practice',
    'hospital_type',
    'hospital_size_beds',
    'group_practice_members',
    'aom',
    'idns'
  ]
}

/**
 * - Main Function -
 * Parses response data into readable and easy manageable data for Charts components
 * @param { Object } responseData
 * @returns { Object } parsedResponse
 */
const ChartsParser = responseData => {
  if (!responseData) {
    responseData = {}
  }

  const { charts = {}, listSize = 0, universe = 0 } = responseData

  const parsedCharts = {}

  const parsedResponse = {
    listSize: listSize || 0,
    universe: universe || 0,
    charts: {}
  }

  // First parse every value needed for each chart in responseData
  Object.entries(charts || {}).forEach(([name, chart]) => {
    if (chart) {
      const parsedChart = individualChartParser(chart)

      if (parsedChart.show) {
        parsedCharts[name] = {
          name,
          ...parsedChart
        }
      }
    }
  })

  const parsedChartKeys = Object.keys(parsedCharts)

  // Acording to chartCatalog
  // Order parsed chart data and put them into its respective category
  Object.entries(chartCatalog).forEach(([category, chartKeys]) => {
    const categoryCharts = []

    chartKeys.forEach(chartKey => {
      if (parsedChartKeys.includes(chartKey)) {
        categoryCharts.push(parsedCharts[chartKey])
      }
    })

    parsedResponse.charts[category] = categoryCharts
  })

  return parsedResponse
}

/**
 * - Main Function -
 * Parses response data into readable and easy manageable data for Charts components
 * In case of multiple Combinations at graphing
 * @param { Object } responseData
 * @returns { Object } parsedResponse
 */
const BreakdownChartsParser = responseData => {
  const {
    splitsCharts,
    listSize,
    universe,
    filters: { combinations, _totalMembersCount, _totalUniversesCount }
  } = responseData

  const parsedChartsArrays = {}

  const parsedResponse = {
    listSize,
    universe,
    tableCombinations: combinations,
    _totalMembersCount,
    _totalUniversesCount,
    charts: {}
  }

  // First parse every value needed for each chart in responseData
  Object.entries(splitsCharts).forEach(([name, chartsArray]) => {
    if (chartsArray && chartsArray.length > 0) {
      parsedChartsArrays[name] = []

      chartsArray.forEach((chart, idx) => {
        if (chart) {
          const key = `${name}-${idx}`
          const parsedChart = individualChartParser(chart)
          const { _key = null } = chart

          if (parsedChart.show) {
            parsedChartsArrays[name].push({
              name: key,
              _key,
              ...parsedChart
            })
          }
        }
      })
    }
  })

  const parsedChartKeys = Object.keys(parsedChartsArrays)

  // Acording to chartCatalog
  // Order parsed charts of arrays and put them into its respective category
  Object.entries(chartCatalog).forEach(([category, chartKeys]) => {
    const categoryCharts = []

    chartKeys.forEach(chartKey => {
      if (parsedChartKeys.includes(chartKey)) {
        categoryCharts.push(...parsedChartsArrays[chartKey])
      }
    })

    parsedResponse.charts[category] = categoryCharts
  })

  return parsedResponse
}

/**
 * Parses single Chart data
 * @param { Object } chart
 * @returns { Object } parsedChart
 */
function individualChartParser(chart) {
  const { title, tooltip, yAxis } = chart

  const { labels, completeLabels } = parseRowLabels(chart)

  const subtitle = parseChartSubtitle(chart)
  const numData = parseNumData(chart, subtitle)

  const { newTotal, stepSize } = parseChartScale(chart, subtitle)

  const { dataset, legendEnabled } = parseChartDataset(chart, numData)

  const height = parseChartHeight(chart)

  if (dataset.length === 0) {
    return { show: false }
  } else {
    const { options, chartData } = parseChartData({
      ...chart,
      total: newTotal,
      dataset,
      legendEnabled,
      subtitle,
      labels,
      completeLabels,
      stepSize
    })

    return {
      title,
      tooltip,
      yAxis,
      height,
      options,
      chartData,
      show: true
    }
  }
}

/**
 * Trims rows' labels when needed and saves complete labels for tooltips
 * @param { Object } chart
 * @returns { Array, Arrray } { labels, completeLabels }
 */
function parseRowLabels(chart) {
  const labels = []
  const completeLabels = []

  chart.rows.forEach((row, index) => {
    if (chart.cumulative && chart.percentile) {
      if (index > 0) {
        const label =
          row[0] && row[0].length > 30
            ? `${row[0].slice(0, 30).trim()}...`
            : row[0] || ''

        const completeLabel = row[0] ? row[0] : row[0] || ''

        labels.push(label)
        completeLabels.push(completeLabel)
      }
    } else if (index > 0 && row[0] !== null) {
      const label =
        row[0].length > 30 ? `${row[0].slice(0, 30).trim()}...` : row[0]

      labels.push(label)
      completeLabels.push(row[0])
    }
  })

  return { labels, completeLabels }
}

/**
 *  Extracts chart subtitle
 * @param { Object } chart
 * @returns { string } subtitle
 */
function parseChartSubtitle(chart) {
  let subtitle = chart.rows[0][1]

  if (chart.cumulative) {
    subtitle = chart.rows[0][0]
  }

  return subtitle
}

/**
 *  Formats chart rows' values into 4 sets in case of multiple divisions of the Chart bars
 * @param { Object } chart
 * @param { string } subtitle
 * @returns { Object } numData
 */
function parseNumData(chart, subtitle) {
  let numData = {
    numData1: [],
    numData2: [],
    numData3: [],
    numData4: []
  }

  if (
    (chart.percentile && chart.cumulative) ||
    subtitle.startsWith('#') ||
    chart.cumulative
  ) {
    chart.rows.forEach((row, index) => {
      if (index > 0) {
        numData = {
          numData1: [...numData.numData1, row[1]],
          numData2: [...numData.numData2, row[2]],
          numData3: [...numData.numData3, row[3]],
          numData4: [...numData.numData4, row[4]]
        }
      }
    })
  } else {
    chart.rows.forEach((row, index) => {
      if (index > 0) {
        numData = {
          numData1: [...numData.numData1, row[1]],
          numData2: [...numData.numData2, row[3]]
        }
      }
    })
  }

  return numData
}

/**
 *  Measures the chart rows' values according to its total value # of digits
 *      in order to adjust the chart scale.
 * @param { Object } chart
 * @param { string } subtitle
 * @returns { number, number } { newTotal, stepSize }
 */
function parseChartScale(chart, subtitle) {
  let digits = chart.total.toString().length
  let newTotal = chart.total

  if (chart.cumulative && !chart.percentile) {
    switch (digits) {
      case 2:
        newTotal = Math.ceil(newTotal / 20) * 20
        break
      case 3:
        newTotal = Math.ceil(newTotal / 200) * 200
        break
      case 4:
        newTotal = Math.ceil(newTotal / 2000) * 2000
        break
      case 5:
        newTotal = Math.ceil(newTotal / 20000) * 20000
        break
      default:
        newTotal = Math.ceil(newTotal / 2) * 2
    }
  } else if (subtitle.startsWith('#')) {
    // get highest bar and round that to nearest multiple of 4
    let highestValue = 0
    chart.rows.forEach((row, index) => {
      if (index > 0) {
        if (row[1] > highestValue) {
          highestValue = row[1]
        }
      }
    })

    digits = highestValue.toString().length

    switch (digits) {
      case 2:
        newTotal = Math.ceil(highestValue / 20) * 20
        break
      case 3:
        newTotal = Math.ceil(highestValue / 200) * 200
        break
      case 4:
        newTotal = Math.ceil(highestValue / 2000) * 2000
        break
      case 5:
        newTotal = Math.ceil(highestValue / 20000) * 20000
        break
      default:
        newTotal = Math.ceil(newTotal / 2) * 2
    }
  }

  return { newTotal, stepSize: newTotal / 4 }
}

/**
 *  Measures the convenient height of the chart
 *      according to its # of rows
 * @param { Object } chart
 * @returns { string } height
 */
function parseChartHeight(chart) {
  const numberOfRows = chart.rows.length - 1
  let height = '110px'

  if (numberOfRows > 2) {
    const initialHeight = 110
    height = `${initialHeight + numberOfRows * 15}px`
  }

  if (chart.rows[0].length === 5) {
    const initialHeight = 120
    height = `${initialHeight + numberOfRows * 25}px`
  }

  return height
}

/**
 *  Creates the chart dataset needed for ChartJS to graph it
 * @param { Object } chart
 * @param { Object } numData
 * @returns { Array, boolean } { dataset, legendEnabled }
 */
function parseChartDataset(chart, numData) {
  if (numData.numData1.length > 0) {
    let dataset = []
    let legendEnabled = false

    if ((chart.cumulative && chart.percentile) || chart.cumulative) {
      dataset = [
        {
          label: chart.rows[0][1],
          data: numData.numData1,
          backgroundColor: 'rgb(48, 39, 116)'
        },
        {
          label: chart.rows[0][2],
          data: numData.numData2,
          backgroundColor: 'rgb(126, 177, 216)'
        },
        {
          label: chart.rows[0][3],
          data: numData.numData3,
          backgroundColor: 'rgb(104, 96, 170)'
        },
        {
          label: chart.rows[0][4],
          data: numData.numData4,
          backgroundColor: 'rgb(110, 110, 110)'
        }
      ]
    } else if (chart.rows[0].length === 5) {
      legendEnabled = true

      dataset = [
        {
          label: 'Target List',
          data: numData.numData1,
          backgroundColor: ['rgb(37, 172, 137)']
        },
        {
          label: 'Survey Participants',
          data: numData.numData2,
          backgroundColor: ['rgb(89, 127, 203)']
        }
      ]
    } else {
      dataset = [
        {
          label: chart.rows[0][1],
          data: numData.numData1,
          backgroundColor: ['rgb(37, 172, 137)']
        }
      ]
    }

    return { dataset, legendEnabled }
  } else {
    return { dataset: [], legendEnabled: false }
  }
}

/**
 *  Parses the complete chart data needed for ChartJS to graph it and its corresponding config options
 * @param { Object } chart - Adjusted previously to included new calculated data obtained in previous functions
 * @returns { Object, Object } { options, chartData }
 */
function parseChartData(chart) {
  if (chart.dataset.length > 0) {
    let options = {
      animation: {
        onComplete: null
      }
    }

    if (chart.percentile) {
      options = {
        ...options,
        maintainAspectRatio: false, //set to false to specify the height in CSS
        barPercentage: 0.7, // Size of the bar
        indexAxis: 'y', //Set the bar chart in the Y axis, to make it horizontal
        elements: {
          bar: {
            borderWidth: 0 // no border
          }
        },
        responsive: true,
        plugins: {
          legend: {
            display: chart.legendEnabled // only enabled if in compare mode
          },
          title: {
            display: false,
            text: chart.title
          },
          subtitle: {
            display: true,
            position: 'bottom',
            fullSize: false,
            align: 'center',
            text: chart.subtitle
          },
          datalabels: {
            display: false,
            anchor: 'end',
            align: 'end',
            offset: 10,
            formatter: value => {
              if (chart.percentile) {
                return value ? `${value}%` : ''
              } else {
                return value || ''
              }
            }
          },
          tooltip: {
            callbacks: {
              title: function (context) {
                const index = context[0].dataIndex

                return chart.completeLabels[index]
              }
            }
          }
        },
        scales: {
          x: {
            min: 0,
            max: 100,
            ticks: {
              stepSize: 25,
              callback: function (value) {
                return value + '%'
              }
            }
          },
          y: {
            grid: {
              display: false
            }
          }
        }
      }
    } else {
      options = {
        ...options,
        maintainAspectRatio: false, //set to false to specify the height in CSS
        aspectRatio: 2,
        barPercentage: 0.7, // Size of the bar
        indexAxis: 'y', //Set the bar chart in the Y axis, to make it horizontal
        elements: {
          bar: {
            borderWidth: 0 // no border
          }
        },
        responsive: true,
        plugins: {
          legend: {
            display: chart.legendEnabled // only enabled in compare mode
          },
          title: {
            display: false,
            text: chart.title
          },
          subtitle: {
            display: true,
            position: 'bottom',
            fullSize: false,
            align: 'center',
            text: chart.subtitle
          },
          datalabels: {
            display: false,
            anchor: 'end',
            align: 'end',
            offset: 10,
            formatter: value => {
              if (chart.percentile) {
                return value ? `${value}%` : ''
              } else {
                return value || ''
              }
            }
          },
          tooltip: {
            callbacks: {
              title: function (context) {
                const index = context[0].dataIndex

                return chart.completeLabels[index]
              }
            }
          }
        },
        scales: {
          x: {
            min: 0,
            max: chart.total,
            precision: 0,
            ticks: {
              stepSize: chart.stepSize
            }
          },
          y: {
            grid: {
              display: false
            }
          }
        }
      }
    }

    if (chart.dataset.length === 4) {
      if (chart.percentile && chart.cumulative) {
        options = {
          ...options,
          scales: {
            x: {
              min: 0,
              max: 100,
              stacked: true,
              ticks: {
                stepSize: 25
              }
            },
            y: {
              grid: {
                display: false
              },
              stacked: true
            }
          },
          plugins: {
            ...options.plugins,
            datalabels: {
              ...options.plugins.datalabels,
              anchor: 'end',
              align: 'left',
              offset: 4,
              color: 'white'
            }
          }
        }
      } else {
        options = {
          ...options,
          scales: {
            x: {
              min: 0,
              max: chart.total,
              stacked: true,
              ticks: {
                stepSize: chart.stepSize
              }
            },
            y: {
              grid: {
                display: false
              },
              stacked: true
            }
          }
        }
      }
    }

    return {
      options,
      chartData: {
        labels: chart.labels,
        datasets: chart.dataset
      }
    }
  }
}

export { ChartsParser, BreakdownChartsParser }
export default ChartsParser
