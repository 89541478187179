import { createReducer, createAction } from '@reduxjs/toolkit'

export const labLoadRequest = createAction('LAB_LOAD_REQUEST')
export const labLoadSuccessRequest = createAction('LAB_LOAD_SUCCESS_REQUEST')
export const labLoadFailRequest = createAction('LAB_LOAD_FAIL_REQUEST')
export const actions = {
  labLoadRequest,
  labLoadSuccessRequest,
  labLoadFailRequest
}

const initialValue = {
  isFetching: true,
  error: ''
}

const labReducer = createReducer(initialValue, {
  [labLoadRequest]: state => {
    state.isFetching = true
    state.error = ''
  },
  [labLoadSuccessRequest]: state => {
    state.isFetching = false
    state.error = ''
  },
  [labLoadFailRequest]: (state, { payload: { error } }) => {
    state.isFetching = false
    state.error = error
  }
})

export default labReducer
