import { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Icon from '../Icon'
import Ripple from '../Ripple'
import styles from './ButtonFloatingAction.module.sass'
import icons from '@psl/icons/listIcons'
import cx from 'classnames'
import { between } from '../utils/propValidators'

class ButtonFloatingAction extends Component {
  static displayName = 'ButtonFloatingAction'
  static defaultProps = {
    icon: 'svg/material-design-icons/navigation/check',
    iconSize: 24,
    text: 'Submit',
    iconColor: '#ffffff',
    tier: 'action',
    disabled: false,
    submitting: false
  }
  static propTypes = {
    /** Whether or not the button is submitting */
    submitting: PropTypes.bool,
    /** The icon color */
    iconColor: PropTypes.string,
    /** An additional custom className for the root element */
    className: PropTypes.string,
    /** Whether or not the button is disabled */
    disabled: PropTypes.bool,
    /** The text shown in the button's aria-label attribute */
    text: PropTypes.string,
    /** The button color tier (defined by the theme) */
    tier: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'action']),
    /** The icon to show on the button */
    icon: PropTypes.oneOf(icons),
    /** The icon size, in pixels (1 - 45) */
    iconSize: between({ gte: 1, lte: 45 }),
    /** The submitting spinner stroke color */
    spinnerStrokeColor: PropTypes.string,
    /** The submitting spinner fill color */
    spinnerFillColor: PropTypes.string,
    /** A CSS modules style object to override default theme */
    altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
  }
  state = {
    focused: false
  }
  rippleRef = createRef()
  buttonFocus = event => {
    this.rippleRef.current.focus(event)
    this.setState({ focused: true })
  }
  buttonBlur = () => {
    this.rippleRef.current.blur()
    this.setState({ focused: false })
  }
  render = () => {
    const {
      icon,
      iconSize,
      text,
      iconColor,
      submitting,
      className,
      spinnerStrokeColor,
      spinnerFillColor,
      disabled,
      tier,
      altTheme,
      ...other
    } = this.props
    const themeStyles = { ...styles, ...altTheme }
    const { focused } = this.state
    return (
      <div
        className={cx({
          [themeStyles.container]: true,
          [themeStyles.focused]: focused
        })}
      >
        <Ripple
          role="ripple"
          ref={this.rippleRef}
          className={cx({
            [themeStyles.floatingAction]: true,
            [themeStyles.disabled]: disabled,
            [themeStyles[tier]]: true,
            [themeStyles.submitting]: submitting,
            [className]: className
          })}
        >
          <button
            type="submit"
            aria-label={text}
            disabled={disabled || submitting}
            onFocus={this.buttonFocus}
            onBlur={this.buttonBlur}
            {...other}
          >
            <Icon icon={icon} iconSize={iconSize} iconColor={iconColor} />
          </button>
        </Ripple>
        {submitting ? (
          <Spinner
            strokeColor={spinnerStrokeColor}
            fillColor={spinnerFillColor}
            strokeWidth={3}
          />
        ) : null}
      </div>
    )
  }
}

export default ButtonFloatingAction
