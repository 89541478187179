import UserTransactionML from './UserTransactionML'

describe('User Transaction Middle Layer', () => {
  let create = jest.fn().mockReturnValue({})
  const SessionService = {
    getFromCache: jest.fn().mockReturnValue()
  }
  const getState = jest.fn().mockReturnValue()
  const UserTransactionParser = {
    lastAction: jest.fn().mockReturnValue()
  }
  beforeEach(() => {
    SessionService.getFromCache.mockReset()
    getState.mockReset()
    UserTransactionParser.lastAction.mockReset()
  })
  test('Create client', () => {
    const api = UserTransactionML(
      SessionService,
      getState,
      UserTransactionParser
    )
    const client = api.createClient()

    // Has all interceptors
    expect(client.interceptors.request.handlers).toEqual([])
    expect(client.interceptors.response.handlers).toEqual([])
  })
  test.skip('Create fetchWithStandardEmail object', () => {
    // Mock Services
    SessionService.getFromCache
      .mockReturnValueOnce({
        access: 'accessToken'
      }) // state
      .mockReturnValueOnce('abc123') // interactionId
      .mockReturnValueOnce('165') // articleId
      .mockReturnValueOnce('123-abc-456-def') // id_key/deviceId
      .mockReturnValueOnce('') // CampaignId

    getState.mockReturnValue({
      authReducer: {
        isAuth: true,
        dspId: 'dsp'
      }
    })

    UserTransactionParser.lastAction.mockReturnValue('lastAction')

    // Create the ML instance
    const api = UserTransactionML(
      SessionService,
      getState,
      UserTransactionParser
    )

    // mock the createClient function
    api.createClient = jest.fn().mockReturnValue({
      request: create
    })

    // Call fetch function
    api.fetchWithStandardEmail('/endpoint')

    // Tests
    expect(create).toBeCalled()
  })
  test.skip('Create fetchWithStandardEmail object when isAuth: false and dspId: null', () => {
    // Mock Services
    SessionService.getFromCache
      .mockReturnValueOnce({
        access: 'accessToken'
      }) // state
      .mockReturnValueOnce('abc123') // interactionId
      .mockReturnValueOnce('') // articleId
      .mockReturnValueOnce('123-abc-456-def') // id_key/deviceId
      .mockReturnValueOnce('') // CampaignId

    getState.mockReturnValue({
      authReducer: {
        isAuth: false,
        dspId: null
      }
    })

    UserTransactionParser.lastAction.mockReturnValue('lastAction')

    // Create the ML instance
    const api = UserTransactionML(
      SessionService,
      getState,
      UserTransactionParser
    )

    // mock the createClient function
    api.createClient = jest.fn().mockReturnValue({
      request: create
    })

    // Call fetch function
    api.fetchWithStandardEmail('/endpoint')

    // Tests
    expect(create).toBeCalled()
  })
})
