class SmartsMLError extends Error {
  constructor(message, responseData) {
    super(message)
    this.responseData = responseData
    // For IE11 badness...
    this.constructor = SmartsMLError
  }

  getResponseData() {
    return this.responseData
  }
}

export default SmartsMLError
