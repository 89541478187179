import { useSelector } from 'react-redux'
import Dropdown from 'components/DropdownInput'
import TextInput from 'components/TextInput'
import * as listIngestionSelectors from '../ListIngestionSelector'
import styles from './form.module.sass'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const { getOptions } = listIngestionSelectors

const XlsxForm = () => {
  const sheetOptions = useSelector(getOptions('sheets'))
  const { parsedSheetsOptions = {} } = sheetOptions
  return (
    <div className={cx(styles.formContainer)} data-testid="xlsxFormContainer">
      <Dropdown
        label="Select sheet"
        name="xlsxSheet"
        required
        traditional
        options={parsedSheetsOptions}
      />
      <TextInput
        label="Heading Row"
        name="headingRow"
        type="text"
        required
        traditional
      />
    </div>
  )
}
export default XlsxForm
