import siteConfig from 'site.config.json'

const { title, configuration } = siteConfig

const basename = process.env.REACT_APP_BASEPATH

const channelName = title

/**
 * For PHNX API
 */
const phnxSiteID = process.env.REACT_APP_PHNX_SITEID

/**
 * IDH setup
 */
const idhSiteID = process.env.REACT_APP_IDH_SITEID
const idhBrandId = process.env.REACT_APP_IDH_BRANDID
const idhCampaignID = process.env.REACT_APP_IDH_CAMPAIGNID
const idhSiteId = process.env.REACT_APP_IDH_SITEID
const brandCode = process.env.REACT_APP_BRAND_CODE

/**
 * Piwik Constants
 */
const piwikSiteId = process.env.REACT_APP_PIWIK_SITEID
const siteUrl = process.env.REACT_APP_PIWIK_URL
const scriptUrl = process.env.REACT_APP_PIWIK_SCRIPT_URL
const piwikEnabled = process.env.REACT_APP_PIWIK_DEV_ENABLED
const scriptName = process.env.REACT_APP_PIWIK_SCRIPT_NAME
const nodeEnv = process.env.NODE_ENV

/**
 * Ads
 */
const adsProperty = process.env.REACT_APP_ADS_PROPERTY
const adsActionUrl = process.env.REACT_APP_AIMATCH_ACTION

/**
 * Constants for routing and UI navigation.
 */
const sessionStorage = process.env.REACT_APP_SESSION_STORAGE
const sessionDomainStorage = process.env.REACT_APP_SESSION_DOMAIN
const defaultSpecialty = configuration.profile.defSpecialty
const defaultProfession = configuration.profile.defProfession

/**
 * Auth constants
 */
const paramsDuration = configuration.auth.cacheStateLife
const roleLevel = {
  full: ['auth'],
  partial: ['soft'],
  limited: ['limited'],
  anon: ['default']
}
const authVer = {
  v1: 'V1',
  v2: 'V2'
}
const authRoles = ['auth', 'superadmin', 'soft']
const anonRoles = ['default', 'anon']
const limitedRoles = ['limited', 'default']

export {
  basename,
  channelName,
  roleLevel,
  authVer,
  phnxSiteID,
  piwikSiteId,
  siteUrl,
  scriptUrl,
  scriptName,
  piwikEnabled,
  nodeEnv,
  adsProperty,
  adsActionUrl,
  idhCampaignID,
  idhSiteID,
  brandCode,
  sessionStorage,
  sessionDomainStorage,
  defaultSpecialty,
  defaultProfession,
  idhBrandId,
  idhSiteId,
  authRoles,
  anonRoles,
  limitedRoles,
  paramsDuration
}
