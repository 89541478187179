import { createSelector } from '@reduxjs/toolkit'

export const thresholdSelector = state => state.thresholdReducer

export const getIsFetching = () =>
  createSelector(thresholdSelector, threshold => threshold.fetching)
export const getSearchState = () =>
  createSelector(thresholdSelector, threshold => threshold.searchState)
export const getThresholdObject = () =>
  createSelector(thresholdSelector, threshold => threshold.thresholdObject)
export const getPagination = () =>
  createSelector(thresholdSelector, threshold => threshold.pagination)
export const getThresholdFetching = () =>
  createSelector(thresholdSelector, threshold => threshold.thresholdFetch)
export const getSingleThreshold = () =>
  createSelector(thresholdSelector, threshold => threshold.singleThreshold)
export const getModalOpen = () =>
  createSelector(thresholdSelector, threshold => threshold.modalOpen)
export const getDeleteFetching = () =>
  createSelector(thresholdSelector, threshold => threshold.deleteFetching)
export const getSelected = () =>
  createSelector(thresholdSelector, threshold => threshold.selected)
