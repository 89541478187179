import PropTypes from 'prop-types'
import styles from './Typography.module.sass'
import cx from 'classnames'

export const typographyTypes = [
  'greeting',
  'display4',
  'display3',
  'display2',
  'display1',
  'headline',
  'title',
  'subheading',
  'list-primary',
  'list-secondary',
  'list-institute',
  'menu-item',
  'section-heading',
  'body2',
  'body1',
  'caption',
  'button',
  'link',
  'error',
  'quote',
  'logo',
  'footnote',
  'thumb-label'
]

const Typography = ({
  children,
  type,
  align,
  noWrap,
  gutterBottom,
  paragraph,
  color,
  uppercase,
  component,
  className,
  altTheme,
  fontFamily,
  style,
  ...other
}) => {
  const themeStyles = { ...styles, ...altTheme }
  const TypeCheck = typoType => {
    switch (typoType) {
      case 'display4':
      case 'display3':
      case 'display2':
      case 'display1':
      case 'headline':
        return 'h1'
      case 'title':
        return 'h2'
      case 'subheading':
      case 'list-primary':
      case 'list-institute':
        return 'h3'
      case 'section-heading':
        return 'h3'
      case 'body2':
      case 'footnote':
        return 'aside'
      case 'body1':
      case 'quote':
      case 'logo':
      case 'greeting':
        return 'p'
      case 'caption':
        return 'figcaption'
      case 'button':
      case 'menu-item':
      case 'thumb-label':
        return 'span'
      case 'link':
        return 'a'
      case 'list-secondary':
      case 'error':
      default:
        return 'div'
    }
  }
  const ElementTag = component || TypeCheck(type)
  return (
    <ElementTag
      {...other}
      className={cx(
        themeStyles[type === 'error' ? 'errorElement' : type], // Must distinguish 'error' type and color classes
        themeStyles[align],
        noWrap && themeStyles.noWrap,
        gutterBottom && themeStyles.gutter,
        paragraph && themeStyles.paragraph,
        color && themeStyles[color],
        uppercase && themeStyles.uppercase,
        className
      )}
      style={{ ...style, fontFamily }}
    >
      {children}
    </ElementTag>
  )
}
Typography.displayName = 'Typography'
Typography.defaultProps = {
  align: '',
  noWrap: false,
  color: '',
  paragraph: false,
  gutterBottom: false,
  type: 'body1'
}
Typography.propTypes = {
  /**
   * An additional custom className for the root element
   */
  className: PropTypes.string,
  /**
   * The component children
   */
  children: PropTypes.node.isRequired,
  /**
   * The typography type
   */
  type: PropTypes.oneOf(typographyTypes),
  /**
   * The component alignment
   */
  align: PropTypes.oneOf(['', 'left', 'center', 'right', 'justify']),
  /**
   * The text does not wrap
   */
  noWrap: PropTypes.bool,
  /**
   * Select a color from the theme
   */
  color: PropTypes.oneOf([
    '',
    'inherit',
    'primary',
    'secondary',
    'tertiary',
    'error',
    'text-primary',
    'text-secondary',
    'action'
  ]),
  /**
   * The text has a bottom gutter
   */
  gutterBottom: PropTypes.bool,
  /**
   * use paragraph spacing (margin-bottom)
   */
  paragraph: PropTypes.bool,
  /**
   * A CSS modules style object to override default theme
   */
  altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  /**
   * The text is uppercase
   */
  uppercase: PropTypes.bool,
  /**
   * If provided, the rendered element
   */
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  /**
   * If provided, the 'font-family' style provided to the element
   */
  fontFamily: PropTypes.string,
  /**
   * If provided, CSS styles applied to the element
   */
  style: PropTypes.object
}
export default Typography
