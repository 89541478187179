import { createSelector } from '@reduxjs/toolkit'

export const dataCachesSelector = state => state.dataCachesReducer

export const getIsFetching = () =>
  createSelector(dataCachesSelector, dataCaches => dataCaches.isFetching)

export const getData = () =>
  createSelector(dataCachesSelector, dataCaches => dataCaches.data)

export const getPagination = () =>
  createSelector(dataCachesSelector, dataCaches => dataCaches.pagination)

export const getFormState = () =>
  createSelector(dataCachesSelector, dataCaches => dataCaches.formState)

export const getisJSONFetching = () =>
  createSelector(dataCachesSelector, dataCaches => dataCaches.isJSONFetching)

export const getRecordJSON = () =>
  createSelector(dataCachesSelector, dataCaches => dataCaches.recordJSON)

export const getRecordName = () =>
  createSelector(dataCachesSelector, dataCaches => dataCaches.recordName)

export const getIsDownloadReady = () =>
  createSelector(dataCachesSelector, dataCaches => dataCaches.downloadReady)

export const getIsClearing = () =>
  createSelector(dataCachesSelector, dataCaches => dataCaches.isClearing)

export const getIsRebuilding = () =>
  createSelector(dataCachesSelector, dataCaches => dataCaches.isRebuilding)
