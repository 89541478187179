import { createReducer, createAction } from '@reduxjs/toolkit'

export const fullProfileViewsRequest = createAction(
  'FULL_PROFILE_VIEWS_REQUEST'
)
export const fullProfileViewsSuccess = createAction(
  'FULL_PROFILE_VIEWS_SUCCESS'
)
export const fullProfileViewsFail = createAction('FULL_PROFILE_VIEWS_FAIL')

export const actions = {
  fullProfileViewsRequest,
  fullProfileViewsSuccess,
  fullProfileViewsFail
}

const fullProfileViewsReducer = createReducer(
  {
    fetching: true,
    access: false,
    profileLimitNumber: 0
  },
  {
    [fullProfileViewsRequest]: state => {
      state.fetching = true
    },
    [fullProfileViewsSuccess]: (state, { payload: { access, count } }) => {
      state.fetching = false
      state.access = access
      state.profileLimitNumber = count
    },
    [fullProfileViewsFail]: state => {
      state.fetching = false
      state.access = false
    }
  }
)

export default fullProfileViewsReducer
