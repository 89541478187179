import * as Yup from 'yup'

const validationSchema = {
  filters: Yup.object().shape({}),
  'project-metadata': Yup.object().shape({
    domain: Yup.string().required('Required'),
    clientProduct: Yup.string().when('domain', {
      is: domain => domain === 'FW',
      then: Yup.string(),
      otherwise: Yup.string().required('Required')
    }),
    brand: Yup.string().when('domain', {
      is: domain => domain === 'FW',
      then: Yup.string(),
      otherwise: Yup.string().required('Required')
    }),
    client: Yup.string().when('domain', {
      is: domain => domain === 'FW',
      then: Yup.string(),
      otherwise: Yup.string().required('Required')
    }),
    masterProject: Yup.string().when('domain', {
      is: domain => domain === 'FW',
      then: Yup.string(),
      otherwise: Yup.string().required('Required')
    }),
    project: Yup.string(),
    notes: Yup.string(),
    reportName: Yup.string().required('Required')
  })
}

export default validationSchema
