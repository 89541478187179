import { createReducer, createAction } from '@reduxjs/toolkit'

export const setFormState = createAction('VARIABLES_SET_FORM_STATE')
export const requestVariables = createAction('VARIABLES_REQUEST')
export const completeRequestVariables = createAction(
  'VARIABLES_COMPLETE_REQUEST'
)
export const setSelected = createAction('VARIABLES_SET_SELECTED')
export const requestVariablesDelete = createAction('VARIABLES_DELETE')
export const completeVariablesDelete = createAction('VARIABLES_COMPLETE_DELETE')
export const requestSingleVariable = createAction('VARIABLES_REQUEST_SINGLE')
export const setSingleVariable = createAction('VARIABLES_SET_SINGLE')
export const resetSingleVariable = createAction('VARIABLES_SINGLE_RESET')
export const completeVariablesSingle = createAction('VARIABLES_COMPLETE_SINGLE')

export const actions = {
  setFormState,
  requestVariables,
  completeRequestVariables,
  setSelected,
  requestVariablesDelete,
  completeVariablesDelete,
  requestSingleVariable,
  setSingleVariable,
  resetSingleVariable,
  completeVariablesSingle
}

const variablesReducer = createReducer(
  {
    formState: {
      id: '',
      note: '',
      orderBy: 'id',
      order: 'asc',
      page: 1,
      perPage: 20
    },
    selected: {
      id: null,
      note: ''
    },
    isFirstFetching: true,
    isFetching: true,
    variables: [],
    pagination: {
      total: 0,
      filtered: 0,
      maxPage: 0,
      results: 0,
      page: 1,
      perPage: 25
    },
    isFetchingSingle: true,
    singleVariable: {
      id: '',
      note: '',
      data: {},
      createdAt: 0,
      updatedAt: 0
    }
  },
  {
    [setFormState]: (state, { payload }) => {
      state.formState = { ...state.formState, ...payload }
    },
    [requestVariables]: state => {
      state.isFetching = true
    },
    [completeRequestVariables]: (state, { payload }) => {
      state.variables = payload.data
      state.pagination = payload.pagination
      state.isFirstFetching = false
      state.isFetching = false
    },
    [setSelected]: (state, { payload }) => {
      state.selected.id = payload.id
      state.selected.note = payload.note
    },
    [requestVariablesDelete]: state => {
      state.isFetching = true
    },
    [completeVariablesDelete]: state => {
      state.selected.id = null
      state.selected.note = ''
    },
    [requestSingleVariable]: state => {
      state.isFetchingSingle = true
    },
    [setSingleVariable]: (state, { payload }) => {
      state.singleVariable.id = payload.id
      state.singleVariable.note = payload.note
      state.singleVariable.data = payload.data
      state.singleVariable.createdAt = payload.createdAt
      state.singleVariable.updatedAt = payload.updatedAt
      state.isFetchingSingle = false
    },
    [resetSingleVariable]: state => {
      state.singleVariable = {
        id: '',
        note: '',
        data: {},
        createdAt: 0,
        updatedAt: 0
      }
      state.isFetchingSingle = true
      state.selected.id = null
      state.selected.note = ''
    },
    [completeVariablesSingle]: (state, { payload }) => {
      state.singleVariable = {
        id: payload.id,
        note: payload.note,
        data: payload.data,
        createdAt: payload.createdAt,
        updatedAt: payload.updatedAt
      }
    }
  }
)

export default variablesReducer
