import PropTypes from 'prop-types'
import { useField } from 'formik'
import TextArea from 'components/ui/TextArea'

/**
 * PSL UI TextArea input integration with formik
 * @param props
 * @returns {*}
 * @constructor
 */
const TextAreaInput = ({ ...props }) => {
  const [field, meta] = useField(props)
  return (
    <TextArea
      {...field}
      {...props}
      id={props.name}
      error={
        meta.touched && meta.error
          ? meta.error
          : props.error
          ? props.error
          : null
      }
    />
  )
}
TextAreaInput.propTypes = {
  /**
   * Input label
   */
  label: PropTypes.string.isRequired,
  /**
   * Input name
   */
  name: PropTypes.string.isRequired,
  /**
   * Error
   */
  error: PropTypes.string
}
export default TextAreaInput
