import { createReducer, createAction } from '@reduxjs/toolkit'

export const uploadRequest = createAction('USERSBULK_UPLOAD_REQUEST')
export const uploadRequestSuccess = createAction(
  'USERSBULK_UPLOAD_REQUEST_SUCCESS'
)
export const uploadRequestFail = createAction('USERSBULK_UPLOAD_REQUEST_FAIL')

export const downloadRequest = createAction('USERSBULK_DOWNLOAD_REQUEST')
export const downloadRequestSuccess = createAction(
  'USERSBULK_DOWNLOAD_REQUEST_SUCCESS'
)
export const downloadRequestFail = createAction(
  'USERSBULK_DOWNLOAD_REQUEST_FAIL'
)

export const actions = {
  uploadRequest,
  uploadRequestSuccess,
  uploadRequestFail,
  downloadRequest,
  downloadRequestSuccess,
  downloadRequestFail
}

const usersBulkReducer = createReducer(
  {
    fetching: false,
    type: ''
  },
  {
    [uploadRequest]: state => {
      state.fetching = true
      state.type = 'upload'
    },
    [uploadRequestSuccess]: state => {
      state.fetching = false
      state.type = ''
    },
    [uploadRequestFail]: state => {
      state.fetching = false
      state.type = ''
    },
    [downloadRequest]: state => {
      state.fetching = true
      state.type = 'download'
    },
    [downloadRequestSuccess]: state => {
      state.fetching = false
      state.type = ''
    },
    [downloadRequestFail]: state => {
      state.fetching = false
      state.type = ''
    }
  }
)

export default usersBulkReducer
