import moduleStyles from './Chip.module.sass'
import MUIChip from '@material-ui/core/Chip'

const Chip = props => {
  return (
    <MUIChip role="muiChip" classes={{ root: moduleStyles.root }} {...props} />
  )
}
Chip.displayName = 'Chip'
export default Chip
