import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as chartsSearchActions } from './ChartsSearchReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'

function* requestChartsSearch(services, { payload }) {
  const AdminService = services('AdminService')

  const { chartId = '', title = '', tooltip = '', enable = null } = payload

  const enableToSend =
    enable === 'true' ? true : enable === 'false' ? false : null

  const objectToSearch = {
    ...payload,
    chartId: { like: chartId },
    title: { like: title },
    tooltip: { like: tooltip },
    enable: enableToSend
  }

  try {
    const { data = [], pagination = {} } = yield call(
      [AdminService, 'searchChartsConfiguration'],
      objectToSearch
    )

    yield put(
      chartsSearchActions.requestChartsSearchSuccess({ data, pagination })
    )
  } catch (e) {
    yield put(chartsSearchActions.requestChartsSearchFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to fetch Charts',
        duration: 0
      })
    )
  }
}

function* requestIndividualConfig(services, { payload }) {
  const AdminService = services('AdminService')

  try {
    const response = yield call(
      [AdminService, 'getIndividualChartConfig'],
      payload
    )

    yield put(chartsSearchActions.requestIndividualConfigSuccess(response))
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to get individual chart config',
        duration: 0
      })
    )
    yield put(chartsSearchActions.requestIndividualConfigFail())
  }
}

function* requestSingleChart(services, { payload }) {
  const AdminService = services('AdminService')

  try {
    const response = yield call(
      [AdminService, 'getIndividualChartConfig'],
      payload
    )
    const { chartId, config } = response
    const { title, tooltip, enable, countries, workflows, professions } = config
    const enableToSend = enable ? 'true' : 'false'
    yield put(
      chartsSearchActions.requestSingleChartSuccess({
        chartState: {
          chartId,
          title,
          tooltip,
          enable: enableToSend,
          countries: Object.entries(countries),
          workflows: Object.entries(workflows),
          professions: Object.entries(professions)
        }
      })
    )
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to get single chart',
        duration: 0
      })
    )
    yield put(chartsSearchActions.requestSingleChartFail())
  }
}

function* deleteChart(services, { payload }) {
  const AdminService = services('AdminService')

  try {
    const { message } = yield call(
      [AdminService, 'deleteIndividualChartConfig'],
      payload
    )
    yield put(chartsSearchActions.deleteChartSuccess())
    yield put(
      notificationActions.notificationEnqueue({
        message,
        duration: 0
      })
    )
  } catch (e) {
    yield put(chartsSearchActions.deleteChartFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to delete chart',
        duration: 0
      })
    )
  }
}

function* getVariations(services) {
  const AdminService = services('AdminService')

  try {
    const workflows = yield call(
      [AdminService, 'getVariations'],
      'lists-workflows'
    )
    const professions = yield call(
      [AdminService, 'getVariations'],
      'professions'
    )
    const countries = yield call([AdminService, 'getVariations'], 'countries')
    yield put(
      chartsSearchActions.requestVariationsSuccess({
        workflows,
        professions,
        countries
      })
    )
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to get variations',
        duration: 0
      })
    )
    yield put(chartsSearchActions.requestVariationsFail())
  }
}

function* updateChartConfig(services, { payload }) {
  const AdminService = services('AdminService')

  const { objectToSend, isNew } = payload

  try {
    let { message } = yield call(
      [AdminService, 'updateChartConfig'],
      objectToSend
    )
    if (isNew) {
      message = 'New Charts Configuration Created'
      yield put(chartsSearchActions.setChartCreated(true))
    }
    yield put(
      notificationActions.notificationEnqueue({
        message,
        duration: 0
      })
    )
  } catch (e) {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Unable to update chart config',
        duration: 0
      })
    )
  }
}

export default function* watchRequestsChartsSearch(services) {
  yield all([takeEvery('CHARTS_SEARCH_REQUEST', requestChartsSearch, services)])
  yield all([
    takeEvery(
      'CHARTS_SEARCH_INDIVIDUAL_REQUEST',
      requestIndividualConfig,
      services
    )
  ])
  yield all([
    takeEvery('CHARTS_SEARCH_SINGLE_REQUEST', requestSingleChart, services)
  ])
  yield all([takeEvery('CHARTS_SEARCH_DELETE_CHART', deleteChart, services)])
  yield all([
    takeEvery('CHARTS_SEARCH_REQUEST_VARIATIONS', getVariations, services)
  ])
  yield all([
    takeEvery(
      'CHARTS_SEARCH_UPDATE_CONFIG_REQUEST',
      updateChartConfig,
      services
    )
  ])
}
