/**
 * Typeahead Parser
 */
const TypeaheadParser = () => ({
  /**
   * Parse options for dropdown
   *
   * @param options
   */
  optionsParser(options) {
    return options.reduce(
      (prev, curr) => ({ ...prev, [curr.key]: curr.caption }),
      {}
    )
  },
  /**
   * Parse tooltip for dropdown
   *
   * @param options
   */
  tooltipParser(options) {
    return options.reduce(
      (prev, curr) => ({ ...prev, [curr.key]: curr.tooltip }),
      {}
    )
  }
})

export default TypeaheadParser
