import parse, { domToReact } from 'html-react-parser'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import ActionBar from 'components/Dougall/ActionBar/ActionBar'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { EditIcon } from 'static/icons'

import Typography from 'components/ui/Typography'
// import Warning from 'components/Dougall/Warning/Warning'
import HelpfulMessage from 'components/Dougall/HelpfulMessage/HelpfulMessage'

import {
  getMessages,
  getLinkedPromptId,
  getPromptNum,
  getIsTyping
} from 'modules/dougall/chat/ChatSelector'
import useTracking from 'modules/dougall/tracking/useTracking'
// import SessionService from 'services/SessionService'
// import CookieService from 'services/CookieService'

import styles from './Message.module.sass'

import { includes, lowerCase } from 'lodash'

// const Session = new SessionService()

const cx = classNames.bind(styles)

const MDButton = styled(Button)({
  borderRadius: '4px',
  border: 'solid 1px #717bbc',
  margin: '6px 3px',
  minWidth: 'auto',
  fontSize: '12px',
  color: '#363f72',
  padding: '4px 7px 3px',
  lineHeight: '1.5',
  fontWeight: '500',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.24), 0 -2px 2px rgba(0, 0, 0, 0.05)',
  '&:hover': {
    border: 'solid 1px #c50201',
    backgroundColor: 'transparent'
  }
})

const DougallMDSection = ({
  url = {},
  handleButtonClick,
  accessToken,
  interactionId
}) => {
  const { section = '', elements = [] } = url
  const { t } = useTranslation()
  let errorMsg = includes(['conditions', 'maladies'], lowerCase(section))
    ? t('chatNoConditionFound')
    : t('chatNoMedicationsFound')
  return (
    <div key={section} className={styles.url}>
      <div className={cx(styles.buttons)}>
        {elements.length > 0 ? (
          elements.map((element, idx) => {
            const { name, refUrl } = element

            const link = refUrl
              .replace(/{%access_token%}/gi, accessToken)
              .replace(/{%interactionId%}/gi, interactionId)

            return (
              <div key={name}>
                <MDButton
                  data-url={link}
                  type="button"
                  onClick={() => handleButtonClick(link, { name }, idx + 1)}
                  onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === ' ')
                      handleButtonClick(link, { name }, idx + 1)
                  }}
                >
                  {name}
                </MDButton>
              </div>
            )
          })
        ) : (
          <Typography
            type="display2"
            align={'center'}
            className={styles.urlSectionMessage}
          >
            {errorMsg}
          </Typography>
        )}
      </div>
    </div>
  )
}

DougallMDSection.propTypes = {
  url: PropTypes.object,
  handleButtonClick: PropTypes.func,
  accessToken: PropTypes.string,
  interactionId: PropTypes.string
}

// const DougallMDMessage = ({
//   urls = [],
//   track = {},
//   accessToken = '',
//   interactionId = '',
//   tags = [],
//   answerId,
//   promptId
// }) => {
//   // tracking on view of answer
//   const { t } = useTranslation()
//   const { trackPageView, trackClickAction } = useTracking()
//   const parentPromptId = useSelector(getLinkedPromptId()) || ''
//   const gpt = Session.getFromCache('gpt', {})

//   const handleUrlClick = (url, tracking, rawPos) => {
//     const tagDetails = tags.filter(e => {
//       if (e.tagNameLanguageUsed && e.tagNameLanguageUsed !== 'None') {
//         return e.tagNameLanguageUsed === tracking.name
//       } else {
//         return e.tagName === tracking.name
//       }
//     })[0]
//     const { tagCategory = '', tagId, tagName, tagType } = tagDetails

//     // Check if it is the second section
//     // If it is add the number of previous section tags
//     const pos =
//       tagCategory === 'Medications' ? rawPos + urls[0].elements.length : rawPos

//     // track click on btn click.
//     trackClickAction({
//       title: `Site/Click/DougallMD/${tagCategory.toLowerCase()}`,
//       userData: {
//         answerId: answerId,
//         promptId: promptId,
//         destURL: url,
//         btnId: `${tracking.name}`,
//         pos,
//         tagId,
//         tagName,
//         tagType
//       }
//     })
//     window.open(url, '_blank')
//   }
//   useEffect(() => {
//     urls.forEach(url => {
//       const { section = '', elements = [] } = url
//       elements.forEach((element, idx) => {
//         const { name } = element
//         const tagDetails = tags
//           ? tags.filter(e => {
//               if (e.tagNameLanguageUsed && e.tagNameLanguageUsed !== 'None') {
//                 return e.tagNameLanguageUsed === name
//               } else {
//                 return e.tagName === name
//               }
//             })[0]
//           : {}

//         // Check if it is the second section
//         const pos =
//           section === 'Medications'
//             ? idx + urls[0].elements.length + 1 // Add the number of previous section tags
//             : idx + 1

//         trackPageView({
//           title: `Site/View/DougallMD/${section.toLowerCase()}`,
//           userData: {
//             answerId: answerId,
//             promptId: promptId,
//             btnId: `${name}`,
//             pos,
//             tagId: tagDetails.tagId,
//             tagName: tagDetails.tagName,
//             tagType: tagDetails.tagType,
//             parentPromptId
//           }
//         })
//       })
//     })
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [])

//   return (
//     <div className={styles.urls}>
//       {urls.length > 0 ? (
//         urls.map(url => (
//           <div key={url.section}>
//             <DougallMDSection
//               url={url}
//               handleButtonClick={handleUrlClick}
//               accessToken={gpt.access}
//               interactionId={interactionId}
//             />
//           </div>
//         ))
//       ) : (
//         <Typography
//           type="display2"
//           align={'center'}
//           className={styles.urlSectionMessage}
//         >
//           {t('chatNoTagsFound')}
//         </Typography>
//       )}
//     </div>
//   )
// }

// DougallMDMessage.propTypes = {
//   urls: PropTypes.array,
//   track: PropTypes.object,
//   accessToken: PropTypes.string,
//   interactionId: PropTypes.string,
//   tags: PropTypes.array,
//   answerId: PropTypes.string,
//   promptId: PropTypes.string
// }

const Message = ({
  index = '',
  isAnswer = true,
  isDougall = false,
  showWarning = true,
  icon,
  title,
  message = '',
  highlightedAnswer = '',
  prompt = '',
  editable = true,
  onEditClick,
  onAction,
  promptId = '',
  answerId = '',
  tags = [],
  urls = [],
  showSocial = true,
  isError = false,
  date = '',
  showShare,
  showLikes,
  typewriter = false,
  done = false,
  onTypingFinish = () => {}
}) => {
  const { trackPageView } = useTracking()
  // const gpt = Session.getFromCache('gpt', {})
  // const accessToken = gpt.access
  // const interactionId =
  //   decodeURIComponent(CookieService().getFromCache('interactionId', '')) || ''
  const messages = useSelector(getMessages())
  const parentPromptId = useSelector(getLinkedPromptId()) || ''
  const promptNum = useSelector(getPromptNum()) || undefined
  const isTyping = useSelector(getIsTyping())
  const messageRef = useRef(null)
  const currentMessage = messages[messages.length - 1]

  // Get date format
  const dateFormat =
    date &&
    moment(date).calendar(null, {
      sameDay: '[Today]',
      lastDay: '[Yesterday]',
      lastWeek: 'dddd',
      sameElse: 'D MMM YYYY'
    })

  // Remove initial and last break lines & Special Character Formatting
  const trimmed = message.replace(/\\n/g, '\n').replace(/\\"/g, '"').trim()
  const bolded = trimmed.replace(/\*\*(.*?)\*\*/gm, '<strong>$1</strong>')
  const linked = bolded.replace(
    /\[([^\]]+)\]\(([^)]+)\)/gm,
    '<a href="$2" target="_blank">$1</a>'
  )
  const tabbed = linked.replace('\t', '&nbsp;&nbsp;&nbsp;&nbsp;')
  const heading = tabbed
    .replace(/^######\s*(.*)/gm, '<h6>$1</h6>')
    .replace(/^#####\s*(.*)/gm, '<h5>$1</h5>')
    .replace(/^####\s*(.*)/gm, '<h4>$1</h4>')
    .replace(/^###\s*(.*)/gm, '<h3>$1</h3>')
    .replace(/^##\s*(.*)/gm, '<h2>$1</h2>')
    .replace(/^#\s*(.*)/gm, '<h1>$1</h1>')

  // let highlightIdx = 0
  const parserOptions = {
    replace: domNode => {
      const { name, attribs, children } = domNode

      if (name === 'span' && attribs.id) {
        const tag = tags.find(t => t.tagId === attribs.id)
        // const pos = ++highlightIdx

        if (tag) {
          // const { tagName, tagSource = '', tagType } = tag
          const highlightText = domToReact(children)
          // const destURL = tagSource
          //   .replace(/{%access_token%}/gi, accessToken)
          //   .replace(/{%interactionId%}/gi, interactionId)
          return (
            // <a
            //   href={destURL}
            //   target="_blank"
            //   rel="noreferrer"
            //   className={styles.tag}
            //   onClick={() => {
            //     trackClickAction({
            //       title: `Site/Click/Answer/highlight`,
            //       clickType: 'answer-highlight',
            //       userData: {
            //         tagName,
            //         tagType,
            //         parentPromptId,
            //         pos,
            //         answerId,
            //         promptId
            //       }
            //     })
            //   }}
            // >
            //   {highlightText}
            // </a>
            highlightText
          )
        }
      }
    }
  }

  const highlighted = isAnswer
    ? isDougall
      ? ''
      : parse(heading, parserOptions)
    : message

  // Tracking on view of answer
  useEffect(() => {
    if (isAnswer && !isDougall) {
      // track submit on prompt submit.
      trackPageView({
        title: `Site/View/Answer`,
        userData: {
          answerId: currentMessage?.answerId,
          promptId: currentMessage?.promptId,
          parentPromptId,
          promptNum
        }
      })
    } else if (isAnswer && isDougall && !isTyping) {
      messageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnswer])

  useEffect(() => {
    if (done) {
      // All the answer has been received and typing animation is done
      onTypingFinish()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [done])

  useEffect(() => {
    if (typewriter && isTyping) {
      const resizeObserver = new ResizeObserver(() => {
        messageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
      })
      resizeObserver.observe(messageRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typewriter, isTyping])

  return (
    <div ref={messageRef}>
      <div className={styles.container}>
        <div
          className={cx(
            styles.bodyContainer,
            isAnswer && styles.answerBodyContainer,
            !isAnswer && styles.promptBodyContainer
          )}
        >
          <div className={styles.titleContainer}>
            {isAnswer && !isDougall ? (
              <>
                <img
                  className={styles.titleImg}
                  src={icon}
                  alt={'Profile Icon'}
                />
                <Typography className={styles.title} type="display2">
                  {dateFormat}
                </Typography>
              </>
            ) : (
              <>
                {/* {isDougall && (
                  <div className={styles.dougallImgContainer}>
                    <img
                      className={styles.dougallImg}
                      src={icon}
                      alt={'Profile Icon'}
                    />
                    <Typography
                      align={'right'}
                      className={styles.dougallTitle}
                      type="display2"
                    >
                      {title}
                    </Typography>
                  </div>
                )} */}
                {!isDougall && (
                  <>
                    <Typography className={styles.title} type="display2">
                      {title}
                    </Typography>
                    <Typography className={styles.title} type="display2">
                      {dateFormat}
                    </Typography>
                  </>
                )}
              </>
            )}
          </div>
          {!isDougall && message && (
            <div className={cx(styles.messageContainer)}>
              <div className={styles.promptContainer}>
                <Typography
                  className={cx(styles.message, { isError, isAnswer })}
                >
                  {highlighted}
                </Typography>
                {!isAnswer && editable && (
                  <div
                    onClick={() => {
                      onEditClick(message)
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === ' ')
                        onEditClick(message)
                    }}
                  >
                    <EditIcon
                      sx={{
                        fontSize: '16px',
                        cursor: 'pointer',
                        margin: '0',
                        'g>g': {
                          fill: '#363f72'
                        }
                      }}
                    />
                  </div>
                )}
              </div>
              {isAnswer && showSocial && (
                <ActionBar
                  onAction={onAction}
                  prompt={prompt}
                  promptId={promptId}
                  answerId={answerId}
                  showShare={showShare}
                  showLikes={showLikes}
                />
              )}
              {showWarning && isAnswer && !isTyping && (
                <HelpfulMessage
                  onAction={onAction}
                  promt={prompt}
                  promptId={promptId}
                  answerId={answerId}
                />
              )}
              {/* {showWarning && isAnswer && <Warning boxed />} */}
            </div>
          )}
          {/* {isDougall && urls && (
            <DougallMDMessage
              urls={urls}
              track={{ tags, answerId: index, message }}
              accessToken={accessToken}
              interactionId={interactionId}
              tags={tags}
              answerId={answerId}
              promptId={promptId}
            />
          )} */}
        </div>
      </div>
    </div>
  )
}

Message.propTypes = {
  /**
   * Message Index
   */
  index: PropTypes.string,
  /**
   * Boolean to differentiate between user message and response
   */
  isAnswer: PropTypes.bool,
  /**
   * Boolean to differentiate between chatGPT and Dougall.MD responses
   */
  isDougall: PropTypes.bool,
  /**
   * Boolean to show warning in answer
   */
  showWarning: PropTypes.bool,
  /**
   * Message Title
   */
  icon: PropTypes.string,
  /**
   * Message Title
   */
  title: PropTypes.string,
  /**
   * Message to display
   */
  message: PropTypes.string,
  /**
   * Answer Message with highlight tags
   */
  highlightedAnswer: PropTypes.string,
  /**
   * Prompt made for specific answer
   */
  prompt: PropTypes.string,
  /**
   * If a prompt can be edited or not
   */
  editable: PropTypes.bool,
  /**
   * When is user prompt, on edit icon click
   */
  onEditClick: PropTypes.func,
  /**
   * When it has actionBar, on like or dislike
   */
  onAction: PropTypes.func,
  /**
   * Prompt ID, if answer it refers to the prompt it answers
   */
  promptId: PropTypes.string,
  /**
   * In case of being an answer, the answer identifier
   */
  answerId: PropTypes.string,
  /**
   * In case of being an answer, the tags to display
   */
  tags: PropTypes.array,
  /**
   * In case of being an answer from Dougall, the urls list to display
   */
  urls: PropTypes.array,
  /**
   * Boolean to show social block
   */
  showSocial: PropTypes.bool,
  /**
   * Boolean to display the message with error style
   */
  isError: PropTypes.bool,
  /**
   * Message date
   */
  date: PropTypes.string,
  /**
   * Boolean to show Share Button or not
   */
  showShare: PropTypes.bool,
  /**
   * Boolean to show Like/Dislike button
   */
  showLikes: PropTypes.bool,
  /**
   * Boolean to display message with typewriter animation
   */
  typewriter: PropTypes.bool,
  /**
   * Boolean to know if the app has received the complete message from stream
   */
  done: PropTypes.bool,
  /**
   * Function excecuted when typewriter message animation is finished
   */
  onTypingFinish: PropTypes.func
}

export default Message
