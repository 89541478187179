import { createSelector } from '@reduxjs/toolkit'

export const usersCreateSelector = state => state.usersCreateReducer

export const getFormState = () =>
  createSelector(usersCreateSelector, usersCreate => usersCreate.formState)

export const getCountriesFetching = () =>
  createSelector(
    usersCreateSelector,
    usersCreate => usersCreate.countriesFetching
  )

export const getRolesFetching = () =>
  createSelector(usersCreateSelector, usersCreate => usersCreate.rolesFetching)

export const getPossibleCountries = () =>
  createSelector(
    usersCreateSelector,
    usersCreate => usersCreate.possibleCountries
  )

export const getSubmitting = () =>
  createSelector(usersCreateSelector, usersCreate => usersCreate.submitting)

export const getRolesAttributes = () =>
  createSelector(
    usersCreateSelector,
    usersCreate => usersCreate.rolesAttributes
  )

// export const getUserFetching = () =>
//   createSelector(usersCreateSelector, usersCreate => usersCreate.userFetching)
