import { put, all, takeEvery } from 'redux-saga/effects'
import { actions as themeActions } from 'modules/dougall/themeProvider/ThemeProviderReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { useConfig } from 'hooks'

/**
 * Extracts theme override values and exposes them to Redux state
 * @returns {Generator<*, void, *>}
 */
function* themeRequest() {
  const { configuration } = useConfig
  try {
    yield put(
      themeActions.themeRequestSuccess({
        active: configuration.theme.active,
        list: configuration.theme.list
      })
    )
  } catch (e) {
    console.error(e)
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Theme is not available',
        duration: 0
      })
    )
    yield put(themeActions.themeRequestFail())
  }
}

export default function* watchTheme() {
  yield all([takeEvery('THEME_REQUEST', themeRequest)])
}
