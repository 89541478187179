import SvgIcon from '@mui/material/SvgIcon'

const ClockRewind = props => (
  <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
    <path
      d="m22.7 13.566-2-2-2 2m2.3-1.5a9 9 0 1 1-1.245-4.57M12 7.066v5l3 2"
      stroke="#B3B8DB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
export default ClockRewind
