import { createSelector } from '@reduxjs/toolkit'

export const variablesSelector = state => state.variablesReducer

export const getFormState = () =>
  createSelector(variablesSelector, variables => variables.formState)

export const getFirstFetching = () =>
  createSelector(variablesSelector, variables => variables.isFirstFetching)

export const getFetching = () =>
  createSelector(variablesSelector, variables => variables.isFetching)

export const getVariables = () =>
  createSelector(variablesSelector, variables => variables.variables)

export const getSelected = () =>
  createSelector(variablesSelector, variables => variables.selected)

export const getPagination = () =>
  createSelector(variablesSelector, variables => variables.pagination)

export const getFetchingSingle = () =>
  createSelector(variablesSelector, variables => variables.isFetchingSingle)

export const getSingleVariable = () =>
  createSelector(variablesSelector, variables => variables.singleVariable)
