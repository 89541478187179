import PropTypes from 'prop-types'
import { useField } from 'formik'
import Input from 'components/ui/Input'
import Typography from './ui/Typography'
import styles from './TextInput.module.sass'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

/**
 * PSL UI Text input without formik integration
 * @param traditional
 * @param type
 * @param props
 * @returns {*}
 * @constructor
 */
const TextInput = ({ id, traditional = true, className, type, ...props }) => {
  return (
    <>
      <Input
        {...props}
        className={cx(
          styles.container,
          props.disabled && styles.disabled,
          className,
          {
            traditional
          }
        )}
        traditional={traditional}
        id={id}
        type={type}
        input={{ value: props.value }}
      />
      {props.caption && (
        <Typography type="caption" style={{ fontSize: '12px' }}>
          {props.caption}
        </Typography>
      )}
    </>
  )
}
TextInput.propTypes = {
  /**
   * Input ID
   */
  id: PropTypes.string,
  /**
   * Input label
   */
  label: PropTypes.string,
  /**
   * Additional className
   */
  className: PropTypes.string,
  /**
   * Input name
   */
  name: PropTypes.string.isRequired,
  /**
   * Input type
   */
  type: PropTypes.string.isRequired,
  /**
   * flag for traditional render
   */
  traditional: PropTypes.bool,
  /**
   * Optional caption text to be shown after the input
   */
  caption: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool
}

/**
 * PSL UI Text input integration with formik
 * @param traditional
 * @param type
 * @param props
 * @returns {*}
 * @constructor
 */
const TextInputField = ({ traditional = true, className, type, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <>
      <Input
        {...field}
        {...props}
        className={cx(
          styles.container,
          props.disabled && styles.disabled,
          className,
          {
            traditional
          }
        )}
        traditional={traditional}
        id={props.name}
        type={type}
        error={meta.touched && meta.error ? meta.error : null}
        value={`${field.value}`}
        input={{ value: `${field.value}` }}
      />
      {props.caption && (
        <Typography type="caption" style={{ fontSize: '12px' }}>
          {props.caption}
        </Typography>
      )}
    </>
  )
}
TextInputField.propTypes = {
  /**
   * Input label
   */
  label: PropTypes.string,
  /**
   * Additional className
   */
  className: PropTypes.string,
  /**
   * Input name
   */
  name: PropTypes.string.isRequired,
  /**
   * Input type
   */
  type: PropTypes.string.isRequired,
  /**
   * flag for traditional render
   */
  traditional: PropTypes.bool,
  /**
   * Optional caption text to be shown after the input
   */
  caption: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool
}

export { TextInput }
export default TextInputField
