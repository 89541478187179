import DougallML from './DougallML'

describe('Dougall Middle Layer', () => {
  let create = jest.fn().mockReturnValue({})
  const DougallMLInterceptors = {
    catchNetworkErrors: 'cacheNetworkErrors',
    detectMLfailuresOnFail: 'detectMLfailuresOnFail'
  }
  const SessionService = {
    getFromCache: jest.fn().mockReturnValue()
  }
  beforeEach(() => {
    SessionService.getFromCache.mockReset()
  })
  test('Create client', () => {
    const api = DougallML(DougallMLInterceptors, SessionService)
    const client = api.createClient()

    // Has no interceptors
    expect(client.interceptors.request.handlers).toEqual(
      expect.arrayContaining([
        {
          fulfilled: null,
          rejected: 'cacheNetworkErrors',
          synchronous: false,
          runWhen: null
        }
      ])
    )
    expect(client.interceptors.response.handlers).toEqual(
      expect.arrayContaining([
        {
          fulfilled: null,
          rejected: 'detectMLfailuresOnFail',
          synchronous: false,
          runWhen: null
        }
      ])
    )
  })
  test.skip('Create fetchWithStandardEmail object', () => {
    // Mock Services
    SessionService.getFromCache
      .mockReturnValueOnce({
        access: 'accessToken'
      }) // state
      .mockReturnValueOnce('123abc') // interactionId

    // Create the ML instance
    const api = DougallML(DougallMLInterceptors, SessionService)

    // mock the createClient function
    api.createClient = jest.fn().mockReturnValue({
      request: create
    })

    // Call fetch function
    api.fetch('/endpoint')

    // Tests
    expect(create).toBeCalledWith({
      data: {
        interactionId: '123abc'
      },
      headers: {
        Authorization: 'Bearer accessToken',
        'Content-Type': 'application/json'
      },
      method: 'GET',
      url: '/endpoint'
    })
  })
})
