import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Typography from '../../components/ui/Typography'
import Page from 'components/Page'
import styles from './LoginWithEmailSuccess.module.sass'
import siteConfig from 'site.config.json'
import { getIsAuth } from 'modules/auth/AuthSelector'

const { sections } = siteConfig

const LoginWithEmailSuccess = () => {
  const {
    login: {
      LoginWithEmailTitle,
      LoginWithEmailText,
      LoginWithEmailContactUs: { email, text }
    }
  } = sections
  const isAuth = useSelector(getIsAuth())

  if (isAuth) {
    return (
      <Redirect
        to={{
          pathname: '/'
        }}
      />
    )
  }

  return (
    <Page
      showHero
      showFooter={false}
      backIconLink={'/user/login'}
      showMenu={false}
      isFull
      showDivider={false}
    >
      <div className={styles.container}>
        <Typography
          type="title"
          style={{
            fontWeight: '300',
            fontSize: '21px',
            color: 'rgba(0,0,0,0.84)'
          }}
        >
          {LoginWithEmailTitle}
        </Typography>
        <Typography type="body1" className={styles.section}>
          {LoginWithEmailText}&nbsp;
          <Typography
            type="link"
            href={`mailto:${email}`}
            style={{ fontSize: '1em' }}
          >
            {text}
          </Typography>
        </Typography>
      </div>
    </Page>
  )
}

export default LoginWithEmailSuccess
