import { createReducer, createAction } from '@reduxjs/toolkit'

export const uploadRequest = createAction('UNIVERSE_UPLOAD_REQUEST')
export const uploadRequestSuccess = createAction(
  'UNIVERSE_UPLOAD_REQUEST_SUCCESS'
)
export const uploadRequestFail = createAction('UNIVERSE_UPLOAD_REQUEST_FAIL')

export const downloadRequest = createAction('UNIVERSE_DOWNLOAD_REQUEST')
export const downloadRequestSuccess = createAction(
  'UNIVERSE_DOWNLOAD_REQUEST_SUCCESS'
)
export const downloadRequestFail = createAction(
  'UNIVERSE_DOWNLOAD_REQUEST_FAIL'
)

export const searchRequest = createAction('UNIVERSE_SEARCH_REQUEST')
export const searchRequestSuccess = createAction(
  'UNIVERSE_SEARCH_REQUEST_SUCCESS'
)
export const searchRequestFail = createAction('UNIVERSE_SEARCH_REQUEST_FAIL')

export const searchSpecialtiesRequest = createAction(
  'UNIVERSE_SPECIALTIES_REQUEST'
)
export const searchSpecialtiesSuccess = createAction(
  'UNIVERSE_SPECIALTIES_SUCCESS'
)
export const searchSpecialtiesFail = createAction('UNIVERSE_SPECIALTIES_FAIL')

export const searchProfessionsRequest = createAction(
  'UNIVERSE_PROFESSIONS_REQUEST'
)
export const searchProfessionsSuccess = createAction(
  'UNIVERSE_PROFESSIONS_SUCCESS'
)
export const searchProfessionsFail = createAction('UNIVERSE_PROFESSIONS_FAIL')

export const actions = {
  uploadRequest,
  uploadRequestSuccess,
  uploadRequestFail,
  downloadRequest,
  downloadRequestSuccess,
  downloadRequestFail,
  searchRequest,
  searchRequestSuccess,
  searchRequestFail,
  searchSpecialtiesRequest,
  searchSpecialtiesSuccess,
  searchSpecialtiesFail,
  searchProfessionsRequest,
  searchProfessionsSuccess,
  searchProfessionsFail
}

const universeReducer = createReducer(
  {
    searchState: {
      country: '',
      profession: '',
      specialty: '',
      orderBy: '',
      order: '',
      page: 1,
      perPage: 25
    },
    fetching: false,
    type: '',
    universe: [],
    pagination: {
      total: 0,
      filtered: 0,
      maxPage: 0,
      results: 0,
      page: 1,
      perPage: 25
    },
    searchFetching: false,
    fetchingSpecialties: false,
    specialties: {},
    fetchingProfessions: false,
    professions: {}
  },
  {
    [uploadRequest]: state => {
      state.fetching = true
      state.type = 'upload'
    },
    [uploadRequestSuccess]: state => {
      state.fetching = false
      state.type = ''
    },
    [uploadRequestFail]: state => {
      state.fetching = false
      state.type = ''
    },
    [downloadRequest]: state => {
      state.fetching = true
      state.type = 'download'
    },
    [downloadRequestSuccess]: state => {
      state.fetching = false
      state.type = ''
    },
    [downloadRequestFail]: state => {
      state.fetching = false
      state.type = ''
    },
    [searchRequest]: (state, { payload }) => {
      state.searchFetching = true
      state.searchState = payload.search || state.searchState
    },
    [searchRequestSuccess]: (state, { payload }) => {
      state.searchFetching = false
      state.universe = payload.universe
      state.pagination = payload.pagination
    },
    [searchRequestFail]: state => {
      state.searchFetching = false
      state.universe = [...state.universe]
      state.pagination = { ...state.pagination }
    },
    [searchSpecialtiesRequest]: state => {
      state.fetchingSpecialties = true
    },
    [searchSpecialtiesSuccess]: (state, { payload }) => {
      state.fetchingSpecialties = false
      state.specialties = { ...payload }
    },
    [searchSpecialtiesFail]: state => {
      state.fetchingSpecialties = false
      state.specialties = {}
    },
    [searchProfessionsRequest]: state => {
      state.fetchingProfessions = true
    },
    [searchProfessionsSuccess]: (state, { payload }) => {
      state.fetchingProfessions = false
      state.professions = { ...payload }
    },
    [searchProfessionsFail]: state => {
      state.fetchingProfessions = false
      state.professions = {}
    }
  }
)

export default universeReducer
