const ConfigParser = () => ({
  /**
   * Get necessary config for app
   *
   * @param configuration
   * @returns {{}}
   */
  parseConfig(configuration) {
    const config = { ...configuration }
    try {
      return {
        ads: this.adsConfig(config)
      }
    } catch (e) {
      console.log(e)
    }
  },
  /**
   * Parse initial Ad Configuration
   *
   * @param config
   * @returns {{}}
   */
  adsConfig(config) {
    const { ads } = config
    try {
      return {
        enabled: ads.enabled,
        display: ads.display,
        content: ads.content
      }
    } catch (e) {
      console.log(e)
      return { enabled: false }
    }
  }
})

export default ConfigParser
