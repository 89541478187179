import Cookies from 'js-cookie'
import {
  cacheValuesSynonyms,
  domainCacheValues,
  sessionCacheValues,
  sessionDomainStorage,
  sessionStorage
} from '../constants'
import { getValue, isDefined, isEmpty } from 'utils'

const CookieService = () => ({
  /**
   * Gets a value from the cache.
   *
   * @param id (string) - Identifier of the value to get
   * @param defaultValue (any) - The value to get if the required value is found (defaults to null)
   * @return any - Cache item if found, default if not, null if no default
   */
  getFromCache(id, defaultValue) {
    const sectionId = 'global'
    const cookieName =
      domainCacheValues.indexOf(id) > -1 ? sessionDomainStorage : sessionStorage
    const cache = this.getCacheComplete(cookieName)
    const cacheSection = this.getCacheSection(cache, sectionId)

    return isDefined(cacheSection[id]) ? cacheSection[id] : defaultValue
  },
  /**
   * Gets the complete cache object.
   *
   * @return any - The cache object
   */
  getCacheComplete(cacheName = sessionDomainStorage) {
    let cache = '{}'
    let cacheObj

    cache = getValue(() => this.get(cacheName), cache)

    try {
      cacheObj = JSON.parse(cache)
    } catch (e) {
      cacheObj = {}
    }

    return cacheObj
  },
  /**
   * Saves values to cache.
   *
   * @param values (object) - Values that are wished to be saved to the cache
   * @return void
   */
  saveToCache(values, allowOthers = false, expireTime = null) {
    //Translate the values
    const formattedValues = this.formatValues(values, allowOthers)

    for (const key in formattedValues) {
      const value = formattedValues[key]
      if (domainCacheValues.indexOf(key) > -1) {
        this.saveCookie({
          id: key,
          val: getValue(() => this.urlParamsFormatted[key], value),
          cacheName: sessionDomainStorage,
          expireTime
        })
      } else if (sessionCacheValues.indexOf(key) > -1 || allowOthers) {
        this.saveCookie({
          id: key,
          val: getValue(() => this.urlParamsFormatted[key], value),
          cacheName: sessionStorage,
          expireTime
        })
      }
    }
  },
  /**
   * Saves a value to the cache.
   *
   * @param cacheName (string) - Identifier of the cookie
   * @param id (string) - Identifier of the value to save
   * @param val (any) - The value to save
   * @param expireTime (number) - The number of seconds to store the value, by default it never expires.
   * @return void
   */
  saveCookie({ id, val, expireTime = null, cacheName = sessionDomainStorage }) {
    const sectionId = 'global'
    const cache = this.getCacheComplete(cacheName)
    const cacheSection = this.getCacheSection(cache, sectionId)

    if (expireTime) {
      this.setExpiration(id, expireTime, cache)
    }

    cacheSection[id] = val
    this.saveCachePersist(cache, cacheName)
  },
  /**
   * Saves the cache to domainCacheValues storage.
   *
   * @param cache (any) - The updated cache object to save
   * @return void
   * @private
   */
  saveCachePersist(cache, cacheName = sessionDomainStorage) {
    for (const sectionId in cache) {
      if (sectionId !== 'cacheExpirationTime') {
        this.expireCache(cache, sectionId)
      }
    }

    const cacheString = JSON.stringify(cache)
    this.set(cacheName, cacheString, { domain: cacheName })
  },
  /**
   * Format url params by considering synonyms.
   *
   * @param originalValues (object) - The original cahce values
   * @param allowOthers (boolean) - Flag to allow other values not defined in the synonyms
   * @return Object - Formatted values
   */
  formatValues(originalValues, allowOthers = false) {
    const formattedValues = {}

    for (const property in originalValues) {
      const value = originalValues[property]
      if (cacheValuesSynonyms.hasOwnProperty(property)) {
        formattedValues[property] = getValue(() => value, '')
      } else {
        const newProperty = this.getSynonymKey(property)
        if (isDefined(newProperty)) {
          formattedValues[newProperty] = getValue(() => value, '')
        } else if (allowOthers) {
          formattedValues[property] = getValue(() => value, '')
        }
      }
    }

    return formattedValues
  },
  /**
   * Looks in the synonyms for the correct param name.
   *
   * @param paramName (string) - The parameter name
   * @return string - The synonym key
   */
  getSynonymKey(paramName) {
    for (const key in cacheValuesSynonyms)
      if (cacheValuesSynonyms[key].indexOf(paramName) > -1) return key

    return undefined
  },
  /**
   * Retrieves a cache section (by reference).
   *
   * @param cache (any) - The complete cache object
   * @param sectionId (string) - The cache section to retrieve
   * @return any - The cache section object
   * @private
   */
  getCacheSection(cache, sectionId) {
    if (isEmpty(cache[sectionId])) {
      cache[sectionId] = {}
    }

    return cache[sectionId]
  },
  /**
   * For any given cached value, set an expiration time.
   *
   * @param id (string) - Identifier of the value
   * @param time (number) - Time in seconds
   * @param cache (any) - The cache object
   * @return void
   * @private
   */
  setExpiration(id, time, cache) {
    const cacheId = 'global'
    const cacheExpirationTime = this.getCacheSection(
      cache,
      'cacheExpirationTime'
    )
    const cacheExpirationSection = this.getCacheSection(
      cacheExpirationTime,
      cacheId
    )
    cacheExpirationSection[id] = time * 1000 // Save expiration in milliseconds
  },
  /**
   * Expires a Cache.
   *
   * @param cache (string) - The complete cache object
   * @param sectionId - The section of the cache to expire
   * @return void
   * @private
   */
  expireCache(cache, sectionId) {
    if (sectionId === 'cacheExpirationTime') {
      return
    }

    const cacheExpirationTime = getValue(() => cache['cacheExpirationTime'], {})
    if (isEmpty(cacheExpirationTime[sectionId])) {
      return
    }

    for (const id in cacheExpirationTime[sectionId]) {
      if (cacheExpirationTime[sectionId][id] < Date.now()) {
        delete cache[sectionId][id]
        delete cacheExpirationTime[sectionId][id]
      }
    }
  },
  /**
   * Deletes a value from cache.
   *
   * @param id (string) - Identifier of the value to delete
   * @return void
   */
  deleteFromCache(id) {
    const sectionId = 'global'
    const cookieName =
      domainCacheValues.indexOf(id) > -1 ? sessionDomainStorage : sessionStorage
    const cache = this.getCacheComplete(cookieName)
    const cacheSection = this.getCacheSection(cache, sectionId)

    if (isDefined(cacheSection[id])) {
      delete cacheSection[id]
    }

    const cacheExpirationTime = this.getCacheSection(
      cache,
      'cacheExpirationTime'
    )
    if (
      isDefined(cacheExpirationTime[sectionId]) &&
      isDefined(cacheExpirationTime[sectionId][id])
    ) {
      delete cacheExpirationTime[sectionId][id]
    }

    this.saveCachePersist(cache, cookieName)
  },
  /**
   * Deletes a list of values from cache
   * @param idList
   */
  bulkDeleteFromCache(idList = []) {
    return Promise.resolve(idList.forEach(v => this.deleteFromCache(v)))
  },
  get(key) {
    return Cookies.get(key)
  },
  set(key, value, extra = null) {
    return Cookies.set(key, value, extra)
  },
  remove(key) {
    return Cookies.remove(key)
  }
})

export default CookieService
