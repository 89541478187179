export const csvTable = [
  {
    id: 1,
    column: ['mail', 'email'],
    description: 'Email',
    required: true,
    example: ['john.doe@pslgroup.com']
  },
  {
    id: 2,
    column: ['status'],
    description: 'Status',
    required: true,
    example: ['1', '0']
  },
  {
    id: 3,
    column: ['firstName', 'field_first_name_value'],
    description: 'First Name',
    required: false,
    example: ['John']
  },
  {
    id: 4,
    column: ['lastName', 'field_last_name_value'],
    description: 'Last Name',
    required: false,
    example: ['Doe']
  },
  {
    id: 5,
    column: ['field_company_value'],
    description: 'Company',
    required: false,
    example: ['PSL']
  },
  {
    id: 6,
    column: ['field_job_function_value'],
    description: 'Job Function',
    required: false,
    example: ['Development']
  },
  {
    id: 7,
    column: ['field_job_title_value'],
    description: 'Job Title',
    required: false,
    example: ['Dev']
  },
  {
    id: 8,
    column: ['field_postalcode_value'],
    description: 'Postal code',
    required: false,
    example: ['12345']
  },
  {
    id: 9,
    column: ['field_city_value'],
    description: 'City',
    required: false,
    example: ['Queretaro']
  },
  {
    id: 10,
    column: ['field_province_value'],
    description: 'Province',
    required: false,
    example: ['Queretaro']
  },
  {
    id: 11,
    column: ['field_country_value'],
    description: 'Country',
    required: false,
    example: ['Mexico']
  },
  {
    id: 12,
    column: ['field_telephone_value'],
    description: 'Telephone',
    required: false,
    example: ['1234567890']
  },
  {
    id: 13,
    column: ['roles'],
    description: (
      <div>
        List of roles separated by semicolon{' '}
        <pre style={{ display: 'inline-block' }}>;</pre>
      </div>
    ),
    required: true,
    example: ['BASIC;AMI_SALES']
  }
]
