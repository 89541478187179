/**
 * Must use old require format because this is used by react-app-rewired overrides
 */
const { asClass, asFunction } = require('awilix')

module.exports = [
  { name: 'SmartsML', registerAs: asFunction },
  { name: 'SmartsMLError', registerAs: asClass },
  { name: 'SmartsMLInterceptors', registerAs: asFunction },
  { name: 'ConfigParser', registerAs: asFunction },
  { name: 'TrackingService', registerAs: asClass },
  { name: 'SessionService', registerAs: asClass },
  { name: 'CookieService', registerAs: asFunction },
  { name: 'AuthService', registerAs: asFunction },
  { name: 'UserTransactionService', registerAs: asFunction },
  {
    name: 'UserTransactionML',
    subfolder: '/userTransaction',
    registerAs: asFunction
  },
  {
    name: 'UserTransactionParser',
    subfolder: '/userTransaction',
    registerAs: asFunction
  },
  { name: 'MiscService', registerAs: asFunction },
  { name: 'WebsiteConfigService', registerAs: asFunction },
  { name: 'SmartsService', registerAs: asFunction },
  { name: 'SmartsParser', registerAs: asFunction },
  { name: 'TypeaheadService', registerAs: asFunction },
  { name: 'TypeaheadParser', registerAs: asFunction },
  { name: 'DeviceIdService', registerAs: asClass },
  { name: 'JobStatusService', registerAs: asClass },
  { name: 'AdvisorSearchParser', registerAs: asFunction },
  { name: 'AdvisorSearchService', registerAs: asClass },
  { name: 'ListingsParser', registerAs: asFunction },
  { name: 'AdvisorScheduleService', registerAs: asClass },
  { name: 'AdvisorProfileRequestService', registerAs: asClass },
  { name: 'ChartsParser', registerAs: asFunction },
  { name: 'UsersCreateService', registerAs: asFunction },
  { name: 'AdminService', registerAs: asFunction },
  // Dougall services
  { name: 'DougallML', subfolder: '/dougall', registerAs: asFunction },
  { name: 'DougallMLError', subfolder: '/dougall', registerAs: asClass },
  {
    name: 'DougallMLInterceptors',
    subfolder: '/dougall',
    registerAs: asFunction
  },
  { name: 'DougallService', subfolder: '/dougall', registerAs: asFunction },
  { name: 'DougallController', subfolder: '/dougall', registerAs: asFunction },
  { name: 'DougallParser', subfolder: '/dougall', registerAs: asFunction },
  { name: 'TranslationService', subfolder: '/dougall', registerAs: asFunction }
]
