import axios from 'axios'
import { piwikSiteId, idhBrandId } from '../../constants'
import siteConfig from 'site.config.json'

const {
  configuration: { envVars }
} = siteConfig

const UserTransactionML = (
  SessionService,
  CookieService,
  getState,
  UserTransactionParser
) => ({
  createClient() {
    const instance = axios.create({})
    return instance
  },

  async fetchWithStandardEmail(
    endpoint,
    method = 'GET',
    data = {},
    source = {},
    extra = {}
  ) {
    const isAuth = getState().authReducer.isAuth
    const dspId = getState().authReducer.dspId

    const { headers = {}, ...restExtra } = extra

    // const { access } = SessionService.getFromCache('state', '')
    const { access } = SessionService.getFromCache('gpt', {})

    const interactionId = decodeURIComponent(
      CookieService.getFromCache('interactionId', '')
    )
    const articleId = SessionService.getFromCache('article_id', 0)
    const deviceId = CookieService.getFromCache('psl_device_id', '')
    const campaignId = SessionService.getFromCache('CampaignID')

    const conf = {
      url: endpoint,
      method,
      data: {
        brandCode: envVars.REACT_APP_DOUGALL_BRANDCODE,
        configType: 'standard',
        idhBrandId: parseInt(idhBrandId),
        notifyCS: true,
        source: {
          articleId: !isAuth ? parseInt(articleId) || undefined : undefined,
          campaignId: isAuth ? parseInt(campaignId) || undefined : undefined,
          device: navigator.userAgent,
          deviceId: `${deviceId}`,
          dspId: dspId || undefined,
          interactionId: interactionId || undefined,
          lastAction: UserTransactionParser.lastAction(),
          piwikSiteId: parseInt(piwikSiteId),
          ...source
        },
        ...data
      },
      ...restExtra,
      headers: {
        Authorization: `Bearer ${access}`,
        'Content-Type': 'application/json',
        ...headers
      }
    }

    const client = this.createClient()
    return client.request(conf)
  }
})

export default UserTransactionML
