import axios from 'axios'
import { isEmpty } from 'utils'

const WebsiteConfigService = () => ({
  /**
   * Website config GET request
   *
   * @param endpoint
   * @param params - query string params
   * @param token - access token
   * @returns {Promise<*[]|*>}
   */
  async getRequest(endpoint, params, token) {
    let axiosOptions = {
      url: endpoint,
      method: 'GET',
      params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const response = await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })

    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },
  /**
   * Website config PUT request
   *
   * @param endpoint
   * @param payload - website config payload to update
   * @param token - access token
   * @returns {Promise<*[]|*>}
   */
  async putRequest(endpoint, payload, token) {
    const axiosOptions = {
      url: endpoint,
      method: 'PUT',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const response = await axios(axiosOptions).catch(e => {
      throw new Error(e)
    })
    return response
  }
})

export default WebsiteConfigService
