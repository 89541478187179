import SvgIcon from '@mui/material/SvgIcon'

const FileQuestion = props => (
  <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
    <path
      d="M20 9.566v-2.7c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.31c-.642-.328-1.482-.328-3.162-.328H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.346 4 5.186 4 6.866v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311c.642.327 1.482.327 3.162.327H14m2.5-6.997a2.248 2.248 0 0 1 4.37.75c0 1.498-2.25 2.247-2.25 2.247m.03 3h.01"
      stroke="#B3B8DB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
export default FileQuestion
