import { useState } from 'react'
import PropTypes from 'prop-types'
import { useField, useFormikContext } from 'formik'
import DatePicker from 'components/ui/DatePicker'
import Typography from '../ui/Typography'
import styles from './DateInput.module.sass'

/**
 * PSL UI DatePicker integration with formik
 * @param traditional
 * @param type
 * @param props
 * @returns {*}
 * @constructor
 */
const DateInput = ({ ...props }) => {
  const [field, meta] = useField({ ...props })
  const [open, setOpen] = useState(false)
  const { setFieldValue } = useFormikContext()
  const fieldName = props.name
  const handleChange = (date, value) => {
    setFieldValue(fieldName, date)
    setOpen(false)
  }
  const renderLabel = date => {
    if (field.value) {
      return field.value.format('MM/DD/YYYY')
    } else {
      return ''
    }
  }
  return (
    <div className={styles.container}>
      <div
        data-testid="dateInput-container"
        className={styles.dateInputContainer}
        onClick={() => setOpen(true)}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') setOpen(true)
        }}
      >
        <label>
          {props.label}
          {props.required && '*'}
        </label>
        <div className={styles.dateInput}>{renderLabel()}</div>
        {meta.touched && meta.error && (
          <Typography
            data-testid="errorCaption"
            type="caption"
            color="error"
            style={{ fontSize: '12px', position: 'absolute' }}
          >
            {meta.error}
          </Typography>
        )}
      </div>
      <DatePicker
        {...field}
        {...props}
        id={props.name}
        className={styles.hidden}
        errorAlign="left"
        open={open}
        error={meta.touched && meta.error ? meta.error : null}
        value={field.value ? field.value : props.value}
        // labelFunc={renderLabel}
        onChange={handleChange}
        onClose={() => setOpen(false)}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') setOpen(false)
        }}
      />
    </div>
  )
}
DateInput.propTypes = {
  /**
   * Input label
   */
  label: PropTypes.string,
  /**
   * Input name
   */
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool
}
export default DateInput
