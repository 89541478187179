import classNames from 'classnames/bind'
import styles from './Page.module.sass'
import PropTypes from 'prop-types'
import Typography from 'components/ui/Typography'

const cx = classNames.bind(styles)

const PageContent = ({ children, className, title = '' }) => {
  return (
    <div className={cx(styles.container, className)}>
      <Typography type="title" className={styles.title} color="text-primary">
        {title}
      </Typography>
      {children}
    </div>
  )
}
PageContent.propTypes = {
  /**
   * Extra class passed to the container
   */
  className: PropTypes.string,
  /**
   * The page content
   */
  children: PropTypes.node.isRequired,
  /**
   * Title
   */
  title: PropTypes.string
}

export default PageContent
