import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as chartsAction } from './ChartsReducer'
import { v4 as uuidv4 } from 'uuid'

/**
 * Call the ChartsAnalysis endpoint to get the charts
 *
 * @param services
 * @param payload
 */
function* loadChartsRequest(services) {
  const SmartsService = services('SmartsService')
  try {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Loading Charts, Please wait a moment',
        duration: 1000
      })
    )

    // hardcoded the call data since it's just to test the mockdb
    const response = yield call([SmartsService, 'listsChartsAnalysis'], {
      lists: null,
      combinations: [
        {
          country: ['UK'],
          profession: 'Physician',
          specialty: 'Oncology'
        }
      ],
      filters: {
        idn: {
          yes: true
        }
      }
    })
    yield put(
      chartsAction.chartsLoadSuccessRequest(
        response ? response.mapped.charts : {}
      )
    )
  } catch (e) {
    console.error(e)
    yield put(chartsAction.chartsLoadSuccessRequest({}))
    yield put(
      notificationActions.notificationEnqueue({
        message: e?.message || 'We had an issue processing your request',
        duration: 0
      })
    )
  }
}

function* uploadChart(services, { payload }) {
  const SmartsService = services('SmartsService')
  try {
    const chartName = `${uuidv4()}.png`
    const file = yield call(
      [SmartsService, 'convertImgToFile'],
      payload,
      chartName
    )

    const formData = new FormData()
    formData.append(chartName, file)

    const { uploadedFiles } = yield call(
      [SmartsService, 'listsChartsUpload'],
      formData
    )

    // Removes the bucket environment (included in the CDN name)
    const arrayOfWords = uploadedFiles[0].split('/')
    arrayOfWords.splice(0, 1)
    const finalArr = [arrayOfWords.join('/')]

    yield put(chartsAction.uploadChartRequestSuccess(finalArr))
  } catch (e) {
    console.error(e)
    yield put(chartsAction.uploadChartRequestSuccess([]))
    yield put(
      notificationActions.notificationEnqueue({
        message: e?.message || 'We had an issue uploading the chart',
        duration: 0
      })
    )
  }
}

function* shareChart(services, { payload }) {
  const SmartsService = services('SmartsService')
  const {
    copyMe,
    message,
    title,
    image,
    country,
    profession,
    specialty,
    combinations = [],
    universe,
    listSize
  } = payload
  try {
    const objToSend = {
      workflow: 'ShareChart',
      recipients: [payload.toEmail],
      copyMe,
      fields: {
        message,
        title,
        country,
        profession,
        specialty,
        combinations,
        image,
        universe,
        listSize
      }
    }
    const status = yield call([SmartsService, 'listsChartShare'], objToSend)
    if (status === 'OK') {
      yield put(
        notificationActions.notificationEnqueue({
          message: 'Your results have been sent.',
          duration: 0
        })
      )
    }

    yield put(chartsAction.chartShareRequestSuccess())
  } catch (e) {
    console.error(e)
    yield put(chartsAction.chartShareRequestFail())
    yield put(
      notificationActions.notificationEnqueue({
        message: e?.message || 'We had an issue sharing the chart',
        duration: 0
      })
    )
  }
}

export default function* watchChartsRequest(services) {
  yield all([takeEvery('CHARTS_LOAD_REQUEST', loadChartsRequest, services)])
  yield all([takeEvery('UPLOAD_CHART_REQUEST', uploadChart, services)])
  yield all([takeEvery('CHART_SHARE_REQUEST', shareChart, services)])
}
