import { createReducer, createAction } from '@reduxjs/toolkit'

export const trackToSecondTracker = createAction('TRACK_ACTION_SECOND_TRACKER')
export const trackSubmitAction = createAction('TRACK_SUBMIT_ACTION')
export const trackPageView = createAction('TRACK_PAGE_VIEW')
export const trackSubmitActionReset = createAction('TRACK_SUBMIT_ACTION_RESET')
export const trackClickAction = createAction('TRACK_CLICK_ACTION')
export const trackClickActionSuccess = createAction(
  'TRACK_CLICK_ACTION_SUCCESS'
)
export const setTrackedEmailClickLogin = createAction('TRACK_EMAIL_CLICK_LOGIN')
export const setTrackedEmailClickLoginSuccess = createAction(
  'TRACK_EMAIL_CLICK_LOGIN_SUCCESS'
)

export const actions = {
  trackSubmitAction,
  trackSubmitActionReset,
  trackToSecondTracker,
  trackPageView,
  trackClickAction,
  trackClickActionSuccess,
  setTrackedEmailClickLogin,
  setTrackedEmailClickLoginSuccess
}

const initialState = {
  title: '',
  payload: {},
  trackedEmailClickLogin: false,
  trackedEmailClickLoginSuccess: false
}

const trackingReducer = createReducer(initialState, {
  [trackSubmitAction]: (state, { payload: { title = '', userData = {} } }) => {
    state.title = title
    state.payload = userData
  },
  [trackSubmitActionReset]: state => {
    state.title = ''
    state.payload = {}
  },
  [setTrackedEmailClickLogin]: state => {
    state.trackedEmailClickLogin = true
  },
  [setTrackedEmailClickLoginSuccess]: state => {
    state.trackedEmailClickLoginSuccess = true
  }
})

export default trackingReducer
