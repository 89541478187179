import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styles from './form.module.sass'
import Checkbox from 'components/Checkbox'
import Button, { RaisedButton } from 'components/Button'
import Icon from 'components/ui/Icon'
import FiltersComponent from 'modules/filter'
import Typography from '../../../components/ui/Typography'
import CombinationTable from 'modules/CombinationsTable'
import * as exploreProjectSelectors from '../ExploreProjectSelector'
import ListIds from 'modules/listIds'

const { getFetchStatus } = exploreProjectSelectors

const ListFiltersForm = ({ formProps, handleReset }) => {
  const isFetching = useSelector(getFetchStatus())
  const { setFieldValue, values } = formProps
  const { activeStep, selectedListLabels } = values

  // Update type value when user click on submit buttons
  // (ExploreProject submit will be called after this function)
  // const handleSubmit = type => {
  //   setFieldValue('type', type)
  // }
  const handleAddCombinations = () => {
    if (
      values.combinationsCountry ||
      values.combinationsProfession ||
      values.combinationsSpecialty
    ) {
      setFieldValue('combinations', [
        ...values['combinations'],
        {
          id: values['combinations'].length + 1,
          country: values.combinationsCountry,
          profession: values.combinationsProfession,
          specialty: values.combinationsSpecialty
        }
      ])
      setFieldValue('combinationsCountry', '')
      setFieldValue('combinationsProfession', '')
      setFieldValue('combinationsSpecialty', '')
    }
  }
  const handleListIdSelection = (id, suggestion) => {
    const newSelectedLabels = [...selectedListLabels]
    newSelectedLabels[id] = suggestion.label
    setFieldValue('selectedListLabels', newSelectedLabels)
  }
  return (
    <div className={styles.divider}>
      <ListIds
        formName="explore-list"
        name="ids"
        disabled={activeStep === 'filters'}
        onSuggestionSelected={handleListIdSelection}
        requestBody={{
          data: {
            filters: {
              workflow: 'ExploreProject'
            },
            search: {
              perPage: 100
            }
          }
        }}
      />
      {activeStep === 'filters' ? (
        <>
          <Checkbox
            label="Select all List Members"
            className={styles.selectAllMembers}
            name="allMembersSelected"
            isRadio
          />
          <div>
            <Typography type="body1">
              Optional - Use the provided filters to generate a list of HCPs
              from the LiMA universe
            </Typography>
          </div>

          <div>
            <CombinationTable
              name="combinations"
              formProps={formProps}
              workflow="ExploreProject"
            />
            <FiltersComponent name="filters" />
          </div>
          <div className={styles.buttonsRow}>
            {/* <Button
              // type="button"
              className={styles.button}
              text="Graph"
              hasSpinner={values.type === 'graph'}
              submitting={isFetching}
            /> */}
            {/* <Button
              // type="button"
              className={styles.button}
              onClick={() => handleSubmit('save-list')}
              text="Save List"
              hasSpinner={values.type === 'save-list'}
              submitting={isFetching}
            /> */}
          </div>
          <div className={styles.buttonsRow}>
            <Button
              type="button"
              className={styles.button}
              text="Add Combination"
              onClick={handleAddCombinations}
              disabled={isFetching}
            />
            {/* <Button
              // type="button"
              className={styles.button}
              onClick={() => handleSubmit('run-analysis')}
              text="Run Analysis"
              hasSpinner={values.type === 'run-analysis'}
              submitting={isFetching}
              disabled={isFetching}
            /> */}
            <Button
              type="button"
              className={styles.button}
              text="Reset Form"
              disabled={isFetching}
              onClick={handleReset}
            />
          </div>
        </>
      ) : (
        <div className={styles.addListContainer}>
          {values.ids.slice(-1) !== '' && (
            <RaisedButton
              type="button"
              onClick={() => {
                if (values.ids[values.ids.length - 1]) {
                  setFieldValue('ids', [...values.ids, ''])
                }
              }}
              disabled={isFetching}
              fontSize="16px"
            >
              Add List Id{' '}
              <Icon icon="svg/custom/add-circle" colorTier="tertiary" />
            </RaisedButton>
          )}
        </div>
      )}
    </div>
  )
}

ListFiltersForm.propTypes = {
  /**
   * Form props
   */
  formProps: PropTypes.object,
  /**
   * Reset Form Func
   */
  handleReset: PropTypes.func
}

export default ListFiltersForm
