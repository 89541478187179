import { useEffect, useState } from 'react'
import { isEmpty } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import Page from 'components/Page'
import Typography from 'components/ui/Typography'
import Divider from 'components/ui/Divider'
import Avatar from 'components/Avatar'
import { Button } from 'components/ui/Button'
import styles from './AdvisorProfileSummary.module.sass'
import * as profileSearchSelectors from '../advisorProfile/AdvisorProfileSelector'
import * as authSelectors from '../auth/AuthSelector'
import { actions } from './AdvisorProfileReducer'
import * as advisorScheduleActions from 'modules/formAdvisorSchedule/AdvisorScheduleReducer'
import LoadingComponent from 'components/LoadingComponent'
import { actions as trackActions } from 'modules/tracking/TrackingReducer'
const { getProfileSearchStatus, getProfile } = profileSearchSelectors
const { getFeaturesTable } = authSelectors

const AdvisorProfileSummary = ({ match }) => {
  const history = useHistory()
  const memberId = match.params.memberId
  const roleSchema = useSelector(getFeaturesTable())['lima-bureau-profile']
  const isFetching = useSelector(getProfileSearchStatus())
  const [advisorProfile] = useSelector(getProfile())
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      actions.profileRequest({
        memberId: memberId,
        hasList: false,
        isSummary: true
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {isFetching ? (
        <LoadingComponent />
      ) : isEmpty(advisorProfile) ? (
        <NoDataPage />
      ) : (
        <Page
          showHero={false}
          showDivider={false}
          showFooter={false}
          showBackIcon={true}
          backIconLink={'/lima-bureau-advisor/list'}
          isLimaBureau
        >
          <ProfileCard profile={advisorProfile} roleSchema={roleSchema} />
          <Divider className={styles.topDivider} />
          <ProfileSummary profile={advisorProfile} roleSchema={roleSchema} />
          <div className={styles.fullProfileButton}>
            <Button
              className={styles.button}
              fontSize={'1em'}
              onClick={() => {
                dispatch(trackActions.trackSubmitAction({}))
                history.push(`/lima-bureau-advisor/full/${memberId}`)
              }}
            >
              View Full Profile
            </Button>
          </div>
        </Page>
      )}
    </>
  )
}
AdvisorProfileSummary.propTypes = {
  match: PropTypes.object
}

const ProfileCard = ({ profile, roleSchema }) => {
  const {
    sections: { fields }
  } = roleSchema
  const avatarName = `Dr. ${
    isEmpty(profile.firstName) ? '' : profile.firstName
  } ${isEmpty(profile.lastName) ? '' : profile.lastName}`
  return (
    <div className={styles.profileCardContainer}>
      <div>
        {fields.includes('callingCardPicture') && (
          <Avatar fullName={avatarName} size={70} />
        )}
      </div>
      <div>
        {fields.includes('callingCardName') && (
          <Typography type={'headline'}>{profile.fullName}</Typography>
        )}
        {fields.includes('callingCardCredential') &&
          !isEmpty(profile?.credentials) && (
            <Typography type={'subheading'}>{profile.credentials}</Typography>
          )}
        {fields.includes('callingCardSpecialty') &&
          !isEmpty(profile?.specialties) && (
            <Typography type={'subheading'}>{profile.specialties}</Typography>
          )}
        {fields.includes('callingCardInstitution') &&
          !isEmpty(profile?.institution) && (
            <Typography type={'subheading'}>{profile.institution}</Typography>
          )}
      </div>
    </div>
  )
}
ProfileCard.propTypes = {
  profile: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    credentials: PropTypes.string,
    specialties: PropTypes.string,
    institution: PropTypes.string
  }),
  roleSchema: PropTypes.object
}

const ProfileSummary = ({ profile, roleSchema }) => {
  const {
    sections: { fields }
  } = roleSchema
  const Contact = () => (
    <div>
      <SectionHeader text={'Contact Info'} />
      <Typography type={'section-heading'}>{profile.fullName}</Typography>
      <div>
        <div className={styles.innerContentDivider}>
          {fields.includes('callingCcontactInfoWorkplaceNameardName') &&
            !isEmpty(profile?.workplaceName) && (
              <Typography type={'body1'}>{profile.workplaceName}</Typography>
            )}
          {fields.includes('contactInfoWorkplaceAddress') &&
            !isEmpty(profile?.workplaceAddress) && (
              <Typography type={'body1'}>{profile.workplaceAddress}</Typography>
            )}
        </div>
        <div>
          {fields.includes('contactInfoPhoneNumber') &&
            !isEmpty(profile?.phone) && (
              <Typography type={'body1'}>{profile.phone}</Typography>
            )}
          {fields.includes('contactInfoFaxNumber') &&
            !isEmpty(profile?.fax) && (
              <Typography type={'body1'}>{profile.fax}</Typography>
            )}
        </div>
      </div>
    </div>
  )
  const Hospital = () =>
    fields.includes('hospitalAffiliations') &&
    !isEmpty(profile?.hospitalAffiliations) && (
      <div>
        <SectionHeader text={'Hospital & Workplace Affiliations'} />
        <div>
          <ListShowMore
            list={profile.hospitalAffiliations.map((item, i) => (
              <div className={styles.innerContentDivider} key={item.name}>
                {fields.includes('hospitalAffiliationsName') && (
                  <Typography type={'section-heading'}>{item.name}</Typography>
                )}
                {fields.includes('hospitalAffiliationsAddress') && (
                  <Typography type={'body1'}>{item.address}</Typography>
                )}
              </div>
            ))}
          />
        </div>
      </div>
    )
  const Other = () =>
    fields.includes('practiceLanguages') && (
      <div>
        <SectionHeader text={'Other'} />
        <Typography type={'section-heading'}>Practice Languages</Typography>
        <Typography type={'body1'}>English</Typography>
        {!isEmpty(profile?.languages) &&
          profile.languages.map(
            (lang, i) =>
              lang.name !== 'English' && (
                <Typography key={lang.name} type={'body1'}>
                  {lang.name}
                </Typography>
              )
          )}
      </div>
    )

  return (
    <div>
      <div className={styles.profileBig}>
        <div>
          <Contact />
        </div>
        <div>
          <Hospital />
          <Other />
        </div>
      </div>

      <div className={styles.profileSmall}>
        <Contact />
        <Hospital />
        <Other />
      </div>
    </div>
  )
}
ProfileSummary.propTypes = {
  roleSchema: PropTypes.object,
  profile: PropTypes.shape({
    fullName: PropTypes.string,
    workplaceName: PropTypes.string,
    workplaceAddress: PropTypes.string,
    phone: PropTypes.string,
    fax: PropTypes.string,
    hospitalAffiliations: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        address: PropTypes.string
      })
    ),
    languages: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string
      })
    )
  })
}

const ListShowMore = ({ list, showLength = 6 }) => {
  const [listCount, setListCount] = useState(showLength)
  const increaseListCount = (e, length) => {
    setListCount(length)
  }
  const resetListCount = () => {
    setListCount(showLength)
  }
  const listLength = isEmpty(list) ? 0 : list.length
  if (listLength <= 0) {
    return <Typography type={'primary'}>No data available</Typography>
  }

  if (listLength > listCount) {
    return (
      <>
        {list.slice(0, listCount).map((tag, i) => (
          <div key={tag}>{tag}</div>
        ))}
        <div className={styles.moreButtonContainer}>
          <Button
            className={styles.moreButton}
            tier={'primary'}
            raised={false}
            fontSize={'1em'}
            iconLeft="svg/custom/carat-d"
            onClick={e => increaseListCount(listLength)}
          >
            show more
          </Button>
        </div>
      </>
    )
  } else if (listLength <= 6) {
    return (
      <>
        {list.slice(0, listCount).map((tag, i) => (
          <div key={tag}>{tag}</div>
        ))}
      </>
    )
  } else {
    return (
      <>
        {list.slice(0, listCount).map((tag, i) => (
          <div key={tag}>{tag}</div>
        ))}
        <div className={styles.moreButtonContainer}>
          <Button
            className={styles.moreButton}
            tier={'primary'}
            raised={false}
            fontSize={'1em'}
            iconLeft="svg/custom/carat-u"
            onClick={e => resetListCount()}
          >
            show less
          </Button>
        </div>
      </>
    )
  }
}
ListShowMore.propTypes = {
  list: PropTypes.array,
  showLength: PropTypes.number
}

const SectionHeader = ({ text }) => {
  return (
    <div className={styles.sectionHeader}>
      <Typography type={'title'}>{text}</Typography>
      <Divider className={styles.titleDivider} />
    </div>
  )
}
SectionHeader.propTypes = {
  text: PropTypes.string
}

const NoDataPage = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(advisorScheduleActions.scheduleReset())
    }
  })
  return (
    <Page
      heroTitle={'No data available'}
      showBackIcon={false}
      backIconLink={'/lima-bureau-advisor'}
      showHero={true}
      showDivider={true}
      showFooter={true}
      isLimaBureau
    >
      <Typography type={'link'} component={Link} to={'/lima-bureau-advisor'}>
        Back to Advisor Search Form
      </Typography>
    </Page>
  )
}

export default AdvisorProfileSummary
