import { createReducer, createAction } from '@reduxjs/toolkit'

export const requestGenericConfig = createAction('CHARTS_GENERIC_REQUEST')
export const requestGenericConfigSuccess = createAction(
  'CHARTS_GENERIC_REQUEST_SUCCESS'
)
export const requestGenericConfigFail = createAction(
  'CHARTS_GENERIC_REQUEST_FAIL'
)
export const updateTokens = createAction('CHARTS_GENERIC_UPDATE_TOKENS')
export const updateTooltips = createAction('CHARTS_GENERIC_UPDATE_TOOLTIPS')
export const resetData = createAction('CHARTS_GENERIC_RESET_DATA')

export const saveGenericConfig = createAction('CHARTS_GENERIC_SAVE')
export const saveGenericConfigSuccess = createAction(
  'CHARTS_GENERIC_SAVE_SUCCESS'
)

export const actions = {
  requestGenericConfig,
  requestGenericConfigSuccess,
  requestGenericConfigFail,
  updateTokens,
  updateTooltips,
  resetData,
  saveGenericConfig,
  saveGenericConfigSuccess
}

const chartsGenericReducer = createReducer(
  {
    isFetching: false,
    tokens: {},
    tooltips: {},
    fullData: {
      tokens: {},
      tooltips: {}
    }
  },
  {
    [requestGenericConfig]: state => {
      state.isFetching = true
    },
    [requestGenericConfigSuccess]: (state, { payload }) => {
      state.isFetching = false
      state.tokens = payload.tokens
      state.tooltips = payload.tooltips
      state.fullData = payload.responseData
    },
    [requestGenericConfigFail]: state => {
      state.isFetching = false
    },
    [updateTokens]: (state, { payload }) => {
      state.tokens = payload
    },
    [updateTooltips]: (state, { payload }) => {
      state.tooltips = payload
    },
    [resetData]: state => {
      state.tokens = state.fullData.tokens
      state.tooltips = state.fullData.tooltips
    },
    [saveGenericConfigSuccess]: (state, { payload }) => {
      state.fullData = payload
    }
  }
)

export default chartsGenericReducer
