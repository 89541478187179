import { createReducer, createAction } from '@reduxjs/toolkit'

export const trackSubmitAction = createAction('TRACK_SUBMIT_ACTION')
export const trackSubmitActionReset = createAction('TRACK_SUBMIT_ACTION_RESET')
export const trackCoinClick = createAction('TRACK_COIN_CLICK')
export const trackCustomAction = createAction('TRACK_CUSTOM_ACTION')

export const actions = {
  trackSubmitAction,
  trackSubmitActionReset,
  trackCoinClick,
  trackCustomAction
}

const initialState = {
  title: '',
  payload: {}
}

const trackingReducer = createReducer(initialState, {
  [trackSubmitAction]: (state, { payload: { title = '', data = {} } }) => {
    state.title = title
    state.payload = data
  },
  [trackSubmitActionReset]: state => {
    state.title = ''
    state.payload = {}
  }
})

export default trackingReducer
