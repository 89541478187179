import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import PropTypes from 'prop-types'
import { Button } from 'components/ui/Button'
import Typography from 'components/ui/Typography'
import Page from 'components/Page'
import TextInput from 'components/TextInput'
import styles from './AdvisorSchedule.module.sass'
import { actions } from './AdvisorScheduleReducer'
import * as advisorSchedule from './AdvisorScheduleSelector'

const { getScheduleFetchingStatus, getScheduleSentStatus } = advisorSchedule

const SuccessPage = ({ fullName }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(actions.scheduleReset())
    }
  })
  return (
    <Page
      showBackIcon={true}
      backIconLink={'/lima-bureau-advisor/list'}
      showHero={false}
      showDivider={false}
      showFooter={true}
      isLimaBureau
    >
      <div className={styles.scheduleSuccess}>
        <Typography type={'display2'}>Request Submitted</Typography>
        <Typography type={'title'}>
          The LiMA Bureau Manager will contact you shortly with available times
          for the advisor or to clarify any outstanding questions about the
          request.
        </Typography>
        <div>
          <Typography
            component={Link}
            type="link"
            to={'/lima-bureau-advisor/list'}
            className={styles.link}
          >
            Back to Search Results
          </Typography>
        </div>
      </div>
    </Page>
  )
}
SuccessPage.propTypes = {
  /**
   * Advisor name to display on success
   */
  fullName: PropTypes.string
}

const AdvisorSchedule = ({ match }) => {
  const dispatch = useDispatch()
  const isFetching = useSelector(getScheduleFetchingStatus())
  const scheduleSentStatus = useSelector(getScheduleSentStatus())
  const memberId = match.params.memberId

  return (
    <>
      {scheduleSentStatus ? (
        <SuccessPage />
      ) : (
        <Page
          heroTitle={'Request a Session'}
          showBackIcon={true}
          backIconLink={'/lima-bureau-advisor/list'}
          showHero={true}
          showDivider={true}
          showFooter={false}
          isLimaBureau
        >
          <Formik
            initialValues={{
              userName: '',
              phone: '',
              projectInfo: '',
              comments: ''
            }}
            onSubmit={async (values, { setSubmitting }) => {
              values.hashedPartyId = memberId
              dispatch(actions.scheduleRequest(values))
            }}
          >
            <Form>
              <TextInput
                label="Name"
                name="userName"
                type="text"
                traditional={false}
                required={false}
              />
              <TextInput
                label="Phone No."
                name="phone"
                type="text"
                traditional={false}
                required={false}
              />
              <TextInput
                label="Project Info"
                name="projectInfo"
                type="text"
                traditional={false}
                required={false}
              />
              <TextInput
                label="Additional Comments/Considerations"
                name="comments"
                type="text"
                traditional={false}
                required={false}
              />
              <div className={styles.buttonContainer}>
                <Button
                  type="submit"
                  submitting={isFetching}
                  disabled={isFetching}
                  hasSpinner
                  className={styles.button}
                  spinnerStrokeColor="#1e3661"
                >
                  Submit Request
                </Button>
              </div>
            </Form>
          </Formik>
        </Page>
      )}
    </>
  )
}
AdvisorSchedule.propTypes = {
  match: PropTypes.object
}
export default AdvisorSchedule
