import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styles from './form.module.sass'
import Button from 'components/Button'
import * as exploreListSelectors from '../ExploreListSelector'
import * as exploreListActions from '../ExploreListReducer'
import useFilters from 'modules/filter/useFilters'
import { SelectedFilters, SelectedListIds } from 'components/Selected'
import useRoleFeatures from 'modules/auth/useRoleFeatures'
import ListSize from 'components/ListSize'

const { getFormState, getFetchStatus } = exploreListSelectors

function ProjectMetadataForm({ formProps }) {
  const isFetching = useSelector(getFetchStatus())
  const formState = useSelector(getFormState())
  const { values } = formProps
  const {
    allMembersSelected,
    combinations,
    filters,
    selectedListLabels,
    type,
    universe,
    listSize
  } = values
  const dispatch = useDispatch()
  const { selectedFiltersCaptions } = useFilters(filters)
  const { features } = useRoleFeatures({
    coin: 'explore-market'
  })

  const handleChangeForm = step => {
    dispatch(
      exploreListActions.setFormState({
        ...formState,
        activeStep: step
      })
    )
  }
  const onSaveListClick = () => {
    dispatch(
      exploreListActions.setFormState({
        ...formState,
        type: 'save-list',
        activeStep: 'project-metadata'
      })
    )
  }
  return (
    <>
      <div className={styles.selectedContainer}>
        <SelectedListIds
          selectedListLabels={selectedListLabels}
          onEdit={() => handleChangeForm('list-selection')}
          isFetching={isFetching}
          alignEditButton="flex-end"
        />
        <SelectedFilters
          combinations={combinations}
          selectedFiltersCaptions={selectedFiltersCaptions}
          onEdit={() => handleChangeForm('filters')}
          isFetching={isFetching}
          allMembersSelected={allMembersSelected}
          alignEditButton="flex-end"
        />
      </div>
      <div className={styles.buttonsRow}>
        {type !== 'save-list' &&
          features.sections?.actions.includes('save-list') && (
            <Button
              className={styles.button}
              onClick={onSaveListClick}
              hasSpinner={values.type === 'save-list'}
              submitting={isFetching}
              disabled={isFetching}
              type="button"
            >
              Save List
            </Button>
          )}
        {/* {type !== 'run-analysis' &&
          features.sections?.actions.includes('graph') && (
            <Button
              className={styles.button}
              onClick={() => handleTypeSubmit('run-analysis')}
              hasSpinner={values.type === 'run-analysis'}
              submitting={isFetching}
              disabled={isFetching}
            >
              Run Analysis
            </Button>
          )} */}
      </div>
      <ListSize hidden={false} universeSize={universe} listSize={listSize} />
    </>
  )
}

ProjectMetadataForm.propTypes = {
  /**
   * Form props
   */
  formProps: PropTypes.object
}

export default ProjectMetadataForm
