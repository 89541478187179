import React from 'react'
import Page from 'components/Page'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button, { RaisedButton } from 'components/Button'
import { actions as exploreProjectActions } from './ExploreProjectReducer'
import * as exploreProjectSelectors from './ExploreProjectSelector'
import styles from './ExploreProject.module.sass'
import { initialValues, steps } from './config'
import validationSchema from './validationSchema'
import { isEmpty } from 'utils'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const { getFormState, getFetchStatus } = exploreProjectSelectors

const ExploreProject = () => {
  let initialForm = {
    ...initialValues
  }
  const dispatch = useDispatch()
  const history = useHistory()
  const formState = useSelector(getFormState())
  const isFetching = useSelector(getFetchStatus())
  const { activeStep } = formState
  const formStatePr = isEmpty(formState) ? initialForm : formState
  const currentValidationSchema = validationSchema[activeStep]

  const isLastStep = activeStep === 'project-metadata'

  const handleBack = () => {
    let newStep = ''
    switch (activeStep) {
      case 'project':
        history.goBack()
        break
      case 'list-selection':
        newStep = 'project'
        break
      case 'filters':
        newStep = 'list-selection'
        break
      default:
        break
    }
    if (newStep) {
      dispatch(
        exploreProjectActions.setFormState({
          ...formState,
          activeStep: newStep
        })
      )
    }
  }

  const handleSubmit = (values, actions) => {
    if (!isLastStep) {
      dispatch(
        exploreProjectActions.submitFormRequest({
          ...values
        })
      )
    } else {
      dispatch(exploreProjectActions.submitFormCompleteRequest({ ...values }))
    }
  }

  const handleReset = ({ resetForm }) => {
    if (typeof resetForm === 'function') {
      resetForm()
    }
    dispatch(
      exploreProjectActions.setFormState({
        ...initialValues
      })
    )
  }

  const ActiveStep = formProps =>
    React.cloneElement(steps[activeStep].component, {
      ...steps[activeStep].component.props,
      formProps,
      handleReset
    })

  return (
    <Page
      heroTitle="Explore Project"
      showHero
      showGoBack
      onBackClick={handleBack}
      showDivider
      showFooter={false}
    >
      <Formik
        initialValues={formStatePr}
        validationSchema={currentValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {formProps => (
          <Form>
            <ActiveStep {...formProps} />
            <div className={styles.buttonContainer}>
              {/* {isLastStep && (
                <Button
                  className={styles.submitButton}
                  hasSpinner
                  submitting={isFetching}
                >
                  Submit
                </Button>
              )} */}
              {activeStep !== 'filters' && (
                <Button
                  className={styles.button}
                  type="submit"
                  submitting={isFetching}
                  disabled={isFetching}
                  hasSpinner
                  isLimaSubmit
                />
              )}
            </div>
            <div className={cx(styles.resetContainer)}>
              <RaisedButton
                type="button"
                className={cx(styles.resetButton)}
                onClick={() => handleReset(formProps)}
                disabled={isFetching}
              >
                Reset Form
              </RaisedButton>
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  )
}

export default ExploreProject
