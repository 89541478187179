import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { isEmpty } from 'utils'

import {
  getFirstFetching,
  getFetching,
  getFormState,
  getHistory,
  getPagination,
  getSelected
} from './UniverseHistorySelector'
import { actions as universeHistoryActions } from './UniverseHistoryReducer'

import Button from 'components/Admin/Button'
import Card from 'components/Admin/Card/Card'
import PageContent from 'components/Admin/Page/PageContent'
import Typography from 'components/ui/Typography'
import TextInput from 'components/TextInput'
import Icon from 'components/ui/Icon'
import { CircularProgress } from 'components/ui/Progress'
import DataTable from 'react-data-table-component'
import ConfirmationModal from 'components/Admin/ConfirmationModal/ConfirmationModal'

import styles from './UniverseHistory.module.sass'

const UniverseHistory = () => {
  const dispatch = useDispatch()
  const routerHistory = useHistory()
  const initialForm = {
    note: '',
    orderBy: '',
    order: '',
    page: 1,
    perPage: 25
  }
  const [modalOpen, setModalOpen] = useState(false)
  const [clearInput, setClearInput] = useState(false)
  const isFirstFetching = useSelector(getFirstFetching())
  const isFetching = useSelector(getFetching())
  const history = useSelector(getHistory())
  const pagination = useSelector(getPagination())
  const formState = useSelector(getFormState())
  const selected = useSelector(getSelected())
  const formStatePr = isEmpty(formState) ? initialForm : formState
  const { location } = routerHistory

  const handleModalOpen = (id, note) => {
    dispatch(
      universeHistoryActions.setSelected({
        id,
        note
      })
    )
    setModalOpen(true)
  }

  const handleModalClose = () => {
    dispatch(
      universeHistoryActions.setSelected({
        id: null,
        note: ''
      })
    )
    setModalOpen(false)
  }

  const handleView = (id, note) => {
    dispatch(
      universeHistoryActions.setSelected({
        id,
        note
      })
    )
    const urlId = location.pathname.slice(-1) === '/' ? `${id}` : `/${id}`
    routerHistory.push(`${location.pathname}${urlId}`)
  }

  const columns = [
    {
      name: 'Note',
      selector: row => row.note,
      sortField: 'note',
      sortable: true,
      cell: row => {
        return (
          <div className={styles.noteCell}>
            <Typography>{row.note}</Typography>
          </div>
        )
      }
    },
    {
      name: 'Record Counts',
      selector: row => row.data.recordsCountBefore,
      sortable: false,
      center: true,
      cell: row => {
        return (
          <div>
            <Typography>
              <b>Before: </b> {row.data.recordsCountBefore}
            </Typography>
            <Typography>
              <b>After: </b> {row.data.recordsCountAfter}
            </Typography>
          </div>
        )
      }
    },
    {
      name: 'Updated',
      selector: row => row.updatedAt,
      sortField: 'date',
      center: true,
      sortable: true,
      cell: row => {
        return <Typography>{row.updatedAt}</Typography>
      }
    },
    {
      sortable: false,
      maxWidth: '20px',
      cell: row => {
        return (
          <Icon
            icon="svg/custom/visibility"
            className={styles.tableIcon}
            onClick={() => handleView(row.id, row.note)}
          />
        )
      }
    },
    {
      sortable: false,
      maxWidth: '20px',
      cell: row => {
        return (
          <Icon
            icon="svg/material-design-icons/action/delete"
            className={styles.tableIcon}
            onClick={() => handleModalOpen(row.id, row.note)}
          />
        )
      }
    }
  ]

  const handleRowsChange = (perPage, page) => {
    const payload = {
      ...formStatePr,
      page,
      perPage
    }
    dispatch(universeHistoryActions.setFormState(payload))
    dispatch(universeHistoryActions.requestUniverseHistory(payload))
  }

  const handlePageChange = page => {
    const payload = {
      ...formStatePr,
      page
    }
    dispatch(universeHistoryActions.setFormState(payload))
    dispatch(universeHistoryActions.requestUniverseHistory(payload))
  }

  const handleSort = ({ sortField: selector }, sortDirection) => {
    const payload = {
      ...formStatePr,
      orderBy: selector,
      order: sortDirection
    }
    dispatch(universeHistoryActions.setFormState(payload))
    dispatch(universeHistoryActions.requestUniverseHistory(payload))
  }

  const handleReset = () => {
    dispatch(universeHistoryActions.setFormState({ ...initialForm }))
    dispatch(universeHistoryActions.requestUniverseHistory({}))
    setClearInput(!clearInput)
  }

  const handleSubmit = values => {
    const { note } = values
    dispatch(
      universeHistoryActions.setFormState({
        ...initialForm,
        note
      })
    )
    dispatch(
      universeHistoryActions.requestUniverseHistory({
        note
      })
    )
  }

  const handleDelete = () => {
    dispatch(
      universeHistoryActions.requestHistoryDelete({
        id: selected.id,
        formState: formStatePr
      })
    )
    setModalOpen(false)
  }

  if (isFirstFetching) {
    dispatch(universeHistoryActions.requestUniverseHistory({}))
  }

  return (
    <PageContent title="Universe History">
      <Formik
        key={clearInput}
        enableReinitialize
        initialValues={formStatePr}
        onSubmit={handleSubmit}
      >
        <Form autoComplete="off">
          <Card className={styles.card}>
            <Typography type="title" color="text-primary">
              Search
            </Typography>
            <div className={styles.searchForm}>
              <TextInput
                label="Note"
                name="note"
                type="text"
                required
                traditional={false}
              />
              <div className={styles.formButtons}>
                <Button
                  className={styles.resetButton}
                  text="Reset"
                  textCase="none"
                  disabled={false}
                  type="reset"
                  backgroundColor="#d3d3d3"
                  onClick={() => handleReset()}
                />
                <Button
                  text="Search"
                  textCase="none"
                  disabled={false}
                  type="submit"
                />
              </div>
            </div>
          </Card>

          <Card>
            <Typography
              type="title"
              color="text-primary"
              className={styles.cardTitle}
            >
              History
            </Typography>

            <DataTable
              columns={columns}
              data={history}
              keyField="key"
              progressPending={isFetching}
              pagination
              paginationServer
              paginationTotalRows={pagination.filtered}
              onChangeRowsPerPage={handleRowsChange}
              onChangePage={handlePageChange}
              onSort={handleSort}
              sortServer
              persistTableHead
              paginationPerPage={pagination.perPage}
              paginationRowsPerPageOptions={[15, 25, 35, 45, 55, 85, 100]}
              progressComponent={
                <CircularProgress
                  className={styles.circularProgress}
                  fillColor="transparent"
                  size={64}
                  strokeWidth={4}
                />
              }
            />
          </Card>
        </Form>
      </Formik>

      <ConfirmationModal
        message={
          'Are you sure you want to delete element with note: ' + selected.note
        }
        open={modalOpen}
        onConfirm={handleDelete}
        onCancel={handleModalClose}
        actionType="delete"
        actionCaption="Delete"
      />
    </PageContent>
  )
}

export default UniverseHistory
