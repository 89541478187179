import { capitalize, isEmpty } from 'utils'
import { pubMedContentEndpoint } from 'constants'
import axios from 'axios'

const ListingsParser = () => ({
  /**
   *  profile: PropTypes.shape({
   *     credentials: PropTypes.string,
   *     specialties: PropTypes.string,
   *     institution: PropTypes.string,
   *     fullName: PropTypes.string,
   *     workplaceName: PropTypes.string,
   *     workplaceAddress: PropTypes.string,
   *     phone: PropTypes.string,
   *     fax: PropTypes.string,
   *     facultyName: PropTypes.string,
   *     facultyAddress: PropTypes.string,
   *     certifications: PropTypes.array,
   *     educationFellowships: PropTypes.arrayOf(
   *       PropTypes.shape({
   *         name: PropTypes.string,
   *         graduated: PropTypes.string,
   *         address: PropTypes.string
   *       })
   *     ),
   *     hospitalAffiliations: PropTypes.arrayOf(
   *       PropTypes.shape({
   *         name: PropTypes.string,
   *         address: PropTypes.string
   *       })
   *     ),
   *     publications: PropTypes.array,
   *     tags: PropTypes.array,
   *     advisorType: PropTypes.array,
   *     conflicts: PropTypes.array,
   *     history: PropTypes.array,
   *     sessionFee: PropTypes.string,
   *     languages: PropTypes.arrayOf(
   *       PropTypes.shape({
   *         name: PropTypes.string
   *       })
   *     )
   *   })
   * Parses the list of advisors.
   * @param {*} data
   * @param isAdmin
   * @returns an object
   */
  profileRiver({ data, isAdmin, maxResultsDisplay }) {
    if (isEmpty(data)) {
      return []
    } else {
      let advisorList
      if (isAdmin) {
        advisorList = data
      } else {
        advisorList = data.slice(0, maxResultsDisplay)
      }
      return advisorList.map(profile => {
        const getFullName = `Dr. 
        ${!isEmpty(profile.firstName) ? capitalize(profile.firstName) : ''} ${
          !isEmpty(profile.middleName) ? capitalize(profile.middleName) : ''
        } ${!isEmpty(profile.lastName) ? capitalize(profile.lastName) : ''}`
        const getWorkplaceAddress = profile => {
          const location = !isEmpty(profile.location) ? profile.location : null
          const workplaces = !isEmpty(profile.workplaces)
            ? profile.workplaces
            : null

          if (!isEmpty(location) && !isEmpty(workplaces)) {
            const city = !isEmpty(location[0]?.city) ? location[0].city : ''
            const state = !isEmpty(workplaces[0]?.state)
              ? workplaces[0].state
              : ''
            const zip = !isEmpty(workplaces[0]?.postalCode)
              ? workplaces[0].postalCode
              : ''
            const country = !isEmpty(location[0]?.country)
              ? location[0].country
              : ''

            if (
              !isEmpty(city) &&
              !isEmpty(state) &&
              !isEmpty(zip) &&
              !isEmpty(country)
            ) {
              return `${city}, ${state} ${zip}, ${country}`
            } else if (!isEmpty(city) && !isEmpty(state) && !isEmpty(country)) {
              return `${city}, ${state}, ${country}`
            } else if (!isEmpty(city) && !isEmpty(country)) {
              return `${city}, ${country}`
            } else if (!isEmpty(country)) {
              return country
            } else {
              return ''
            }
          } else if (!isEmpty(location)) {
            const city = !isEmpty(location[0]?.metroCity)
              ? location[0].metroCity
              : ''
            const country = !isEmpty(location[0]?.country)
              ? location[0].country
              : ''
            if (!isEmpty(city) && !isEmpty(country)) {
              return `${city}, ${country}`
            } else if (!isEmpty(country)) {
              return country
            } else {
              return ''
            }
          }
        }
        const getWorkplaceName = profile => {
          if (!isEmpty(profile?.workplaces)) {
            return !isEmpty(profile?.workplaces[0])
              ? !isEmpty(profile?.workplaces[0].limaPlacename)
                ? profile?.workplaces[0].limaPlacename
                : ''
              : ''
          } else {
            return ''
          }
        }
        const getHospitalAffiliations = profile => {
          const address = item => {
            const city = !isEmpty(item?.city) ? item.city : ''
            const state = !isEmpty(item?.state) ? item.state : ''
            const address = !isEmpty(item?.address) ? item.address : ''
            const zip = !isEmpty(item?.postalCode) ? item.postalCode : ''
            const country = !isEmpty(item?.country) ? item.country : ''

            if (
              !isEmpty(city) &&
              !isEmpty(state) &&
              !isEmpty(address) &&
              !isEmpty(zip) &&
              !isEmpty(country)
            ) {
              return `${address}, ${city}, ${state} ${zip}, ${country}`
            } else if (
              !isEmpty(city) &&
              !isEmpty(state) &&
              !isEmpty(zip) &&
              !isEmpty(country)
            ) {
              return `${city}, ${state} ${zip}, ${country}`
            } else if (!isEmpty(state) && !isEmpty(country)) {
              return `${city}, ${country}`
            } else if (!isEmpty(country)) {
              return country
            } else {
              return ''
            }
          }

          let workplaceList = []
          if (!isEmpty(profile?.workplaces)) {
            profile.workplaces.map(item =>
              workplaceList.push({
                name: !isEmpty(item.limaPlacename) ? item.limaPlacename : '',
                address: address(item)
              })
            )
          }

          return workplaceList
        }
        const getPhone = profile => {
          if (!isEmpty(profile?.workplaces)) {
            return !isEmpty(profile?.workplaces[0]?.phone)
              ? `Office Phone: ${profile.workplaces[0].phone}`
              : ''
          } else {
            return ''
          }
        }
        const getFax = profile => {
          if (!isEmpty(profile?.workplaces)) {
            return !isEmpty(profile?.workplaces[0]?.fax)
              ? `Office Fax: ${profile.workplaces[0].fax}`
              : ''
          } else {
            return ''
          }
        }
        const getAdvisorType = profile => {
          let newAdvisors = []
          if (!isEmpty(profile?.advisorType)) {
            Object.keys(profile.advisorType).forEach(item => {
              if (profile.advisorType[item]) {
                newAdvisors.push(item)
              }
            })
          }
          return newAdvisors
        }
        return {
          // PROFILE SUMMARY
          hashedPartyId: profile.hashedPartyId,
          memberId: profile.memberId,
          fullName: getFullName,
          middlename: !isEmpty(profile?.middleName)
            ? capitalize(profile.middleName)
            : '',
          firstName: !isEmpty(profile?.firstName)
            ? capitalize(profile.firstName)
            : '',
          lastName: !isEmpty(profile?.lastName)
            ? capitalize(profile.lastName)
            : '',
          credentials: !isEmpty(profile?.credential)
            ? profile.credential.join(', ')
            : '',
          specialties: !isEmpty(profile?.masterSpecialties)
            ? profile.masterSpecialties.join(', ')
            : '',
          institution: !isEmpty(profile?.institution)
            ? profile.institution.join(', ')
            : '',
          workplaceName: getWorkplaceName(profile),
          workplaceAddress: getWorkplaceAddress(profile),
          phone: getPhone(profile),
          fax: getFax(profile),
          // hospital and workplace affiliations
          hospitalAffiliations: getHospitalAffiliations(profile),
          // TAGS
          tags: !isEmpty(profile?.tags) ? profile.tags : [],
          // NEWS
          publications: !isEmpty(profile?.pubmedIds) ? profile.pubmedIds : [],
          advisorType: getAdvisorType(profile),
          // OTHER
          languages: !isEmpty(profile?.languages) ? profile.languages : [],
          sessionFee: !isEmpty(profile?.sessionHono)
            ? profile?.sessionHono
            : '',
          nationalID: !isEmpty(profile?.nationalId)
            ? profile?.nationalId
            : 'N/A'
        }
        // Faculty
        // facultyName: 'Columbia University Medical School',
        // facultyAddress: 'New York, NY 10032, United States',
        // // Certifications
        // certifications: [
        //   'American Board Of Internal Medicine - Internal Medicine'
        // ],
        // MEDICAL SCHOOL
        // // Education & fellowship
        // educationFellowships: [
        //   {
        //     name: 'American Board Of Internal Medicine - Internal Medicine',
        //     graduated: 'Graduated 1998',
        //     address: 'New York, NY 10032, United States'
        //   }
        // ]
      })
    }
  },
  /**
   *
   * @param data
   * @param memberId
   * @returns {Promise<[]|*[]>}
   */
  async getPubmedData({ data }) {
    if (!isEmpty(data)) {
      const processPubMedID = async publications => {
        if (!isEmpty(publications)) {
          let parsedArticleData = []
          let parsed = false
          let publicationsList = publications.slice(0, 10)
          for await (let item of publicationsList) {
            if (typeof item !== 'object' || isEmpty(item)) {
              await axios
                .get(`${pubMedContentEndpoint}${item}`)
                .then(response => {
                  try {
                    let regex1 = /<ArticleTitle>(.*?)<\/ArticleTitle>/gm
                    let titleRegEX = regex1.exec(response.data)
                    let title = !isEmpty(titleRegEX) ? titleRegEX[1] : ''
                    return parsedArticleData.push({
                      articleTitle: title,
                      articleLink: item
                    })
                  } catch (e) {
                    console.error(e)
                  }
                })
                .catch(e => {
                  console.error(e)
                })
            } else {
              parsed = true
              break
            }
          }
          // IF PARSED RETURN INCOMING PUBS - IF NOT EMPTY TO PREVENT UNDEFINED
          return !isEmpty(parsedArticleData)
            ? parsedArticleData
            : parsed
            ? publications
            : []
        } else {
          return []
        }
      }
      // console.log('ARTICLE')
      let article = data[0]
      return await processPubMedID(article.publications).then(articleData => {
        // console.log('ARTICLE DATA')
        // console.log(articleData)
        let newData
        try {
          newData = data.map(item => ({ ...item, publications: articleData }))
          // console.log('PROCESSED DATA')
          // console.log(newData)
          return newData
        } catch (e) {
          console.error(e)
        }
        // console.log('PROCESSING FAILED')
        // console.log(data)
        return data
      })
    } else {
      return []
    }
  }
})

export default ListingsParser
