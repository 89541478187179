import { createReducer, createAction } from '@reduxjs/toolkit'

export const isAllowed = createAction('IS_ALLOWED')

export const authRequest = createAction('AUTH_REQUEST')
export const authRequestSuccess = createAction('AUTH_REQUEST_SUCCESS')
export const authRequestFail = createAction('AUTH_REQUEST_FAIL')
export const authFailedMsg = createAction('AUTH_FAILED_MSG')

export const logoutRequest = createAction('LOGOUT_REQUEST')
export const logoutRequestSuccess = createAction('LOGOUT_REQUEST_SUCCESS')
export const softlogoutRequest = createAction('SOFT_LOGOUT_REQUEST')

export const authPrepare = createAction('AUTH_PREPARE')
export const authSetSpecialty = createAction('AUTH_SET_SPECIALTY')

export const actions = {
  isAllowed,
  authRequest,
  authRequestSuccess,
  authRequestFail,
  authFailedMsg,
  logoutRequest,
  logoutRequestSuccess,
  authPrepare,
  authSetSpecialty,
  softlogoutRequest
}

const authReducer = createReducer(
  {
    access: '',
    specialty: '',
    profession: '',
    idhProfileId: '',
    role: [],
    features: {},
    fetching: true,
    firstVisit: true,
    isAllowed: false,
    isAuth: false,
    isLimited: false,
    // NOTIFICATION
    authFailedMsg: ''
  },
  {
    [authRequest]: state => {
      state.fetching = true
      state.isAuth = false
    },
    [authRequestSuccess]: (
      state,
      {
        payload: {
          access,
          role,
          features,
          isAllowed,
          isAuth,
          isLimited,
          specialty,
          profession,
          idhProfileId
        }
      }
    ) => {
      state.access = access
      state.isAllowed = isAllowed
      state.role = role
      state.features = features
      state.idhProfileId = idhProfileId
      state.specialty = specialty
      state.profession = profession
      state.isAuth = isAuth
      state.isLimited = isLimited
      state.fetching = false
      state.firstVisit = false
    },
    [authRequestFail]: state => {
      state.access = ''
      state.specialty = ''
      state.profession = ''
      state.idhProfileId = ''
      state.isAllowed = false
      state.fetching = false
      state.isAuth = false
    },
    [logoutRequest]: () => {},
    [logoutRequestSuccess]: state => {
      state.access = ''
      state.specialty = ''
      state.profession = ''
      state.idhProfileId = ''
      state.isAllowed = false
      state.fetching = false
      state.isAuth = false
    },
    [softlogoutRequest]: state => {
      state.access = ''
      state.specialty = ''
      state.profession = ''
      state.idhProfileId = ''
      state.isAllowed = false
      state.fetching = false
      state.isAuth = false
    },
    [authPrepare]: state => {
      state.fetching = true
    },

    [authFailedMsg]: (state, { payload: { msg } }) => {
      state.authFailedMsg = msg
    }
  }
)

export default authReducer
