import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'components/Checkbox'
import DropdownInput from 'components/DropdownInput'
import Input from 'components/TextInput'
// import Tooltip from 'components/Tooltip'
import { useDispatch, useSelector } from 'react-redux'
import * as exportListActions from '../ExportListReducer'
import * as exportListSelectors from '../ExportListSelector'
import Typography from 'components/ui/Typography'
import styles from './form.module.sass'
// import { buildLoiOptions } from '../config'

const { getBrandOptions } = exportListSelectors

const HopperExportForm = ({ formProps }) => {
  const { values, setFieldValue } = formProps
  const { activeStep, exportToHopper, fileUploaded } = values
  const brandOptions = useSelector(getBrandOptions())
  const dispatch = useDispatch()
  const [enabled, setEnabled] = useState(true)
  // const loiOptions = buildLoiOptions()

  useEffect(() => {
    if (
      Object.keys(brandOptions).length === 0 &&
      activeStep === 'create-list'
    ) {
      getOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setEnabled(exportToHopper === 'Yes')
  }, [exportToHopper])

  const getOptions = () => {
    dispatch(exportListActions.getBrandsRequest())
  }

  const handleExportFileChange = () => {
    // Now we change to the other step, meaning if we are on 'export-list' we go to 'create-list'
    if (values.exportToFile === 'Yes') {
      setFieldValue('exportToFile', 'No')
    } else {
      setFieldValue('exportToFile', 'Yes')
    }
  }

  const handleExportHopperChange = () => {
    // Now we change to the other step, meaning if we are on 'export-list' we go to 'create-list'
    if (values.exportToHopper === 'Yes') {
      setFieldValue('exportToHopper', 'No')
    } else {
      setFieldValue('exportToHopper', 'Yes')
    }
  }

  return (
    <div className={styles.hopperContainer}>
      {activeStep === 'export-list' && (
        <>
          <Typography
            type="title"
            color="text-primary"
            className={styles.title}
          >
            Export to Files *
          </Typography>
          <div className={styles.checkboxGroup}>
            <Checkbox
              label="Yes"
              name="exportToFile"
              value={values.exportToFile === 'Yes'}
              onChange={handleExportFileChange}
            />
            <Checkbox
              label="No"
              name="exportToFile"
              value={values.exportToFile === 'No'}
              onChange={handleExportFileChange}
            />
          </div>
          <Typography
            type="title"
            color="text-primary"
            className={styles.title}
          >
            Export to Hopper *
          </Typography>
          <div className={styles.checkboxGroup}>
            <Checkbox
              label="Yes"
              name="exportToHopper"
              value={values.exportToHopper === 'Yes'}
              onChange={handleExportHopperChange}
            />
            <Checkbox
              label="No"
              name="exportToHopper"
              value={values.exportToHopper === 'No'}
              onChange={handleExportHopperChange}
            />
          </div>
        </>
      )}
      {activeStep === 'create-list' && (
        <DropdownInput
          name="brand"
          label="Brand"
          placeholder="Choose a Brand"
          required
          options={brandOptions}
          disabled={!fileUploaded}
        />
      )}
      <Input
        label="Maconomy Number"
        name="maconomyNumber"
        type="number"
        required={activeStep === 'create-list' || enabled}
        traditional
        disabled={
          activeStep === 'create-list' && fileUploaded ? false : !enabled
        }
        disableNegativeValue
      />
      <Input
        label="Project Segment"
        name="waveNumber"
        type="number"
        traditional
        disabled={
          activeStep === 'create-list' && fileUploaded ? false : !enabled
        }
        disableNegativeValue
      />
      {/* <Input
        label="Max Size"
        name="maxSize"
        type="number"
        placeholder="Unlimited"
        onKeyDown
        labelTooltip={
          <Tooltip
            tip="Number of records to submit to Hopper."
            className={styles.inputTooltip}
            multiline={false}
            iconProps={{
              icon: 'svg/custom/help-outline',
              iconSize: 20
            }}
          />
        }
        traditional
        disabled={activeStep === 'create-list' ? false : !enabled}
        disableNegativeValue
        disableExponential
      /> */}
      {/* <DropdownInput
        label="Length of Interview"
        name="lengthOfInterview"
        type="number"
        required={activeStep === 'create-list' || enabled}
        traditional
        disabled={activeStep === 'create-list' ? false : !enabled}
        options={loiOptions}
      /> */}
    </div>
  )
}

HopperExportForm.propTypes = {
  /**
   * Form props
   */
  formProps: PropTypes.object
}

export default HopperExportForm
