import { createReducer, createAction } from '@reduxjs/toolkit'

export const commentsReset = createAction('COMMENTS_RESET')
export const getCommentsRequest = createAction('GET_COMMENTS_REQUEST')
export const getCommentsRequestSuccess = createAction(
  'GET_COMMENTS_REQUEST_SUCCESS'
)
export const getCommentsRequestFail = createAction('GET_COMMENTS_REQUEST_FAIL')
export const openCommentsSection = createAction('OPEN_COMMENTS_SECTION')
export const closeCommentsSection = createAction('CLOSE_COMMENTS_SECTION')

export const postCommentRequest = createAction('POST_COMMENT_REQUEST')
export const postCommentRequestSuccess = createAction(
  'POST_COMMENT_REQUEST_SUCCESS'
)
export const postCommentRequestFail = createAction('POST_COMMENT_REQUEST_FAIL')

export const postCommentReplyRequest = createAction(
  'POST_COMMENT__REPLY_REQUEST'
)
export const postCommentReplyRequestSuccess = createAction(
  'POST_COMMENT_REPLY_REQUEST_SUCCESS'
)

export const likeArticleRequest = createAction('LIKE_ARTICLE_REQUEST')
export const likeArticleRequestSuccess = createAction(
  'LIKE_ARTICLE_REQUEST_SUCCESS'
)
export const likeArticleRequestFail = createAction('LIKE_ARTICLE_REQUEST_FAIL')
export const likeArticleResetFail = createAction('LIKE_ARTICLE_RESEST_FAIL')

export const actions = {
  commentsReset,
  getCommentsRequest,
  getCommentsRequestSuccess,
  getCommentsRequestFail,
  openCommentsSection,
  closeCommentsSection,

  postCommentRequest,
  postCommentRequestSuccess,
  postCommentRequestFail,

  postCommentReplyRequest,
  postCommentReplyRequestSuccess,

  likeArticleRequest,
  likeArticleRequestSuccess,
  likeArticleRequestFail,
  likeArticleResetFail
}
const initialState = {
  status: false,
  fetchingComments: false,
  payloadComments: {},
  comments: [],
  commentSection: {},

  postingComment: false,
  postingCommentFailed: false,
  payloadPostComment: {},

  postingCommentReply: false,
  payloadPostCommentReply: {},

  likingArticle: false,
  likingArticleFailed: false,
  payloadLikeArticle: {},

  commentFailed: {},
  likeFailed: {}
}

const commentsReducer = createReducer(initialState, {
  [commentsReset]: state => {
    state.comments = []
  },
  [getCommentsRequest]: (state, { payload }) => {
    state.status = false
    state.fetchingComments = true
    state.payloadComments = payload
    state.comments = []
  },
  [getCommentsRequestSuccess]: (state, { payload: { comments } }) => {
    state.status = true
    state.comments = [comments]
    state.fetchingComments = false
  },
  [getCommentsRequestFail]: state => {
    state.status = false
    state.comments = []
    state.fetchingComments = false
  },
  [openCommentsSection]: (state, { payload: { articleId } }) => {
    state.commentSection[articleId] = true
  },
  [closeCommentsSection]: (state, { payload: { articleId } }) => {
    state.commentSection[articleId] = false
  },

  [postCommentRequest]: (state, { payload }) => {
    state.postingComment = true
    state.postingCommentFailed = false
    state.payloadPostComment = payload
    state.commentFailed[payload?.articleId] = false
  },
  [postCommentRequestSuccess]: state => {
    state.postingComment = false
    state.postingCommentFailed = false
  },
  [postCommentRequestFail]: (state, { payload: { articleId } }) => {
    state.payloadPostComment = {}
    state.postingComment = false
    state.postingCommentFailed = true
    state.commentFailed[articleId] = true
  },

  [postCommentReplyRequest]: (state, { payload }) => {
    state.postingCommentReply = true
    state.postingCommentFailed = false
    state.payloadPostCommentReply = payload
    state.commentFailed[payload?.articleId] = false
  },
  [postCommentReplyRequestSuccess]: state => {
    state.postingCommentReply = false
    state.postingCommentFailed = false
  },

  [likeArticleRequest]: (state, { payload }) => {
    state.likingArticle = true
    state.likingArticleFailed = false
    state.payloadLikeArticle = payload
    state.likeFailed[payload?.articleId] = false
  },
  [likeArticleRequestSuccess]: state => {
    state.likingArticle = false
    state.likingArticleFailed = false
  },
  [likeArticleRequestFail]: (state, { payload: { articleId } }) => {
    state.likingArticle = false
    state.likingArticleFailed = true
    state.payloadLikeArticle = {}
    state.likeFailed[articleId] = true
  },
  [likeArticleResetFail]: (state, { payload: { articleId } }) => {
    state.likeFailed[articleId] = false
  }
})

export default commentsReducer
