import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  roles: Yup.array()
    .min(1, 'At least one role should be selected')
    .required('Required')
})

export default validationSchema
