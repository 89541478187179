import { createSelector } from '@reduxjs/toolkit'

export const authSelector = state => state.authReducer

// access token, for persistence and API requests
export const getAccessToken = () =>
  createSelector(authSelector, auth => auth.access)
// if processing AuthServer response = true
export const getFetchingAuthStatus = () =>
  createSelector(authSelector, auth => auth.fetching)
// to avoid calling AuthServer every time
export const getIsFirstVisit = () =>
  createSelector(authSelector, auth => auth.firstVisit)
// if role soft or auth = true
export const getIsAuth = () => createSelector(authSelector, auth => auth.isAuth)
// if role is limited
export const getIsLimited = () =>
  createSelector(authSelector, auth => auth.isLimited)
// if user is allowed to enter path = true
export const getIsAllowed = () =>
  createSelector(authSelector, auth => auth.isAllowed)
// Specialty retrieved from UserInfo AUTH
export const getSpecialty = () =>
  createSelector(authSelector, auth => auth.specialty)
// Profession retrieved from UserInfo AUTH
export const getProfession = () =>
  createSelector(authSelector, auth => auth.profession)
// retrieve idhProfileId
export const getIdhProfileId = () =>
  createSelector(authSelector, auth => auth.idhProfileId)
// ROLE table
export const getRoleTable = () =>
  createSelector(authSelector, auth => auth.role)
// Role Limits
export const getRoleLimits = () =>
  createSelector(authSelector, auth => auth.roleLimits)
// Feature table
export const getFeaturesTable = () =>
  createSelector(authSelector, auth => auth.features)

export const getAuthFailedMsg = () =>
  createSelector(authSelector, auth => auth.authFailedMsg)
export const getAuthState = () => createSelector(authSelector, auth => auth)
