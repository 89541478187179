import { all } from 'redux-saga/effects'
import { default as bootstrapSaga } from 'modules/bootstrap/BootstrapSaga'
import { default as trackingSaga } from 'modules/tracking/TrackingSaga'
import { default as authSaga } from 'modules/auth/AuthSaga'
import { default as loginSaga } from 'modules/login/LoginSaga'
import { default as faqPageSaga } from 'modules/faq/FaqPageSaga'
import { default as listIngestionSaga } from 'modules/listIngestion/ListIngestionSaga'
import { default as exploreListsSaga } from 'modules/exploreList/ExploreListSaga'
import { default as soundBoardSaga } from 'modules/soundboard/SoundBoardSaga'
import { default as exploreMarketSaga } from 'modules/exploreMarket/ExploreMarketSaga'
import { default as exploreProjectSaga } from 'modules/exploreProject/ExploreProjectSaga'
import { default as typeahedSaga } from 'modules/typeahead/TypeaheadSaga'
import { default as listIdsSaga } from 'modules/listIds/ListIdsSaga'
import { default as deviceIdSaga } from 'modules/deviceId/DeviceIdSaga'
import { default as jobStatusListSaga } from 'modules/jobStatusList/JobStatusListSaga'
import { default as jobStatusSingleSaga } from 'modules/jobStatusSingle/JobStatusSingleSaga'
import { default as compareListsSaga } from 'modules/compareLists/CompareListsSaga'
import { default as exportListSaga } from 'modules/exportList/ExportListSaga'
import { default as chartsSaga } from 'modules/Charts/ChartsSaga'
import { default as filterSaga } from 'modules/filter/FilterSaga'
import { default as contactUsSaga } from 'modules/contactUs/ContactUsSaga'
import { default as homeSaga } from 'modules/home/HomeSaga'
import { default as labSaga } from 'modules/lab/LabSaga'
import { default as advisorSearchSaga } from 'modules/formAdvisorSearch/AdvisorSearchSaga'
import { default as advisorScheduleSaga } from 'modules/formAdvisorSchedule/AdvisorScheduleSaga'
import { default as advisorProfileSearchSaga } from 'modules/advisorProfile/AdvisorProfileSaga'
import { default as fullProfileViewsSaga } from 'modules/fullProfileViews/FullProfileViewsSaga'
import { default as combinationsTableSaga } from 'modules/CombinationsTable/CombinationsTableSaga'
import { default as usersCreateSaga } from 'modules/admin/usersCreate/UsersCreateSaga'
import { default as usersSearchSaga } from 'modules/admin/usersSearch/UsersSearchSaga'
import { default as universeSaga } from 'modules/admin/universe/UniverseSaga'
import { default as universeHistorySaga } from 'modules/admin/universeHistory/UniverseHistorySaga'
import { default as incomingSaga } from 'modules/admin/incomingCounts/IncomingCountsSaga'
import { default as usersBulkSaga } from 'modules/admin/usersBulkCreate/UsersBulkSaga'
import { default as thresholdSaga } from 'modules/admin/threshold/ThresholdSaga'
import { default as variablesSaga } from 'modules/admin/variables/VariablesSaga'
import { default as dataCachesSaga } from 'modules/admin/dataCaches/DataCachesSaga'
import { default as chartsGenericSaga } from 'modules/admin/chartsGeneric/ChartsGenericSaga'
import { default as chartsSearchSaga } from 'modules/admin/chartsSearch/ChartsSearchSaga'
// Dougall Sagas
import { default as loadingSaga } from 'modules/dougall/loading/LoadingSaga'
import { default as chatSaga } from 'modules/dougall/chat/ChatSaga'
import { default as historySaga } from 'modules/dougall/history/HistorySaga'
import { default as popularPromptsSaga } from 'modules/dougall/popularPrompts/PopularPromptsSaga'
import { default as faqSaga } from 'modules/dougall/faq/FaqSaga'
import { default as feedbackSaga } from 'modules/dougall/feedback/FeedbackSaga'
import { default as translationSaga } from 'modules/dougall/translation/TranslationSaga'
import { default as themeProviderSaga } from 'modules/dougall/themeProvider/ThemeProviderSaga'

export default function* root(services) {
  yield all([
    trackingSaga(services),
    authSaga(services),
    bootstrapSaga(services),
    loginSaga(services),
    faqPageSaga(services),
    listIngestionSaga(services),
    exploreListsSaga(services),
    soundBoardSaga(services),
    exploreMarketSaga(services),
    exploreProjectSaga(services),
    typeahedSaga(services),
    deviceIdSaga(services),
    listIdsSaga(services),
    jobStatusListSaga(services),
    jobStatusSingleSaga(services),
    compareListsSaga(services),
    exportListSaga(services),
    chartsSaga(services),
    filterSaga(services),
    contactUsSaga(services),
    homeSaga(services),
    labSaga(services),
    advisorSearchSaga(services),
    advisorScheduleSaga(services),
    fullProfileViewsSaga(services),
    advisorProfileSearchSaga(services),
    combinationsTableSaga(services),
    universeSaga(services),
    universeHistorySaga(services),
    incomingSaga(services),
    thresholdSaga(services),
    usersCreateSaga(services),
    usersSearchSaga(services),
    usersBulkSaga(services),
    variablesSaga(services),
    dataCachesSaga(services),
    chartsGenericSaga(services),
    chartsSearchSaga(services),
    // Dougall Sagas
    loadingSaga(services),
    translationSaga(services),
    chatSaga(services),
    historySaga(services),
    popularPromptsSaga(services),
    faqSaga(services),
    feedbackSaga(services),
    themeProviderSaga(services)
  ])
}
