import { cloneElement, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import modernStyles from './InputModern.module.sass'
import traditionalStyles from './InputTraditional.module.sass'
import classNames from 'classnames/bind'
import uniqueId from 'lodash/uniqueId'
import FieldError from '../FieldError'
import { propWarning } from '../utils'
import Field from '../Field'

/**
 * Fork of PSL UI Input: this component fixes the controlled value issue
 */
const Input = ({
  onChange,
  onFocus,
  onBlur,
  disabled,
  label,
  required,
  labelFloat,
  labelTooltip,
  login,
  altTheme,
  error,
  errorAlign,
  className,
  meta: { touched, error: metaError },
  traditional,
  readOnly,
  textarea,
  leftElements,
  rightElements,
  onEnterKeyDown,
  placeholder,
  fieldRef,
  defaultValue,
  value: valueProp,
  input,
  id,
  type,
  name,
  disableNegativeValue,
  disableExponential,
  ...other
}) => {
  const { value: inputValueProp } = input
  const passedValue =
    inputValueProp !== undefined ? inputValueProp : valueProp || defaultValue
  const [isFocused, setIsFocused] = useState(false)
  const [hasValue, setHasValue] = useState(Boolean(passedValue))
  const [value, setValue] = useState(passedValue || '')
  const [inputId, setInputId] = useState(input.name || name || id)
  const handleChange = event => {
    if (typeof input.onChange === 'function') {
      input.onChange(event)
    }

    if (typeof onChange === 'function') {
      onChange(event)
    }

    const value = event.target.value
    setValue(value)
    setHasValue(Boolean(value))
  }
  const handleFocus = event => {
    if (typeof input.onFocus === 'function') {
      input.onFocus(event)
    }
    if (onFocus !== undefined) {
      onFocus(event)
    }
    setIsFocused(true)
  }
  const handleBlur = event => {
    if (typeof input.onBlur === 'function') {
      input.onBlur(event)
    }
    if (onBlur !== undefined) {
      onBlur(event)
    }
    setIsFocused(false)
  }
  const disableArrowKeys = e => {
    if (
      disableExponential &&
      type === 'number' &&
      e.type === 'keydown' &&
      e.code === 'KeyE'
    ) {
      e.preventDefault()
    }
    if ([38, 40].includes(e.which) && type === 'number') {
      e.preventDefault()
    }
    if (e.which === 189 && disableNegativeValue && type === 'number') {
      e.preventDefault()
    }
  }
  const styles =
    textarea || traditional || login ? traditionalStyles : modernStyles
  const themeStyles = { ...styles, ...altTheme }
  const cx = classNames.bind(themeStyles)
  const hasError = Boolean((touched && metaError) || error)
  const EnhancedLabelTooltip =
    labelTooltip &&
    cloneElement(labelTooltip, {
      // className: cx(labelTooltip.props.className, themeStyles.tooltipButton),
      id: `${inputId}-tooltip`
    })
  const pattern = type === 'number' ? '[0-9]*' : null
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inputRef = useRef(null)
  if (fieldRef) {
    inputRef.current = fieldRef
  }

  const leftElementsRef = useRef(null)

  let inputValue
  if (typeof valueProp === 'string') {
    inputValue = valueProp || ''
  } else if (typeof input.value === 'string') {
    inputValue = input.value || ''
  } else {
    inputValue = value
  }

  const EnhancedLeftElements = (
    <div ref={leftElementsRef} className={themeStyles.sideElements}>
      {leftElements}
    </div>
  )
  useEffect(() => {
    // To set value/hasValue from a browser autofill
    let currentValue = value
    let autofillDelay
    if (inputRef.current) {
      // The change event is not fired in Chrome iOS and possibly other browsers on autofill
      // According to Chrome devs, this is for security reasons
      // The value is only available once the user interacts with the page
      // Here, we detect an autofill and set the hasValue state appropriately
      // https://github.com/facebook/react/issues/1159
      // https://bugs.chromium.org/p/chromium/issues/detail?id=352527
      autofillDelay = setTimeout(() => {
        let autofilled
        try {
          autofilled =
            inputRef.current.matches(':autofill') ||
            inputRef.current.matches(':-webkit-autofill')
          if (autofilled) {
            currentValue = inputRef.current.value
            setValue(currentValue)
            setHasValue(Boolean(currentValue || autofilled))
            if (input.onChange !== undefined) {
              input.onChange(currentValue)
            }
          }
        } catch (error) {}
        // This causes issues with the controlled value
        // setValue(currentValue) // This may not be worth leaving - autofill values are not available for security reasons
        // setHasValue(Boolean(currentValue || autofilled))
      }, 500)
    }

    return () => clearTimeout(autofillDelay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (!inputId) {
      setInputId(`${textarea ? 'textarea' : 'input'}-${uniqueId()}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setValue(passedValue)
    setHasValue(Boolean(passedValue))
  }, [passedValue])
  return (
    <div data-testid="input-container" className={className}>
      <div
        className={cx(themeStyles.container, {
          disabled,
          login,
          isFocused,
          hasError,
          readOnly,
          hasSideElements: rightElements || leftElements
        })}
      >
        {label && !login ? (
          <label
            htmlFor={inputId}
            style={
              !traditional
                ? {
                    marginLeft:
                      leftElementsRef.current &&
                      !hasValue &&
                      !isFocused &&
                      leftElementsRef.current.clientWidth
                  }
                : {}
            }
            className={cx(themeStyles.label, {
              labelFocused: isFocused,
              hasValue,
              labelDisabled: disabled,
              labelReadOnly: readOnly,
              labelFloat
            })}
          >
            {label}
            {required && <span className={themeStyles.required}> *</span>}
            {EnhancedLabelTooltip}
          </label>
        ) : null}
        <Field
          {...other}
          textarea={textarea}
          hasError={hasError}
          isFocused={isFocused}
          readOnly={readOnly}
          disabled={disabled}
          id={inputId}
          type={type}
          altTheme={altTheme}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onEnterKeyDown={onEnterKeyDown}
          value={inputValue}
          traditional={traditional}
          placeholder={login ? placeholder || label : placeholder}
          fieldRef={inputRef}
          required={required}
          pattern={pattern}
          leftElements={leftElements && EnhancedLeftElements}
          rightElements={rightElements}
          onWheel={event => event.currentTarget.blur()}
          onKeyDown={disableArrowKeys}
          onKeyUp={disableArrowKeys}
        />
      </div>
      {hasError && (
        <FieldError className={themeStyles.errorMessage} align={errorAlign}>
          {metaError || error}
        </FieldError>
      )}
    </div>
  )
}

Input.defaultProps = {
  type: 'text',
  disabled: false,
  input: {},
  meta: {},
  required: false,
  error: '',
  login: false,
  traditional: false
}
Input.displayName = 'Input'
Input.propTypes = {
  /**
   * @deprecated in version 2.7
   *
   * An optional button element to be contained within the component
   * This is deprecated in favor of `rightElements` prop which can contain multiple elements
   */
  button: propWarning(
    'Deprecated prop: This prop will be removed in version 3. Use `rightElements` prop instead.'
  ),
  /**
   * An optional node of elements to be contained within the Input component
   */
  leftElements: PropTypes.node,
  /**
   * An optional node of elements to be contained within the Input component
   */
  rightElements: PropTypes.node,
  /**
   * The input ID
   */
  id: PropTypes.string,
  /**
   * The name used for the label element "for" and input "name" attributes (if not using redux form)
   */
  name: PropTypes.string,
  /**
   * A custom onChange event
   */
  onChange: PropTypes.func,
  /**
   * The type of input (not used for textarea)
   */
  type: PropTypes.oneOf([
    'text',
    'number',
    'password',
    'tel',
    'url',
    'email',
    'search'
  ]),
  /**
   * The placeholder text shown inside the input before text is entered (not used with "labelFloat")
   */
  placeholder: propWarning(
    'Deprecated prop: This prop will be removed in version 3. Use `rightElements` prop instead.'
  ),
  /**
   * The error shown below the input (not used if there is a redux-form style 'meta.error' value)
   */
  error: PropTypes.node,
  /**
   * the alignment of the error
   */
  errorAlign: PropTypes.string,
  /**
   * Whether or not the input is disabled
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, this is a login style input
   */
  login: PropTypes.bool,
  /**
   * The connected input props (if using redux-form)
   */
  input: PropTypes.object,
  /**
   * Metadata about the state of this field (if using redux-form)
   */
  meta: PropTypes.object,
  /**
   * Called when enter key is pressed
   */
  onEnterKeyDown: PropTypes.func,
  /**
   * If `true`, this input requires a value to submit the form (marks an existing label with an asterisk)
   */
  required: PropTypes.bool,
  /**
   * The label text (if any)
   */
  label: PropTypes.string,
  /**
   * @ignore
   */
  labelFloat: propWarning(
    'Deprecated prop: This prop will be removed in version 3. Use `rightElements` prop instead.'
  ),
  /**
   * If `true`. the input has a password visibility toggle button (only available for type="password")
   */
  hasPasswordToggle: PropTypes.bool,
  /**
   * A Tooltip component used for the label
   */
  labelTooltip: PropTypes.node,
  /**
   * An additional custom className
   */
  className: PropTypes.string,
  /**
   * A CSS modules style object to override default theme
   */
  altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  /**
   * If `true`, the input uses the traditional style
   */
  traditional: PropTypes.bool,
  /**
   * Default value to initialize the input, will only be taken into consideration on first render
   */
  defaultValue: PropTypes.string,
  /**
   * Current value of the input. Only when used as controlled input
   */
  value: PropTypes.string,
  /**
   * If `true`, the input is read-only
   */
  readOnly: PropTypes.bool,
  /**
   * Called when input is focused
   */
  onFocus: PropTypes.func,
  /**
   * Called when input is blurred
   */
  onBlur: PropTypes.func,
  /**
   * If `true`, the field will render as a textarea
   */
  textarea: PropTypes.bool,
  /**
   * If defined, a ref for the field
   */
  fieldRef: PropTypes.object,
  /**
   * @ignore
   */
  autosize: PropTypes.bool,
  /**
   * @ignore
   */
  resize: PropTypes.oneOf([
    '',
    'both',
    'horizontal',
    'vertical',
    'block',
    'inline'
  ]),
  /**
   * @ignore
   */
  minHeight: PropTypes.number,
  /**
   * @ignore
   */
  maxHeight: PropTypes.number,
  /**
   * Disable negative numbers, only for number input
   */
  disableNegativeValue: PropTypes.bool,
  /**
   * Disable exponential "e/E" character, only for number input
   */
  disableExponential: PropTypes.bool
}

export default Input
