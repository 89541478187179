import { Component } from 'react'
import PropTypes from 'prop-types'
import icons from '@psl/icons/listIcons'
import Icon from 'components/ui/Icon'
import Typography from 'components/ui/Typography'
import styles from './Header.module.sass'
import classNames from 'classnames/bind'
class Header extends Component {
  static defaultProps = {
    align: 'center',
    logoLink: '/',
    headerMode: 'default',
    iconLeft: 'svg/material-design-icons/navigation/arrow_back',
    iconLeftSize: 24,
    iconRight: 'svg/material-design-icons/navigation/menu',
    iconRightSize: 24,
    iconColor: 'action',
    borderBottom: '1px solid #e0e0e0',
    hasShadow: false
  }
  static displayName = 'Header'
  static propTypes = {
    /**
     * The overall header content alignment
     */
    align: PropTypes.string.isRequired,
    /**
     * The header background color
     */
    backgroundColor: PropTypes.string,
    /**
     * The header title text color
     */
    titleColor: PropTypes.string,
    /**
     * The function to run when the left icon button is clicked
     */
    actionLeft: PropTypes.func,
    /**
     * The function to run when the right icon button is clicked
     */
    actionRight: PropTypes.func,
    /**
     * The svg icon to show for the left button
     */
    iconLeft: PropTypes.oneOf(['', ...icons]),
    /**
     * The icon size to show for the left button
     */
    iconLeftSize: PropTypes.any,
    /**
     * The svg icon to show for the right button
     */
    iconRight: PropTypes.oneOf(['', ...icons]),
    /**
     * The icon size to show for the right button
     */
    iconRightSize: PropTypes.any,
    /**
     * The header mode
     */
    headerMode: PropTypes.oneOf(['default', 'transparent', 'external', 'text']),
    /**
     * The text to show in the h1 (hidden by logo if not using headerMode `text`)
     */
    title: PropTypes.string,
    /**
     * The text to show in the h2
     */
    subheading: PropTypes.string,
    /**
     * A CSS modules style object to override default theme
     */
    altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    /**
     * The logo's image source
     */
    logoSrc: PropTypes.string,
    /**
     * The logo's image className for styling purposes
     */
    logoClass: PropTypes.string,
    /**
     * The brand's image and text className for styling purposes
     */
    brandClass: PropTypes.string,
    /**
     * The titles's className for styling purposes
     */
    titleClass: PropTypes.string,
    /**
     * Function that executes on clicking the container
     */
    logoOnClick: PropTypes.func,
    /**
     * The logo's link
     */
    logoLink: PropTypes.string,
    /**
     * The icon button color
     */
    iconColor: PropTypes.oneOf([
      'action',
      'primary',
      'secondary',
      'tertiary',
      'default',
      'knockout'
    ]),
    /**
     * The bottom border style, thickness and color (accepts CSS values, e.g. "1px solid black", "none", etc).
     * Has no effect if the headerMode is "transparent"
     */
    borderBottom: PropTypes.string,
    /**
     * The header has a shadow
     */
    hasShadow: PropTypes.bool,
    /**
     * An additional custom className for the root element
     */
    className: PropTypes.string,
    /**
     * An additional custom style for the root element
     */
    style: PropTypes.object,
    /**
     * A ref to be forwarded for the root element
     */
    forwardedRef: PropTypes.object,
    /**
     * Modify header structure to give fixed width
     */
    fixedAppBarTheme: PropTypes.any
  }
  render = () => {
    const {
      logoLink,
      title,
      headerMode,
      logoSrc,
      logoClass,
      brandClass,
      logoOnClick,
      altTheme,
      backgroundColor,
      iconLeft,
      iconLeftSize,
      iconRight,
      iconRightSize,
      titleClass,
      actionLeft,
      actionRight,
      iconColor,
      borderBottom,
      hasShadow,
      titleColor,
      className,
      subheading,
      align = '',
      style,
      forwardedRef,
      fixedAppBarTheme,
      ...other
    } = this.props
    const themeStyles = { ...styles, ...altTheme }
    const cx = classNames.bind(themeStyles)
    return (
      <div
        id="header"
        role="region"
        className={cx(
          themeStyles[headerMode],
          {
            shadow: hasShadow
          },
          className
        )}
        style={{
          backgroundColor,
          ...(borderBottom &&
            headerMode !== 'transparent' && { borderBottom: borderBottom }),
          ...style
        }}
        ref={forwardedRef}
        {...other}
      >
        {fixedAppBarTheme ? (
          <div
            data-testid="fixedAppBarTheme"
            className={styles.fixedAppBar}
            style={fixedAppBarTheme}
          >
            {(headerMode === 'default' || headerMode === 'text') &&
              iconLeft && (
                <div
                  data-testid="headerMode"
                  className={styles.fixedButtonLeft}
                >
                  <Icon
                    icon={iconLeft}
                    onClick={actionLeft}
                    colorTier={iconColor}
                    iconSize={iconLeftSize}
                  />
                </div>
              )}
            <div
              className={cx(
                {
                  link: true
                },
                styles.fixedButtonLeft
              )}
            >
              <div
                className={cx(styles.logocontainer, brandClass)}
                onClick={logoOnClick}
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') logoOnClick()
                }}
              >
                <img className={logoClass} src={logoSrc} alt={title} />
                {title && (
                  <Typography type="title" className={titleClass}>
                    {title}
                  </Typography>
                )}
              </div>
              {subheading && (
                <Typography
                  type="subheading"
                  align={'center'}
                  style={{
                    ...(titleColor && { color: titleColor })
                  }}
                  className={themeStyles.subheading}
                >
                  {subheading}
                </Typography>
              )}
            </div>
            {(headerMode === 'default' || headerMode === 'text') &&
              iconRight && (
                <div data-testid="iconRight" className={styles.buttonRight}>
                  <Icon
                    icon={iconRight}
                    onClick={actionRight}
                    colorTier={iconColor}
                    iconSize={iconRightSize}
                  />
                </div>
              )}
          </div>
        ) : (
          <>
            {(headerMode === 'default' || headerMode === 'text') &&
              iconLeft && (
                <Icon
                  data-testid="header-iconLeft"
                  className={themeStyles.buttonLeft}
                  icon={iconLeft}
                  onClick={actionLeft}
                  colorTier={iconColor}
                  button
                  iconSize={iconLeftSize}
                />
              )}
            <div
              className={cx({
                link: true,
                [themeStyles[align]]: align,
                iconLeft: align === 'center' && iconLeft && !iconRight,
                iconRight: align === 'center' && iconRight && !iconLeft
              })}
            >
              <div
                className={cx(styles.logocontainer, brandClass)}
                onClick={logoOnClick}
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') logoOnClick()
                }}
              >
                <img className={logoClass} src={logoSrc} alt={title} />
                {title && (
                  <Typography type="title" className={titleClass}>
                    {title}
                  </Typography>
                )}
              </div>
              {subheading && (
                <Typography
                  type="subheading"
                  align={align}
                  style={{
                    ...(titleColor && { color: titleColor })
                  }}
                  className={themeStyles.subheading}
                >
                  {subheading}
                </Typography>
              )}
            </div>
            {(headerMode === 'default' || headerMode === 'text') &&
              iconRight && (
                <Icon
                  className={themeStyles.buttonRight}
                  icon={iconRight}
                  onClick={actionRight}
                  colorTier={iconColor}
                  button
                  iconSize={iconRightSize}
                />
              )}
          </>
        )}
      </div>
    )
  }
}

export default Header
